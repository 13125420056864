<div *ngIf="dataLoaded">
  <app-breadcrumb [title]="'ngw.payment.entry.bread-crumb' | translate" [storePreviousUrl]="true"></app-breadcrumb>
  <app-contract-header-change-account></app-contract-header-change-account>
  <app-contract-header [contractDetail]="contractDetail"></app-contract-header>
  <div *ngIf="dataLoaded && !servicesDownError && !terminatedAccountPaymentNotAllowed">
    <app-contract-header-amount-date-due></app-contract-header-amount-date-due>
  </div>
  <div class="page-payment-entry">
    <div class="payment-entry-container" [scrollTop]="scrollToTop">
      <div class="general error-container" role="alert">
        <em *ngIf="paymentEntryError === true" class="error-validation" id="errorMessage" [innerHtml]="errorMessage">
        </em>
      </div>
      <form #paymentEntryForm="ngForm" novalidate
        *ngIf="dataLoaded && !servicesDownError && !terminatedAccountPaymentNotAllowed">
        <div class="form-group">
          <label id="paymentAmountLabel" for="paymentAmount">{{'ngw.payment.lbl-amount-req' | translate}}</label>
          <mat-select [(ngModel)]="paymentEntry.selectedPaymetAmount" name="paymentAmount" required id="paymentAmount"
            class="form-control" (ngModelChange)="onPaymentAmountChange($event)" aria-label="Payment Amount"
            aria-labelledby="paymentAmountLabel">
            <mat-option *ngFor="let list of paymentEntry.paymentDueList" [value]="list">{{list.key}}</mat-option>
          </mat-select>
          <span #tooltipAmount="matTooltip" (mouseenter)="tooltipAmount.toggle()" (touchstart)="tooltipAmount.toggle()"
            class="icon icon-info" matTooltip="{{'ngw.payment.entry.tp-payment-amount' | translate}}"
            *ngIf="paymentEntry.showPaymentAmountToolTip === true && paymentEntry.otherAmountSelected === false"
            [matTooltipPosition]="position"></span>
        </div>
        <div class="form-group form-other-amount" *ngIf="paymentEntry.otherAmountSelected"
          [ngClass]="{'has-error': showAmountError(paymentEntryForm?.controls.otherAmount) || amountValidationError}">
          <input id="otherAmount" name="otherAmount" type="text" autocomplete="off" currency-mask
            value="{{paymentEntry.paymentAmount}}" required class="form-control other-amount"
            [(ngModel)]="paymentEntry.paymentAmount" placeholder="0.00"
            (change)="onOtherAmountChange($event.target.value)" />
          <em class="error-validation" id="paymentAmountRequiredError"
            *ngIf="otherAmountRequiredError">{{'ngw.validation.error.required' | translate}}</em>
          <em class="error-validation" id="invalidPaymentAmountError"
            *ngIf="invalidOtherAmountError">{{invalidOtherAmountErrorMessage}}</em>
        </div>
        <div class="form-group payment-source"
          [ngClass]="{'has-error': selectedPaymentSourceIsInvalid(paymentEntryForm.controls.paymentSource)}">
          <label id="paymentSourceLabel" for="paymentSource">{{'ngw.payment.lbl-source-req' | translate}}</label>
          <mat-select [(ngModel)]="paymentEntry.selectedPaymentSource" name="paymentSource" required id="paymentSource"
            class="form-control" (ngModelChange)="onPaymentSourceChange($event)" aria-label="Payment Source"
            aria-labelledby="paymentSourceLabel"
            [placeholder]="'ngw.recurring-payment.entry.caption-payment-source' | translate">
            <mat-option *ngFor="let source of paymentEntry.sources"
              [value]="source">{{source.displayAccountNumber}}</mat-option>
          </mat-select>
          <div class="payment-source-message-container" id="paymentSourceRequiredErrordiv"
            *ngIf="selectedPaymentSourceIsInvalid(paymentEntryForm.controls.paymentSource)">
            <em class="error-validation" id="paymentSourceRequiredError">{{'ngw.validation.error.required' |
              translate}}</em>
          </div>
          <div class="payment-source-message-container">
            <p class="payment-source-info-message">
              {{debitCardFeeMsg}}
            </p>
          </div>
        </div>
        <div class="form-group payment-date">
          <label id="paymentDateLabel" for="paymentdate">{{'ngw.payment.lbl-date-req' | translate}}</label>
          <mat-form-field class="mat-datepicker-container">
            <input matInput [matDatepicker]="paymentDateDatepicker" mat-hide-icons="all" id="paymentDate"
              class="form-control" [ngClass]="{'input-date-has-error': paymentDateError}"
              [min]="paymentEntry.firstDatePaymentAllowed" [max]="paymentEntry.lastDatePaymentAllowed"
              name="paymentDate" required (dateChange)="onPaymentDateChange($event.target.value)"
              [(ngModel)]="paymentEntry.paymentdate" [readonly]="paymentEntry.paymentdateReadOnly"
              aria-label="Payment Date" aria-labelledby="paymentDateLabel">
            <mat-datepicker-toggle *ngIf="!datepickerIsReadonly()" matSuffix
              [for]="paymentDateDatepicker"></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="touch" mat-hide-icons="all" (dateChange)="onPaymentDateChange($event)"
              #paymentDateDatepicker></mat-datepicker>
          </mat-form-field>
          <div class="payment-date-message-container">
            <p id="paymentDateMessage" class="payment-date-info-message"
              *ngIf="paymentEntry.showPaymentDateMessage === true">{{paymentEntry.paymentDateMessage | translate}}</p>
            <em class="error-validation" id="paymentDateErrorMessage"
              *ngIf="paymentDateError === true">{{paymentEntry.paymentDateMessage | translate}}</em>
          </div>
        </div>
        <div class="form-group" *ngIf="paymentEntry.isAch">
          <label id="emailAddressLabel" for="paymentConfirmationEmail">{{'ngw.payment.lbl-email-req' |
            translate}}</label>
          <mat-select [(ngModel)]="paymentEntry.selectedEmailAddress" name="paymentConfirmationEmail" required
            id="paymentConfirmationEmail" class="form-control" (ngModelChange)="onEmailChange($event)"
            aria-label="Email Address" aria-labelledby="emailAddressLabel">
            <mat-option *ngFor="let email of paymentEntry.emails" [value]="email">
              {{email | translate}}
            </mat-option>
          </mat-select>
          <span #tooltipEmail="matTooltip" (touchstart)="tooltipEmail.toggle(500)" class="icon icon-info"
            matTooltip="{{'ngw.payment.entry.tp-confirmation-email' | translate}}"
            [matTooltipPosition]="position"></span>
        </div>
        <div class="form-group new-email" *ngIf="showEnterNewEmail()"
          [ngClass]="{'has-error': showEmailError(paymentEntryForm?.controls.emailAddress, 'required') || showRegexError(paymentEntryForm?.controls.emailAddress, 'email') || invalidEmailError}">
          <input [(ngModel)]="paymentEntry.emailAddress" value="{{paymentEntry.emailAddress}}" name="emailAddress"
            (change)="setEmailValue($event.target.value)" required maxlength="60" id="emailAddress" type="email"
            class="form-control" placeholder="{{'ngw.payment.entry.txt-new-email' | translate}}" />
          <em class="error-validation" id="emailAddressRequiredError"
            *ngIf="emailValidationError === true">{{'ngw.validation.error.required' | translate}}</em>
          <em class="error-validation" id="emailAddressPatternError"
            *ngIf="emailPatternError === true || invalidEmailError">{{'ngw.validation.error.email' | translate}}</em>
          <div id="differentEmailMessage" *ngIf="!emailValidationError && !emailPatternError && !invalidEmailError">
            <p>
              {{'ngw.payment.entry.dif-email' | translate}}
            </p>
          </div>
        </div>

        <div id="previousPaymentDetail" *ngIf="paymentEntry.showLastPaymentDetail && this.showPreviousPaymentInfo">
          <p>
            {{paymentEntry.lastPaymentInformationText | translate}}
          </p>
        </div>
        <div class="payment-review-accept-agreement">
          <p>{{'ngw.payment.review.agreement' | translate}}</p>
          <mat-checkbox id="acceptPaymentCheckbox" name="acceptPaymentCheckbox"
            [(ngModel)]="resetCheckBox">{{'ngw.payment.review.payment-accept' | translate}}&nbsp;<a
              id="paymentacceptLink"
              (click)="showOneTimeAuthDialog()">{{'ngw.general.lnk-one-time-payment-authorization' |
              translate}}</a></mat-checkbox>
          <em class="error-validation" *ngIf="showAlertMessage"
            id="submitPaymentError">{{'ngw.payment.review.error.accept' | translate}}</em>
        </div>
        <div class="action-buttons">
          <div class="payoffInfo-msg-container" *ngIf="!terminatedAccount">
            <span>{{'ngw.payment.entry.payoff-info-txt' | translate}} <a
                (click)="navigateToPayoffInformation()">{{'ngw.payment.entry.lnk-payoff-information-page' |
                translate}}</a></span>
          </div>
          <button id="btnPrevious" type="button" class="btn btn-secondary" (click)="navigateToHome()"
            analytics-event="body.cancel-payment-entry.cancel-payment-entry.button">
            {{'ngw.general.btn-cancel' | translate}}</button>
          <button id="btnSubmitPayment" type="submit" setFocus
            [disabled]="paymentEntryForm.invalid || paymentEntry.noPaymentSource || otherAmountRequiredError || emailValidationError || emailPatternError || paymentDateError || invalidOtherAmountError || !resetCheckBox"
            class="btn btn-primary"
            (click)="navigateToPaymentConfirmation(); $event.preventDefault();">{{'ngw.payment.review.btn-submit' |
            translate}}</button>
        </div>
      </form>
      <div class="action-buttons" *ngIf="servicesDownError">
        <button id="btnPrevious" type="button" class="btn btn-secondary" (click)="navigateToHome()"
          analytics-event="body.cancel-payment-entry.cancel-payment-entry.button">{{'ngw.general.btn-cancel' |
          translate}}</button>
      </div>
    </div>
  </div>
</div>