import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from "../../shared/store/app.store";
import { AnalyticsIdOverrideName } from '../../shared/enums';
import { McidCookieFromBridgeServiceEcid } from '../../shared/_helper-services/mcid-cookie-from-bridge-service-ecid.service';

const {
    MCID_COOKIE_FROM_BRIDGE_SERVICE_ECID
} = AnalyticsIdOverrideName;

export class AnalyticsIdOverride {
    setOverrideId: () => void = () => {};
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsIdOverrideFactoryService {
    private idProviders = {
        [MCID_COOKIE_FROM_BRIDGE_SERVICE_ECID]: McidCookieFromBridgeServiceEcid
    };

    constructor(
        private injector: Injector,
        private store: Store<IAppState>
    ) { }

    getAnalyticsIdOverrideService(): AnalyticsIdOverride {
        let specifiedOverride: AnalyticsIdOverrideName;
        this.store.select(state => state.EnvironmentConfig.ANALYTICS_CONFIG.ID_OVERRIDE).subscribe(x => specifiedOverride = x);
        const idProvider = this.idProviders[specifiedOverride] || AnalyticsIdOverride;
        return new idProvider(this.injector, this.store);
    }
}