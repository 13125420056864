import { Component, OnInit, OnDestroy, Renderer2, Inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-cancel-purchase-miles',
  templateUrl: './cancel-purchase-miles.component.html',
  styleUrls: ['./cancel-purchase-miles.component.scss']
})


export class CancelPurchaseMilesComponent implements OnInit, OnDestroy{
   
   	@Input() stepper: any;

    constructor(private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    public returnToPurchaseMiles():void {
    	this.stepper.selectedIndex = 3;
    }
   
}
