import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LeaseEndInspection } from '../_models/vehicle-return-estimate/lease-end-inspection.model';
import { EndOfTermServiceClient, GetDsiInspectionReportPdfRequest, GetDsiInspectionReportPdfResponse } from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { DocumentTypeId, InspectionType } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";

@Injectable()
export class PreInspectionDocumentPdfService {
  constructor(
    private getDsiInspectionReportPdfClient: EndOfTermServiceClient,
    private fsTokenErrorHandler: FSTokenErrorHandler,
    private store: Store<IAppState>) {
  }

  public getInspectionReportPdfClient(accountNumber: string, inspectionTypeId: number): Observable<LeaseEndInspection> {
    let userId: string;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userId = x);
    let calculateandSavePayoffLetterPdfRequest = new GetDsiInspectionReportPdfRequest();
    calculateandSavePayoffLetterPdfRequest.accountNumber = accountNumber;
    calculateandSavePayoffLetterPdfRequest.documentTypeId = this.findDocumentTypeId(inspectionTypeId);
    let getDsiInspectionReportPdfResponse = new GetDsiInspectionReportPdfResponse();
    getDsiInspectionReportPdfResponse.contentStream

    return this.getDsiInspectionReportPdfClient.getDsiInspectionReportPdf(calculateandSavePayoffLetterPdfRequest)
      .pipe(mergeMap((response) => { return this.aftegetDsiInspectionReportPdfSuccess(response); })
        , catchError((error: any) => { return this.aftergetDsiInspectionReportPdfFailure(error); }));

  }

  public getCompleteInspectionReportPdfClient(accountNumber: string): Observable<LeaseEndInspection> {
    let userId: string;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userId = x);
    let calculateandSavePayoffLetterPdfRequest = new GetDsiInspectionReportPdfRequest();
    calculateandSavePayoffLetterPdfRequest.accountNumber = accountNumber;
    calculateandSavePayoffLetterPdfRequest.documentTypeId = DocumentTypeId.DSI;
    let getDsiInspectionReportPdfResponse = new GetDsiInspectionReportPdfResponse();
    getDsiInspectionReportPdfResponse.contentStream

    return this.getDsiInspectionReportPdfClient.getDsiInspectionReportPdf(calculateandSavePayoffLetterPdfRequest)
      .pipe(mergeMap((response) => { return this.aftegetDsiInspectionReportPdfSuccess(response); })
        , catchError((error: any) => { return this.aftergetDsiInspectionReportPdfFailure(error); }));

  }

  private aftegetDsiInspectionReportPdfSuccess(result: any): Observable<LeaseEndInspection> {
    let leaseEndInspection = new LeaseEndInspection();
    leaseEndInspection.contentStreamPdf = result.contentStream;
    if (leaseEndInspection.contentStreamPdf === '' || leaseEndInspection.contentStreamPdf == null) {
      leaseEndInspection.error = true;
    }
    return of(leaseEndInspection);
  }

  private aftergetDsiInspectionReportPdfFailure(result: any): Observable<LeaseEndInspection> {
    this.fsTokenErrorHandler.handleFSTokenError(result);
    let leaseEndInspection = new LeaseEndInspection();
    let faultType;
    let faultDetail;
    if (result.faultType) {
      faultType = result.faultType;
    }
    if (result.response) {
      let response = JSON.parse(result.response);
      if (response.FaultDetail !== undefined && response.FaultDetail.Message !== undefined) {
        faultDetail = response.FaultDetail.Message;
      }
    }
    leaseEndInspection.error = true;

    return of(leaseEndInspection);
  }

  private findDocumentTypeId(inspectionTypeId: number) {
    switch (inspectionTypeId) {
      case InspectionType.DsiCompleteInspection:
        return DocumentTypeId.DSI
      case InspectionType.DSINextPreInspection:
        return DocumentTypeId.DSINEXTPRE
      default:
        return DocumentTypeId.DSIPRE
    }
  }

}
