<form>
  <div class="alert alert-info" *ngIf="!isHide">
    <p>{{imagelimitationInformation | translate}}</p>
    <p>
      <span>{{'ngw.secure-message-center.attachments.lbl-total-remaining-size' | translate}} </span>
      {{this.fileAttachments.remainingUploadDocumentsSize}} MB
    </p>
  </div>
  <div class="action-buttons">
    <label for="file-upload-{{fileName}}" class="btn btn-secondary has-icon">
      <i class="fa fa-paperclip"></i>{{title}}
    </label>
    <input id="file-upload-{{fileName}}" type="file" (change)="onChange($event)" accept=".jpeg, .png, .gif, .jpg, .pdf, .tif, .gif, .tiff" multiple />
  </div>
</form>
