import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { RouterService } from '../_helper-services/router.service';
import { Constants } from '../constants';
import { UserService } from '../_helper-services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IAppState } from "../store/app.store";
import { GlobalErrorActions } from '../store/reducers/global-error-message.reducer';
import { AnalyticsService } from '../_helper-services/analytics.service';
import { ActivityLogging, ActivityLoggingFactory } from '../../../_shared/core/basecomponent/features/logging/logging.factory';
import { state } from '@angular/animations';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  protected logger: ActivityLogging;
  protected logFactory: ActivityLoggingFactory;

  constructor(
    private injector: Injector,
    private routerService: RouterService,
    private store: Store<IAppState>,
    private globalErrorActions: GlobalErrorActions,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  private logError(error, path) {
    let client_APP_Id: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => client_APP_Id = x);
    this.logFactory = this.injector.get(ActivityLoggingFactory);
    this.logFactory.getLogger(
      this,
      client_APP_Id,
      false
    )
      .then(logger => {
        this.logger = logger;
        this.logger.logerror([
          `${Constants.Logger.errorAt} ${path}; ${Constants.Logger.errorMessage} ${error}`
        ]);
      });
  }

  public handleError(error: any): void {
    if (this.analyticsService == undefined) { this.injector.get(AnalyticsService); }
    if (this.translateService == undefined) { this.injector.get(TranslateService); }
    if (this.routerService == undefined) { this.injector.get(RouterService); }

    const path = location.pathname;
    console.log(error);

    this.store.dispatch(this.globalErrorActions.setGlobalError(""));
    if (error instanceof UnknownException) {
      const errorTranslationKey = "ngw.global.technical-error";
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.store.dispatch(this.globalErrorActions.setGlobalError(res));
        this.logError(res, path);
      });
      this.analyticsService.pushErrorEvent(errorTranslationKey);
      this.routerService.navigateToErrorPage();
    }
    else {
      this.logError(error.message, path);
      this.store.dispatch(this.globalErrorActions.setGlobalError(error.message + "\n" + error.stack));
      this.analyticsService.pushErrorEvent(error.message);
      this.routerService.navigateToErrorPage();
    }
  }
}

@Injectable()
export class FSTokenErrorHandler {
  constructor(private routerService: RouterService, private userService: UserService) { }
  public handleFSTokenError(error: any) {
    if (error.response !== undefined) {
      let tokenErrorMessage = JSON.parse(error.response).Error != undefined ? JSON.parse(error.response).Error : JSON.parse(error.response).Message;
      switch (tokenErrorMessage) {
        case Constants.FSTOKEN_EXPIRED_ERROR:
          this.clearTokenAndNavigateToTwoFactorAuth();
          break;
        case Constants.FSTOKEN_SPOOFING_ERROR:
          this.clearTokenAndNavigateToTwoFactorAuth();
          break;
        case Constants.FSTOKEN_AUTHENTICATION_ERROR:
          this.clearTokenAndNavigateToTwoFactorAuth();
          break;
        default:
          break;
      }
    }
  }

  private clearTokenAndNavigateToTwoFactorAuth(): void {
    // this.userService.clearFSToken();
    // this.routerService.navigateToAuthentication();
  }
}

export class UnknownException {

}
