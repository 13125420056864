
export class FooterModel {
  privacy: string;
  terms: string;
  faq: string;
  contactus: string;
  scra: string;
  myBMW: string;
  myGarage: string;

  constructor(privacy?: string,
    terms?: string,
    faq?: string,
    contactus?: string,
    scra?: string,
    myBMW?: string,
    myGarage?: string) {
    this.privacy = privacy;
    this.terms = terms;
    this.faq = faq;
    this.contactus = contactus;
    this.scra = scra;
    this.myBMW = myBMW;
    this.myGarage = myGarage;
  }
}

