
export class StatementCollection {
    error: boolean;
    statementInfo: StatementInfo[];
    accountNumber: string;
}

export class StatementInfo {
    invoiceNumber: number;
    invoiceDate: Date;
    isAccountInBankruptcyStatus: boolean;
}
