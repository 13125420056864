import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from "./../store/app.store";
import { DatePipe, CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MapPaymentOption } from '../enums';
import { ObjectService } from './object.service';
import { ContractAccountDetailDTO } from '../../core/gateway-api';
import { MapPaymentReviewDetails } from '../../_models/map/map-payment-review-details.model';



@Injectable()
export class MileageAdjustmentHelperService {

  constructor(private store: Store<IAppState>,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private translateService: TranslateService,
    private objectService: ObjectService) {

  }

  public accountIsInElligibleForMAP(): boolean {
    let contractDetail: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    if (contractDetail) {
      let maturityDate = contractDetail.currentContractEndDate;
      return maturityDate <= new Date();
    }
    return false;
  }

  public getMileageAgreementContent(): string[] {
    let p1, p2, p3: string;
    let content: string[] = new Array();
    let mapPaymentReviewDetails: MapPaymentReviewDetails;
    this.store.select(state => state.MapPaymentReviewDetails).subscribe(x => mapPaymentReviewDetails = x);
    if (!this.objectService.isEmptyObject(mapPaymentReviewDetails)) {
      let previousMileageAllowed = mapPaymentReviewDetails.previousMilesAllowed.toLocaleString('en');
      let newTotalMileageAllowed = mapPaymentReviewDetails.totalMilesAllowed.toLocaleString('en');
      let nextMonthlyPayment = this.currencyPipe.transform(mapPaymentReviewDetails.nextMonthlyPayment, 'USD', 'symbol');
      let startDate = this.datePipe.transform(mapPaymentReviewDetails.dateEffective, 'mediumDate');
      let mileRate = this.currencyPipe.transform(mapPaymentReviewDetails.costPerMile, 'USD', 'symbol');
      let isMonthlyPayment = mapPaymentReviewDetails.paymentOption === MapPaymentOption.Monthly;
      if (isMonthlyPayment) {
        this.translateService.get("ngw.map.review.monthly-payment-agreement-p1", { currentMileage: previousMileageAllowed, newTotalMileage: newTotalMileageAllowed })
          .subscribe((res: string) => { p1 = res; content.push(p1); });
        this.translateService.get("ngw.map.review.monthly-payment-agreement-p2", { newMonthlyPayment: nextMonthlyPayment, startDate: startDate })
          .subscribe((res: string) => { p2 = res; content.push(p2); });
        this.translateService.get("ngw.map.review.monthly-payment-agreement-p3", { mileRate: mileRate })
          .subscribe((res: string) => { p3 = res; content.push(p3); });
      }
      else {
        this.translateService.get("ngw.map.review.single-payment-agreement-p1", { currentMileage: previousMileageAllowed, newTotalMileage: newTotalMileageAllowed })
          .subscribe((res: string) => { p1 = res; content.push(p1); });
        this.translateService.get("ngw.map.review.single-payment-agreement-p2", { mileRate: mileRate })
          .subscribe((res: string) => { p2 = res; content.push(p2); });
      }
    }
    return content;
  }


  public getTotalContractedMileage(milesToPurchase: number) {
    let originalContractedMileage = this.getOriginalContractedMileage();
    return originalContractedMileage + milesToPurchase;
  }

  public getOriginalContractedMileage() {
    let contractDetail: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    return contractDetail.totalContractMileage + contractDetail.additionalPurchasedMiles;
  }

}

