import { Injectable, Injector } from "@angular/core";
import { IdTokenFromBridgeService } from "../../../_shared/shared/_helper-services/id-token-from-bridge-services.service"
import { EnvironmentConfig, GcdmOneConfig } from "../environment-config";

@Injectable()
export class IdTokenProvider {
  idToken: () => Promise<string> = () => Promise.resolve('');
}

@Injectable({
  providedIn: 'root',
})
export class IdTokenProviderService {
  private tokenProviders = {
    IdTokenFromBridgeService
  };

  constructor(
    private injector: Injector
  ) { }

  public getIdTokenProvider = (envConfig: EnvironmentConfig): IdTokenProvider => {

    const specifiedProvider = (envConfig.GCDM_ONE_CONFIG || {} as Partial<GcdmOneConfig>).TOKENPROVIDER;
    const tokenProvider = this.tokenProviders[specifiedProvider] || IdTokenProvider;

    return new tokenProvider(this.injector);
  }
}