import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { GuardService } from './../shared/_helper-services/guard.service';
import { ModuleOnBoardingHelperService } from './../shared/_helper-services/module-onboarding-helper.service';

export const OnBoardingAcceptGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const guardService = inject(GuardService);
  const moduleOnBoardingHelperService = inject(ModuleOnBoardingHelperService);
  if (moduleOnBoardingHelperService.isEsignAccepted() && moduleOnBoardingHelperService.isTermsAccepted() &&
    moduleOnBoardingHelperService.isProfileTermsAccepted() && moduleOnBoardingHelperService.isPaperlessEnrolled())
    return guardService.guardSucceded();

  return guardService.guardFailed("onboarding");
}
