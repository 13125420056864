import { Injectable, EventEmitter } from '@angular/core';
import { StatementInfo } from '../../_models/my-account/statement.model';

@Injectable()
export class EventService {
  public openStatement$ = new EventEmitter<StatementInfo>();
  public cancelPendingPayment$ = new EventEmitter();
  public downloadAmortDocument$ = new EventEmitter();
  public schedulePaymentCancel$ = new EventEmitter();
  public botInitialized$ = new EventEmitter();
}

