<div class="legal-agreement-done-container">
  <div class="banner-content">
    <img src="../../../../../../../assets/images/legal-agreement-thank-you-screen-banner.jpg" alt="{{'ngw.paperless-enrollment.thank-you.banner-img-alt' | translate}}" />
  </div>
  <div class="thankyou-text">
    <h6>{{'ngw.paperless-enrollment.thank-you.title' | translate}}</h6>
    <p>{{'ngw.paperless-enrollment.thank-you.heading' | translate}}</p>
  </div>
  <p class="description-text">
    {{'ngw.paperless-enrollment.thank-you.desc.text-1' | translate}}
    <span>{{'ngw.paperless-enrollment.thank-you.desc.text-2' | translate}}</span>
    {{'ngw.paperless-enrollment.thank-you.desc.text-3' | translate}}
  </p>
</div>

