import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../shared/constants';

@Injectable()
export class ValidatorService {
  constructor(
    private translateService: TranslateService
  ) { }

  public regexValues = {
    mtan: "^[0-9]{6}$",
    routingNo: "^[0-9]{9}$",
    accountNo: "^[a-zA-Z0-9]{4,17}$",
    accountNumber: "^[0-9]{10}$",
    bankNumberCan: "^[0-9]{3}$",
    bankAccountNumberCan: "^[0-9]{2,17}$",
    bankTransitNumberCan: "^[0-9]{5}$",    
    ssn: "^([0-9]{3}-?[0-9]{2}-?[0-9]{4}| XXX - XX - XXXX)$",
    lastFourOfSSN: "^[0-9]{4}$",
    zip: "(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)",
    name: "^[a-zA-Z0-9-'. ]{2,30}$",
    mfa: "^[a-zA-Z0-9]{6,15}$",
    amount: "^[0-9]+(\\.[0-9]+)?$",
    email: '^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$',
    mileage:'^[0-9,]*$'
  };

  public regexExpressions = {
    name: new RegExp(/^[a-zA-Z0-9-'. ]{2,30}$/),
    nameEnglishFrench: new RegExp(/^[a-zA-Z0-9-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{2,30}$/),
    email: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    zip: new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/),
    accountNumber: new RegExp(/^\d{10}$/),
    bankNumberCan: new RegExp(/^\d{3}$/),
    bankAccountNumberCan: new RegExp(/^\d{2,17}$/),
    bankTransitNumberCan: new RegExp(/^\d{5}$/),
    achAccountNumber: new RegExp(/^[a-zA-Z0-9]{4,17}$/),
    achRoutingNumber: new RegExp(/^\d{9}$/),
    lastFourOfSSN: new RegExp(/^\d{4}$/),
    ssn: new RegExp(/^(\d{3}-?\d{2}-?\d{4}| XXX - XX - XXXX)$/),
    mtan: new RegExp(/^[0-9]{6}$/),
    date: new RegExp(/^(0?[1-9]|1[0-2])\/(0?[1-9]|1[0-9]|2[0-9]|3[01])\/(\d{4}|\d{2})$/),
    phoneNumber: new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
    amount: new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/),
    miles: new RegExp(/^[0-9]+$/),
    vin: new RegExp(/^[a-zA-Z0-9-'. ]{17}$/),
    mfa: new RegExp(/^[a-zA-Z0-9]{6,15}$/),
    nameOnCardEnglishFrench: new RegExp(/^[a-zA-Z0-9-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{2,61}$/)
  }

  showError(field: any, type?: string, showErrorAtStart?: boolean, showErrorOnChange?: boolean): boolean {
    let fieldIsInvalid: boolean;
    if (type) {
      fieldIsInvalid = field && field.invalid && field.errors && field.errors[type];
    }
    else {
      fieldIsInvalid = field && field.invalid;
    }
    return showErrorAtStart ?
      fieldIsInvalid
      : showErrorOnChange ?
        fieldIsInvalid && (field.touched || field.dirty)
        : fieldIsInvalid && field.touched;
  }

  showCheckboxError(field: any): boolean {
    let fieldIsInvalid = field && field.invalid && field.errors && field.errors["required"];
    return fieldIsInvalid && (field.touched || field.dirty);
  }

  showRegexError(field: any, type: string, showErrorAtStart?: boolean): boolean {
    let fieldIsNotValid = field && !this.validateRegex(type, field.value);
    return showErrorAtStart ? fieldIsNotValid : fieldIsNotValid && field.touched;
  }

  showPasswordError(field: any, showErrorAtStart?: boolean): boolean {
    let result: any;
    if (field && field.value) {
      let password = field.value;
      let length = [8, 40];
      result = {
        passedChecks: 0,
        lengthCheck: false,
        letterCheck: false,
        allCharactersValid: false,
        numberCheck: false,
        specialLettersCheck: false,
        isValid: function () {
          return this.lengthCheck === true && this.allCharactersValid && this.hasPassedChecks();
        },
        hasPassedChecks: function () {
          return this.passedChecks >= 2;
        },
        isLongEnough: function () {
          return this.lengthCheck;
        },
        passesLetters: function () {
          return this.letterCheck;
        },
        passedCharactersValid: function () {
          return this.allCharactersValid;
        },
        passesNumbers: function () {
          return this.numberCheck;
        },
        passesSpecialLetters: function () {
          return this.specialLettersCheck;
        }
      };
      // check min/max length
      if (password.length >= length[0] && password.length <= length[1]) {
        result.lengthCheck = true;
      }
      // allowedCharacters
      let allowedCharactersMatch = password.match(/[\-a-zA-Z0-9)(.,+@:;*'&#_\/"]/g);
      if (allowedCharactersMatch && allowedCharactersMatch.length === password.length) {
        result.allCharactersValid = true;
      }
      // check letters
      let letterMatch = password.match(/[a-zA-Z]/g);
      if (letterMatch && letterMatch.length > 0) {
        result.letterCheck = true;
        result.passedChecks++;
      }
      // check numbers
      let numberMatch = password.match(/[0-9]/g);
      if (numberMatch && numberMatch.length > 0) {
        result.numberCheck = true;
        result.passedChecks++;
      }
      // check special characters
      let specialLetterMatch = password.match(/[\-)(.,+@:;*'&#_\/"]/g);
      if (specialLetterMatch && specialLetterMatch.length > 0) {
        result.specialLettersCheck = true;
        result.passedChecks++;
      }
    }
    return showErrorAtStart ? result && !result.isValid() : result && !result.isValid() && field.touched;
  }

  showConfirmPasswordError(confirmPasswordField: any, newPasswordField: any, showErrorAtStart?: boolean) {
    let isInvalid = confirmPasswordField && newPasswordField && confirmPasswordField.value != null && confirmPasswordField.value !== "" && confirmPasswordField.value !== newPasswordField.value;
    return showErrorAtStart ? isInvalid : isInvalid && confirmPasswordField.touched;
  }

  showPhoneNumberError(field: any) {
    let fieldIsNotValid = field && !this.validatePhoneNumber(field.value);
    return fieldIsNotValid && (field.touched || field.dirty);
  }

  showAmountError(field: any) {
    if (field && field.value === undefined) {
      return field && !this.validateRegex("amount", field);
    }
    return field && field.value && !this.validateRegex("amount", field.value);
  }

  public showMilesError(field: any) {
    if (field && field.value === undefined) {
      return field && !this.validateRegex("miles", field);
    }
    return field && field.value && !this.validateRegex("miles", field.value);
  }

  public hasPatternError(formControl): boolean {
    let hasPatternError = false;
    if (formControl) {
      hasPatternError = this.showError(formControl, 'pattern', false);
    }
    return hasPatternError;
  }

  public hasRequiredError(formControl): boolean {
    let hasRequiredError = false;
    if (formControl) {
      hasRequiredError = this.showError(formControl, 'required', false);
    }
    return hasRequiredError;
  }

  private validateRegex(key: string, value: string): boolean {
    if (value) {
      let regex = this.regexExpressions[key];
      let isValid = regex ? regex.test(value) : false;
      return isValid;
    }
    return true;
  }
  
  private validatePhoneNumber(value: string) {
    if (value) {
      let regex = this.regexExpressions["phoneNumber"];
      let isValid = (regex ? regex.test(value) : false) && value.replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "").replace(/-/g, "") !== Constants.NoPhoneNumber;
      return isValid;
    }
    return true;
  }

  public getValidationRule(messageKey: string, validationType: string, validators: any): any {
    let messageText: string;
    this.translateService.get(messageKey).subscribe((res: string) => {
      messageText = res;
    });
    return (
      {
        message: messageText,
        rule: validationType,
        validator: validators
      });
  }

  isValidAmount(amount: string): boolean {
    if (amount) {
      let regex = new RegExp(this.regexValues.amount);
      let isValid = regex ? regex.test(amount) : false;
      return isValid;
    }
    return true;
  }
}
