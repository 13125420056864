import { TranslateService } from '@ngx-translate/core';
import { ServicingPreferenceDTO } from '../core/gateway-api';
import { Constants } from '../shared/constants';

export class NotificationModel {
  label: string;
  isVisible: boolean;
  isSelected: boolean;
  isActive: boolean;
  isEditable: boolean;
  dialogTitle: string;
  dialogSubTitle: string;
  dialogMessageActivate: string;
  dialogMessageDeactivate: string;
  dialogOptionPrimaryText: string;
  dialogOptionSecondaryText: string;
  dialogOptionPrimaryIsChecked: boolean;
  dialogOptionSecondaryIsChecked: boolean;
  dialogTooltipText: string;
  dialogValue: number;
  preference: ServicingPreferenceDTO;
  cancelBtnActivateText: string;
  submitBtnActivateText: string;
  cancelBtnDeactivateText: string;
  submitBtnDeactivateText: string;


  constructor(
    private translateService: TranslateService,
    labelKey: string,
    isVisible: boolean,
    isSelected: boolean,
    isActive: boolean,
    titleKey: string,
    subTitleKey: string,
    messageActivateKey: string,
    messageDeactivateKey: string,
    primaryEmail: string,
    secondaryEmail: string,
    isEditable: boolean,
    dialogOptionPrimaryKey: string,
    dialogOptionSecondaryKey: string,
    dialogTooltipKey: string,
    dialogValue: number,
    preference: ServicingPreferenceDTO,
    cancelBtnActivateText: string,
    submitBtnActivateText: string,
    cancelBtnDeactivateText: string,
    submitBtnDeactivateText: string) {
    this.translateService.get(labelKey).subscribe((res: string) => {
      this.label = res;
    });
    this.translateService.get(titleKey).subscribe((res: string) => {
      this.dialogTitle = res;
    });
    if (subTitleKey) {
      this.translateService.get(subTitleKey).subscribe((res: string) => {
        this.dialogSubTitle = res;
      });
    }
    this.translateService.get(messageActivateKey, { value: primaryEmail }).subscribe((res: string) => {
      this.dialogMessageActivate = res;
    });
    this.translateService.get(messageDeactivateKey).subscribe((res: string) => {
      this.dialogMessageDeactivate = res;
    });
    if (dialogOptionPrimaryKey) {
      this.translateService.get(dialogOptionPrimaryKey, { primaryEmail: primaryEmail }).subscribe((res: string) => {
        this.dialogOptionPrimaryText = res;
      });
    }
    if (dialogOptionSecondaryKey && secondaryEmail !== Constants.EMPTY) {
      this.translateService.get(dialogOptionSecondaryKey, { secondaryEmail: secondaryEmail }).subscribe((res: string) => {
        this.dialogOptionSecondaryText = res;
      });
    }
    if (dialogTooltipKey) {
      this.translateService.get(dialogTooltipKey).subscribe((res: string) => {
        this.dialogTooltipText = res;
      });
    }
    this.isVisible = isVisible;
    this.isSelected = isSelected;
    this.isActive = isActive;
    this.isEditable = isEditable;
    this.preference = preference;
    this.dialogOptionPrimaryIsChecked = true;// the primary email is always preselected
    this.dialogOptionSecondaryIsChecked = preference && preference.alternateEmailIndicator;
    this.dialogValue = dialogValue;
    if (cancelBtnActivateText) {
      this.translateService.get(cancelBtnActivateText).subscribe((res: string) => {
        this.cancelBtnActivateText = res;
      });
    }
    if (submitBtnActivateText) {
      this.translateService.get(submitBtnActivateText).subscribe((res: string) => {
        this.submitBtnActivateText = res;
      });
    }
    if (cancelBtnDeactivateText) {
      this.translateService.get(cancelBtnDeactivateText).subscribe((res: string) => {
        this.cancelBtnDeactivateText = res;
      });
    }
    if (submitBtnDeactivateText) {
      this.translateService.get(submitBtnDeactivateText).subscribe((res: string) => {
        this.submitBtnDeactivateText = res;
      });
    }
  }

}
