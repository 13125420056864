import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { RecurringPaymentHoldsModel } from '../../../_models/recurring-payment/recurring-payment-holds.model';

@Injectable()
export class RecurringPaymentHoldsActions {
  static PUSH_RECURRING_PAYMENT_HOLDS: string = "PUSH_RECURRING_PAYMENT_HOLDS";
  static REMOVE_RECURRING_PAYMENT_HOLDS: string = "REMOVE_RECURRING_PAYMENT_HOLDS";
  static CLEAR_RECURRING_PAYMENT_HOLDS: string = "CLEAR_RECURRING_PAYMENT_HOLDS";


  pushRecurringPaymentHolds(recurringPaymentHoldsModel: RecurringPaymentHoldsModel): FSA<string, any, null> {
    return {
      type: RecurringPaymentHoldsActions.PUSH_RECURRING_PAYMENT_HOLDS,
      payload: recurringPaymentHoldsModel,
      meta: null
    };
  }

  removeRecurringPaymentHolds(accountNumber: string): FSA<string, any, null> {
    return {
      type: RecurringPaymentHoldsActions.REMOVE_RECURRING_PAYMENT_HOLDS,
      payload: accountNumber,
      meta: null
    };
  }

  clearRecurringPaymentHolds(): FSA<string, any, null> {
    return {
      type: RecurringPaymentHoldsActions.CLEAR_RECURRING_PAYMENT_HOLDS,
      payload: null,
      meta: null
    };
  }

}

@Injectable()
export class RecurringPaymentHoldsReducers {
  static RecurringPaymentHoldsReducer: Reducer<RecurringPaymentHoldsModel[]> = (state: RecurringPaymentHoldsModel[] = [], action: FSA<string, any, void>) => {
    switch (action.type) {
      case RecurringPaymentHoldsActions.PUSH_RECURRING_PAYMENT_HOLDS:
        return state = [...state, action.payload];
      case RecurringPaymentHoldsActions.REMOVE_RECURRING_PAYMENT_HOLDS:
        return state = state ? state.filter(recurringPaymentHolds => recurringPaymentHolds.accountNumber !== action.payload) : [];
      case RecurringPaymentHoldsActions.CLEAR_RECURRING_PAYMENT_HOLDS:
        return state = [];
      default:
        return state;
    }
  }
}
