import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { CustomerBulletinModel } from "../../../_models/customer-bulletin.model";

@Injectable()
export class CustomerBulletinActions {
  static SET_CUSTOMER_BULLETIN: string = 'SET_CUSTOMER_BULLETIN';
  static CLEAR_CUSTOMER_BULLETIN: string = 'CLEAR_CUSTOMER_BULLETIN';

  setCustomerBulletin(customerBulletin: CustomerBulletinModel): FSA<string, CustomerBulletinModel, null> {
    return {
      type: CustomerBulletinActions.SET_CUSTOMER_BULLETIN,
      payload: customerBulletin,
      meta: null
    };
  }

  clearCustomerBulletin(): FSA<string, CustomerBulletinModel, null> {
    return {
      type: CustomerBulletinActions.CLEAR_CUSTOMER_BULLETIN,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class CustomerBulletinReducers {
  static CustomerBulletinReducer: Reducer<CustomerBulletinModel> = (state: CustomerBulletinModel = new CustomerBulletinModel(), action: FSA<string, any, void>) => {
    switch (action.type) {
      case CustomerBulletinActions.SET_CUSTOMER_BULLETIN:
        return tassign(state, action.payload);
      case CustomerBulletinActions.CLEAR_CUSTOMER_BULLETIN:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
