import { MapPaymentOption } from "../../shared/enums";

export class MapPaymentReviewDetails {

  previousMilesAllowed: number;
  milesToPurchase: number;
  totalMilesAllowed: number;
  paymentOption: MapPaymentOption;
  totalCostForMiles: number;
  monthlyCostForMiles: number;
  nextMonthlyPayment: number;
  dateEffective: Date;
  quoteId: number;
  paymentDate: Date;
  paymentSource: string;
  emailAddress: string;
  isCreditCard: boolean;
  costPerMile: number;
  confirmationNumber?: number;

  constructor(previousMilesAllowed?: number,
    milesToPurchase?: number,
    totalMilesAllowed?: number,
    paymentOption?: MapPaymentOption,
    totalCostForMiles?: number,
    monthlyCostForMiles?: number,
    nextMonthlyPayment?: number,
    dateEffective?: Date,
    paymentSource?: string,
    paymentDate?: Date,
    emailAddress?: string,
    isCreditCard?: boolean,
    quoteId?: number,
    costPerMile?: number) {

    this.previousMilesAllowed = previousMilesAllowed;
    this.milesToPurchase = milesToPurchase;
    this.totalMilesAllowed = totalMilesAllowed;
    this.paymentOption = paymentOption;
    this.totalCostForMiles = totalCostForMiles;
    this.monthlyCostForMiles = monthlyCostForMiles;
    this.nextMonthlyPayment = nextMonthlyPayment;
    this.dateEffective = dateEffective;
    this.paymentSource = paymentSource;
    this.paymentDate = paymentDate;
    this.emailAddress = emailAddress;
    this.isCreditCard = isCreditCard;
    this.quoteId = quoteId;
    this.costPerMile = costPerMile;
  }

}



