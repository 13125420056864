import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { RecurringPaymentCancel } from '../../../_models/recurring-payment/recurring-payment-cancel-model';

@Injectable()
export class CancelRecurringPaymentReviewActions {

    static SET_CANCEL_RECURRING_PAYMENT: string = 'SET_CANCEL_RECURRING_PAYMEN';
    static CLEAR_CANCEL_RECURRING_PAYMENT: string = 'CLEAR_CANCEL_RECURRING_PAYMENT';

    setCancelRecurringPayment(recurringPaymentCancel: RecurringPaymentCancel): FSA<string, RecurringPaymentCancel, null> {
        return {
            type: CancelRecurringPaymentReviewActions.SET_CANCEL_RECURRING_PAYMENT,
            payload: recurringPaymentCancel,
            meta: null
        };
    }

clearCancelRecurringPayment(): FSA <string, RecurringPaymentCancel, null > {
    return {
        type: CancelRecurringPaymentReviewActions.CLEAR_CANCEL_RECURRING_PAYMENT,
        payload: null,
        meta: null
    };
}
    
}

@Injectable()
export class CancelRecurringPaymentReducers {
    static CancelRecurringPaymentReducers: Reducer<RecurringPaymentCancel> = (state: RecurringPaymentCancel = new RecurringPaymentCancel(), action: FSA<string, RecurringPaymentCancel, void>) => {
        switch (action.type) {
            case CancelRecurringPaymentReviewActions.SET_CANCEL_RECURRING_PAYMENT:
                return tassign(state, action.payload);
            case CancelRecurringPaymentReviewActions.CLEAR_CANCEL_RECURRING_PAYMENT:
                return tassign(state, action.payload);
            default:
                return state;
        }
    }
}

