
export class HeaderModel {
    home: string;
    myGarage: string;
    myAccount: string;
    myProfile: string;
    bmwLogin: string;
    logOutMyBMW: string;
  
    constructor(home?: string,
      myGarage?: string,
      myAccount?: string,
      myProfile?: string,
      bmwLogin?: string,
      logOutMyBMW?: string) {
      this.home = home;
      this.myGarage = myGarage;
      this.myAccount = myAccount;
      this.myProfile = myProfile;
      this.bmwLogin = bmwLogin;
      this.logOutMyBMW = logOutMyBMW;
    }
  }
  
  