import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA } from 'flux-standard-action';
import { tassign } from 'tassign';
import { ContractAccountDetailDTO } from '../../../core/gateway-api';

@Injectable()
export class SelectedContractDashboardActions {
  static SET_SELECTED_CONTRACT_DASHBOARD: string = 'SET_SELECTED_CONTRACT_DASHBOARD';
  static CLEAR_SELECTED_CONTRACT_DASHBOARD: string = 'CLEAR_SELECTED_CONTRACT_DASHBOARD';

  setSelectedContractDashboard(contractAccountDetail: ContractAccountDetailDTO): FSA<string, ContractAccountDetailDTO, null> {
    return {
      type: SelectedContractDashboardActions.SET_SELECTED_CONTRACT_DASHBOARD,
      payload: contractAccountDetail,
      meta: null
    };
  }

  clearSelectedContractDashboard(): FSA<string, ContractAccountDetailDTO, null> {
    return {
      type: SelectedContractDashboardActions.CLEAR_SELECTED_CONTRACT_DASHBOARD,
      payload: null,
      meta: null
    };
  }

}

@Injectable()
export class SelectedContractDashboardReducers {
  static SelectedContractDashboardReducer: Reducer<ContractAccountDetailDTO> = (state: ContractAccountDetailDTO = new ContractAccountDetailDTO(), action: FSA<string, ContractAccountDetailDTO, void>) => {
    switch (action.type) {
      case SelectedContractDashboardActions.SET_SELECTED_CONTRACT_DASHBOARD:
        return tassign(state, action.payload);
      case SelectedContractDashboardActions.CLEAR_SELECTED_CONTRACT_DASHBOARD:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
