import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FinancialAccounts } from '../_models/payment-source.model';
import { PaymentSourceDeleteStatus } from '../_models/delete-payment-source.model';
import { CustomerFinancialAccountServiceClient, InsertFinancialAccountRequest, FinancialAccountDTO, FindFinancialAccountsRequest, FinancialAccountQueryDTO, ExpireFinancialAccountRequest } from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import * as _ from 'lodash-es';
import { ScheduledItemsActions } from './../shared/store/reducers/scheduled-items.reducers';
import { Store } from '@ngrx/store';
import { ObjectService } from '../shared/_helper-services/object.service';
import { IAppState } from "../shared/store/app.store";
import { FinancialAccountDetailModel, FinancialAccountDetailErrorType } from '../_models/financial-account-detail.model';
import { FaultCodes } from '../shared/FaultCodes';
import { PaymentSource } from '../_models/payment.model';
import { UserService } from '../shared/_helper-services/user.service';

@Injectable()
export class CustomerFinancialAccountService {

    constructor(
        private customerFinancialAccountServiceClient: CustomerFinancialAccountServiceClient,
        private fsTokenErrorHandler: FSTokenErrorHandler,
        private scheduledItemsActions: ScheduledItemsActions,
        private objectService: ObjectService,
        private userService: UserService,
        private store: Store<IAppState>) {
    }

    public insertFinancialAccount(achModelInfo: PaymentSource): Observable<FinancialAccountDetailModel> {
        let insertFinancialAccountRequest = new InsertFinancialAccountRequest();
        let financialAccountDTO = new FinancialAccountDTO();
        let customerNumber = this.userService.getCustomerNumber();
        financialAccountDTO.customerNumber = customerNumber;
        financialAccountDTO.bankId = achModelInfo.bankId;
        financialAccountDTO.financialAccountNumber = achModelInfo.financialAccountNumber;
        financialAccountDTO.displayName = achModelInfo.displayAccountNumber;
        financialAccountDTO.accountType = achModelInfo.accountType;
        !achModelInfo.retain ? financialAccountDTO.expirationDate = new Date(Date.now() - 86400000) : null;//must send a date in the past to not retain payment source
        insertFinancialAccountRequest.financialAccount = financialAccountDTO;
        return this.customerFinancialAccountServiceClient.insertFinancialAccount(insertFinancialAccountRequest)
            .pipe(mergeMap((response) => { return this.afterInsertFinancialAccountSuccess(response); })
                , catchError((error: any) => { return this.afterInsertFinancialAccountFailure(error); }));
    }

    private afterInsertFinancialAccountSuccess(response: any): Observable<FinancialAccountDetailModel> {
        let financialAccountDetail = new FinancialAccountDetailModel();
        financialAccountDetail.error = false;
        financialAccountDetail.financialAccountId = response.financialAccountId;
        return of(financialAccountDetail);
    }

    private afterInsertFinancialAccountFailure(error: any): Observable<FinancialAccountDetailModel> {
        let financialAccountDetail = new FinancialAccountDetailModel();
        financialAccountDetail.error = true;
        if (error.faultType) {
            switch (error.faultType) {
                case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_CustomerNotFound_V201109_CustomerNotFoundFault:
                    {
                        financialAccountDetail.errorType = FinancialAccountDetailErrorType.CustomerNotFound;
                        break;
                    }
                case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_CustomerIdentifierRequiredFault:
                    {
                        financialAccountDetail.errorType = FinancialAccountDetailErrorType.CustomerIdentifierRequired;
                        break;
                    }
                case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_FinancialAccountMustHaveAccountNumberFault:
                    {
                        financialAccountDetail.errorType = FinancialAccountDetailErrorType.FinancialAccountMustHaveAccountNumber;
                        break;
                    }
                default:
                    {
                        financialAccountDetail.errorType = FinancialAccountDetailErrorType.InsertACHFailed;
                        break;
                    }
            }
        }
        return of(financialAccountDetail);
    }


    public findFinancialAccounts(): Observable<any> {
        let financialAccountQueryDTO = new FinancialAccountQueryDTO();
        financialAccountQueryDTO.customerNumber = this.userService.getCustomerNumber();
        financialAccountQueryDTO.includeExpired = false;
        let findFinancialAccountsRequest = new FindFinancialAccountsRequest();
        findFinancialAccountsRequest.query = financialAccountQueryDTO;

        return this.customerFinancialAccountServiceClient.findFinancialAccounts(findFinancialAccountsRequest)
            .pipe(mergeMap((response) => { return this.afterGetFinancialAccountsPostSuccess(response); })
                , catchError((error: any) => { return this.afterGetFinancialAccountsPostFailure(error); }));
    }

    private afterGetFinancialAccountsPostSuccess(result: any): Observable<FinancialAccounts> {
        let financialAccounts = new FinancialAccounts();
        financialAccounts.error = false;
        financialAccounts.accounts = result.accounts;
        return of(financialAccounts);
    }

    private afterGetFinancialAccountsPostFailure(error: any): Observable<FinancialAccounts> {
        let financialAccounts = new FinancialAccounts();
        financialAccounts.error = true;
        this.fsTokenErrorHandler.handleFSTokenError(error);
        return of(financialAccounts);
    }

    public expireFinancialAccount(financialAccountid: string): Observable<PaymentSourceDeleteStatus> {
        let expireFinancialAccountRequest = new ExpireFinancialAccountRequest();
        expireFinancialAccountRequest.customerNumber = this.userService.getCustomerNumber();
        expireFinancialAccountRequest.financialAccountid = financialAccountid;
        return this.customerFinancialAccountServiceClient.expireFinancialAccount(expireFinancialAccountRequest)
            .pipe(mergeMap((response) => { return this.afterPostExpireFinancialAccountSuccess(response); })
                , catchError((error: any) => { return this.afterPostExpireFinancialAccountError(error); }));
    }

    private afterPostExpireFinancialAccountSuccess(result: any): Observable<PaymentSourceDeleteStatus> {
        let paymentSourceDeleteStatus = new PaymentSourceDeleteStatus();
        paymentSourceDeleteStatus.error = false;
        return of(paymentSourceDeleteStatus);
    }

    private afterPostExpireFinancialAccountError(error: any): Observable<PaymentSourceDeleteStatus> {
        let paymentSourceDeleteStatus = new PaymentSourceDeleteStatus();
        paymentSourceDeleteStatus.error = true;
        paymentSourceDeleteStatus.faultType = error.faultType;
        return of(paymentSourceDeleteStatus);
    }
}
