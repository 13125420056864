import { Injectable } from '@angular/core';
import { Constants } from '../constants';


@Injectable()
export class WindowService {


  public print(): void {
    window.print();
  }

  public scrollToTop(timeout?: boolean) {
    if (timeout) {
      setTimeout(function () {
        if (window.pageYOffset) {
          window.scroll(0, 0);
        }
      }, Constants.ScrollToTopDelay);
    }
    else {
      window.scroll(0, 0);
    }

  }

  public scrollDialogToTop(topDiv: any) {
    if (topDiv) {
      let topDivElement = topDiv.nativeElement;
      if (topDivElement) {
        setTimeout(function () {
          topDivElement.scrollIntoView(false);
        }, Constants.ScrollToTopDelay);
      }
    }
  }

}
