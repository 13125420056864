import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { FeatureToggleService } from './feature-toggle.service';

@Directive({
    selector: '[featureToggle]'
})

export class FeatureToggleDirective implements OnInit {

    private _featureName: string;
    private _featureToggle: string;

    @Input('featureToggle')
    set featureToggle(value: any) {
        this._featureName = value;
    }

   

    private featureEnabled: boolean;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private featureToggleService: FeatureToggleService
    ) { }

    ngOnInit() {
        this.featureEnabled = this.featureToggleService.isFeatureEnabled(this._featureName);
        if (this.featureEnabled) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
