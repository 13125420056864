import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { PaymentEntryComponent } from './_components/payment-entry/payment-entry.component';
import { PaymentConfirmationComponent } from './_components/payment-confirmation/payment-confirmation.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentService } from "../../_web-services/payment.service";
import { PaymentViewService } from '../../_web-services/payment-view.service';
import { CardHelperService } from '../../../_shared/shared/_helper-services/card-helper.service';
import { PaymentCardService, CardAppearanceFontsHelperService } from 'customerdigital-service-lib';
import { LoggerHelperService } from '../../shared/_helper-services/logger-helper.service';
import { DialogService, FsInputWebModule } from 'customerdigital-ui-lib';

@NgModule({
    imports: [
        CommonModule,
        PaymentRoutingModule,
        SharedModule,
        TranslateModule,
        FsInputWebModule
    ],
    exports: [PaymentEntryComponent, PaymentConfirmationComponent],
    declarations: [PaymentEntryComponent, PaymentConfirmationComponent],
    providers: [CurrencyPipe,
        PaymentService,
        PaymentViewService,
        DatePipe,
        DialogService,
        PaymentCardService,
        CardHelperService, 
        CardAppearanceFontsHelperService,
        LoggerHelperService]
})
export class PaymentModule { }
