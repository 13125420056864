import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { BaseApplicationComponent } from '../../../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { ActivityTypes } from '../../../../../../shared/enums';
import { LogSiteActivityService } from '../../../../../../_web-services/log-site-activity.service';

@Component({
  selector: 'recall-dialog',
  templateUrl: './recall-dialog.component.html',
  styleUrls: ['./recall-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RecallDialog extends BaseApplicationComponent<RecallDialog> {

  constructor(
    public dialogRef: MatDialogRef<RecallDialog>,
    private logSiteActivityService: LogSiteActivityService,
    @Inject(DOCUMENT) document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector) {
    super(injector, document);
  }

  public cancel() {
    this.dialogRef.close(null);
  }

  public continue() {
    this.logSiteActivityService.logUserActivity(this.data.customerNumber, this.data.accountNumbers, ActivityTypes.VehicleRecallContinue);
    this.store.select(state => state.EnvironmentConfig.MY_GARAGE_URL).subscribe(x => this.document.location.href = x);
  }

}
