import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from "../../shared/store/app.store";
import { RouterService, routes } from '../../shared/_helper-services/router.service';
import { GuardService } from '../../shared/_helper-services/guard.service';
import { UrlBuilderService } from '../../shared/_helper-services/url-builder.service';
import { UserService } from '../../shared/_helper-services/user.service';
import { LanguageService } from '../../shared/_helper-services/language.service';
import { Constants } from '../../shared/constants';
import { LoginService } from '../../_web-services/login.service';
import { LoginStatus, LoginErrorType } from '../../_models/login-status.model';

@Injectable()
export class FeatureToggleService {

  constructor(
    private store: Store<IAppState>,
    private routerService: RouterService,
    private guardService: GuardService,
    private urlBuilderService: UrlBuilderService,
    private languageService: LanguageService,
    private loginService: LoginService,
    private userService: UserService
    
  ) { }

  //Based on disabled features
  isFeatureEnabled(featureName: string): boolean {
    let disabled_Features: string;
    this.store.select(state => state.ApplicationConfig.DISABLED_FEATURES).subscribe(x => disabled_Features = x);
    if (JSON.parse(disabled_Features)) {
      let disableFeatures: string[] = JSON.parse(disabled_Features);

      if (disableFeatures.length > 0 && disableFeatures.indexOf(featureName) > -1) {
        return false;
      }
      else {
        return true;
      }
    }
    return true;
  }

  //Based on enabled features
  isEnabledFeature(featureName: string): boolean {
    let enabled_Features: string[];
    this.store.select(state => state.EnvironmentConfig.ENABLED_FEATURES).subscribe(x => enabled_Features = x);
    let isEnabled = enabled_Features &&
      enabled_Features.indexOf(featureName) > -1;
    return isEnabled;
  }

  isRouteEnabled(routeUrl: string): boolean {
    let urlWithoutParams = "";
    let disabled_Routes: string[];
    this.store.select(state => state.ApplicationConfig.DISABLED_ROUTES).subscribe(x => disabled_Routes = x);
    if (routeUrl.indexOf("?") > 0) {
      urlWithoutParams = routeUrl.substring(0, routeUrl.indexOf("?"));
    }
    else if (routeUrl.lastIndexOf("/") === 0) {
      urlWithoutParams = routeUrl;
    }
    else {
      urlWithoutParams = routeUrl.substring(0, routeUrl.lastIndexOf("/"));
    }
    if (disabled_Routes && disabled_Routes.length > 0 && disabled_Routes[0].includes(urlWithoutParams)) {
      return false;
    }
    else {
      return true;
    }
  }

  handleDisabledRoute(currentUrl: string, origUrl: string): void {
    let currentUrlWithoutParams = "";
    if (currentUrl.indexOf("?") > 0) {
      currentUrlWithoutParams = currentUrl.substring(0, currentUrl.indexOf("?"));
    }
    else if (currentUrl.lastIndexOf("/") === 0) {
      currentUrlWithoutParams = currentUrl;
    }
    else {
      currentUrlWithoutParams = currentUrl.substring(0, currentUrl.lastIndexOf("/"));
    }
    let authHubExternalUrl = "";
    let disabled_Routes: string[];
    this.store.select(state => state.ApplicationConfig.DISABLED_ROUTES).subscribe(x => disabled_Routes = x);
    if (disabled_Routes && disabled_Routes.length > 0 && disabled_Routes[0].includes(currentUrlWithoutParams)) {
      if (currentUrl.includes(routes.forgotpassword.toString()) || currentUrl.includes(routes.forgotpasswordchange.toString())) {  //reset password
        authHubExternalUrl = this.urlBuilderService.getAuthProviderRedirectUrl(currentUrl);
        this.guardService.guardFailed(authHubExternalUrl, true);
      }
      else if (currentUrl.includes(routes.registration.toString())) {
        authHubExternalUrl = this.urlBuilderService.getAuthProviderRegistrationUrl();
        this.guardService.guardFailed(authHubExternalUrl, true);
      }
      else if (currentUrl.includes(routes.login.toString())) {
        if (!this.userService.isLoggedIn()) {
          this.urlBuilderService.getIdToken()
            .then((token: string) => {
              const hasActiveToken: boolean = token ? token != Constants.PLACEHOLDER_TOKEN : false;
              hasActiveToken ? this.ssoTokenLogin(currentUrl, token, origUrl) : this.redirectToLogin(origUrl);
            })
            .catch(() => {
              this.routerService.navigateToServiceError(origUrl);
            })
        }
        else {
          this.routerService.navigateToHome();
        }
      }
      else {
        this.routerService.navigateToPathNotFound();
      }

    }
  }

  handleConnectedAppWebViewSso(currentUrl: string, origUrl: string): void {
    this.routerService.navigateToTarget("sso?granttype=bearer_token&state=" + encodeURI(origUrl));
  }

  private redirectToLogin(originalUrl: string, token?: string) {
    const authHubExternalUrl = this.urlBuilderService.getAuthProviderLoginRedirectUrl(originalUrl, token);
    this.guardService.guardFailed(authHubExternalUrl, true);
  }

  public setLanguage(state: any): void {
    let language = state._root.value.queryParams[Constants.LanguageParameterName];
    if (language !== undefined)
      this.languageService.setCurrentLanguage(language);
  }

  private ssoTokenLogin(currentUrl: string, token: string, originalUrl) {
    this.loginService.ssoLogin(token, Constants.SsoIdToken, currentUrl)
      .subscribe(loginStatus => { this.postAuthentication(loginStatus, originalUrl); });
  }

  postAuthentication(loginStatus: LoginStatus, state: string) {
    if (loginStatus.error == true) {
      this.userService.clearSession();
      this.userService.clearOnBoardingSession();
      this.routerService.navigateToErrorPage();

    } else {
      let decodedTargetUrl = decodeURIComponent(state);
      this.routerService.navigateToTarget(decodedTargetUrl);
    }
  }

}
