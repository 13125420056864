import { BridgeServiceConfig } from "bridgeservices-lib";
import { AnalyticsIdOverrideName } from "../shared/enums";

export class EnvironmentConfig {
  SPINNER_THRESHOLD: number;
  ENVIRONMENT_CODE: string;
  PRODUCTION: boolean;
  GATEWAY_API_BASE_URL: string;
  MY_BMW_WEB_URL: string;
  MY_BMW_URL: string;
  COSY_URL: string;
  SAFETY_RECALL_LINK: string;
  CONSOLE_LOGGING_ENABLED: boolean;
  APP_LOGGING_ENABLED: boolean;
  IDLE_SESSION: number;
  TIME_OUT_SESSION: number;
  TWO_FACTOR_AUTH_COOKIE_EXPIRATION_DAYS: number;
  PRIVACY_POLICY_URL: string;
  REFRESH_BEARER_TOKEN_INTERVAL_IN_SEC: number;
  LOGOUT_FOR_BEARER_TOKEN_EXPIRY_INTERVAL_IN_MS: number;
  SMC_ATTACHMENT_SIZE_LIMIT: number;
  FS_EMAIL_ADDRESS: string;
  MOBILE_WALLET_APPLE_URL?: string;
  MOBILE_WALLET_ANDROID_URL?: string;
  MOBILE_WALLET_APPLE_IMG_SRC?: string;
  MOBILE_WALLET_ANDROID_IMG_SRC?: string;
  MOBILE_WALLET_SOURCE?: string;
  MY_FS_URL: string;
  DEEPLINK_ENABLED: boolean;
  SSO_REDIRECT_URI: string;
  AUTH_HUB_DOMAIN?: string;
  AUTH_HUB_SSO_URL: string;
  AUTH_HUB_REGISTER_URL?: string;
  NGD_URL: string;
  MY_GARAGE_URL: string;
  SSO_LOGOUT_REDIRECT_URI: string;
  AUTH_HUB_MANAGE_SETTINGS_REDIRECT_URI: string;
  CRITICAL_MASS_HEADER_ENABLED?: boolean;
  DISABLED_FEATURES?: string[];
  ENABLED_FEATURES: string[];
  MAP_RETRY_COUNT: number;
  AUTH_HUB_REDIRECT_URI: string;
  CHATBOT: ChatbotConfig;
  CONNECTED_DRIVE_URL?: string; 
  CONNECTED_DRIVE_WEB_URL?: string;
  DEALER_LOCATOR_URL?: string;
  BMW_CONTACTUS_URL?: string;
  ANALYTICS_CONFIG: AnalyticsConfig;
  MY_BMW_CREDIT_CARD_URL?: string;
  CREDIT_CARD_SSO_LOGIN_URL?: string;
  CAPTCHA_SITE_KEY?: string;
  OMC?: boolean;
  SSO: boolean;
  NGD_BASE_URL: string;
  AUTH_PROVIDER: number;
  GCDM_ONE_CONFIG: GcdmOneConfig;
  LEASE_LINK?: LeaseLink;
  CA_DISABLED_FEATURES?: string[];
  BRIDGE_SERVICE_CONFIG?: BridgeServiceConfig;
  MY_BMW_CREDIT_CARD_URL_For_Mileage_Management: string;
  EPAAS_SCRIPT_URL?: string;
  TOP_HEADER_NAVIGATION_CONFIG?: TopHeaderNavigationConfig;
  FOOTER_NAVIGATION_CONFIG?: FooterNavigationConfig;
  PROFILE_URL?: string;
  MYPROFILE_URL: string;
  STRIPE_PK?: string;
  
}
export class LinkList {
  TYPE: string;
  LINK: string;
  ENGLSIH_DESTINATION_URL: string;
  FRENCH_DESTINATION_URL: string;
}
export class LeaseLink {
  LINK_LIST: LinkList[]
}

export interface AnalyticsConfig {
  BASE_DOMAIN: string;
  CUSTOM_LINK_TRACKING: boolean;
  DATALAYER_NAME: string;
  DEBUG: boolean;
  EMBED_SCRIPT_URL: string;
  ID_OVERRIDE?: AnalyticsIdOverrideName;
  IS_MOBILE?: boolean;
  MC_ORG_ID?: string;
  SUB_DOMAIN: string;
}

export class ChatbotConfig {
  SCRIPT_URL?: string;
  APIURL?: string;
  APIKEY?: string;
}

export class GcdmOneConfig {
  CLIENT: string;
  BRAND: string;
  COUNTRY: string;
  LANGUAGE: string;
  SCOPE: string;
  RESPONSETYPE: string;
  LOGINURL: string;
  GRANTTYPE: string;
  TOKENPROVIDER: string;
  LOGOUTURL: string;
  PROFILEURL?: string;
}

export class TopHeaderNavigationConfig {
  HOME_URL: string;
  MY_ACCOUNT_URL: string;
  MY_PROFILE_URL: string;
  LOG_OUT_MY_BMW_URL: string;
}

export class FooterNavigationConfig {
  TERMS_URL: string;
  FAQ_URL: string;
  CONTACT_US_URL: string;
  SCRA_URL: string;
}
