import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from "../store/app.store";
import { StorageType } from '../../_models/storagetype.model';
import { StorageService } from 'customerdigital-service-lib';
import { CookieService } from 'ngx-cookie-service';
import { EpaasHelperService } from './epaas-helper.service';
import { Constants } from '../constants';

@Injectable()
export class StorageHelperService {

  constructor(
    private storageService: StorageService,
    private epaasHelperService: EpaasHelperService,
    private cookieService: CookieService,
    private store: Store<IAppState>) {
  }

  get ePaasEnabled(): boolean {
    return this.epaasHelperService.ePaasEnabled();
  }

  public isMFACookieKey(key: string): boolean {
    return (key.includes(`-${Constants.MFA}`) || key.includes(`-${Constants.FS}`));
  }

  public getItem(key: string, type: StorageType): string {
    return this.ePaasEnabled && !this.isMFACookieKey(key) ?
      this.epaasHelperService.getItem(key) :
      this.storageService.getItem(key, type);
  }

  public setItem(key: string, data: string, type: StorageType) {
    this.ePaasEnabled && !this.isMFACookieKey(key) ?
      this.epaasHelperService.setItem(key, data) :
      this.storageService.setItem(key, data, type);
  }

  public removeItem(key: string, type: StorageType): void {
    this.ePaasEnabled && !this.isMFACookieKey(key) ?
      this.epaasHelperService.removeItem(key) :
      this.storageService.removeItem(key, type);
  }

  public setCookie(key: string, value: string, expires: number | Date, path = "/", domain: string = window.location.hostname) {
    let secure: boolean;
    this.store.select(state => state.EnvironmentConfig.PRODUCTION).subscribe(x => secure = x);
    this.ePaasEnabled && !this.isMFACookieKey(key) ?
      this.epaasHelperService.setItem(key, value) :
      this.cookieService.set(key, value, expires, path, domain, secure);
  }

  public getCookie(key: string): string {
    return this.ePaasEnabled && !this.isMFACookieKey(key) ?
      this.epaasHelperService.getItem(key) :
      this.cookieService.get(key);
  }

  public deleteCookie(key: string, path?: string, domain?: string, secure?: boolean) {
    this.ePaasEnabled && !this.isMFACookieKey(key) ?
      this.epaasHelperService.removeItem(key) :
      this.cookieService.delete(key, path, domain, secure);
  }

  public checkCookie(key: string): boolean {
    return this.ePaasEnabled && !this.isMFACookieKey(key) ?
      Boolean(this.epaasHelperService.getItem(key)) :
      this.cookieService.check(key);
  }

  public getFSCookieKey(gcid: string): string {
    return `${gcid}-${Constants.FS}`;
  }

  public getMFACookieKey(gcid: string): string {
    return `${gcid}-${Constants.MFA}`;
  }

}


