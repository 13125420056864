import { BaseApplicationComponent } from './../../../_shared/core/basecomponent/base.component';
import { Component, ViewEncapsulation, Injector, Inject, HostBinding } from '@angular/core';
import { UserService } from './../../../_shared/shared/_helper-services/user.service';
import { IdleService } from './../../../_shared/shared/_helper-services/idle.service';
import { DOCUMENT } from '@angular/common';
import { Constants } from './../../../_shared/shared/constants';
import * as _ from 'lodash';
import { routes } from './../../../_shared/shared/_helper-services/router.service';
import { MicroFrontendHelperService } from 'projects/_shared/shared/_helper-services/mfe-helper.service';
import { EpaasHelperService } from 'projects/_shared/shared/_helper-services/epaas-helper.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { '[class]': 'customClass' },
  encapsulation: ViewEncapsulation.None
})
export class AppComponentMiniCaNgd extends BaseApplicationComponent<AppComponentMiniCaNgd>{
  showSpinner: boolean;
  customClass: string = Constants.ApplicationRootCustomClass;
  theme: string = '';

  @HostBinding('class.mini-theme') isMini() {
    return this.theme == 'mini-theme';
  };

  constructor(injector: Injector,
    private userService: UserService,
    private idleService: IdleService,
    private mfeHelper: MicroFrontendHelperService,
    private epaasHelperService: EpaasHelperService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  ngOnInit(): void {
    let clientAppID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAppID = x);
    super.getLoggerForComponent(this, clientAppID, true)
      .then(rtrn => {
        this.logger = rtrn;
        this.initIdleIfAuthorized();
      });
    this.mfeHelper.InitEventBus();
    this.translateService.onDefaultLangChange.pipe(first()).subscribe((event: LangChangeEvent) => {
      this.epaasHelperService.initializeEpaas();
    });
    this.theme = 'mini-theme'; // set theme based on URL here
  }

  private initIdleIfAuthorized() {
    if (this.userService.isAuthenticated()) {
      this.idleService.initIdle();
    }
  }

  ngOnDestroy(): void {
    let clientAppID: any;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAppID = x);
    super.getLoggerForComponent(this, clientAppID, true)
      .then(rtrn => {
        this.logFactory.loggerDestroyed(rtrn);
      });
  }
}
