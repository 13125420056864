import { ContractAccountDetailDTOCustomerRoleType, ContractAccountDetailDTOPortfolioCategoryCode } from '../../core/gateway-api';

export class ContactInformation {
  accountNumber: string;
  vehicleYearAndModel: string;
  mailingAddress: string;
  vehicleRegistrationAddress: string;
  primaryMobilePhone: string;
  secondaryMobilePhone: string;
  homePhone: string;
  workPhone: string;
  showEditBtn: boolean;
  isUpdated: boolean;
  isLease: boolean;
  isTerminated: boolean;
  shouldExpand: boolean;
  customerRoleType: ContractAccountDetailDTOCustomerRoleType;
  portfolioCategoryCode: ContractAccountDetailDTOPortfolioCategoryCode;
}

export class UpdatePhoneNumbers {
  error: boolean;
  faultType: string;
  errorDesc: string;
}
