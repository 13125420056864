import { ServicingPreferenceDTO } from '../core/gateway-api';


export class ServicingPreferencesStatus {
    preferences: ServicingPreferenceDTO[];
    isEsignAccepted: boolean;
    error: boolean;
    faultType: string;
}



