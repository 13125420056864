import { Injectable } from '@angular/core';
import { Constants } from '../constants';

@Injectable()
export class TooltipService {

  public innerWidth: number;
  public position: string;
  public innerHeight: number;
  public setTooltipLeft(window: any) {
    this.innerWidth = window.innerWidth;
    return this.position = this.innerWidth < Constants.WindowWidthBreakpoint
      ? Constants.LeftString
      : Constants.RightString;
  }

  public setTooltipRight(window: any) {
    this.innerWidth = window.innerWidth;
    return this.position = this.innerWidth < Constants.WindowWidthBreakpoint ? Constants.RightString : Constants.LeftString;
  }

}
