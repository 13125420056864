import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { MessageSubjectClassificationDTO } from '../../../core/gateway-api';

@Injectable()
export class SubjectTopicsActions
{
  static SET_SUBJECT_TOPICS: string = 'SET_SUBJECT_TOPICS';
  static CLEAR_SUBJECT_TOPICS: string = 'CLEAR_SUBJECT_TOPICS';

  setSubjectTopics(subjectTopics: MessageSubjectClassificationDTO[]): FSA<string, MessageSubjectClassificationDTO[], null>{
    return {
      type: SubjectTopicsActions.SET_SUBJECT_TOPICS,
      payload: subjectTopics,
      meta: null
    };
  }

  clearSubjectTopics(): FSA<string, any, null> {
    return {
      type: SubjectTopicsActions.CLEAR_SUBJECT_TOPICS,
      payload: null,
      meta: null
    };
  }

 
}

@Injectable()
export class SubjectTopicsReducers
{
    static SubjectTopicsReducer: Reducer<MessageSubjectClassificationDTO[]> = (state: MessageSubjectClassificationDTO[] = [], action: FSA<string, MessageSubjectClassificationDTO[], void>) => {
    switch(action.type){
        case SubjectTopicsActions.SET_SUBJECT_TOPICS:
        return tassign(state, action.payload);
      case SubjectTopicsActions.CLEAR_SUBJECT_TOPICS:
        return state = [];
      default:
          return state;
    }
  }
}
