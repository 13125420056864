<div class="terms-wrapper" role="main">
    <div class="terms-container">
        <div *ngIf="termsLoadFailed" class="row">
            <fs-text type="error" >{{errorMessage}}</fs-text>
        </div>
        <h1>{{headTitle}}</h1>
        <div class="terms-content-container">
            <p class="terms-content" [innerHTML]="termsContentHtml"></p>
        </div>
        <div class="terms-checkbox">
            <mat-checkbox [checked]="checked" (change)="onChecked($event)">{{ checkboxLabel }} <fs-anchor (onSelect)="navigateToTermsView()" [anchorText]="checkboxLink"></fs-anchor></mat-checkbox>
            <div *ngIf="showError" id="acceptedRequiredError" >
                <fs-text type="error" >{{checkboxErrorMessage}}</fs-text>
            </div>
        </div>
        <div class="terms-action-buttons action-buttons">
            <fs-button componentId="" text="{{secondaryBtnText}}" [primary]="false" ariaLabel="{{secondaryBtnAriaLabel}}" analyticsEvent ="{{secondaryBtnAnalyticsEvent}}" (onClick)="cancelTerms()" class="terms-buttons"></fs-button>
            <fs-button componentId="" text="{{primaryBtnText}}" [primary]="true" ariaLabel="{{primaryBtnAriaLabel}}" analyticsEvent ="{{secondaryBtnAnalyticsEvent}}" [state]="termsAccepted" (onClick)="continueTerms()" class="terms-buttons"></fs-button>
        </div>
    </div>
</div>