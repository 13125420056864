//Modules
import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { SharedRoutingModule } from './shared-routing.module';

//Components
import { PdfLinkComponent } from "./_components/pdf-link/pdf-link.component";
import { NavigationComponent } from "./_components/navigation/navigation.component";
import { ContractHeaderComponent } from './_components/contract-header/contract-header.component';
import { ContractHeaderAmountDateDueComponent } from './_components/contract-header-amount-date-due/contract-header-amount-date-due.component';
import { ContractHeaderAmountDateDueCanComponent } from './_components/contract-header-amount-date-due/contract-header-amount-date-due.component.can';
import { ContractHeaderChangeAccountComponent } from './_components/contract-header-change-account/contract-header-change-account.component';
import { SpinnerComponent } from './_components/spinner/spinner.component';
import { HeaderCMComponent } from './_components/header-cm/header-cm.component';
import { HeaderComponent } from './_components/header/header.component';
import { HeaderMiniComponent } from './_components/header-mini/header-mini.component';
import { FooterComponent } from './_components/footer/footer.component';
import { FooterMiniComponent } from './_components/footer-mini/footer-mini.component';
import { ContractImageComponent } from './_components/contract-image/contract-image.component';
import { TitleComponent } from './_components/title/title.component';
import { AccordionComponent } from './_components/accordion/accordion.component';
import { SectionComponent } from './_components/section/section.component';
import { TitleParagraphComponent } from './_components/title-paragraph/title-paragraph.component';
import { JumbotronComponent } from './_components/jumbotron/jumbotron.component';
import { AccountDetailItemComponent } from './_components/account-detail-item/account-detail-item.component';
import { Base64BtnComponent } from './_components/base-64-btn/base-64-btn.component';
import { UploadAttachmentComponent } from './_components/upload-attachment/upload-attachment.component';
import { UserConsentDialog } from './_components/dialogs/user-consent-dialog/user-consent-dialog.component';
import { VehicleServiceDialog } from './_components/dialogs/vehicle-service-dialog/vehicle-service-dialog.component';
import { SubNavHeaderComponent } from './_components/sub-nav-header/sub-nav-header.component';
import { EditEmailDialog } from './../feature/my-profile/_components/edit-email/edit-email-dialog.component';
import { EditPhoneNumbersDialogComponent } from './../feature/my-profile/_components/contact-information/_components/edit-phone-numbers/edit-phone-numbers-dialog.component';
import { DeleteMsgComponentDialog } from './../feature/secure-message-center/_components/delete-msg/delete-msg-dialog.component';
import { DiscardMsgDialog } from './../feature/secure-message-center/_components/discard-msg/discard-msg-dialog.component';
import { ChangeContractDialog } from './_components/dialogs/change-contract-dialog/change-contract-dialog.component';
import { UserInactivityDialog } from './_components/dialogs/user-inactivity-dialog/user-inactivity-dialog.component';
import { UserInactivityDialogButtons } from './_components/dialogs/user-inactivity-dialog-buttons/user-inactivity-dialog-buttons.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HeaderImageComponent } from './_components/header-image/header-image.component';
import { LabelToggleComponent } from './_components/label-toggle/label-toggle.component';
import { PromotionComponent } from './_components/promotion/promotion.component';
import { CreditCardBannerComponent } from './_components/banner/credit-card-banner.component';
import { BreadcrumbComponent } from './_components/breadcrumb/breadcrumb.component';
import { ManagePaymentSourcesComponent } from './../feature/my-profile/_components/manage-payment-sources/manage-payment-sources.component';
import { ContactInformationComponent } from '../feature/my-profile/_components/contact-information/contact-information.component';
import { MyProfileLeftNavComponent } from '../feature/my-profile/_components/my-profile-left-nav/my-profile-left-nav.component';
import { EasyPayEnrollmentDialog } from './_components/dialogs/easy-pay-enrollment-dialog/easy-pay-enrollment-dialog.component';
import { OneTimePaymentAuthorizationDialog } from './_components/dialogs/one-time-payment-authorization-dialog/one-time-payment-authorization-dialog.component';
import { ExtendLeaseReviewCanDialog } from './_components/dialogs/extend-lease-review-canada-dialog/extend-lease-review-can-dialog.component';
//Web Services
import { CashAccountViewService } from './../_web-services/cash-account-view.service';
import { ContractualPayoffService } from './../_web-services/contractual-payoff.service';
import { PaymentViewService } from '../_web-services/payment-view.service';
import { MessageCenterService } from '../_web-services/message-center.service';
import { AccountProfileManagementService } from '../_web-services/account-profile-management.service';
import { AmortizationService } from '../_web-services/amortization.service';
import { PartnerIntegrationService } from '../_web-services/partner-integration.service';
import { PreInspectionDocumentPdfService } from '../_web-services/preInspectionDocumentPdf.service';
import { RepairReceiptUploadService } from '../_web-services/repair-receipt-upload.service';

//Helper Services
import { AnalyticsService } from './_helper-services/analytics.service';
import { AccountInfoService } from './_helper-services/account-info.service';
import { MileageAdjustmentHelperService } from '../shared/_helper-services/mileage-adjustment-helper.service';
import { EventService } from './_helper-services/event.service';
import { WindowService } from './_helper-services/window.service';
import { DownloadPdfService } from './_helper-services/download-pdf.service';
import { MaskService } from './_helper-services/mask.service';
import { LogErrorService } from './_helper-services/log-error.service';
import { PipeService } from './_helper-services/pipe.service';
import { ValidatorService } from "../_validators/validator.service";
import { FilterService } from './_helper-services/filter.service';
import { FileLoaderService } from './_helper-services/file-loader.service';
import { RouterService } from './_helper-services/router.service';
import { PaymentHelperService } from './_helper-services/payment-helper.service';
import { MessageService } from './_helper-services/message-helper.service';
import { NotificationsHelperService } from './_helper-services/notifications-helper.service';
import { TaxJurisdictionHelperService } from './_helper-services/tax-jurisdiction-helper.service';
import { NavigationService } from './_helper-services/navigation.service';
import { UrlBuilderService } from './_helper-services/url-builder.service';
import { AutoEnrollService } from './_helper-services/auto-enroll.service';
import { ModuleOnBoardingHelperService } from './_helper-services/module-onboarding-helper.service';
import { RepairReceiptHelperService } from './_helper-services/repair-receipt-helper.service';
import { AddChequingAccountHelperService } from './_helper-services/add-chequing-account-helper.service';
import { UploadAttachmentService } from './_helper-services/upload-attachment.service';
import { FrenchDateAdapterService } from './_helper-services/french-date-adapter.service';
import { LoadExternalFileService, ChatBotLibService } from 'customerdigital-service-lib';

//Service Clients
import {
  NoteServiceClient,
  PartnerContactServiceClient,
  ContractualPayoffServiceClient,
  PaymentViewServiceClient,
  HelpServiceClient,
  MessageCenterServiceClient,
  AccountProfileManagementServiceClient,
  AmortizationServiceClient,
  BotIntegrationServiceClient,
  PartnerIntegrationServiceClient,
  BmwCardServiceClient,
  EndOfTermServiceClient,
  VehicleShadowServiceClient,
  OfferServiceClient
} from './../core/gateway-api';

//Mappers
import { NavigationMapper } from "./../_mappers/navigation.mapper";
//Directives
import { AutoFocusDirective } from '../_directives/auto-focus.directive';
import { ResizeExpansionPanelsDirective } from '../_directives/resize-expansion-panels.directive';
import { CurrencyMaskDirective } from "../_directives/currency-mask.directive";
import { ScrollToHereDirective } from "../_directives/scroll-to-here.directive";
import { ScrollTopDirective } from "../_directives/scroll-top.directive";
import { ScrollToViewDirective } from "../_directives/scroll-to-view.directive"
import { AccountNumberMaskDirective } from "../_directives/account-number-mask.directive";
import { AnalyticsErrorDirective } from "../_directives/analytics-error.directive";
import { NumericValueDirective } from "../_directives/numeric-value.directive";
import { LimitInputTextDirective } from "../_directives/limit-input-text.directive";
//Pipes
import { ParensToMinusSignPipe } from '../_pipes/parensToMinusSign.pipe';
import { MinusSignToParensPipe } from '../_pipes/minus-sign-to-parens.pipe';
import { TruncatePipe } from '../_pipes/truncate.pipe';
import { AccountNumberMaskPipe } from "../_pipes/account-number-mask.pipe";

//Resolvers
import { ContractsResolve } from "../_resolvers/contracts.resolve";
import { ContactResolve } from "../_resolvers/contact.resolve";
import { SortedFilteredContractsResolve } from "../_resolvers/sorted-filtered-contracts.resolve";
import { V2FeatureIndicatorResolve } from '../_resolvers/v2-feature-indicator.resolve';
import { GlobalNavigationService } from './_helper-services/global-navigation.service';
import { FeatureToggleModule } from '../feature/feature-toggle/feature-toggle.module';
import { LanguageService } from './_helper-services/language.service';
import { BotIntegrationService } from '../_web-services/bot-integration.service';
import { LeaseExtensionHelperService } from './_helper-services/lease-extension-helper.service';
import { FileDownloadService } from './_helper-services/file-download.service';
import { ErrorMessagingService } from './_helper-services/error-messaging.service';
import { SMCCreateMsgComponent } from '../feature/secure-message-center/_components/shared/create-msg/create-msg.component';
import { ProgressBarSectionComponent } from '../feature/dashboard/_components/account-activity/_components/progress-bar-section/progress-bar-section.component';
import { PayoffTitleComponent } from '../feature/dashboard/_components/account-activity/_components/payoff-title/payoff-title.component';
import { BulletinBoxComponent } from '../feature/dashboard/_components/bulletin-box/bulletin-box.component';
import { PaymentComponent } from "../feature/dashboard/_components/overview/_components/payment/payment.component";
import { DashboardSubtitleComponent } from "../feature/dashboard/_components/shared/sub-title/sub-title.component";
import { DashboardTitleComponent } from "../feature/dashboard/_components/shared/title/title.component";
import { HelpCenterItemComponent } from '../feature/dashboard/_components/account-activity/_components/help-center-item/help-center-item.component';

import { ConfirmationNumberPipe } from '../_pipes/confirmation-number.pipe';
import { PreviousRouteService } from './_helper-services/previous-route.service';
import { BmwCardService } from '../_web-services/bmw-card.service';
import { EasyPayEnrollmentHelperService } from './_helper-services/easy-pay-enrollment-helper.service';

//Common Actions
import { SelectedContractDashboardActions } from './store/reducers/selected-contract-dashboard.reducers';
import { VehicleReturnInspectionActions } from './store/reducers/vehicle-inspection.reducer';
import { HasLoggedInActions } from './store/reducers/has-logged-in.reducer';
import { RecurringPaymentHoldsActions } from './store/reducers/recurring-payment-holds.reducer';
import { PreviousUrlReducers, PreviousUrlActions } from './store/reducers/previous-url.reducer';
import { VehicleReturnActions } from './store/reducers/vehicle-return-estimate.reducer';
import { CardHolderActions } from './store/reducers/card-holder.reducer';
import { AddChequingAccountActions } from './store/reducers/add-chequing-account.reducer';
import { RepairReceiptsActions } from './store/reducers/repair-receipts.reducer';
import { BulletinBoxDialog } from '../feature/dashboard/_components/bulletin-box/_components/bulletin-box-dialog/bulletin-box-dialog.component';
import { AuthhubUrlBuilderService } from './_helper-services/authhub-url-builder.service';
import { GcdmOneLoginUrlBuilderService } from './_helper-services/gcdm-one-login-url-builder-service';
import { SsoService } from './_helper-services/_component-helper-services/sso-helper.service';
import { VehicleShadowService } from '../_web-services/vehicle-shadow.service';
import { VehicleShadowDataActions } from './store/reducers/vehicle-shadow-data.reducer';
import { CustomerIntentDialog } from './_components/dialogs/customer-intent-dialog/customer-intent-dialog.component';
import { CustomerIntentService } from './_helper-services/customer-intent.service';
import { OfferService } from '../_web-services/offer.service';
import { AccountIncentiveActions } from './store/reducers/account-incentive.reducer';
import { OffersHelperService } from './_helper-services/offers-helper.service';
import { LeaseEndNavigationService } from './_helper-services/lease-end-navigation.service';
import { EpaasService, EventBusService, StorageService } from 'customerdigital-service-lib';
import { StorageHelperService } from './_helper-services/storage-helper.service';
import { EpaasHelperService } from './_helper-services/epaas-helper.service';
import { AdobeAnalyticsActions } from './store/reducers/adobe-analytics.reducer';
import { FsHeaderWebModule } from 'customerdigital-ui-lib';
import { IsEpassInitializedActions } from './store/reducers/is-epaas-initialized.reducer';
import { CannotAuthenticateActions } from './store/reducers/cannot-authenticate.reducer';

@NgModule({
    imports: [
        SharedRoutingModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatInputModule,
        MatDialogModule,
        MatProgressBarModule,
        FeatureToggleModule,
        MatIconModule,
        FsHeaderWebModule
    ],
    declarations: [
        SpinnerComponent,
        HeaderCMComponent,
        HeaderComponent,
        HeaderMiniComponent,
        FooterComponent,
        FooterMiniComponent,
        ContractImageComponent,
        AutoFocusDirective,
        ResizeExpansionPanelsDirective,
        ParensToMinusSignPipe,
        MinusSignToParensPipe,
        ConfirmationNumberPipe,
        TruncatePipe,
        AccountNumberMaskPipe,
        AccountNumberMaskDirective,
        AnalyticsErrorDirective,
        CurrencyMaskDirective,
        ScrollToHereDirective,
        ScrollToViewDirective,
        NumericValueDirective,
        LimitInputTextDirective,
        ContractHeaderComponent,
        ContractHeaderAmountDateDueComponent,
        ContractHeaderAmountDateDueCanComponent,
        ContractHeaderChangeAccountComponent,
        PdfLinkComponent,
        AccountDetailItemComponent,
        TitleComponent,
        AccordionComponent,
        SectionComponent,
        TitleParagraphComponent,
        JumbotronComponent,
        NavigationComponent,
        Base64BtnComponent,
        UploadAttachmentComponent,
        UserConsentDialog,
        VehicleServiceDialog,
        BulletinBoxDialog,
        SubNavHeaderComponent,
        LabelToggleComponent,
        HeaderImageComponent,
        ScrollTopDirective,
        PromotionComponent,
        EditEmailDialog,
        EditPhoneNumbersDialogComponent,
        DiscardMsgDialog,
        DeleteMsgComponentDialog,
        ChangeContractDialog,
        UserInactivityDialog,
        UserInactivityDialogButtons,
        BreadcrumbComponent,
        SMCCreateMsgComponent,
        CreditCardBannerComponent,
        ManagePaymentSourcesComponent,
        ContactInformationComponent,
        MyProfileLeftNavComponent,
        ProgressBarSectionComponent,
        PayoffTitleComponent,
        BulletinBoxComponent,
        PaymentComponent,
        DashboardSubtitleComponent,
        DashboardTitleComponent,
        HelpCenterItemComponent,
        EasyPayEnrollmentDialog,
        CustomerIntentDialog,
        OneTimePaymentAuthorizationDialog,
        ExtendLeaseReviewCanDialog
    ],
    exports: [
        SpinnerComponent,
        CommonModule,
        FormsModule,
        HeaderCMComponent,
        HeaderComponent,
        HeaderMiniComponent,
        FooterComponent,
        FooterMiniComponent,
        ContractImageComponent,
        AutoFocusDirective,
        ResizeExpansionPanelsDirective,
        ParensToMinusSignPipe,
        MinusSignToParensPipe,
        ConfirmationNumberPipe,
        TruncatePipe,
        AccountNumberMaskPipe,
        AccountNumberMaskDirective,
        AnalyticsErrorDirective,
        CurrencyMaskDirective,
        ScrollToHereDirective,
        ScrollToViewDirective,
        NumericValueDirective,
        LimitInputTextDirective,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatInputModule,
        MatDialogModule,
        BulletinBoxDialog,
        MatProgressBarModule,
        ContractHeaderComponent,
        ContractHeaderChangeAccountComponent,
        ContractHeaderAmountDateDueComponent,
        ContractHeaderAmountDateDueCanComponent,
        PdfLinkComponent,
        AccountDetailItemComponent,
        TitleComponent,
        AccordionComponent,
        SectionComponent,
        TitleParagraphComponent,
        JumbotronComponent,
        NavigationComponent,
        TranslateModule,
        Base64BtnComponent,
        UploadAttachmentComponent,
        LabelToggleComponent,
        HeaderImageComponent,
        MatMenuModule,
        ScrollTopDirective,
        PromotionComponent,
        EditEmailDialog,
        EditPhoneNumbersDialogComponent,
        DiscardMsgDialog,
        DeleteMsgComponentDialog,
        BreadcrumbComponent,
        SMCCreateMsgComponent,
        CreditCardBannerComponent,
        ManagePaymentSourcesComponent,
        ContactInformationComponent,
        MyProfileLeftNavComponent,
        ProgressBarSectionComponent,
        PayoffTitleComponent,
        BulletinBoxComponent,
        PaymentComponent,
        DashboardSubtitleComponent,
        DashboardTitleComponent,
        HelpCenterItemComponent
    ],
    providers: [
        { provide: DateAdapter, useClass: FrenchDateAdapterService },
        ValidatorService,
        MaskService,
        LogErrorService,
        PipeService,
        PaymentHelperService,
        LeaseEndNavigationService,
        MessageService,
        RouterService,
        FilterService,
        FileLoaderService,
        FileDownloadService,
        WindowService,
        DownloadPdfService,
        AutoEnrollService,
        NoteServiceClient,
        PartnerContactServiceClient,
        ContractualPayoffServiceClient,
        PaymentViewServiceClient,
        CashAccountViewService,
        AccountInfoService,
        MileageAdjustmentHelperService,
        AddChequingAccountHelperService,
        ContractualPayoffService,
        PaymentViewService,
        NavigationMapper,
        NavigationService,
        HelpServiceClient,
        EventService,
        MessageCenterServiceClient,
        AccountProfileManagementServiceClient,
        AccountProfileManagementService,
        MessageCenterService,
        NotificationsHelperService,
        LeaseExtensionHelperService,
        RepairReceiptHelperService,
        TaxJurisdictionHelperService,
        CookieService,
        UrlBuilderService,
        AmortizationService,
        AmortizationServiceClient,
        GlobalNavigationService,
        LanguageService,
        ModuleOnBoardingHelperService,
        BotIntegrationServiceClient,
        BotIntegrationService,
        LoadExternalFileService,
        ChatBotLibService,
        ErrorMessagingService,
        PartnerIntegrationService,
        PartnerIntegrationServiceClient,
        PreInspectionDocumentPdfService,
        RepairReceiptUploadService,
        SelectedContractDashboardActions,
        VehicleReturnInspectionActions,
        HasLoggedInActions,
        RecurringPaymentHoldsActions,
        PreviousRouteService,
        PreviousUrlActions,
        PreviousUrlReducers,
        VehicleReturnActions,
        CardHolderActions,
        AddChequingAccountActions,
        RepairReceiptsActions,
        BmwCardService,
        BmwCardServiceClient,
        UploadAttachmentService,
        EndOfTermServiceClient,
        AnalyticsService,
        AuthhubUrlBuilderService,
        GcdmOneLoginUrlBuilderService,
        SsoService, VehicleShadowService, VehicleShadowServiceClient, VehicleShadowDataActions,
        EasyPayEnrollmentHelperService,
        CustomerIntentService,
        OffersHelperService,
        OfferService,
        OfferServiceClient,
        AccountIncentiveActions,
        DeviceDetectorService,
        EpaasService,
        StorageHelperService,
        StorageService,
        EpaasHelperService,
        EventBusService,
        AdobeAnalyticsActions,
        IsEpassInitializedActions,
        CannotAuthenticateActions
    ]
})
export class SharedModule {
  @Input() collapsedHeight: string;
  @Input() expandedHeight: string;
}
