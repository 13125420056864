import { SecureMessageThreadDTO } from '../../core/gateway-api';

export class SMCToken {
    error: boolean;
    userToken:string;
}

export class InboxMessages {
    error: boolean;
    messageThreads: SecureMessageThreadDTO[];
}
