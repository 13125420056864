<div class="modal-tax-jurisdiction" [scrollTop]="scrollToTop">
  <div class="payment-source-container" *ngIf="taxJurisdiction">
    <app-title [title]="title"></app-title>
    <p>{{subtitle}}</p>
    <div class="general error-container" role="alert" *ngIf="taxJurisdiction.error">
      <p class="error-validation" id="errorMessage">{{taxJurisdiction.errorMessage}}</p>
    </div>
    <div *ngIf="activeMode === step1Mode">
      <form #taxFormStep1="ngForm" autocomplete="off">
        <div class="form-group">
          <label class="control-label" for="tax">{{'ngw.profile.contact-information.tax-jurisdiction.step1.lbl-tax' | translate}}</label>
          <span *ngIf="taxJurisdiction.taxCounty" name="tax" id="tax">{{taxJurisdiction.taxCounty.county}}</span>
          <mat-select *ngIf="taxJurisdiction.taxCounties" floatPlaceholder="never" [(ngModel)]="taxJurisdiction.selectedTaxCounty" name="taxCounty" required id="state" class="form-control" aria-label="dialogValue" aria-labelledby="dialogValue">
            <mat-option *ngFor="let taxCounty of taxJurisdiction.taxCounties" [value]="taxCounty">{{taxCounty.county}}</mat-option>
          </mat-select>
        </div>
        <div class="form-group">
          <label class="control-label" for="address">{{'ngw.profile.contact-information.tax-jurisdiction.step1.lbl-address' | translate}}</label>
          <span name="address" id="address">{{taxJurisdiction.address}}</span>
        </div>
        <div class="form-group">
          <label class="control-label" for="city">{{'ngw.profile.contact-information.tax-jurisdiction.step1.lbl-city' | translate}}</label>
          <span name="city" id="city">{{taxJurisdiction.city}}</span>
        </div>
        <div class="form-group">
          <label class="control-label" for="state">{{'ngw.profile.contact-information.tax-jurisdiction.step1.lbl-state' | translate}}</label>
          <span name="state" id="state">{{taxJurisdiction.state}}</span>
        </div>
        <div class="form-group">
          <label class="control-label" for="zip">{{'ngw.profile.contact-information.tax-jurisdiction.step1.lbl-zip' | translate}}</label>
          <span name="zip" id="zip">{{taxJurisdiction.zip}}</span>
        </div>
        <div class="action-buttons">
          <button type="button" (click)="cancel()" class="btn btn-secondary" id="cancelTaxBtn" analytics-event="body.cancel-tax-jurisdiction.cancel-tax-jurisdiction.button">
            {{'ngw.general.btn-cancel' | translate}}</button>
          <button type="button" (click)="continueToStep2()" class="btn btn-primary" id="continueTaxBtn">{{'ngw.profile.contact-information.tax-jurisdiction.step1.btn-continue' | translate}}</button>
        </div>
      </form>
    </div>
    <div *ngIf="activeMode === step2Mode">
      <form #taxFormStep2="ngForm" autocomplete="off">
        <div class="info-row">
          <span class="label">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.lbl-curr-payment'| translate}}
          </span>
          <span class="value">{{taxJurisdiction.currentPayment | currency : 'USD':'symbol-narrow':'1.2-2': this.locale}}</span>
        </div>
        <div class="info-row">
          <span class="label">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.lbl-base-payment'| translate}}
          </span>
          <span class="value">{{taxJurisdiction.basePayment | currency : 'USD':'symbol-narrow':'1.2-2': this.locale}}</span>
        </div>
        <div class="info-row">
          <span class="label">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.lbl-sales-tax'| translate}}
          </span>
          <span class="value">{{taxJurisdiction.salesTax | currency : 'USD':'symbol-narrow':'1.2-2': this.locale}}</span>
        </div>
        <div class="info-row" *ngIf="taxJurisdiction.singleCharge !== 0">
          <span class="label">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.lbl-single-charge'| translate}}
          </span>
          <span class="value">{{taxJurisdiction.singleCharge | currency : 'USD':'symbol-narrow':'1.2-2': this.locale}}</span>
        </div>
        <div class="info-row" *ngIf="movePackEligible">
          <span class="label">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.lbl-move-pack'| translate}}
          </span>
          <span class="value">
            <span>{{taxJurisdiction.movePackFee | currency : 'USD':'symbol-narrow':'1.2-2': this.locale}}</span>
            <span>{{'ngw.profile.contact-information.tax-jurisdiction.step2.move-pack-value-suffix'| translate}}</span>
          </span>
        </div>
        <div class="info-row new-payment">
          <span class="label">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.lbl-new-payment'| translate}}
          </span>
          <span class="value">{{taxJurisdiction.newPayment | currency : 'USD':'symbol-narrow':'1.2-2': this.locale}}</span>
        </div>
        <div class="form-section" *ngIf="movePackEligible">
          <h3>{{'ngw.profile.contact-information.tax-jurisdiction.step2.title-move-pack'| translate}}</h3>
          <p>{{taxJurisdiction.movePackSubtitle}}</p>
          <mat-checkbox name="movepack" [(ngModel)]="taxJurisdiction.movePackSelected">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.checkbox-move-pack'| translate}}
          </mat-checkbox>
        </div>
        <div class="form-section">
          <h3>{{'ngw.profile.contact-information.tax-jurisdiction.step2.title-email-conf'| translate}}</h3>
          <mat-checkbox name="emailconf" [(ngModel)]="taxJurisdiction.emailConfirmationSelected">
            {{'ngw.profile.contact-information.tax-jurisdiction.step2.checkbox-email-conf'| translate}}
          </mat-checkbox>
          <div class="form-group email-confirmation">
            <input [(ngModel)]="taxJurisdiction.emailAddress" name="emailAddress" required id="emailAddress" type="text" class="form-control" [disabled]="!taxJurisdiction.emailConfirmationSelected" title="{{'ngw.profile.contact-information.tax-jurisdiction.step2.checkbox-email-conf'| translate}}" />
            <em class="error-validation" id="emailAddressRequiredError" *ngIf="emailAddressIsInvalid(taxFormStep2.controls.emailAddress)">{{emailAddressError}}</em>
          </div>
        </div>
        <div class="action-buttons">
          <button type="button" (click)="cancel()" class="btn btn-secondary" id="cancelTaxBtn" analytics-event="body.cancel-tax-jurisdiction.cancel-tax-jurisdiction.button">{{'ngw.general.btn-cancel' | translate}}</button>
          <button type="button" (click)="save()" class="btn btn-primary" id="saveTaxBtn" [disabled]="formIsInvalid(taxFormStep2)">{{'ngw.profile.contact-information.tax-jurisdiction.step2.btn-save' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
