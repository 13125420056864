import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterService } from '../../../../shared/_helper-services/router.service'

@Component({
    selector: 'app-discard-msg-dialog',
    templateUrl: './discard-msg-dialog.component.html',
    styleUrls: ['./discard-msg-dialog.component.scss']
})
export class DiscardMsgDialog implements OnInit {

    constructor(
        private translateService: TranslateService,
        private dialogRef: MatDialogRef<DiscardMsgDialog>,
        private routerService: RouterService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {

    }

    discardMessage() {
        this.dialogRef.close(true);
    }

    cancelDiscardMessage() {
        this.dialogRef.close();
    }
}
