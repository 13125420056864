import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { LeaseExtReviewDetails } from '../../../_models/lease-extension.model';


@Injectable()
export class LeaseExtReviewDetailsActions {
  static SET_LEASEEXT_REVIEW_DETAILS: string = 'SET_LEASEEXT_REVIEW_DETAILS';
  static CLEAR_LEASEEXT_REVIEW_DETAILS: string = 'CLEAR_LEASEEXT_REVIEW_DETAILS';

  setLeaseExtReviewDetails(leaseExtReviewDetails: LeaseExtReviewDetails): FSA<string, LeaseExtReviewDetails, null> {
    return {
      type: LeaseExtReviewDetailsActions.SET_LEASEEXT_REVIEW_DETAILS,
      payload: leaseExtReviewDetails,
      meta: null
    };
  }

  clearLeaseExtReviewDetails(): FSA<string, LeaseExtReviewDetails, null> {
      return {
        type: LeaseExtReviewDetailsActions.CLEAR_LEASEEXT_REVIEW_DETAILS,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class LeaseExtReviewDetailsReducers {
  static LeaseExtReviewDetailsReducer: Reducer<LeaseExtReviewDetails> = (state: LeaseExtReviewDetails = new LeaseExtReviewDetails(), action: FSA<string, LeaseExtReviewDetails, void>) => {
    switch (action.type) {
      case LeaseExtReviewDetailsActions.SET_LEASEEXT_REVIEW_DETAILS:
        return tassign(state, action.payload);
      case LeaseExtReviewDetailsActions.CLEAR_LEASEEXT_REVIEW_DETAILS:
         return tassign(undefined);
      default:
        return state;
    }
  }
}
