export class UpdateCustomerIntentModel {

  error: boolean;
  errorType: UpdateCustomerIntentFaultType;

  constructor(error: boolean, errorType?: UpdateCustomerIntentFaultType) {
    this.error = error;
    this.errorType = errorType;
  }
}


export enum UpdateCustomerIntentFaultType {
  AddCustomerIntentFault = 1,
  DataValidation = 2,
}
