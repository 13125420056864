import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState } from "../store/app.store";
import { FeatureIndicatorService } from '../../_web-services/feature-indicator.service';
import { FeatureIndicatorTypes } from '../enums';

@Injectable()
export class RepairReceiptHelperService {


  constructor(private store: Store<IAppState>,
    private featureIndicatorService: FeatureIndicatorService) {

  }

  public isRepairReceiptEnabled(): Observable<boolean> {
    return this.featureIndicatorService.getFeatureIndicator(FeatureIndicatorTypes.RepairReceiptToggle);
  }

}
