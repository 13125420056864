import { Injectable } from '@angular/core';
import { catchError, mergeMap, Observable, of } from 'rxjs';
import {
    ContractAccountDetailDTO, ContractAccountDetailDTOStatusCategoryCode,
    GetRecallStatusRequest, GetRecallStatusResponse,
    RecallServiceClient
} from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';

@Injectable()
export class RecallService {

    constructor(private recallServiceClient: RecallServiceClient, private fsTokenErrorHandler: FSTokenErrorHandler) { }

    public getRecallStatus(contract: ContractAccountDetailDTO, customerNumber: number): Observable<boolean> {
        const recallRequest: GetRecallStatusRequest = new GetRecallStatusRequest();
        recallRequest.customerNumber = customerNumber;
        recallRequest.vin = contract.vIN;
        return this.recallServiceClient.getRecallStatus(recallRequest).pipe(mergeMap((response: GetRecallStatusResponse) => {
            return this.afterGetRecallStatusSuccess(response, contract);
        }), catchError((error: any) => { return this.afterGetRecallStatusError(error) }));
    }

    private afterGetRecallStatusSuccess(response: GetRecallStatusResponse, contract: ContractAccountDetailDTO): Observable<boolean> {
        return of(response &&
            response.recallStatus &&
            contract.statusCategoryCode === ContractAccountDetailDTOStatusCategoryCode.Active);
    }

    private afterGetRecallStatusError(error: any): Observable<boolean> {
        this.fsTokenErrorHandler.handleFSTokenError(error);
        return of(false);
    }
}
