<div [class.bmw-ca]="isBMW">
<header [class.subnav]="isHomeAccess()">
  <nav>
    
    <div [ngClass]="getBrandBasedHeaderClass('navOuterDiv')">
      <div [ngClass]="getBrandBasedHeaderClass('navInnerDiv')">
        <a *ngIf="!isBMW" class="logo-link" (click)="navigateToHome();">
          <img class="logo pointer" src='{{"ngw.header.img-logo-src" | translate}}' id="roundel" alt="{{ 'ngw.header.img-alt-logo' | translate }}" />
        </a>
        <div *ngIf="!isBMW" class="head-title">{{ 'ngw.header.lbl-menu' | translate }}</div>

        <ul class="nav">
          <li *ngIf="isLoggedIn()" class="btn-account-dropdown">

            <button mat-button [matMenuTriggerFor]="menu" [disableRipple]="true" (menuOpened)="toggleMenu()" (menuClosed)="toggleMenu()" (click)="!isBMW ? scrollToTop() : false" [ngClass]="{'menu-open': menuOpened}">
              <img [src]="isBMW ? '../../../../assets/images/icon-profile.svg' : '../../../../assets/images/icon-my-mini.svg'" alt="{{ 'ngw.general.title' | translate }}" />
              <span>&nbsp;{{(isBMW ? "ngw.header.lbl-menu" : "ngw.general.lbl-user-profile") | translate}}</span>
              <svg *ngIf="isBMW" class="chevron" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9.6 6.2" style="enable-background:new 0 0 9.6 6.2;" xml:space="preserve">
              <g class="chevron-up">
              <polygon fill="#FFFFFF" id="up" points="8.2,6.2 4.8,2.8 1.4,6.2 0,4.8 4.8,0 9.6,4.8 " />

                </g>
              <g class="chevron-down">
              <polygon fill="#FFFFFF" id="down" points="8.2,0 4.8,3.4 1.4,0 0,1.4 4.8,6.2 9.6,1.4 " />

                </g>
              </svg>
            </button>

            <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before"  [class]="getMatMenuClass()">
              
              <button *ngFor="let item of profileMenuList;let i=index" (click)="this[item.eventName](i)" [ngClass]="isBMW ? item.class : ''" [class.active]="isBMW && isActive(i)" mat-menu-item>
                <img *ngIf="item.isImage==true" src="{{ matMenuImgPath + item.srcname}}" alt="{{ item.text | translate }}" />
                {{ item.text | translate }}
              </button>
           
            </mat-menu>

          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="isBMW" class="nav-secondary">
      <div class="desktop-header-container  desktop-container">
        <a class="logo-link" (click)="navigateToHome();">
          <img class="logo pointer" src="{{'ngw.header.img-logo-src' | translate}}" id="roundel" alt="{{'ngw.header.img-alt-logo' | translate}}" />
        </a>
        <div class="bmw-financial-services"></div>
      </div>
    </div>
    <div *ngIf="isHomeAccess() && this.headerMenuItems.length > 0" id="navbar">
        <fs-header-web  (onClick)="navigateTo($event)" [title]="headerTitle" [headerMenuItems]="headerMenuItems"
    [backBtnMode]="backBtnMode" skipToContentText="{{ headerSkipContent }}" (onBack)="onHeaderBackBtnClick()" [backEnabled]="headerBackBtnEnabled"
    [ariaLabelMainNavigation]="headerAriaLabelMainNav" [ariaLabelSecondaryNavigation]="headerAriaLabelSecNav">
  </fs-header-web>

    </div>
   
  </nav>
</header>
</div>