import { Component, OnInit, OnDestroy, Renderer2, Inject, Injector } from '@angular/core';
import { PaymentReviewDetails, PaymentEntry } from '../../../../_models/payment.model';
import { AccountInfoService } from '../../../../shared/_helper-services/account-info.service';
import { RouterService } from '../../../../shared/_helper-services/router.service';
import { WindowService } from '../../../../shared/_helper-services/window.service';
import { TranslateService } from '@ngx-translate/core';
import { LogSiteActivityService } from '../../../../_web-services/log-site-activity.service';
import { ActivityTypes, FeatureIndicatorTypes } from '../../../../shared/enums';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../../../../shared/constants';
import { BaseApplicationComponent } from "../../../../core/basecomponent/base.component";
import { IsInPaymentFlowActions } from '../../../../shared/store/reducers/is-in-payment-flow.reducer';
import { CanNavigateBackActions } from '../../../../shared/store/reducers/can-navigate-back.reducer';
import { UserService } from '../../../../shared/_helper-services/user.service';
import { ContractAccountDetailDTO, FinancialAccountDTOAccountType, FindCreditCardAccountsRequestBmwCreditCardBrand } from '../../../../core/gateway-api';
import { ContractualPayoffService } from '../../../../_web-services/contractual-payoff.service';
import { Payoff } from '../../../../_models/my-account/payoff.model';
import { PipeService } from '../../../../shared/_helper-services/pipe.service';
import { VehicleReturnActions } from '../../../../shared/store/reducers/vehicle-return-estimate.reducer';
import { CoBrandedCreditCardModel } from '../../../../_models/cobranded-credit-card.model';
import { FeatureIndicatorService } from '../../../../_web-services/feature-indicator.service';
import { BmwCardService } from '../../../../_web-services/bmw-card.service';
import { PaymentReviewDetailsActions } from '../../../../shared/store/reducers/payment-review-details.reducer';
import { CashAccountViewService } from '../../../../_web-services/cash-account-view.service';
import { ContractAccountDetailsActions } from "../../../../shared/store/reducers/contract-account-details.reducers";

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent extends BaseApplicationComponent<PaymentConfirmationComponent> implements OnInit, OnDestroy {
  paymentReviewDetails: PaymentReviewDetails;
  footerEl: any;
  contractDetail: ContractAccountDetailDTO;
  paymentAmountDisplay: number;
  private paymentConfirmationMessage: string;
  private title: string;
  public isLoanAccount: boolean;
  public payoffServiceError: boolean;
  public payoffAmount: string;
  public cardHolderModel: CoBrandedCreditCardModel;
  public showCreditCardBanner: boolean;
  public customerNumber: number;
  public showEmailAddress: boolean;
  public accountPrincipalBalance: number;

  constructor(public routerService: RouterService,
    private accountInfoService: AccountInfoService,
    private isInPaymentFlowActions: IsInPaymentFlowActions,
    private translateService: TranslateService,
    private canNavigateBackActions: CanNavigateBackActions,
    private vehicleReturnActions: VehicleReturnActions,
    private logSiteActivityService: LogSiteActivityService,
    private userService: UserService,
    private contractualPayoffService: ContractualPayoffService,
    private windowService: WindowService,
    public pipeService: PipeService,
    private featureIndicatorService: FeatureIndicatorService,
    private bmwCardService: BmwCardService,
    private paymentReviewDetailsActions: PaymentReviewDetailsActions,
    private cashAccountViewService: CashAccountViewService,
    private contractAccountDetailsActions: ContractAccountDetailsActions,
    renderer2: Renderer2,
    @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
  }

  ngOnInit() {
    super.pushDataLayer();
    this.analytics.pushSiteToolEvent({ siteTool: Constants.MakeAPayment, siteToolStep: Constants.SiteToolComplete });
    this.init();
    this.setFooter();
    this.displayCardHolder();

  }
  public getBMWCreditcardDetails() {
    super.setDataLoadedOnRequest(false);
    this.customerNumber = this.userService.getCustomerNumber();
    this.bmwCardService.getBMWCreditcardDetails(FindCreditCardAccountsRequestBmwCreditCardBrand.BMW, this.customerNumber, false)
      .subscribe(response => this.postGetBMWCreditCardDetails(response));

  }

  private postGetBMWCreditCardDetails(response: CoBrandedCreditCardModel) {
    if (!response.error) {
      this.cardHolderModel = response;
      this.showCreditCardBanner = (this.cardHolderModel.isCardHolder && !this.cardHolderModel.isSelfServiceRestricted && this.cardHolderModel.rewardPoints >= this.cardHolderModel.minimumRequiredPoints);
      this.postGetCreditCardSsoEnabledFlag();
    }
    else {
      this.showCreditCardBanner = false;
    }
    super.setDataLoadedOnRequest(true);
  }
  private displayCardHolder() {
    this.featureIndicatorService.getFeatureIndicator(FeatureIndicatorTypes.NGDCoBrandedCreditCardBanner)
      .subscribe(response => this.postGetFeatureIndicator(response));
  }
  private postGetFeatureIndicator(response: boolean) {
    if (response)
      this.getBMWCreditcardDetails();
    else {
      this.showCreditCardBanner = false;
    }
  }

  private postGetCreditCardSsoEnabledFlag() {
    this.featureIndicatorService.getFeatureIndicator(FeatureIndicatorTypes.CreditCardSsoEnabledFlag)
      .subscribe((response) => {
        this.cardHolderModel.cardHolderSsoEnabled = response;
      });
  }

  ngOnDestroy() {
    this.destroy();
  }

  public init() {
    let contractDetail: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.contractDetail = Object.assign({}, contractDetail);
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => this.paymentReviewDetails = x);
    let paymentEntry: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => paymentEntry = x);
    this.showEmailAddress = paymentEntry.selectedPaymentSource.accountType === FinancialAccountDTOAccountType.Checking || paymentEntry.selectedPaymentSource.accountType === FinancialAccountDTOAccountType.Savings;

    this.setPrincipalBalance();

    let paymentAmount: number = this.paymentReviewDetails.paymentAmount ?? this.paymentReviewDetails.lumpSumAmount;
    if (paymentEntry.selectedPaymentSource.accountType === FinancialAccountDTOAccountType.Debit) {
      this.paymentAmountDisplay = Number(paymentAmount) + Number(paymentEntry.cardPaymentFeeAmount);
    }
    else {
      this.paymentAmountDisplay = paymentAmount;
    }
    if (this.showEmailAddress) {
      this.translateService.get("ngw.payment.confirmation.success").subscribe((res: string) => {
        this.paymentConfirmationMessage = res;
      });
    }
    else {
      this.translateService.get("ngw.payment.confirmation.card-success").subscribe((res: string) => {
        this.paymentConfirmationMessage = res;
      });
    }
    this.translateService.get("ngw.payment.confirmation.title").subscribe((res: string) => {
      this.title = res;
    });

    this.logUserActivity();

    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(false));
    this.isLoanAccount = !this.accountInfoService.isLeaseAccount(this.contractDetail.portfolioCategoryCode);
    if (this.isLoanAccount) {
      this.contractualPayoffService.calculatePayoff(this.contractDetail.fSAccountId, true)
        .subscribe(response => {
          this.postCalculatePayoff(response);
        });
    }
    else {
      this.windowService.scrollToTop(true);
    }
    this.reset(this.contractDetail.accountNumber);
    this.accountInfoService.clearCache(false);

    let customerNumber: number = this.userService.getCustomerNumber();
    let accountNumbers: string[] = this.userService.getAccountNumbers();
    this.cashAccountViewService.findAccounts(customerNumber, accountNumbers, false).subscribe(result => {
      if (result != undefined && !result.error) {
        this.store.dispatch(this.contractAccountDetailsActions.setContracts(result.contractAccountDetails));
      }
    });
  }

  private setPrincipalBalance() {
    this.accountPrincipalBalance = this.contractDetail.principalAmount + this.contractDetail.totalProductPrincipalAmount + this.contractDetail.totalProductInterestAmount;
  }

  private logUserActivity() {
    if (this.isLeaseAccountWithMaturityBilled(this.contractDetail.portfolioCategoryCode, this.paymentReviewDetails.accountno) && this.contractDetail.countryCode.codeValue === Constants.Country_CAN)
      this.logSiteActivityService.logUserActivity(this.paymentReviewDetails.customerno, this.paymentReviewDetails.accountno, ActivityTypes.OneTimeMaturityBillingPaymentConfirmation);
    else if (this.accountInfoService.isPastDue(this.contractDetail.pastDueAmount) && this.contractDetail.countryCode.codeValue === Constants.Country_CAN)
      this.logSiteActivityService.logUserActivity(this.paymentReviewDetails.customerno, this.paymentReviewDetails.accountno, ActivityTypes.OneTimeCollectionsPaymentConfirmation);
    else if (this.paymentReviewDetails.lumpSumAmount && this.paymentReviewDetails.lumpSumAmount > 0)
      this.logSiteActivityService.logUserActivity(this.paymentReviewDetails.customerno, this.paymentReviewDetails.accountno, ActivityTypes.LumpSumPaymentConfirmation);
    else
      this.logSiteActivityService.logUserActivity(this.paymentReviewDetails.customerno, this.paymentReviewDetails.accountno, ActivityTypes.OneTimePaymentConfirmation);
  }

  private isLeaseAccountWithMaturityBilled(portfolioCategoryCode: any, accountNumber: string): boolean {
    return (this.accountInfoService.isLeaseAccount(portfolioCategoryCode) && this.accountInfoService.isMaturityBilled(accountNumber));
  }

  private postCalculatePayoff(response: Payoff) {
    if (!response.error && response.payoffAmount) {
      this.payoffAmount = this.pipeService.getAmountAsCurrency(response.payoffAmount);
      this.payoffServiceError = false;
    }
    else
      this.payoffServiceError = true;
  }

  private setFooter() {
    this.footerEl = document.getElementsByClassName("footer-container")[0];
    this.footerEl.className += " pin-bottom";
    this.footerEl.className = "footer-container";
  }

  public destroy() {
    this.store.dispatch(this.isInPaymentFlowActions.setIsInPaymentFlow(false));
    this.store.dispatch(this.paymentReviewDetailsActions.clearPaymentReviewDetails());
  }

  print(): void {
    this.windowService.print();
  }

  public navigateToHome() {
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.routerService.navigateToHome();
  }

  private reset(accountNumber: string): void {
    this.store.dispatch(this.vehicleReturnActions.removeVehicleReturnEstimate(accountNumber));
  }

  public isAllowToBackward(): boolean {
    let CanNavigateBack: any;
    this.store.select(state => state.CanNavigateBack.booleanValue).subscribe(x => CanNavigateBack = x);
    return (CanNavigateBack) ? false : true;
  }
}
