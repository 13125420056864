import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { VehicleImageModel } from '../../../_models/contract/vehicle-image.model';

@Injectable()
export class VehicleImageActions {
    static PUSH_VEHICLE_IMAGE: string = 'PUSH_VEHICLE_IMAGE';
    static REMOVE_VEHICLE_IMAGE: string = 'REMOVE_VEHICLE_IMAGE';
    static CLEAR_VEHICLE_IMAGE: string = 'CLEAR_VEHICLE_IMAGE';

    pushVehicleImage(vehicleImageModel: VehicleImageModel): FSA<string, any, null> {
        return {
            type: VehicleImageActions.PUSH_VEHICLE_IMAGE,
            payload: vehicleImageModel,
            meta: null
        };
    }

    removeVehicleImage(financialProductId: string): FSA<string, any, null> {
        return {
            type: VehicleImageActions.REMOVE_VEHICLE_IMAGE,
            payload: financialProductId,
            meta: null
        };
    }

    clearVehicleImage(): FSA<string, any, null> {
        return {
            type: VehicleImageActions.CLEAR_VEHICLE_IMAGE,
            payload: null,
            meta: null
        };
    }
}

@Injectable()
export class VehicleImageReducers {
    static VehicleImageReducer: Reducer<VehicleImageModel[]> = (state: VehicleImageModel[] = [], action: FSA<string, any, void>) => {
        switch (action.type) {
            case VehicleImageActions.PUSH_VEHICLE_IMAGE:
                return state = [...state, action.payload];
            case VehicleImageActions.REMOVE_VEHICLE_IMAGE:
                return state = state ? state.filter(vehicleImageModel => vehicleImageModel.financialProductId !== action.payload) : [];
            case VehicleImageActions.CLEAR_VEHICLE_IMAGE:
                return state = [];
            default:
                return state;
        }
    }
}
