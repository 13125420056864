import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StatementCollection } from '../../../_models/my-account/statement.model';

@Injectable()
export class StatementCollectionActions
{
    static SET_STATEMENT_COLLECTION: string = 'SET_STATEMENT_COLLECTION';
    static CLEAR_STATEMENT_COLLECTION: string = 'CLEAR_STATEMENT_COLLECTION';

  setStatementCollection(statementCollection: StatementCollection): FSA<string, StatementCollection, null>{
    return {
      type: StatementCollectionActions.SET_STATEMENT_COLLECTION,
      payload: statementCollection,
      meta: null
    };
  }

  clearStatementCollection(): FSA<string, StatementCollection, null> {
      return {
          type: StatementCollectionActions.SET_STATEMENT_COLLECTION,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class StatementCollectionReducers
{
  static StatementCollectionReducer: Reducer<StatementCollection> = (state: StatementCollection=new StatementCollection(), action: FSA<string, StatementCollection, void>) => {
    switch(action.type){
    case StatementCollectionActions.SET_STATEMENT_COLLECTION:
            return tassign(state, action.payload);
    case StatementCollectionActions.CLEAR_STATEMENT_COLLECTION:
        return tassign(undefined);
    default:
      return state;
    }
  }
}
