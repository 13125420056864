import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ContactUpcoming } from '../_models/contract/contact-upcoming.model';
import { ScheduledItem } from '../_models/contract/scheduled-item.model';
import { PendingOneTimePayment } from '../_models/payment-details/scheduled-payment.model';
import { BankInfo } from '../_models/payment-source.model';
import { ACHErrorType, PaymentReviewDetails, PaymentSource } from '../_models/payment.model';
import { PaymentPreferenceType } from '../_models/recurring-payment/payment-preference.model';
import { RecurringPaymentCancel } from '../_models/recurring-payment/recurring-payment-cancel-model';
import { RecurringPaymentEntry } from '../_models/recurring-payment/recurring-payment-entry.model';
import { RecurringPaymentHoldsModel } from '../_models/recurring-payment/recurring-payment-holds.model';
import { CancelPaymentRequest, CancelRecurringPaymentScheduleRequest, CountryCode, CustomerACHServiceClient, FindEasyPayScheduleRequest, FindEasyPayScheduleResponse, FindUpcomingUpaysRequest, GetBankInformationByIdRequest, GetHoldsRequest, HoldAndSchedulePaymentRequest, HoldAndSchedulePaymentRequestChannel, HoldAndSchedulePaymentRequestSystem, InitiateRecurringPaymentScheduleRequest, InitiateRecurringPaymentScheduleRequestChannel, InitiateRecurringPaymentScheduleRequestScheduleFrequency, InitiateRecurringPaymentScheduleRequestSystem, PlaceHoldRequest, PlaceHoldRequestHoldReason, RemoveHoldRequest, UpdateRecurringPaymentScheduleRequest, ValidateRoutingNumberRequest } from '../core/gateway-api';
import { FaultCodes } from '../shared/FaultCodes';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { ObjectService } from '../shared/_helper-services/object.service';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from "../shared/store/app.store";
import { RecurringPaymentHoldsActions } from '../shared/store/reducers/recurring-payment-holds.reducer';
import { ScheduledItemsActions } from './../shared/store/reducers/scheduled-items.reducers';
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class CustomerACHService {
  recurringPaymentEntry: RecurringPaymentEntry;
  storeAppConfig: ApplicationConfig;
  constructor(
    private customerACHServiceClient: CustomerACHServiceClient,
    private fsTokenErrorHandler: FSTokenErrorHandler,
    private scheduledItemsActions: ScheduledItemsActions,
    private objectService: ObjectService,
    private recurringPaymentHoldsActions: RecurringPaymentHoldsActions,
    private userService: UserService,
    private store: Store<IAppState>) {
  }

  private getCachedScheduledItems(): ScheduledItem[] {
    let scheduledItem: ScheduledItem[];
    this.store.select(state => state.ScheduledItems).subscribe(x => scheduledItem = x);
    return scheduledItem;
  }

  public findUpcomingUpays(accountNumber: string, refresh: boolean): Observable<ContactUpcoming> {
    let cachedScheduledItems = this.getCachedScheduledItems();
    if (refresh || this.objectService.isEmptyObject(cachedScheduledItems) || cachedScheduledItems == null) {
      let findUpcomingUpaysRequest = new FindUpcomingUpaysRequest();
      findUpcomingUpaysRequest.accountNumber = accountNumber;
      return this.customerACHServiceClient.findUpcomingUpays(findUpcomingUpaysRequest)
        .pipe(mergeMap((response) => { return this.afterFindUpcomingSuccess(accountNumber, response, refresh); })
          , catchError((error: any) => { return this.afterFindUpcomingFailure(error); }));
    }
    let cachedScheduledItem = _.find(this.getCachedScheduledItems(), function (si) { return si.accountNumber === accountNumber; })
    let contactUpcoming = this.getContactUpcoming(cachedScheduledItem);
    return of(contactUpcoming);
  }

  public placeHold(accountNumber: string, holdStartDate: Date, holdEndDate: Date, userId?: string): Observable<boolean> {
    let request = new PlaceHoldRequest();
    request.accountNumber = accountNumber;
    request.holdStartDate = holdStartDate;
    request.holdEndDate = holdEndDate;
    request.userId = userId;
    request.holdReason = PlaceHoldRequestHoldReason.ACHPaymentDelayRequested;
    return this.customerACHServiceClient.placeHold(request)
      .pipe(mergeMap((response) => { return this.afterSucessPlaceHold(response); })
        , catchError((error) => { return this.afterFailurePlaceHold(error); }));
  }

  public removeHold(accountNumber: string, holdStartDate: Date, holdEndDate: Date, userId?: string): Observable<boolean> {
    let request = new RemoveHoldRequest();

    request.accountNumber = accountNumber;
    request.holdStartDate = holdStartDate;
    request.holdEndDate = holdEndDate;
    request.userId = userId;

    return this.customerACHServiceClient.removeHold(request)
      .pipe(mergeMap((response) => { return this.afterSuccessRemoveHold(response); })
        , catchError((error) => { return this.afterFailuresRemoveHold(error); }));
  }

  public findEasyPaySchedule(accountNumber: string): Observable<RecurringPaymentEntry> {
    let findEasyPayScheduleRequest = new FindEasyPayScheduleRequest();
    findEasyPayScheduleRequest.accountNumber = accountNumber;
    return this.customerACHServiceClient.findEasyPaySchedule(findEasyPayScheduleRequest)
      .pipe(mergeMap((response) => { return this.afterFindEasyPayScheduleSuccess(response, accountNumber); })
        , catchError((error: any) => { return this.afterFindEasyPayScheduleFailure(error, accountNumber); }));
  }

  public initiateRecurringPaymentSchedule(): Observable<RecurringPaymentEntry> {
    let ifRecurringPaymentEntry: RecurringPaymentEntry;
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => ifRecurringPaymentEntry = x);
    if (ifRecurringPaymentEntry) {
      this.store.select(state => state.RecurringPaymentEntry).subscribe(x => this.recurringPaymentEntry = x);
      let initiateRecurringPaymentScheduleRequest = new InitiateRecurringPaymentScheduleRequest();
      initiateRecurringPaymentScheduleRequest.accountNumber = this.recurringPaymentEntry.accountNumber;
      initiateRecurringPaymentScheduleRequest.startDate = new Date(this.recurringPaymentEntry.startDate);
      initiateRecurringPaymentScheduleRequest.scheduleFrequency = InitiateRecurringPaymentScheduleRequestScheduleFrequency.Monthly; //Need to verify.
      initiateRecurringPaymentScheduleRequest.shouldDrawFullBalance = this.recurringPaymentEntry.selectedPaymentPreference.type == PaymentPreferenceType.MonthlyAndOutstanding;
      initiateRecurringPaymentScheduleRequest.additionalAmount = this.recurringPaymentEntry.additionalPaymentPreference && this.recurringPaymentEntry.additionalPaymentPreference.isChecked
        ? this.recurringPaymentEntry.additionalPaymentPreference.value
        : null;
      initiateRecurringPaymentScheduleRequest.financialAccountId = this.recurringPaymentEntry.selectedPaymentSource.financialAccountId;
      let countryCode = new CountryCode();
      this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
      countryCode.codeValue = this.storeAppConfig.COUNTRY_CODE;
      initiateRecurringPaymentScheduleRequest.countryCode = countryCode;
      initiateRecurringPaymentScheduleRequest.channel = InitiateRecurringPaymentScheduleRequestChannel.Web;
      initiateRecurringPaymentScheduleRequest.system = InitiateRecurringPaymentScheduleRequestSystem.MyBMW;
      initiateRecurringPaymentScheduleRequest.permitStartBeforeToday = false;
      initiateRecurringPaymentScheduleRequest.isRegularMonthlyPaymentDue = this.recurringPaymentEntry.isRegularMonthlyPaymentDue;
      initiateRecurringPaymentScheduleRequest.createUser = this.storeAppConfig.USER_ID;

      return this.customerACHServiceClient.initiateRecurringPaymentSchedule(initiateRecurringPaymentScheduleRequest)
        .pipe(mergeMap((response) => { return this.afterInitiateUpdateRecurringPaymentScheduleSuccess(response); })
          , catchError((error: any) => { return this.afterInitiateUpdateRecurringPaymentScheduleFailure(error); }));
    }
    return of(null);
  }

  public updateRecurringPaymentSchedule(): Observable<RecurringPaymentEntry> {
    let ifRecurringPaymentEntry: RecurringPaymentEntry;
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => ifRecurringPaymentEntry = x);
    if (ifRecurringPaymentEntry) {
      this.store.select(state => state.RecurringPaymentEntry).subscribe(x => this.recurringPaymentEntry = x);
      let updateRecurringPaymentScheduleRequest = new UpdateRecurringPaymentScheduleRequest();
      updateRecurringPaymentScheduleRequest.accountNumber = this.recurringPaymentEntry.accountNumber;
      if (this.recurringPaymentEntry.selectedPaymentPreference) {
        updateRecurringPaymentScheduleRequest.shouldDrawFullBalance = this.recurringPaymentEntry.selectedPaymentPreference.type == PaymentPreferenceType.MonthlyAndOutstanding;
      }
      if (this.recurringPaymentEntry.additionalPaymentPreference) {
        updateRecurringPaymentScheduleRequest.additionalAmount = this.recurringPaymentEntry.additionalPaymentPreference && this.recurringPaymentEntry.additionalPaymentPreference.isChecked
          ? this.recurringPaymentEntry.additionalPaymentPreference.value
          : 0;
      }
      updateRecurringPaymentScheduleRequest.financialAccountId = this.recurringPaymentEntry.selectedPaymentSource.financialAccountId;
      this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => updateRecurringPaymentScheduleRequest.updateUser = x);
      updateRecurringPaymentScheduleRequest.customerNumber = null;

      return this.customerACHServiceClient.updateRecurringPaymentSchedule(updateRecurringPaymentScheduleRequest)
        .pipe(mergeMap((response) => { return this.afterInitiateUpdateRecurringPaymentScheduleSuccess(response); })
          , catchError((error: any) => { return this.afterInitiateUpdateRecurringPaymentScheduleFailure(error); }));
    }
    return of(null);
  }

  private afterSuccessRemoveHold(response: any) {
    return of(true);
  }

  private afterFailuresRemoveHold(response: any) {
    return of(false);
  }

  private afterSucessPlaceHold(response: any) {
    return of(true);
  }

  private afterFailurePlaceHold(error: any) {
    return of(false);
  }

  private getContactUpcoming(scheduledItem: ScheduledItem): ContactUpcoming {
    let contactUpcoming = new ContactUpcoming();
    contactUpcoming.scheduledItem = scheduledItem;
    return contactUpcoming;
  }

  private getRecurringPaymentEntry(response: FindEasyPayScheduleResponse, error: boolean, faultType: string, accountNumber: string): RecurringPaymentEntry {
    let recurringPaymentEntry = new RecurringPaymentEntry();
    recurringPaymentEntry.findEasyPayScheduleResponse = response;
    recurringPaymentEntry.error = error;
    recurringPaymentEntry.faultType = faultType;
    recurringPaymentEntry.accountNumber = accountNumber;
    return recurringPaymentEntry;
  }

  private afterFindEasyPayScheduleSuccess(result: any, accountNumber: string): Observable<RecurringPaymentEntry> {
    let recurringPaymentEntry = this.getRecurringPaymentEntry(result, false, null, accountNumber);
    return of(recurringPaymentEntry);
  }

  private afterFindEasyPayScheduleFailure(error: any, accountNumber: string): Observable<RecurringPaymentEntry> {
    let recurringPaymentEntry = this.getRecurringPaymentEntry(null, true, error.faultType, accountNumber);
    return of(recurringPaymentEntry);
  }

  private afterFindUpcomingSuccess(accountNumber: string, result: any, refresh: boolean): Observable<ContactUpcoming> {
    //caching 
    let scheduledItems = result.upays;
    let sortedScheduledItems = _.sortBy(scheduledItems, function (si) { return si.scheduledDate; });
    let scheduledItem = new ScheduledItem();
    scheduledItem.scheduledItemDTO = sortedScheduledItems && sortedScheduledItems.length > 0 ? sortedScheduledItems[0] : null;
    scheduledItem.accountNumber = accountNumber;
    scheduledItem.hasMultipleItems = sortedScheduledItems.length > 1 ? true : false;
    this.store.dispatch(this.scheduledItemsActions.pushScheduledItem(scheduledItem));
    let contactUpcoming = this.getContactUpcoming(scheduledItem);
    return of(contactUpcoming);
  }

  private afterFindUpcomingFailure(result: any): Observable<ContactUpcoming> {
    let contactUpcoming = new ContactUpcoming();
    contactUpcoming.error = true;
    this.fsTokenErrorHandler.handleFSTokenError(result);
    return of(contactUpcoming);
  }

  public getBankInfo(bankId: number): Observable<BankInfo> {
    let getBankInformationByIdRequest = new GetBankInformationByIdRequest();
    getBankInformationByIdRequest.bankId = bankId;

    return this.customerACHServiceClient.getBankInformationById(getBankInformationByIdRequest).
      pipe(mergeMap((response) => { return this.afterGetBankInfoPostSuccess(response); })
        , catchError((error: any) => { return this.afterGetBankInfoPostFailure(error); }));
  }

  private afterGetBankInfoPostSuccess(result: any): Observable<BankInfo> {
    let bankInfo = new BankInfo();
    bankInfo.error = false;
    bankInfo.bank = result.bank;

    return of(bankInfo);
  }

  private afterGetBankInfoPostFailure(error: any): Observable<BankInfo> {
    let bankInfo = new BankInfo();
    bankInfo.error = true;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(bankInfo);
  }

  private afterInitiateUpdateRecurringPaymentScheduleSuccess(result: any) {
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => this.recurringPaymentEntry = x);
    this.recurringPaymentEntry.confirmationNumber = result.confirmationId;
    this.recurringPaymentEntry.error = false;
    return of(this.recurringPaymentEntry);
  }

  private afterInitiateUpdateRecurringPaymentScheduleFailure(error: any) {
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => this.recurringPaymentEntry = x);
    this.recurringPaymentEntry.error = true;
    this.recurringPaymentEntry.faultType = error.faultType;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(this.recurringPaymentEntry);
  }

  public validateRoutingNumber(routingNumber: number): Observable<any> {
    let validateRoutingNumberRequest = new ValidateRoutingNumberRequest();
    let countryCode = new CountryCode();
    this.store.select(state => state.ApplicationConfig.COUNTRY_CODE).subscribe(x => countryCode.codeValue  = x);
    validateRoutingNumberRequest.routingNumber = routingNumber.toString();
    validateRoutingNumberRequest.countryCode = countryCode;
    return this.customerACHServiceClient.validateRoutingNumber(validateRoutingNumberRequest)
      .pipe(mergeMap((response) => { return this.afterValidateRoutingNumberSuccess(response); })
        , catchError((error: any) => { return this.afterValidateRoutingNumberFailure(error); }));
  }

  private afterValidateRoutingNumberSuccess(response: any): Observable<any> {
    let achModel = new PaymentSource();
    achModel.bankId = response.bankId;
    return of(achModel);
  }

  private afterValidateRoutingNumberFailure(error: any): Observable<any> {
    let achModel = new PaymentSource();
    achModel.error = true;
    if (error.faultType === FaultCodes.BMWFSAM_Services_CustomerCashManagement_InvalidRoutingNumberFault) {
      achModel.errorType = ACHErrorType.InvalidRoutingNumber;
    } else {
      achModel.errorType = ACHErrorType.AddACHFailed;
    }
    return of(achModel);
  }

  public cancelRecurringPaymentSchedule(accountNo: string): Observable<RecurringPaymentCancel> {
    let recurringPaymentCancelRequest = new CancelRecurringPaymentScheduleRequest();
    recurringPaymentCancelRequest.accountNumber = accountNo;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => recurringPaymentCancelRequest.cancelUser = x);

    return this.customerACHServiceClient.cancelRecurringPaymentSchedule(recurringPaymentCancelRequest)
      .pipe(mergeMap((response) => { return this.afterPostCancelRecurringPaymentScheduleSucess(response) })
        , catchError((error: any) => { return this.afterPostCancelRecurringPaymentScheduleError(error) }));
  }

  private afterPostCancelRecurringPaymentScheduleSucess(result: any): Observable<RecurringPaymentCancel> {
    let recurringPaymentCancel = new RecurringPaymentCancel();
    recurringPaymentCancel.error = false;
    return of(recurringPaymentCancel);
  }

  private afterPostCancelRecurringPaymentScheduleError(error: any): Observable<RecurringPaymentCancel> {
    let recurringPaymentCancel = new RecurringPaymentCancel();
    recurringPaymentCancel.error = true;
    recurringPaymentCancel.errorMessage = error.faultType;
    return of(recurringPaymentCancel);
  }

  public cancelPendingOneTimePaymentSchedule(accountNo: string, scheduledItemId: number): Observable<PendingOneTimePayment> {
    let cancelPaymentRequest = new CancelPaymentRequest();
    cancelPaymentRequest.accountNumber = accountNo;
    cancelPaymentRequest.scheduledItemId = scheduledItemId;
    let appconfig: ApplicationConfig;
    this.store.select(state => state.ApplicationConfig).subscribe(x => appconfig = x);
    cancelPaymentRequest.userId = appconfig ? appconfig.USER_ID : null;
    return this.customerACHServiceClient.cancelPayment(cancelPaymentRequest)
      .pipe(mergeMap((response) => { return this.afterCancelPendingOneTimePaymentScheduleSucess(response) })
        , catchError((error: any) => { return this.afterCancelPendingOneTimePaymentScheduleError(error) }));
  }

  private afterCancelPendingOneTimePaymentScheduleSucess(result: any): Observable<PendingOneTimePayment> {
    let pendingPayment = new PendingOneTimePayment();
    pendingPayment.error = false;
    return of(pendingPayment);
  }

  private afterCancelPendingOneTimePaymentScheduleError(error: any): Observable<PendingOneTimePayment> {
    let pendingPayment = new PendingOneTimePayment();
    pendingPayment.error = true;
    return of(pendingPayment);
  }

  public getCachedRecurringPaymentHolds(accountNumber: string): RecurringPaymentHoldsModel {
    let recurringPaymentHolds: RecurringPaymentHoldsModel[];
    this.store.select(state => state.RecurringPaymentHolds).subscribe(x => recurringPaymentHolds = x);
    let cachedRecurringPaymentHoldsModel = _.find(recurringPaymentHolds, function (recurringPaymentHold) { return recurringPaymentHold.accountNumber === accountNumber; });
    return cachedRecurringPaymentHoldsModel;
  }

  public getHolds(accountNumber: string, refresh?: boolean): Observable<RecurringPaymentHoldsModel> {
    if (refresh)
      this.store.dispatch(this.recurringPaymentHoldsActions.removeRecurringPaymentHolds(accountNumber));

    let recurringPaymentHoldsModel = this.getCachedRecurringPaymentHolds(accountNumber);
    if (!recurringPaymentHoldsModel) {
      let getHoldsRequest = new GetHoldsRequest();
      getHoldsRequest.accountNumber = accountNumber;
      return this.customerACHServiceClient.getHolds(getHoldsRequest)
        .pipe(mergeMap((response) => { return this.getHoldsSucess(response, accountNumber); })
          , catchError((error: any) => { return this.getHoldsFailure(error); }));
    }
    return of(recurringPaymentHoldsModel);
  }

  private getHoldsSucess(response: any, accountNumber): Observable<RecurringPaymentHoldsModel> {
    let recurringPaymentHoldsModel = new RecurringPaymentHoldsModel();
    if (response)
      recurringPaymentHoldsModel.holds = response.holds;
    recurringPaymentHoldsModel.accountNumber = accountNumber;
    recurringPaymentHoldsModel.error = false;
    recurringPaymentHoldsModel.isOnHold = response.isOnHold;
    recurringPaymentHoldsModel.resumeDate = response.resumeDate;
    this.store.dispatch(this.recurringPaymentHoldsActions.pushRecurringPaymentHolds(recurringPaymentHoldsModel));
    return of(recurringPaymentHoldsModel);
  }

  private getHoldsFailure(error: any): Observable<RecurringPaymentHoldsModel> {
    let recurringPaymentHoldsModel = new RecurringPaymentHoldsModel();
    recurringPaymentHoldsModel.error = true;
    return of(recurringPaymentHoldsModel);
  }

  public holdAndSchedulePayment(holdAndSchedulePaymentRequest: HoldAndSchedulePaymentRequest): Observable<PaymentReviewDetails> {
    return this.customerACHServiceClient.holdAndSchedulePayment(holdAndSchedulePaymentRequest)
      .pipe(mergeMap((response) => { return this.afterHoldAndSchedulePaymentSuccess(response); })
        , catchError((error: any) => { return this.afterHoldAndSchedulePaymentError(error); }));
  }

  getHoldAndSchedulePaymentRequest(paymentReviewDetails: PaymentReviewDetails): HoldAndSchedulePaymentRequest {
    let holdAndSchedulePaymentRequest = new HoldAndSchedulePaymentRequest();
    holdAndSchedulePaymentRequest.accountNumber = paymentReviewDetails.accountno;
    holdAndSchedulePaymentRequest.customerNumber = this.userService.getCustomerNumber();
    holdAndSchedulePaymentRequest.emailAddress = paymentReviewDetails.primaryEmailAddress;
    holdAndSchedulePaymentRequest.financialAccountId = paymentReviewDetails.financialAccountId;
    holdAndSchedulePaymentRequest.paymentAmount = paymentReviewDetails.paymentAmount;
    holdAndSchedulePaymentRequest.scheduledItemID = paymentReviewDetails.scheduledItemToHold.scheduledItemId;
    holdAndSchedulePaymentRequest.scheduledPaymentDate = paymentReviewDetails.paymentDate;
    holdAndSchedulePaymentRequest.channel = HoldAndSchedulePaymentRequestChannel.Web;
    holdAndSchedulePaymentRequest.system = HoldAndSchedulePaymentRequestSystem.CanadaOwnersCircle;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => holdAndSchedulePaymentRequest.userId = x);
    return holdAndSchedulePaymentRequest;
  }

  private afterHoldAndSchedulePaymentSuccess(result: any): Observable<PaymentReviewDetails> {
    let paymentReviewDetails: PaymentReviewDetails;
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => paymentReviewDetails = x);
    paymentReviewDetails.confirmationNumber = result.confirmationID;
    paymentReviewDetails.error = false;
    return of(paymentReviewDetails);
  }

  private afterHoldAndSchedulePaymentError(error: any): Observable<PaymentReviewDetails> {
    let paymentReviewDetails: PaymentReviewDetails;
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => paymentReviewDetails = x);
    paymentReviewDetails.error = true;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(paymentReviewDetails);
  }
}
