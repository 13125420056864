import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AddAchPaymentSourceCanModel } from "../_models/add-ach-payment-source-can.model";
import { UpdatePhoneNumbers } from '../_models/contract/contact-information.model';
import { LegitimizationErrorType, LegitimizationStatus } from '../_models/legitimization-status.model';
import { SMCToken } from '../_models/secure-message-center/inbox.model';
import {
  DeliverSmcMessageRequest, DeliverSmcMessageRequestBrand, DeliverSmcMessageRequestCountry,
  DeliverSmcMessageRequestMessageType,
  DeliverSmcMessageRequestSubjectTemplateName, DeliverSmcMessageRequestSystemCode,
  DeliverSmcMessageRequestTemplate,
  EmailAddressDTO,
  GetContactByGcidClientIdRequest,
  GetContactByGcidClientIdRequestAppId,
  LegitimizeCanadaFinancialServicesByBmwIdDobRequest,
  LegitimizeCanadaFinancialServicesByVinLastPaymentRequest,
  LegitimizeFinancialServicesByAccountRequest, LegitimizeFinancialServicesBySSNRequest,
  PartnerContactServiceClient,
  PhoneNumberDTO2,
  PrepareMessageCenterRelationshipRequest,
  SwaggerException,
  UpdateContactEmailAddressByGcidClientIdRequest, UpdateContactPhoneByGcidClientIdRequest
} from '../core/gateway-api';
import { FaultCodes } from '../shared/FaultCodes';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from "../shared/store/app.store";
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class PartnerContactService {

  private legitimizeByAccountRequest: LegitimizeFinancialServicesByAccountRequest;
  private legitimizeBySSNRequest: LegitimizeFinancialServicesBySSNRequest;
  storeAppConfig: ApplicationConfig;
  constructor(
    private userService: UserService,
    private partnerContactService: PartnerContactServiceClient,
    private store: Store<IAppState>) { }

  public getContactByGcidClientId(): Observable<any> {
    let request = new GetContactByGcidClientIdRequest();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    request.clientId = this.storeAppConfig.CLIENT_ID;
    request.gCID = this.userService.getGcid();
    let webUserRole: string = this.storeAppConfig.WEB_USER_ROLE;
    switch (webUserRole) {
      case GetContactByGcidClientIdRequestAppId.AppUser.toString():
        request.appId = GetContactByGcidClientIdRequestAppId.AppUser;
        break;
      case GetContactByGcidClientIdRequestAppId.WebUser.toString():
        request.appId = GetContactByGcidClientIdRequestAppId.WebUser;
        break;
      case GetContactByGcidClientIdRequestAppId.AppV2User.toString():
        request.appId = GetContactByGcidClientIdRequestAppId.AppV2User;
        break;
      default:
        request.appId = GetContactByGcidClientIdRequestAppId.WebUser;
        break;
    }

    return this.partnerContactService.getContactByGcidClientId(request);
  }

  legitimize(useSSN: boolean, lastName: string, ssn: string, zip: string, accountNumber: string, lastFourOfSSN: string): Observable<LegitimizationStatus> {
    return useSSN ? this.legitimizeBySSN(lastName, ssn, zip) : this.legitimizeByAccount(lastName, accountNumber, lastFourOfSSN);

  }

  private legitimizeBySSN(lastName: string, ssn: string, zip: string): Observable<LegitimizationStatus> {
    this.legitimizeBySSNRequest = new LegitimizeFinancialServicesBySSNRequest();

    this.legitimizeBySSNRequest.lastName = lastName;
    this.legitimizeBySSNRequest.ssn = ssn;
    this.legitimizeBySSNRequest.postalCode = zip;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    this.legitimizeBySSNRequest.clientId = this.storeAppConfig.CLIENT_ID;
    this.legitimizeBySSNRequest.gCID = this.userService.getGcid();

    return this.partnerContactService.legitimizeFinancialServicesBySSN(this.legitimizeBySSNRequest)
      .pipe(mergeMap((response) => { return this.afterLegitimizeSuccess(response); }), catchError((error: any) => {
        return this.afterLegitimizeFailure(error);
      }));
  }

  private legitimizeByAccount(lastName: string, accountNumber: string, lastFourOfSSN: string): Observable<LegitimizationStatus> {
    this.legitimizeByAccountRequest = new LegitimizeFinancialServicesByAccountRequest();

    this.legitimizeByAccountRequest.lastName = lastName;
    this.legitimizeByAccountRequest.accountNumber = accountNumber;
    this.legitimizeByAccountRequest.ssnLast4 = lastFourOfSSN;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    this.legitimizeByAccountRequest.clientId = this.storeAppConfig.CLIENT_ID;
    this.legitimizeByAccountRequest.gCID = this.userService.getGcid();

    return this.partnerContactService.legitimizeFinancialServicesByAccount(this.legitimizeByAccountRequest)
      .pipe(mergeMap((response) => { return this.afterLegitimizeSuccess(response); }), catchError((error: any) => {
        return this.afterLegitimizeFailure(error);
      }));
  }


  private afterLegitimizeSuccess(result: any): Observable<LegitimizationStatus> {
    this.userService.setFsStatus(true);
    let legitimizeStatus = new LegitimizationStatus();
    return of(legitimizeStatus);
  }

  private afterLegitimizeFailure(error: SwaggerException): Observable<LegitimizationStatus> {
    let legitimizationStatus = new LegitimizationStatus();

    legitimizationStatus.error = true;
    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_DataValidation_V201112_DataValidationFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.DataValidation;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_NoMatchesFoundFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.NoMatchesFound;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_UniqueMatchNotFoundFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.UniqueMatchNotFound;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_GCIDAlreadyLegitimizedFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.GCIDAlreadyLegitimized;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_OwnershipValidationFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.OwnershipValidation;
            break;
          }
        default:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.LegitimizationFailed;
            break;
          }
      }
    } else {
      legitimizationStatus.errortype = LegitimizationErrorType.LegitimizationFailed;
    }
    return of(legitimizationStatus);
  }

  public getMessageCenterUserToken(): Observable<SMCToken> {
    let request = new PrepareMessageCenterRelationshipRequest();
    request.customerNumber = this.userService.getCustomerNumber();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    request.vendorId = this.storeAppConfig.SMC_VENDOR_ID;
    return this.partnerContactService.prepareMessageCenterRelationship(request)
      .pipe(mergeMap((response) => { return this.afterGetMessageCenterTokenSuccess(response); }), catchError((error: any) => {
        return this.afterGetMessageCenterTokenFailure(error);
      }));
  }

  private afterGetMessageCenterTokenSuccess(result: any): Observable<SMCToken> {
    let smcToken = new SMCToken();
    smcToken.error = false;
    smcToken.userToken = result.userToken;
    return of(smcToken);
  }

  private afterGetMessageCenterTokenFailure(error: any): Observable<SMCToken> {
    let smcToken = new SMCToken();
    smcToken.error = true;
    return of(smcToken);
  }

  public updateEmail(emailAddress: EmailAddressDTO): Observable<void> {

    let request = new UpdateContactEmailAddressByGcidClientIdRequest();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    request.clientId = this.storeAppConfig.CLIENT_ID;
    request.gCID = this.userService.getGcid();
    request.newEmailAddress = emailAddress;

    return this.partnerContactService.updateContactEmailAddressByGcidClientId(request);
  }

  public updateContactPhoneByGcidClientId(phoneNumberDetail: PhoneNumberDTO2): Observable<UpdatePhoneNumbers> {
    let request = new UpdateContactPhoneByGcidClientIdRequest();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    request.clientId = this.storeAppConfig.CLIENT_ID;
    request.gCID = this.userService.getGcid();
    request.newPhoneNumber = phoneNumberDetail;

    return this.partnerContactService.updateContactPhoneByGcidClientId(request)
      .pipe(mergeMap((response) => { return this.afterUpdateContactPhoneSuccess(response); })
        , catchError((error: any) => { return this.afterUpdateContactPhoneFailure(error); }));
  }

  private afterUpdateContactPhoneSuccess(result: any): Observable<UpdatePhoneNumbers> {
    let updatePhoneNumbers = new UpdatePhoneNumbers();
    updatePhoneNumbers.error = false;
    return of(updatePhoneNumbers);
  }

  private afterUpdateContactPhoneFailure(error: any): Observable<UpdatePhoneNumbers> {
    let updatePhoneNumbers = new UpdatePhoneNumbers();
    updatePhoneNumbers.error = true;
    updatePhoneNumbers.faultType = error.faultType;
    updatePhoneNumbers.errorDesc = error.response;
    return of(updatePhoneNumbers);
  }


  legitimizeCanadaFinancialServices(useVIN: boolean, firstName: string, lastName: string, birthDate: Date, vin: string, lastPaymentAmount: number, bmwId: string): Observable<LegitimizationStatus> {
    return useVIN ? this.legitimizeCanadaFinancialServicesByVinLastPayment(firstName, lastName, birthDate, vin, lastPaymentAmount) : this.legitimizeCanadaFinancialServicesByBMWId(firstName, lastName, birthDate, bmwId);
  }

  private legitimizeCanadaFinancialServicesByVinLastPayment(firstName: string, lastName: string, birthDate: Date, vin: string, lastPaymentAmount: number): Observable<LegitimizationStatus> {
    let legitimizeCanadaFinancialServicesByVinLastPaymentRequest = new LegitimizeCanadaFinancialServicesByVinLastPaymentRequest();

    legitimizeCanadaFinancialServicesByVinLastPaymentRequest.firstName = firstName;
    legitimizeCanadaFinancialServicesByVinLastPaymentRequest.lastName = lastName;
    legitimizeCanadaFinancialServicesByVinLastPaymentRequest.birthDate = birthDate ? birthDate : new Date('1/1/1900');
    legitimizeCanadaFinancialServicesByVinLastPaymentRequest.vin = vin;
    legitimizeCanadaFinancialServicesByVinLastPaymentRequest.lastPaymentAmount = lastPaymentAmount;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    legitimizeCanadaFinancialServicesByVinLastPaymentRequest.clientId = this.storeAppConfig.CLIENT_ID;
    legitimizeCanadaFinancialServicesByVinLastPaymentRequest.gcId = this.userService.getGcid();

    return this.partnerContactService.legitimizeCanadaFinancialServicesByVinLastPayment(legitimizeCanadaFinancialServicesByVinLastPaymentRequest)
      .pipe(mergeMap((response) => { return this.afterLegitimizeCanadaFinancialServicesSuccess(response); }), catchError((error: any) => {
        return this.afterLegitimizeCanadaFinancialServicesFailure(error);
      }));
  }
  private legitimizeCanadaFinancialServicesByBMWId(firstName: string, lastName: string, birthDate: Date, bmwId: string): Observable<LegitimizationStatus> {
    let legitimizeCanadaFinancialServicesByBmwIdDobRequest = new LegitimizeCanadaFinancialServicesByBmwIdDobRequest

    legitimizeCanadaFinancialServicesByBmwIdDobRequest.firstName = firstName;
    legitimizeCanadaFinancialServicesByBmwIdDobRequest.lastName = lastName;
    legitimizeCanadaFinancialServicesByBmwIdDobRequest.birthDate = birthDate;
    legitimizeCanadaFinancialServicesByBmwIdDobRequest.bmwId = bmwId;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    legitimizeCanadaFinancialServicesByBmwIdDobRequest.clientId = this.storeAppConfig.CLIENT_ID;
    legitimizeCanadaFinancialServicesByBmwIdDobRequest.gcId = this.userService.getGcid();

    return this.partnerContactService.legitimizeCanadaFinancialServicesByBmwIdDob(legitimizeCanadaFinancialServicesByBmwIdDobRequest)
      .pipe(mergeMap((response) => { return this.afterLegitimizeCanadaFinancialServicesSuccess(response); }), catchError((error: any) => {
        return this.afterLegitimizeCanadaFinancialServicesFailure(error);
      }));
  }


  private afterLegitimizeCanadaFinancialServicesSuccess(result: any): Observable<LegitimizationStatus> {
    this.userService.setFsStatus(true);
    let legitimizeStatus = new LegitimizationStatus();
    return of(legitimizeStatus);
  }

  private afterLegitimizeCanadaFinancialServicesFailure(error: SwaggerException): Observable<LegitimizationStatus> {
    let legitimizationStatus = new LegitimizationStatus();

    legitimizationStatus.error = true;
    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_DataValidation_V201112_DataValidationFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.DataValidation;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_NoMatchesFoundFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.NoMatchesFound;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_UniqueMatchNotFoundFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.UniqueMatchNotFound;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_GCIDAlreadyLegitimizedFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.GCIDAlreadyLegitimized;
            break;
          }
        case FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_OwnershipValidationFault:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.OwnershipValidation;
            break;
          }
        default:
          {
            legitimizationStatus.errortype = LegitimizationErrorType.LegitimizationFailed;
            break;
          }
      }
    } else {
      legitimizationStatus.errortype = LegitimizationErrorType.LegitimizationFailed;
    }
    return of(legitimizationStatus);
  }

  public deliverSmcMessage(accountNumber: string
    , customerNumber: number
    , brand: string
    , country: string
    , messageType: string
    , systemCode: string
    , template: string
    , subjectTemplateName: string
    , attachments: {}
    , metaData: {}
    , addAchPaymentSourceModel: AddAchPaymentSourceCanModel) {

    let deliverSmcMessageRequest = new DeliverSmcMessageRequest();
    deliverSmcMessageRequest.accountNumber = accountNumber;
    deliverSmcMessageRequest.customerNumber = customerNumber;
    deliverSmcMessageRequest.brand = DeliverSmcMessageRequestBrand[brand];
    deliverSmcMessageRequest.country = DeliverSmcMessageRequestCountry[country];
    deliverSmcMessageRequest.messageType = DeliverSmcMessageRequestMessageType[messageType];
    deliverSmcMessageRequest.systemCode = DeliverSmcMessageRequestSystemCode[systemCode];
    deliverSmcMessageRequest.template = DeliverSmcMessageRequestTemplate[template];
    deliverSmcMessageRequest.subjectTemplateName = DeliverSmcMessageRequestSubjectTemplateName[subjectTemplateName];
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    deliverSmcMessageRequest.userId = this.storeAppConfig.USER_ID;
    deliverSmcMessageRequest.attachments = attachments;
    deliverSmcMessageRequest.metaData = metaData;

    return this.partnerContactService.deliverSmcMessage(deliverSmcMessageRequest)
      .pipe(mergeMap((response) => { return this.afterdeliverSmcMessageSuccess(response, addAchPaymentSourceModel); }), catchError((error: any) => {
        return this.afterdeliverSmcMessageFailure(error, addAchPaymentSourceModel);
      }));
  }

  private afterdeliverSmcMessageSuccess(result: any, addAchPaymentSourceModel: AddAchPaymentSourceCanModel): Observable<AddAchPaymentSourceCanModel> {
    addAchPaymentSourceModel.error = false;
    addAchPaymentSourceModel.confirmationNumber = result.messageId;
    return of(addAchPaymentSourceModel);
  }

  private afterdeliverSmcMessageFailure(error: any, addAchPaymentSourceModel: AddAchPaymentSourceCanModel): Observable<AddAchPaymentSourceCanModel> {
    addAchPaymentSourceModel.error = true;
    return of(addAchPaymentSourceModel);
  }
}
