import { MMAQuoteDTO } from '../../core/gateway-api';


export class MidtermMileageAdjustmentQuote {
    quoteList: MMAQuoteDTO[];  
    faultType: string;
    error: boolean;
}

export class AcceptMidtermMileageAdjustmentQuote {
  faultType: string;
  error: boolean;
}


export class AcceptAndPayMidtermMileageAdjustmentQuote {
  faultType: string;
  error: boolean;
  paymentTranId: number;  
}



