import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { MapPaymentReviewDetails } from '../../../_models/map/map-payment-review-details.model';
import { tassign } from 'tassign';


@Injectable()
export class MapPaymentReviewDetailsActions {
  static SET_MAP_PAYMENT_REVIEW_DETAILS: string = 'SET_MAP_PAYMENT_REVIEW_DETAILS';
  static CLEAR_MAP_PAYMENT_REVIEW_DETAILS: string = 'CLEAR_MAP_PAYMENT_REVIEW_DETAILS';

  setMapPaymentReviewDetails(paymentReviewDetails: MapPaymentReviewDetails): FSA<string, MapPaymentReviewDetails, null> {
    return {
      type: MapPaymentReviewDetailsActions.SET_MAP_PAYMENT_REVIEW_DETAILS,
      payload: paymentReviewDetails,
      meta: null
    };
  }

  clearMapPaymentReviewDetails(): FSA<string, MapPaymentReviewDetails, null> {
      return {
        type: MapPaymentReviewDetailsActions.CLEAR_MAP_PAYMENT_REVIEW_DETAILS,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class MapPaymentReviewDetailsReducers {
  static MapPaymentReviewDetailsReducer: Reducer<MapPaymentReviewDetails> = (state: MapPaymentReviewDetails = new MapPaymentReviewDetails(), action: FSA<string, MapPaymentReviewDetails, void>) => {
    switch (action.type) {
      case MapPaymentReviewDetailsActions.SET_MAP_PAYMENT_REVIEW_DETAILS:
        return tassign(state, action.payload);
      case MapPaymentReviewDetailsActions.CLEAR_MAP_PAYMENT_REVIEW_DETAILS:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
