import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';

@Injectable()
export class IsDarkThemeActions
{
  static SET_THEME: string = 'SET_THEME';
  setIsDarkModeTheme(toggled:boolean): FSA<string, boolean, null>{
    return {
      type: IsDarkThemeActions.SET_THEME,
      payload: toggled,
      meta: null
    };
  }
}

@Injectable()
export class IsDarkThemeReducers
{
  static IsDarkThemeReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
          case IsDarkThemeActions.SET_THEME:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
