<div class="epaas-error" *ngIf="isEpaasError">
    <div class="epaas-error-container" role="alert">
      <div class="text-container">
        <h3>{{"ngw.shared.ePaas-error-dialog.title" | translate }}</h3>
        <p>{{"ngw.shared.ePaas-error-dialog.warning" | translate }}</p>
      </div>
      <div class="action-buttons">
        <button type="button" class="btn btn-primary" id="btnCloseEpaasError" (click)="close()">{{"ngw.shared.ePaas-error-dialog.btn-cancel" | translate }}</button>
      </div>
    </div>
</div>