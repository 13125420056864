import { Component,OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ButtonState } from 'customerdigital-ui-lib';

@Component({
  selector: 'app-terms',
  templateUrl: "./terms.component.html",
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  @Input() headTitle!: string;
  @Input() checkboxLabel!: string ;
  @Input() primaryBtnText!: string;
  @Input() secondaryBtnText!: string;
  @Input() termsContentHtml!: SafeHtml;
  @Input() checkboxLink!: string;
  @Input() termsAccepted: string = ButtonState.Disable ;
  @Input() termsLoadFailed!: boolean;
  @Input() errorMessage!: string;
  @Input() showError!:  boolean;
  @Input() checkboxErrorMessage!: string;
  @Input() primaryBtnAriaLabel!: string;
  @Input() secondaryBtnAriaLabel!: string;
  @Input() primaryBtnAnalyticsEvent!: string;
  @Input() secondaryBtnAnalyticsEvent!: string;
  @Input() brandType!: string;
  /** Event emitted when user clicks on the anchor.*/
  @Output() onTermsAccepted: EventEmitter<any> = new EventEmitter<any>();
  /** Event emitted when user clicks on the anchor. */
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  /** Event emitted when user clicks on the anchor. */
  @Output() onContinue: EventEmitter<any> = new EventEmitter<any>();
  checked: boolean = false;

  public navigateToTermsView(): void{
    this.checked = !this.checked;
    this.showError = !this.checked
    this.termsAccepted = (this.checked)?ButtonState.Active:ButtonState.Disable;
    this.onTermsAccepted.emit();
   }
   public cancelTerms(): void{
    this.onCancel.emit();
   }
   public continueTerms(): void{
    this.onContinue.emit();
   }
  onChecked(event: any){
    this.termsAccepted = (event.checked)?ButtonState.Active:ButtonState.Disable;
    this.showError = !event.checked; 
  }
}
