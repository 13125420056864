
export class Base64BtnModel {
    base64: string;
    fileType: string;
    btnTxt: string;
    docName: string;
    error: boolean;

    constructor(private translateService?, _base64?: string, _fileType?: string, _btnTxt?: string, _docName?: string) {
        this.base64 = _base64;
        this.fileType = _fileType;
        if (_btnTxt) {
          this.translateService.get(_btnTxt).subscribe((res: string) => {
            this.btnTxt = res;
          })
        }
        if (_docName) {
          this.translateService.get(_docName).subscribe((res: string) => {
            this.docName = res;
          })
        }
    }
}
