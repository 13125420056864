import { Component, OnInit, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from "../../core/basecomponent/base.component";
import { LogSiteActivityService } from '../../_web-services/log-site-activity.service';
import { DOCUMENT } from '@angular/common';
import { ContractAccountDetailDTO, VehicleReturnEstimateDTO, GetVehicleShadowDetailsResponse } from '../../core/gateway-api';
import { Constants } from '../../shared/constants';
import { ActivityTypes } from '../../shared/enums';
import { UserService } from '../../shared/_helper-services/user.service';
import * as _ from 'lodash-es';
import * as $ from "jquery";
import { EndOfTermService } from '../../_web-services/end-of-term.service';
import { VehicleReturnModel } from '../../_models/vehicle-return-estimate/vehicle-return.model';
import { SelectedContractDashboardActions } from '../../shared/store/reducers/selected-contract-dashboard.reducers';
import { ContractAccountDetailActions } from '../../shared/store/reducers/contract-account-detail.reducers';
import { VehicleShadowService } from '../../_web-services/vehicle-shadow.service';

@Component({
  selector: 'app-vehicle-return-estimate',
  templateUrl: './vehicle-return-estimate.component.html',
  styleUrls: ['./vehicle-return-estimate.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VehicleReturnEstimateComponent extends BaseApplicationComponent<VehicleReturnEstimateComponent> implements OnInit {

  errorMessage: string;

  error: boolean;
  title: string;
  vehicleReturnEstimate: VehicleReturnEstimateDTO = null;
  contractDetail: ContractAccountDetailDTO;

  totalBillAmount: string;
  component: string;
  public position: string = "right";

  constructor(private _location: Location, private translateService: TranslateService,
    injector: Injector,
    private logSiteActivityService: LogSiteActivityService,
    private endOfTermService: EndOfTermService,
    private userService: UserService,
    private vehicleShadowService: VehicleShadowService,
    private selectedContractDashboardActions: SelectedContractDashboardActions,
    private contractAccountDetailActions: ContractAccountDetailActions,
    @Inject(DOCUMENT) document) {
    super(injector, document);

    this.translateService.get('ngw.vehicle-return.title').subscribe((res: string) => {
      this.title = res;
    })

  }

  ngOnInit() {
    super.pushDataLayer();
    this.getVehicleReturnEstimate();
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    let accountNumber = this.contractDetail.accountNumber;
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
      });

    this.logUserActivity(this.userService.getCustomerNumber(), accountNumber);
    if (window.innerWidth < 761) {
      this.position = "above";
    }
  }

  public navigateToBack(): void {
    this.store.dispatch(this.selectedContractDashboardActions.setSelectedContractDashboard(this.contractDetail));
    this.store.dispatch(this.contractAccountDetailActions.setContractAccountDetail(this.contractDetail));
    this._location.back();
  }

  logUserActivity(customerNumber: number, accountNumber: string) {
    this.logSiteActivityService.logUserActivity(customerNumber, accountNumber, ActivityTypes.VehicleReturnEstimate);
  }

  public getVehicleReturnEstimate() {
    super.setDataLoadedOnRequest(false);
    this.endOfTermService.getVehicleReturnEstimate(undefined, true, true)
      .subscribe(response => this.postGetVehicleReturnEstimate(response));
  }

  private postGetVehicleReturnEstimate(response: VehicleReturnModel) {
    if (!response.error) {
      this.error = false;
      this.vehicleReturnEstimate = response.vehicleReturnEstimate;
      // Fetch Vehicle Shadow Data
      this.vehicleShadowService.getVehicleShadowDetails(this.contractDetail.vIN, this.userService.getCustomerNumber(), this.contractDetail.accountNumber)
        .subscribe(response => this.postGetVehicleShadowData(response, this.vehicleReturnEstimate));
    }
    else {
      this.error = true;
      const errorTranslationKey = 'ngw.global.technical-error'
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.errorMessage = res;
      })
      this.pushErrorEvent(errorTranslationKey);
    }

  }

  private postGetVehicleShadowData(response: GetVehicleShadowDetailsResponse, vehicleReturnEstimateDto: VehicleReturnEstimateDTO) {
    if (response.vehicleShadowDto != undefined) {
      if (response.vehicleShadowDto.mileage > 0) {
        vehicleReturnEstimateDto.inspection.inspectionDate = new Date();
        vehicleReturnEstimateDto.excessMileageDetails.actualMiles = response.vehicleShadowDto.mileage != 0 ? response.vehicleShadowDto.mileage : vehicleReturnEstimateDto.excessMileageDetails.actualMiles;
        if (vehicleReturnEstimateDto.excessMileageDetails.actualMiles > vehicleReturnEstimateDto.excessMileageDetails.totalAllowedMiles) {
          vehicleReturnEstimateDto.excessMileageDetails.excessMiles = Math.abs(vehicleReturnEstimateDto.excessMileageDetails.actualMiles - vehicleReturnEstimateDto.excessMileageDetails.totalAllowedMiles);
          vehicleReturnEstimateDto.excessMileageDetails.total = Math.abs(vehicleReturnEstimateDto.excessMileageDetails.actualMiles - vehicleReturnEstimateDto.excessMileageDetails.totalAllowedMiles) * vehicleReturnEstimateDto.excessMileageDetails.excessChargePerMile;
        }
        else {
          // Reset excess mileage and charges
          vehicleReturnEstimateDto.excessMileageDetails.excessMiles = 0;
          vehicleReturnEstimateDto.excessMileageDetails.total = 0;
        }
      }
    }
    super.setDataLoadedOnRequest(true);
  }
}
