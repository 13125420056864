import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from "../store/app.store";
import { UserIsTimedOutActions } from '../store/reducers/user-is-timed-out.reducer';
import { DialogService } from './dialog.service';
import { UserService } from './user.service';
import { EnvironmentConfig } from '../../../_shared/_models/environment-config';

@Injectable()
export class IdleService {
  timeoutWarningDialogIsShown: boolean = false;
  dialogRef = null;
  intTime;

  constructor(private idle: Idle,
    public dialog: MatDialog,
    private userService: UserService,
    private dialogService: DialogService,
    private store: Store<IAppState>,
    private userIsTimedOutActions: UserIsTimedOutActions,
    private translateService: TranslateService) { }

  public initIdle(): void {
    let storeEnvConfig: EnvironmentConfig;
    this.store.select(state => state.EnvironmentConfig).subscribe(x => storeEnvConfig = x);
    // sets an idle timeout of 18 minutes
    let idle_Session: number = storeEnvConfig.IDLE_SESSION;
    this.idle.setIdle((idle_Session ? idle_Session : 0) * 60);
    //sets a timeout period of 2 minutes. after 20 minutes of inactivity, the user will be considered timed out.
    let time_Out_Session: number = storeEnvConfig.TIME_OUT_SESSION;
    this.idle.setTimeout((time_Out_Session ? time_Out_Session : 0) * 60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      this.store.dispatch(this.userIsTimedOutActions.setUserIsTimedOut(true));
      this.dialogService.closeDialogsBeforeTimeout();
      this.userService.logout();
      this.toggleWarningIdleDialog(false);
      this.stopIdle();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      if (!this.timeoutWarningDialogIsShown) {
        this.toggleWarningIdleDialog(true);
      }
    });

    this.reset();

  }

  public reset() {
    this.idle.watch();
    this.timeoutWarningDialogIsShown = false;
  }

  public stopIdle() {
    this.idle.stop();
  }

  public toggleWarningIdleDialog(showDialog: boolean) {
    if (showDialog) {
      this.dialogService.openIdleDialog();
    }
    else {
      this.dialogService.closeIdleDialog();
    }
    this.timeoutWarningDialogIsShown = showDialog;
  }

  private setWarningDialog(isShown: boolean): void {
    this.timeoutWarningDialogIsShown = isShown;
  }

  private pauseInterrupts() {
    this.idle.clearInterrupts();
  }



}

