import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.store';
import { AccountInfoService } from './account-info.service';
import { ContractAccountDetailDTO } from '../../../_shared/core/gateway-api';
import { ObjectService } from './object.service';
import { ConditionCode } from '../enums';
import { HeaderMenuModel } from 'customerdigital-ui-lib';
import { DialogService } from './dialog.service';
import { RouterService } from './router.service';
import { Offers } from '../../_models/offerModel';
import { LanguageService } from './language.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderHelperService {

    private eotContracts = [];
    languageChanged: boolean = false;
    routerHistory = new BehaviorSubject<any>({internalRoutes: [], externalRoutes: null});

    constructor(
        private infoService: AccountInfoService,
        private objectService: ObjectService,
        private routerService: RouterService,
        private languageService: LanguageService,
        public store: Store<IAppState>,
        private injector: Injector
    ) { }

    private dialogService = this.injector.get(DialogService);

    public shouldAddEOTOption(contracts: any): boolean {

        let shouldAddEOTOption = false
        this.eotContracts = [];
        let v2Enabled: boolean;
        this.store.select(state => state.V2Enabled.booleanValue).subscribe(x => v2Enabled = x);
        if ((!this.objectService.isEmptyObject(contracts) || contracts.length > 0)
            && (v2Enabled)) {
            Object.keys(contracts).forEach(key => {
                // Date Info
                let contract: ContractAccountDetailDTO = contracts[key];
                const today = new Date();
                const startDate = new Date(contract.contractStartDate);
                const endDate = new Date(contract.currentContractEndDate);
                const oneYearAfterBookDate = startDate.setFullYear(startDate.getFullYear() + 1);
                const expirationStartDate = endDate.setDate(endDate.getDate() - 190);

                // Account Info
                const isSelectAccount = this.infoService.isSelectAccount(contract.contractType);
                const isRetailAccount = this.infoService.isRetailAccount(contract.contractType);
                const isLeaseOrOCContractType = this.infoService.isLeaseOrOCContractType(contract.contractType);
                const isPrePayLease = this.infoService.isPrePayLeaseContractType(contract.contractType);
                const isReLease = this.infoService.isReLeaseContractType(contract.contractType);
                const isRetailRefiDirect = this.infoService.isRetailRefiDirectContractType(contract.contractType);
                const isLeaseEnd = this.infoService.isAccountConditionCodeExist(contract, ConditionCode.LeaseEnd);


                // Conditions to display EOT Center Option
                const condition01 = isSelectAccount && today.getTime() > expirationStartDate;
                const condition02 = (isRetailAccount || isRetailRefiDirect) && today.getTime() > oneYearAfterBookDate;
                const condition03 = (isReLease || isPrePayLease || isLeaseOrOCContractType) && isLeaseEnd;

                if (condition01 || condition02 || condition03) {
                    shouldAddEOTOption = true;
                    this.eotContracts.push(contract);
                }
            });
        }
        return shouldAddEOTOption;
    }


    public shouldAddCAEOTOption(contracts: any): boolean {

        let shouldAddEOTOption = false
        this.eotContracts = [];
        if ((!this.objectService.isEmptyObject(contracts) || contracts.length > 0)) {
            Object.keys(contracts).forEach(key => {
                // Date Info
                let contract: ContractAccountDetailDTO = contracts[key];
                const today = new Date();
                const startDate = new Date(contract.contractStartDate);
                const endDate = new Date(contract.currentContractEndDate);
                const oneYearAfterBookDate = startDate.setFullYear(startDate.getFullYear() + 1);
                const expirationStartDate = endDate.setDate(endDate.getDate() - 190);

                // Account Info
                const isSelectAccount = this.infoService.isSelectAccount(contract.contractType);
                const isRetailAccount = this.infoService.isRetailAccount(contract.contractType);
                const isLeaseOrOCContractType = this.infoService.isLeaseOrOCContractType(contract.contractType);
                const isPrePayLease = this.infoService.isPrePayLeaseContractType(contract.contractType);
                const isReLease = this.infoService.isReLeaseContractType(contract.contractType);
                const isRetailRefiDirect = this.infoService.isRetailRefiDirectContractType(contract.contractType);
                const isLeaseEnd = this.infoService.isAccountConditionCodeExist(contract, ConditionCode.LeaseEnd);


                // Conditions to display EOT Center Option
                const condition01 = isSelectAccount && today.getTime() > expirationStartDate;
                const condition02 = (isRetailAccount || isRetailRefiDirect) && today.getTime() > oneYearAfterBookDate;
                const condition03 = (isReLease || isPrePayLease || isLeaseOrOCContractType) && isLeaseEnd;

                if (condition01 || condition02 || condition03) {
                    shouldAddEOTOption = true;
                    this.eotContracts.push(contract);
                }
            });
        }
        return shouldAddEOTOption;
    }

    public endOfTermNavigation(menuItems: Array<HeaderMenuModel>, currentMenu: string) {

        this.vinFromAccountSelectionDialog(menuItems, currentMenu, this.eotContracts).then(vin => {
            if (vin != null) {
                let currentLanugage = this.languageService.getCurrentLanguage();
                this.routerService.navigateToEndOfTerm({ vin: vin, language:currentLanugage });
            }
        })
    }

    public paymentCenterEnabled(): boolean {
        let isPaymentOverviewEnabled: boolean;
        let isPaymentDetailsEnabled: boolean;
        this.store.select(state => state.V2PaymentOverview.booleanValue).subscribe(x => isPaymentOverviewEnabled = x);
        this.store.select(state => state.V2PaymentDetails.booleanValue).subscribe(x => isPaymentDetailsEnabled = x);

        return (isPaymentOverviewEnabled || isPaymentDetailsEnabled);
    }

    public navigatePaymentCenter(menuItems: Array<HeaderMenuModel>, currentMenu: string) {
        let contracts: any;
        let arrContracts: ContractAccountDetailDTO[] = [];
        this.store.select(state => state.ContractAccountDetails).subscribe(x => contracts = x);

        if (!this.objectService.isEmptyObject(contracts) || contracts.length > 0) {
            Object.keys(contracts).forEach(key => {
                // Date Info
                let contract: ContractAccountDetailDTO = contracts[key];
                arrContracts.push(contract);
            });
        }

        this.vinFromAccountSelectionDialog(menuItems, currentMenu, arrContracts).then(vin => {
            if (vin != null) {
                this.navigateToPaymentCenter(vin);
            }
        })
    }

    private vinFromAccountSelectionDialog(menuItems: Array<HeaderMenuModel>, currentMenu: string, contracts: ContractAccountDetailDTO[]): Promise<string> {
        return new Promise(resolve => {
            if (contracts?.length === 1) {
                resolve(contracts[0]?.vIN);
            } else {
                let contractChangeDialogRef = this.dialogService.openContractChangeDialog(false, contracts, null, 'ngw.shared.choose-vehicle-dialog.title');

                contractChangeDialogRef.afterClosed().subscribe(accountNumber => {
                    if (accountNumber) {
                        let selectedContract = contracts.find((c: ContractAccountDetailDTO) => c.accountNumber === accountNumber);
                        resolve(selectedContract?.vIN);
                    }
                    else {
                        menuItems?.forEach((item) => item.active = item.id === currentMenu.toLowerCase() ? true : false);
                        resolve(null);
                    }
                });
            }
        });
    }

    private navigateToPaymentCenter(vin: string) {
        this.store.select(state => state.V2PaymentOverview.booleanValue).subscribe(paymentOverViewEnabled => {
            if (paymentOverViewEnabled) {
                this.routerService.navigateToPaymentOverview({ vin: vin });
            } else {
                this.routerService.navigateToPaymentDetailsV2({ vin: vin });
            }
        });
    }

    public offersEnabled(): boolean {
        let isOffersEnabled: boolean;
        let loadedOffers: Offers;
        this.store.select(state => state.OffersEnabled).subscribe(x => isOffersEnabled = x.booleanValue);
        this.store.select(state => state.offers).subscribe(x => loadedOffers = x);
        if (isOffersEnabled) {
            if (loadedOffers && loadedOffers.hasOffer !== undefined) {
                return true;
            }
            return false;
        }
    }

    public eotEnabled(): boolean {
        let isEotEnabled: boolean;
        this.store.select(state => state.EndOfTerm).subscribe(x => isEotEnabled = x.booleanValue);
        return isEotEnabled;
    }

    public showOffers(): boolean {
        let offers: Offers;
        let offersEnabled: boolean;
        let showOffer: boolean = false;

        this.store.select(state => state.OffersEnabled.booleanValue).subscribe(x => offersEnabled = x);
        this.store.select(state => state.offers).subscribe(x => offers = x);
        if (offersEnabled === true && offers?.offerModel?.length > 0) {
            showOffer = true;
        }
        return showOffer;
    }

    setRouteHistory(value) {
        this.routerHistory.next(value);
    }
}