import { Injectable, Injector, EventEmitter } from '@angular/core';
import { Constants } from '../constants';
import { IAppState } from "../store/app.store";
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UploadAttachment, Attachments } from '../../_models/uploadAttachment.model';
import { EnvironmentConfig } from '../../_models/environment-config';

@Injectable()
export class UploadAttachmentService {

  fileAttachments: Attachments;
  allowableFileExtension: string[] = ["application/pdf", "image/png", "image/gif", "image/jpeg", "image/tif", "image/gif", "image/tiff"]; // Need to check if this can go to enviornment config.
  public attachedmentAdded: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translateService: TranslateService, private store: Store<IAppState>) {
  }

  public initAttachments(): Attachments {
    if (!this.fileAttachments) {
      this.fileAttachments = new Attachments(this.translateService);
      let storeEnvConfig: EnvironmentConfig;
      this.store.select(state => state.EnvironmentConfig).subscribe(envConfig => storeEnvConfig = envConfig);
      this.fileAttachments.uploadLimit = storeEnvConfig.SMC_ATTACHMENT_SIZE_LIMIT;
      this.fileAttachments.remainingUploadDocumentsSize = storeEnvConfig.SMC_ATTACHMENT_SIZE_LIMIT;
      this.fileAttachments.totalUploadDocumentsSize = storeEnvConfig.SMC_ATTACHMENT_SIZE_LIMIT;
    }

    return this.fileAttachments;
  }

  public onAttachmentChange(event, attachmentDesc): Attachments {
    this.fileAttachments.errorMessage = undefined;
    this.fileAttachments.error = false;
    var files = event.target.files || event.srcElement.files;
    for (var x = 0; x < files.length; x++) {
      let file = files[x];
      this.isFileValidToUpload(file) ? this.convertAttachmentToBase64String(file, attachmentDesc) : this.translateService.get("ngw.shared.upload-attachment.invalid-attachment").subscribe((res: string) => {
        this.fileAttachments.errorMessage = res;
        this.fileAttachments.error = true;
        this.attachedmentAdded.emit(true);
      });
    }

    return this.fileAttachments;
  }

  private convertAttachmentToBase64String(inputFile: any, attachmentDesc): void {
    let base64String: string;
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      base64String = myReader.result.toString();
      let uploadAttachment = new UploadAttachment();
      uploadAttachment.base64Data = this.cleanBase64String(base64String);
      uploadAttachment.name = inputFile.name;
      uploadAttachment.size = inputFile.size;
      uploadAttachment.type = inputFile.type;
      uploadAttachment.sizeMB = + (inputFile.size / Constants.KiloBit / Constants.KiloBit).toFixed(Constants.FixedDecimalPoint);
      uploadAttachment.attachmentDesc = attachmentDesc;
      this.fileAttachments.add(uploadAttachment);
      this.fileAttachments.calculateTotalUploadedAttachmentsSize();
      this.attachedmentAdded.emit(true);
    }
    myReader.readAsDataURL(inputFile);
  }

  private isFileValidToUpload(file: any): boolean {
    return (this.allowableFileExtension.indexOf(file.type) > -1 && this.checkValidFileName(file.name)) ? true : false;
  }

  private cleanBase64String(base64String: string): string {
    // Remove base64 header as it is not required.
    if (base64String && base64String.length > 0) {
      return base64String.slice(base64String.indexOf(",") > -1 ? (base64String.indexOf(",") + 1) : 0, base64String.length);
    }
    return base64String;
  }

  private checkValidFileName(fileName: string): boolean {
    if (fileName && fileName.length > 0) {
      let splitArray = fileName.split(".");
      return splitArray.length == Constants.FixedDecimalPoint ? true : false;
    }
  }

  public clearAttachments() {
    this.fileAttachments = null;
  }

  public deleteAttachment(attachment: UploadAttachment) {
    if (this.fileAttachments != undefined
      && this.fileAttachments.attachments.length > 0
      && attachment != undefined
      && attachment.name.length > 0) {

      // filtering by removing an item from array
      this.fileAttachments.attachments = this.fileAttachments.attachments.filter(x => x.name != attachment.name);
    }
  }
}
