import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
@Component({
  selector: 'how-to-redeem-credit-points-dialog',
  templateUrl: './how-to-redeem-credit-points-dialog.component.html',
  styleUrls: ['./how-to-redeem-credit-points-dialog.component.scss']
})

export class HowToRedeemCreditPointsDialog extends BaseApplicationComponent<HowToRedeemCreditPointsDialog>
{
  constructor(public dialogRef: MatDialogRef<HowToRedeemCreditPointsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
    
  }

  public closeDialog(): void {
    this.dialogRef.close(null);
  }
}
