import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class IsEpassInitializedActions
{
    public static EPAAS_INITIALIZED: string = 'IsEpassInitialized';

    setIsEpassInitialized(value: boolean): FSA<string, boolean, null>{
        return {
            type: IsEpassInitializedActions.EPAAS_INITIALIZED,
            payload: value,
            meta: null
        };
    }

}

@Injectable()
export class IsEpassInitializedReducers
{
    static IsEpassInitializedReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
            case IsEpassInitializedActions.EPAAS_INITIALIZED:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
