import { DeliveryChannelDetailsDTO } from "../../core/gateway-api";

export class PaymentDeferralEligibilityModel {
  eligible: boolean;
  paymentToPostpone: Date;
  isOnlyHolder: boolean;
  requiresWrittenConsent: boolean;
  acceptAgreement: boolean;
  acceptSignatureAgreement: boolean;
  deliveryChannelDetails: DeliveryChannelDetailsDTO[];
  deferralNotEligibleReason: string;
  deferralTypeId: number;
  deferralTypeText: string;
  error: boolean;
  errorType: PaymentDeferralErrorType;
  

  constructor(
    eligible?: boolean,
    paymentToPostpone?: Date,
    isOnlyHolder?: boolean,
    requiresWrittenConsent?: boolean,
    deliveryChannelDetails?: DeliveryChannelDetailsDTO[],
    deferralNotEligibleReason?: string,
    deferralTypeId?: number,
    deferralTypeText?: string,
    error?: boolean,
    errorType?: PaymentDeferralErrorType)
  {
    this.eligible = eligible;
    this.paymentToPostpone = paymentToPostpone;
    this.isOnlyHolder = isOnlyHolder;
    this.requiresWrittenConsent = requiresWrittenConsent;
    this.deliveryChannelDetails = deliveryChannelDetails;
    this.deferralNotEligibleReason = deferralNotEligibleReason;
    this.deferralTypeId = deferralTypeId;
    this.deferralTypeText = deferralTypeText;
    this.error = error;
    this.errorType = errorType;
  }
}

export enum PaymentDeferralErrorType {
  AccountNotFoundError = 1,
  DeferralNotEligible = 2,
  SiebelEAIException = 3,
  Unknown = 4

}
