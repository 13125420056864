import assign from 'lodash-es/assign';
import cloneDeep from 'lodash-es/cloneDeep';
import compact from 'lodash-es/compact';
import difference from 'lodash-es/difference';
import includes from 'lodash-es/includes';
import kebabCase from 'lodash-es/kebabCase';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeepLinkParam } from '../../../_shared/_models/deeplink-param.model';
import { AnalyticsConfig } from '../../../_shared/_models/environment-config';
import {
  AnalyticsAttribute,
  AnalyticsDeepLinkTracking,
  AnalyticsMyAccountTracking,
  AnalyticsPageTracking,
  BaseDataLayerModel,
  CreateInsertScriptParams,
  PushDataLayerParameters
} from '../../_models/analytics/analytics-data.model';
import { AnalyticsPageDetail, AnalyticsPageDetails } from '../analytics-page-details';
import { Constants } from '../constants';
import { AnalyticsAttributeName, AnalyticsEvent, AnalyticsLinkType } from '../enums';
import { IAppState } from '../store/app.store';
import { route } from './router.service';
import { UserService } from './user.service';
import { AnalyticsIdOverrideFactoryService } from '../../_models/analytics/analytics-id-override-factory.service'

declare global {
  interface Window {
    _satellite?: {
      track: (callName: AnalyticsEvent, details?: any) => void
    };
  }
}

@Injectable()
export class AnalyticsService {
  private analyticsPageDetails!: AnalyticsPageDetail[];
  private analyticsTags: Partial<AnalyticsAttribute>[] = [
    {
      name: AnalyticsAttributeName.ACCORDION_LINK,
      event: AnalyticsEvent.ACCORDION_LINK,
    },
    {
      name: AnalyticsAttributeName.CUSTOM_LINK,
      event: AnalyticsEvent.BODY_LINK,
    },
  ]
  private embedScriptsLoaded: boolean = false;
  private environmentConfig: Partial<AnalyticsConfig>;
  private globalClickSubScription: Subscription = new Subscription();
  private isClickHandlerAttached: boolean = false;
  private maxAncesterCheck = 5;
  private validLinkElements = [Constants.LinkTagName, Constants.ButtonTagName];

  public constructor(
    private idOverride: AnalyticsIdOverrideFactoryService,
    private store: Store<IAppState>,
    private userService: UserService
  ) { }

  get config(): Partial<AnalyticsConfig> {
    if (this.environmentConfig === undefined) {
      this.store.select(state => state.EnvironmentConfig.ANALYTICS_CONFIG).subscribe(x => this.environmentConfig = x);
    }
    return this.environmentConfig || {};
  }

  get currentDataLayer(): Partial<BaseDataLayerModel> {
    if (!this.config) return {};

    const dataLayer = window[this.config.DATALAYER_NAME]
    return cloneDeep(dataLayer) || {};
  }

  get deepLinkTracking(): AnalyticsDeepLinkTracking | null {
    let deepLinkParams: DeepLinkParam | null;
    this.store.select(state => state.DeepLinkParam).subscribe(x => deepLinkParams = x);
    return !deepLinkParams ? null : {
      target: deepLinkParams.pageId,
      source: deepLinkParams.source
    }
  }

  get debug(): boolean {
    return this.config.DEBUG == true;
  }

  get pageDetails(): AnalyticsPageDetail[] {
    if (!this.analyticsPageDetails) {
      let overwritePageDetails : AnalyticsPageDetail[];
      this.store.select(state => state.ApplicationConfig.ANALYTICS_PAGE_DETAIL_OVERWRITES).subscribe(x => overwritePageDetails = x);
      const overwritePaths = overwritePageDetails.map(details => details.path);
      this.analyticsPageDetails = AnalyticsPageDetails
        .filter(details => !overwritePaths.includes(details.path))
        .concat(overwritePageDetails);
    }

    return this.analyticsPageDetails;
  }

  private getCurrentPageDetails(): Partial<AnalyticsPageTracking> {
    const path = location.pathname.slice(1);
    const foundDetails = this.pageDetails.find(page => page.path == path);
    const page = foundDetails || { path, name: path };

    if (!foundDetails) {
      if (this.debug) {
        const pagesWithoutDetails = () => {
          return difference(Object.values(route), this.pageDetails.map(page => page.path));
        }
        console.warn(`Page details could not be found for ${path}. Please add details to analytics-page-details.`,
          { pagesWithoutDetails });
      }
      return page;
    }

    const buildPageDetails = (): Partial<AnalyticsPageTracking> => {
      return {
        name: `${Constants.MyAccount}:${page.name}`,
        pageType: page.name,
        pageLevel2: page.name,
        title: page.name
      }
    }

    return page.name ? buildPageDetails() : page.details;
  }

  /**
   * @description calls analytics event
   * @param callName name of call being made (string)
   */
  public analyticsCall(callName: AnalyticsEvent, details: any = undefined, delay: number = 0) {
    if (!window._satellite) {
      if (this.debug)
        console.warn(
          `${callName} could not be called because _satellite not yet available`,
          { details }
        );
      return;
    }

    setTimeout(() => window._satellite.track(callName, details), delay);
  }

  /**s
   * @description attaches a global click handler used for custom link tracking
   */
  public attachClickHandler() {
    if (this.isClickHandlerAttached || !this.config.CUSTOM_LINK_TRACKING)
      return;
    const local = location.hostname === "localhost";

    this.globalClickSubScription.add(
      fromEvent(document, "mousedown").subscribe((event: MouseEvent) => {
        const element: HTMLElement = event.target as HTMLElement;
        const tag: string = element.tagName.toUpperCase();
        const analyticsAttribute: Partial<AnalyticsAttribute> =
          this.findAnalyticsAttribute(element, this.analyticsTags) || {};

        if ((analyticsAttribute.value || "").toLowerCase() === "false") return;

        const isBodyLink: boolean =
          !this.isFooterLink(element) && !this.isHeaderLink(element);
        const trackableLink: boolean =
          Boolean(analyticsAttribute.event) ||
          (isBodyLink && includes(this.validLinkElements, tag));

        if (!trackableLink) return;

        switch (analyticsAttribute.event) {
          case AnalyticsEvent.ACCORDION_LINK: {
            this.trackAccordianLink(analyticsAttribute.taggedElement, analyticsAttribute.value);
            break;
          }

          default: {
            this.trackCustomLink(element, tag, analyticsAttribute);
            break;
          }
        }
      })
    );

    this.isClickHandlerAttached = true;
  }

  public attachRouteTrigger = (router: Router) => {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.pushDataLayer({ dataLayer: {} });
    })
  }

  private buildDataLayer = (dataLayer: Partial<BaseDataLayerModel>): BaseDataLayerModel => {
    const deepLinkTracking = ((dataLayer || {}).page || {}).pageType == Constants.RedirectComponentName ? this.deepLinkTracking : null;
    const currentPageDetails = this.getCurrentPageDetails();
    const baseDataLayer = new BaseDataLayerModel();
    const currentMyAccount = (this.currentDataLayer || {}).myAccount || {};
    const myAccountSiteTools: Partial<AnalyticsMyAccountTracking> = {
      siteTool: (dataLayer.myAccount || {}).siteTool || currentMyAccount.siteTool,
      siteToolStep: (dataLayer.myAccount || {}).siteToolStep || currentMyAccount.siteToolStep
    };
    return {
      ...baseDataLayer, ...dataLayer,
      deepLinkTracking,
      myAccount: { ...baseDataLayer.myAccount, ...dataLayer.myAccount, ...myAccountSiteTools },
      page: {
        ...baseDataLayer.page, ...dataLayer.page, ...currentPageDetails,
        baseDomain: this.config.BASE_DOMAIN,
        primarySection: Constants.MyAccount,
        subDomain: this.config.SUB_DOMAIN,
      },
      user: {
        gcid: this.userService.getGcid() ? this.userService.getGcid() : null,
        loginStatus:
          this.userService.isLoggedIn() !== null
            ? Constants.LoggedInState
            : Constants.AnonymousState
      }
    };
  }

  private dynamicLinkName(element: HTMLElement, tag: string, embedLinkName?: string): string {
    const embeddedOverrides = (embedLinkName || '').split('.');
    const overrides = {
      linkName: embeddedOverrides.pop(),
      linkDestination: embeddedOverrides.pop(),
      linkLocation: embeddedOverrides.pop()
    }

    const linkName: string = kebabCase(overrides.linkName || element.innerText);
    const linkDestination: string = kebabCase(overrides.linkDestination) || linkName;
    const linkLocation = kebabCase(overrides.linkLocation)
      || this.isComponentFooterLink(element)
      || Constants.BodyTagName.toLowerCase();

    const buttonType = (classes: string): AnalyticsLinkType => {
      return includes(classes.toUpperCase(), Constants.PrimaryClass)
        ? AnalyticsLinkType.CTA_BUTTON
        : AnalyticsLinkType.BUTTON;
    };

    const type: AnalyticsLinkType =
      tag === Constants.ButtonTagName
        ? buttonType(element.className)
        : AnalyticsLinkType.LINK;

    if (!linkName) {
      if (this.debug) {
        console.warn(
          `The following element does not have any text for link tracking`,
          { element });
      }
      return null;
    }

    const nameArray = [linkLocation, linkDestination, linkName, type];
    return nameArray.join(Constants.LinkNameSeparator);
  }

  /**
   * @description creates the script tag with adobe launch in index.html on load
   */
  public embedAnalyticsScripts() {
    if (this.embedScriptsLoaded || !this.config.EMBED_SCRIPT_URL) {
      return;
    }

    const createInsertScript = ({ scriptId, scriptSrc, scriptText }: CreateInsertScriptParams): void => {
      const scriptTag = document.createElement(Constants.AnalyticsScriptTagName) as HTMLScriptElement;
      if (scriptId) scriptTag.id = scriptId;
      scriptTag.lang = Constants.AnalyticsScriptLanguage;
      scriptTag.type = Constants.AnalyticsScriptType;
      scriptTag.async = true;
      if (scriptSrc) scriptTag.src = scriptSrc;
      if (scriptText) scriptTag.text = scriptText;
      document.head.insertBefore(scriptTag, document.head.firstChild);
    }

    createInsertScript({ scriptId: Constants.AnalyticsScriptId, scriptSrc: this.config.EMBED_SCRIPT_URL });
    if (!window[this.config.DATALAYER_NAME]) window[this.config.DATALAYER_NAME] = this.buildDataLayer({});

    this.embedScriptsLoaded = true;
  }

  private exitLinkName(
    element: HTMLAnchorElement,
    linkName: string
  ): string | null {
    const destination = element.href;
    if (!destination) return null;

    const destinationHostName = new URL(destination).hostname;
    if (destinationHostName == location.hostname) return null;

    const linkNameArray = linkName.split(Constants.LinkNameSeparator);
    linkNameArray.splice(-1, 1, Constants.ExitLinkSuffix);
    return linkNameArray.join(Constants.LinkNameSeparator);
  }

  private findAnalyticsAttribute(
    element: HTMLElement,
    possibleTags: Partial<AnalyticsAttribute>[],
    ancestor: number = 0
  ): AnalyticsAttribute | null {
    const elementsToIgnore = [Constants.BodyTagName, Constants.HtmlTagName];
    if (
      ancestor == this.maxAncesterCheck ||
      !element ||
      includes(elementsToIgnore, element.tagName.toUpperCase())
    )
      return null;

    const foundTags = compact(
      possibleTags.map((tag: AnalyticsAttribute) => {
        const value = element.getAttribute(tag.name);
        return value !== null
          ? Object.assign({ taggedElement: element, value } as Partial<AnalyticsAttribute>, tag)
          : null;
      }));

    return foundTags.length ? foundTags[0] : this.findAnalyticsAttribute(element.parentElement, possibleTags, ancestor + 1);
  }

  private isComponentFooterLink(element: HTMLElement): string {
    const footer = document.querySelector(Constants.FooterComponentSelector)
      || document.querySelector(Constants.FooterWebviewClassSelector);
    if (!footer) return Constants.EMPTY;
    return footer.contains(element) ? Constants.footer : Constants.EMPTY;
  }

  private isFooterLink(element: HTMLElement): boolean {
    if (this.config.IS_MOBILE) return false;

    const footer = document.querySelector(Constants.FooterSelector);
    if (!footer) return false;
    return footer.contains(element);
  }

  private isHeaderLink(element: HTMLElement): boolean {
    const header = document.querySelector(Constants.HeaderSelector) || document.querySelector(Constants.HeaderComponentSelector);
    if (!header) return false;
    return header.contains(element);
  }

  /**
   * @description pushes/updates the Datalayer object on respective pages
   * @param pushDataLayerParameters includes datalayer (js Object), eventName (string), and additionalArguments (any)
   */
  public pushDataLayer({ dataLayer, eventName, additionalArguments, delay }: PushDataLayerParameters) {
    if (!this.embedScriptsLoaded) this.embedAnalyticsScripts;
    if (!this.config.DATALAYER_NAME) return;

    const finalDataLayer: BaseDataLayerModel = this.buildDataLayer(dataLayer);

    window[this.config.DATALAYER_NAME] = finalDataLayer;

    if (eventName) this.analyticsCall(eventName, additionalArguments, delay);
  }

  /**
   * @description updates data layer and triggers direct call for download link tracking
   */
  public pushDownloadEvent(downloadFileName: string): void {
    const currentDataLayer = this.currentDataLayer;
    const downloadDataLayer: Partial<BaseDataLayerModel> = {
      downloadTracking: {
        downloadFileName
      }
    }
    const dataLayer: Partial<BaseDataLayerModel> = assign({}, currentDataLayer, downloadDataLayer);

    this.pushDataLayer({
      dataLayer: dataLayer,
      eventName: AnalyticsEvent.DOWNLOAD
    });
  }

  /**
   * @description pushes analytics error event
   * @param errorName error name (as string)
   */
  public pushErrorEvent(errorName: string, eventName: AnalyticsEvent = AnalyticsEvent.ERROR, delay: number = 1500) {
    const currentDataLayer = this.currentDataLayer;
    const errorTracking = { errorName };
    const pageDetails = { ...currentDataLayer.page, pageType: Constants.ErrorPageType };
    this.pushDataLayer({ dataLayer: { ...currentDataLayer, errorTracking, page: pageDetails }, eventName, delay });
  }

  /**
   * @description updates data layer and triggers direct call for siteTool tracking
   */
  public pushSiteToolEvent(siteToolTracking: Partial<AnalyticsMyAccountTracking>, eventName: AnalyticsEvent = AnalyticsEvent.SITE_TOOL) {
    const currentDataLayer = this.currentDataLayer;
    const myAccountDetails: Partial<AnalyticsMyAccountTracking> = { ...currentDataLayer.myAccount, ...siteToolTracking };
    this.pushDataLayer({ dataLayer: { ...currentDataLayer, myAccount: myAccountDetails }, eventName });
  }

  /**
   * @description updates data layer and triggers direct call for siteTool error tracking
   */
  public pushSiteToolError(siteToolTracking: { siteTool?: string, siteToolError: string, siteToolStep?: string }) {
    this.pushSiteToolEvent(siteToolTracking, AnalyticsEvent.SITE_TOOL_ERROR);
  }

  public setIdOverride() {
    this.idOverride.getAnalyticsIdOverrideService().setOverrideId();
  }

  /**
   * @description updates data layer and triggers direct call for accordian link tracking
   */
  private trackAccordianLink(element: HTMLElement, definedLinkName?: string): void {

    if (element.tagName.toUpperCase() != Constants.ExpansionPanelHeaderClass.toUpperCase() && this.debug) {
      console.warn("trackAccordianLink triggered for an element that is not a mat-expansion-panel-header");
      return;
    }

    const headerClassNames = element.classList;
    const currentDataLayer = this.currentDataLayer;
    const myAccount: Partial<AnalyticsMyAccountTracking> = {
      ...currentDataLayer.myAccount, ... {
        accordionLink: definedLinkName || element.innerText,
        accordionLinkStatus: includes(headerClassNames, Constants.ExpansionPanelHeaderExpandedClass)
          ? Constants.OpenedExpansionPanel
          : Constants.ClosedExpansionPanel,
      }
    };

    this.pushDataLayer({ dataLayer: { ...currentDataLayer, myAccount }, eventName: AnalyticsEvent.ACCORDION_LINK });
  }

  /**
   * @description defines 4 parameter linkPosition (or link name) and triggers direct call for custom link
   */
  private trackCustomLink(
    element: HTMLElement,
    tag: string,
    analyticsAttribute: Partial<AnalyticsAttribute>
  ): void {
    const embedLinkName: string | null = analyticsAttribute.value;
    const isCompleteEmbedLinkName: boolean = (embedLinkName || '').split('.').length == 4;
    const rawLinkName = isCompleteEmbedLinkName ? embedLinkName : this.dynamicLinkName(element, tag, embedLinkName);
    if (!rawLinkName) return;
    const name =
      tag == Constants.LinkTagName
        ? this.exitLinkName(element as HTMLAnchorElement, rawLinkName) ||
        rawLinkName
        : rawLinkName;

    const linkTrackingParameters = {
      name,
      position: name.split(".")[0],
    };

    switch (true) {
      case this.isFooterLink(element): {
        analyticsAttribute.event = AnalyticsEvent.FOOTER_LINK;
        break;
      }
      case this.isHeaderLink(element): {
        analyticsAttribute.event = AnalyticsEvent.HEADER_LINK;
        break;
      }

      default: {
        analyticsAttribute.event = AnalyticsEvent.BODY_LINK;
        break;
      }
    }

    this.analyticsCall(analyticsAttribute.event, linkTrackingParameters);
  }
}
