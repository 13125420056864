import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StringModel } from '../../../_models/string.model';

@Injectable()
export class SMCPostCreateDisplayActions {
    static SET_SMC_POST_CREATE_DISPLAY: string = 'SET_SMC_POST_CREATE_DISPLAY';
    static CLEAR_SMC_POST_CREATE_DISPLAY: string = 'CLEAR_SMC_POST_CREATE_DISPLAY';

    setSMCPostCreateDisplay(smcPostCreateText: string): FSA<string, string, null> {
        return {
            type: SMCPostCreateDisplayActions.SET_SMC_POST_CREATE_DISPLAY,
            payload: smcPostCreateText,
            meta: null
        };
    }

    clearSMCPostCreateDisplay(): FSA<string, string, null> {
        return {
            type: SMCPostCreateDisplayActions.CLEAR_SMC_POST_CREATE_DISPLAY,
            payload: null,
            meta: null
        };
    }
}

@Injectable()
export class SMCPostCreateDisplayReducers {
    static SMCPostCreateDisplayReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
        switch (action.type) {
            case SMCPostCreateDisplayActions.SET_SMC_POST_CREATE_DISPLAY:
                return tassign(state, new StringModel(action.payload));
            case SMCPostCreateDisplayActions.CLEAR_SMC_POST_CREATE_DISPLAY:
                return tassign(undefined);
            default:
                return state;
        }
    }
}
