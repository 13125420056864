import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { FooterModel } from '../../_models/footer.model';
import { HeaderModel } from '../../_models/header.model';
import { IAppState } from "../store/app.store";
import { route } from './router.service';
import { UrlBuilderService } from './url-builder.service';
import { UserService } from './user.service';
import { EnvironmentConfig } from '../../../_shared/_models/environment-config';

declare var GlobalnavPrimaryEmbedded: any;

@Injectable()
export class GlobalNavigationService {

  constructor(private translateService: TranslateService,
    private urlBuilderService: UrlBuilderService,
    private userService: UserService,
    private store: Store<IAppState>) {

  }
  private footerTranslationText: FooterModel;
  private headerTranslationText: string;
  private footerTranslationKey: FooterModel;
  private headerTranslationKey: string;
  private footerUrl: FooterModel;
  private headerUrl: HeaderModel;
  private editUsernameUrl: string;
  private logoutUrl: string;


  public initializeGlobalNavigation(authenticated?: boolean): void {
    let translationKeys: string[] = this.getTranslationKeys();
    this.translateService.get(translationKeys).subscribe((res: any) => {
      let globalNavConfigObj = this.getGlobalNavConfigObject(res);
      if (GlobalnavPrimaryEmbedded && globalNavConfigObj) {
        GlobalnavPrimaryEmbedded.init(globalNavConfigObj);
      }
    })
  }

  public initializeGlobalNavigationAfterLogin() {
    //reset config
    if (GlobalnavPrimaryEmbedded &&
      GlobalnavPrimaryEmbedded.config &&
      GlobalnavPrimaryEmbedded.defaultConfig &&
      GlobalnavPrimaryEmbedded.isInitialized) {
      GlobalnavPrimaryEmbedded.config = GlobalnavPrimaryEmbedded.defaultConfig;
      GlobalnavPrimaryEmbedded.isInitialized = false;
    }
    this.initializeGlobalNavigation(true);
  }

  private setUrls() {
    this.footerUrl = new FooterModel();
    this.headerUrl = new HeaderModel();
    let storeEnvConfig: EnvironmentConfig;
    this.store.select(state => state.EnvironmentConfig).subscribe(x => storeEnvConfig = x);
    this.footerUrl.privacy = storeEnvConfig.PRIVACY_POLICY_URL;
    this.footerUrl.myBMW = storeEnvConfig.MY_BMW_URL;
    this.footerUrl.myGarage = storeEnvConfig.MY_GARAGE_URL;
    this.footerUrl.terms = storeEnvConfig.FOOTER_NAVIGATION_CONFIG.TERMS_URL;
    this.footerUrl.faq = storeEnvConfig.FOOTER_NAVIGATION_CONFIG.FAQ_URL
    this.footerUrl.contactus = storeEnvConfig.FOOTER_NAVIGATION_CONFIG.CONTACT_US_URL;
    this.footerUrl.scra = storeEnvConfig.FOOTER_NAVIGATION_CONFIG.SCRA_URL;
    this.editUsernameUrl = this.urlBuilderService.getAuthProviderManageSettingsRedirectUrl()
    this.logoutUrl = route.ssologout;
    this.headerUrl.home = storeEnvConfig.TOP_HEADER_NAVIGATION_CONFIG.HOME_URL
    this.headerUrl.myGarage = storeEnvConfig.MY_GARAGE_URL;
    this.headerUrl.myAccount = storeEnvConfig.TOP_HEADER_NAVIGATION_CONFIG.MY_ACCOUNT_URL;
    this.headerUrl.myProfile = storeEnvConfig.TOP_HEADER_NAVIGATION_CONFIG.MY_PROFILE_URL;
    this.headerUrl.bmwLogin = this.urlBuilderService.getAuthProviderProfileRedirectUrl();
    this.headerUrl.logOutMyBMW = storeEnvConfig.TOP_HEADER_NAVIGATION_CONFIG.LOG_OUT_MY_BMW_URL;
  }

  private getTranslationKeys(): string[] {
    this.footerTranslationKey = new FooterModel('ngw.footer.links.privacy-policy',
      'ngw.general.lnk-online-services-agreement',
      'ngw.footer.links.faq',
      'ngw.contact-us.title',
      'ngw.footer.links.scra',
      'ngw.footer.links.my-bmw',
      'ngw.footer.links.my-garage');
    this.headerTranslationKey = "ngw.header.manage-settings";
    let footerTranslationKeys = _.values(this.footerTranslationKey);
    let translationKeys = footerTranslationKeys.concat([this.headerTranslationKey]);
    return translationKeys;
  }

  private setTranslationText(response: any) {
    if (response) {
      if (this.footerTranslationKey) {
        this.footerTranslationText = new FooterModel();
        this.footerTranslationText.privacy = response[this.footerTranslationKey.privacy];
        this.footerTranslationText.terms = response[this.footerTranslationKey.terms];
        this.footerTranslationText.faq = response[this.footerTranslationKey.faq];
        this.footerTranslationText.contactus = response[this.footerTranslationKey.contactus];
        this.footerTranslationText.scra = response[this.footerTranslationKey.scra];
        this.footerTranslationText.myBMW = response[this.footerTranslationKey.myBMW];
        this.footerTranslationText.myGarage = response[this.footerTranslationKey.myGarage];
      }
      if (this.headerTranslationKey) {
        this.headerTranslationText = response[this.headerTranslationKey]
      }
    }
  }

  private getGlobalNavConfigObject(translationResponse: any) {
    this.setUrls();
    this.setTranslationText(translationResponse);
    let configObject = {
      navigationElement: "header",
      footerElement: "div#footer",
      title: "MyBMW",
      domain: "https://www.bmwusa.com",
      logout: this.logoutUrl,
      profileLinks: this.getProfileLinks(),
      footerLinks: this.getFooterLinks()
    }
    return configObject;
  }

  private getFooterLinks(): any[] {
    return [
      {
        name: this.footerTranslationText.privacy,
        title: this.footerTranslationText.privacy,
        url: this.footerUrl.privacy,
        newTab: true,
        active: true,
        analyticEvent: "flyout.myaccount.privacy-policy"
      },
      {
        name: this.footerTranslationText.terms,
        title: this.footerTranslationText.terms,
        url: this.footerUrl.terms,
        newTab: false,
        active: true,
        analyticEvent: "flyout.myaccount.terms-and-conditions"
      },
      {
        name: this.footerTranslationText.faq,
        title: this.footerTranslationText.faq,
        url: this.footerUrl.faq,
        newTab: false,
        active: true,
        analyticEvent: "flyout.myaccount.faq"
      },
      {
        name: this.footerTranslationText.contactus,
        title: this.footerTranslationText.contactus,
        url: this.footerUrl.contactus,
        newTab: false,
        active: true,
        analyticEvent: "flyout.myaccount.contact-us"
      },
      {
        name: this.footerTranslationText.scra,
        title: this.footerTranslationText.scra,
        url: this.footerUrl.scra,
        newTab: false,
        active: true,
        analyticEvent: "flyout.myaccount.scra"
      },
      {
        name: this.footerTranslationText.myBMW,
        title: this.footerTranslationText.myBMW,
        url: this.footerUrl.myBMW,
        newTab: false,
        active: true,
        analyticEvent: "flyout.myaccount.mybmw"
      },
      {
        name: this.footerTranslationText.myGarage,
        title: this.footerTranslationText.myGarage,
        url: this.footerUrl.myGarage,
        newTab: false,
        active: true,
        analyticEvent: "flyout.mygarage.mygarage"
      }
    ];
  }

  private getProfileLinks(): any[] {
    return [
      {
        url: this.headerUrl.home,
        id: 'home'
      },
      {
        url: this.headerUrl.myGarage,
        id: 'myGarage'
      },
      {
        url: this.headerUrl.myAccount,
        id: 'myAccount'
      },
      {
        url: this.headerUrl.myProfile,
        id: 'myProfile'
      },
      {
        url: this.headerUrl.bmwLogin,
        id: 'bmwLogin',
        isNewTab: true,
        active: true
      },
      {
        url: this.headerUrl.logOutMyBMW,
        id: 'logOutMyBMW'
      }
    ];
  }

}

