import { Injectable, Injector } from '@angular/core';
import { ILogger } from 'customerdigital-service-lib';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from '../store/app.store';
import { Store } from '@ngrx/store';
import { ActivityLogging, ActivityLoggingFactory } from '../../../_shared/core/basecomponent/features/logging/logging.factory'

@Injectable()
export class LoggerHelperService implements ILogger {

  protected logger: ActivityLogging | undefined;
  protected logFactory: ActivityLoggingFactory;
  public currentRoute: string | undefined;

  constructor(injector: Injector,
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute
  ) {
    this.logFactory = injector.get(ActivityLoggingFactory);

  }


  private getLoggerForComponent(componentName: string, activityName: string, isParentActivity: boolean) {
    return this.logFactory.getLogger(
      componentName,
      activityName,
      isParentActivity
    );
  }

  public logAlways(message: string): void {
    let clients: string;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => clients = x);
    this.getLoggerForComponent(
      this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      clients, false).then(rtrn => {
        this.logger = rtrn;
        this.logger.logalways([message]);
      });
  }

  public logCritical(message: string): void {
    let clients: string;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => clients = x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      clients, false).then(rtrn => {
        this.logger = rtrn;
        this.logger.logcritical([message]);
      });
  }

  public logError(message: string): void {
    let clients: string;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => clients = x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      clients, false).then(rtrn => {
        this.logger = rtrn;
        this.logger.logerror([message]);
      });
  }

  public logInformational(message: string): void {
    let clients: string;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => clients = x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      clients, false).then(rtrn => {
        this.logger = rtrn;
        this.logger.loginformational([message]);
      });
  }

  public logVerbose(message: string): void {
    let clients: string;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => clients = x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      clients, false).then(rtrn => {
        this.logger = rtrn;
        this.logger.logverbose([message]);
      });
  }

  public logWarning(message: string): void {
    let clients: string;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => clients = x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      clients, false).then(rtrn => {
        this.logger = rtrn;
        this.logger.logwarning([message]);
      });
  }
}