import { TranslateService } from '@ngx-translate/core'; 

export class NavigationModel {
    isActive: boolean;
    label: string;
    route: any;
    parameters: object;
    value: any;
    order: any;
    isSpotLightEnable: boolean;

    constructor(label: string, labelIsTranslateKey: boolean, value?: any, route?: string, parameters?: object, order?: any, isActive?: boolean, private translateService?: TranslateService, isSpotLightEnable?:boolean) {
        this.isActive = isActive;
        this.route = route;
        this.parameters = parameters;
        this.value = value;
        this.order = order;
        this.isSpotLightEnable = isSpotLightEnable;

        if (labelIsTranslateKey) {
            this.translateService.get(label).subscribe((res: string) => {
                this.label = res;
            })
        }
        else {
            this.label = label;
        }
    }
}
