import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class CanNavigateToConfirmationActions {
  static SET_CAN_NAVIGATE_TO_CONFIRMATION: string = 'SET_CAN_NAVIGATE_TO_CONFIRMATION';



  setCanNavigateToConfirmation(canNavigateToConfirmation: boolean): FSA<string, boolean, null> {
    return {
      type: CanNavigateToConfirmationActions.SET_CAN_NAVIGATE_TO_CONFIRMATION,
      payload: canNavigateToConfirmation,
      meta: null
    };
  }

}

@Injectable()
export class CanNavigateToConfirmationReducers {
  static CanNavigateToConfirmationReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case CanNavigateToConfirmationActions.SET_CAN_NAVIGATE_TO_CONFIRMATION:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
}
