import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[scrollTop]'
})
export class ScrollTopDirective implements OnChanges {
    @Input('scrollTop') scrollToTop: boolean;

    constructor(private elementRef: ElementRef) { };

    ngOnChanges(changes: SimpleChanges) {
        try {
            if (this.scrollToTop) {
                if (this.elementRef.nativeElement.scrollTo) {
                    this.elementRef.nativeElement.scrollTo(0, 0);
                }
                if (this.elementRef.nativeElement.scrollTop) {
                    this.elementRef.nativeElement.scrollTop = 0;
                }
                if (window.scroll) {
                    window.scroll(0, 0);
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}
