import { Component, OnInit, OnDestroy, Renderer2, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterService, routes } from '../../shared/_helper-services/router.service';
import { LoginService } from '../../_web-services/login.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/constants';
import { BaseApplicationComponent } from "../../core/basecomponent/base.component";
import { UserService } from '../../shared/_helper-services/user.service';
import { UserIsTimedOutActions } from "../../shared/store/reducers/user-is-timed-out.reducer";
import { IdleService } from '../../shared/_helper-services/idle.service';
import { LoginStatus, LoginErrorType } from '../../_models/login-status.model';
import { AuthService } from '../../_web-services/auth.service';
import { ModuleOnBoardingHelperService } from '../../shared/_helper-services/module-onboarding-helper.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsoComponent extends BaseApplicationComponent<SsoComponent> implements OnInit, OnDestroy {
  public code: string;
  public state: string;
  public grantType: string;
  public uri: string;
  public hasError: boolean;
  public errorMessage: string;

  constructor(public activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
    public routerService: RouterService,
    public loginService: LoginService,
    public userService: UserService,
    public userIsTimedOutActions: UserIsTimedOutActions,
    public idleService: IdleService,
    public authService: AuthService,
    renderer2: Renderer2,
    @Inject(DOCUMENT) document,
    injector: Injector,
    public moduleOnBoardingHelperService?: ModuleOnBoardingHelperService) {
    super(injector, document);
    super.setDataLoadedOnRequest(false);
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public init() {
    // Clear session
    this.userService.clearSession();

    this.userService.clearOnBoardingSession();
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
        this.activatedRoute
          .queryParams
          .subscribe(params => {
            this.code = params[Constants.SsoCodeParam];
            this.state = params[Constants.SsoStateParam];
            this.grantType = params[Constants.SsoGrantTypeParam] ? params[Constants.SsoGrantTypeParam] : Constants.SsoAuthenticateUserGrantType;
            this.uri = params[Constants.SsoUriParam];
            this.authenticateUser(this.state);
            const partner = this.uri;
            this.dataLayer = {
              ... this.dataLayer,
              page: {
                ...(this.dataLayer || {}).page,
                partner
              }
            }
            super.pushDataLayer();
          });
      });
  }

  public destroy() {
    super.setDataLoadedOnRequest(true);
  }

  public authenticateUser(state: string) {
    //reset session timeout
    this.store.dispatch(this.userIsTimedOutActions.setUserIsTimedOut(false));
    //clear any orphan session
    this.userService.clearSession();
    this.userService.clearOnBoardingSession();
    //reset error
    this.errorMessage = "";
    this.hasError = false;
    this.loginService.ssoLogin(this.code, this.grantType, this.uri)
      .subscribe(loginStatus => { this.postAuthentication(loginStatus, state); });
  }

  postAuthentication(loginStatus: LoginStatus, state: string) {
    if (loginStatus.error == true) {
      this.hasError = true;
      this.logger.logcritical(["Error occurred in: " + Constants.LoginComponentName + ", loginService.ssoLogin()"]);
      this.userService.clearSession();
      this.userService.clearOnBoardingSession();
      this.routerService.navigateToErrorPage();

    } else {
      this.hasError = false;
      this.idleService.initIdle();
      super.setDataLoadedOnRequest(true);
      let decodedTargetUrl = decodeURIComponent(state);
      this.routerService.navigateToTarget(decodedTargetUrl);
    }
  }

}
