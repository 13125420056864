import { DecimalPipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from '../../../../../_shared/core/basecomponent/base.component';
import { LeaseExtReviewDetails } from '../../../../../_shared/_models/lease-extension.model';

@Component({
  selector: 'app-extend-lease-review-can-dialog',
  templateUrl: './extend-lease-review-can-dialog.component.html',
  styleUrls: ['./extend-lease-review-can-dialog.component.scss']
})
export class ExtendLeaseReviewCanDialog extends BaseApplicationComponent<ExtendLeaseReviewCanDialog> implements OnInit {
  public leaseExtReviewDetails: LeaseExtReviewDetails;
  public newContractDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ExtendLeaseReviewCanDialog>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector,
    private decimalPipe: DecimalPipe
  ) {
    super(injector, document);
  }

  ngOnInit(): void {
    this.store.select(state => state.LeaseExtReviewDetails).subscribe(x => this.leaseExtReviewDetails = x);
    this.newContractDate = this.leaseExtReviewDetails.extendatedDates[this.leaseExtReviewDetails.selectedMonthsOption - 1].name;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public confirmAction(): void {
    this.dialogRef.close(true);
  }

}