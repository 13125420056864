export class CoBrandedCreditCardModel  {
  isCardHolder: boolean;
  isSelfServiceRestricted: boolean;
  rewardPoints: number;
  dollarValue: number;
  minimumRequiredPoints: number;
  error: boolean;
  accountEAC: string;
  ssoLoginUri: string;
  cardHolderSsoEnabled: boolean;
  samlBase64: string;
}
