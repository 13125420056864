import { AddressDTO, TaxJurisdictionDTO, PaymentAdjustmentDTO, AddressTypes, CheckAddressResponseResponseErrorCode } from "../core/gateway-api";
import { EditAddressType } from "../shared/enums";

export class EditAddressModel {
  error: boolean;
  errortype: EditAddressErrorType;
  faultCode: string;
  validatedAddress: AddressDTO;
  mailingAddress: AddressDTO;
  oldAddress: AddressDTO;
  contractAccountNumber: string;
  isLeaseAddress: boolean;
  addressType: AddressTypes;
  type: EditAddressType;
  taxJurisdictions: TaxJurisdictionDTO[];
  paymentAdjustments: PaymentAdjustmentDTO[];
  responseErrorCode: CheckAddressResponseResponseErrorCode;
  movePackEligible: boolean;

  constructor(
      error: boolean,
      mailingAddress?: AddressDTO,
      validatedAddress?: AddressDTO,
      oldAddress?: AddressDTO,
      contractAccountNumber?: string,
      paymentAdjustments?: PaymentAdjustmentDTO[],
      taxJurisdictions?: TaxJurisdictionDTO[],
      responseErrorCode?: CheckAddressResponseResponseErrorCode,
      errorType?: EditAddressErrorType,
      faultCode?: string, 
      movePackEligible?: boolean)
  {
      this.error = error;
      this.errortype = errorType;
      this.faultCode = faultCode;
      this.validatedAddress = validatedAddress;
      this.mailingAddress = mailingAddress;
      this.oldAddress = oldAddress;
      this.contractAccountNumber = contractAccountNumber;
      this.paymentAdjustments = paymentAdjustments;
      this.taxJurisdictions = taxJurisdictions;
      this.responseErrorCode = responseErrorCode;
      this.movePackEligible = movePackEligible;
  } 
}

export enum EditAddressErrorType {
  AddressValidation = 1,
  DataValidation = 2,
  POBoxAddress = 3,
  AddressValidationOrUpdateFailed = 4
}
