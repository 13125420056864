import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'user-inactivity-dialog',
  templateUrl: './user-inactivity-dialog.component.html',
  styleUrls: ['./user-inactivity-dialog.component.scss']
})

export class UserInactivityDialog {
  public width: number;

  constructor(public dialogRef: MatDialogRef<UserInactivityDialog>) {
  }

}
