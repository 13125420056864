import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { MileageAndTermsModel } from '../_models/mileageandterms.model';
import { PaymentEstimateModel } from '../_models/payment-estimate.model';
import { GetMileageAndTermsRequest, GetWebPaymentEstimateRequest, PaymentEstimationServiceClient, WebPaymentEstimationRequestDTO } from '../core/gateway-api';

@Injectable()
export class PaymentEstimationService {
  constructor(
    private paymentEstimationServiceClient: PaymentEstimationServiceClient,
  ) { }

  public getMileageAndTerms(
    vehicleConditionId?: number,
    franchiseTypeCode?: number,
    contractType?: number,
    modelYear?: string,
    country?: string,
    language?: string
  ): Observable<MileageAndTermsModel> {
    const accountOfferRequest = new GetMileageAndTermsRequest({
      vehicleConditionId,
      franchiseTypeCode,
      contractType,
      modelYear,
      country,
      language
    });

    return this.paymentEstimationServiceClient.getMileageAndTerms(accountOfferRequest).pipe(
      mergeMap(terms => of({ ...terms, error: false, faultType: undefined } as MileageAndTermsModel)),
      catchError((error: any) => of({ error: true, faultType: error.faultType }))
    );
  }

  get getWebPaymentEstimationRequestDTO(): WebPaymentEstimationRequestDTO {
    return new WebPaymentEstimationRequestDTO();
  }

  public getWebPaymentEstimate(estimatorRequest: WebPaymentEstimationRequestDTO): Observable<PaymentEstimateModel> {
    let getWebPaymentEstimateRequest = new GetWebPaymentEstimateRequest();
    let webPaymentEstimationRequestDTO = this.getWebPaymentEstimationRequestDTO;
    webPaymentEstimationRequestDTO = estimatorRequest;
    getWebPaymentEstimateRequest.estimatorRequest = webPaymentEstimationRequestDTO;
    return this.paymentEstimationServiceClient.getWebPaymentEstimate(getWebPaymentEstimateRequest)
      .pipe(mergeMap((response) => { return this.getWebPaymentEstimateSuccess(response); })
        , catchError((error: any) => { return this.getWebPaymentEstimateError(error); }));
  }

  private getWebPaymentEstimateSuccess(result: any): Observable<PaymentEstimateModel> {
    let paymentEstimateModel = new PaymentEstimateModel();
    paymentEstimateModel.paymentEstimationDTO = result.estimate;
    paymentEstimateModel.error = false;
    return of(paymentEstimateModel);
  }

  private getWebPaymentEstimateError(error: any): Observable<PaymentEstimateModel> {
    let paymentEstimateModel = new PaymentEstimateModel();
    paymentEstimateModel.error = true;
    return of(paymentEstimateModel);
  }
}