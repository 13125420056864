import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { StringModel } from '../../../_models/string.model';
import { FSA } from 'flux-standard-action';

import { tassign } from 'tassign';


@Injectable()
export class PreviousUrlActions {
  static SET_PREVIOUS_URL: string = 'SET_PREVIOUS_URL';
  static CLEAR_PREVIOUS_URL: string = 'CLEAR_PREVIOUS_URL';


  setPreviousUrl(url: string): FSA<string, string, null> {
    return {
      type: PreviousUrlActions.SET_PREVIOUS_URL,
      payload: url,
      meta: null
    };
  }
  clearPreviousUrl(): FSA<string, string, null> {
    return {
      type: PreviousUrlActions.CLEAR_PREVIOUS_URL,
      payload: null,
      meta: null
    };
  }

}

@Injectable()
export class PreviousUrlReducers {
  static PreviousUrlReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
    switch (action.type) {
      case PreviousUrlActions.SET_PREVIOUS_URL:
        return tassign(state, new StringModel(action.payload));
      case PreviousUrlActions.CLEAR_PREVIOUS_URL:
        return tassign(state, new StringModel(action.payload));
      default:
        return state;
    }
  }
}
