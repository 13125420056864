import { Component, Inject, Injector, ViewEncapsulation, OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from '../../../../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../../../../../../../shared/constants';
import { PendingOneTimePayment } from '../../../../../../../_models/payment-details/scheduled-payment.model';
import { CustomerACHService } from '../../../../../../../_web-services/customer-ach.service';
import { EventService } from '../../../../../../../shared/_helper-services/event.service';
import { ScheduledItemsActions } from '../../../../../../../shared/store/reducers/scheduled-items.reducers';
import { PaymentHelperService } from '../../../../../../../shared/_helper-services/payment-helper.service';
import { UserService } from '../../../../../../../shared/_helper-services/user.service';

@Component({
  selector: 'cancel-pending-payment-dialog',
  templateUrl: './cancel-pending-payment-dialog.component.html',
  styleUrls: ['./cancel-pending-payment-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CancelPendingOneTimePayment extends BaseApplicationComponent<CancelPendingOneTimePayment> implements OnInit {
  error: boolean;
  errorMessage: string;
  pendingOneTimePaymentData: PendingOneTimePayment;
  onCancel = new EventEmitter();
  public paymentSourceName: string;

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    public dialogRef: MatDialogRef<CancelPendingOneTimePayment>,
    private customerACHService: CustomerACHService,
    private scheduledItemsActions: ScheduledItemsActions,
    private paymentHelperService: PaymentHelperService,
    private eventService: EventService,
    @Inject(DOCUMENT) document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector) {
    super(injector, document);
    this.errorMessage = "";
    this.pendingOneTimePaymentData = this.data.pendingOneTimePaymentData;
  }

  ngOnInit() {
    let gcid = this.userService.getGcid();
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
        this.paymentSourceName = this.paymentHelperService.getPaymentDisplayName(this.pendingOneTimePaymentData.scheduledItem.scheduledItemDTO.paymentAccount);
      });
    this.analytics.pushSiteToolEvent({ siteTool: Constants.CancelPendingOneTimePaymentComponentName, siteToolStep: Constants.SiteToolStart });
  }

  public cancelPendingPayment() {
    super.setDataLoadedOnRequest(false);
    if (this.pendingOneTimePaymentData && this.pendingOneTimePaymentData.scheduledItem && this.pendingOneTimePaymentData.scheduledItem.accountNumber
      && this.pendingOneTimePaymentData.scheduledItem.scheduledItemDTO && this.pendingOneTimePaymentData.scheduledItem.scheduledItemDTO.scheduledItemId) {
      this.customerACHService.cancelPendingOneTimePaymentSchedule(this.pendingOneTimePaymentData.scheduledItem.accountNumber, this.pendingOneTimePaymentData.scheduledItem.scheduledItemDTO.scheduledItemId)
        .subscribe(response => { this.postCancelPendingPayment(response); });
    } else {
      this.dialogRef.close(null);
      super.setDataLoadedOnRequest(true);
    }
  }

  private postCancelPendingPayment(response: any) {
    if (response.error) {
      this.error = true;
      const errorTranslationKey = "ngw.global.technical-error";
      this.translateService.get(errorTranslationKey).subscribe(res => {
        this.errorMessage = res;
      });
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
      this.logger.logcritical(["Error occurred in: " + Constants.CancelPendingOneTimePaymentComponentName + ", account number:" + this.userService.getCustomerNumber() + ", customerACHService.cancelPendingOneTimePaymentSchedule()."]);
    } else {
      this.store.dispatch(this.scheduledItemsActions.clearScheduledItems());
      this.eventService.cancelPendingPayment$.emit('cancelPayment');
      this.analytics.pushSiteToolEvent({ siteTool: Constants.CancelPendingOneTimePaymentComponentName, siteToolStep: Constants.SiteToolComplete });
      this.dialogRef.close({ data: undefined });
    }
    super.setDataLoadedOnRequest(true);
  }

  public keepPendingPayment() {
    this.pushDataLayer({ dataLayer: { myAccount: { siteTool: null, siteToolStep: null } } });
    this.dialogRef.close(null);
  }

}
