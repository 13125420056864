<div class="payment-source dialog" [scrollTop]="scrollToTop">
  <div #editAddressTopDiv id="editAddressTopDiv"></div>
  <div class="payment-source-container">
    <app-title [title]="title"></app-title>
    <div class="required-fields">
      {{'ngw.general.required' | translate}}
    </div>
    <div class="general error-container" role="alert" *ngIf="error">
      <em class="error-validation" id="errorMessage">{{errorMessage}}</em>
      <em id="adressfaultWarningMessage" class="warning">{{addressFaultWarningMessage}}</em>
      <em id="nonBillingWarningMessage" class="warning">{{nonBillingWarningMessage}}</em>
    </div>
    <form #editAddressForm="ngForm" autocomplete="off">
      <div class="form-group" *ngIf="isRegistrationAddress && address">
        <mat-radio-group required name="type" [(ngModel)]="type">
          <mat-radio-button *ngFor="let type of types" [value]="type.value" (change)="onTypeChange($event)">
            {{type.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-group" [ngClass]="{'has-error': address1IsInvalid(editAddressForm.controls.address1)}" *ngIf="address">
        <label class="control-label required" for="address1">{{'ngw.profile.contact-information.edit-address.lbl-address1' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="address.address1" name="address1" required maxLength="50" id="address1" type="text" class="form-control" [disabled]="fieldsDisabled" />
        <em class="error-validation" id="address1RequiredError" *ngIf="address1IsInvalid(editAddressForm.controls.address1)">{{"ngw.validation.error.required" | translate}}</em>
      </div>
      <div class="form-group" *ngIf="address">
        <label class="control-label" for="address2">{{'ngw.profile.contact-information.edit-address.lbl-address2' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="address.address2" name="address2" maxLength="50" id="address2" type="text" class="form-control" [disabled]="fieldsDisabled" />
      </div>
      <div class="form-group" *ngIf="address">
        <label class="control-label" for="address3">{{'ngw.profile.contact-information.edit-address.lbl-address3' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="address.address3" name="address3" maxLength="50" id="address3" type="text" class="form-control" [disabled]="fieldsDisabled" />
      </div>
      <div class="form-group" [ngClass]="{'has-error': cityIsInvalid(editAddressForm.controls.city)}" *ngIf="address">
        <label class="control-label required" for="city">{{'ngw.profile.contact-information.edit-address.lbl-city' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="address.city" name="city" required maxLength="50" id="city" type="text" class="form-control" [disabled]="fieldsDisabled" />
        <em class="error-validation" id="cityRequiredError" *ngIf="cityIsInvalid(editAddressForm.controls.city)">{{"ngw.validation.error.required" | translate}}</em>
      </div>
      <div class="two-col">
        <div class="form-group" *ngIf="address">
          <label class="control-label required" for="state">{{'ngw.profile.contact-information.edit-address.lbl-state' | translate}}</label>
          <mat-select floatPlaceholder="never" [(ngModel)]="address.state" [disabled]="fieldsDisabled" name="state" required id="state" class="form-control" aria-label="dialogValue" aria-labelledby="dialogValue">
            <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
          </mat-select>
        </div>
        <div class="form-group" [ngClass]="{'has-error': zipIsInvalid(editAddressForm.controls.zip)}" *ngIf="address">
          <label class="control-label required" for="zip">{{'ngw.profile.contact-information.edit-address.lbl-zip' | translate}}</label>
          <input tabindex="-1" [(ngModel)]="shortPostalCode" name="zip" required maxLength="5" id="zip" type="text" class="form-control" [disabled]="fieldsDisabled" />
          <em class="error-validation" id="zipError" *ngIf="zipIsInvalid(editAddressForm.controls.zip)">{{zipErrorMessage}}</em>
        </div>
      </div>
      <div class="action-buttons">
        <button type="button" (click)="cancel()" class="btn btn-secondary" id="cancelEditAddressBtn" analytics-event="body.cancel-edit-address.cancel-edit-address.button">
          {{'ngw.general.btn-cancel' | translate}}</button>
        <button type="submit" (click)="save()" class="btn btn-primary" id="saveEditAddressBtn" [disabled]="formIsInvalid(editAddressForm)" *ngIf="!isInConfirmMode()">{{'ngw.profile.contact-information.edit-address.btn-save' | translate}}</button>
        <button type="submit" (click)="confirm()" class="btn btn-primary" id="saveEditAddressBtn" [disabled]="formIsInvalid(editAddressForm)" *ngIf="isInConfirmMode()">{{'ngw.profile.contact-information.edit-address.btn-confirm' | translate}}</button>
      </div>
    </form>
  </div>
</div>
