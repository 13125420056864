import { VehicleReturnEstimateExcessMileageDTO } from "../../core/gateway-api";


export class MileageSummary {
  message: string;
  showButton: boolean;
  data: VehicleReturnEstimateExcessMileageDTO;
  inspectionDate: Date;
}

