export class GenerateMultiFactorTokenStatus {
    error: boolean;
    errortype: GenerateMultiFactorTokenStatusErrorType;
    transactionId: string;
  }
  
  export enum GenerateMultiFactorTokenStatusErrorType {
    DataValidation,
    TooManyTokenRequest,
    MaxDailyTokenReached,
    MaxMonthlyTokenReached,
    MFAFailed
  }
  