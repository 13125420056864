export enum FinanceProduct {
  Lease = 1,
  Loan = 2,
}

export enum ProtectionPlan {
  Lease = 4,
  Loan = 5,
}
export enum AccountStatus {
  Active = 1,
  Terminated = 0
}

export enum ActivityTypes {
  MaturityBillingStatementView = 22,
  MaturityBillingCreditCardPayment = 23,
  UpdateAddress = 27,
  UpdatePaperless = 29,
  UpdateStmtNotify = 30,
  UpdatePayReminder = 31,
  MMA = 34,
  Amortization = 38,
  SecureMessageCenterHome = 74,
  SecureMessageCenterCreateMessage = 75,
  SecureMessageCenterDeleteMessage = 76,
  MyAccountLogin = 78,
  Legitimizationcompleted = 79,
  ContractList = 80,
  OneTimePaymentDataEntry = 81,
  OneTimePaymentReview = 82,
  OneTimePaymentConfirmation = 83,
  eSignAgreement = 84,
  ChangePassword = 85,
  ViewContractDetails = 86,
  AccountStatements = 87,
  PayoffQuote = 88,
  ChangeLogin = 89,
  RecurringPaymentEntry = 90,
  RecurringPaymentReview = 91,
  RecurringPaymentConfirmation = 92,
  CancelRecurringPayment = 93,
  CancelRecurringPaymentComplete = 94,
  UpdateVehicleRegistrationAddress = 95,
  SecureMessageCenterReadMessage = 96,
  SecureMessageCenterReplyMessage = 97,
  ChangeDueDateEntry = 98,
  ChangeDueDateReview = 99,
  ChangeDueDateConfirmation = 100,
  ChangeDueDateInformational = 101,
  PaymentConfirmationNotification = 102,
  PaymentReminderNotification = 103,
  Dashboard = 104,
  VehicleReturnEstimate = 105,
  CustomerIntent = 106,
  OnboardPaperless = 107,
  OnboardPaperlessOff = 108,
  LeaseExtensionModel = 109,
  LeaseExtensionPaymentEntry = 110,
  LeaseExtensionPaymentReview = 111,
  LeaseExtensionPaymentConfirmation = 112,
  UploadRepairReceipt = 113,
  SubmitRepairReceipt = 114,
  ViewSubmitedReceipts = 115,
  PaymentDeferralInformational = 116,
  PaymentDeferralEntry = 117,
  PaymentDeferralConfirmation = 118,
  PaymentDeferralRequest = 119,
  PauseRecurringPayment = 120,
  ResumeRecurringPayment = 121,
  AddChequingAccountButton = 122,
  AddChequingAccountLink = 123,
  AddChequingAccountSubmit = 124,
  ReschedulePaymentEntry = 125,
  ReschedulePaymentConfirmation = 126,
  OneTimeMaturityBillingPaymentEntry = 127,
  OneTimeMaturityBillingPaymentConfirmation = 128,
  OneTimeCollectionsPaymentEntry = 129,
  OneTimeCollectionsPaymentConfirmation = 130,
  VehicleRecallDialog = 131,
  VehicleRecallContinue = 132,
  LeaseExtensionDuration = 133,
  VehicleServiceDialog = 134,
  VehicleServiceContinue = 135,
  SelectAccount = 136,
  AccountLinks = 137,
  IntentGetNew = 144,
  IntentKeep = 145,
  IntentReturn = 146,
  IntentNotSure = 147,
  IntentPurchase = 148,
  IntentRefinance = 149,
  AccountActivityOffers = 138,
  LeaseEndGetNewVehicle = 140,
  LeaseEndBuyoutVehicle = 141,
  LeaseEndPreOwnewd = 143,
  LeaseEndReturn = 142,
  LeaseEndRoadAhead = 139,
  ClickToSignLandingPage = 153,
  ClickToSignAgreeButton = 154,
  ClickToSignConfirmationPage = 155,
  LumpSumPaymentEntry = 158,
  LumpSumPaymentConfirmation = 159
}

export enum UserAction {
  Cancel = 1,
  Modify = 2,
  Submit = 3,
  Logout = 4,
}

export enum EditAddressType {
  SameAsMailing = 1,
  Other = 2,
}

export enum TaxJurisdictionViewMode {
  Step1 = 1,
  Step2 = 2,
}

export enum InspectionType {
  AutoVinInspection = 1,
  PreInspection = 3,
  DsiCompleteInspection = 4,
  DSINextPreInspection = 10,
  CustomerSelfInspection = 11
}
export enum DocumentTypeId {
  DSIPRE = 181,
  DSI = 22,
  DSINEXTPRE = 1257
}

export enum LinkName {
  AccountActivityOffers = "ACCOUNT_ACTIVITY_OFFERS",
  LeaseEndGetNewVehicle = "LEASE_END_GET_NEW_VEHICLE",
  LeaseEndBuyoutVehicle = "LEASE_END_BUYOUT_VEHICLE",
  LeaseEndPreOwnewd = "LEASE_END_PRE_OWNED",
}
export enum FeatureIndicatorTypes {
  NGDMoBillsLogosActive = 115,
  SMCInformationalMessage = 112,
  HomePageInformationalMessage = 117,
  BMWConnectedAppNotifications = 118,
  NGDCoBrandedCreditCardBanner = 132,
  RepairReceiptToggle = 133,
  ExtendLeaseContract = 128,
  PaymentDeferral = 134,
  CreditCardSsoEnabledFlag = 135,
  AddChequingAccountEnabledFlag = 153,
  CanadaReschedulePaymentFlag = 154,
  DisableCardRegistration = 229
  //Epaas = 154
}

export enum FeatureIndicatorNames {
  Mfa = "MULTIFACTAUTH",
  Recaptcha = "RECAPTCHA",
  ExtendLeaseContract = "ExtendLeaseContract",
  VehicleShadowData = "VehicleShadowData",
  Offers = "Offers",
  CoBrandedCreditCard = "CoBrandedCreditCard",
  ChatBot = "ChatBotDisplay",
  PayOffPayment = "PayOff Payment",
  VehicleReturnGuide = "VehicleReturnGuide",
  LumpSumPayment = "LumpSumPayment",
  V2PaymentDetails = "BravePaymentDetails",
  V2PayOff = "BravePayoff",
  V2MakePaymentEnabled = "BraveMakeAPayment",
  V2Statements = "BraveStatements",
  V2EasyPay = "BraveEasyPay",
  V2LeaseExtension = "BraveLeaseExtension",
  EnableV2 = "EnableV2",
  DueDateChange = "BraveChangeDueDate",
  V2PaymentOverView = "BravePaymentOverview",
  EndOfTerm = "EndOfTerm",
  V2MidtermMileage = "BraveMidtermMileage"
}
export enum VehicleImageAngle {
  Front = "frontAngle",
  Angled = "Angled",
  default = "defaultAngle",
}

export enum VehicleImageQuality {
  High = "High",
  Low = "Low",
}

export enum VehicleGhostImage {
  Front = "../../assets/images/ghost-car-front.png",
  Angled = "../../assets/images/ghost-car-angled.png",
}

export enum ApplicationId {
  //Make an entry to the EDB customer..request_source table whenever a new entry added to this enum
  Ngd = <any>"NGD",
  ConnectedApp = <any>"CONAPP",
  ConnectedAppIOS = <any>"CONAPP_IOS",
  App2IOS = <any>"APP2_IOS",
  App2ANDROID = <any>"APP2_ANDROID",
  App2SSO = <any>"APP2_SSO",
}
export enum DashboardQuickLinksType {
  Notifications = <any>"billingPreferenceNotification",
  RecurringPayments = <any>"recurringpayments",
  Amortization = <any>"amortization",
  Payoff = <any>"Payoff",
  AccountStatements = <any>"AccountStatements",
  AccountDetails = <any>"AccountDetails",
  RecentActivity = <any>"RecentActivity",
  PaymentDetails = <any>"PaymentDetails",
  AddACHPaymentSource = <any>"AddACHPaymentSource",
  LeaseMaturityInvoice = <any>"LeaseMaturityInvoice",
}
export enum MfaSteps {
  Info = "1",
  Method = "2",
  EnterCode = "3",
  RememberMe = "4",
}

export enum EasyPayEnrollmentSteps {
  PaymentSource = "1",
  PaymentAmout = "2",
  PaymentDate = "3",
  Review = "4",
}

export enum MmaEnrollmentSteps {
  GetStarted = "1",
  CurrentMileage = "2",
  SelectMiles = "3",
  PaymentOption = "4",
}

export enum MapPaymentOption {
  Monthly = 0,
  Single = 1,
}

export enum LegitimizeOptionType {
  Personal = 1,
  Business = 2,
}

export enum Language {
  English = "en",
  French = "fr",
}

export enum Languagecode {
  English = "enu",
  French = "fra",
}

export enum LanguageCde {
  English = 1,
  French = 2,
}

export enum OnboardingLegalAgreementType {
  Terms = "terms",
  Esign = "esign",
  Profile = "profile"
}

export enum AccountTypes {
  Chequing = "Chequing",
  Saving = "Saving",
}

export enum CustomerIntentCode {
  KeepAndDrive = 276,
  PayBalloon = 266,
  Error = 267,
  NewCar = 218,
  Refinance = 220,
  NotSureYet = 221,
  TurnIn = 222,
}

export enum CustomerIntentDisplayName {
  KeepAndDrive = <any>"Keep my BMW",
  PayBalloon = <any>"Purchase my BMW",
  Default = <any>"Select Your Intent",
  NewCar = <any>"Get a new BMW",
  Refinance = <any>"Refinance my BMW",
  NotSureYet = <any>"Not Sure",
  TurnIn = <any>"Return my BMW",
  ChangePlan = <any>"Change My Plan"
}

export enum AuthenticationViewMode {
  Info = 1,
  EnterCode = 2,
  NoCode = 3,
  Remember = 4,
  Method = 5,
}

export enum SMCSubjectType {
  RepairReceiptId = 101,
  BankUpdateId = 104,
  BankUpdateIdFr = 105,
  RepairReceipt = "Repair Receipt",
  BankUpdate = "Bank Update",
  BankUpdateFr = "Mise à jour du mode de paiement",
}
export enum DeferralNotElligibleReason {
  ExceededNumber = "Exceeded the number of Disaster Assistance Deferrals allowed.",
  NextEasypayClose = "Next Scheduled EasyPay Payment Date is to close.",
  HasPendingDeferral = "Account already has a pending deferral",
}

export enum Market {
  US = "us",
  Canada = "ca",
}

export enum Region {
  USA = "USA",
  Canada = "CANADA",
}

export enum Brand {
  BMW = "BMW",
  MINI = "MINI",
}

export enum ConditionCode {
  LeaseEnd = 3,
  ReLease = 5,
  PrePaidLease = 7,
  DirectRefinance =  17,
  OutForRepo = 31,
  LegalHold = 32,
  LegalGeneralLitigation = 42,
  PaymentFreeze = 88,
  HoldMaturityBillingLetter = 281,
  ConsumerProposal = 329,
  CanadaFraud = 353,
  CanCollections = 390,
  InsDefNew = 83,
  InsDefExpired = 84,
  InsDefCancelled = 85,
  InsDefInadequate = 86,
  InsDefInsLoss = 135,
  InsDefFTC = 150,
  InsuranceDeficiency = 315,
  EarlyOutOption = 191,
  DriveCondition = 119
}

export enum RecentActivityName {
  seriesallowance = <any>"7 Series Allowance",
  accountbalanceaftercredit = <any>"Account Balance After Credit",
  accounttotalnewbalance = <any>"Account Total - New Balance",
  administrativefee = <any>"Administrative Fee",
  allsurface = <any>"AllSurface",
  amountpaidbybmwfs = <any>"Amount Paid by BMW FS",
  amounttobepaidbybmwfs = <any>"Amount to be Paid by BMW FS",
  appearanceprotgstpst = <any>"Appearance Prot GST/PST",
  appearanceprotection = <any>"Appearance Protection",
  appearanceprotectioninterest = <any>"Appearance Protection Interest",
  appearanceprotectionpst = <any>"Appearance Protection PST",
  applicationfee = <any>"Application Fee",
  assignmentfees = <any>"Assignment Fees",
  assumptionfee = <any>"Assumption Fee",
  autorepairs = <any>"Auto Repairs",
  bailifffees = <any>"Bailiff Fees",
  balloonpmt = <any>"Balloon Pmt",
  capreduction = <any>"Cap Reduction",
  chargeunpaidtaxfinesfees = <any>"Charge: unpaid tax/fines/fees",
  cleanupdetail = <any>"Cleanup & Detail",
  collectionfees = <any>"Collection Fees",
  creditcardpointsredemption = <any>"Credit Card Points Redemption",
  creditorlifedisability = <any>"Creditor Life Disability",
  creditorlifedisabilityint = <any>"Creditor Life Disability Int",
  currentpaymentdue = <any>"Current Payment Due",
  datechange = <any>"Date Change",
  debitcardfee = <any>"Debit Card Fee",
  dentprotection = <any>"Dent Protection",
  dispositionfeeloyaltycredit = <any>"Disposition Fee Loyalty Credit",
  drivecredit = <any>"DRIVE Credit",
  duedatechangefee = <any>"Due Date Change Fee",
  easypaypayment = <any>"EasyPay Payment",
  endofleasecharge = <any>"End of Lease Charge",
  excesswearanduse = <any>"Excess Wear and Use",
  excesswearandusegst = <any>"Excess Wear and Use GST",
  excesswearanduseint = <any>"Excess Wear and Use Int",
  excesswearandusepst = <any>"Excess Wear and Use PST",
  extendedwarranty = <any>"Extended Warranty",
  extendedwarrantygsthst = <any>"Extended Warranty GST/HST",
  extendedwarrantyinterest = <any>"Extended Warranty Interest",
  extendedwarrantypst = <any>"Extended Warranty PST",
  extensionfee = <any>"Extension Fee",
  gap = <any>"GAP",
  gsthsttax = <any>"GST/HST Tax",
  insurancedeficiency = <any>"Insurance Deficiency",
  interest = <any>"Interest",
  keyprotection = <any>"Key Protection",
  lastpayment = <any>"Last Payment",
  lastpaymentgsthsttax = <any>"Last Payment GST/HST Tax",
  lastpaymentpstqsttax = <any>"Last Payment PST/QST Tax",
  latecharge = <any>"Late Charge",
  leaseduedatechange = <any>"Lease Due Date Change",
  leasepaymentdue = <any>"Lease Payment Due",
  leaseprotection = <any>"Lease Protection",
  legalfees = <any>"Legal Fees",
  licenseplatetax = <any>"License Plate Tax",
  loanduedatechange = <any>"Loan Due Date Change",
  loanpaymentdue = <any>"Loan Payment Due",
  mileageadjustment = <any>"Mileage Adjustment",
  moveadministrationfee = <any>"Move Administration Fee",
  moveregistrationadminfee = <any>"Move Registration Admin. Fee",
  othercharges = <any>"Other Charges",
  overtermcharge = <any>"Overterm Charge",
  paidaheadnopaymentdue = <any>"Paid Ahead: No Payment Due",
  parkingticket = <any>"Parking Ticket",
  parkingticketadminfee = <any>"Parking Ticket Admin. Fee",
  pasttermrent = <any>"Past Term Rent",
  paymentamountdelayed = <any>"Payment Amount Delayed",
  paymentdeferral = <any>"Payment Deferral",
  paymentmadebybmwfs = <any>"Payment Made by BMW FS",
  paymentreceivedthankyou = <any>"Payment Received.  Thank You",
  paymentwaiver = <any>"Payment Waiver",
  paymentwillbemadebybmwfs = <any>"Payment Will Be Made by BMW FS",
  pleasepaythisamount = <any>"Please Pay This Amount",
  ppsafee = <any>"PPSA Fee",
  previousbalance = <any>"Previous Balance",
  previousstatementbalance = <any>"Previous Statement Balance",
  principleinterestreduction = <any>"Principle & Interest Reduction",
  propertytax = <any>"Property Tax",
  pstqsttax = <any>"PST/QST Tax",
  quickpayfee = <any>"QuickPay Fee",
  registrationfees = <any>"Registration Fees",
  repofees = <any>"Repo Fees",
  researchfees = <any>"Research Fees",
  returneditem = <any>"Returned Item",
  returneditemfee = <any>"Returned Item Fee",
  salesusetax = <any>"Sales/Use Tax",
  securitydeposit = <any>"Security Deposit",
  serviceincplusgsthst = <any>"Service Inc Plus GST/HST",
  serviceincpluspst = <any>"Service Inc Plus PST",
  serviceinclusive = <any>"Service Inclusive",
  serviceinclusivegsthst = <any>"Service Inclusive GST/HST",
  serviceinclusiveinterest = <any>"Service Inclusive Interest",
  serviceinclusiveplus = <any>"Service Inclusive Plus",
  serviceinclusiveplusint = <any>"Service Inclusive Plus Int",
  serviceinclusivepst = <any>"Service Inclusive PST",
  storagecharge = <any>"Storage Charge",
  tireandrim = <any>"Tire and Rim",
  tireandrimint = <any>"Tire and Rim Int",
  tireandwheelprotection = <any>"Tire and Wheel Protection",
  titlefees = <any>"Title Fees",
  ultimatepayfee = <any>"UltimatePay Fee",
  unusedmileageloyaltycredit = <any>"Unused Mileage Loyalty Credit",
  windshieldprotection = <any>"Windshield Protection",
}

export enum AnalyticsAttributeName {
  ACCORDION_LINK = "analytics-event-accordian",
  CUSTOM_LINK = "analytics-event",
}

export enum AnalyticsEvent {
  ACCORDION_LINK = "accordion link click",
  BODY_LINK = "CustomLink",
  COMPOSE_MESSAGE_COMPLETE = "SMC : completed",
  COMPOSE_MESSAGE_START = "SMC : initiated",
  DOCUMENT_SIGN_COMPLETE = "SMC : signing document completed",
  DOCUMENT_SIGN_START = "SMC : signing document initiated",
  DOWNLOAD = "download tracking",
  ERROR = "error tracking",
  FOOTER_LINK = "FooterCustomLink",
  HEADER_LINK = "HeaderCustomLink",
  SITE_TOOL = "site tool tracking",
  SITE_TOOL_ERROR = "site tool error",
}

export enum AnalyticsIdOverrideName {
  MCID_COOKIE_FROM_BRIDGE_SERVICE_ECID = "mcidCookieFromBridgeServiceEcid",
}

export enum AnalyticsLinkType {
  BUTTON = "button",
  CTA_BUTTON = "cta-button",
  IMAGE = "image-link",
  LINK = "hyperlink",
  TOGGLE = "toggle",
}

export enum AnalyticsSiteToolName {
  EASY_PAY_ENROLLMENT = "EasyPay Enrollment",
  EASY_PAY_PAUSE = "EasyPay Pause",
  EASY_PAY_RESUME = "EasyPay Resume",
  EASY_PAY_UNENROLLMENT = "EasyPay Unenrollment",
  EASY_PAY_UPDATE = "EasyPay Update",
  LEASE_EXTENSION = "Extend Lease Contract",
  PAYMENT = "Payment",
  MILEAGE_MANAGEMENT = "Mileage Management",
  MILEAGE_MANAGEMENT_PURCHASE_MILES = "Mileage Management Purchase Miles",
}

export enum AnalyticsSource {
  MILEAGE_MANAGEMENT = "Mileage Management",
  SAVED_QUOTE = "SavedQuote",
}

export enum AnalyticsSiteToolStep {
  COMPLETE = "Complete",
  EDIT_PAYMENT_AMOUNT = "Edit Payment Amount",
  EDIT_PAYMENT_SOURCE = "Edit Payment Source",
  SELECT_PAYMENT_AMOUNT = "Select Payment Amount",
  SELECT_START_DATE = "Select Start Date",
  SELECT_PAYMENT_SOURCE = "Select Payment Source",
  START = "Start",
  CURRENT_MILEAGE = "Current Mileage",
  SELECT_MILES = "Select Miles",
  CALCULATE_MILES = "Calculate Miles",
  SELECT_PAYMENT_OPTION = "Select Payment Option",
  SAVE_QUOTE = "Save Quote",
  REVIEW = "Review",
}

export enum DeviceOS {
  IOS = "IOS",
  MAC = "MAC",
  ANDROID = "ANDROID",
}

export enum NewSourceType {
  ACH = "ach",
  CARD = "card",
  CREDITCARD = "creditcard",
}

export enum ContractTypeText {
  OC = "OC",
  Select = "Select",
  Retail = "Retail",
  Lease = "Lease",
}
export enum LeaselinkType {
  en = "ENGLSIH_DESTINATION_URL",
  fr = "FRENCH_DESTINATION_URL",
}
export enum PaymentCardLogErrorType {
  ExpireAccount = 1,
  InitiateCard = 2,
  FinalizeCard = 3,
  InitiationIdNotMatching = 4,
  UnknownError = 5,
}

export enum FooterMenuItem {
  toggelToEnglish = "toggelToEnglish",
  toggelToFrench = "toggelToFrench",
  privacyLink = "privacyLink",
  UnsubscribeLink = "UnsubscribeLink",
  termsLink = "termsLink",
  faqlink = "faqlink",
  contactUsLink = "contactUsLink",
  cookiePolicyLink = "cookiePolicyLink",
  homeLink = "homeLink",
}

export enum CreditCardPageType {
  MakeAPayment = "mp",
  PaymentConfirmation = "pc",
  MileageConfirmation = "mc"
}

export enum HeaderNavigationPaths {
  home = "home",
  settings = "settings"
}
export enum selectedLanguageIndexCode {
  english = 0,
  french = 1
}


