import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StringModel } from '../../../_models/string.model';


@Injectable()
export class EmailIdActions {
  static SET_EMAIL_ID: string = 'SET_EMAIL_ID';



  setEmailId(emailId: string): FSA<string, string, null> {
    return {
      type: EmailIdActions.SET_EMAIL_ID,
      payload: emailId,
      meta: null
    };
  }

}

@Injectable()
export class EmailIdReducers {
  static EmailIdReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
    switch (action.type) {
      case EmailIdActions.SET_EMAIL_ID:
        return tassign(state, new StringModel(action.payload));
      default:
        return state;
    }
  }
}
