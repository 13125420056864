import { OnDestroy, OnInit, Inject, Injector, Directive } from "@angular/core";
import {
  ActivityLoggingFactory,
  ActivityLogging,
} from "./features/logging/logging.factory";
import { ValidatorService } from "../../_validators/validator.service";
import { Store } from '@ngrx/store';
import { IAppState } from "./../../shared/store/app.store";
import { DOCUMENT } from "@angular/common";
import { DataLoadedActions } from "../../shared/store/reducers/dataloaded.reducer";
import { BooleanModel } from "../../_models/boolean.model";
import { ContractAccountDetailDTO } from "../gateway-api";
import { ActivatedRoute } from "@angular/router";
import { LanguageService } from "../../shared/_helper-services/language.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import {
  BaseDataLayerModel,
  PushDataLayerParameters
} from "../../_models/analytics/analytics-data.model";
import { AnalyticsEvent } from "../../shared/enums";
import { AnalyticsService } from "../../shared/_helper-services/analytics.service";
import { Constants } from "../../shared/constants";
import { start } from "single-spa";

@Directive()
export abstract class BaseApplicationComponent<T> implements OnDestroy, OnInit {
  protected logger: ActivityLogging;
  protected logFactory: ActivityLoggingFactory;
  private analyticsService: AnalyticsService;
  private baseValidatorService: ValidatorService;
  private dataLoadedActions: DataLoadedActions;
  public store: Store<IAppState>;
  public dataLoaded: boolean = true;
  public contract: ContractAccountDetailDTO;
  private dataLoadedSubscription;
  public scrollToTop: boolean;
  private routes: ActivatedRoute;
  private langService: LanguageService;
  private transService: TranslateService;
  public locale: string;
  public dateFormat: string;
  public dateLongFormat: string;
  private langChangeSubscription: any;
  public dataLayer: Partial<BaseDataLayerModel> = {};
  private adobeAnalyticsSubs: any;
  constructor(private injector: Injector, @Inject(DOCUMENT) public document) {
    this.logFactory = injector.get(ActivityLoggingFactory);
    this.analyticsService = injector.get(AnalyticsService);
    this.store = injector.get(Store);
    this.dataLoadedActions = injector.get(DataLoadedActions);
    this.baseValidatorService = injector.get(ValidatorService);
    this.routes = injector.get(ActivatedRoute);
    this.langService = injector.get(LanguageService);
    this.transService = injector.get(TranslateService);

    this.routes.queryParams.subscribe((params) => {
      let language = params["language"];
      if (language != undefined) {
        this.langService.setCurrentLanguage(language);
      }
    });

    this.langChangeSubscription = this.transService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.getDateAndCurrencyFormat();
      }
    );

    this.dataLoadedSubscription = this.store.select(state => state.DataLoaded)
      .subscribe((BooleanModel) => this.setDataLoadedOnChange(BooleanModel));
    this.getDateAndCurrencyFormat();
  }

  getDateAndCurrencyFormat() {
    //currency format
    this.transService.get("ngw.general.locale").subscribe((res: string) => {
      this.locale = res;
    });
    //date format
    this.transService
      .get("ngw.general.date-format")
      .subscribe((res: string) => {
        this.dateFormat = res;
      });
    this.transService
      .get("ngw.general.date-long-format")
      .subscribe((res: string) => {
        this.dateLongFormat = res;
      });
  }

  initializeLogger(implementation: T) {
    let clientAPPId: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPId = x);
    this.getLoggerForComponent(implementation, clientAPPId)
      .then(rtrn => {
        this.logger = rtrn;
        this.logger.logverbose([Constants.Logger.Initialize + Constants.Logger.Space + implementation.constructor.name])
      });
  }
  ngOnInit(): void {
          this.adobeAnalyticsSubs=this.store.select(state=>state.AdobeAnalyticsEnabled)
      .subscribe(flag => {
        if (flag && flag.booleanValue)
          this.analyticsService.embedAnalyticsScripts();
      });

  }

  ngOnDestroy(): void {

    this.dataLoadedSubscription.unsubscribe();
    this.langChangeSubscription.unsubscribe();
    if (this.adobeAnalyticsSubs)
      this.adobeAnalyticsSubs.unsubscribe();
  }

  public getLoggerForComponent(
    implementation: T,
    activityName: string = "",
    isParentActivity: boolean = false
  ) {
    return this.logFactory.getLogger<T>(
      implementation,
      activityName,
      isParentActivity
    );
  }

  public setDataLoadedOnRequest(dataLoaded: boolean) {
    this.store.dispatch(this.dataLoadedActions.setDataLoaded(dataLoaded));
  }

  public showFormFieldError(field: any, type?: string): boolean {
    return this.baseValidatorService.showError(field, type);
  }

  private setDataLoadedOnChange(BooleanModel: BooleanModel) {
    this.dataLoaded = BooleanModel.booleanValue;
  }

  get analytics(): AnalyticsService {
    return this.analyticsService;
  }

  pushDataLayer(pushDataLayerParams?: PushDataLayerParameters) {
    this.analyticsService.pushDataLayer({ dataLayer: this.dataLayer, ...pushDataLayerParams });
  }

  pushErrorEvent(errorName: string, eventName?: AnalyticsEvent) {
    this.analyticsService.pushErrorEvent(errorName, eventName);
  }

  attachAnalyticsClickHandler() {
    this.analyticsService.attachClickHandler();
  }
}
