import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { DeepLinkParam } from "../../../_models/deeplink-param.model";

@Injectable()
export class DeepLinkParamActions {
    static SET_DEEPLINK_PARAM: string = 'SET_DEEPLINK_PARAM';
    static CLEAR_DEEPLINK_PARAM: string = 'CLEAR_DEEPLINK_PARAM';

    setDeepLinkParam(deepLinkParam: DeepLinkParam): FSA<string, DeepLinkParam, null> {
    return {
        type: DeepLinkParamActions.SET_DEEPLINK_PARAM,
        payload: deepLinkParam,
      meta: null
    };
  }

    clearDeepLinkParam(): FSA<string, DeepLinkParam, null> {
      return {
          type: DeepLinkParamActions.CLEAR_DEEPLINK_PARAM,
          payload: null,
          meta: null
      };
  }
}

@Injectable()
export class DeepLinkParamReducers {
    static DeepLinkParamReducer: Reducer<DeepLinkParam> = (state: DeepLinkParam = null, action: FSA<string, DeepLinkParam, void>) => {
    switch (action.type) {
        case DeepLinkParamActions.SET_DEEPLINK_PARAM:
            return tassign(state, action.payload);
        case DeepLinkParamActions.CLEAR_DEEPLINK_PARAM:
          return tassign(undefined);
      default:
        return state;
    }
  }
}
