import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ServicingPreferencesStatus } from '../_models/servicing-preferences.model';
import {
  GetServicingPreferencesRequest, GetServicingPreferencesResponse,
  PreferenceCenterServiceClient,
  ServicingPreferenceDTO,
  ServicingPreferenceDTOPreferenceCode,
  ServicingPreferenceDTOPreferenceLevel,
  ServicingPreferenceDTOPreferenceStatus,
  UpdateServicingPreferencesRequest, UpdateServicingPreferencesRequestSystemCode
} from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { ActivityTypes } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";
import { PreferencesActions } from "../shared/store/reducers/preferences.reducers";
import { LogSiteActivityService } from './log-site-activity.service';
import { UserService } from '../shared/_helper-services/user.service';
import { ApplicationConfig } from '../_models/application-config';


@Injectable()
export class PreferenceCenterService {

  accountsNotEsigned: string[];
  storeAppConfig: ApplicationConfig;
    constructor(private preferenceCenterServiceClient: PreferenceCenterServiceClient,
        private logSiteActivityService: LogSiteActivityService,
        private userService : UserService,
        private store: Store<IAppState>,
        private preferencesActions: PreferencesActions,
        private fsTokenErrorHandler: FSTokenErrorHandler) { }

  public getCachedPreferences(): ServicingPreferenceDTO[] {
    let preference: ServicingPreferenceDTO[];
    this.store.select(state => state.Preferences).subscribe(x => preference = x);
    return preference;
  }

  public updateServicingPreferencesStatus(customerNumber: number, preferences?: ServicingPreferenceDTO[]): Observable<boolean> {
    let request = new UpdateServicingPreferencesRequest();
    let preferencesToUpdate = preferences ? preferences : this.getEsignPreferencesToBeUpdated(customerNumber);
    request.preferencesToUpdate = preferencesToUpdate;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    request.siteId = this.storeAppConfig.SITEID.toString();
    request.userId =this.storeAppConfig.USER_ID.toString();
    request.systemCode = UpdateServicingPreferencesRequestSystemCode.MyBMW;
    request.customerNumber = customerNumber;
    return this.preferenceCenterServiceClient.updateServicingPreferences(request)
      .pipe(mergeMap((response) => { return this.afterUpdateServicingPreferencesSuccess(response, preferencesToUpdate); }), catchError((error: any) => {
        return this.afterUpdateServicingPreferencesFailure(error);
      }));
  }

  public getServicingPreferences(customerNumber: number, accounts?: string[], refresh?: boolean, includeInactive?: boolean): Observable<ServicingPreferencesStatus> {
    let cachedPreferences = this.getCachedPreferences();
    if (accounts && accounts.length > 0) {
      if (refresh || !cachedPreferences || (cachedPreferences && cachedPreferences.length === 0)) {
        let request = new GetServicingPreferencesRequest();
        request.customerNumber = customerNumber;
        request.accounts = accounts;
        request.includeInactive = includeInactive == true;
        return this.preferenceCenterServiceClient.getServicingPreferences(request)
          .pipe(mergeMap((response) => { return this.afterGetServicingPreferencesSuccess(response); }), catchError((error: any) => {
            return this.afterGetServicingPreferencesFailure(error);
          }));
      }
      let servicingPreferencesStatus: ServicingPreferencesStatus = this.getServicingPreferencesStatus(cachedPreferences);
      return of(servicingPreferencesStatus);
    }
    return of(null);
  }

  public getServicingPreferencesPerAccount(customerNumber: number, accountNumber: string, includeInactive?: boolean): Observable<ServicingPreferencesStatus> {
    if (customerNumber && accountNumber) {
      let request = new GetServicingPreferencesRequest();
      request.customerNumber = customerNumber;
      request.accounts = [accountNumber];
      request.includeInactive = includeInactive ? true : false;
      return this.preferenceCenterServiceClient.getServicingPreferences(request)
        .pipe(mergeMap((response) => { return this.afterGetServicingPreferencesSuccess(response); }), catchError((error: any) => {
          return this.afterGetServicingPreferencesFailure(error);
        }));
    }
    return of(null);
  }

  private getEsignPreferencesToBeUpdated(customerNumber: number): ServicingPreferenceDTO[] {
    let preferencesToBeUpdated: ServicingPreferenceDTO[] = [];
    let accountsNotEsigned = this.accountsNotEsigned;
    _.each(accountsNotEsigned, function (accno) {
      let preference = new ServicingPreferenceDTO();
      preference.accountNumber = accno;
      preference.customerNumber = customerNumber;
      preference.preferenceCode = ServicingPreferenceDTOPreferenceCode.ElectronicDocumentConsent;
      preference.preferenceStatus = ServicingPreferenceDTOPreferenceStatus.On;
      preference.preferenceLevel = ServicingPreferenceDTOPreferenceLevel.CustomerAccount;
      preference.isPreferenceEditable = true;
      preference.preferenceValue = null;
      preference.alternateEmailIndicator = null;
      preferencesToBeUpdated.push(preference);
    });
    return preferencesToBeUpdated;
  }

  private afterGetServicingPreferencesSuccess(response: GetServicingPreferencesResponse): Observable<ServicingPreferencesStatus> {
    let servicingPreferencesStatus: ServicingPreferencesStatus = this.getServicingPreferencesStatus(response.preferences);
    this.store.dispatch(this.preferencesActions.setPreferences(response.preferences));
    return of(servicingPreferencesStatus);
  }

  private getServicingPreferencesStatus(preferences: ServicingPreferenceDTO[]): ServicingPreferencesStatus {
    let servicingPreferencesStatus = new ServicingPreferencesStatus();
    let accountsNotEsigned = this.getNotEsignedAccounts(preferences);
    this.accountsNotEsigned = accountsNotEsigned;
    servicingPreferencesStatus.isEsignAccepted = (accountsNotEsigned === undefined) || (accountsNotEsigned && accountsNotEsigned.length > 0) ? false : true;
    servicingPreferencesStatus.preferences = preferences;
    servicingPreferencesStatus.error = false;
    return servicingPreferencesStatus;
  }

  private getNotEsignedAccounts(preferences: ServicingPreferenceDTO[]): string[] {
      let accountsNotEsigned: string[] = [];
      //only include selfservice authorized account numbers.
      let accountNumbers = this.userService.getAccountNumbers();
      if (accountNumbers) {
        _.forEach(accountNumbers, accountNo=>{
          let eSigned = true;
          let esignPreferences = _.filter(preferences, function (pref) {
            return pref.preferenceCode === ServicingPreferenceDTOPreferenceCode.ElectronicDocumentConsent
                && pref.preferenceStatus === ServicingPreferenceDTOPreferenceStatus.On
                && pref.accountNumber==accountNo;
          });
          //no record or preferences is off
          if(esignPreferences.length==0){
            eSigned = false;
          }
          if(!eSigned){
            let accountNumExists = _.find(accountsNotEsigned, function (anum) { return anum === accountNo; });
              if (!accountNumExists) {
                  accountsNotEsigned.push(accountNo);
              }
          }
        });
    }
    return accountsNotEsigned;
  }

  private afterGetServicingPreferencesFailure(error: any): Observable<ServicingPreferencesStatus> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    let servicingPreferencesStatus = new ServicingPreferencesStatus();
    servicingPreferencesStatus.error = true;
    servicingPreferencesStatus.faultType = error.faultType;
    return of(servicingPreferencesStatus);
  }

  private afterUpdateServicingPreferencesSuccess(response: any, preferences: ServicingPreferenceDTO[]): Observable<boolean> {
    this.logEsignAgreementActivity(preferences)
    return of(true);
  }

  private afterUpdateServicingPreferencesFailure(error: any): Observable<boolean> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(false);
  }

  private logEsignAgreementActivity(preferences: ServicingPreferenceDTO[]) {
    if (preferences && preferences[0].preferenceCode == ServicingPreferenceDTOPreferenceCode.ElectronicDocumentConsent) {
      let customerNumber: number;
      let accountNumbers: string[] = [];
      _.each(preferences, function (preference) {
        accountNumbers.push(preference.accountNumber);
        customerNumber = preference.customerNumber
      })
      this.logSiteActivityService.logUserActivity(customerNumber, accountNumbers, ActivityTypes.eSignAgreement);
    }
  }
}
