import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { ContactDTO } from '../../../core/gateway-api';

@Injectable()
export class ContactActions
{
    static SET_CONTACT: string = 'SET_CONTACT';
    static CLEAR_CONTACT: string = 'CLEAR_CONTACT';

  setContact(contact: ContactDTO): FSA<string, ContactDTO, null>{
    return {
      type: ContactActions.SET_CONTACT,
      payload: contact,
      meta: null
    };
  }

  clearContact(): FSA<string, ContactDTO, null> {
      return {
          type: ContactActions.CLEAR_CONTACT,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class ContactReducers
{
  static ContactReducer: Reducer<ContactDTO> = (state: ContactDTO=new ContactDTO(), action: FSA<string, ContactDTO, void>) => {
    switch(action.type){
    case ContactActions.SET_CONTACT:
            return tassign(state, action.payload);
    case ContactActions.CLEAR_CONTACT:
        return tassign(undefined);
    default:
      return state;
    }
  }
}
