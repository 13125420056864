import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';
import { tassign } from 'tassign';


export class PopStateChangeActions
{
  static SET_POP_STATE_FLAG: string = 'SET_POP_STATE_FLAG';

    setPopStateChange(userIsTimedOut: boolean): FSA<string, boolean, null>{
          return {
            type: PopStateChangeActions.SET_POP_STATE_FLAG,
              payload: userIsTimedOut,
              meta: null
        };
    }
}

@Injectable()
export class PopStateChangeReducers
{
  static PopStateChangeReducers: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
          case PopStateChangeActions.SET_POP_STATE_FLAG:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
