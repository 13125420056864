import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import {  Router } from '@angular/router';
import { RedirectCanComponent } from './redirect-can.component';
import { RedirectRoutingCanModule } from './redirect-can-routing.module';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RedirectRoutingCanModule
    ],
    declarations: [RedirectCanComponent],
    providers: [   ]
})
export class RedirectCanModule { 
}
