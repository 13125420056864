import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { EnvironmentConfig } from '../../../_models/environment-config';


@Injectable()
export class EnvironmentConfigActions {
  static SET_ENVIRONMENT_CONFIG: string = 'SET_ENVIRONMENT_CONFIG';

  setEnvironmentConfig(environmentConfig: EnvironmentConfig): FSA<string, EnvironmentConfig, null> {
    return {
      type: EnvironmentConfigActions.SET_ENVIRONMENT_CONFIG,
      payload: environmentConfig,
      meta: null
    };
  }

}

@Injectable()
export class EnvironmentConfigReducers {
  static EnvironmentConfigReducer: Reducer<EnvironmentConfig> = (state: EnvironmentConfig = new EnvironmentConfig(), action: FSA<string, EnvironmentConfig, void>) => {
    switch (action.type) {
      case EnvironmentConfigActions.SET_ENVIRONMENT_CONFIG:
        return tassign(state, action.payload);
      default:
        return state;
    }
  }
}
