import { Component, OnInit, Renderer2, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterService, routes, routesV2, DeepLinkPageIds, DeepLinkPageIdsV2 } from '../../shared/_helper-services/router.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http"
import { Observable } from 'rxjs';
import { Constants } from '../../shared/constants';
import { BaseApplicationComponent } from "../../core/basecomponent/base.component";
import { DeepLinkParam } from "../../_models/deeplink-param.model";
import { DeepLinkParamActions } from "../../shared/store/reducers/deeplink-param.reducer";
import { LanguageService } from '../../shared/_helper-services/language.service';

@Component({
    selector: 'app-can-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RedirectCanComponent extends BaseApplicationComponent<RedirectCanComponent> implements OnInit {
    pageId: string;
    vin: string;
    anchor: string;
    source: string;
    private isV2PageId: boolean;
    language: string;

    constructor(private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private routerService: RouterService,
        private deepLinkParamActions: DeepLinkParamActions,
        private languageService: LanguageService,
        renderer2: Renderer2,
        @Inject(DOCUMENT) document,
        injector: Injector,
        private http: HttpClient) {
        super(injector, document);
        super.setDataLoadedOnRequest(false);
    }

    ngOnInit() {
        //workaround as suggessted for exting Ang issue - https://github.com/angular/angular/issues/34795
        setTimeout(() => {
            let clientAPPID: string;
            this.language = this.languageService.getCurrentLanguage();
            this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
            super.getLoggerForComponent(this, clientAPPID)
                .then(rtrn => {
                    this.logger = rtrn;
                    let isDeepLinkEnabled: boolean;
                    this.store.select(state => state.EnvironmentConfig.DEEPLINK_ENABLED).subscribe(x => isDeepLinkEnabled = x);
                    if (!isDeepLinkEnabled) {
                        this.routerService.navigateToHome();
                    }
                    else {
                        this.activatedRoute
                            .queryParams
                            .subscribe(params => {
                                this.pageId = params[Constants.DeepLinkPageIdParam];
                                this.vin = params[Constants.DeepLinkVINParam];
                                this.anchor = params[Constants.DeepLinkAnchorParam];
                                this.source = params[Constants.DeepLinkSourceParam];
                                this.language = params[Constants.DeepLinkLanguageParam] || this.language;
                                let deepLinkRoute: string;
                                let v2Enabled: boolean;
                                this.store.select(state => state.V2Enabled.booleanValue).subscribe(x => v2Enabled = x);
                                if (v2Enabled && DeepLinkPageIdsV2[this.pageId]) {
                                    if (this.getFeatureSwitchValue()) {
                                        deepLinkRoute = DeepLinkPageIdsV2[this.pageId];
                                        this.isV2PageId = true;
                                    }
                                    else {
                                        deepLinkRoute = DeepLinkPageIds[this.pageId];
                                        this.isV2PageId = false;
                                    }
                                }
                                else {
                                    deepLinkRoute = DeepLinkPageIds[this.pageId];
                                    this.isV2PageId = false;
                                }

                                if (!this.pageId || !deepLinkRoute) {
                                    this.routerService.navigateToHome();
                                }
                                else {
                                    this.mapToRoute(deepLinkRoute);
                                }
                            });
                    }
                });
        }, 2000);


    }

    private getFeatureSwitchValue(): boolean {
        let featureEnabled: boolean;
        let v2FeatureSwitch = new Map<string, string>([
            ["et", "EndOfTerm"]
        ]);

        const feature = v2FeatureSwitch.get(this.pageId);

        if (feature) {
            this.store.select(state => state[feature].booleanValue).subscribe(x => featureEnabled = x);
        } else {
            featureEnabled = false;
        }
        return featureEnabled;
    }

    private mapToRoute(page: string) {
        super.setDataLoadedOnRequest(true);
        if (page) {
            let deepLinkParam = new DeepLinkParam(page, this.vin, this.anchor, this.source);
            this.store.dispatch(this.deepLinkParamActions.setDeepLinkParam(deepLinkParam));
            this.dataLayer = {
                page: {
                    name: `${Constants.MyAccount}:${Constants.RedirectComponentName}:${page}`,
                    pageType: Constants.RedirectComponentName,
                    pageLevel2: page,
                    title: `${Constants.RedirectComponentName}:${page}`
                }
            }
            this.pushDataLayer({ dataLayer: this.dataLayer });

            if (this.isV2PageId) {
                if (!this.vin || (this.vin && this.vin.length < Constants.Vin7Length)) {
                    this.routerService.navigateToV2(routesV2[page]);
                }
                else {
                    this.routerService.navigateToV2(routesV2[page], { vin: this.vin, language: this.language }, this.anchor);
                }
            }
            else {
                if (!this.vin || this.vin && this.vin.length < Constants.Vin7Length) {
                    this.routerService.navigateTo(routes[page]);
                }
                else {
                    this.routerService.navigateTo(routes[page], { vin: this.vin, language: this.language }, this.anchor);
                }
            }
        }
        else {
            this.routerService.navigateToHome();
        }
    }
}
