<div class="communication-pref-dialog dialog">
  <h1 class="communication-pref-dialog--title">{{'ngw.communication-pref-dialog.title' | translate}}</h1>
  <p class="communication-pref-dialog--info">
    {{'ngw.communication-pref-dialog.content-text' | translate}}
  </p>
  <div class="communication-pref-dialog--button">
    <button type="button" tabindex="-1" class="btn btn-secondary" (click)="cancelUserAction(); $event.preventDefault();" analytics-event="body.cancel-communications-preferences.cancel-communications-preferences.button">
      {{'ngw.communication-pref-dialog.btn-cancel' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="confirmUserAction(); $event.preventDefault();">{{'ngw.communication-pref-dialog.btn-continue' | translate}}</button>
  </div>
</div>
