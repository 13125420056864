import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectCanComponent } from './redirect-can.component';
import { route } from '../../shared/_helper-services/router.service';
import { AuthGuard } from "../../_guard/auth.guard";
import { OffersEnabledResolve } from 'projects/_shared/_resolvers/offers-enabled.resolve';
import { EndOfTermFeatureIndicatorResolve } from 'projects/_shared/_resolvers/end-of-term-indicator.resolve';


const redirectRoutes: Routes = [
    {
        path: route.redirect, component: RedirectCanComponent, canActivate: [AuthGuard],
        resolve: [OffersEnabledResolve, EndOfTermFeatureIndicatorResolve]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(redirectRoutes)

    ],
    exports: [
        RouterModule
    ]
})
export class RedirectRoutingCanModule { }
