import { Component, Inject, HostListener, EventEmitter, Injector, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseApplicationComponent } from "../../../../../core/basecomponent/base.component";
import { DOCUMENT } from '@angular/common';
import { MidtermMileageAdjustment } from '../../../../../_models/map/midterm-mileage-adjustment.model';
import { MidtermMileageAdjustmentService } from '../../../../../_web-services/midterm-mileage-adjustment.service';
import { ContractAccountDetailDTO, GetMidtermMileageAdjustmentResponse } from '../../../../../core/gateway-api';
import { RouterService } from '../../../../../shared/_helper-services/router.service';

@Component({
    selector: 'mileage-adjustment-payment-cancel-dialog',
    templateUrl: './mileage-adjustment-payment-cancel.component.html',
    styleUrls: ['./mileage-adjustment-payment-cancel.component.scss']
})

export class MileageAdjustmentPaymentCancelDialog extends BaseApplicationComponent<MileageAdjustmentPaymentCancelDialog> implements OnInit, OnDestroy {

    midtermMileageAdjustmentData: MidtermMileageAdjustment;
    globalTechError: boolean = false;
    errorMessage: string = '';
    isMonthlyPayment: boolean;

    constructor(injector: Injector,
        @Inject(DOCUMENT) private _document,
        private translateService: TranslateService,
        private dialogRef: MatDialogRef<MileageAdjustmentPaymentCancelDialog>,
        private midtermMileageAdjustmentService: MidtermMileageAdjustmentService,
        private routerService: RouterService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super(injector, _document);
    }

    ngOnInit() {
        this.setInitialData();
    }

    private setInitialData() {
        this.isMonthlyPayment = this.data ? this.data.isMonthlyPayment : null;
    }

    cancelPayment() {
        this.dialogRef.close(true);
    }

    returnToPurchase() {
        this.dialogRef.close();
    }

    saveAsQuoteClick() {
        this.saveAsQuote();
    }

    public saveAsQuote() {
        let selectedDate: Date;
        //let isMonthlyPayment = 
        this.store.select(state => state.MidtermMileageAdjustment).subscribe(x => this.midtermMileageAdjustmentData = x);
        if (this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.milesToPurchase > 0) {
            let mmaData: GetMidtermMileageAdjustmentResponse = this.midtermMileageAdjustmentData.midtermMileageAdjustmentData;
            if (mmaData) {
                if (mmaData.monthlyMmaWithNoQuote == 1 || !mmaData.mMAStartDateList || mmaData.mMAStartDateList.length == 0 || !this.isMonthlyPayment) {
                    selectedDate = new Date();
                }
                else if (mmaData.monthlyMmaWithNoQuote == 0 && mmaData.mMAStartDateList && mmaData.mMAStartDateList.length > 0) {
                    selectedDate = mmaData.mMAStartDateList[0];
                }
            }
            let contractAccountDetail: ContractAccountDetailDTO;
            this.store.select(state => state.ContractAccountDetail).subscribe(x => contractAccountDetail = x);
            if (contractAccountDetail && contractAccountDetail.accountNumber && this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.milesToPurchase && selectedDate) {
                super.setDataLoadedOnRequest(false);
                this.midtermMileageAdjustmentService.saveMidtermMileageAdjustmentQuote(
                    contractAccountDetail.accountNumber,
                    this.midtermMileageAdjustmentData.milesToPurchase,
                    selectedDate,
                    this.isMonthlyPayment)
                    .subscribe(response => this.postSaveMidtermMileageAdjustmentQuote(response));
            }
        }
    }

    private postSaveMidtermMileageAdjustmentQuote(success: boolean) {
        super.setDataLoadedOnRequest(true);
        if (success) {
            this.globalTechError = false;
            this.dialogRef.close(true);
        }
        else {
            this.globalTechError = true;
            const errorTranslationKey = "ngw.global.technical-error";
            this.translateService.get(errorTranslationKey).subscribe((res: string) => {
                this.errorMessage = res;
            });
            this.pushErrorEvent(errorTranslationKey);
        }
    }
}
