import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { IAppState } from "../shared/store/app.store";
import { ContractAccountDetailDTO } from "../core/gateway-api";
import { FeatureIndicatorService } from "../_web-services/feature-indicator.service";
import { FeatureIndicatorActions } from "../shared/store/reducers/feature-indicator.reducers";
import { FeatureIndicatorNames } from "../shared/enums";
import { map } from "rxjs";
import { RouterService, route, routesV2 } from "../shared/_helper-services/router.service";

export const MakeAPaymentResolver: ResolveFn<any> = (activeRoute: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot) => {
    const routeService = inject(RouterService);
    const store = inject(Store<IAppState>);
    const featureIndicatorService = inject(FeatureIndicatorService);
    const featureIndicatorActions = inject(FeatureIndicatorActions);
    let currentContract: ContractAccountDetailDTO;
    let v2MakePaymentEnabled: boolean;
    let currentRouteIsMakeAPayment = stateSnapshot.url.includes(route.paymententry);

    const redirectFn = () => {
        if (currentRouteIsMakeAPayment && v2MakePaymentEnabled) {
            routeService.navigateToV2(routesV2.makeapayment, { vin: currentContract.vIN });
        }
    }

    store.select(state => state.ContractAccountDetail).subscribe(x => currentContract = x);
    store.select(state => state.V2MakePaymentEnabled.booleanValue).subscribe(x => v2MakePaymentEnabled = x);

    if (!v2MakePaymentEnabled) {
        return featureIndicatorService.getFeatureIndicatorByClientId(FeatureIndicatorNames.V2MakePaymentEnabled).pipe(map(res => {
            store.dispatch(featureIndicatorActions.setV2MakePaymentEnabled(res));
            redirectFn();
        }));
    }

    redirectFn();
};