import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class ContractsLoadingErrorActions {
  static SET_CONTRACTS_LOADING_ERROR_STATUS: string = 'SET_CONTRACTS_LOADING_ERROR_STATUS';

  setContractsLoadingError(errorStatus: boolean): FSA<string, boolean, null> {
    return {
      type: ContractsLoadingErrorActions.SET_CONTRACTS_LOADING_ERROR_STATUS,
      payload: errorStatus,
      meta: null
    };
  }

}

@Injectable()
export class ContractsLoadingErrorReducers {
  static ContractsLoadingErrorReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case ContractsLoadingErrorActions.SET_CONTRACTS_LOADING_ERROR_STATUS:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
}
