import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ViewRepairReceiptModel } from '../_models/view-repair-receipt.model';
import { GetRepairReceiptsRequest, GetRepairReceiptsResponse, NoteServiceClient } from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { IAppState } from "../shared/store/app.store";
import { RepairReceiptsActions } from '../shared/store/reducers/repair-receipts.reducer';

@Injectable()
export class RepairReceiptUploadService {
    constructor(
      private store: Store<IAppState>,
      private fsTokenErrorHandler: FSTokenErrorHandler,
      private repairReceiptsActions: RepairReceiptsActions,
      private noteServiceClient: NoteServiceClient) { }

  
    public checkRepairReceiptUpload(accountNumber:string): Observable<ViewRepairReceiptModel> {

    let getRepairReceiptsRequest = new GetRepairReceiptsRequest();
    getRepairReceiptsRequest.accountNumber = accountNumber;

    let receiptModel = this.getCachedRepairReceipts(accountNumber);
    if (!receiptModel) {
      return this.noteServiceClient.getRepairReceipts(getRepairReceiptsRequest)
        .pipe(mergeMap((response) => { return this.afterCheckRepairReceiptUploadSuccess(response, accountNumber); })
        ,catchError((error: any) => { return this.afterCheckRepairReceiptUploadFailure(error, accountNumber); }));
    }
    return of(receiptModel);
  }

  public getCachedRepairReceipts(accountNumber: string): ViewRepairReceiptModel {
    let repairReceipts:ViewRepairReceiptModel[];
    this.store.select(state => state.RepairReceipts).subscribe(x => repairReceipts = x);
    let cachedViewRepairReceiptModel = _.find(repairReceipts, function (repairReceipt) { return repairReceipt.accountNumber === accountNumber; });
    return cachedViewRepairReceiptModel;
  }

  private afterCheckRepairReceiptUploadSuccess(result: GetRepairReceiptsResponse, accountNumber: string): Observable<ViewRepairReceiptModel> {
    let receiptModel = new ViewRepairReceiptModel();
    
    result.repairReceipts = _.sortBy(_.uniqBy(_.sortBy(result.repairReceipts, [function (val) { return val.receiptDate }]), function (e) { return e.messagingId }), [function (val) { return val.receiptDate }]).reverse();
    receiptModel.accountNumber = accountNumber;
    receiptModel.response = result;
    receiptModel.error = false;
    
    this.store.dispatch(this.repairReceiptsActions.pushRepairReceipts(receiptModel));
    return of(receiptModel);
  }

  private afterCheckRepairReceiptUploadFailure(error: any, accountNumber: string): Observable<ViewRepairReceiptModel> {
    let receiptModel = new ViewRepairReceiptModel();
    receiptModel.accountNumber = accountNumber;
    receiptModel.error = true;
    this.store.dispatch(this.repairReceiptsActions.pushRepairReceipts(receiptModel));
    return of(receiptModel);
  }

  
}
