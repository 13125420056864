import { Component, OnInit, OnDestroy, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { BaseApplicationComponent } from "../../../../core/basecomponent/base.component";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ValidatorService } from '../../../../_validators/validator.service';
import { EmailAddressDTO, EmailAddressDTOEmailAddressType, ContactDTO } from '../../../../core/gateway-api';
import { UserService } from '../../../../shared/_helper-services/user.service';
import { PartnerContactService } from '../../../../_web-services/partner-contact.service';
import { MaskService } from '../../../../shared/_helper-services/mask.service';
import { ContactActions } from '../../../../shared/store/reducers/contact.reducers';
import { Constants } from '../../../../shared/constants';

@Component({
  selector: 'app-email-edit',
  templateUrl: './edit-email-dialog.component.html',
  styleUrls: ['./edit-email-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class EditEmailDialog extends BaseApplicationComponent<EditEmailDialog> implements OnInit {
  title: string;
  userName: string;
  errorMessage: string;
  updateEmailSourceError: boolean;
  emailList: EmailAddressDTO[];
  billingEmailAddress: EmailAddressDTO;
  otherEmailAddress: EmailAddressDTO;
  catchedBillingAddress: EmailAddressDTO;
  catchedOtherEmailAddress: EmailAddressDTO;
  contact: ContactDTO;
      
  constructor(
    private validationService: ValidatorService,
    private translateService: TranslateService,
    private userService: UserService,
    private partnerContactService: PartnerContactService,
    private maskService: MaskService,
    private contactActions:ContactActions,
    public dialogRef: MatDialogRef<EditEmailDialog>,
    injector: Injector,
    @Inject(DOCUMENT) document,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(injector, document);
    this.updateEmailSourceError = false;
  }

  ngOnInit() {
    this.translateService.get("ngw.profile.contact-information.edit-email.title").subscribe((res: string) => {
      this.title = res;
    })

    this.analytics.pushSiteToolEvent({ siteTool: Constants.EditEmailAddress, siteToolStep: Constants.SiteToolStart });

    this.store.select(state => state.Contact).subscribe(x => this.contact  = x);

    if (this.userService && this.userService.getUserName()) {
      this.userName = this.maskService.getMaskedEmail(this.userService.getUserName());
    }
    
    if (this.contact && this.contact.emailAddresses && this.contact.emailAddresses.length > 0) {
      let catchedBillingAddresses: any[] = this.contact.emailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Billing);
      if (catchedBillingAddresses && catchedBillingAddresses.length > 0) {
       
        this.catchedBillingAddress =  catchedBillingAddresses[0];

        if (this.catchedBillingAddress) {
          let billingEmailAddress = new EmailAddressDTO();
          billingEmailAddress.bMWCommercialElectronicMessages = this.catchedBillingAddress.bMWCommercialElectronicMessages;
          billingEmailAddress.emailAddress = this.catchedBillingAddress.emailAddress;
          billingEmailAddress.emailAddressType = this.catchedBillingAddress.emailAddressType;
          billingEmailAddress.emailId = this.catchedBillingAddress.emailId;
          billingEmailAddress.externalSourceUpdateDt = this.catchedBillingAddress.externalSourceUpdateDt;
          billingEmailAddress.externalUpdateSource = this.catchedBillingAddress.externalUpdateSource;
          billingEmailAddress.isPrevious = this.catchedBillingAddress.isPrevious;
          billingEmailAddress.isPrimary = this.catchedBillingAddress.isPrimary;
          billingEmailAddress.mINICommercialElectronicMessages = this.catchedBillingAddress.mINICommercialElectronicMessages;
          this.billingEmailAddress = billingEmailAddress;
        }


      }
      let catchedOtherEmailAddresses = this.contact.emailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Home);
      if (catchedOtherEmailAddresses && catchedOtherEmailAddresses.length > 0) {
        this.catchedOtherEmailAddress = catchedOtherEmailAddresses[0];

        if (this.catchedOtherEmailAddress) {
          let otherEmailAddress = new EmailAddressDTO();

          otherEmailAddress.bMWCommercialElectronicMessages = this.catchedOtherEmailAddress.bMWCommercialElectronicMessages;
          otherEmailAddress.emailAddress = this.catchedOtherEmailAddress.emailAddress;
          otherEmailAddress.emailAddressType = this.catchedOtherEmailAddress.emailAddressType;
          otherEmailAddress.emailId = this.catchedOtherEmailAddress.emailId;
          otherEmailAddress.externalSourceUpdateDt = this.catchedOtherEmailAddress.externalSourceUpdateDt;
          otherEmailAddress.externalUpdateSource = this.catchedOtherEmailAddress.externalUpdateSource;
          otherEmailAddress.isPrevious = this.catchedOtherEmailAddress.isPrevious;
          otherEmailAddress.isPrimary = this.catchedOtherEmailAddress.isPrimary;
          otherEmailAddress.mINICommercialElectronicMessages = this.catchedOtherEmailAddress.mINICommercialElectronicMessages;

          this.otherEmailAddress = otherEmailAddress;
        }
      }
    }
  }

  public cancel() {
    this.dialogRef.close(null);
  }

  public updateEmail(emailData: any) {

    if (this.catchedBillingAddress && this.catchedBillingAddress.emailAddress != emailData.primaryEmail) {
      this.billingEmailAddress.emailAddress = emailData.primaryEmail;
      this.partnerContactService.updateEmail(this.billingEmailAddress).subscribe(data => { this.postUpdateEmail(true) }, data => { this.postUpdateEmail(false) });
    }
   
    if (this.catchedOtherEmailAddress && this.catchedOtherEmailAddress.emailAddress != emailData.secondaryEmail) {
      this.otherEmailAddress.emailAddress = emailData.secondaryEmail;
      this.partnerContactService.updateEmail(this.otherEmailAddress).subscribe(data => { this.postUpdateEmail(true) }, data => { this.postUpdateEmail(false) });
    }

    if ((this.catchedBillingAddress && this.catchedBillingAddress.emailAddress == emailData.primaryEmail) && (this.catchedOtherEmailAddress && this.catchedOtherEmailAddress.emailAddress == emailData.secondaryEmail)) {
      this.dialogRef.close(null);
    }
  }

  public ValidateRequiredEmail(emailAddress:any) {
    return this.validationService.showError(emailAddress, 'required');
  }

  public ValidateEmailFormat(emailAddress:any) {
    return this.validationService.showRegexError(emailAddress, 'email') || this.validationService.showError(emailAddress, 'maxlength');
  }

  public toggleSaveOnValidate(primaryEmailAddress: string, secondaryEmailAddress: string) {

    return (this.validationService.showError(primaryEmailAddress, 'required') || this.validationService.showRegexError(secondaryEmailAddress, 'email')
      || this.validationService.showError(secondaryEmailAddress, 'maxlength') || this.validationService.showRegexError(primaryEmailAddress, 'email')
      || this.validationService.showError(primaryEmailAddress, 'maxlength'));

  }
  private postUpdateEmail(response: boolean) {
    if (response == false) {
      this.updateEmailSourceError = true;
      const errorTranslationKey = "ngw.global.technical-error";
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.errorMessage = res;
      });
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
      super.setDataLoadedOnRequest(true);
    }
    else {
      this.analytics.pushSiteToolEvent({ siteTool: Constants.EditEmailAddress, siteToolStep: Constants.SiteToolComplete });
      this.updateContactEmail();
      this.store.dispatch(this.contactActions.setContact(this.contact));
    }
    this.dialogRef.close(null);
  }

  private updateContactEmail() {
    let contactEmailAddresses = this.contact ? this.contact.emailAddresses : null;
    if (contactEmailAddresses) {
      if (contactEmailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Billing) &&
        contactEmailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Billing)[0]) {
        contactEmailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Billing)[0].emailAddress = this.billingEmailAddress.emailAddress;
      }
      if (contactEmailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Home) &&
        contactEmailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Home)[0]) {
        contactEmailAddresses.filter(email => email.emailAddressType == EmailAddressDTOEmailAddressType.Home)[0].emailAddress = this.otherEmailAddress.emailAddress;
      }
    }
  }

}
