import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { MidtermMileageAdjustment } from '../../../_models/map/midterm-mileage-adjustment.model';

@Injectable()
export class MidtermMileageAdjustmentActions {
  static SET_MIDTERM_MILEAGE_ADJUSTMENT: string = 'SET_MIDTERM_MILEAGE_ADJUSTMENT';
  static CLEAR_MIDTERM_MILEAGE_ADJUSTMENT: string = 'CLEAR_MIDTERM_MILEAGE_ADJUSTMENT';

  setMidtermMileageAdjustment(midtermMileageAdjustment: MidtermMileageAdjustment): FSA<string, MidtermMileageAdjustment, null> {
    return {
      type: MidtermMileageAdjustmentActions.SET_MIDTERM_MILEAGE_ADJUSTMENT,
      payload: midtermMileageAdjustment,
      meta: null
    };
  }

  clearMidtermMileageAdjustment(): FSA<string, MidtermMileageAdjustment, null> {
    return {
      type: MidtermMileageAdjustmentActions.CLEAR_MIDTERM_MILEAGE_ADJUSTMENT,
      payload: null,
      meta: null
    };
  }

}

@Injectable()
export class MidtermMileageAdjustmentReducers {
  static MidtermMileageAdjustmentReducer: Reducer<MidtermMileageAdjustment> = (state: MidtermMileageAdjustment = new MidtermMileageAdjustment(), action: FSA<string, MidtermMileageAdjustment, void>) => {
    switch (action.type) {
      case MidtermMileageAdjustmentActions.SET_MIDTERM_MILEAGE_ADJUSTMENT:
        return tassign(state, action.payload);
      case MidtermMileageAdjustmentActions.CLEAR_MIDTERM_MILEAGE_ADJUSTMENT:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
