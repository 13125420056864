import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash-es';
import { Constants } from '../constants';
import { StepperType } from 'customerdigital-ui-lib/lib/models/stepper.model';
import { EasyPayEnrollmentSteps } from '../enums';

@Injectable()
export class EasyPayEnrollmentHelperService {
  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {
  }

  private noOfDaysBetweenDate(pastDate: Date, currentDate: Date): any {
    var timeDifference = Math.abs(currentDate.getTime() - pastDate.getTime());
    return Math.ceil(timeDifference / (Constants.OneThousandDays * Constants.ThirtySixHundredDays * Constants.TwentyFourDays));
  }

  public showEasyPayEnrollementDialog(startDate: string, firstAvailDate: Date, totalAmountDue: number): boolean {
    if (firstAvailDate && startDate !== firstAvailDate.toString() ||
      (startDate === firstAvailDate.toString() && totalAmountDue !== null && totalAmountDue > 0
        && this.noOfDaysBetweenDate(new Date(startDate), new Date()) > 20 ? true : false)) {
      return true;
    }
    return false;
  }

  public setEasyPayEnrollmentMessage(data: any) {
    let dialogContent;
    let date;
    let mediumDateFormat;
    let easyPayEnrollmentMessage;

    dialogContent = "ngw.shared.easy-pay-enrollment-dialog.content-text";
    date = new Date(data.startDate);
    this.translateService.get("ngw.general.date-medium-format").subscribe((res: string) => {
      mediumDateFormat = res;
    });
    this.translateService.get(dialogContent, { startDate: this.datePipe.transform(date, mediumDateFormat) }).subscribe((res: string) => {
      easyPayEnrollmentMessage = res;
    });
    return easyPayEnrollmentMessage;
  }

  public getEasyPayEnrollmentSteps(easyPayEnrollmentStep: string): Array<StepperType> {
    let steps: Array<StepperType> = [];
    steps.push({ label: EasyPayEnrollmentSteps.PaymentSource, editable: false, active: false, visible: true, completed: false },
      { label: EasyPayEnrollmentSteps.PaymentAmout, editable: false, active: false, visible: true, completed: false },
      { label: EasyPayEnrollmentSteps.PaymentDate, editable: false, active: false, visible: true, completed: false },
      { label: EasyPayEnrollmentSteps.Review, editable: false, active: false, visible: true, completed: false });
    if (easyPayEnrollmentStep) {
      steps.find(x => x.label === easyPayEnrollmentStep).active = true;
      steps.slice(0, Number(easyPayEnrollmentStep) - 1).map(x => x.completed = true);
    }
    return steps;
  }
}
