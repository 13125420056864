import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { DueDateChangeReviewModel } from '../../../_models/due-date-change/due-date-change-review.model';


@Injectable()
export class DueDateChangeReviewActions {
  static SET_DUE_DATE_CHANGE_REVIEW_DETAILS: string = 'SET_DUE_DATE_CHANGE_REVIEW_DETAILS';
  static CLEAR_DUE_DATE_CHANGE_REVIEW_DETAILS: string = 'CLEAR_DUE_DATE_CHANGE_REVIEW_DETAILS';

  setDueDateChangeReview(dueDateChangeReview: DueDateChangeReviewModel): FSA<string, DueDateChangeReviewModel, null> {
    return {
        type: DueDateChangeReviewActions.SET_DUE_DATE_CHANGE_REVIEW_DETAILS,
        payload: dueDateChangeReview,
        meta: null
    };
  }

  clearDueDateChangeReview(): FSA<string, DueDateChangeReviewModel, null> {
      return {
        type: DueDateChangeReviewActions.CLEAR_DUE_DATE_CHANGE_REVIEW_DETAILS,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class DueDateChangeReviewReducers {
    static DueDateChangeReviewReducer: Reducer<DueDateChangeReviewModel> = (state: DueDateChangeReviewModel = new DueDateChangeReviewModel(), action: FSA<string, DueDateChangeReviewModel, void>) => {
    switch (action.type) {
        case DueDateChangeReviewActions.SET_DUE_DATE_CHANGE_REVIEW_DETAILS:
          return tassign(state, action.payload);
        case DueDateChangeReviewActions.CLEAR_DUE_DATE_CHANGE_REVIEW_DETAILS:
        return tassign(undefined);
        
      default:
        return state;
    }
  }
}
