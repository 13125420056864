<app-spinner></app-spinner>
<div class="modal-edit-email">
  <div class="payment-source-container">
    <app-title [title]="title"></app-title>
    <div class="required-fields">
      {{'ngw.general.required' | translate}}
    </div>
    <div class="general error-container" role="alert" *ngIf="updateEmailSourceError">
      <p class="error-validation" id="errorMessage">
        {{errorMessage}}
      </p>
    </div>
    <form #emailEditForm="ngForm" novalidate>
      <div class="form-group">
        <label class="control-label" for="emailUsername">{{'ngw.profile.contact-information.edit-email.lbl-user-name' | translate }}<span class="icon icon-info" matTooltip="{{'ngw.profile.contact-information.edit-email.ttip-username' | translate }}" matTooltipPosition="right" #tooltipUsername="matTooltip" (mouseenter)="tooltipUsername.toggle()" (touchstart)="tooltipUsername.toggle()"></span></label>
        <div class="read-only">{{userName}}</div>
      </div>
      <div class="form-group">
        <label class="control-label required" for="primaryEmail">{{'ngw.profile.contact-information.edit-email.lbl-primary-email' | translate }}</label>
        <input type="email" #primaryEmail="ngModel" required class="form-control" id="primaryEmail" name="primaryEmail" [(ngModel)]="billingEmailAddress.emailAddress" />
        <em class="error-validation" id="primaryEmailRequiredError" *ngIf="ValidateRequiredEmail(emailEditForm.controls.primaryEmail)">{{'ngw.profile.contact-information.edit-email.message-primary-email-required' | translate }}</em>
        <em class="error-validation" id="primaryEmailPatternError" *ngIf="ValidateEmailFormat(emailEditForm.controls.primaryEmail)">{{'ngw.profile.contact-information.edit-email.message-email-formate-validate' | translate }}</em>
      </div>
      <div class="form-group">
        <label class="control-label" for="secondaryEmail">{{'ngw.profile.contact-information.edit-email.lbl-secondary-email' | translate }} <span class="icon icon-info" matTooltip="{{'ngw.profile.contact-information.edit-email.tooltip-alternate-email' | translate }}" matTooltipPosition="right" #tooltipSecondaryEmail="matTooltip" (mouseenter)="tooltipSecondaryEmail.toggle()" (touchstart)="tooltipSecondaryEmail.toggle()"></span></label>
        <input type="email" #secondaryEmail="ngModel" class="form-control" id="secondaryEmail" name="secondaryEmail" [(ngModel)]="otherEmailAddress.emailAddress" />
        <em class="error-validation" id="primaryEmailPatternError" *ngIf="ValidateEmailFormat(emailEditForm.controls.secondaryEmail)">{{'ngw.profile.contact-information.edit-email.message-email-formate-validate' | translate }}</em>
      </div>
      <div class="action-buttons">
        <button type="button" class="btn btn-secondary" (click)="cancel()" analytics-event="body.cancel-edit-email.cancel-edit-email.button">
          {{'ngw.profile.contact-information.edit-email.btn-cancel' | translate }}</button>
        <button type="submit" class="btn btn-primary" [disabled]="toggleSaveOnValidate(emailEditForm.controls.primaryEmail,emailEditForm.controls.secondaryEmail)"
                (click)="updateEmail(emailEditForm.value);$event.preventDefault();">
          {{'ngw.profile.contact-information.edit-email.btn-save' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
