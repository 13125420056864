import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';
import { tassign } from 'tassign';


@Injectable()
export class EsignAgreementCheckedActions {
  static SET_ESIGN_AGREEMENT_CHECKED: string = 'SET_ESIGN_AGREEMENT_CHECKED';



  setEsignAgreementChecked(esignAgreementChecked: boolean): FSA<string, boolean, null> {
    return {
      type: EsignAgreementCheckedActions.SET_ESIGN_AGREEMENT_CHECKED,
      payload: esignAgreementChecked,
      meta: null
    };
  }

}

@Injectable()
export class EsignAgreementCheckedReducers {
  static EsignAgreementCheckedReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case EsignAgreementCheckedActions.SET_ESIGN_AGREEMENT_CHECKED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
}
