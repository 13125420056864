import { formatNumber } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { CoBrandedCreditCardModel } from '../../../_models/cobranded-credit-card.model';
import { PartnerIntegrationService } from '../../../_web-services/partner-integration.service';
import { DialogService } from '../../_helper-services/dialog.service';
import { IAppState } from '../../store/app.store';

@Component({
  selector: 'app-credit-card-banner',
  templateUrl: './credit-card-banner.component.html',
  styleUrls: ['credit-card-banner.component.scss']
})

export class CreditCardBannerComponent implements OnInit {

  @Input() cardHolderModel: CoBrandedCreditCardModel
  @ViewChild('creditCardSsoPostForm') creditCardSsoPostForm: ElementRef;

  isEnoughPoints: boolean = false;
  isNotCreditCardHolder: boolean = false;
  url: string;
  textLessThanMinumum: string;


  constructor(private dialogService: DialogService, private store: Store<IAppState>, private translateService: TranslateService, private partnerIntegrationService: PartnerIntegrationService) { }

  ngOnInit() {
    this.store.select(state => state.EnvironmentConfig.MY_BMW_CREDIT_CARD_URL).subscribe(x => this.url = x);
    if (this.cardHolderModel.minimumRequiredPoints != null) {
      this.translateService.get('ngw.credit-card-banner.right-content-text-less-than-minimum', { MinimumRequiredPoints: formatNumber(this.cardHolderModel.minimumRequiredPoints, "en-US") }).subscribe((res: string) => {
        this.textLessThanMinumum = res;
      });

      this.isEnoughPoints = (this.cardHolderModel.isCardHolder && this.cardHolderModel.rewardPoints >= this.cardHolderModel.minimumRequiredPoints);
    }
    this.isNotCreditCardHolder = !this.cardHolderModel.isCardHolder;

  }

  openHowItWorkDialog() {
    this.dialogService.openHowToRedeemCreditPointDialog();
  }

  applyNow() {
    if (this.cardHolderModel.cardHolderSsoEnabled && this.cardHolderModel.isCardHolder) {
      //For Card Holder
      this.partnerIntegrationService.getSamlResponse().pipe(
        map(response => response))
        .subscribe(response => this.postSubmitURL(response));
    }
    else {
      //For Other Scenarios
      this.postSubmitURL("");
    }
  }

  private postSubmitURL(response: string) {

    if (response != undefined && response != "") {
      this.cardHolderModel.samlBase64 = response;
      this.store.select(state => state.EnvironmentConfig.CREDIT_CARD_SSO_LOGIN_URL).subscribe(x => this.cardHolderModel.ssoLoginUri = x);
    }
    let dialogRef = this.dialogService.openCommunicationPreferenceDialog();
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result == true) {
        if (this.creditCardSsoPostForm != undefined &&
          this.cardHolderModel.samlBase64 != undefined && this.cardHolderModel.samlBase64 != "") {
          //For CardHolder
          this.creditCardSsoPostForm.nativeElement.submit();
        }
        else {
          //For Other Scenarios
          window.open(this.url, '_blank');
        }
      }
    });
  }

}
