import { Component, OnInit, Inject, Injector, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from "../../../../core/basecomponent/base.component";
import { DOCUMENT } from '@angular/common';
import { RouterService } from "../../../../shared/_helper-services/router.service";
import { VehicleReturnEstimateDTO, ContractAccountDetailDTO } from '../../../../core/gateway-api';

@Component({
  selector: 'app-estimated-purchase-summary',
  templateUrl: './estimated-purchase-summary.component.html',
  styleUrls: ['./estimated-purchase-summary.component.scss']
})

export class EstimatedPurchaseSummaryComponent extends BaseApplicationComponent<EstimatedPurchaseSummaryComponent> implements OnInit {

  outstandingBalance: number;
  balloonPayment: number;
  purchaseDiscount: number;
  feesAndCredits: number;
  estimatedPurchaseTotal: number;
  contractDetail: ContractAccountDetailDTO;

  @Input() vehicleReturnEstimate: VehicleReturnEstimateDTO;

  constructor(private _location: Location, private translateService: TranslateService, private routerService: RouterService,
    injector: Injector,
    @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  ngOnInit() {    
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    this.balloonPayment = this.contractDetail && this.contractDetail.balloonAmount ? this.contractDetail.balloonAmount : 0;
    if (this.vehicleReturnEstimate) {
      this.purchaseDiscount = 0 - this.vehicleReturnEstimate.customerPurchaseDiscount;
      this.outstandingBalance = this.vehicleReturnEstimate.remainingPaymentsDetails ? this.vehicleReturnEstimate.remainingPaymentsDetails.total : 0;
      if (this.vehicleReturnEstimate.feesAndCreditsDetails) {
        this.feesAndCredits = this.vehicleReturnEstimate.feesAndCreditsDetails.feeAndCreditsTotal;
      }
      this.estimatedPurchaseTotal = this.outstandingBalance + this.balloonPayment + this.feesAndCredits + this.purchaseDiscount;
    }
  }

}
