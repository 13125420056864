import { AccountActivityDetailDTO } from '../../core/gateway-api';

export class AccountActivity {
  error: boolean;
  accountNumber: string;
  accountDetails: AccountActivityDetailDTO[];

  constructor(error?: boolean, accountNumber?: string, accountDetails?: AccountActivityDetailDTO[]) {
    this.accountDetails = accountDetails;
    this.accountNumber = accountNumber;
    this.error = error;
  }
}
