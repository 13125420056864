import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LoginErrorType, LoginStatus } from '../_models/login-status.model';
import { AppMode, User } from '../_models/user.model';
import { AuthenticateUserRequest, AuthenticateUserResponse, GCDMDTOGCDMLevelType, LoginServiceByTokenClient, LoginServiceClient, ProviderKey, SiteKey } from '../core/gateway-api';
import { FaultCodes } from '../shared/FaultCodes';
import { AccountInfoService } from '../shared/_helper-services/account-info.service';
import { FilterService } from '../shared/_helper-services/filter.service';
import { StorageHelperService } from '../shared/_helper-services/storage-helper.service';
import { UserService } from '../shared/_helper-services/user.service';
import { Constants } from "../shared/constants";
import { ApplicationId } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";
import { ContactActions } from "../shared/store/reducers/contact.reducers";
import { HasLoggedInActions } from '../shared/store/reducers/has-logged-in.reducer';
import { AuthService } from './auth.service';
import { PartnerContactService } from './partner-contact.service';
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class LoginService {

  private currentUser: User;
  private password: string;
  private code: string;
  private siteKey: SiteKey;
  private providerKey: ProviderKey;
  private authenticateUserRequest: AuthenticateUserRequest;
  private authenticateUserResponse: AuthenticateUserResponse;
  private loginStatus: LoginStatus;
  public initialUserLoginState: EventEmitter<any> = new EventEmitter<any>();
  public storeAppConfig: ApplicationConfig;
  constructor(
    private loginServiceClient: LoginServiceClient,
    private loginServiceByTokenClient: LoginServiceByTokenClient,
    private filterService: FilterService,
    private userService: UserService,
    private storageHelperService: StorageHelperService,
    private partnerContactService: PartnerContactService,
    private store: Store<IAppState>,
    private authService: AuthService,
    private accountInfoService: AccountInfoService,
    private contactActions: ContactActions,
    private hasLoggedInActions: HasLoggedInActions) { }

  login(emailAddress: string, password: string): Observable<LoginStatus> {
    this.currentUser = new User(emailAddress);
    this.password = password;
    this.userService.setApplicationId(ApplicationId.Ngd.toString());
    return this.authenticateUser(false, undefined, undefined);

  }

  ssoLogin(code: string, grantType: string, uri: string): Observable<LoginStatus> {
    this.currentUser = new User();
    this.code = code;
    switch (grantType) {
      case Constants.SsoAuthenticateUserGrantType:
        this.currentUser.appMode = AppMode.AuthHub
        return this.authenticateUser(true, grantType, uri);
      case Constants.SsoIdToken:
        this.currentUser.appId = ApplicationId.App2SSO;
        let applicationId = this.userService.getApplicationId();
        this.setAppModeAndId(applicationId);
        return this.authenticateUser(true, grantType, uri);
      default:
        this.currentUser.appMode = AppMode.Default
        return this.authenticateUser(true, grantType, uri);
    }
  }

  private setAppModeAndId(applicationId: ApplicationId) {
    this.userService.setApplicationId(applicationId.toString());
  }

  private setProviderKeySiteKey() {
    this.providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    this.providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    this.siteKey = new SiteKey();
    this.siteKey.siteId = this.storeAppConfig.SITEID;
  }

  private authenticateUser(isSSO: boolean, grantType: string, uri: string): Observable<LoginStatus> {
    this.authenticateUserRequest = new AuthenticateUserRequest();
    this.setProviderKeySiteKey();
    if (isSSO) {
      this.authenticateUserRequest.code = this.code;
      this.authenticateUserRequest.grantType = grantType;
      let ssoRedirectURI: string;
      this.store.select(state => state.EnvironmentConfig.SSO_REDIRECT_URI).subscribe(x => ssoRedirectURI = x);
      this.authenticateUserRequest.redirectUri = (uri == undefined) ? ssoRedirectURI : uri;
      this.authenticateUserRequest.username = null;
      this.authenticateUserRequest.password = null;
    }
    else {
      this.authenticateUserRequest.username = this.currentUser.userName;
      this.authenticateUserRequest.password = this.password;
    }
    this.authenticateUserRequest.siteKey = this.siteKey;
    this.authenticateUserRequest.providerKey = this.providerKey;

    this.loginStatus = new LoginStatus();

    return this.loginServiceClient.authenticateUser(this.authenticateUserRequest)
      .pipe(mergeMap((response) => { return this.afterAuthenticationPostSuccess(response); }), catchError((error: any) => {
        return this.afterAuthenticationPostFailure(error);
      }));

  }

  private afterAuthenticationPostSuccess(result: any): Observable<LoginStatus> {
    this.authenticateUserResponse = result;
    this.currentUser.isAuthenticatedUser = this.authenticateUserResponse.userEntity.mutable.isAuthenticated;
    if (this.currentUser.isAuthenticatedUser) {
      //map the data back
      this.currentUser.bearerToken = this.authenticateUserResponse.userEntity.mutable.token;
      this.currentUser.isTermsAccepted = this.authenticateUserResponse.userEntity.mutable.isTermsAccepted;
      this.currentUser.tokenExpiresIn = this.authenticateUserResponse.userEntity.mutable.tokenExpiresIn;
      this.currentUser.refreshToken = this.authenticateUserResponse.userEntity.mutable.refreshToken;
      this.currentUser.gcid = this.authenticateUserResponse.userEntity.key.userId;
      this.currentUser.userName = this.authenticateUserResponse.userEntity.mutable.username;
      let policyConsents = this.authenticateUserResponse.userEntity.mutable.policyConsents.map(x => Object.assign({}, x));
      this.currentUser.policyConsents = policyConsents;
      this.userService.setCurrentUser(this.currentUser);
      this.store.dispatch(this.hasLoggedInActions.setHasLoggedIn(true));
      this.userService.setFs(this.storageHelperService.getCookie(this.storageHelperService.getFSCookieKey(this.currentUser.gcid)))
      this.authService.handleTokenExpiryAfterInterval();
      //call partner contact service to get the user's gcdm access level
      return this.partnerContactService.getContactByGcidClientId()
        .pipe(mergeMap((response) => { return this.afterGetContactByGcidSuccess(response); })
          , catchError((error: any) => { return this.afterGetContactByGcidFailure(error); }));
    } else {
      //error items
      this.loginStatus.error = true;
      this.loginStatus.errortype = LoginErrorType.AuthenticationFailed;
      return of(this.loginStatus);
    }
  }

  private afterGetContactByGcidSuccess(response: any): Observable<LoginStatus> {
    if (response.contact) {
      let filteredFinancialProducts = this.filterService.getFilteredFinancialProducts(response.contact.financialProducts);
      this.accountInfoService.enCodeAndSaveCosyUrl(filteredFinancialProducts);
      response.contact.financialProducts = filteredFinancialProducts;
      this.store.dispatch(this.contactActions.setContact(response.contact));
      if (response.contact.isFsCustomer && response.contact.gCDMRelationships) {
        if (response.contact.gCDMRelationships.
          filter(data => (data.gCID === this.currentUser.gcid)
            && (data.gCDMLevelType == GCDMDTOGCDMLevelType.FinancialServices ||
              data.gCDMLevelType == GCDMDTOGCDMLevelType.Driver ||
              data.gCDMLevelType == GCDMDTOGCDMLevelType.DriverAndFinancialServices)).length > 0) {
          this.currentUser.isFsLogin = true;
          this.loginStatus.error = false;
        }
      }
      let customerNumber = response.contact.eDBCustomerNumber;
      this.currentUser.customerNumber = customerNumber;
      let accountNumbers = this.filterService.getFilteredAccountNumbers(response.contact.financialProducts);
      this.currentUser.accountNumbers = accountNumbers;
      this.userService.setCurrentUser(this.currentUser);
    }
    return of(this.loginStatus);
  }

  private afterGetContactByGcidFailure(error: any): Observable<LoginStatus> {
    if (error && error.faultType === FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_ContactNotFound_V201112_ContactNotFoundFault ||
      error.faultType === FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_GCDMAccountNotActivatedFault ||
      error.faultType === FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_DataValidation_V201112_DataValidationFault ||
      error.faultType === FaultCodes.BMWFSAM_Services_CustomerRelationshipManagement_NoMatchesFoundFault) {
      //contact not found fault
      this.currentUser.isFsLogin = false;
      this.loginStatus.currentUser = this.currentUser;
      this.userService.setCurrentUser(this.currentUser);
      this.loginStatus.error = false;
    }
    else {
      //any other server error, we can treat as login failed.
      this.loginStatus.error = true;
      this.loginStatus.errortype = LoginErrorType.AuthenticationFailed;
    }
    return of(this.loginStatus);
  }

  private afterAuthenticationPostFailure(error: any): Observable<LoginStatus> {
    this.loginStatus.error = true;
    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserAccountExpired_V201109_UserAccountExpiredFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountExpired;
            break;
          }
        case FaultCodes
          .BMWFSAM_Services_IdentityAndAccessManagement_UserAccountPasswordLocked_V201109_UserAccountPasswordLockedFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountLocked;
            break;
          }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UsernameDisabled_V201109_UsernameDisabledFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountLocked;
            break;
          }
        case FaultCodes
          .BMWFSAM_Services_IdentityAndAccessManagement_UserAccountNotActivated_V201109_UserAccountNotActivatedFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountNotActivated;
            break;
          }
        default:
          {
            //statements;
            this.loginStatus.errortype = LoginErrorType.AuthenticationFailed;
            break;
          }
      }
    } else {
      //generic server error
      this.loginStatus.errortype = LoginErrorType.GenericServerError;
    }


    return of(this.loginStatus);
  }
}
