import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { GuardService } from './../shared/_helper-services/guard.service';
import { UserService } from './../shared/_helper-services/user.service';

export const NotTermsGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {
    const guardService = inject(GuardService);
    const userService = inject(UserService);
    guardService.setLanguage(state);
    if (!userService.isTermsAccepted())
        return guardService.guardSucceded();

    return guardService.guardFailed("home");
}

