import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { FeatureIndicatorService } from '../_web-services/feature-indicator.service';
import { FeatureIndicatorNames } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";
import { BooleanModel } from '../_models/boolean.model';
import { FeatureIndicatorActions } from '../shared/store/reducers/feature-indicator.reducers';
import { Observable, of } from 'rxjs';

export const OffersEnabledResolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) : Observable<boolean> => {
  const store = inject(Store<IAppState>);
  const featureIndicatorService = inject(FeatureIndicatorService);
  const featureIndicatorActions = inject(FeatureIndicatorActions)
  let offersEnabled: BooleanModel;
  store.select(state => state.OffersEnabled).subscribe(x => offersEnabled = x);
  if (offersEnabled.booleanValue === null) {
    
    return featureIndicatorService.getFeatureIndicatorByClientId(FeatureIndicatorNames.Offers).pipe(switchMap(offersEnabled => {
      store.dispatch(featureIndicatorActions.setOffers(offersEnabled));
      return of(offersEnabled);
    }));
  }else{
    return of(offersEnabled.booleanValue);
  }

};

