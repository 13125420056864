import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { PartnerContactService } from "../_web-services/partner-contact.service";
import { ContactDTO } from '../core/gateway-api';
import { FSTokenErrorHandler } from "../shared/_errorhandler/gobal-error-handler";
import { AccountInfoService } from '../shared/_helper-services/account-info.service';
import { ContactActions } from "../shared/store/reducers/contact.reducers";
import { RouterService } from './../shared/_helper-services/router.service';
import { IAppState } from "./../shared/store/app.store";

export const ContactResolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store<IAppState>);
  const routerService = inject(RouterService);
  const partnerContactService = inject(PartnerContactService);
  const fsTokenErrorHandler = inject(FSTokenErrorHandler);
  const accountInfoService = inject(AccountInfoService);
  const contactActions = inject(ContactActions);
  let contact: ContactDTO;
  let afterGetContactByGcidSuccess = (response: any) => {
    store.dispatch(contactActions.setContact(response.contact));
    accountInfoService.enCodeAndSaveCosyUrl(response.contact.financialProducts);
  }

  let afterGetContactByGcidFailure = (error: any) => {
    fsTokenErrorHandler.handleFSTokenError(error);
  }
  store.select(state => state.Contact).subscribe(x => contact = x);
  if (!contact && !contact.eDBCustomerNumber || !contact.financialProducts) {
    return partnerContactService.getContactByGcidClientId().pipe(map(response => {
      if (response && !response.error) {
        afterGetContactByGcidSuccess(response);
      }
      else {

        afterGetContactByGcidFailure(response.error);
        routerService.navigateToHome();
      }
    }));
  }
};