export class DueDateChangeReviewModel {
  error: boolean;
  errorMessage: string;
  monthlyPayment: number;
  currentDueDay: number;
  newDueDay: number;
  dueDateChangeFee: number;
  dueDateChangeFeeTotal: number;
  proRatedAmount: number;
  currentPaymentDueDate: Date;
  nextPaymentDueDate: Date;
  finalPaymentDueDate: Date;
  contractEndDate: Date;
  acceptAgreement: boolean;
  feeAmount: number;
  feeLabel: string;
  feeTooltipText: string;
  faultType: string;
  note: string;

  constructor(error?: boolean,
      monthlyPayment?: number,
      currentDueDay?: number,
      newDueDay?: number,
      dueDateChangeFee?: number,
      dueDateChangeFeeTotal?: number,
      proRatedAmount?: number,
      currentPaymentDueDate?: Date,
      nextPaymentDueDate?: Date,
      finalPaymentDueDate?: Date,
      contractEndDate?: Date,
      acceptAgreement?: boolean,
      errorMessage?: string,
      feeAmount?: number) {
      this.error = error;
      this.monthlyPayment = monthlyPayment;
      this.currentDueDay = currentDueDay;
      this.newDueDay = newDueDay;
      this.dueDateChangeFee = dueDateChangeFee;
      this.dueDateChangeFeeTotal = dueDateChangeFeeTotal;
      this.proRatedAmount = proRatedAmount;
      this.currentPaymentDueDate = currentPaymentDueDate;
      this.nextPaymentDueDate = nextPaymentDueDate;
      this.finalPaymentDueDate = finalPaymentDueDate;
      this.contractEndDate = contractEndDate;
      this.acceptAgreement = acceptAgreement;
      this.errorMessage = errorMessage;
      this.feeAmount = feeAmount;
  }
}
