import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()

export class MessageService {
  private addAchsubject = new Subject<any>();
  private cancelAchSubject = new Subject<any>();

  public sendMessage(message: string) {
    this.addAchsubject.next({ text: message });
  }

  public getMessage(): Observable<any> {
    return this.addAchsubject.asObservable();
  }

  public sendCancelNotification(message: string) {
    this.cancelAchSubject.next({ text: message });
  }

  public getCancelNotification(): Observable<any> {
    return this.cancelAchSubject.asObservable();
  }

}
