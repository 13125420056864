<mat-horizontal-stepper linear="false" #mileageAdjustmentStepper (selectionChange)="changedStep($event)">
  <mat-step *ngIf="displayAccountInEligible">
    <app-error-modal></app-error-modal>
  </mat-step>
  <mat-step [stepControl]="enterMiles">
    <app-entry-miles></app-entry-miles>
  </mat-step>
  <mat-step [stepControl]="currentMiles">
    <app-current-miles [stepper]="mileageAdjustmentStepper"></app-current-miles>
  </mat-step>
  <mat-step [stepControl]="totalMiles">
    <app-exceed-total-miles [stepper]="mileageAdjustmentStepper"></app-exceed-total-miles>
  </mat-step>
  <mat-step [stepControl]="selectMiles">
    <app-select-miles [stepper]="mileageAdjustmentStepper"></app-select-miles>
  </mat-step>
  <mat-step [stepControl]="preferredPaymentOption">
    <app-preferred-payment-option [stepper]="mileageAdjustmentStepper"></app-preferred-payment-option>
  </mat-step>
  <mat-step [stepControl]="cancelPurchaseMiles">
    <app-cancel-purchase-miles [stepper]="mileageAdjustmentStepper"></app-cancel-purchase-miles>
  </mat-step>
  <mat-step [stepControl]="quoteSaved">
    <app-quote-saved [stepper]="mileageAdjustmentStepper"></app-quote-saved>
  </mat-step>
</mat-horizontal-stepper>
<div class="global-action-buttons" *ngIf="!hideActionsBar">
  <div class="modify-button-container">
    <button *ngIf="showModifyButton" class="btn btn-text modify" (click)="modifyStepper(mileageAdjustmentStepper)">
      <i class="fa fa-chevron-left" aria-hidden="true"></i> {{'ngw.map.entry.lnk-modify' | translate}}
    </button>
  </div>
  <div class="indicator-container">
    <div class="indicator" [ngClass]="{'active': activeBullet(mileageAdjustmentStepper, 2)}"></div>
    <div class="indicator" [ngClass]="{'active': activeBullet(mileageAdjustmentStepper, 4)}"></div>
    <div class="indicator" [ngClass]="{'active': activeBullet(mileageAdjustmentStepper, 5)}"></div>
  </div>
  <div class="skip-button-container">
    <button *ngIf="showSkipButton" class="btn btn-text skip" (click)="skipEnterMiles(mileageAdjustmentStepper)">{{'ngw.map.entry.current-miles.btn-skip' | translate}} <i class="fa fa-chevron-right" aria-hidden="true"></i></button>
  </div>
</div>
