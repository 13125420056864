
export class CustomerDealerDataModel {

  isDsiDealer: boolean;
  error: boolean;

  constructor() {
    this.isDsiDealer = false;
    this.error = false;
  }

}

