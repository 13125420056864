import { Injectable, Injector, EventEmitter } from '@angular/core';
import { StorageType } from './../../_models/storagetype.model';
import { AccountInfoService } from './account-info.service';
import { Constants } from './../constants';
import { User } from './../../_models/user.model';
import { Idle } from '@ng-idle/core';
import { PaymentReviewDetailsActions } from '../store/reducers/payment-review-details.reducer';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.store';
import { PaymentEntryActions } from '../store/reducers/payment-entry.reducer';
import { ApplicationConfigActions } from '../store/reducers/application-config.reducer';
import { ApplicationConfig } from '../../_models/application-config';
import { EnvironmentConfig } from '../../_models/environment-config';
import { EnvironmentConfigActions } from '../store/reducers/environment-config.reducer';
import { RecurringPaymentEntryActions } from '../store/reducers/recurring-payment.reducer';
import { RouterService } from './router.service';
import { DocumentPolicyConsent } from '../../core/gateway-api';
import { ApplicationId, DeviceOS } from './../enums';
import { StorageHelperService } from './storage-helper.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class UserService {
  private currentUser: User;
  public userSuccessfullyLoggedOut: EventEmitter<any> = new EventEmitter<any>();
  public setChatBot: EventEmitter<any> = new EventEmitter<any>();

  constructor(private idle: Idle,
    private storageService: StorageHelperService,
    private accountInfoService: AccountInfoService,
    private injector: Injector,
    private paymentReviewDetailsActions: PaymentReviewDetailsActions,
    private applicationConfigActions: ApplicationConfigActions,
    private environmentConfigActions: EnvironmentConfigActions,
    private paymentEntryActions: PaymentEntryActions,
    private recurringPaymentEntryActions: RecurringPaymentEntryActions,
    private routerService: RouterService,
    private deviceDetectorService: DeviceDetectorService,
    private store: Store<IAppState>) {
  }

  setCurrentUser(data: User) {
    this.currentUser = data;
    this.storageService.setItem(Constants.USER_DATA, JSON.stringify(this.currentUser), StorageType.session);
  }

  clearSession() {
    this.currentUser = null;
    this.storageService.removeItem(Constants.USER_DATA, StorageType.session);
  }

  isLoggedIn(): boolean {
    this.currentUser = this.getUserFromSession();
    return this.currentUser !== null && this.currentUser !== undefined;
  }

  isAuthenticated(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.isAuthenticatedUser;
    } else {
      return false;
    }
  }

  hasCheckedClientDeviceToken(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.deviceTokenChecked;
    } else {
      return false;
    }
  }

  public setDeviceTokenChecked(checked: boolean): void {
    this.currentUser = this.getUserFromSession();
    this.currentUser.deviceTokenChecked = checked;
    this.setCurrentUser(this.currentUser);
  }

  isFsLogin(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.isFsLogin && this.currentUser.isAuthenticatedUser);
    } else {
      return false;
    }
  }

  isHomePageActivityLogged(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.isHomePageActivityLogged);
    } else {
      return false;
    }
  }

  isLegitimizationCompletedInCurrentSession(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.isLegitimizationCompletedInCurrentSession);
    } else {
      return false;
    }
  }

  isTermsAccepted(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.isTermsAccepted;
    } else {
      return false;
    }
  }

  isEsignAccepted(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.isEsignAccepted;
    } else {
      return false;
    }
  }

  setUserNameInfo(firstName: string, lastName: string): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.firstName = firstName;
      this.currentUser.lastName = lastName;
      this.setCurrentUser(this.currentUser);
    }
  }

  isHomeAccess(validateTerms: boolean): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {

      let isAuthenticatedAndFs = this.currentUser.isAuthenticatedUser &&
        this.currentUser.isFsLogin &&
        this.currentUser.fsToken !== '' &&
        this.currentUser.fsToken !== undefined;

      return validateTerms ? isAuthenticatedAndFs && this.currentUser.isTermsAccepted : isAuthenticatedAndFs;
    } else {
      return false;
    }
  }

  public getUserBearerToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.bearerToken);
    } else {
      return '';
    }
  }

  public getUserBearerTokenExpireTime(): number {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.tokenExpiresIn);
    } else {
      return 0;
    }
  }

  public getUserRefreshToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.refreshToken);
    } else {
      return '';
    }
  }

  public resetBearerToken(accessToken: string, tokenExpiresIn: number, refreshToken: string): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser !== null) {
      this.currentUser.bearerToken = accessToken;
      this.currentUser.tokenExpiresIn = tokenExpiresIn;
      this.currentUser.refreshToken = refreshToken;
      this.setCurrentUser(this.currentUser);
    }
  }

  // todo: change implementation, just for testing purposes
  public getFSToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser && this.currentUser.fsToken;
    } else {
      return '';
    }
  }

  public getDeviceToken(): string {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      return this.storageService.getCookie(this.storageService.getFSCookieKey(this.currentUser.gcid));
    }
    else {
      return "";
    }
  }

  public getDeviceTokenByGcid(gcid: string): string {
    if (gcid != undefined && gcid != "") {
      return this.storageService.getCookie(this.storageService.getFSCookieKey(gcid));
    }
    else {
      return "";
    }
  }

  public getUserName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.userName;
    } else {
      return '';
    }
  }

  public getFirstName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.firstName;
    } else {
      return '';
    }
  }
  public getLastName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.lastName;
    } else {
      return '';
    }
  }
  public setUserName(firstName: string, lastName: string) {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.firstName = firstName;
      this.currentUser.lastName = lastName;
      this.setCurrentUser(this.currentUser);

    }
  }
  public getCustomerNumber(): number {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.customerNumber;
    } else {
      return null;
    }
  }

  public getHasBeenAutoEnrolled(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.hasBeenAutoEnrolled;
    } else {
      return null;
    }
  }

  public getTransactionId(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.twoFactorAuthTransactionid;
    } else {
      return null;
    }
  }

  public getAccountNumbers(): string[] {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.accountNumbers;
    } else {
      return null;
    }
  }


  public getGcid(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.gcid;
    } else {
      return '';
    }
  }

  public getDocumentPolicyConsent(): DocumentPolicyConsent[] {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.policyConsents;
    } else {
      return null;
    }
  }

  public getShowBulletins(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.showBulletins;
    } else {
      return false;
    }
  }

  public setShowBulletins(showBulletins: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.showBulletins = showBulletins;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setFsStatus(status: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.isFsLogin = status;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setChatBotVisibleStatus(status: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.isChatBotAllowed = status;
      this.setCurrentUser(this.currentUser);
      //Raise an event for chatBot Initializaton
      this.setChatBot.emit(true);
    }
  }

  public setHomePageActivityLogged(status: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.isHomePageActivityLogged = status;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setLegitimizationCompletedInCurrentSession(status: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.isLegitimizationCompletedInCurrentSession = status;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setTermsAccepted(accepted: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.isTermsAccepted = accepted;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setHasBeenAutoEnrolled(enrolled: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.hasBeenAutoEnrolled = enrolled;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setEsignAccepted(accepted: boolean): void {
    this.currentUser = this.getUserFromSession();
    this.currentUser.isEsignAccepted = accepted;
    this.setCurrentUser(this.currentUser);
  }

  public setFs(fsToken: string): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.fsToken = fsToken;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setCustomerNumber(customerNumber: number): void {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      this.currentUser.customerNumber = customerNumber;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setAccountNumbers(accountNumbers: string[]): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.accountNumbers = accountNumbers;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setTransactionId(transactionId: string): void {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      this.currentUser.twoFactorAuthTransactionid = transactionId;
      this.setCurrentUser(this.currentUser);
    }
  }

  // two-factor authenticated
  public fsTokenExists(): boolean {
    this.currentUser = this.getUserFromSession();
    return this.currentUser &&
      this.currentUser.fsToken !== '' &&
      this.currentUser.fsToken !== undefined &&
      this.currentUser.fsToken !== null;
  }

  public chatBotVisibeStatusExists(): boolean {
    this.currentUser = this.getUserFromSession();
    return this.currentUser.isChatBotAllowed;
  }

  public customerDeviceTokenExists(): boolean {
    const currentUserGcid = this.getGcid();
    return this.storageService.getCookie(this.storageService.getFSCookieKey(currentUserGcid)) !== '';
  }

  public clearFSToken(): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.fsToken = '';
      this.setCurrentUser(this.currentUser);
    }
  }

  public removeDeviceCookie(): void {
    this.storageService.deleteCookie(this.currentUser.gcid, '/', window.location.hostname);
  }

  public logout(): void {
    this.clearSession();
    this.clearOnBoardingSession();
    this.accountInfoService.clearCache(true);
    // this line comes here because of circular reference auth service-idle service
    this.idle.stop();
    this.userSuccessfullyLoggedOut.emit(true);
    this.routerService.navigateToLogout();
  }

  public getUserFromSession(): User {
    let sessionValue = this.storageService.getItem(Constants.USER_DATA, StorageType.session);

    if (sessionValue) {

      let sessionUser: User = JSON.parse(sessionValue);

      if (sessionUser) {
        // logged in so return true
        return sessionUser;
      }
    }
    return null;
  }

  public resetPaymentHeaderDetails() {
    this.store.dispatch(this.paymentReviewDetailsActions.clearPaymentReviewDetails());
    this.store.dispatch(this.paymentEntryActions.clearPaymentEntry());
    this.store.dispatch(this.recurringPaymentEntryActions.clearRecurringPaymentEntry());
  }


  public setEnvironmentConfig(_environmentConfig: EnvironmentConfig): void {
    this.store.dispatch(this.environmentConfigActions.setEnvironmentConfig(_environmentConfig));
  }

  public setApplicationConfig(_applicationConfig: ApplicationConfig): void {
    this.store.dispatch(this.applicationConfigActions.setApplicationConfig(_applicationConfig));
  }

  public getApplicationId(): ApplicationId {
    let applicationId = ApplicationId.App2SSO;
    let client_app_id: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => client_app_id = x);
    if (client_app_id === Constants.App2ClientAppId) {
      let deviceOs = this.deviceDetectorService.os.toUpperCase();
      if (deviceOs === DeviceOS.IOS || deviceOs === DeviceOS.MAC) {
        applicationId = ApplicationId.App2IOS;
      }
      else if (deviceOs === DeviceOS.ANDROID) {
        applicationId = ApplicationId.App2ANDROID;
      }
    }
    return applicationId;
  }

  public setApplicationId(applicationId: string): void {
    let applicationConfig: ApplicationConfig;
    this.store.select(state => state.ApplicationConfig).subscribe(x => applicationConfig = x);
    applicationConfig.CLIENT_APP_ID = applicationId;
    this.setApplicationConfig(applicationConfig);
  }


  public isInConnectedAppMode(): boolean {
    let appConfig: ApplicationConfig;
    this.store.select(state => state.ApplicationConfig).subscribe(x => appConfig = x);
    return appConfig && appConfig.CLIENT_APP_ID && (appConfig.CLIENT_APP_ID === ApplicationId.ConnectedApp.toString() || appConfig.CLIENT_APP_ID === ApplicationId.ConnectedAppIOS.toString());
  }

  public isIniOSMode(): boolean {
    let appConfig: ApplicationConfig;
    this.store.select(state => state.ApplicationConfig).subscribe(x => appConfig = x);
    return appConfig && appConfig.IS_IN_IOS_MODE && appConfig.IS_IN_IOS_MODE === "true";
  }

  public setAgentId(agentId: string): void {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      this.currentUser.agentId = agentId;
      this.setCurrentUser(this.currentUser);
    }
  }

  public getAgentId(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.agentId;
    } else {
      return null;
    }
  }

  public setJwToken(jwToken: string) {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.jwToken = jwToken;
      this.setCurrentUser(this.currentUser);
    }
  }

  public getJwToken(): string {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      return this.currentUser.jwToken;
    } else { return Constants.EMPTY }
  }

  public clearOnBoardingSession() {
    this.storageService.removeItem(Constants.ONBOARDING_MODULE_DATA, StorageType.session);
  }
}
