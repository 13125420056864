<div class="legal-agreement-dialog-container" [ngClass]="{'dialog-error-container': hasError, 'height-container': applyHeightContainer(), 'one-show-expand': hasOnlyOneAgreementShownAndExpanded()}">
  <div class="general error-container" role="alert" *ngIf="hasError; else noError">
    <em class="error-validation" id="errorMessage" analytics-error="ngw.global.technical-error">{{'ngw.global.technical-error' | translate}}</em>
  </div>
  <ng-template #noError>
    <mat-horizontal-stepper linear="false" #onboardingDialogStepper>
      <mat-step *ngIf="showLegalAgreementsStep" [label]="agreementsStepLabel">
        <app-legal-agreement-terms-conditions  (setExpanded$)="setExpanded($event)"></app-legal-agreement-terms-conditions>
      </mat-step>
      <mat-step *ngIf="showPaperlessStep" [label]="paperlessStepLabel">
        <app-legal-agreement-paperless *ngIf="isInPaperlessEnrollMode"></app-legal-agreement-paperless>
        <app-legal-agreement-done *ngIf="!isInPaperlessEnrollMode"></app-legal-agreement-done>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-template>
  <app-legal-agreement-dialog-footer [stepper]="stepper" (setError$)="setError($event)" (setMode$)="setMode($event)"></app-legal-agreement-dialog-footer>
</div>
