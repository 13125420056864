import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IAppState } from "../../../../shared/store/app.store";
import { LeaseExtensionEligbility } from '../../../../_models/lease-extension/lease-extension-eligibility.model';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../constants';
import { LeaseExtensionHelperService } from '../../../_helper-services/lease-extension-helper.service';
import { ContractAccountDetailActions } from "../../../../shared/store/reducers/contract-account-detail.reducers";
import { AnalyticsService } from '../../../../shared/_helper-services/analytics.service';
import { DatePipe } from '@angular/common';
import { CustomerACHService } from '../../../../_web-services/customer-ach.service';
import * as _ from 'lodash-es';
import { ContractAccountDetailDTO } from '../../../../core/gateway-api';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RecurringPaymentEntry } from '../../../../_models/recurring-payment/recurring-payment-entry.model';

@Component({
  selector: 'extend-lease-contract-can-dialog',
  templateUrl: './extend-lease-contract-can-dialog.component.html',
  styleUrls: ['./extend-lease-contract-can-dialog.component.scss']
})

export class ExtendLeaseContractCanDialog implements OnInit {
  public leaseExtensionEligibilities: LeaseExtensionEligbility[]
  isLeaseExtensionEligible: boolean;
  elgibilityDescription: string;
  elgibilityDescriptionNote: string = Constants.EMPTY;
  isEligibileForOther: boolean;
  showButton: boolean;
  showContactUs: boolean;
  disableButton: boolean;
  public findEasyPayScheduleSubs: any;
  longDateFormat: string;
  contract: ContractAccountDetailDTO;

  constructor(
    public dialogRef: MatDialogRef<ExtendLeaseContractCanDialog>,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private customerACHService: CustomerACHService,
    private leaseExtensionHelperService: LeaseExtensionHelperService,
    private datePipe: DatePipe,
    private contractAccountDetailActions: ContractAccountDetailActions,
    private store: Store<IAppState>,
    private deviceDetectorService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.translateService.get("ngw.payment.date-format").subscribe((res: string) => {
      this.longDateFormat = res;
    });
  }

  ngOnInit() {
    if (this.data) {
      this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contract = x);
      let recurringPayments: RecurringPaymentEntry[];
      this.store.select(state => state.EasypaySchedules).subscribe(x => recurringPayments = x);
      let accountNumber = this.data.contract.accountNumber;
      let easyPayScheduled = _.find(recurringPayments, function (ep) {
        if (ep != undefined && ep.accountNumber != undefined)
          return ep.accountNumber === accountNumber && ep.error === false;
      });
      if (easyPayScheduled) {
        this.setPageData(easyPayScheduled);
      }
      else {
        this.customerACHService.findEasyPaySchedule(this.data.contract.accountNumber).subscribe(response => this.setPageData(response));
      }
    }
  }

  private setPageData(easypaySchedules: any) {
    this.leaseExtensionEligibilities = new Array();
    this.leaseExtensionEligibilities.push(this.leaseExtensionHelperService.isAnyOutstandingBalanceCan(this.data));
    this.leaseExtensionEligibilities.push(this.leaseExtensionHelperService.isValidMaturityDate(this.data));
    this.isEligibileForOther = this.leaseExtensionHelperService.otherInEligibilityConditions(this.data, easypaySchedules);
  }


  public isEligibleToExtend() {
    if (this.isEdgeBrowser()) {
      this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-for-edge-browser").subscribe((res: string) => {
        this.elgibilityDescription = res;
      });
      this.showButton = false;
      return true;
    }
    else if (this.leaseExtensionEligibilities) {
      let notEligibleArray = this.leaseExtensionEligibilities.filter(item => !item.isEligible);
      this.isLeaseExtensionEligible = notEligibleArray.length == 0 && this.isEligibileForOther;
      if (this.isLeaseExtensionEligible) {
        if (this.isDealerInQuebec() || this.isCustomerInQuebec() || this.hasCoHolder()) {
          this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-description-3").subscribe((res: string) => {
            this.elgibilityDescription = res;
          });
          this.showButton = false;
          this.showContactUs = true;
          return true;
        }
        else {
          let originalContractEndDate = new Date(this.data.contract.originalContractEndDate);
          let maxDateToExtend = this.leaseExtensionHelperService.addMonths(new Date(originalContractEndDate.toString()), + Constants.MaxMonthsToExtend);
          this.translateService.get("ngw.shared.extend-lease-contract-dialog.eligible-description-1", { MaxDateToExtend: String(this.datePipe.transform(maxDateToExtend, this.longDateFormat)) }).subscribe((res: string) => {
            this.elgibilityDescription = res;
          });
          this.showButton = true;
          this.disableButton = false;
          return true;
        }
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  public anyOutStandingBalanceOrValidFurtureMaturityDate() {
    if (this.leaseExtensionEligibilities && this.leaseExtensionEligibilities.length > 0) {
      let leaseExtensionOutstanding: any;
      let leaseExtensionMaturityDate: any;
      if (this.leaseExtensionEligibilities[0]) {
        leaseExtensionOutstanding = this.leaseExtensionEligibilities[0];
      }
      if (this.leaseExtensionEligibilities[1]) {
        leaseExtensionMaturityDate = this.leaseExtensionEligibilities[1];
      }

      if (!leaseExtensionOutstanding.isEligible || !leaseExtensionMaturityDate.isEligible) {
        if (!leaseExtensionOutstanding.showMakeAPayment && leaseExtensionMaturityDate.isEligible) {
          this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-description-3").subscribe((res: string) => {
            this.elgibilityDescription = res;
          });
          this.showButton = false;
        }
        else {
          this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-description-2").subscribe((res: string) => {
            this.elgibilityDescription = res;
          });
          this.showButton = true;
          this.disableButton = true;
        }
        return true;
      } else {
        return false;
      }
    }
    else
      return false;
  }

  private isDealerInQuebec(): boolean {
    return this.contract.originatingDealerStateCode && this.contract.originatingDealerStateCode.toUpperCase() === Constants.QuebecState;
  }

  private isCustomerInQuebec(): boolean {
    let address = _.find(this.contract.addresses, function (address) {
      return address.state.indexOf(Constants.QuebecState) > -1;
    });

    return address ? true : false;
  }

  private hasCoHolder(): boolean {
    return this.contract.hasCoHolder;
  }

  private isEdgeBrowser(): boolean {
    return this.deviceDetectorService.browser.toLocaleLowerCase().indexOf(Constants.Edge.toLocaleLowerCase()) > -1;
  }

  public pastMaturityDate() {
    if (this.leaseExtensionEligibilities && this.leaseExtensionEligibilities.length > 0) {
      let leaseExtensionPastMaturity: any;
      if (this.leaseExtensionEligibilities[2]) {
        leaseExtensionPastMaturity = this.leaseExtensionEligibilities[2];
      }
      if (leaseExtensionPastMaturity.isEligible) {
        this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-description-3").subscribe((res: string) => {
          this.elgibilityDescription = res;
        });
        this.showButton = false;
        this.disableButton = false;
        return true;
      } else {
        return false;
      }
    }
    else
      return false;
  }

  public closeDialogOnly() {
    this.dialogRef.close();
  }

  public navigateToContactUs(): void {
    this.dialogRef.close(Constants.ContactUsComponentName);
  }

  public navigateToMakeAPayment(): void {
    this.store.dispatch(this.contractAccountDetailActions.setContractAccountDetail(this.data.contract));
    this.dialogRef.close(Constants.PaymentEntryComponentName);
  }
  public navigateToLeasePayment() {
    this.store.dispatch(this.contractAccountDetailActions.setContractAccountDetail(this.data.contract));
    this.dialogRef.close(Constants.LeaseExtensionPaymentDuration);
  }
}
