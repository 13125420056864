import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { UpdatePhoneNumbers } from '../_models/contract/contact-information.model';
import { EditAddressErrorType, EditAddressModel } from '../_models/edit-address.model';
import {
    AccountProfileManagementServiceClient,
    AddressDTO,
    CheckAddressRequest,
    CheckAddressResponse,
    ContactDTO,
    MovePackDTO,
    PhoneNumberDTO,
    UpdateAccountAddressRequest,
    UpdateAccountPhoneRequest
} from '../core/gateway-api';
import { FaultCodes } from '../shared/FaultCodes';
import { UserService } from '../shared/_helper-services/user.service';
import { Constants } from '../shared/constants';
import { IAppState } from "../shared/store/app.store";

@Injectable()
export class AccountProfileManagementService {
    constructor(
        private accountProfileManagementServiceClient: AccountProfileManagementServiceClient,
        private userService: UserService,
        private store: Store<IAppState>) {
    }


    public checkAddress(address: AddressDTO, accountNumber: string): Observable<EditAddressModel> {
        let checkAddressRequest = new CheckAddressRequest();
        checkAddressRequest.accountNumber = accountNumber;
        checkAddressRequest.customerNumber = this.userService.getCustomerNumber();
        checkAddressRequest.address = address;

        return this.accountProfileManagementServiceClient.checkAddress(checkAddressRequest)
            .pipe(mergeMap((response) => { return this.afterCheckAddressSuccess(response); }),
                catchError((error: any) => { return this.afterCheckAddressFailure(error); }));
    }

    public updateAccountAddress(newAddress: AddressDTO, accountNumber: string, oldAddress?: AddressDTO, mailingAddress?: AddressDTO, geocode?: string, sendEmailConfirmation?: boolean, emailAddress?: string, isLeaseAddress?: boolean, movePackEnabled?: boolean): Observable<EditAddressModel> {
        let updateAccountAddressRequest = new UpdateAccountAddressRequest();
        let contact: ContactDTO;
        this.store.select(state => state.Contact).subscribe(x => contact = x);
        updateAccountAddressRequest.accountNumber = accountNumber;
        updateAccountAddressRequest.customerNumber = this.userService.getCustomerNumber();
        updateAccountAddressRequest.address = newAddress;
        updateAccountAddressRequest.movePack = new MovePackDTO();
        if (isLeaseAddress && oldAddress) {
            updateAccountAddressRequest.geocode = geocode;
            updateAccountAddressRequest.movePack.oldCity = oldAddress.city;
            updateAccountAddressRequest.movePack.oldState = oldAddress.state;
            updateAccountAddressRequest.movePack.oldPostalCode = oldAddress.postalCode;
            updateAccountAddressRequest.movePack.newCity = newAddress.city;
            updateAccountAddressRequest.movePack.newState = newAddress.state;
            updateAccountAddressRequest.movePack.newPostalCode = newAddress.postalCode;
            updateAccountAddressRequest.movePack.confirmationEmailAddress = emailAddress;
            updateAccountAddressRequest.movePack.sendEmailConfirmation = sendEmailConfirmation;
            if (sendEmailConfirmation) {
                updateAccountAddressRequest.movePack.sendToFirstName = contact ? contact.firstName : Constants.EMPTY;
                updateAccountAddressRequest.movePack.sendToLastName = contact ? contact.lastName : Constants.EMPTY;
            }
            if (movePackEnabled && mailingAddress) {
                updateAccountAddressRequest.movePack.sendToAddress1 = mailingAddress.address1;
                updateAccountAddressRequest.movePack.sendToAddress2 = mailingAddress.address2;
                updateAccountAddressRequest.movePack.sendToAddress3 = mailingAddress.address3;
                updateAccountAddressRequest.movePack.sendToCity = mailingAddress.city;
                updateAccountAddressRequest.movePack.sendToState = mailingAddress.state;
                updateAccountAddressRequest.movePack.sendToPostalCode = mailingAddress.postalCode;
            }



        }

        return this.accountProfileManagementServiceClient.updateAccountAddress(updateAccountAddressRequest)
            .pipe(mergeMap((response) => { return this.afterUpdateAccountAddressSuccess(response); }),
                catchError((error: any) => { return this.afterUpdateAccountAddressFailure(error); }));
    }


    private afterCheckAddressSuccess(result: CheckAddressResponse): Observable<EditAddressModel> {
        let editAddressModel: EditAddressModel;
        if (result) {
            editAddressModel = new EditAddressModel(false, null, result.validatedAddress, null, null, result.paymentAdjustments, result.taxJurisdictions, result.responseErrorCode, null, null, result.movePackEligible);
        }
        else {
            editAddressModel = new EditAddressModel(true);
        }
        return of(editAddressModel);
    }

    private afterCheckAddressFailure(error: any): Observable<EditAddressModel> {
        let errorType: EditAddressErrorType = this.getEditAddressErrorType(error);
        let editAddressModel = new EditAddressModel(true, null, null, null, null, null, null, null, errorType, error.faultCode);
        return of(editAddressModel);
    }

    private afterUpdateAccountAddressSuccess(result: any): Observable<EditAddressModel> {
        let editAddressModel = new EditAddressModel(false);
        return of(editAddressModel);
    }

    private afterUpdateAccountAddressFailure(error: any): Observable<EditAddressModel> {
        let errorType: EditAddressErrorType = this.getEditAddressErrorType(error);
        let editAddressModel = new EditAddressModel(true, null, null, null, null, null, null, null, errorType, error.faultCode);
        return of(editAddressModel);
    }

    private getEditAddressErrorType(error: any): EditAddressErrorType {
        let errorType: EditAddressErrorType;
        if (error.faultType) {
            switch (error.faultType) {
                case FaultCodes.BMWFSAM_Services_AccountManagement_AddressValidationFault: {
                    errorType = EditAddressErrorType.AddressValidation;
                    break;
                }
                case FaultCodes.BMWFSAM_Services_AccountManagement_POBoxAddressFault: {
                    errorType = EditAddressErrorType.POBoxAddress;
                    break;
                }
                case FaultCodes.BMWFSAM_Services_AccountManagement_DataValidation_V201112_DataValidationFault: {
                    errorType = EditAddressErrorType.DataValidation;
                    break;
                }

                default: {
                    errorType = EditAddressErrorType.AddressValidationOrUpdateFailed;
                    break;
                }
            }
        }
        else {
            errorType = EditAddressErrorType.AddressValidationOrUpdateFailed;
        }
        return errorType;
    }


    public updateAccountPhone(accountNumber: string, phoneNumberDetail: PhoneNumberDTO): Observable<UpdatePhoneNumbers> {
        let request = new UpdateAccountPhoneRequest();
        request.accountNumber = accountNumber;
        request.customerNumber = this.userService.getCustomerNumber();
        request.newPhoneNumber = phoneNumberDetail;

        return this.accountProfileManagementServiceClient.updateAccountPhone(request)
            .pipe(mergeMap((response) => { return this.afterUpdateAccountPhoneSuccess(response); })
                , catchError((error: any) => { return this.afterUpdateAccountPhoneFailure(error); }));
    }

    private afterUpdateAccountPhoneSuccess(result: any): Observable<UpdatePhoneNumbers> {
        let updatePhoneNumbers = new UpdatePhoneNumbers();
        updatePhoneNumbers.error = false;
        return of(updatePhoneNumbers);
    }

    private afterUpdateAccountPhoneFailure(error: any): Observable<UpdatePhoneNumbers> {
        let updatePhoneNumbers = new UpdatePhoneNumbers();
        updatePhoneNumbers.error = true;
        updatePhoneNumbers.faultType = error.faultType;
        updatePhoneNumbers.errorDesc = error.response;
        return of(updatePhoneNumbers);
    }

}
