import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { QuoteInformation } from '../_models/my-account/quote-information.model';
import { AccountKey, AuditUserKey, CalculateandSavePayoffLetterPdfForAccountRequest, ContractualPayoffDocumentServiceClient, PayoffQuoteReasonKey } from '../core/gateway-api';
import { FaultCodes } from '../shared/FaultCodes';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { IAppState } from "../shared/store/app.store";

@Injectable()
export class ContractualPayoffDocumentService {
  constructor(
    private contractualPayoffDocumentServiceClient: ContractualPayoffDocumentServiceClient,
    private fsTokenErrorHandler: FSTokenErrorHandler,
    private store: Store<IAppState>) {
  }

  public calculateandSavePayoffLetterPdfForAccount(accountNumber: string): Observable<QuoteInformation> {
    let userId: string;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userId = x);
    let accountKey = new AccountKey();
    accountKey.accountNumber = accountNumber;
    let calculateandSavePayoffLetterPdfRequest = new CalculateandSavePayoffLetterPdfForAccountRequest();
    calculateandSavePayoffLetterPdfRequest.accountKey = accountKey;
    let auditUser = new AuditUserKey();
    auditUser.userId = userId;
    calculateandSavePayoffLetterPdfRequest.quotedTo = userId;
    calculateandSavePayoffLetterPdfRequest.auditUser = auditUser;
    calculateandSavePayoffLetterPdfRequest.originatingSystem = userId;
    let reasonKey = new PayoffQuoteReasonKey();
    reasonKey.payoffQuoteReasonId = 8;
    calculateandSavePayoffLetterPdfRequest.payoffQuoteReasonKey = reasonKey;
    calculateandSavePayoffLetterPdfRequest.quoteReasonNarritive = "";
    return this.contractualPayoffDocumentServiceClient.calculateandSavePayoffLetterPdfForAccount(calculateandSavePayoffLetterPdfRequest)
      .pipe(mergeMap((response) => { return this.afterCalculateAndSavePayoffLetterSuccess(response); })
        , catchError((error: any) => { return this.afterCalculateAndSavePayoffLetterFailure(error); }));

  }

  private afterCalculateAndSavePayoffLetterSuccess(result: any): Observable<QuoteInformation> {
    let quoteInformation = new QuoteInformation();
    quoteInformation.accountNumber = result.contractualPayoffQuote.accountKey.accountNumber
    quoteInformation.payoffAmount = result.contractualPayoffQuote.mutable.grossPayoffAmount;
    quoteInformation.goodThroughDate = result.contractualPayoffQuote.mutable.goodThroughDate;
    quoteInformation.quoteDate = result.contractualPayoffQuote.mutable.quoteDate;
    quoteInformation.securityDeposit = result.contractualPayoffQuote.mutable.securityDeposit;
    quoteInformation.payoffLetterPdf = result.payoffLetterPdf;
    quoteInformation.interestPerDiem = result.contractualPayoffQuote.mutable.interest.perDiemAmount;
    if (quoteInformation.payoffLetterPdf === '' || quoteInformation.payoffLetterPdf == null) {
      quoteInformation.error = true;
    }
    return of(quoteInformation);
  }

  private afterCalculateAndSavePayoffLetterFailure(result: any): Observable<QuoteInformation> {
    this.fsTokenErrorHandler.handleFSTokenError(result);
    let quoteInformation = new QuoteInformation();
    let faultType;
    let faultDetail;
    if (result.faultType) {
      faultType = result.faultType;
    }
    else {
      quoteInformation.serviceError = true;
    }
    if (result.response) {
      let response = JSON.parse(result.response);
      if (response.FaultDetail !== undefined && response.FaultDetail.Message !== undefined) {
        faultDetail = response.FaultDetail.Message;
      }
    }
    if (faultType === FaultCodes.BMWFSAM_Services_AccountManagement_IneligibleToQuoteContractPayoffFault && faultDetail === FaultCodes.StopSaleFlagErrorMessage) {
      quoteInformation.stopSaleFlag = true;
    } else {
      quoteInformation.error = true;
    }
    return of(quoteInformation);
  }

}
