import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserInactivityDialog } from './../_components/dialogs/user-inactivity-dialog/user-inactivity-dialog.component';
import { ChangeContractDialog } from './../_components/dialogs/change-contract-dialog/change-contract-dialog.component';
import { DeletePaymentSource } from '../../feature/my-profile/_components/manage-payment-sources/_components/delete-payment-source/delete-payment-source.component';
import { CancelRecurringPayment } from '../../feature/recurring-payment/_components/recurring-payment-cancellation/recurring-payment-cancellation.component'
import { ToggleRecurringPayment } from '../../feature/recurring-payment/_components/recurring-payment-toggle/recurring-payment-toggle.component'
import { Store } from '@ngrx/store';
import { IAppState } from "./../../shared/store/app.store";
import { AddACHPaymentSourceComponent } from './../_components/dialogs/add-ach-payment-source/add-ach-payment-source.component';
import { DeleteMsgComponentDialog } from '../../feature/secure-message-center/_components/delete-msg/delete-msg-dialog.component';
import { DiscardMsgDialog } from '../../feature/secure-message-center/_components/discard-msg/discard-msg-dialog.component';
import { UserConsentDialog } from '../_components/dialogs/user-consent-dialog/user-consent-dialog.component';
import { ConfirmCancelDueDateChangeDialog } from '../../feature/due-date-change/_components/confirm-cancel-due-date-change-dialog/confirm-cancel-due-date-change-dialog.component';
import { NotificationDialog } from "../../feature/my-account/_components/notifications/_components/notification-dialog/notification-dialog.component";
import { EditAddressDialogComponent } from "../../feature/my-profile/_components/contact-information/_components/edit-address-dialog/edit-address-dialog.component";
import { TaxJurisdictionDialogComponent } from "../../feature/my-profile/_components/contact-information/_components/tax-jurisdiction-dialog/tax-jurisdiction-dialog.component";
import { CancelPendingOneTimePayment } from '../../feature/my-account/_components/payment-details/_components/pending-one-time-payments/cancel-pending-payment-dialog/cancel-pending-payment-dialog.component';
import { EditEmailDialog } from "../../feature/my-profile/_components/edit-email/edit-email-dialog.component";
import { EditPhoneNumbersDialogComponent } from "../../feature/my-profile/_components/contact-information/_components/edit-phone-numbers/edit-phone-numbers-dialog.component";
import { EditAddressModel } from "../../_models/edit-address.model";
import { DueDateChangeDialog } from "./../../feature/due-date-change/_components/due-date-change/due-date-change-dialog.component";
import { BulletinBoxDialog } from "./../../feature/dashboard/_components/bulletin-box/_components/bulletin-box-dialog/bulletin-box-dialog.component";
import { ContractAccountDetailDTO } from "./../../core/gateway-api";
import { MileageAdjustmentEntryDialog } from "./../../feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/mileage-adjustment-entry-dialog.component";
import { MileageAdjustmentPaymentCancelDialog } from "../../feature/mileage-adjustment/_components/shared/mileage-adjustment-payment-cancel-dialog/mileage-adjustment-payment-cancel.component";
import { LegalAgreementDialog } from '../_components/dialogs/legal-agreement-dialog/legal-agreement-dialog.component';
import { ExtendLeaseContractDialog } from '../_components/dialogs/extend-lease-contract-dialog/extend-lease-contract-dialog.component';
import { Observable } from 'rxjs';
import { HowToRedeemCreditPointsDialog } from '../_components/dialogs/how-to-redeem-credit-points-dialog/how-to-redeem-credit-points-dialog.component';
import { CommunicationPreferencesDialog } from '../_components/dialogs/communication-preferences-dialog/communication-preferences-dialog.component';
import { RecallDialog } from '../../feature/dashboard/_components/account-activity/_components/recall-dialog/recall-dialog.component';
import { ExtendLeaseContractCanDialog } from '../_components/dialogs/extend-lease-contract-canada-dialog/extend-lease-contract-can-dialog.component';
import { VehicleServiceDialog } from '../_components/dialogs/vehicle-service-dialog/vehicle-service-dialog.component';
import { EasyPayEnrollmentDialog } from '../_components/dialogs/easy-pay-enrollment-dialog/easy-pay-enrollment-dialog.component';
import { CustomerIntentDialog } from '../_components/dialogs/customer-intent-dialog/customer-intent-dialog.component';
import { OneTimePaymentAuthorizationDialog } from '../_components/dialogs/one-time-payment-authorization-dialog/one-time-payment-authorization-dialog.component';
import { ExtendLeaseReviewCanDialog } from '../_components/dialogs/extend-lease-review-canada-dialog/extend-lease-review-can-dialog.component';

@Injectable()
export class DialogService {
  public changeAccountDialogIsShown: boolean;
  public addAchDialogIsShown: boolean;
  private modalSubscription;
  public isModelOpened: boolean;

  idleDialogRef: MatDialogRef<UserInactivityDialog>;
  changeContractDialogRef: MatDialogRef<ChangeContractDialog>;
  addAchDialogRef: MatDialogRef<AddACHPaymentSourceComponent>;
  deletePaymentSourceDialogRef: MatDialogRef<DeletePaymentSource>;
  cancelRecurrigPaymentDialogRef: MatDialogRef<CancelRecurringPayment>;
  toggleRecurrigPaymentDialogRef: MatDialogRef<ToggleRecurringPayment>;
  deleteSMCThreadDialogRef: MatDialogRef<DeleteMsgComponentDialog>;
  discardMessageDialogRef: MatDialogRef<DiscardMsgDialog>;
  userConsentDialogRef: MatDialogRef<UserConsentDialog>;
  mileageAdjustmentPaymentCancelDialogRef: MatDialogRef<MileageAdjustmentPaymentCancelDialog>;
  confirmCancelDueDateChangeDialogRef: MatDialogRef<ConfirmCancelDueDateChangeDialog>;
  notificationDialogRef: MatDialogRef<NotificationDialog>;
  cancelPendingOneTimePaymentDialogRef: MatDialogRef<CancelPendingOneTimePayment>;
  editAddressDialogRef: MatDialogRef<EditAddressDialogComponent>;
  emailEditDialogRef: MatDialogRef<EditEmailDialog>;
  editPhoneNumbersDialogRef: MatDialogRef<EditPhoneNumbersDialogComponent>;
  taxJurisdictionDialogRef: MatDialogRef<TaxJurisdictionDialogComponent>;
  changeDueDateDialogRef: MatDialogRef<DueDateChangeDialog>;
  bulletinBoxDialogRef: MatDialogRef<BulletinBoxDialog>;
  mileageAdjustmentEntryDialogDialogRef: MatDialogRef<MileageAdjustmentEntryDialog>;
  legalAgreementDialogRef: MatDialogRef<LegalAgreementDialog>;
  extendLeaseDialogRef: MatDialogRef<ExtendLeaseContractDialog>;
  extendLeaseCanDialogRef: MatDialogRef<ExtendLeaseContractCanDialog>;
  howToRedeemCreditPointsDialogRef: MatDialogRef<HowToRedeemCreditPointsDialog>;
  communicationPreferenceDialogRef: MatDialogRef<CommunicationPreferencesDialog>;
  recallDialogRef: MatDialogRef<RecallDialog>;
  vehicleServiceDialogRef: MatDialogRef<VehicleServiceDialog>;
  easyPayEnrollmentDialogRef: MatDialogRef<EasyPayEnrollmentDialog>;
  customerIntentDialogRef: MatDialogRef<CustomerIntentDialog>;
  oneTimePaymentAuthorizationDialogRef: MatDialogRef<OneTimePaymentAuthorizationDialog>;
  extendLeaseReviewCanDialog: MatDialogRef<ExtendLeaseReviewCanDialog>;

  constructor(private dialog: MatDialog,
    private store: Store<IAppState>) { }

  public openIdleDialog(): void {
    this.closeDialogsBeforeTimeout();
    this.idleDialogRef = this.dialog.open(UserInactivityDialog);

  }

  public closeIdleDialog(): void {
    if (this.idleDialogRef) {
      this.idleDialogRef.close(true);
    }
  }

  public closeDialogsBeforeTimeout(): void {
    this.closeDiscardMessageDialog();
    this.closeContractChangeDialog();
    this.closeDeletePaymentSourceDialog();
    this.closeAddAchDialog();
    this.closeCancelPaymentDialog();
    this.closeDeleteSMCThreadsDialog();
    this.closeUserConsentDialog();
    this.closeNotificationDialog();
    this.closeEditAddressDialog();
    this.closeEditPhoneNumbersDialog();
    this.closeTaxJurisdictionDialog();
    this.closeEmailDialog();
    this.closeCancelPendingOneTimePaymentDialog();
    this.closeConfirmCancelDueDateChangeDialog();
    this.closeDueDateChangeDialog();
    this.closeBulletinBoxDialog();
    this.closeMileageAdjustmentEntryDialog();
    this.closeMileageAdjustmentPaymentCancelDialog();
    this.closeHowToRedeemCreditPointDialog();
    this.closeCommunicationPreferenceDialog();
    this.closeTogglePaymentDialog();
    this.closeEasyPayEnrollmentDialog();
    this.closeOneTimePaymentAuthDialog();
    this.closeExtendLeaseReviewCanDialog();
    //todo: add any new dialogs here
  }

  public openExtendLeaseDialog(data: any): MatDialogRef<ExtendLeaseContractDialog> {
    this.extendLeaseDialogRef = this.dialog.open(ExtendLeaseContractDialog, {
      panelClass: 'extend-lease-dialog', disableClose: true, data: data
    });
    return this.extendLeaseDialogRef;
  }

  public openExtendLeaseCanadaDialog(data: any): MatDialogRef<ExtendLeaseContractCanDialog> {
    this.extendLeaseCanDialogRef = this.dialog.open(ExtendLeaseContractCanDialog, {
      panelClass: 'extend-lease-dialog', disableClose: true, data: data
    });
    return this.extendLeaseCanDialogRef;
  }

  public openDiscardMessageDialog(): MatDialogRef<DiscardMsgDialog> {
    this.discardMessageDialogRef = this.dialog.open(DiscardMsgDialog);
    return this.discardMessageDialogRef;

  }

  public closeDiscardMessageDialog(): void {
    if (this.discardMessageDialogRef) {
      this.discardMessageDialogRef.close(true);
    }
  }

  public openContractChangeDialog(disableCurrent: boolean, contracts?: ContractAccountDetailDTO[], subtitle?: string, title?: string): MatDialogRef<ChangeContractDialog> {
    let data = { disableCurrent: disableCurrent, contracts: contracts, subtitle: subtitle, title: title };
    this.changeContractDialogRef = this.dialog.open(ChangeContractDialog, { panelClass: 'contract-change-dialog', data: data });
    this.changeAccountDialogIsShown = true;
    this.changeContractDialogRef.afterClosed().subscribe(result => {
      this.changeAccountDialogIsShown = false;

    });
    return this.changeContractDialogRef;
  }

  public closeContractChangeDialog(): void {
    if (this.changeContractDialogRef) {
      this.changeContractDialogRef.close(true);
    }
  }


  public openDeletePaymentSourceDialog(): void {
    this.deletePaymentSourceDialogRef = this.dialog.open(DeletePaymentSource);
  }

  public closeDeletePaymentSourceDialog(): void {
    if (this.deletePaymentSourceDialogRef) {
      this.deletePaymentSourceDialogRef.close(true);
    }
  }

  public openEditAddressDialog(data: any): MatDialogRef<EditAddressDialogComponent> {
    this.editAddressDialogRef = this.dialog.open(EditAddressDialogComponent, { data: data, disableClose: true });
    return this.editAddressDialogRef;
  }

  public closeEditAddressDialog(): void {
    if (this.editAddressDialogRef) {
      this.editAddressDialogRef.close(true);
    }
  }

  public openTaxJurisdictionDialog(editAddressModel: EditAddressModel): MatDialogRef<TaxJurisdictionDialogComponent> {
    let data = { editAddressModel: editAddressModel };
    this.taxJurisdictionDialogRef = this.dialog.open(TaxJurisdictionDialogComponent, { data: data, disableClose: true });
    return this.taxJurisdictionDialogRef;
  }

  public closeTaxJurisdictionDialog(): void {
    if (this.taxJurisdictionDialogRef) {
      this.taxJurisdictionDialogRef.close(true);
    }
  }

  public openEditPhoneNumbersDialog(data: any): MatDialogRef<EditPhoneNumbersDialogComponent> {
    this.editPhoneNumbersDialogRef = this.dialog.open(EditPhoneNumbersDialogComponent, { data: data, autoFocus: false });
    return this.editPhoneNumbersDialogRef;
  }

  public closeEditPhoneNumbersDialog(): void {
    if (this.editPhoneNumbersDialogRef) {
      this.editPhoneNumbersDialogRef.close(true);
    }
  }

  public openAddAchDialog(data: any): MatDialogRef<AddACHPaymentSourceComponent> {
    this.addAchDialogRef = this.dialog.open(AddACHPaymentSourceComponent, data);
    return this.addAchDialogRef;
  }

  public closeAddAchDialog(): void {
    if (this.addAchDialogRef) {
      this.addAchDialogRef.close(true);
    }
  }

  public openCancelPaymentDialog(): MatDialogRef<CancelRecurringPayment> {
    return this.cancelRecurrigPaymentDialogRef = this.dialog.open(CancelRecurringPayment);
  }

  public closeCancelPaymentDialog(): void {
    if (this.cancelRecurrigPaymentDialogRef) {
      this.cancelRecurrigPaymentDialogRef.close(true);
    }

  }

  public openTogglePaymentDialog(data: any): MatDialogRef<ToggleRecurringPayment> {
    this.toggleRecurrigPaymentDialogRef = this.dialog.open(ToggleRecurringPayment, { data: data, disableClose: true });
    return this.toggleRecurrigPaymentDialogRef;
  }

  public closeTogglePaymentDialog(): void {
    if (this.toggleRecurrigPaymentDialogRef) {
      this.toggleRecurrigPaymentDialogRef.close(true);
    }
  }

  public openCancelPendingOneTimePaymentDialog(data: any): MatDialogRef<CancelPendingOneTimePayment> {
    this.cancelPendingOneTimePaymentDialogRef = this.dialog.open(CancelPendingOneTimePayment, data);
    return this.cancelPendingOneTimePaymentDialogRef;
  }

  public closeCancelPendingOneTimePaymentDialog(): void {
    if (this.cancelPendingOneTimePaymentDialogRef) {
      this.cancelPendingOneTimePaymentDialogRef.close(true);
    }

  }

  public openDeleteSMCThreadsDialog(data: any): MatDialogRef<DeleteMsgComponentDialog> {
    this.deleteSMCThreadDialogRef = this.dialog.open(DeleteMsgComponentDialog, data);
    return this.deleteSMCThreadDialogRef;
  }

  public closeDeleteSMCThreadsDialog(): void {
    if (this.deleteSMCThreadDialogRef) {
      this.deleteSMCThreadDialogRef.close(true);
    }
  }

  public openUserConsentDialog(data: any): Observable<any> {
    this.userConsentDialogRef = this.dialog.open(UserConsentDialog, { data: data });
    return this.userConsentDialogRef.afterClosed();
  }

  public closeUserConsentDialog(): void {
    if (this.userConsentDialogRef) {
      this.userConsentDialogRef.close(true);
    }

  }

  public openConfirmCancelDueDateChangeDialog(): MatDialogRef<ConfirmCancelDueDateChangeDialog> {
    let data: any;
    this.confirmCancelDueDateChangeDialogRef = this.dialog.open(ConfirmCancelDueDateChangeDialog, data);
    return this.confirmCancelDueDateChangeDialogRef;

  }

  public closeConfirmCancelDueDateChangeDialog(): void {
    if (this.confirmCancelDueDateChangeDialogRef) {
      this.confirmCancelDueDateChangeDialogRef.close(true);
    }

  }

  public openNotificationDialog(notification: any): MatDialogRef<NotificationDialog> {
    this.notificationDialogRef = this.dialog.open(NotificationDialog, { data: notification, disableClose: true });
    return this.notificationDialogRef;

  }
  public closeNotificationDialog(): void {
    if (this.notificationDialogRef) {
      this.notificationDialogRef.close(null);
    }

  }


  public openEmailEditDialog(): void {
    this.emailEditDialogRef = this.dialog.open(EditEmailDialog);
  }

  public closeEmailDialog(): void {
    if (this.emailEditDialogRef) {
      this.emailEditDialogRef.close(true);
    }
  }

  public openDueDateChangeDialog(): MatDialogRef<DueDateChangeDialog> {
    this.changeDueDateDialogRef = this.dialog.open(DueDateChangeDialog, { disableClose: true });
    return this.changeDueDateDialogRef;
  }
  public closeDueDateChangeDialog(): void {
    if (this.changeDueDateDialogRef) {
      this.changeDueDateDialogRef.close(null);
    }
  }

  public openBulletinBoxDialog(bulletinMessage: string): void {
    this.bulletinBoxDialogRef = this.dialog.open(BulletinBoxDialog, { data: bulletinMessage, disableClose: true });
  }
  public closeBulletinBoxDialog(): void {
    if (this.bulletinBoxDialogRef) {
      this.bulletinBoxDialogRef.close(null);
    }
  }

  public openMileageAdjustmentEntryDialog(): MatDialogRef<MileageAdjustmentEntryDialog> {
    this.mileageAdjustmentEntryDialogDialogRef = this.dialog.open(MileageAdjustmentEntryDialog, {
      panelClass: 'mileage-adjustment-dialog',
      disableClose: true
    });
    this.isModelOpened = true;
    return this.mileageAdjustmentEntryDialogDialogRef;
  }
  public closeMileageAdjustmentEntryDialog(updateData?: boolean): void {
    if (this.mileageAdjustmentEntryDialogDialogRef) {
      this.mileageAdjustmentEntryDialogDialogRef.close(updateData);
    }
    this.isModelOpened = false;
  }

  public openMileageAdjustmentPaymentCancelDialog(isMonthlyPayment: boolean): MatDialogRef<MileageAdjustmentPaymentCancelDialog> {
    this.mileageAdjustmentPaymentCancelDialogRef = this.dialog.open(MileageAdjustmentPaymentCancelDialog, {
      panelClass: 'mileage-adjustment-dialog',
      disableClose: true,
      data: { isMonthlyPayment: isMonthlyPayment }
    });
    return this.mileageAdjustmentPaymentCancelDialogRef;
  }
  public closeMileageAdjustmentPaymentCancelDialog(): void {
    if (this.mileageAdjustmentPaymentCancelDialogRef) {
      this.mileageAdjustmentPaymentCancelDialogRef.close();
    }
  }

  public openLegalAgreementDialog(): MatDialogRef<LegalAgreementDialog> {
    this.legalAgreementDialogRef = this.dialog.open(LegalAgreementDialog, {
      disableClose: true,
    });
    return this.legalAgreementDialogRef;
  }
  public closeLegalAgreementDialog(): void {
    if (this.legalAgreementDialogRef) {
      this.legalAgreementDialogRef.close();
    }
  }

  public openHowToRedeemCreditPointDialog(): void {
    this.howToRedeemCreditPointsDialogRef = this.dialog.open(HowToRedeemCreditPointsDialog);
  }

  public closeHowToRedeemCreditPointDialog(): void {
    if (this.howToRedeemCreditPointsDialogRef) {
      this.howToRedeemCreditPointsDialogRef.close(true);
    }
  }

  public openCommunicationPreferenceDialog(): MatDialogRef<CommunicationPreferencesDialog> {
    this.communicationPreferenceDialogRef = this.dialog.open(CommunicationPreferencesDialog);
    return this.communicationPreferenceDialogRef;
  }
  public closeCommunicationPreferenceDialog(): void {
    if (this.communicationPreferenceDialogRef) {
      this.communicationPreferenceDialogRef.close(true);
    }
  }


  public openRecallDialog(data: any): MatDialogRef<RecallDialog> {
    this.recallDialogRef = this.dialog.open(RecallDialog, data);
    return this.recallDialogRef;
  }

  public openVehicleServiceDialog(data: any): MatDialogRef<VehicleServiceDialog> {
    this.vehicleServiceDialogRef = this.dialog.open(VehicleServiceDialog, data);
    return this.vehicleServiceDialogRef;
  }

  public openEasyPayEnrollmentDialog(data: any): MatDialogRef<EasyPayEnrollmentDialog> {
    this.easyPayEnrollmentDialogRef = this.dialog.open(EasyPayEnrollmentDialog, { data: data, disableClose: true });
    return this.easyPayEnrollmentDialogRef;
  }

  public closeEasyPayEnrollmentDialog(): void {
    if (this.easyPayEnrollmentDialogRef) {
      this.easyPayEnrollmentDialogRef.close(true);
    }
  }

  public openCustomerIntentDialog(data: any): MatDialogRef<CustomerIntentDialog> {
    this.customerIntentDialogRef = this.dialog.open(CustomerIntentDialog, { data: data, disableClose: true });
    return this.customerIntentDialogRef;
  }

  public closeCustomerIntentDialog(): void {
    if (this.customerIntentDialogRef) {
      this.customerIntentDialogRef.close(true);
    }
  }

  public openOneTimePaymentAuthDialog(): MatDialogRef<OneTimePaymentAuthorizationDialog> {
    this.oneTimePaymentAuthorizationDialogRef = this.dialog.open(OneTimePaymentAuthorizationDialog, { disableClose: true });
    return this.oneTimePaymentAuthorizationDialogRef;
  }

  public closeOneTimePaymentAuthDialog(): void {
    if (this.oneTimePaymentAuthorizationDialogRef) {
      this.oneTimePaymentAuthorizationDialogRef.close(null);
    }
  }

  public openExtendLeaseReviewCanDialog(): MatDialogRef<ExtendLeaseReviewCanDialog> {
    this.extendLeaseReviewCanDialog = this.dialog.open(ExtendLeaseReviewCanDialog, { disableClose: true });
    return this.extendLeaseReviewCanDialog;
  }

  public closeExtendLeaseReviewCanDialog(): void {
    if (this.extendLeaseReviewCanDialog) {
      this.extendLeaseReviewCanDialog.close(null);
    }
  }
}
