import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../../../_shared/shared/_helper-services/router.service';

@Component({
  selector: 'app-path-not-found-bmw-can',
  templateUrl: './path-not-found-bmw-can.component.html',
  styleUrls: ['./path-not-found-bmw-can.component.scss']
})
export class PathNotFoundBmwCanComponent implements OnInit {

  constructor(private routerService: RouterService) { }

  ngOnInit() {
  }

  navigateToBaseURL(): void {
    this.routerService.navigateToHome();
  }

}
