export class CustomerDevice {
    error: boolean;
    faultType: string;
    errorResponse: string;
    responseCode: string;
}




