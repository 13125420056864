import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { VehicleShadowDTO } from '../../../core/gateway-api';


@Injectable()
export class VehicleShadowDataActions {
  static PUSH_VEHICLE_SHADOW_DATA: string = 'PUSH_VEHICLE_SHADOW_DATA';
  static REMOVE_VEHICLE_SHADOW_DATA: string = 'REMOVE_VEHICLE_SHADOW_DATA';
  static CLEAR_VEHICLE_SHADOW_DATA: string = 'CLEAR_VEHICLE_SHADOW_DATA';

  pushVehicleShadowData(vehicleShadowData: VehicleShadowDTO): FSA<string, any, null> {
    return {
      type: VehicleShadowDataActions.PUSH_VEHICLE_SHADOW_DATA,
      payload: vehicleShadowData,
      meta: null
    };
  }

  removeVehicleShadowData(vin: string): FSA<string, any, null> {
    return {
      type: VehicleShadowDataActions.REMOVE_VEHICLE_SHADOW_DATA,
      payload: vin,
      meta: null
    };
  }
  
  clearVehicleShadowData(): FSA<string, any, null> {
    return {
      type: VehicleShadowDataActions.CLEAR_VEHICLE_SHADOW_DATA,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class VehicleShadowDataActionsReducers {
  static VehicleShadowDataActionsReducers: Reducer<VehicleShadowDTO[]> = (state: VehicleShadowDTO[] = [], action: FSA<string, any, void>) => {
    switch (action.type) {
      case VehicleShadowDataActions.PUSH_VEHICLE_SHADOW_DATA:
        return state = [...state, action.payload];
      case VehicleShadowDataActions.REMOVE_VEHICLE_SHADOW_DATA:
        return state = state ? state.filter(vehicleShadowData => vehicleShadowData.vin !== action.payload) : [];
      case VehicleShadowDataActions.CLEAR_VEHICLE_SHADOW_DATA:
        return state = [];
      default:
        return state;
    }
  }
}
