import { Injector } from "@angular/core";
import { AnalyticsIdOverride } from "../../_models/analytics/analytics-id-override-factory.service";
import { BridgeHelperService } from "./bridge-helper.service";
import { Store } from '@ngrx/store';
import { IAppState } from "../store/app.store";
import { StorageHelperService } from "./storage-helper.service";

export class McidCookieFromBridgeServiceEcid implements AnalyticsIdOverride {

  private bridgeService: BridgeHelperService;
  private storage: StorageHelperService;

  constructor(
    injector: Injector,
    private store: Store<IAppState>
  ) {
    this.bridgeService = injector.get<BridgeHelperService>(BridgeHelperService);
    this.storage = injector.get<StorageHelperService>(StorageHelperService);
  }

  public setOverrideId(): void { }
}