import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../../shared/constants';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { RecurringPaymentCancel } from '../../../../_models/recurring-payment/recurring-payment-cancel-model';
import { CancelRecurringPaymentReviewActions } from '../../../../shared/store/reducers/recurring-payment-cancel.reducer';
import { CustomerACHService } from "../../../../_web-services/customer-ach.service";
import { LogSiteActivityService } from '../../../../_web-services/log-site-activity.service';
import { ActivityTypes, AnalyticsEvent } from '../../../../shared/enums';
import { PaymentHelperService } from '../../../../shared/_helper-services/payment-helper.service';
import { AutopaysActions } from '../../../../shared/store/reducers/autopays.reducers';
import { RecurringPaymentHoldsActions } from '../../../../shared/store/reducers/recurring-payment-holds.reducer';
import { UserService } from '../../../../shared/_helper-services/user.service';

@Component({
    selector: 'cancel-recurring-payment',
    templateUrl: './recurring-payment-cancellation.component.html',
    styleUrls: ['./recurring-payment-cancellation.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CancelRecurringPayment extends BaseApplicationComponent<CancelRecurringPayment> {
    subtitle: string;
    recurringPaymentCancel: RecurringPaymentCancel;
    errorMessage: string;
    cancelRecurringPaymentError: boolean;
    accountNumber: string;
    paymentAmount: string;

    constructor(
        private translateService: TranslateService,
        private customerACHService: CustomerACHService,
        private cancelRecurringPaymentReviewActions: CancelRecurringPaymentReviewActions,
        private autopaysActions: AutopaysActions,
        private logSiteActivityService: LogSiteActivityService,
        private paymentHelperService: PaymentHelperService,
        private userService: UserService,
        private recurringPaymentHoldsActions: RecurringPaymentHoldsActions,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CancelRecurringPayment>, @Inject(DOCUMENT) document,
        injector: Injector
    ) {
        super(injector, document);
        this.cancelRecurringPaymentError = false;
        this.errorMessage = "";
    }

    ngOnInit() {
        this.dataLayer = {
            myAccount: {
                siteTool: Constants.CancelRecurringPaymentComponentName,
                siteToolStep: Constants.SiteToolStart
            }
        }
        super.pushDataLayer({ eventName: AnalyticsEvent.SITE_TOOL });
        this.store.select(state => state.RecurringPaymentCancel).subscribe(x => this.recurringPaymentCancel = x);
        this.translateService.get('ngw.payment.cancel.read-txt', { nextScheduledPaymentDate: this.recurringPaymentCancel.schedulePaymentDate }).subscribe((res: string) => {
            this.subtitle = res;
        });
        this.accountNumber = this.recurringPaymentCancel.accountNumber;
        this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.recurringPaymentCancel.accountNumber, ActivityTypes.CancelRecurringPayment);
        this.paymentAmount = this.paymentHelperService.getRecurringPaymentCancelationPaymentAmount(this.recurringPaymentCancel);
    }

    private closeDialog(isRecurringPaymentEnrolled): void {
        this.dialogRef.close({ data: isRecurringPaymentEnrolled });
    }

    public cancelRecurringPayment() {
        super.setDataLoadedOnRequest(false);
        this.customerACHService.cancelRecurringPaymentSchedule(this.recurringPaymentCancel.accountNumber)
            .subscribe(response => { this.postCancelRecurringPayment(response); });
    }

    private postCancelRecurringPayment(response: RecurringPaymentCancel) {
        if (response.error) {
            this.cancelRecurringPaymentError = true;
            const errorTranslationKey = "ngw.global.technical-error";
            this.translateService.get(errorTranslationKey).subscribe((res: string) => {
                this.errorMessage = res;
            });
            this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
        }
        else {
            this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.recurringPaymentCancel.accountNumber, ActivityTypes.CancelRecurringPaymentComplete);
            this.store.dispatch(this.recurringPaymentHoldsActions.removeRecurringPaymentHolds(this.recurringPaymentCancel.accountNumber));
            this.store.dispatch(this.cancelRecurringPaymentReviewActions.clearCancelRecurringPayment());
            this.store.dispatch(this.autopaysActions.removeAutopay(this.recurringPaymentCancel.accountNumber));
            this.analytics.pushSiteToolEvent({ siteTool: Constants.CancelRecurringPaymentComponentName, siteToolStep: Constants.SiteToolComplete });
            this.closeDialog(false);
        }
        super.setDataLoadedOnRequest(true);
    }

    public keepRecurringPayment() {
        this.store.dispatch(this.cancelRecurringPaymentReviewActions.clearCancelRecurringPayment());
        this.pushDataLayer({ dataLayer: { myAccount: { siteTool: null, siteToolStep: null } } });
        this.closeDialog(true)
    }

}
