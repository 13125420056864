import { Injectable } from '@angular/core';
import {
  EmailAddressDTOEmailAddressType,
  EmailAddressDTO,
  ServicingPreferenceDTO,
  ServicingPreferenceDTOPreferenceCode,
  ContractAccountDetailDTOCustomerRoleType,
  ServicingPreferenceDTOPreferenceStatus,
  ContactDTO
} from '../../core/gateway-api';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { IAppState } from "../../shared/store/app.store";
import { TranslateService } from '@ngx-translate/core';
import { NotificationModel } from '../../_models/notification.model';
import { Constants } from '../constants';

@Injectable()
export class NotificationsHelperService {
  private accountLbl: string;
  private vehicleLbl: string;
  private accountCaption: string;
  private vehicleCaption: string;
  private msgCreditCardSbj: string;

  constructor(
    private store: Store<IAppState>,
    private translateService: TranslateService) { }

  public getNotificationsForBillingPreference(preferences: ServicingPreferenceDTO[]): NotificationModel[] {
    let notifications: NotificationModel[] = new Array();
    //paperless
    let paperlessPreference: ServicingPreferenceDTO = _.find(preferences, function (pref: ServicingPreferenceDTO) {
      return pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaperlessInvoicing;
    });
    let isBillingCustomer: boolean = this.isBillingCustomer(paperlessPreference);
    //let isAccountHolder: boolean = this.isAccountHolder(customerRoleType);
    let primaryEmailAddress: string = this.getCustomerPrimaryEmailAddress();
    let secondaryEmailAddress: string = this.getCustomerSecondaryEmailAddress(primaryEmailAddress);
    if (paperlessPreference) {
      let paperlessNotification = this.getPaperlessNotification(paperlessPreference, isBillingCustomer, primaryEmailAddress, secondaryEmailAddress);
      notifications.push(paperlessNotification);
    }

    return notifications;
  }

  public getNotificationsForCurrentContract(preferences: ServicingPreferenceDTO[],
    customerRoleType: ContractAccountDetailDTOCustomerRoleType, isEnrolledInAutomaticPayments: boolean): NotificationModel[] {
    let notifications: NotificationModel[] = new Array();
    //paperless
    let paperlessPreference: ServicingPreferenceDTO = _.find(preferences, function (pref: ServicingPreferenceDTO) {
      return pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaperlessInvoicing;
    });
    let isBillingCustomer: boolean = this.isBillingCustomer(paperlessPreference);
    let isAccountHolder: boolean = this.isAccountHolder(customerRoleType);
    let primaryEmailAddress: string = this.getCustomerPrimaryEmailAddress();
    let secondaryEmailAddress: string = this.getCustomerSecondaryEmailAddress(primaryEmailAddress);
    //payment confirmation
    let paymentConfirmationPreference: ServicingPreferenceDTO = _.find(preferences, function (pref: ServicingPreferenceDTO) {
      return pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaymentConfirmation;
    });
    if (paymentConfirmationPreference) {
      let paymentConfirmationNotification = this.getPaymentConfirmationNotification(paymentConfirmationPreference, isAccountHolder, primaryEmailAddress, secondaryEmailAddress);
      notifications.push(paymentConfirmationNotification);
    }
    //payment reminder
    let paymentReminderPreference: ServicingPreferenceDTO = _.find(preferences, function (pref: ServicingPreferenceDTO) {
      return pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaymentReminder;
    });
    if (paymentReminderPreference) {
      let paymentReminderNotification = this.getPaymentReminderNotification(paymentReminderPreference, isEnrolledInAutomaticPayments, primaryEmailAddress, secondaryEmailAddress);
      notifications.push(paymentReminderNotification);
    }
    //statement
    let statementPreference: ServicingPreferenceDTO = _.find(preferences, function (pref: ServicingPreferenceDTO) {
      return pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.StatementNotification;
    });
    if (statementPreference && paperlessPreference) {
      let statementNotification = this.getStatementNotification(statementPreference, paperlessPreference, isBillingCustomer, primaryEmailAddress, secondaryEmailAddress);
      notifications.push(statementNotification);
    }

    return notifications.filter(function (n) {
      return n.isVisible
    });
  }

  private getPaperlessNotification(preference: ServicingPreferenceDTO, isBillingCustomer: boolean, primaryEmail: string, secondaryEmail: string): NotificationModel {
    if (preference) {
      let isActive = isBillingCustomer;
      let isSelected = preference.preferenceStatus == ServicingPreferenceDTOPreferenceStatus.On;
      let paperlessNotification = new NotificationModel(
        this.translateService,
        'ngw.my-account.notifications.lbl-paperless',
        true,
        isSelected,
        isActive,
        'ngw.my-account.notifications.paperless.dialog-title',
        'ngw.my-account.notifications.paperless.dialog-sub-title',
        'ngw.my-account.notifications.paperless.dialog-message-activate',
        'ngw.my-account.notifications.paperless.dialog-message-deactivate',
        primaryEmail,
        secondaryEmail,
        true,
        'ngw.my-account.notifications.paperless.option-primary',
        'ngw.my-account.notifications.paperless.option-secondary',
        null,
        null,
        preference,
        'ngw.my-account.notifications.paperless.btn-cancel-activate',
        'ngw.my-account.notifications.paperless.btn-submit-activate',
        'ngw.my-account.notifications.paperless.btn-cancel-deactivate',
        'ngw.my-account.notifications.paperless.btn-submit-deactivate'
      );
      return paperlessNotification;
    }
    return null;
  }

  private getPaymentConfirmationNotification(preference: ServicingPreferenceDTO, isAccountHolder: boolean, primaryEmail: string, secondaryEmail: string): NotificationModel {
    if (preference) {
      let isVisible = isAccountHolder;
      let isSelected = preference.preferenceStatus == ServicingPreferenceDTOPreferenceStatus.On;
      let paymentConfirmationNotification = new NotificationModel(
        this.translateService,
        'ngw.my-account.notifications.lbl-payment-confirmation',
        isVisible,
        isSelected,
        true,
        'ngw.my-account.notifications.payment-confirmation.dialog-title',
        'ngw.my-account.notifications.payment-confirmation.dialog-sub-title',
        'ngw.my-account.notifications.payment-confirmation.dialog-message-activate',
        'ngw.my-account.notifications.payment-confirmation.dialog-message-deactivate',
        primaryEmail,
        secondaryEmail,
        true,
        null,
        null,
        null,
        null,
        preference,
        'ngw.my-account.notifications.payment-confirmation.btn-cancel-activate',
        'ngw.my-account.notifications.payment-confirmation.btn-submit-activate',
        'ngw.my-account.notifications.payment-confirmation.btn-cancel-deactivate',
        'ngw.my-account.notifications.payment-confirmation.btn-submit-deactivate'
      );
      return paymentConfirmationNotification;
    }
    return null;
  }

  private getPaymentReminderNotification(preference: ServicingPreferenceDTO, isEnrolledInAutomaticPayments: boolean, primaryEmail: string, secondaryEmail: string): NotificationModel {
    if (preference) {
      let isVisible = !isEnrolledInAutomaticPayments;
      let isSelected = preference.preferenceStatus == ServicingPreferenceDTOPreferenceStatus.On;
      let dialogValue = preference.preferenceValue
        ? !isNaN(Number(preference.preferenceValue))
          ? Number(preference.preferenceValue)
          : Constants.DefaultDaysForPaymentReminder
        : Constants.DefaultDaysForPaymentReminder;
      let paymentReminderNotification = new NotificationModel(
        this.translateService,
        'ngw.my-account.notifications.lbl-payment-reminder',
        isVisible,
        isSelected,
        true,
        'ngw.my-account.notifications.payment-reminder.dialog-title',
        'ngw.my-account.notifications.payment-reminder.dialog-sub-title',
        'ngw.my-account.notifications.payment-reminder.dialog-message-activate',
        'ngw.my-account.notifications.payment-reminder.dialog-message-deactivate',
        primaryEmail,
        secondaryEmail,
        true,
        'ngw.my-account.notifications.payment-reminder.option-primary',
        'ngw.my-account.notifications.payment-reminder.option-secondary',
        null,
        dialogValue,
        preference,
        'ngw.my-account.notifications.payment-reminder.btn-cancel-activate',
        'ngw.my-account.notifications.payment-reminder.btn-submit-activate',
        'ngw.my-account.notifications.payment-reminder.btn-cancel-deactivate',
        'ngw.my-account.notifications.payment-reminder.btn-submit-deactivate'
      );
      return paymentReminderNotification;
    }
    return null;
  }

  private getStatementNotification(preference: ServicingPreferenceDTO, paperlessPreference: ServicingPreferenceDTO, isBillingCustomer: boolean, primaryEmail: string, secondaryEmail: string): NotificationModel {
    if (preference) {
      let isVisible = !(isBillingCustomer && paperlessPreference.preferenceStatus == ServicingPreferenceDTOPreferenceStatus.On);
      let isSelected = preference.preferenceStatus == ServicingPreferenceDTOPreferenceStatus.On;
      let statementNotification = new NotificationModel(
        this.translateService,
        "ngw.my-account.notifications.lbl-statement",
        isVisible,
        isSelected,
        true,
        "ngw.my-account.notifications.statement.dialog-title",
        "ngw.my-account.notifications.statement.dialog-sub-title",
        "ngw.my-account.notifications.statement.dialog-message-activate",
        "ngw.my-account.notifications.statement.dialog-message-deactivate",
        primaryEmail,
        secondaryEmail,
        true,
        'ngw.my-account.notifications.statement.option-primary',
        'ngw.my-account.notifications.statement.option-secondary',
        null,
        null,
        preference,
        'ngw.my-account.notifications.statement.btn-cancel-activate',
        'ngw.my-account.notifications.statement.btn-submit-activate',
        'ngw.my-account.notifications.statement.btn-cancel-deactivate',
        'ngw.my-account.notifications.statement.btn-submit-deactivate'
      );
      return statementNotification;
    }
    return null;
  }

  public getPaperlessPreference(preferences?: ServicingPreferenceDTO[], accountNumber?: string): ServicingPreferenceDTO {
    if (!preferences) {
      let preference: ServicingPreferenceDTO[];
      this.store.select(state => state.Preferences).subscribe(x => preference = x);
      preferences = _.values(preference);
    }
    let ppref: ServicingPreferenceDTO = _.find(preferences, function (pref: ServicingPreferenceDTO) {
      return accountNumber
        ? pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaperlessInvoicing && pref.accountNumber == accountNumber
        : pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaperlessInvoicing;
    });
    return ppref;
  }

  public isPaperlessEnabled(preferences?: ServicingPreferenceDTO[]): boolean {
    let ppref: ServicingPreferenceDTO = this.getPaperlessPreference(preferences);
    return (ppref && ppref.preferenceStatus == ServicingPreferenceDTOPreferenceStatus.On);
  }

  public isBillingCustomer(paperlessPreference?: ServicingPreferenceDTO, preferences?: ServicingPreferenceDTO[]): boolean {
    let ppref: ServicingPreferenceDTO = paperlessPreference;
    if (!ppref) {
      ppref = _.find(preferences, function (pref: ServicingPreferenceDTO) {
        return pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaperlessInvoicing;
      });
    }
    return ppref && ppref.isPreferenceEditable;
  }

  public isAccountHolder(customerRoleType: ContractAccountDetailDTOCustomerRoleType): boolean {
    return customerRoleType == ContractAccountDetailDTOCustomerRoleType.Primary;
  }

  public getCustomerPrimaryEmailAddress(): string {
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    if (contact != null) {
      let primaryEmailAddress = _.find(contact.emailAddresses, function (emailAddress: EmailAddressDTO) {
        return emailAddress.emailAddressType == EmailAddressDTOEmailAddressType.Billing;
      })
      return primaryEmailAddress ? primaryEmailAddress.emailAddress : Constants.EMPTY;
    }
  }

  public getCustomerSecondaryEmailAddress(primaryEmailAddress: string): string {
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    if (contact != null) {
      let secondaryEmailAddress = _.find(contact.emailAddresses, function (emailAddress: EmailAddressDTO) {
        return emailAddress.emailAddress.toLowerCase() !== primaryEmailAddress.toLowerCase() && emailAddress.emailAddress != null;
      })
      return secondaryEmailAddress ? secondaryEmailAddress.emailAddress : Constants.EMPTY;
    }
  }

}

