import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { MaturityBillingModel } from '../../../_models/contract/maturity-billing.model';

@Injectable()
export class MaturityBillingActions {
  static PUSH_MATURITY_BILLING: string = 'PUSH_MATURITY_BILLING';
  static REMOVE_MATURITY_BILLING: string = 'REMOVE_MATURITY_BILLING';
  static CLEAR_MATURITY_BILLING: string = 'CLEAR_MATURITY_BILLING';

  pushMaturityBilling(maturityBilling: MaturityBillingModel): FSA<string, any, null> {
    return {
      type: MaturityBillingActions.PUSH_MATURITY_BILLING,
      payload: maturityBilling,
      meta: null
    };
  }

  removeMaturityBilling(accountNumber: string): FSA<string, any, null> {
    return {
      type: MaturityBillingActions.REMOVE_MATURITY_BILLING,
      payload: accountNumber,
      meta: null
    };
  }

  clearMaturityBilling(): FSA<string, any, null> {
    return {
      type: MaturityBillingActions.CLEAR_MATURITY_BILLING,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class MaturityBillingReducers {
  static MaturityBillingReducer: Reducer<MaturityBillingModel[]> = (state: MaturityBillingModel[] = [], action: FSA<string, any, void>) => {
    switch (action.type) {
      case MaturityBillingActions.PUSH_MATURITY_BILLING:
        return state = [...state, action.payload];
      case MaturityBillingActions.REMOVE_MATURITY_BILLING:
        return state = state ? state.filter(maturityBilling => maturityBilling.accountNumber !== action.payload) : [];
      case MaturityBillingActions.CLEAR_MATURITY_BILLING:
        return state = [];
      default:
        return state;
    }
  }
}
