import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { forkJoin, map } from 'rxjs';
import { Payoff } from '../_models/my-account/payoff.model';
import { CashAccountViewService } from "../_web-services/cash-account-view.service";
import { ContractualPayoffService } from '../_web-services/contractual-payoff.service';
import { CustomerACHService } from '../_web-services/customer-ach.service';
import { MaturityBillingService } from '../_web-services/maturity-billing.service';
import { ContractAccountDetailDTO } from "../core/gateway-api";
import { AccountInfoService } from "../shared/_helper-services/account-info.service";
import { UserService } from "../shared/_helper-services/user.service";
import { ActiveContractAccountDetailsActions } from "../shared/store/reducers/active-contract-account-details.reducers";
import { ContractsLoadingErrorActions } from "../shared/store/reducers/contracts-loading-error.reducer";
import { DataLoadedActions } from "../shared/store/reducers/dataloaded.reducer";
import { EasypaySchedulesActions } from "../shared/store/reducers/easypay-schedules.reducers";
import { SortedActiveContractAccountDetailsActions } from "../shared/store/reducers/sorted-active-contract-account-details.reducers";
import { SortedTerminatedContractAccountDetailsActions } from "../shared/store/reducers/sorted-terminated-contract-account-details.reducers";
import { ContactUpcoming } from './../_models/contract/contact-upcoming.model';
import { RecurringPaymentEntry } from './../_models/recurring-payment/recurring-payment-entry.model';
import { IAppState } from "./../shared/store/app.store";
import { PartnerContactService } from '../_web-services/partner-contact.service';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { FilterService } from '../shared/_helper-services/filter.service';
import { ContactActions } from '../shared/store/reducers/contact.reducers';

export const SortedFilteredContractsResolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const userService = inject(UserService);

  if(userService.isAuthenticated()){
    const dataLoadedActions = inject(DataLoadedActions);
    const store = inject(Store<IAppState>);
    const cashAccountViewService = inject(CashAccountViewService);
    const customerACHService = inject(CustomerACHService);
    const contractualPayoffService = inject(ContractualPayoffService);
    const maturityBillingService = inject(MaturityBillingService);
    const contractsLoadingErrorActions = inject(ContractsLoadingErrorActions);
    const activeContractAccountDetailsActions = inject(ActiveContractAccountDetailsActions);
    const activeSortedContractsActions = inject(SortedActiveContractAccountDetailsActions);
    const easypaySchedulesActions = inject(EasypaySchedulesActions);
    const terminatedSortedContractsActions = inject(SortedTerminatedContractAccountDetailsActions);
    const accountInfoService = inject(AccountInfoService);
    const partnerContactService = inject(PartnerContactService);
    const fsTokenErrorHandler = inject(FSTokenErrorHandler);
    const filterService = inject(FilterService);
    const contactActions = inject(ContactActions);

    let contracts: ContractAccountDetailDTO[];
    let sortedActiveContracts: ContractAccountDetailDTO[];
    let sortedTerminatedContracts: ContractAccountDetailDTO[];
  
    let postGetCalculatePayoffAndUPays = (responses: any[]) => {
      let upcomingUpayResponses: any[] = [];
      let payoffResponses: any[] = [];
      let findEasyPayScheduleResponses: any[] = [];
      _.each(responses, function (response: any) {
        if (response instanceof ContactUpcoming) {
          upcomingUpayResponses.push(response);
        }
        else if (response instanceof Payoff) {
          payoffResponses.push(response);
        }
        else if (response instanceof RecurringPaymentEntry) {
          findEasyPayScheduleResponses.push(response);
        }
      })
      postFindEasyPaySchedule(findEasyPayScheduleResponses);
      promiseChanged(true);
    }
  
    let postFindUpcomingUpays = (responses: any[]) => {
  
    }
  
    let postFindEasyPaySchedule = (responses: any[]) => {
      store.dispatch(easypaySchedulesActions.clearEasypaySchedules());
      responses.forEach((response) => {
        store.dispatch(easypaySchedulesActions.pushEasypaySchedule(response));
      });
    }
  
    let groupAndFilterActiveContracts = (contracts: ContractAccountDetailDTO[]) => {
      sortedActiveContracts = accountInfoService.getSortedActiveContractsForHomePage(contracts);
      store.dispatch(activeSortedContractsActions.clearSortedActiveContracts());
      store.dispatch(activeSortedContractsActions.setSortedActiveContracts(sortedActiveContracts));
      enableChatbotAfterContractsAreLoaded();
    }
  
    let sortTerminatedContracts = (terminatedContracts: ContractAccountDetailDTO[]) => {
      //let self! = this;
      let leaseAccounts = _.filter(terminatedContracts, function (contract) {
        return contract && accountInfoService.isLeaseAccount(contract.portfolioCategoryCode);
      });
      let loanAccounts = _.filter(terminatedContracts, function (contract) {
        return contract && !accountInfoService.isLeaseAccount(contract.portfolioCategoryCode);
      });
      let promisesList = new Array();
      if (leaseAccounts.length > 0) {
        _.each(leaseAccounts, function (lease) {
          promisesList.push(maturityBillingService.getMaturityBilling(lease.accountNumber, false, lease));
        })
        forkJoin(promisesList).subscribe(responses => { postGetMaturityBillings(responses, leaseAccounts, loanAccounts); });
      }
      else {
        postGetMaturityBillings(null, leaseAccounts, loanAccounts);
      }
    }
  
    let postGetMaturityBillings = (responses?: any, leaseAccounts?: ContractAccountDetailDTO[], loanAccounts?: ContractAccountDetailDTO[]) => {
      let maturityBills = new Array<ContractAccountDetailDTO>();
      if (responses) {
        for (let response of responses) {
          let maturityBill = _.remove(leaseAccounts, function (leaseAccount) {
            return leaseAccount && leaseAccount.accountNumber == response.accountNumber;
          });
          maturityBills.push(maturityBill[0]);
        }
      }
  
      let negativeMaturityBills = _.filter(maturityBills, function (maturityBill) {
        return maturityBill && maturityBill.pastDueAmount < 0;
      });
      let zeroMaturityBills = _.filter(maturityBills, function (maturityBill) {
        return maturityBill && maturityBill.pastDueAmount == 0;
      });
      let positiveMaturityBills = _.filter(maturityBills, function (maturityBill) {
        return maturityBill && maturityBill.pastDueAmount > 0;
      });
      let orderedLeaseContracts = _.orderBy(leaseAccounts, ['year', 'model'], ['desc', 'asc']);
      let orderedLoanContracts = _.orderBy(loanAccounts, ['year', 'model'], ['desc', 'asc']);
      sortedTerminatedContracts = positiveMaturityBills.concat(zeroMaturityBills, negativeMaturityBills, orderedLeaseContracts, orderedLoanContracts);
      store.dispatch(terminatedSortedContractsActions.clearSortedTerminatedContracts());
      store.dispatch(terminatedSortedContractsActions.setSortedTerminatedContracts(sortedTerminatedContracts));
      enableChatbotAfterContractsAreLoaded();
    }
  
    let promiseChanged = (finished: boolean) => {
      store.dispatch(dataLoadedActions.setDataLoaded(finished));
    }
  
    let setError = (error: boolean) => {
      store.dispatch(contractsLoadingErrorActions.setContractsLoadingError(error));
    }
  
    let enableChatbotAfterContractsAreLoaded = () => {
      userService.setChatBotVisibleStatus(true);
    }
    let customerNumber: number = userService.getCustomerNumber();
    let accountNumbers: string[] = userService.getAccountNumbers();
    promiseChanged(false);

    let fetchAccounts = (customerNumber: number, accountNumbers: string[]) => {
      return cashAccountViewService.findAccounts(customerNumber, accountNumbers, false).subscribe((response => {
        if (response != undefined && response.error) {
          setError(true);
          promiseChanged(true);
        }
        else {
          setError(false);
          contracts = response.contractAccountDetails;
          let activeContractDetails = accountInfoService.getActiveAccounts(contracts);
          let terminatedContracts = accountInfoService.getTerminatedAccounts(contracts);
          let loanContracts = accountInfoService.getLoanAccounts(contracts);
          store.dispatch(activeContractAccountDetailsActions.setActiveContracts(activeContractDetails));
          //stop the spinner here if there're no contracts which means there're no additional promises
          if (activeContractDetails.length === 0) {
            promiseChanged(true);
          }
          let promisesList = new Array();
          let self = this;
          _.each(activeContractDetails, function (accountDetails) {
            promisesList.push(customerACHService.findUpcomingUpays(accountDetails.accountNumber, false));
            promisesList.push(customerACHService.findEasyPaySchedule(accountDetails.accountNumber));
          })
          _.each(loanContracts, function (accountDetails) {
            promisesList.push(contractualPayoffService.calculatePayoff(accountDetails.fSAccountId, false));
          })
          forkJoin(promisesList).subscribe(responses => {
            postGetCalculatePayoffAndUPays(responses);
          });
          let terminatedPromisesList = new Array();
          _.each(terminatedContracts, function (accountDetails) {
            terminatedPromisesList.push(customerACHService.findUpcomingUpays(accountDetails.accountNumber, false));
          })
          forkJoin(terminatedPromisesList).subscribe(responses => {
            postFindUpcomingUpays(responses);
          });
  
          if (activeContractDetails.length > 0) {
            groupAndFilterActiveContracts(activeContractDetails);
          }
          if (terminatedContracts.length > 0) {
            sortTerminatedContracts(terminatedContracts);
          }
        }
      }));
    }

    let afterGetContactByGcidSuccess = (response: any) => {
      let filteredFinancialProducts = filterService.getFilteredFinancialProducts(response.contact.financialProducts);
      response.contact.financialProducts = filteredFinancialProducts;
      accountInfoService.enCodeAndSaveCosyUrl(filteredFinancialProducts);
      store.dispatch(contactActions.setContact(response.contact));
      let accountNumbers = filterService.getFilteredAccountNumbers(response.contact.financialProducts);
      userService.setAccountNumbers(accountNumbers);     
      return fetchAccounts(customerNumber, accountNumbers);    
    }
  
    let afterGetContactByGcidFailure = (error: any) => {
      fsTokenErrorHandler.handleFSTokenError(error);
    }
    
    if (accountNumbers.length > 0) {
      fetchAccounts(customerNumber, accountNumbers);
    }
    else {
      return partnerContactService.getContactByGcidClientId().pipe(map(response => {
        if (response && !response.error) {
          return afterGetContactByGcidSuccess(response);
        }
        else {
          afterGetContactByGcidFailure(response.error);
        }
      }));
    }
  }
}