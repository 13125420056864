import { AnalyticsAttributeName, AnalyticsEvent, AnalyticsSiteToolName, AnalyticsSiteToolStep } from '../../shared/enums';

export interface AnalyticsDeepLinkTracking {
  target: string;
  source: string;
}

interface AnalyticsDownloadTracking {
  downloadFileName: string;
}

interface AnalyticsErrorTracking {
  errorName: string;
}

export class AnalyticsMyAccountTracking {
  accordionLink: string | null = null;
  accordionLinkStatus: string | null = null;
  discountedPurchaseMiles: number | null = null;
  revenue: number | null = null;
  paymentId: number | null = null;
  paymentMethod: string | null = null;
  source: string | null = null;
  savedQuote: string | null = null;
  notificationReminderPreference: string | null = null;
  securityAuthentication: string | null = null;
  siteTool: AnalyticsSiteToolName | string | null = null;
  siteToolStep: AnalyticsSiteToolStep | string | null = null;
  siteToolError: string | null = null;
}

export class AnalyticsPageTracking {
  baseDomain: string | null = null;
  name!: string;
  pageLevel2: string | null = null;
  pageLevel3: string | null = null;
  pageLevel4: string | null = null;
  pageType: string | null = null;
  primarySection: string = "";
  qsp: string = window.location.search.replace('?', '');
  subDomain: string = "";
  title: string | null = null;
  url: string = window.location.href;
  loginState: string | null;
  partner: string | null;
}

interface AnalyticsSecureMessageCenterTracking {
  subject: string;
}

interface AnalyticsUserTracking {
  gcid: string;
  loginStatus: string;
}

export class BaseDataLayerModel {
  deepLinkTracking: AnalyticsDeepLinkTracking | null = null;
  downloadTracking?: AnalyticsDownloadTracking;
  errorTracking?: AnalyticsErrorTracking;
  myAccount: Partial<AnalyticsMyAccountTracking> = new AnalyticsMyAccountTracking();
  page: Partial<AnalyticsPageTracking> = new AnalyticsPageTracking();
  secureMessageCenter: AnalyticsSecureMessageCenterTracking | null = null;
  timeStamp: string = new Date().toString();
  user!: AnalyticsUserTracking;
}

export interface PushDataLayerParameters {
  dataLayer?: Partial<BaseDataLayerModel>;
  eventName?: AnalyticsEvent;
  additionalArguments?: any;
  delay?: number;
}

export interface AnalyticsAttribute {
  event: AnalyticsEvent;
  name: AnalyticsAttributeName;
  taggedElement: HTMLElement;
  value: string;
}

export interface CreateInsertScriptParams {
  scriptId?: string;
  scriptSrc?: string;
  scriptText?: string
}