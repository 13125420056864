import { Injectable } from '@angular/core';
import { Constants } from './../constants';
import { UserService } from './user.service';
import { routes } from './router.service';
import { AddressDTO } from '../../core/gateway-api';

@Injectable()
export class MaskService {

  constructor(private userService: UserService) { }

  public getMaskedEmail(email: string): string {
    if (email && email.length > 0) {
      let beforePart = email.substring(0, email.indexOf('@'));
      let afterPart = email.substring(email.indexOf('@'));
      let beforeMaskPart = beforePart.substring(0, 4);
      let afterMaskPartLength = beforePart.substring(4).length;
      let afterMaskPart = Array(afterMaskPartLength + 1).join("*");

      return beforeMaskPart + afterMaskPart + afterPart;
    }
    return "";
  }

  public getMaskedEmailFixedMask(email: string): string {
    if (email && email.length > 0) {
      const beforePart = email.substring(0, email.indexOf('@'));
      const afterPart = email.substring(email.indexOf('@'));
      let beforeMaskPart = beforePart;
      if (beforePart.length > 4) {
        beforeMaskPart = beforePart.substring(0, 4);
      }
      return `${beforeMaskPart}******${afterPart}`;
    }
    return "";
  }

  public getMaskedPhoneNumber(phoneNumber: string): string {
    return `XXX-XXX-${phoneNumber.substring(6)}`.trim();
  }

  public padLeft(value: string, padding: number, paddingChar: string): string {
    return Array(padding + 1).join(paddingChar) + value;
  }

  public getAccountNoWithMaskedChar(accountNo: string, maskedChar: string): string {
    if (accountNo && accountNo.length > 4) {
      return this.padLeft(accountNo.substring(accountNo.length - 4), accountNo.length - 4, maskedChar);
    }

    return accountNo;
  }
  public getMaskedAccountNo(accountNo: string): string {
    if (accountNo && accountNo.length > 0) {
      return this.padLeft(accountNo.substring(accountNo.length - 4), accountNo.length - 4, Constants.MaskCharAccountNo);
    }

    return accountNo;
  }

  public getSingleMaskedAccountNo(accountNo: string): string {
    if (accountNo && accountNo.length > 0) {
      return this.padLeft(accountNo.substring(accountNo.length - 4), 1, Constants.MaskCharAccountNo);
    }

    return accountNo;
  }

  public getLastFourAccountNo(accountNo: string): string {
    if (accountNo && accountNo.length > 0) {
      return accountNo.slice(-4);
    }
    return accountNo;
  }

  public getMaskedRoutingNo(routingNumber: string): string {
    let maskedRoutingNumber: string = "";
    if (routingNumber && routingNumber.length > 4) {
      maskedRoutingNumber = this.padLeft(routingNumber.substring(routingNumber.length - 4), routingNumber.length - 4, Constants.MaskCharRoutingNumber);
    }
    else {
      maskedRoutingNumber = routingNumber;
    }
    return maskedRoutingNumber;
  }

  public getFormatedAddress(address: AddressDTO): string {
    let fullAddress: string = Constants.EMPTY;
    if (address) {
      if (address.address1) {
        fullAddress = address.address1.trim();
      }
      if (address.address2) {
        fullAddress = fullAddress + " " + address.address2.trim();
      }
      if (address.address3) {
        fullAddress = fullAddress + " " + address.address3.trim();
      }
      if (address.city) {
        fullAddress = fullAddress + " " + address.city.trim();
      }
      if (address.state) {
        fullAddress = fullAddress + ", " + address.state.trim();
      }
      if (address.postalCode) {
        fullAddress = fullAddress + ", " + this.getFormattedZip(address.postalCode.trim());
      }
    }
    return fullAddress;
  }

  public getFormatedPhoneNumber(phoneNumber: string): string {
    let formatedNumber: string;
    var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(phoneNumber)) {
      formatedNumber =
        phoneNumber.replace(phoneRegex, "($1) $2-$3");
    }
    else {
      formatedNumber = phoneNumber;
    }
    return formatedNumber;
  }

  public getPhoneNumber(formatedPhoneNumber: string): string {
    return formatedPhoneNumber.replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "").replace(/-/g, "");
  }

  public getFormattedZip(zip: string): string {
    return zip.length > 5 ? this.getZipPrefix(zip) + '-' + zip.substring(5) : zip;
  }

  public getZipPrefix(zip: string): string {
    return zip.substring(0, 5);
  }

  public getVehicleYearAndModel(vehicleYear: string, vehicleModel: string): string {
    return vehicleYear + " " + vehicleModel;
  }

  public getTrimmedAddress(address: AddressDTO): AddressDTO {
    if (address) {
      const addressObj = { ...address };
      addressObj.address1 = address.address1 ? address.address1.trim() : null;
      addressObj.address2 = address.address2 ? address.address2.trim() : null;
      addressObj.address3 = address.address3 ? address.address3.trim() : null;
      addressObj.postalCode = address.postalCode ? address.postalCode : null;
      addressObj.city = address.city ? address.city.trim() : null;
      addressObj.state = address.state ? address.state.trim() : null;
    }
    return address;
  }


  public getConcatenatedString(value1: string, value2: string, value3: string): string {
    let commaSeparator = ",";
    let string: string = value1;
    if (value2) {
      string = string.concat(commaSeparator, value2);
    }
    if (value3) {
      string = string.concat(commaSeparator, value3);
    }
    return string;
  }

  public getTrimmedValue(value: string) {
    return value.trim();
  }

  public getDateWithNoTimestamp(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
}

