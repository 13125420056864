import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import {
  MessageCenterServiceClient,
  PrepareComposeViewRequest,
  PrepareComposeViewRequestSystem,
  MessageSubjectClassificationDTO,
  GetThreadMessagesWebRequest,
  GetThreadMessagesWebRequestSystem,
  FindThreadsWebRequest,
  FindThreadsWebRequestSystem,
  GetAttachmentForDisplayWebRequest,
  GetAttachmentForDisplayWebRequestSystem,
  ExpireThreadsWebRequest,
  ExpireThreadsWebResponse,
  ExpireThreadsWebRequestSystem,
  ReplyToMessageWebRequestSystem,
  SecureMessageAttachmentInputDTO,
  ReplyToMessageWebRequestGenerationType,
  ReplyToMessageWebRequest,
  CreateMessageWebRequest,
  CreateMessageWebRequestSystem,
  MessageProductReferenceDTO,
  CreateMessageWebRequestGenerationType,
  PostPriorityEmailRequest,
  ContactDTO
} from './../core/gateway-api';
import { ComposeMessageModel } from '../_models/secure-message-center/compose-message.model';
import { FSTokenErrorHandler } from './../shared/_errorhandler/gobal-error-handler';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { IAppState } from "../shared/store/app.store";
import { NotificationsHelperService } from '../shared/_helper-services/notifications-helper.service';
import { SMCViewThreadModel } from '../_models/secure-message-center/view-thread.model';
import { InboxMessages } from '../_models/secure-message-center/inbox.model';
import { Base64BtnModel } from '../_models/base-64-btn.model';
import { SubjectTopicsActions } from '../shared/store/reducers/subject-topics.reducers';
import { Constants } from '../shared/constants';
import { UserService } from '../shared/_helper-services/user.service';
import { LanguageService } from '../shared/_helper-services/language.service';
import { SMCSubjectType, Brand } from "../shared/enums";
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class MessageCenterService {
 
  private systemStatusRequest: string;
  private brand: string;
  private tenant: string;
  public storeAppConfig: ApplicationConfig;
constructor(private messageCenterServiceClient: MessageCenterServiceClient,
  private store: Store<IAppState>,
  private notificationsHelperService: NotificationsHelperService,
  private userService: UserService,
  private fsTokenErrorHandler: FSTokenErrorHandler,
  private subjectTopicsActions: SubjectTopicsActions,
  private languageService: LanguageService) {
  this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
  this.systemStatusRequest = this.storeAppConfig.SYSTEM.toString();
  this.brand = this.storeAppConfig.BRAND.toString();
  this.tenant = this.storeAppConfig.TENANT.toString();
}


  private getCachedSubjectTopics() {
  let subjectTopics: MessageSubjectClassificationDTO[];
  this.store.select(state => state.SubjectTopics).subscribe(x => subjectTopics = x);
  return subjectTopics ? _.toArray(subjectTopics) : null;
}

  public prepareComposeView(refresh ?: boolean): Observable < ComposeMessageModel > {
  let cachedSubjectTopics = this.getCachedSubjectTopics();
  if(refresh || cachedSubjectTopics == null || cachedSubjectTopics.length === 0) {
  let prepareComposeViewRequest = this.getPrepareComposeViewRequest();
  return this.messageCenterServiceClient.prepareComposeView(prepareComposeViewRequest)
    .pipe(mergeMap((response) => { return this.afterPrepareComposeViewSuccess(response, refresh); })
      , catchError((error: any) => { return this.afterPrepareComposeViewFailure(error); }));
}
return of(this.getComposeMessageModel(false, cachedSubjectTopics));
  }

  public createMessage(composeMessageModel: ComposeMessageModel): Observable < ComposeMessageModel > {
  let createMessageRequest = this.getCreateMessageRequest(composeMessageModel);
  return this.messageCenterServiceClient.createMessageWeb(createMessageRequest)
    .pipe(mergeMap((response) => { return this.afterCreateMessageSuccess(response); })
      , catchError((error: any) => { return this.afterCreateMessageFailure(error); }));
}

  public postPriorityEmail(composeMessageModel: ComposeMessageModel): Observable < ComposeMessageModel > {
  let postPriorityEmailRequest = this.getPostPriorityEmailRequest(composeMessageModel);
  return this.messageCenterServiceClient.postPriorityEmail(postPriorityEmailRequest)
    .pipe(mergeMap((response) => { return this.afterPostPriorityEmailSuccess(response); })
      , catchError((error: any) => { return this.afterPostPriorityEmailFailure(error); }));
}

  public expireThreads(threadIds: number[]): Observable < ExpireThreadsWebResponse > {
  if(threadIds && threadIds.length > 0) {
  let expireThreadRequest = new ExpireThreadsWebRequest();
  expireThreadRequest.threadIds = threadIds;
  this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => expireThreadRequest.userId = x);
  this.store.select(state => state.MessageCenterUserToken.stringValue).subscribe(x => expireThreadRequest.userToken = x);
  expireThreadRequest.customerNumber = this.userService.getCustomerNumber();
  expireThreadRequest.system = ExpireThreadsWebRequestSystem[this.systemStatusRequest];

  return this.messageCenterServiceClient.expireThreadsWeb(expireThreadRequest)
    .pipe(mergeMap((response) => { return this.afterExpireMessageThreadSuccess(response); })
      , catchError((error: any) => { return this.afterExpireMessageThreadFailure(error); }));
}
  }

  private afterExpireMessageThreadSuccess(response: any) {
  let expireThreadResponse = new ExpireThreadsWebResponse();
  expireThreadResponse.expiredThreads = response.expiredThreads;
  return of(expireThreadResponse);
}

  private afterExpireMessageThreadFailure(error: any) {
  let expireThreadResponse = new ExpireThreadsWebResponse();
  expireThreadResponse.expiredThreads = undefined;
  return of(expireThreadResponse);
}

  private getComposeMessageModel(error: boolean, subjects ?: MessageSubjectClassificationDTO[], faultType ?: string): ComposeMessageModel {
  let composeMessageModel = new ComposeMessageModel;
  composeMessageModel.subject.options = subjects;
  composeMessageModel.error = error;
  composeMessageModel.faultType = faultType;
  return composeMessageModel;
}

  private afterPrepareComposeViewSuccess(result: any, refresh: boolean): Observable < ComposeMessageModel > {
  result = this.filterSubjects(result, this.tenant, this.brand);
  let composeMsgModel = this.getComposeMessageModel(false, result.subjectsByClassification)
    this.store.dispatch(this.subjectTopicsActions.setSubjectTopics(composeMsgModel.subject.options));
  return of(composeMsgModel);
}

  private filterSubjects(result: any, tenant: string, brand: string): any {
  if (result && result.subjectsByClassification)
    _.each(result.subjectsByClassification, function (subjectsByClassification: any) {
      let subjects: any;
      switch (tenant) {
        case (Constants.Country_USA):
          (brand === Brand.BMW.toString()) ? subjects = subjectsByClassification.subjects.filter(x => x.subjectTypeId != SMCSubjectType.RepairReceiptId) : subjects = subjectsByClassification.subjects;
          subjectsByClassification.subjects = subjects;
          break;
        case (Constants.Country_CA):
          subjects = subjectsByClassification.subjects.filter(x => x.subjectTypeId != SMCSubjectType.BankUpdateId && x.subjectTypeId != SMCSubjectType.BankUpdateIdFr);
          subjectsByClassification.subjects = subjects;
          break;
        default:
          break;
      }
    });

  return result;
}

  private afterPrepareComposeViewFailure(error: any): Observable < ComposeMessageModel > {
  this.fsTokenErrorHandler.handleFSTokenError(error);
  let composeMsgModel = this.getComposeMessageModel(true);
  composeMsgModel.error = true;
  return of(composeMsgModel);
}

  private afterCreateMessageSuccess(result: any): Observable < ComposeMessageModel > {
  let composeMessageModel = this.getComposeMessageModel(false);
  return of(composeMessageModel);
}

  private afterCreateMessageFailure(error: any): Observable < ComposeMessageModel > {
  this.fsTokenErrorHandler.handleFSTokenError(error);
  let composeMessageModel = this.getComposeMessageModel(true, null, error.faultType);
  return of(composeMessageModel);
}

  private afterPostPriorityEmailSuccess(result: any): Observable < ComposeMessageModel > {
  let composeMessageModel = this.getComposeMessageModel(false);
  return of(composeMessageModel);
}

  private afterPostPriorityEmailFailure(error: any): Observable < ComposeMessageModel > {
  this.fsTokenErrorHandler.handleFSTokenError(error);
  let composeMessageModel = this.getComposeMessageModel(true, null, error.faultType);
  return of(composeMessageModel);
}

  private getPrepareComposeViewRequest(): PrepareComposeViewRequest {
  let prepareComposeViewRequest = new PrepareComposeViewRequest();
  prepareComposeViewRequest.userToken = null;
  prepareComposeViewRequest.language = this.languageService.getCurrentLanguageCode().toUpperCase();
  prepareComposeViewRequest.system = PrepareComposeViewRequestSystem[this.systemStatusRequest];



  return prepareComposeViewRequest;
}

  private getCreateMessageRequest(composeMessageModel: ComposeMessageModel): CreateMessageWebRequest {
  let createMessageRequest = new CreateMessageWebRequest();
  this.store.select(state => state.MessageCenterUserToken.stringValue).subscribe(x => createMessageRequest.userToken = x);
  createMessageRequest.system = CreateMessageWebRequestSystem[this.systemStatusRequest];
  createMessageRequest.subjectType = composeMessageModel.subject.selected.subtopic.subjectTypeId;
  createMessageRequest.subject = composeMessageModel.subject.selected.subtopic.description;
  createMessageRequest.messageBody = composeMessageModel.body;
  if (composeMessageModel.account && composeMessageModel.account.selected) {
    createMessageRequest.product = new MessageProductReferenceDTO();
    createMessageRequest.product.accountNumber = composeMessageModel.account.selected.accountNumber;
  }
  else {
    createMessageRequest.product = null;
  }
  createMessageRequest.attachments = composeMessageModel.attachments;
  this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => createMessageRequest.userId = x);
  createMessageRequest.generationType = CreateMessageWebRequestGenerationType.InBound;
  createMessageRequest.replyQueue = null;
  createMessageRequest.replyAllowed = null;
  createMessageRequest.customerNumber = this.userService.getCustomerNumber();

  return createMessageRequest;
}

  private getPostPriorityEmailRequest(composeMessageModel: ComposeMessageModel): PostPriorityEmailRequest {
  let postPriorityEmailRequest = new PostPriorityEmailRequest();
  postPriorityEmailRequest.customerNumber = this.userService.getCustomerNumber() ? this.userService.getCustomerNumber().toString() : null;
  let contact: ContactDTO;
  this.store.select(state => state.Contact).subscribe(x => contact = x);
  if (contact) {
    postPriorityEmailRequest.firstName = contact.firstName;
    postPriorityEmailRequest.lastName = contact.lastName;
  }
  if (composeMessageModel) {
    let subtopicIsDefined = composeMessageModel.subject && composeMessageModel.subject.selected && composeMessageModel.subject.selected.subtopic;
    postPriorityEmailRequest.subject = subtopicIsDefined ? composeMessageModel.subject.selected.subtopic.description : Constants.EMPTY;
    postPriorityEmailRequest.comments = composeMessageModel.body;
    this.store.select(state => state.EnvironmentConfig.FS_EMAIL_ADDRESS).subscribe(x => postPriorityEmailRequest.toEmailAddress = x);
    postPriorityEmailRequest.fromEmailAddress = this.notificationsHelperService.getCustomerPrimaryEmailAddress();
    if (composeMessageModel.account && composeMessageModel.account.selected) {
      postPriorityEmailRequest.accountNumber = (composeMessageModel.account.selected.accountNumber != undefined) ? composeMessageModel.account.selected.accountNumber : Constants.EMPTY;
      postPriorityEmailRequest.vIN = (composeMessageModel.account.selected.vIN != undefined) ? composeMessageModel.account.selected.vIN : Constants.EMPTY;
    }

  }
  return postPriorityEmailRequest;
}

  public getThreadMessages(threadId: number): Observable < SMCViewThreadModel > {
  let getThreadRequest = new GetThreadMessagesWebRequest();
  getThreadRequest.threadId = threadId;
  getThreadRequest.markNewAsRead = true;
  this.store.select(state => state.MessageCenterUserToken.stringValue).subscribe(x => getThreadRequest.userToken = x);
  getThreadRequest.system = GetThreadMessagesWebRequestSystem[this.systemStatusRequest];
  getThreadRequest.customerNumber = this.userService.getCustomerNumber();

  return this.messageCenterServiceClient.getThreadMessagesWeb(getThreadRequest)
    .pipe(mergeMap((response) => { return this.afterGetThreadMessagesSuccess(response); })
      , catchError((error: any) => { return this.afterGetThreadMessagesFailure(error); }));
}

  private afterGetThreadMessagesSuccess(response: any): Observable < SMCViewThreadModel > {
  let thread = new SMCViewThreadModel();
  thread.getSecureMessageDetail = response;
  return of(thread);
}

  private afterGetThreadMessagesFailure(error: any): Observable < SMCViewThreadModel > {
  let thread = new SMCViewThreadModel();
  thread.error = true;
  return of(thread);
}

  public findThreads(): Observable < InboxMessages > {
  let findThreadsRequest = new FindThreadsWebRequest();
  findThreadsRequest.system = FindThreadsWebRequestSystem[this.systemStatusRequest];
  this.store.select(state => state.MessageCenterUserToken.stringValue).subscribe(x => findThreadsRequest.userToken = x);
  findThreadsRequest.customerNumber = this.userService.getCustomerNumber();

  return this.messageCenterServiceClient.findThreadsWeb(findThreadsRequest)
    .pipe(mergeMap((response) => { return this.afterFindThreadsSuccess(response); })
      , catchError((error: any) => { return this.afterFindThreadsFailure(error); }));
}

  private afterFindThreadsSuccess(result: any): Observable < InboxMessages > {
  let messages = new InboxMessages();
  messages.error = false;
  messages.messageThreads = result.threads;
  return of(messages);
}

  private afterFindThreadsFailure(error: any): Observable < InboxMessages > {
  let messages = new InboxMessages();
  messages.error = true;
  return of(messages);
}

  public getAttachmentForDisplay(messageId: number, storedDocumentId: number): Observable < Base64BtnModel > {
  let attachmentRequest = new GetAttachmentForDisplayWebRequest();
  this.store.select(state => state.MessageCenterUserToken.stringValue).subscribe(x => attachmentRequest.userToken = x);
  attachmentRequest.messageId = messageId;
  attachmentRequest.storedDocumentId = storedDocumentId;
  attachmentRequest.system = GetAttachmentForDisplayWebRequestSystem[this.systemStatusRequest];
  this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => attachmentRequest.userId = x);
  attachmentRequest.customerNumber = this.userService.getCustomerNumber();

  return this.messageCenterServiceClient.getAttachmentForDisplayWeb(attachmentRequest)
    .pipe(mergeMap((response) => { return this.afterGetAttachmentSuccess(response); })
      , catchError((error: any) => { return this.afterGetAttachmentFailure(error); }));
}

  private afterGetAttachmentSuccess(response: any): Observable < Base64BtnModel > {
  return of(response);
}

  private afterGetAttachmentFailure(error: any): Observable < Base64BtnModel > {
  let attachment = new Base64BtnModel();
  attachment.error = true;
  return of(attachment);
}

  public replyToMessage(threadId ?: number, messageBody ?: string, attachments ?: SecureMessageAttachmentInputDTO[], generationType ?: ReplyToMessageWebRequestGenerationType): Observable < SMCViewThreadModel > {
  let replyToMessageRequest = new ReplyToMessageWebRequest();
  replyToMessageRequest.system = ReplyToMessageWebRequestSystem[this.systemStatusRequest];
  this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => replyToMessageRequest.userId = x);
  this.store.select(state => state.MessageCenterUserToken.stringValue).subscribe(x => replyToMessageRequest.userToken = x);
  replyToMessageRequest.threadId = threadId;
  replyToMessageRequest.messageBody = messageBody;
  replyToMessageRequest.attachments = attachments;
  replyToMessageRequest.generationType = generationType;
  replyToMessageRequest.customerNumber = this.userService.getCustomerNumber();

  return this.messageCenterServiceClient.replyToMessageWeb(replyToMessageRequest)
    .pipe(mergeMap((response) => { return this.replyToMessageSuccess(response); })
      , catchError((error: any) => { return this.replyToMessageFailure(error); }));
}

  private replyToMessageSuccess(result: any): Observable < SMCViewThreadModel > {
  let thread = new SMCViewThreadModel();
  thread.findSecureMessageThreadDetail = result.thread;
  return of(thread);
}

  private replyToMessageFailure(error: any): Observable < SMCViewThreadModel > {
  let thread = new SMCViewThreadModel();
  thread.error = true;
  return of(thread);
}
}
