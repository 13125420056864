import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountInfoService } from "../../../shared/_helper-services/account-info.service";
import { DialogService } from '../../_helper-services/dialog.service';
import { RouterService } from '../../_helper-services/router.service';


@Component({
    selector: 'app-contract-header-change-account',
    templateUrl: './contract-header-change-account.component.html',
    styleUrls: ['contract-header-change-account.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContractHeaderChangeAccountComponent implements OnInit {
    changeContractIsVisible: boolean;
    @Input() isInAccountDetailsMode: boolean;

    constructor(private accountInfoService: AccountInfoService,
      private dialogService: DialogService,
      private routerService: RouterService,
      private translateService: TranslateService) {}

    ngOnInit() {
      this.setChangeContractVisibility();
    }

    public showChangeContractDialog() {
      let changeContractDialogRef = this.dialogService.openContractChangeDialog(true);
    }

    private setChangeContractVisibility() {
        this.changeContractIsVisible =  !this.accountInfoService.isOnlyOneContract() && this.isInAccountDetailsMode;
    }
}
