import { CustomerIntentDTO } from "../../core/gateway-api";

export class CustomerIntentModel {
  isInLeaseEnd: boolean;
  isDriveProgram: boolean;
  error: boolean;
  customerIntent: CustomerIntentDTO[];

  constructor() {
    this.isInLeaseEnd = false;
    this.isDriveProgram = false;
    this.error = false;
    this.customerIntent = new Array();
  }

}


