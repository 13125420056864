import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';

export class ProfileAgreementCheckedActions {
  static SET_PROFILE_AGREEMENT_CHECKED: string = 'SET_PROFILE_AGREEMENT_CHECKED';

  setProfileAgreementChecked(profileAgreementChecked: boolean): FSA<string, boolean, null> {
    return {
      type: ProfileAgreementCheckedActions.SET_PROFILE_AGREEMENT_CHECKED,
      payload: profileAgreementChecked,
      meta: null
    };
  }
}

@Injectable()
export class ProfileAgreementCheckedReducers {
  static ProfileAgreementCheckedReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case ProfileAgreementCheckedActions.SET_PROFILE_AGREEMENT_CHECKED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
}