export class UserConsentDialogModel {
  dialogTitle: string;
  dialogText: string;
  btnOkay: string;
  btnCancel: string;

  constructor(dialogTitle?: string, dialogText?: string, btnOkay?: string, btnCancel?: string ) {
    this.dialogTitle = undefined;
    this.dialogText = undefined;
    this.btnOkay = undefined;
    this.btnCancel = undefined;
  }
}


