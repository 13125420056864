export enum PaymentPreferenceType {
    Monthly = 1,
    MonthlyAndOutstanding = 2,
    Additional = 4
}

export class PaymentPreference {
    label: string;
    type: PaymentPreferenceType;
    valueLabel: string;
    value: number;
    valueInputDisabled: boolean;
    isChecked?: boolean;
    tootlipTxt?: string
    valueTooltipTxt?: string;
}
