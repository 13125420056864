import { Component, OnInit, OnDestroy, Renderer2, ViewChild, Inject, Injector, ElementRef } from '@angular/core';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../../../../shared/constants';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { ValidatorService } from '../../../../../../_validators/validator.service';
import { UserService } from '../../../../../../shared/_helper-services/user.service';
import { AccountProfileManagementService } from '../../../../../../_web-services/account-profile-management.service';
import { AddressDTO, AddressTypes, ContractAccountDetailDTOCustomerRoleType, CheckAddressResponseResponseErrorCode } from '../../../../../../core/gateway-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { ObjectService } from '../../../../../../shared/_helper-services/object.service';
import { AccountInfoService } from '../../../../../../shared/_helper-services/account-info.service';
import { AnalyticsEvent, EditAddressType } from '../../../../../../shared/enums';
import { EditAddressModel, EditAddressErrorType } from '../../../../../../_models/edit-address.model';
import { ServicingPreferencesStatus } from '../../../../../../_models/servicing-preferences.model';
import { PreferenceCenterService } from '../../../../../../_web-services/preference-center.service';
import { NotificationsHelperService } from '../../../../../../shared/_helper-services/notifications-helper.service';
import { MaskService } from '../../../../../../shared/_helper-services/mask.service';
import { LogSiteActivityService } from '../../../../../../_web-services/log-site-activity.service';
import { ActivityTypes } from '../../../../../../shared/enums';
import { WindowService } from '../../../../../../shared/_helper-services/window.service';

@Component({
  selector: 'edit-address-dialog',
  templateUrl: './edit-address-dialog.component.html',
  styleUrls: ['./edit-address-dialog.component.scss']
})

export class EditAddressDialogComponent extends BaseApplicationComponent<EditAddressDialogComponent> implements OnInit, OnDestroy {

  @ViewChild('editAddressTopDiv', { read: ElementRef }) editAddressTopDiv: any;
  confirmMode: boolean;
  title: string;
  error: boolean;
  errorMessage: string;
  addressFaultWarningMessage: string;
  nonBillingWarningMessage: string;
  originalAddress: AddressDTO;
  originalType: EditAddressType;
  validatedAddress: AddressDTO;
  validatedType: EditAddressType;
  zipErrorMessage: string;
  address: AddressDTO;
  shortPostalCode: string;
  oldAddress: AddressDTO;
  mailingAddress: AddressDTO;
  addressType: AddressTypes;
  isLease: boolean;
  isTerminated: boolean;
  type: EditAddressType;
  accountNumber: string;
  types: any[];
  formDisabled: boolean;
  fieldsDisabled: boolean;
  isRegistrationAddress: boolean;
  isBillingCustomer: boolean;
  states: string[];
  customerRoleType: ContractAccountDetailDTOCustomerRoleType;
  checkAddressResult: EditAddressModel;


  constructor(private validatorService: ValidatorService,
    private userService: UserService,
    private translateService: TranslateService,
    private windowService: WindowService,
    private preferenceCenterService: PreferenceCenterService,
    private notificationsHelperService: NotificationsHelperService,
    private accountProfileManagementService: AccountProfileManagementService,
    private maskService: MaskService,
    private logSiteActivityService: LogSiteActivityService,
    public dialogRef: MatDialogRef<EditAddressDialogComponent>,
    renderer2: Renderer2,
    @Inject(DOCUMENT) document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector) {
    super(injector, document);
    this.confirmMode = false;
  }

  ngOnInit() {
    this.dataLayer = {
      myAccount: {
        siteTool: this.addressType == AddressTypes.Payor
          ? Constants.EditMailingAddress
          : Constants.EditVehicleRegistrationAddress,
        siteToolStep: Constants.SiteToolStart
      }
    }
    super.pushDataLayer({ eventName: AnalyticsEvent.SITE_TOOL });
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
        this.setInitialData();
        this.setOriginalAddress();
        this.setTitle(this.address);
        this.setType();
        this.getBillingStatus();
        this.logUserActivity();
      });
  }

  private logUserActivity() {
    if (this.addressType == AddressTypes.Payor && this.accountNumber && this.userService.getCustomerNumber())
      this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.accountNumber, ActivityTypes.UpdateAddress);
    else if (this.addressType == AddressTypes.Driver && this.accountNumber && this.userService.getCustomerNumber())
      this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.accountNumber, ActivityTypes.UpdateVehicleRegistrationAddress);
  }

  public onTypeChange(typeObject: any) {
    this.fieldsDisabled = typeObject && typeObject.value === EditAddressType.SameAsMailing;
    this.formDisabled = this.fieldsDisabled && this.originalType === EditAddressType.SameAsMailing;
  }

  public save() {
    //if the customer is non billing and the non billing warning message is not already shown and we are editing the mailing address 
    // -> show it and don't proceed to validate and update;
    this.scrollToTop = false;
    if (!this.isBillingCustomer && !this.nonBillingWarningMessage && this.addressType == AddressTypes.Payor) {
      this.error = true;
      this.translateService.get("ngw.profile.contact-information.edit-address.warning-non-billing").subscribe((res: string) => {
        this.nonBillingWarningMessage = res;
        this.addressFaultWarningMessage = null;
        this.errorMessage = null;
        this.scrollToTop = true; //scroll page to top to display error message
      });
    }
    else {
      if (this.addressType === AddressTypes.Driver && this.type === EditAddressType.SameAsMailing) {
        let mailingAddress = this.mailingAddress;
        this.address = _.cloneDeep(mailingAddress);
      }
      this.setAddressTypes();
      this.address.postalCode = this.shortPostalCode;
      super.setDataLoadedOnRequest(false);
      this.analytics.pushSiteToolEvent({
        siteTool: this.addressType == AddressTypes.Payor
          ? Constants.EditMailingAddress
          : Constants.EditVehicleRegistrationAddress,
        siteToolStep: Constants.SiteToolComplete
      });
      //if there's no warning message - validate

      this.accountProfileManagementService.checkAddress(this.address, this.accountNumber)
        .subscribe(result => { this.postCheckAddress(result); });

    }
  }

  public cancel() {
    this.dataLayer = { ...this.dataLayer, myAccount: { siteTool: null, siteToolStep: null } };
    super.pushDataLayer();
    this.resetAddress();
    this.dialogRef.close(null);
  }

  public address1IsInvalid(field: any, showErrorAtStart: boolean = false): boolean {
    return this.validatorService.showError(field);
  }

  public cityIsInvalid(field: any, showErrorAtStart: boolean = false): boolean {
    return this.validatorService.showError(field);
  }

  public zipIsInvalid(field: any, showErrorAtStart: boolean = false): boolean {
    return this.getZipErrorMessage(field) !== Constants.EMPTY;
  }

  public formIsInvalid(form: any): boolean {
    let isInvalid: boolean
    let formControlsAreInvalid = form && form.controls && this.address1IsInvalid(form.controls.address1) ||
      this.cityIsInvalid(form.controls.city) ||
      this.zipIsInvalid(form.controls.zip);
    isInvalid = formControlsAreInvalid || this.currentAddressIsSameWithOriginalAddress() || this.formDisabled;
    return isInvalid;
  }

  public confirm() {
    this.updateOrNavigateToTaxJurisdiction(this.checkAddressResult);
  }

  public isInConfirmMode(): boolean {
    return this.confirmMode && this.currentAddressIsSameWithValidatedAddress();
  }

  private currentAddressIsSameWithOriginalAddress(): boolean {
    let addressIsSame: boolean;
    if (this.address) {
      addressIsSame = this.address.address1 === this.originalAddress.address1 &&
        this.address.address2 === this.originalAddress.address2 &&
        this.address.address3 === this.originalAddress.address3 &&
        this.address.city === this.originalAddress.city &&
        this.address.state === this.originalAddress.state &&
        this.shortPostalCode === this.maskService.getZipPrefix(this.originalAddress.postalCode) &&
        this.type === this.originalType;
    }
    return addressIsSame;
  }

  private currentAddressIsSameWithValidatedAddress(): boolean {
    let addressIsSame: boolean;
    if (this.address) {
      addressIsSame = this.address.address1 === this.validatedAddress.address1 &&
        this.address.address2 === this.validatedAddress.address2 &&
        this.address.address3 === this.validatedAddress.address3 &&
        this.address.city === this.validatedAddress.city &&
        this.address.state === this.validatedAddress.state &&
        this.shortPostalCode === this.maskService.getZipPrefix(this.validatedAddress.postalCode) &&
        this.type === this.validatedType;
    }
    return addressIsSame;
  }

  private setInitialData(): void {
    if (this.data) {
      let address = this.data.address;
      this.address = _.cloneDeep(address);
      this.shortPostalCode = this.address ? this.maskService.getZipPrefix(this.address.postalCode) : Constants.EMPTY;
      this.mailingAddress = this.data.mailingAddress;
      this.addressType = this.data.addressType;
      this.accountNumber = this.data.accountNumber;
      this.customerRoleType = this.data.customerRoleType;
      this.isLease = this.data.isLease;
      this.isTerminated = this.data.isTerminated;
    }
    this.store.select(state => state.States).subscribe(x => this.states = x);
  }

  private getBillingStatus() {
    let customerNumber = this.userService.getCustomerNumber();
    let accountNumbers = this.accountNumber ? [this.accountNumber] : [];
    let promisesList = this.preferenceCenterService.getServicingPreferences(customerNumber, accountNumbers)
      .subscribe(result => { this.postGetServicingPreferences(result); });
  }

  private postGetServicingPreferences(result: ServicingPreferencesStatus) {
    this.isBillingCustomer = this.notificationsHelperService.isBillingCustomer(null, result.preferences);
    this.windowService.scrollDialogToTop(this.editAddressTopDiv);
  }

  private getZipErrorMessage(field: any): string {
    let errorMsg: string = Constants.EMPTY;
    if (this.validatorService.showError(field)) {
      this.translateService.get("ngw.validation.error.required").subscribe((res: string) => {
        errorMsg = res;
      });
    }
    else if (this.validatorService.showRegexError(field, "zip")) {
      this.translateService.get("ngw.validation.error.zip-pattern").subscribe((res: string) => {
        errorMsg = res;
      });
    }
    this.zipErrorMessage = errorMsg;
    return errorMsg;
  }

  private setOriginalAddress() {
    let address = this.address;
    this.originalAddress = _.cloneDeep(address);
    this.originalType = this.type;
  }

  private setValidatedAddress() {
    if (this.address) {
      let address = this.address;
      this.validatedAddress = _.cloneDeep(address);
      this.validatedType = this.type;
    }
  }

  private resetAddress() {
    if (this.address) {
      this.address = this.originalAddress;
      this.type = this.originalType;
    }
  }

  private setTitle(address) {
    let titleKey = this.addressType == AddressTypes.Payor ?
      "ngw.profile.contact-information.edit-address.title.mailing" :
      "ngw.profile.contact-information.edit-address.title.vehicle-registration";
    this.translateService.get(titleKey).subscribe((res: string) => {
      this.title = res;
    })
  }

  private setType() {
    this.isRegistrationAddress = this.addressType === AddressTypes.Driver;
    let isSameAsMailing = this.address && _.includes(this.address.addressTypes, AddressTypes.Driver) && _.includes(this.address.addressTypes, AddressTypes.Payor);
    this.type = isSameAsMailing ? EditAddressType.SameAsMailing : EditAddressType.Other;
    this.originalType = this.type;
    this.formDisabled = this.isRegistrationAddress && this.type === EditAddressType.SameAsMailing;
    this.fieldsDisabled = this.formDisabled;
    let sameTypeName, otherTypeName: string;
    this.translateService.get("ngw.profile.contact-information.edit-address.option-account-type-same").subscribe((res: string) => {
      sameTypeName = res;
    });
    this.translateService.get("ngw.profile.contact-information.edit-address.option-account-type-other").subscribe((res: string) => {
      otherTypeName = res;
    });
    this.types = [{ name: sameTypeName, value: EditAddressType.SameAsMailing }, { name: otherTypeName, value: EditAddressType.Other }];

  }

  private setAddressTypes() {
    if (this.addressType === AddressTypes.Driver) {
      if (this.type === EditAddressType.Other) {
        _.remove(this.address.addressTypes, function (at) {
          return at !== AddressTypes.Driver;
        });
      }
      //if we are updating the garaging address from other->same change the address to mailing address 
      //and add the garaging address type
      else if (this.type === EditAddressType.SameAsMailing) {
        //if the garaging type doesn't exist already - add the garaging type
        if (!_.find(this.address.addressTypes, function (at) { return at === AddressTypes.Driver })) {
          this.address.addressTypes.push(AddressTypes.Driver);
        }
      }
    }
    else if (this.addressType === AddressTypes.Payor) {
      //if it's terminated account - remove the garaging address type before saving, because you can not edit the garaging address of terminated account
      if (this.isTerminated) {
        _.remove(this.address.addressTypes, function (at) {
          return at === AddressTypes.Driver;
        });
      }
      let isAccountHolder: boolean = this.notificationsHelperService.isAccountHolder(this.customerRoleType);
      let currentAddressType: AddressTypes = isAccountHolder ? AddressTypes.Primary : AddressTypes.Secondary;
      let oppositeAddressType: AddressTypes = isAccountHolder ? AddressTypes.Secondary : AddressTypes.Primary;
      let currentAddressTypeInCurrentAddress = _.find(this.address.addressTypes, function (at) { return at === currentAddressType });
      let oppositeAddressTypeInCurrentAddress = _.find(this.address.addressTypes, function (at) { return at === oppositeAddressType });
      if (!currentAddressTypeInCurrentAddress) {
        if (oppositeAddressTypeInCurrentAddress) {
          let indexOfOppositeAddressType = _.indexOf(this.address.addressTypes, oppositeAddressType);
          if (indexOfOppositeAddressType > -1) {
            this.address.addressTypes[indexOfOppositeAddressType] = currentAddressType;
          }
        }
        else {
          this.address.addressTypes.push(currentAddressType);
        }
      }
    }
  }

  private updateAddress() {
    this.accountProfileManagementService.updateAccountAddress(this.address, this.accountNumber)
      .subscribe(result => { this.postUpdateAccountAddress(result); });
  }

  private handleResponseErrorCode(result: EditAddressModel): void {
    switch (result.responseErrorCode) {
      case CheckAddressResponseResponseErrorCode.AddressCleansedAndCanOverride: {
        if (this.address.postalCode === result.validatedAddress.postalCode) {
          this.updateOrNavigateToTaxJurisdiction(result);
        } else {
          this.confirmAddress(result);
        }
        break;
      }
      case CheckAddressResponseResponseErrorCode.AddressNotCleansedAndCanOverride: {
        this.confirmAddress(result);
        break;
      }
      case CheckAddressResponseResponseErrorCode.AddressNotCleansedAndCanNotOverride: {
        this.reenterAddress(result);
        break;
      }
      default: {
        this.updateOrNavigateToTaxJurisdiction(result);
        break;
      }
    }
  }

  private postCheckAddress(result: EditAddressModel) {
    if (result && !result.error) {
      if (result.responseErrorCode != CheckAddressResponseResponseErrorCode.AddressValid) {
        this.checkAddressResult = result;
        this.handleResponseErrorCode(result);
      }
      else {
        this.updateOrNavigateToTaxJurisdiction(result);
      }
    } else {
      this.handleError(result);
      super.setDataLoadedOnRequest(true);
      this.scrollToTop = true; //scroll page to top to display error message
    }
  }

  private updateOrNavigateToTaxJurisdiction(result: EditAddressModel) {
    //save directly
    let indexOfGaragingType = _.indexOf(this.address.addressTypes, AddressTypes.Driver);
    if (!this.isLease || (this.isLease && this.addressType === AddressTypes.Payor && indexOfGaragingType === -1)) {
      this.address = result.validatedAddress;
      this.shortPostalCode = this.maskService.getZipPrefix(this.address.postalCode);
      this.updateAddress();
    }
    //go to TJ dialog
    else {
      super.setDataLoadedOnRequest(true);
      if (this.addressType === AddressTypes.Payor && this.type === EditAddressType.SameAsMailing) {
        this.mailingAddress = result.validatedAddress;
      }
      this.closeDialogWithResponse(result);
    }
  }

  private confirmAddress(result: EditAddressModel) {
    this.confirmMode = true;
    this.setValidatedAddress();
    if (result.responseErrorCode == CheckAddressResponseResponseErrorCode.AddressCleansedAndCanOverride) {
      this.setErrorMessage("ngw.profile.contact-information.edit-address.standardized-confirm");
    }
    else if (result.responseErrorCode == CheckAddressResponseResponseErrorCode.AddressNotCleansedAndCanOverride) {
      this.setErrorMessage("ngw.profile.contact-information.edit-address.override-confirm");
    }
    super.setDataLoadedOnRequest(true);
    this.scrollToTop = true; //scroll page to top to display error message
  }

  private reenterAddress(result: any) {
    this.setErrorMessage("ngw.profile.contact-information.edit-address.check-address-error");
    super.setDataLoadedOnRequest(true);
    this.scrollToTop = true; //scroll page to top to display error message
  }

  private postUpdateAccountAddress(result: EditAddressModel) {
    if (result && !result.error) {
      super.setDataLoadedOnRequest(true);
      this.closeDialogWithResponse(result);
    }
    else {
      this.handleError(result);
      super.setDataLoadedOnRequest(true);
    }
  }

  private closeDialogWithResponse(editAddressModel: EditAddressModel): void {
    if (editAddressModel) {
      editAddressModel.validatedAddress = editAddressModel.validatedAddress ? editAddressModel.validatedAddress : this.address;
      editAddressModel.addressType = this.addressType;
      editAddressModel.type = this.type;
      editAddressModel.isLeaseAddress = this.isLease;
      editAddressModel.contractAccountNumber = this.accountNumber;
      editAddressModel.mailingAddress = this.mailingAddress;
      editAddressModel.oldAddress = this.originalAddress;
    }
    this.dialogRef.close({ editAddressModel: editAddressModel });
  }

  private setErrorMessage(errorMsgKey: string) {
    this.error = true;
    this.analytics.pushSiteToolError({ siteToolError: errorMsgKey });
    this.translateService.get(errorMsgKey).subscribe((res: string) => {
      this.errorMessage = res;
      this.addressFaultWarningMessage = null;
      this.nonBillingWarningMessage = null;
    });
  }

  private handleError(result: EditAddressModel) {
    switch (result.errortype) {
      case EditAddressErrorType.DataValidation:
        this.setErrorMessage("ngw.profile.contact-information.edit-address.error-data-validation");
        break;
      case EditAddressErrorType.POBoxAddress:
        this.setErrorMessage("ngw.profile.contact-information.edit-address.error-pobox-validation");
        break;
      default:
        this.setErrorMessage("ngw.global.technical-error");
        break;
    }
    this.logger.logcritical(["Error occurred in: " + Constants.EditAddressDialogComponentName + ", fault: " + result ? result.faultCode : Constants.EMPTY]);
  }


}
