import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { ContractAccountDetailDTO } from '../../../core/gateway-api';

@Injectable()
export class ContractAccountDetailActions
{
    static SET_CONTRACT_ACCOUNT_DETAIL: string = 'SET_CONTRACT_ACCOUNT_DETAIL';
    static CLEAR_CONTRACT_ACCOUNT_DETAIL: string = 'CLEAR_CONTRACT_ACCOUNT_DETAIL';

  setContractAccountDetail(contractAccountDetail: ContractAccountDetailDTO): FSA<string, ContractAccountDetailDTO, null>{
    return {
      type: ContractAccountDetailActions.SET_CONTRACT_ACCOUNT_DETAIL,
      payload: contractAccountDetail,
      meta: null
    };
  }

  clearContractAccountDetail(): FSA<string, ContractAccountDetailDTO, null> {
      return {
          type: ContractAccountDetailActions.CLEAR_CONTRACT_ACCOUNT_DETAIL,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class ContractAccountDetailReducers
{
  static ContractAccountDetailReducer: Reducer<ContractAccountDetailDTO> = (state: ContractAccountDetailDTO=new ContractAccountDetailDTO(), action: FSA<string, ContractAccountDetailDTO, void>) => {
    switch(action.type){
    case ContractAccountDetailActions.SET_CONTRACT_ACCOUNT_DETAIL:
            return tassign(state, action.payload);
    case ContractAccountDetailActions.CLEAR_CONTRACT_ACCOUNT_DETAIL:
        return tassign(undefined);
    default:
      return state;
    }
  }
}
