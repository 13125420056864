<div class="upload-pad-and-cheque-form upload">
  <h2>{{'ngw.add-chequing-account.upload-form-cheque.title' | translate}}</h2>
  <p>{{attachPadAgreementDescription}}</p>
  <app-upload-attachment [title]="attachPadAgreementFormBtn" [isHide]="true" [fileName]="'upload-pad-agreement' | translate" [attachmentDesc]="'PADForm' | translate" (onUploadedAttachments)="onUploadedPadAgreementAttachmentsCompleted($event)"></app-upload-attachment>

  <div class="uploaded-file-container">
    <div *ngFor="let attachment of addAchPaymentSourceCanModel.padAgreementAttachment.attachments" @animateInOut>
      <div class="uploaded-file">
        <span class="filename">{{ attachment.name }}</span>
        <span class="filesize">({{ attachment.sizeMB }} MB)</span>
        <button class="delete-file" (click)="deleteAttachment(attachment)">
          <span>{{'ngw.general.btn-delete' | translate}}</span> <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>

  <p class="add-extra-margin">{{'ngw.add-chequing-account.upload-form-cheque.attach-cheque-description' | translate}}</p>
  <app-upload-attachment [title]="'ngw.add-chequing-account.upload-form-cheque.attach-cheque-btn' | translate" [fileName]="'upload-cheque' | translate" [attachmentDesc]="'ChequeForm' | translate" (onUploadedAttachments)="onUploadedPadAgreementAttachmentsCompleted($event)"></app-upload-attachment>

  <div class="uploaded-file-container">
    <div *ngFor="let attachment of addAchPaymentSourceCanModel.chequeAttachment.attachments" @animateInOut>
      <div class="uploaded-file">
        <span class="filename">{{ attachment.name }}</span>
        <span class="filesize">({{ attachment.sizeMB }} MB)</span>
        <button class="delete-file" (click)="deleteAttachment(attachment)">
          <span>{{'ngw.general.btn-delete' | translate}}</span> <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <em class="error-validation" *ngIf="addAchPaymentSourceCanModel.attachment.attachments && addAchPaymentSourceCanModel.attachment.errorMessage">{{addAchPaymentSourceCanModel.attachment.errorMessage}}</em>
  </div>
  <mat-checkbox name="disclaimerCheckbox" [(ngModel)]="addAchPaymentSourceCanModel.disclaimer">{{formChequeDisclaimerText}}</mat-checkbox>
</div>
