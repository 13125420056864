import { routes } from "./router.service";
import * as _ from "lodash";
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from "../store/app.store";
import { Constants } from "../constants";
import { AccountInfoService } from './account-info.service';
import { ContractAccountDetailDTO } from './../../core/gateway-api';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../_helper-services/language.service';
import { MaturityBillingModel } from "./../../_models/contract/maturity-billing.model";

@Injectable()
export class NavigationService {

  url: string;

  constructor(protected store: Store<IAppState>, protected accountInfoService: AccountInfoService,
    private translateService: TranslateService, private languageService: LanguageService) { }

  //Navigation Items  
  public getMyAccountNavigationItems(parameters): any[] {

    let maturityBillings: MaturityBillingModel[];
    this.store.select(state => state.MaturityBillings).subscribe(x => maturityBillings = x);
    let contractAccounts: ContractAccountDetailDTO[];
    this.store.select(state => state.ContractAccountDetails).subscribe(x => contractAccounts = x);
    let isHoldMaturityBillingLetter: boolean;
    if (maturityBillings && maturityBillings.length > 0) {
      isHoldMaturityBillingLetter = this.accountInfoService.isHoldMaturityBillingLetterForAnyAccount(contractAccounts, maturityBillings);
    }
    else {
      isHoldMaturityBillingLetter = true;
    }
    let menu_Item: string;
    this.store.select(state => state.ApplicationConfig.ACCOUNT_DETAILS_MENU_ITEM).subscribe(x => menu_Item = x);
    let navigationItems = JSON.parse(menu_Item);

    navigationItems = this.removeFromMenu(navigationItems);
    
    _.each(navigationItems, function (item) {
      item.parameters = parameters;
    });

    if (isHoldMaturityBillingLetter && navigationItems && navigationItems.length > 0) {

      navigationItems = _.filter(navigationItems, item => {
        return item.value != Constants.LeaseMaturityInvoiceValue;
      });
    }
    return navigationItems;
  }

  public removeFromMenu(items: any[]) {
    let statementsEnabled: boolean;
    let payoffEnabled: boolean;
    let paymentDetailsEnabled: boolean;
    this.store.select(state => state.V2Statements.booleanValue).subscribe(enabled => { statementsEnabled = enabled });
    this.store.select(state => state.V2PayoffEnabled.booleanValue).subscribe(enabled => { payoffEnabled = enabled });
    this.store.select(state => state.V2PaymentDetails.booleanValue).subscribe(enabled => { paymentDetailsEnabled = enabled });
    if (statementsEnabled) {
      items = _.filter(items, (nav: { value: string; }) => {
        return nav.value !== Constants.NavigationItems.Statements;
      })
    }
    if (payoffEnabled) {
      items = _.filter(items, (nav: { value: string; }) => {
        return nav.value !== Constants.NavigationItems.PayOff;
      })
    }
    if (paymentDetailsEnabled) {
      items = _.filter(items, (nav: { value: string; }) => {
        return nav.value !== Constants.NavigationItems.PaymentDetails;
      })
    }
    if (paymentDetailsEnabled) {
      items = _.filter(items, (nav) => {
        return nav.value !== Constants.PaymentDetails;
      })
    }
    return items;
  }

  public getGoAheadUrl(contract: ContractAccountDetailDTO): string {
    let goAheadUrl, contactId: string;
    this.store.select(state => state.Contact.contactId).subscribe(x => contactId = x);
    let month: number = contract.currentContractEndDate.getMonth() + 1;
    let maturityDate: string = month.toString().concat(Constants.LanguageConcat, contract.currentContractEndDate.getDate().toString(), Constants.LanguageConcat, contract.currentContractEndDate.getFullYear().toString());

    this.translateService.get("ngw.dashboard.lease-end-info.road-ahead-url",
      { siebelId: contactId, maturityDate: maturityDate })
      .subscribe((url: string) => {
        goAheadUrl = url;
      });

    return goAheadUrl;
  }
}

