import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FooterMenuItem } from 'projects/_shared/shared/enums';
import { Observable, of } from 'rxjs';
import { ApplicationConfig } from '../../../../_shared/_models/application-config';
import { EnvironmentConfig } from '../../../../_shared/_models/environment-config';
import { route } from '../../../../_shared/shared/_helper-services/router.service';
import { Constants } from '../../../../_shared/shared/constants';
import { environment } from '../../environments/environment';

EnvironmentConfig
@Injectable()
export class EnvironmentSpecificService {

  public envSpecific: EnvironmentConfig;
  public envSpecificNull: EnvironmentConfig = null;
  public appSpecific: ApplicationConfig;
  public appSpecificNull: ApplicationConfig = null;

  constructor(private http: HttpClient) {
  }

  public loadEnvironment(): Observable<EnvironmentConfig> {
    // Only want to do this once - if root page is revisited, it calls this again.
    if (this.envSpecific === null || this.envSpecific === undefined) {
      this.envSpecific = new EnvironmentConfig();
      this.envSpecific.APP_LOGGING_ENABLED = `${environment.APP_LOGGING_ENABLED}` == "true" ? true : false;
      this.envSpecific.FS_EMAIL_ADDRESS = `${environment.FS_EMAIL_ADDRESS}`;
      this.envSpecific.CONSOLE_LOGGING_ENABLED = `${environment.CONSOLE_LOGGING_ENABLED}` == "true" ? true : false;
      this.envSpecific.COSY_URL = `${environment.COSY_URL}`;
      this.envSpecific.SSO_REDIRECT_URI = `${environment.SSO_REDIRECT_URI}`;
      this.envSpecific.ENABLED_FEATURES = JSON.parse(JSON.stringify(`${environment.ENABLED_FEATURES}`));
      this.envSpecific.ENVIRONMENT_CODE = `${environment.ENVIRONMENT_CODE}`;
      this.envSpecific.AUTH_HUB_SSO_URL = `${environment.AUTH_HUB_SSO_URL}`;
      this.envSpecific.SSO_LOGOUT_REDIRECT_URI = `${environment.SSO_LOGOUT_REDIRECT_URI}`;
      this.envSpecific.GATEWAY_API_BASE_URL = `${environment.GATEWAY_API_BASE_URL}`;
      this.envSpecific.IDLE_SESSION = parseInt(`${environment.IDLE_SESSION}`);
      this.envSpecific.AUTH_HUB_MANAGE_SETTINGS_REDIRECT_URI = `${environment.AUTH_HUB_MANAGE_SETTINGS_REDIRECT_URI}`;
      this.envSpecific.MY_BMW_URL = `${environment.MY_BMW_URL}`;
      this.envSpecific.MY_BMW_WEB_URL = `${environment.MY_BMW_WEB_URL}`;
      this.envSpecific.AUTH_HUB_REDIRECT_URI = `${environment.AUTH_HUB_REDIRECT_URI}`;
      this.envSpecific.MY_GARAGE_URL = `${environment.MY_GARAGE_URL}`;
      this.envSpecific.NGD_URL = `${environment.NGD_URL}`;
      this.envSpecific.PRODUCTION = `${environment.production}` == "true" ? true : false;;
      this.envSpecific.SAFETY_RECALL_LINK = `${environment.SAFETY_RECALL_LINK}`;
      this.envSpecific.TIME_OUT_SESSION = parseInt(`${environment.TIME_OUT_SESSION}`);
      this.envSpecific.TWO_FACTOR_AUTH_COOKIE_EXPIRATION_DAYS = parseInt(`${environment.TWO_FACTOR_AUTH_COOKIE_EXPIRATION_DAYS}`);
      this.envSpecific.ENABLED_FEATURES = JSON.parse(JSON.stringify(`${environment.ENABLED_FEATURES}`));
      this.envSpecific.MAP_RETRY_COUNT = parseInt(`${environment.MAP_RETRY_COUNT}`);
      this.envSpecific.REFRESH_BEARER_TOKEN_INTERVAL_IN_SEC = 300;
      this.envSpecific.LOGOUT_FOR_BEARER_TOKEN_EXPIRY_INTERVAL_IN_MS = 3600000;
      this.envSpecific.SMC_ATTACHMENT_SIZE_LIMIT = 3;
      this.envSpecific.ANALYTICS_CONFIG = JSON.parse(JSON.stringify(environment.ANALYTICS_CONFIG));
      this.envSpecific.DEEPLINK_ENABLED = `${environment.DEEPLINK_ENABLED}` == "true" ? true : false;
      this.envSpecific.CAPTCHA_SITE_KEY = `${environment.CAPTCHA_SITE_KEY}`;
      this.envSpecific.SSO = `${environment.SSO}` == "true" ? true : false;
      this.envSpecific.CONNECTED_DRIVE_URL = `${environment.CONNECTED_DRIVE_URL}`;
      this.envSpecific.DEALER_LOCATOR_URL = `${environment.DEALER_LOCATOR_URL}`;
      this.envSpecific.BMW_CONTACTUS_URL = `${environment.BMW_CONTACTUS_URL}`;
      this.envSpecific.CONNECTED_DRIVE_WEB_URL = `${environment.CONNECTED_DRIVE_WEB_URL}`;
      this.envSpecific.MY_BMW_CREDIT_CARD_URL = `${environment.MY_BMW_CREDIT_CARD_URL}`;
      this.envSpecific.CREDIT_CARD_SSO_LOGIN_URL = `${environment.CREDIT_CARD_SSO_LOGIN_URL}`;
      this.envSpecific.AUTH_PROVIDER = parseInt(`${environment.AUTH_PROVIDER}`);
      this.envSpecific.LEASE_LINK = JSON.parse(JSON.stringify(environment.LEASE_LINK));
      this.envSpecific.EPAAS_SCRIPT_URL = `${environment.EPAAS_SCRIPT_URL}`;
      this.envSpecific.GCDM_ONE_CONFIG = JSON.parse(JSON.stringify(environment.GCDM_ONE_CONFIG));
      this.envSpecific.NGD_BASE_URL = `${environment.NGD_BASE_URL}`;
      this.envSpecific.PROFILE_URL = `${environment.PROFILE_URL}`;
      this.envSpecific.STRIPE_PK =  `${environment.STRIPE_PK}`;
    }

    return of(this.envSpecific);
  }

  public loadApplicationConfig(): ApplicationConfig {

    if (this.appSpecific === null || this.appSpecific === undefined) {
      this.appSpecific = new ApplicationConfig();
      this.appSpecific.CLIENT_ID = 'AE41BBB6-E59D-450A-B503-BA61729A3D58';
      this.appSpecific.SITEID = 2;
      this.appSpecific.USER_ID = 'OC Web';
      this.appSpecific.POLICY_FORMAT = 'HTML';
      this.appSpecific.POLICY_LANGUAGE = 'EN';
      this.appSpecific.POLICY_ID = 'fsOsaWeb';
      this.appSpecific.DISABLED_FEATURES = JSON.stringify(['EditProfile', 'PurchaseLeaseMiles']);
      this.appSpecific.DISABLED_ROUTES = `${environment.SSO}` == "true" ? ["/login", "/registration", "/forgotpassword", "/forgotpassword /change"] : ["/registration", "/forgotpassword", "/forgotpassword /change"];
      this.appSpecific.COOKIES_LINK = 'http://www.allaboutcookies.org/';
      this.appSpecific.TENANT = 'CA';
      this.appSpecific.BRAND = "MINI";
      this.appSpecific.PRODUCT = 'MyAccount';
      this.appSpecific.FAQ_PRESENTATION_TYPE = 9;
      this.appSpecific.MARKET = "CA";
      this.appSpecific.ADOBE_URL = "https://get.adobe.com/reader/";
      this.appSpecific.COUNTRY_CODE = "CAN";
      this.appSpecific.LANGUAGE = "ENU";
      this.appSpecific.MOVEPACK_FEE = 25;
      this.appSpecific.SCRA_LEGAL_ASSISTANCE_LINK = "http://legalassistance.law.af.mil/content/locator.php";
      this.appSpecific.CONN_APP_IOS_URL = "https://itunes.apple.com/us/app/apple-store/id1087277146?pt=999603&ct=SF2_US_2018_My_Account_Notifications_Page&mt=8";
      this.appSpecific.PAYMENT_DEFERRAL_TYPE = 3;
      this.appSpecific.BMW_SYSTEM_CODE = "NGDMINICAN";
      this.appSpecific.CLIENT_APP_ID = "NGD";
      this.appSpecific.WEB_USER_ROLE = "WebUser";
      this.appSpecific.FS_ACCOUNT_HELP_PHONE_NUMBER = "1-866-378-6464";
      this.appSpecific.FS_ACCOUNT_HELP_NUMBER_COLUMBUS = "(614) 718-6350";
      this.appSpecific.LEASE_HELP_PHONE_NUMBER = "1-866-378-6464";
      this.appSpecific.CUSTOMER_CARD_NUMBER = "(888) 269-2273";
      this.appSpecific.SYSTEM = "CanadaOwnersCircle";
      this.appSpecific.IMG_BG_MOBILE_SRC = "assets/images/login-bg-mobile-canada-mini.png";
      this.appSpecific.ACCOUNT_DETAILS_MENU_ITEM = JSON.stringify([
        { "label": "ngw.my-account.lnk-account-details", "value": "accountdetails", "isActive": "false" },
        { "label": "ngw.my-account.lnk-payment-details", "value": "paymentdetails", "isActive": "false" },
        { "label": "ngw.my-account.lnk-payoff-information", "value": "payoffinformation", "isActive": "false" },
        { "label": "ngw.my-account.lnk-message-center", "value": "smcinbox", "isActive": "false" },
        { "label": "ngw.my-account.lnk-lease-maturity-invoice", "value": "leasematurityinvoice", "isActive": "false" }
      ]);
      this.appSpecific.PROFILE_MENU_ITEM = JSON.stringify([
        {
          "srcname": "icon-my-account-small.svg",
          "text": "ngw.my-account.title",
          "isImage": true,
          "class": "my-account-menu",
          "eventName": "navigateToMyAccount"
        },
        {
          "srcname": "icon-edit-small.svg",
          "text": "ngw.header.manage-settings",
          "isImage": true,
          "class": "NA",
          "eventName": "navigateToManageSettings"
        },
        {
          "srcname": "icon-my-account-small.svg",
          "text": "ngw.header.log-out",
          "isImage": false,
          "class": "logout",
          "eventName": "logout"
        }
      ]);

      this.appSpecific.FOOTER_MENU_ITEM = JSON.stringify([
        { "id": FooterMenuItem.toggelToEnglish, "eventName": "toogleToEnglish", "innerText": "ngw.footer.links.toggle-to-english" },
        { "id": FooterMenuItem.toggelToFrench, "eventName": "toogleToFrench", "innerText": "ngw.footer.links.toggle-to-french" },
        { "id": FooterMenuItem.privacyLink, "eventName": "navigateToPrivacyLink", "innerText": "ngw.footer.links.privacy-policy" },
        { "id": FooterMenuItem.UnsubscribeLink, "eventName": "navigateToUnsubscribe", "innerText": "ngw.footer.links.unsubscribe" },
        { "id": FooterMenuItem.termsLink, "eventName": "navigateToTerms", "innerText": "ngw.footer.links.terms-conditions" },
        { "id": FooterMenuItem.faqlink, "eventName": "navigateToFAQ", "innerText": "ngw.footer.links.faq" },
        { "id": FooterMenuItem.contactUsLink, "eventName": "navigateToContactUs", "innerText": "ngw.footer.links.contact-us" },
        { "id": FooterMenuItem.cookiePolicyLink, "eventName": "navigateToCookiePolicy", "innerText": "ngw.footer.links.cookie-policy" },
        { "id": FooterMenuItem.homeLink, "eventName": "navigateToSite", "innerText": "ngw.footer.links.my-bmw" }
      ]);
      this.appSpecific.ANALYTICS_PAGE_DETAIL_OVERWRITES = [
        { path: route.home, name: Constants.SelectAccount }
      ];
      this.appSpecific.SMC_VENDOR_ID = 5;
      this.appSpecific.ROAD_AHEAD_URL = 'https://mini.ca/language/owners/endoflease';
      this.appSpecific.UTM_SOURCE = "MINIFS";
      this.appSpecific.UTM_MEDIUM = "APP";
      this.appSpecific.EPAAS_TENANT_ID = "myaccount_mini_ca";
      this.appSpecific.EPAAS_FEATURE_TYPE = "ePaaS";
      this.appSpecific.PAYOFF_FEATURE_TYPE = "PayOff Payment";
      this.appSpecific.CLIENT = "canhub";
      this.appSpecific.DAYS_FOR_LEASE_EXTENSION = 30;
    }

    return this.appSpecific;
  }
}
