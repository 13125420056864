import { Component, OnInit, OnDestroy, Renderer2, ViewChild, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../../../../shared/constants';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { TaxJurisdictionViewMode } from '../../../../../../shared/enums';
import { EditAddressModel } from '../../../../../../_models/edit-address.model';
import { TaxJurisdictionModel, TaxCounty } from '../../../../../../_models/tax-jurisdiction.model';
import { TaxJurisdictionHelperService } from '../../../../../../shared/_helper-services/tax-jurisdiction-helper.service';
import { AccountProfileManagementService } from '../../../../../../_web-services/account-profile-management.service';
import { ValidatorService } from '../../../../../../_validators/validator.service';

@Component({
  selector: 'tax-jurisdiction-dialog',
  templateUrl: './tax-jurisdiction-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tax-jurisdiction-dialog.component.scss']
})

export class TaxJurisdictionDialogComponent extends BaseApplicationComponent<TaxJurisdictionDialogComponent> implements OnInit {

    public activeMode: TaxJurisdictionViewMode;
    public step1Mode: TaxJurisdictionViewMode;
    public step2Mode: TaxJurisdictionViewMode;
    public title: string;
    public subtitle: string;
    public editAddressModel: EditAddressModel;
    public taxJurisdiction: TaxJurisdictionModel;
    public emailAddressError: string;
    public movePackEligible: boolean;

    constructor(private translateService: TranslateService,
        private tJHelperService: TaxJurisdictionHelperService,
        private accountProfileManagementService: AccountProfileManagementService,
        private validatorService: ValidatorService,
        public dialogRef: MatDialogRef<TaxJurisdictionDialogComponent>,
        renderer2: Renderer2,
        @Inject(DOCUMENT) document,
        @Inject(MAT_DIALOG_DATA) public data: any,
        injector: Injector) {
        super(injector, document);
      }

    ngOnInit() {
        super.pushDataLayer();
        this.initData();
    }


    public cancel(): void {
        this.dialogRef.close(null);
    }

    public continueToStep2(): void {
        this.activeMode = this.step2Mode;
        this.setStep2Data();
    }

    public emailAddressIsInvalid(field: any, showErrorAtStart: boolean = false): boolean {
        if (this.validatorService.showError(field, null, showErrorAtStart)) {
            this.translateService.get("ngw.validation.error.required").subscribe((res: string) => {
                this.emailAddressError = res;
            });
            return true;
        }
        else if (this.validatorService.showRegexError(field, "email", showErrorAtStart)) {
            this.translateService.get("ngw.validation.error.email").subscribe((res: string) => {
                this.emailAddressError = res;
            });
            return true;
        }
        return false;
    }

    public formIsInvalid(form: any): boolean {
      return form && form.controls && this.emailAddressIsInvalid(form.controls.emailAddress, true);
    }

    public save() {
        this.scrollToTop = false;
        if (this.taxJurisdiction && this.editAddressModel) {
            let county: TaxCounty = this.taxJurisdiction.taxCounty ? this.taxJurisdiction.taxCounty : this.taxJurisdiction.selectedTaxCounty;
            this.accountProfileManagementService.updateAccountAddress(
                this.editAddressModel.validatedAddress,
                this.editAddressModel.contractAccountNumber,
                this.editAddressModel.oldAddress,
                this.editAddressModel.mailingAddress,
                county ? county.geocode : null,
                this.taxJurisdiction.emailConfirmationSelected,
                this.taxJurisdiction.emailAddress,
                true,
                this.taxJurisdiction.movePackSelected)
                .subscribe(result => { this.postUpdateAccountAddress(result); });
        }
        else {
            this.handleError();
        }
    }
    
    private handleError(): void {
        this.scrollToTop = true; //scroll page to top to display error message
        this.taxJurisdiction.error = true;
        const errorTranslationKey = "ngw.global.technical-error";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
            this.taxJurisdiction.errorMessage = res;
        });
        this.pushErrorEvent(errorTranslationKey);
    }

    private postUpdateAccountAddress(result: EditAddressModel) {
        if (result && !result.error) {
            this.dialogRef.close(true);
        }
        else {
            this.handleError();
        }

    } 

    private initData() {
        this.step1Mode = TaxJurisdictionViewMode.Step1;
        this.step2Mode = TaxJurisdictionViewMode.Step2;
        this.activeMode = this.step1Mode;
        this.translateService.get("ngw.profile.contact-information.tax-jurisdiction.title").subscribe((res: string) => {
            this.title = res;
        });
        this.editAddressModel = this.data ? this.data.editAddressModel : null;
        this.movePackEligible = this.editAddressModel? this.editAddressModel.movePackEligible: false;
        this.setStep1Data();
    }

    private setStep1Data(): void {
        this.translateService.get("ngw.profile.contact-information.tax-jurisdiction.step1.sub-title").subscribe((res: string) => {
            this.subtitle = res;
        }); 
        if (this.editAddressModel) {
            this.taxJurisdiction = this.tJHelperService.setTJAddressData(this.editAddressModel.validatedAddress, this.editAddressModel.taxJurisdictions);
        }
    }

    private setStep2Data(): void {
        this.translateService.get("ngw.profile.contact-information.tax-jurisdiction.step2.sub-title").subscribe((res: string) => {
            this.subtitle = res;
        }); 
        if (this.editAddressModel) {
            this.taxJurisdiction = this.tJHelperService.setTJPaymentData(this.taxJurisdiction, this.editAddressModel.paymentAdjustments);
        }
    }

}
