<div class="summary-charge-container">
  <div class="title"><strong>{{'ngw.vehicle-return.estimated-summary.title' | translate}}</strong></div>
  <!-- charge table -->
  <div class="charge-table summary">
    <div class="charge-table-row">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-summary.lbl-outstanding" | translate}}</div>
      <div class="charge-value">{{outstandingBalance | currency : 'USD':true | minusToParens}}</div>
    </div>
    <div class="charge-table-row">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-summary.lbl-mileage" | translate}}</div>
      <div class="charge-value">{{mileageSummary | currency : 'USD':true | minusToParens}}</div>
    </div>
    <div class="charge-table-row">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-summary.lbl-inspection" | translate}}</div>
      <div class="charge-value">{{inspectionCharges | currency : 'USD':true | minusToParens}}</div>
    </div>
    <div class="charge-table-row">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-summary.lbl-disposition" | translate}}</div>
      <div class="charge-value">{{dispositionFee | currency : 'USD':true| minusToParens }}</div>
    </div>
    <div class="charge-table-row" *ngIf="feesAndCredits && feesAndCredits!==0">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-summary.lbl-fees" | translate}}</div>
      <div class="charge-value">{{feesAndCredits | currency : 'USD':true | minusToParens}}</div>
    </div>
    <div class="charge-table-row" *ngIf="depositsAndRefunds && depositsAndRefunds!==0">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-summary.lbl-total-despoits-refund-summary" | translate}}</div>
      <div class="charge-value">{{depositsAndRefunds | currency : 'USD':true | minusToParens}}</div>
    </div>
  </div>
  <!-- end charge table -->
  <div class="summary-separator"></div>
  <!-- charge table -->
  <div class="charge-table summary">
    <div class="charge-table-row highlight">
      <div class="charge-label">{{ estimatedTotalLabelKey | translate}}</div>
      <div class="charge-value">{{estimatedTotal | currency : 'USD':true | minusToParens}}</div>
    </div>
  </div>
  <!-- end charge table -->
  <p class="info">{{"ngw.vehicle-return.estimated-summary.disclamer-tax-statement" | translate}}<br>{{disclaimerTotal}}</p>
</div>

<div class="summary-separator-decorated"></div>
<app-estimated-purchase-summary [vehicleReturnEstimate]="vehicleReturnEstimate" *ngIf="isOwnersChoice"></app-estimated-purchase-summary>
<div class="summary-separator-decorated"></div>

<div *ngIf="!this.isOwnersChoice" class="summary-protection-container">
  <p><strong>{{"ngw.vehicle-return.estimated-summary.lbl-protection-plan-lease" | translate}}</strong></p>
  <p>{{"ngw.vehicle-return.estimated-summary.disclaimer-protection-plan-lease.msg1" | translate}}</p>
  <p>{{"ngw.vehicle-return.estimated-summary.disclaimer-protection-plan-lease.msg2" | translate}}</p>
</div>
<div *ngIf="this.isOwnersChoice" class="summary-protection-container">
  <p><strong>{{"ngw.vehicle-return.estimated-summary.lbl-protection-plan-ownerchoice" | translate}}</strong></p>
  <p>{{"ngw.vehicle-return.estimated-summary.disclaimer-protection-plan-ownerchoice.msg1" | translate}}</p>
  <p>{{"ngw.vehicle-return.estimated-summary.disclaimer-protection-plan-ownerchoice.msg2" | translate}}</p>
</div>

