<div class="one-time-payment-authorization dialog">
    <div class="one-time-payment-authorization-container">
        <h1>{{'ngw.shared.one-time-payment-authorization-dialog.title' | translate}}</h1>
        <p>{{'ngw.shared.one-time-payment-authorization-dialog.message' | translate}}</p>
    
        <div class="action-buttons">
            <button id="btnContinue" type="button" class="btn"  (click)="closeDialog()" >
            {{'ngw.shared.one-time-payment-authorization-dialog.btn-continue' | translate}}</button>
        </div>
    </div>
</div>