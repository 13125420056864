import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { BaseApplicationComponent } from '../../../../../../core/basecomponent/base.component';
import { DialogService } from '../../../../../_helper-services/dialog.service';
import { ModuleOnBoardingHelperService } from '../../../../../_helper-services/module-onboarding-helper.service';
import { OnboardingLegalAgreementType } from '../../../../../enums';

@Component({
  selector: 'app-legal-agreement-terms-conditions',
  templateUrl: './legal-agreement-terms-conditions.component.html',
  styleUrls: ['./legal-agreement-terms-conditions.component.scss']
})

export class LegalAgreementTermsConditions extends BaseApplicationComponent<LegalAgreementTermsConditions> implements OnInit {
  @Output() setExpanded$ = new EventEmitter<object>();
  esignType = OnboardingLegalAgreementType.Esign;
  termsType = OnboardingLegalAgreementType.Terms;
  profileTermsType = OnboardingLegalAgreementType.Profile;
  termsCheckedSubscription: any;
  isProjectTeam: boolean;
  esignAccepted: boolean;
  termsAccepted: boolean;
  esignExpanded: boolean;
  termsExpanded: boolean;
  profileTermsAccepted: boolean;
  profileTermsExpanded: boolean;

  constructor(private dialogService: DialogService,

    public moduleOnBoardingHelperService: ModuleOnBoardingHelperService, @Inject(DOCUMENT) document, injector: Injector) {
    super(injector, document);
  }

  ngOnInit() {
    this.esignAccepted = this.moduleOnBoardingHelperService.isEsignAccepted();
    this.termsAccepted = this.moduleOnBoardingHelperService.isTermsAccepted();
    this.profileTermsAccepted = this.moduleOnBoardingHelperService.isProfileTermsAccepted();
    this.profileTermsExpanded = false;
    this.esignExpanded = false;
    this.termsExpanded = false;

  }

  public setExpanded($event: boolean, type: string) {
    if (type == OnboardingLegalAgreementType.Terms) {
      this.termsExpanded = $event;
    }
    else if(type == OnboardingLegalAgreementType.Profile){
      this.profileTermsExpanded = $event;
    }
    else {
      this.esignExpanded = $event;
    }
    this.setExpanded$.emit({ termsExpanded: this.termsExpanded, esignExpanded: this.esignExpanded, profileTermsExpanded:this.profileTermsExpanded });
  }

  public hasExpandedAccordion(): boolean {
    let hasExpanded = this.esignExpanded || this.termsExpanded || this.profileTermsExpanded;
    return hasExpanded;
  }



}
