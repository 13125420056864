import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'user-consent-dialog',
  templateUrl: './user-consent-dialog.component.html',
  styleUrls: ['./user-consent-dialog.component.scss']
})

export class UserConsentDialog {
  dialogTitle: string;
  dialogText: string;
  btnCancel: string;
  btnConfirm: string;

  constructor(private translateService: TranslateService,
        private location: Location,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private dialogRef: MatDialogRef<UserConsentDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.dialogTitle = this.data.dialogTitle;
      this.dialogText = this.data.dialogText;
      this.btnCancel = this.data.btnCancel;
      this.btnConfirm = this.data.btnOkay;
    }
  }

  confirmUserAction() {
    this.dialogRef.close(true);
  }

  cancelUserAction() {      
    const currentUrlTree = this.router.createUrlTree([], this.activeRoute.snapshot);
    const currentUrl = currentUrlTree.toString();
    this.location.go(currentUrl);
    this.dialogRef.close(false);
  }

}
