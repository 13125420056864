import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { PaymentSource } from '../../../_models/payment.model';
import { RecurringPaymentEntry } from '../../../_models/recurring-payment/recurring-payment-entry.model';
import { tassign } from 'tassign';


@Injectable()
export class RecurringPaymentEntryActions {
    static SET_RECURRING_PAYMENT_ENTRY: string = 'SET_RECURRING_PAYMENT_ENTRY';
    static CLEAR_RECURRING_PAYMENT_ENTRY: string = 'CLEAR_RECURRING_PAYMENT_ENTRY';
    static PUSH_RECURRING_PAYMENT_SOURCE: string = 'PUSH_RECURRING_PAYMENT_SOURCE';
    static SET_SELECTED_RECURRING_PAYMENT_SOURCE: string = 'SET_SELECTED_RECURRING_PAYMENT_SOURCE';

    setRecurringPaymentEntry(recurringPaymentEntry: RecurringPaymentEntry): FSA<string, RecurringPaymentEntry, null> {
        return {
            type: RecurringPaymentEntryActions.SET_RECURRING_PAYMENT_ENTRY,
            payload: recurringPaymentEntry,
            meta: null
        };
    }

    pushRecurringPaymentSource(paymentSource: PaymentSource): FSA<string, any, null> {
      return {
        type: RecurringPaymentEntryActions.PUSH_RECURRING_PAYMENT_SOURCE,
        payload: paymentSource,
        meta: null
      };
    }

    setRecurringSelectedPaymentSource(selectedPaymentSource: PaymentSource): FSA<string, any, null> {
      return {
        type: RecurringPaymentEntryActions.SET_SELECTED_RECURRING_PAYMENT_SOURCE,
        payload: selectedPaymentSource,
        meta: null
      };
    }

clearRecurringPaymentEntry(): FSA <string, RecurringPaymentEntry, null > {
    return {
        type: RecurringPaymentEntryActions.CLEAR_RECURRING_PAYMENT_ENTRY,
        payload: null,
        meta: null
    };
}

}

@Injectable()
export class RecurringPaymentEntryReducers {
    static RecurringPaymentEntryReducer: Reducer<RecurringPaymentEntry> = (state: RecurringPaymentEntry = new RecurringPaymentEntry(), action: FSA<string, RecurringPaymentEntry, void>) => {
        switch (action.type) {
            case RecurringPaymentEntryActions.SET_RECURRING_PAYMENT_ENTRY:
            return tassign(state, action.payload);
            case RecurringPaymentEntryActions.PUSH_RECURRING_PAYMENT_SOURCE:
              return {
                ...state,
                sources: [
                  ...state.sources, action.payload
                ]
              }
            case RecurringPaymentEntryActions.SET_SELECTED_RECURRING_PAYMENT_SOURCE:
              return {
                ...state,
                selectedPaymentSource: [
                  action.payload
                ]
              }
            case RecurringPaymentEntryActions.CLEAR_RECURRING_PAYMENT_ENTRY:
                return tassign(undefined);
            default:
                return state;
        }
    }
}
