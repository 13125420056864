import { Component, OnInit, Input, ViewEncapsulation, Inject, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from '../../../core/basecomponent/base.component';
import { Location } from '@angular/common';
import { PreviousRouteService } from '../../_helper-services/previous-route.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/app.store';
import { PreviousUrlActions } from '../../store/reducers/previous-url.reducer';
import { RouterService } from '../../_helper-services/router.service';
import { Constants } from '../../constants';
import { CanNavigateBackActions } from '../../store/reducers/can-navigate-back.reducer';
import { PaymentEntryActions } from '../../store/reducers/payment-entry.reducer';
import { PaymentReviewDetailsActions } from '../../store/reducers/payment-review-details.reducer';
import { MapPaymentReviewDetailsActions } from '../../store/reducers/map-payment-review-details.reducer';
import { RecurringPaymentEntryActions } from '../../store/reducers/recurring-payment.reducer';
import { UserActionActions } from '../../store/reducers/user-action.reducer';
import { UserAction } from '../../../shared/enums';
import { PaymentReviewDetails } from '../../../_models/payment.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  encapsulation: ViewEncapsulation.None
})

export class BreadcrumbComponent extends BaseApplicationComponent<BreadcrumbComponent> implements OnInit {

  @Input() title: string;
  @Input() backToEntryPage?: boolean;
  @Input() storePreviousUrl?: boolean;

  public store: Store<IAppState>;
  previousUrl: string;



  constructor(private translateService: TranslateService,
    private location: Location,
    private previousRouteService: PreviousRouteService,
    private previousUrlActions: PreviousUrlActions,
    private canNavigateBackActions: CanNavigateBackActions,
    private paymentEntryActions: PaymentEntryActions,
    private paymentReviewDetailsActions: PaymentReviewDetailsActions,
    private mapPaymentReviewDetailsActions: MapPaymentReviewDetailsActions,
    private recurringPaymentEntryActions: RecurringPaymentEntryActions,
    private userActionActions: UserActionActions,
    public routerService: RouterService,
    @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
  }

  ngOnInit() {
    if (this.storePreviousUrl == true) {
      this.setPreviousUrl();
    }
  }

  public setPreviousUrl(): void {
    let previous = this.previousRouteService.getPreviousUrl();
    this.store.dispatch(this.previousUrlActions.setPreviousUrl(previous));
  }

  public goBack() {
    this.clearCache();
    let paymentReviewDetails: PaymentReviewDetails;
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => paymentReviewDetails = x);
    if (!(paymentReviewDetails && paymentReviewDetails.isAllowToBackward)) {
      if (!this.backToEntryPage) {
        this.location.back();
      }
      else {
        this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
        this.store.dispatch(this.userActionActions.setUserAction(UserAction[UserAction.Cancel]));
        let prevUrl: string;
        this.store.select(state => state.PreviousUrl.stringValue).subscribe(x => prevUrl = x);
        if (prevUrl && prevUrl != Constants.EMPTY) {
          this.routerService.navigateByUrlWithReload(prevUrl);
          this.clearStorage();
        }
      }
    }
  }

  private clearStorage() {
    this.previousRouteService.removePreviousUrl();

  }
  private clearCache() {
    this.store.dispatch(this.paymentEntryActions.clearPaymentEntry());
    this.store.dispatch(this.paymentReviewDetailsActions.clearPaymentReviewDetails());
    this.store.dispatch(this.mapPaymentReviewDetailsActions.clearMapPaymentReviewDetails());
    this.store.dispatch(this.recurringPaymentEntryActions.clearRecurringPaymentEntry());

  }
}
