import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '../../../../../../../../_web-services/auth.service';
import { BaseApplicationComponent } from '../../../../../../../../core/basecomponent/base.component';
import { DialogService } from '../../../../../../../_helper-services/dialog.service';
import { FileLoaderService } from '../../../../../../../_helper-services/file-loader.service';
import { RouterService, route } from '../../../../../../../_helper-services/router.service';
import { UserService } from '../../../../../../../_helper-services/user.service';
import { Constants } from '../../../../../../../constants';
import { OnboardingLegalAgreementType } from '../../../../../../../enums';
import { EsignAgreementCheckedActions } from '../../../../../../../store/reducers/esign-agreement-checked.reducer';
import { ProfileAgreementCheckedActions } from '../../../../../../../store/reducers/profile-agreement-checked.reducer';
import { TermsAgreementCheckedActions } from '../../../../../../../store/reducers/terms-agreement-checked.reducer';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class Agreement extends BaseApplicationComponent<Agreement> implements OnInit {
  @Input() agreementType: string;
  @Output() setExpanded$ = new EventEmitter<boolean>();

  isInTermsMode: boolean;
  agreementHtml: SafeHtml | string;
  termsLoadError: boolean;
  isChecked: boolean;
  termsViewRoute: string;
  esignConsentAgreementPdfPath: string;
  fullDocReadEle: HTMLElement;
  isExpanded: boolean;
  isInEsignMode: boolean;
  isInProfileMode: boolean;

  constructor(@Inject(DOCUMENT) document,
    injector: Injector,
    private authService: AuthService,
    private routerService: RouterService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private termsAgreementCheckedActions: TermsAgreementCheckedActions,
    private esignAgreementCheckedActions: EsignAgreementCheckedActions,
    private profileAgreementCheckedActions: ProfileAgreementCheckedActions,
    private fileLoaderService: FileLoaderService) {
    super(injector, document);
    this.esignConsentAgreementPdfPath = Constants.EsignConsentAgreementPdfPath;
    this.isExpanded = false;
  }

  ngOnInit() {
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
        if (this.agreementType == OnboardingLegalAgreementType.Esign) {
          this.isInEsignMode = true;
          this.loadEsignContent();
        }
        else if (this.agreementType == OnboardingLegalAgreementType.Terms) {
          this.isInTermsMode = true;
          this.loadTermsContent();
        }
        else if (this.agreementType == OnboardingLegalAgreementType.Profile) {
          this.isInProfileMode = true;
          this.loadProfileContent();
        }
        this.setAgreementAcceptanceStatus(false);
      });
  }

  private loadEsignContent() {
    let esignAgreementFilePath = this.userService.isIniOSMode() ? Constants.EsignCaHtmlPath : Constants.EsignHtmlPath;
    return this.fileLoaderService.getFileContent(esignAgreementFilePath).subscribe(response =>
      this.setEsignContent(response));
  }
  private loadProfileContent() {
    super.setDataLoadedOnRequest(false);
    this.termsViewRoute = route.termsViewLink;
    this.authService.getProfileTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });
  }

  private setEsignContent(response) {
    if (response) {
      this.agreementHtml = response;
    }
    else {
      this.logger.logcritical([`Error occurred in: ${Constants.OnboardingDialogComponentName}, Gcid: ${this.userService.getGcid()}, fileLoaderService.getFileContent().`]);
      this.navigateToErrorPage();
    }

  }

  private loadTermsContent(): void {
    super.setDataLoadedOnRequest(false);
    this.termsViewRoute = route.termsViewLink;
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });
  }

  private displayTermsCondition(success: boolean, terms: string) {
    super.setDataLoadedOnRequest(true);
    if (success) {
      this.agreementHtml = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    else {
      this.logger.logcritical([`Error occurred in: ${Constants.OnboardingDialogComponentName}, Gcid: ${this.userService.getGcid()}, authService.getTermsHtml().`]);
      this.navigateToErrorPage();
    }
    this.termsLoadError = !success;
  }


  private navigateToErrorPage() {
    this.dialogService.closeLegalAgreementDialog();
    this.routerService.navigateToErrorPage();
  }

  public setAgreementAcceptanceStatus(checked: boolean) {
    if (this.isInTermsMode) {
      this.store.dispatch(this.termsAgreementCheckedActions.setTermsAgreementChecked(checked));
    }
    else if (this.isInEsignMode) {
      this.store.dispatch(this.esignAgreementCheckedActions.setEsignAgreementChecked(checked));
    }
    else if (this.isInProfileMode) {
      this.store.dispatch(this.profileAgreementCheckedActions.setProfileAgreementChecked(checked));
    }
  }

  public toggleAccordian(event) {
    var element = event.target.parentElement;
    element.classList.toggle(Constants.ActiveClass);
    element.previousElementSibling.classList.toggle(Constants.ActiveClass);
    element.previousElementSibling.scrollTop = 0;
    this.isExpanded = !this.isExpanded;
    this.setExpanded$.emit(this.isExpanded);
  }
}
