import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../_shared/_guard/auth.guard';
import { TwoFactorAuthenticatedGuard } from '../../../../_shared/_guard/two-factor-authenticated.guard';
import { CancelAddChequingAccountGuard } from "../../../../_shared/_guard/cancel-add-chequing-account.guard";
import { LegitimizedGuard } from '../../../../_shared/_guard/legitimized.guard';
import { route } from '../../../../_shared/shared/_helper-services/router.service';
import { ContractsResolve } from '../../../../_shared/_resolvers/contracts.resolve';
import { AddACHPaymentSourceCanComponent } from './_components/add-ach-payment-source-can/add-ach-payment-source.component.can';
import { AddChequingAccountConfirmationCanComponent } from './_components/add-ach-payment-source-can/_components/add-chequing-account-confirmation/add-chequing-account-confirmation.component';
import { AchConfirmationCanDeactivateGuard } from './add-chequing-account-confirmation.guard';
import { AddChequingAccountConfirmationCanResolve } from './_components/add-ach-payment-source-can/_components/add-chequing-account-confirmation/add-chequing-account-confirmation.resolve';


const paymentSourceRoutes: Routes = [
  {
    path: route.addAchPaymentSource, pathMatch: 'full', component: AddACHPaymentSourceCanComponent,
    canActivate: [AuthGuard, LegitimizedGuard, TwoFactorAuthenticatedGuard], canDeactivate: [CancelAddChequingAccountGuard],
    resolve: { void: ContractsResolve }
  },
  {
    path: route.addAchPaymentConfirmation, pathMatch: 'full', component: AddChequingAccountConfirmationCanComponent,
    canActivate: [AuthGuard, LegitimizedGuard, TwoFactorAuthenticatedGuard], canDeactivate: [AchConfirmationCanDeactivateGuard],
    resolve: { void: AddChequingAccountConfirmationCanResolve }
  }
];

@NgModule({
    imports: [
      RouterModule.forChild(paymentSourceRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PaymentSourceRoutingCanModule { }
