import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountInfoService } from './../../../_helper-services/account-info.service';
import { ContractAccountDetailDTO } from './../../../../core/gateway-api';
import * as _ from 'lodash-es';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { RouterService } from "../../../_helper-services/router.service";

@Component({
  selector: 'change-contract-dialog',
  templateUrl: './change-contract-dialog.component.html',
  styleUrls: ['./change-contract-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChangeContractDialog extends BaseApplicationComponent<ChangeContractDialog> {
  public sortedContracts: ContractAccountDetailDTO[];
  //disable current is true when the dialog is opened from acc details page
  public disableCurrent: boolean;
  public subtitle: string;
  public title: string;
  public contracts: ContractAccountDetailDTO[];

  constructor(public dialogRef: MatDialogRef<ChangeContractDialog>,
    private accountInfoService: AccountInfoService,
    private routerService: RouterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
    this.setInputData(this.data);
    this.sortedContracts = this.accountInfoService.getSortedAccounts(this.contracts);
  }

  public selectContract(contract: ContractAccountDetailDTO): void {
    if (!this.disableCurrent) {
      this.onSelectContract(contract, this.disableCurrent);
    }
    else {
      let contractAccountDetail: ContractAccountDetailDTO;
      this.store.select(state => state.ContractAccountDetail).subscribe(x => contractAccountDetail = x);
      //when the dialog is opened from acc details page -
      //we are not doing anything if the current contract is clicked
      if (contractAccountDetail && contractAccountDetail.accountNumber !== contract.accountNumber) {
        this.onSelectContract(contract, this.disableCurrent);
      }
    }
  }

  public closeDialog(accountNumber?: string): void {
    this.dialogRef.close(accountNumber);
  }

  private onSelectContract(contract: ContractAccountDetailDTO, shouldRefresh: boolean) {
    this.accountInfoService.changeContract(contract);
    //when the  dialog is opened from acc details page - we need page refresh
    if (shouldRefresh) {
      this.routerService.navigateByUrlWithReload(window.location.pathname);
    }
    this.closeDialog(contract.accountNumber);
  }

  private setInputData(data) {

    if (data) {
      this.disableCurrent = this.data.disableCurrent;
      this.subtitle = this.data.subtitle;
      let contractAccountDetail: ContractAccountDetailDTO[];
      this.store.select(state => state.ContractAccountDetails).subscribe(x => contractAccountDetail = x);
      this.contracts = this.data.contracts ? this.data.contracts : contractAccountDetail;
      this.title = this.data.title || 'ngw.shared.change-contract-dialog.title';
    }
  }

}
