import { Injectable } from '@angular/core'
import { NavigationModel } from "../_models/navigation.model";
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash-es";

@Injectable()
export class NavigationMapper {

    constructor(private translateService: TranslateService) { }

    public mapArrayToNavigationItems(request: NavigationMapperRequest): NavigationModel[] {
        let self = this;
        let navigationItems: NavigationModel[] = [];
        let indexOfItem = 0;
        _.each(request.array, function (item) {
            let itemIsActive = request.indexOfActiveItem === indexOfItem;
            let navigationModel = new NavigationModel(
                item[request.labelKey],
                request.isLabelTranslateKey,
                item,
                item[request.routeKey],
                item.parameters,
                item[request.orderKey],
                itemIsActive,
                self.translateService);
            navigationItems.push(navigationModel);
            indexOfItem++;
        });
        let orderedNavigationItems = _.orderBy(navigationItems, 'order');
        return orderedNavigationItems;
    }

}

export class NavigationMapperRequest {
    array: any;
    labelKey: string;
    isLabelTranslateKey: boolean;
    routeKey: string;
    indexOfActiveItem: number;
    orderKey: string;

    constructor(array: any, labelKey: string, isLabelTranslateKey: boolean, routeKey?: string, indexOfActiveItem?: number, orderKey?: string) {
        this.array = array;
        this.labelKey = labelKey;
        this.isLabelTranslateKey = isLabelTranslateKey;
        this.routeKey = routeKey;
        this.indexOfActiveItem = indexOfActiveItem;
        this.orderKey = orderKey;
    }
}
