import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  GetPartnerTokenByCustomerNumberRequest, GetPartnerTokenByCustomerNumberRequestIntegrationProduct, GetPartnerTokenByCustomerNumberRequestIntegrationProvider,
  GetPartnerTokenByGcIdClientIdRequest, GetPartnerTokenByGcIdClientIdRequestIntegrationProduct, GetPartnerTokenByGcIdClientIdRequestIntegrationProvider,
  GetUSBankSsoUrlRequest,
  PartnerIntegrationServiceClient
} from '../core/gateway-api';
import { UserService } from '../shared/_helper-services/user.service';
import { Market, Region } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";
import { ApplicationConfig } from '../_models/application-config';


@Injectable()
export class PartnerIntegrationService {
  private gcId: string;
  private clientId: string;
  private customerNumber: number;
  private brand: string;
  private region: string;
  storeAppConfig: ApplicationConfig;
  constructor(
    private partnerIntegrationServiceClient: PartnerIntegrationServiceClient,
    private userService: UserService,
    private store: Store<IAppState>
  ) { }

  public getSamlResponse(): Observable<string> {
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    this.brand = this.storeAppConfig.BRAND.toUpperCase();
    this.region = this.storeAppConfig.TENANT.toUpperCase();
   
    if (this.region == Region.USA || this.region == Market.US.toUpperCase()) {
      this.region = Market.US.toUpperCase();
    }
    else {
      this.region = Market.Canada.toUpperCase();
    }

    this.gcId = this.userService.getGcid();
    this.clientId = this.storeAppConfig.CLIENT_ID;
    this.customerNumber = this.userService.getCustomerNumber();

    if (this.gcId != undefined && this.gcId != "" && this.gcId != null
      && this.clientId != undefined && this.clientId != "" && this.clientId != null) {
      var gcidclientidrequest = new GetPartnerTokenByGcIdClientIdRequest();
      this.brand = this.mapIntegrationProduct(GetPartnerTokenByGcIdClientIdRequestIntegrationProduct);
      gcidclientidrequest.integrationProduct = GetPartnerTokenByGcIdClientIdRequestIntegrationProduct[this.brand];
      gcidclientidrequest.gcId = this.gcId;
      gcidclientidrequest.clientId = this.clientId;
      gcidclientidrequest.region = this.region;
      gcidclientidrequest.integrationProvider = GetPartnerTokenByGcIdClientIdRequestIntegrationProvider.SamlResponse;
      return this.getSamlResponseByGcIdClientId(gcidclientidrequest);
    }
    else if (this.customerNumber != undefined && this.customerNumber > 0) {
      var customernumberrequest = new GetPartnerTokenByCustomerNumberRequest();
      this.brand = this.mapIntegrationProduct(GetPartnerTokenByCustomerNumberRequestIntegrationProduct);
      customernumberrequest.integrationProduct = GetPartnerTokenByCustomerNumberRequestIntegrationProduct[this.brand];
      customernumberrequest.customerNumber = this.customerNumber;
      customernumberrequest.region = this.region;
      customernumberrequest.integrationProvider = GetPartnerTokenByCustomerNumberRequestIntegrationProvider.SamlResponse;
      return this.getSamlResponseByCustomerNumber(customernumberrequest);
    }
    else {
      return of("");
    }
  }

  private mapIntegrationProduct(request) {

    switch (this.brand) {
      case request.Bmw.toString().toUpperCase():
        return request.Bmw;
      case request.Mini.toString().toUpperCase():
        return request.Mini;
      case request.Motorad.toString().toUpperCase():
        return request.Motorad;
      case request.RollRoyceFuture.toString().toUpperCase():
        return request.RollRoyceFuture;
      default:
        return request.Bmw;
    }
  }

  public getSamlResponseByCustomerNumber(request): Observable<string> {
    return this.partnerIntegrationServiceClient.getPartnerTokenByCustomerNumber(request)
      .pipe(mergeMap((response) => { return this.aftergetSamlResponseBySuccess(response); })
        , catchError((error: any) => { return this.aftergetSamlResponseByFailure(error); }));
  }

  public getSamlResponseByGcIdClientId(request): Observable<string> {
    return this.partnerIntegrationServiceClient.getPartnerTokenByGcIdClientId(request)
      .pipe(mergeMap((response) => { return this.aftergetSamlResponseBySuccess(response); })
        , catchError((error: any) => { return this.aftergetSamlResponseByFailure(error); }));
  }

  private aftergetSamlResponseBySuccess(response): Observable<string> {
    return of(response.integrationToken != undefined && response.integrationToken.length > 0 ? response.integrationToken : "");
  }

  private aftergetSamlResponseByFailure(error: any): Observable<string> {
    return of("");
  }

  public getUSBankSsoUrlResponse(): Observable<string> {
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    this.brand = this.storeAppConfig.BRAND.toUpperCase();
    this.region = this.storeAppConfig.TENANT.toUpperCase();
    if (this.region == Region.USA || this.region == Market.US.toUpperCase()) {
      this.region = Market.US.toUpperCase();
    }
    else {
      this.region = Market.Canada.toUpperCase();
    }

    this.customerNumber = this.userService.getCustomerNumber();

    if (this.region != undefined && this.region != "" && this.region != null
      && this.customerNumber != undefined && this.customerNumber > 0) {
      var gUSBankSsoUrlrequest = new GetUSBankSsoUrlRequest();
      this.brand = this.mapIntegrationProduct(GetPartnerTokenByGcIdClientIdRequestIntegrationProduct);
      gUSBankSsoUrlrequest.integrationProduct = GetPartnerTokenByGcIdClientIdRequestIntegrationProduct[this.brand];
      gUSBankSsoUrlrequest.customerNumber = this.customerNumber;
      gUSBankSsoUrlrequest.region = this.region;
      gUSBankSsoUrlrequest.integrationProvider = GetPartnerTokenByGcIdClientIdRequestIntegrationProvider.SamlResponse;
      return this.getUSBankSsoUrlResponseByRequest(gUSBankSsoUrlrequest);
    }
    else {
      return of("");
    }
  }

  public getUSBankSsoUrlResponseByRequest(request): Observable<string> {
    return this.partnerIntegrationServiceClient.getUSBankSsoUrl(request)
      .pipe(mergeMap((response) => { return this.aftergetUSBankSsoUrlResponseBySuccess(response); })
        , catchError((error: any) => { return this.aftergetUSBankSsoUrlResponseByFailure(error); }));
  }

  private aftergetUSBankSsoUrlResponseBySuccess(response): Observable<string> {
    return of(response.usBankSsoUrl != undefined && response.usBankSsoUrl.length > 0 ? response.usBankSsoUrl : "");
  }

  private aftergetUSBankSsoUrlResponseByFailure(error: any): Observable<string> {
    return of("");
  }

}
