import { TranslateService } from '@ngx-translate/core';

export enum ValidationType {
  required = "required",
  pattern = "pattern",
  minlength = "minlength",
  maxLength = "maxLength",
  email = "email",
  length = "length",
  name = "name",
  max = "max",
  min = "min"
}

export class ValidationRule {
  rule: ValidationType | string;
  validator: any | null;
  message: string;

  constructor(_translateService: TranslateService, _rule: ValidationType, _validator: any, _messageKey: string) {
    _translateService.get(_messageKey).subscribe((res: string) => {
      this.message = res;
    });
    this.rule = _rule;
    this.validator = _validator;
  }
}

