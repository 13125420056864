import { Store } from '@ngrx/store';
import { LibConfigurationProvider, LibConfig } from 'customerdigital-service-lib';
import { IAppState } from '../store/app.store';
import { ApplicationConfig } from '../../../_shared/_models/application-config';
import { EnvironmentConfig } from '../../../_shared/_models/environment-config';

export class LibConfiguration implements LibConfigurationProvider {
  constructor(
    private store: Store<IAppState>
  ) { }
  get config(): LibConfig {
    let gatewayApiBaseURL, clientID, brand, market, language, profileURL, clients: string;
    let siteID, idleSession, timeOutSession: number;
    let storeAppConfig: ApplicationConfig;
    let storeEnvConfig: EnvironmentConfig;
    this.store.select(state => state.ApplicationConfig).subscribe(appConfig => storeAppConfig = appConfig);
    this.store.select(state => state.EnvironmentConfig).subscribe(envConfig => storeEnvConfig = envConfig);
    gatewayApiBaseURL = storeEnvConfig.GATEWAY_API_BASE_URL;
    clientID = storeAppConfig?.CLIENT_ID;
    siteID = storeAppConfig?.SITEID;
    brand = storeAppConfig?.BRAND;
    market = storeAppConfig?.MARKET;
    language = storeAppConfig?.LANGUAGE;
    clients = storeAppConfig?.CLIENT;
    profileURL = storeEnvConfig?.PROFILE_URL;
    idleSession = storeEnvConfig!.IDLE_SESSION;
    timeOutSession = storeEnvConfig!.TIME_OUT_SESSION;
    return {
      gateway_api_base_url: gatewayApiBaseURL,
      clientId: clientID,
      siteId: siteID,
      brand: brand.toLowerCase(),
      country: market,
      language: language,
      profile_url: profileURL,
      sso: {
        ssoRedirectUri: null,
        idp: {
          client: clients,
          scope: null,
          responsetype: null,
          loginurl: null,
          granttype: null,
          logouturl: null
        }
      },
      IdleTimeOutConfig: {
        idleTime: (idleSession ? idleSession : 18) * 60,
        timeoutTime: (timeOutSession ? timeOutSession : 2) * 60
      },
      AnalyticsConfig: null
    };
  }
}