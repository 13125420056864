import { Component, OnInit, Inject, Injector, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from "../../../core/basecomponent/base.component";
import { DOCUMENT } from '@angular/common';
import { RouterService } from "../../../shared/_helper-services/router.service";
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { AccountInfoService } from '../../../shared/_helper-services/account-info.service';
import { VehicleReturnEstimateDTO, ContractAccountDetailDTO, VehicleReturnEstimateInspectionDTO } from '../../../core/gateway-api';
import { MileageSummary } from '../../../_models/vehicle-return-estimate/milleage-summary.model';
import { DispostionFee } from '../../../_models/vehicle-return-estimate/dispostion-fee.model';
import { RemainingPaymentSummary } from '../../../_models/vehicle-return-estimate/remaining-payment-summary.model';
import { InspectionCharges } from '../../../_models/vehicle-return-estimate/inspection-charges.model';
import { IsInMAPFlowActions } from '../../../shared/store/reducers/is-in-map-flow.reducer';
import { Constants } from '../../../shared/constants';
import { ProtectionPlan } from '../../../shared/enums';
import { FeesAndCreditDetailsModel, CreditFeeAndDetailKeyValue } from '../../../_models/vehicle-return-estimate/fees-and-credit-details.model';
import { RepairReceiptUploadService } from '../../../_web-services/repair-receipt-upload.service';
import { InspectionType } from '../../../shared/enums';
import { VehicleReturnInspectionActions } from '../../../shared/store/reducers/vehicle-inspection.reducer';
import { RepairReceiptHelperService } from '../../../shared/_helper-services/repair-receipt-helper.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-estimated-charges',
  templateUrl: './estimated-charges.component.html',
  styleUrls: ['./estimated-charges.component.scss']
})

export class EstimatedChargesComponent extends BaseApplicationComponent<EstimatedChargesComponent> implements OnInit {

  mileageSummary: MileageSummary;
  inspectionCharges: InspectionCharges;
  remainingPaymentsummary: RemainingPaymentSummary;
  contractDetail: ContractAccountDetailDTO;
  isOwnersChoice: boolean = false;
  showSubmittedReceiptButton: boolean = false;
  depositsAndRefund: number = 0;
  feesAndCreditDetailsModel: FeesAndCreditDetailsModel;
  estimatedTotal: number;
  public position: string = "right";
  disPostionFee: DispostionFee;
  @Input() vehicleReturnEstimate: VehicleReturnEstimateDTO;
  public showUploadRepairReceipt: boolean;
  isRepairReceiptEnabled: boolean = false;


  constructor(private _location: Location,
    private translateService: TranslateService,
    private routerService: RouterService,
    injector: Injector,
    private accountInfoService: AccountInfoService,
    private isInMAPFlowActions: IsInMAPFlowActions,
    private currencyPipe: CurrencyPipe,
    private repairReceiptUploadSevice: RepairReceiptUploadService,
    private vehicleReturnInspectionActions: VehicleReturnInspectionActions,
    private repairReceiptHelperSevice: RepairReceiptHelperService,
    @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  ngOnInit() {
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    this.isOwnersChoice = this.accountInfoService.isOwnersChoiceContractType(this.contractDetail.contractType);
    this.repairReceiptHelperSevice.isRepairReceiptEnabled().pipe(
      map(response => response))
      .subscribe(response => {
        if (response) {
          this.isRepairReceiptEnabled = response;
        }
      });
    this.setMileageSummaryData();
    this.setRemainingPaymentSummaryData();
    this.setInspectionChargesData();
    this.setDispostionFee();
    this.setDepositsAndRefund();
    this.setFeesAndCreditDetails();
    this.estimatedTotal = this.vehicleReturnEstimate.estimatedTotal;
    if (window.innerWidth < 761) {
      this.position = "above";
    }
    this.showUploadRepairReceipt = (this.vehicleReturnEstimate.inspection.inspectionTypeId == InspectionType.AutoVinInspection);
  }

  private setMileageSummaryData() {
    this.mileageSummary = new MileageSummary();
    if (this.contractDetail) {
      this.mileageSummary.showButton = !this.isOwnersChoice;
      let mileageSummaryMsgKey = this.isOwnersChoice ? "ngw.vehicle-return.estimated-charges.mileage-summary.msg-owners-choice"
        : "ngw.vehicle-return.estimated-charges.mileage-summary.msg-lease";
      this.translateService.get(mileageSummaryMsgKey).subscribe(result => this.mileageSummary.message = result);
    }
    if (this.vehicleReturnEstimate) {
      if (this.vehicleReturnEstimate.excessMileageDetails) {
        this.vehicleReturnEstimate.excessMileageDetails.excessMiles = Math.abs(this.vehicleReturnEstimate.excessMileageDetails.excessMiles);
      }
      this.mileageSummary.data = this.vehicleReturnEstimate.excessMileageDetails;
      if (this.vehicleReturnEstimate.inspection) {
        this.mileageSummary.inspectionDate = this.vehicleReturnEstimate.inspection.inspectionDate;
      }
    }
  }
  private setDispostionFee() {
    if (this.contractDetail) {
      if (this.vehicleReturnEstimate) {
        this.disPostionFee = new DispostionFee();
        this.disPostionFee.dispostionData = this.vehicleReturnEstimate.feesAndCreditsDetails;
      }
    }
  }

  public navigateToAccountDetails() {
    this.analytics.pushSiteToolEvent({ siteTool: Constants.PurchaseDiscountedMiles, siteToolStep: Constants.SiteToolStart });
    this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(true));
    this.routerService.navigateToAccountDetails(Constants.AccountDetailsAnchors.mileageManagement);
  }

  private setRemainingPaymentSummaryData() {
    this.remainingPaymentsummary = new RemainingPaymentSummary();
    if (this.contractDetail) {
      let paymentSummaryKey = this.isOwnersChoice ? "ngw.vehicle-return.estimated-charges.remaining-payment-summary.msg-owners-choice"
        : "ngw.vehicle-return.estimated-charges.remaining-payment-summary.msg-lease-account";
      this.translateService.get(paymentSummaryKey).subscribe(result => this.remainingPaymentsummary.message = result);
    }
    if (this.vehicleReturnEstimate) {
      this.remainingPaymentsummary.data = this.vehicleReturnEstimate.remainingPaymentsDetails;
    }

  }
  private setInspectionChargesData() {

    this.inspectionCharges = new InspectionCharges();
    this.inspectionCharges.isProtectionPlanexist = false;
    if (this.contractDetail) {

      let inspectionsummaryMsgKey = this.isOwnersChoice ? "ngw.vehicle-return.estimated-charges.wear-tear-charges.disclaimer-owners-choice"
        : "ngw.vehicle-return.estimated-charges.wear-tear-charges.disclaimer-lease";
      this.translateService.get(inspectionsummaryMsgKey).subscribe(result => this.inspectionCharges.message = result);

      this.repairReceiptUploadSevice.checkRepairReceiptUpload(this.contractDetail.accountNumber)
        .subscribe(response => {
          if (response && response.response && response.response.repairReceipts && !response.error) {
            this.showSubmittedReceiptButton = response.response.repairReceipts.length > 0;
          }
        });
    }
    if (this.vehicleReturnEstimate && this.vehicleReturnEstimate.inspection && this.vehicleReturnEstimate.protectionPlan &&
      this.vehicleReturnEstimate.excessWearAndTearDetails && this.vehicleReturnEstimate.excessWearAndTearDetails.inspectionDetails) {
      this.vehicleReturnEstimate.excessWearAndTearDetails.inspectionDetails.forEach(item => this.inspectionCharges.inspectionChargesItems.push(item));
      this.vehicleReturnEstimate.protectionPlan.forEach(item => this.pushProtectionPlans(item))
    }
    this.inspectionCharges.totalInspectionCharges = this.vehicleReturnEstimate.inspection.totalDamageAmount != null ? this.vehicleReturnEstimate.inspection.totalDamageAmount : 0.00;
  }
  private pushProtectionPlans(item) {
    if (item.productId == ProtectionPlan.Lease
      || item.productId == ProtectionPlan.Loan) {
      this.inspectionCharges.isProtectionPlanexist = false;
    }
    else {
      this.inspectionCharges.isProtectionPlanexist = true;
      this.inspectionCharges.protectionPlans.push(item);
    }
  }

  private setDepositsAndRefund() {
    if (this.vehicleReturnEstimate.feesAndCreditsDetails.depositRefund) {
      this.depositsAndRefund = -this.vehicleReturnEstimate.feesAndCreditsDetails.depositRefund
    }
  }

  private setFeesAndCreditDetails() {
    this.feesAndCreditDetailsModel = new FeesAndCreditDetailsModel();
    if (this.vehicleReturnEstimate &&
      this.vehicleReturnEstimate.feesAndCreditsDetails.feeAndCreditDetails
      && this.vehicleReturnEstimate.feesAndCreditsDetails.feeAndCreditsTotal) {
      this.feesAndCreditDetailsModel.feesAndCreditDetails = new Array<CreditFeeAndDetailKeyValue>();
      for (let key of Object.keys(this.vehicleReturnEstimate.feesAndCreditsDetails.feeAndCreditDetails)) {
        if (key !== Constants.BalloonPayment) {
          this.feesAndCreditDetailsModel.feesAndCreditDetails.push(new CreditFeeAndDetailKeyValue(key, this.vehicleReturnEstimate.feesAndCreditsDetails.feeAndCreditDetails[key]));
        }
      }
      this.feesAndCreditDetailsModel.feesAndCreditsTotal = this.vehicleReturnEstimate.feesAndCreditsDetails.feeAndCreditsTotal;
    }
  }

  public navigateToRepairReceiptUpload() {
    this.cacheInspectionDetails(this.vehicleReturnEstimate.inspection, true);
    this.routerService.navigateToRepairReceiptUpload();
  }

  public navigateToViewRepairReceipts() {
    let vin: any = this.contractDetail.vIN;
    this.routerService.navigateToViewRepairReceipts({ vin });
  }

  private cacheInspectionDetails(inspectionDTO: VehicleReturnEstimateInspectionDTO, isCacheble: boolean) {
    if (isCacheble) {
      this.store.dispatch(this.vehicleReturnInspectionActions.setVehicleReturnInspection(inspectionDTO));
    }
  }
}
