import { Component, OnInit, Injector, Inject, ViewEncapsulation,OnDestroy, ViewChild} from '@angular/core';
import * as _ from 'lodash-es';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { ModuleOnBoardingHelperService } from '../../../_helper-services/module-onboarding-helper.service';
import { Constants } from '../../../constants';
import { UserService } from '../../../_helper-services/user.service';
import { PreferenceCenterService } from '../../../../_web-services/preference-center.service';
import { RouterService } from '../../../_helper-services/router.service';

@Component({
  selector: 'app-legal-agreement-dialog',
  templateUrl: './legal-agreement-dialog.component.html',
  styleUrls: ['./legal-agreement-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LegalAgreementDialog extends BaseApplicationComponent<LegalAgreementDialog> implements OnInit, OnDestroy {
  public paperlessStepLabel: string;
  public agreementsStepLabel: string;
  public confirmationStepLabel: string;
  esignAccepted: boolean;
  termsAccepted: boolean;
  esignExpanded: boolean;
  termsExpanded: boolean;
  isInPaperlessEnrollMode: boolean;
  showLegalAgreementsStep: boolean;
  showPaperlessStep: boolean;
  profileTermsExpanded: boolean;
  profileTermsAccepted: boolean;

  constructor(@Inject(DOCUMENT) document,
    injector: Injector,
    private moduleOnBoardingHelperService: ModuleOnBoardingHelperService,
    private userService: UserService,
    private routerService: RouterService,
    private preferenceCenterService: PreferenceCenterService) {
    super(injector, document);
    this.agreementsStepLabel = Constants.AgreementsStepName;
    this.paperlessStepLabel = Constants.PaperlessStepName;
    this.confirmationStepLabel = Constants.ConfirmationStepName;
    this.isInPaperlessEnrollMode = true;
    this.showLegalAgreementsStep = false;
    this.showPaperlessStep = false;
    this.termsExpanded = false;
    this.esignExpanded = false;
    this.profileTermsExpanded = false;
  }

  @ViewChild('onboardingDialogStepper') stepper: MatStepper;
  hasError: boolean;
  
   ngOnInit() {
    this.esignAccepted =this.moduleOnBoardingHelperService.isEsignAccepted();
    this.termsAccepted = this.moduleOnBoardingHelperService.isTermsAccepted();
    this.profileTermsAccepted = this.moduleOnBoardingHelperService.isProfileTermsAccepted();
    this.loadServicingPreferences();
    this.setStepsVisibility();
  }

  public setStepsVisibility(): void {
    this.showLegalAgreementsStep = !this.moduleOnBoardingHelperService.isEsignAccepted() || !this.moduleOnBoardingHelperService.isTermsAccepted() || !this.moduleOnBoardingHelperService.isProfileTermsAccepted();
    this.showPaperlessStep = this.moduleOnBoardingHelperService.getPaperlessNotEnrolledAccount() !== null;
  }

  public setError($event) {
    this.hasError = $event;
  }

  public setMode($event) {
    this.isInPaperlessEnrollMode = $event;
  }

  public setExpanded(expanded: any) {
    this.esignExpanded = expanded.esignExpanded;
    this.termsExpanded = expanded.termsExpanded;
    this.profileTermsExpanded = expanded.profileTermsExpanded;
  }

  public hasOnlyOneAgreementShownAndExpanded(): boolean {
    let condition = (this.esignAccepted && !this.termsAccepted && this.termsExpanded) ||
      (this.termsAccepted && !this.esignAccepted && this.esignExpanded);
    return condition;
  }

  private loadServicingPreferences() {
    let customerNumber: number = this.userService.getCustomerNumber();
    let accountNumbers = this.userService.getAccountNumbers();
    this.preferenceCenterService.getServicingPreferences(customerNumber, accountNumbers, false, true)
      .subscribe(response => {
        if (response !== undefined && response !== null && response.error) {
          this.routerService.navigateToErrorPage();
        }
      });
  }
  public applyHeightContainer() {
    let esignAccepted = this.moduleOnBoardingHelperService.isEsignAccepted();
    let termsAccepted = this.moduleOnBoardingHelperService.isTermsAccepted();
    let profileTermsAccepted = this.moduleOnBoardingHelperService.isProfileTermsAccepted();
    if ((esignAccepted === false && termsAccepted === true) || (esignAccepted === true && termsAccepted === false)) {
      return true;
    }
    else {
      return false;
    }
  }

  
}
