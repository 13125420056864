import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from '../../shared/_helper-services/user.service';
import { IAppState } from "../../shared/store/app.store";
import { FeatureToggleService } from './feature-toggle.service';

export const FeatureToggleGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const featureToggleService = inject(FeatureToggleService);
  const userService = inject(UserService);
  featureToggleService.setLanguage(state);
  if (featureToggleService.isRouteEnabled(state.url)) {
    return true;
  }
  else {
    let currentUrl = state.url;
    let origUrl = state.root.queryParams.origUrl;
    (userService.isIniOSMode()) ? featureToggleService.handleConnectedAppWebViewSso(currentUrl, origUrl) : featureToggleService.handleDisabledRoute(currentUrl, origUrl);
    return false;
  }
}

