<form>
    <div class="modal-consent">
        <h3>{{"ngw.shared.user-consent-dialog.title" | translate}}</h3>
        <p>{{"ngw.shared.user-consent-dialog.warning" | translate}}</p>
        <div class="action-buttons">
            <button class="btn btn-secondary" (click)="cancelUserAction(); $event.preventDefault();" type="submit" setFocus analytics-event="body.cancel-user-consent.cancel-user-consent.button">
                {{"ngw.shared.user-consent-dialog.btn-cancel" | translate}}</button>
            <button class="btn btn-primary" (click)="confirmUserAction(); $event.preventDefault();">{{"ngw.shared.user-consent-dialog.btn-ok" | translate}}</button>
        </div>
    </div>
</form>



