import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';
import { tassign } from 'tassign';


@Injectable()
export class TermsAgreementCheckedActions
{
  static SET_TERMS_AGREEMENT_CHECKED: string = 'SET_TERMS_AGREEMENT_CHECKED';

   

  setTermsAgreementChecked(termsAgreementChecked: boolean): FSA<string, boolean, null>{
        return {
          type: TermsAgreementCheckedActions.SET_TERMS_AGREEMENT_CHECKED,
          payload: termsAgreementChecked,
            meta: null
        };
    }

}

@Injectable()
export class TermsAgreementCheckedReducers
{
  static TermsAgreementCheckedReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
          case TermsAgreementCheckedActions.SET_TERMS_AGREEMENT_CHECKED:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
