import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { RecurringPaymentEntry } from '../../../_models/recurring-payment/recurring-payment-entry.model';

@Injectable()
export class EasypaySchedulesActions
{
    static PUSH_EASYPAY_SCHEDULE: string = 'PUSH_EASYPAY_SCHEDULE';
    static CLEAR_EASYPAY_SCHEDULES: string = 'CLEAR_EASYPAY_SCHEDULES';

    pushEasypaySchedule(easypaySchedule: RecurringPaymentEntry): FSA<string, any, null>{
    return {
      type: EasypaySchedulesActions.PUSH_EASYPAY_SCHEDULE,
      payload: easypaySchedule,
      meta: null
    };
    }

    clearEasypaySchedules(): FSA<string, any, null> {
      return {
          type: EasypaySchedulesActions.CLEAR_EASYPAY_SCHEDULES,
          payload: null,
          meta: null
      };
  }

 
}

@Injectable()
export class EasypaySchedulesReducers {
    static EasypaySchedulesReducer: Reducer<RecurringPaymentEntry[]> = (state: RecurringPaymentEntry[] = [], action: FSA<string, any, void>) => {
    switch(action.type){
        case EasypaySchedulesActions.PUSH_EASYPAY_SCHEDULE:
            return state = [...state, action.payload];
        case EasypaySchedulesActions.CLEAR_EASYPAY_SCHEDULES:
            return state = [];
        default:
          return state;
    }
  }
}
