import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, interval, of, timer } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { ChangePasswordErrorType, ChangePasswordStatus } from '../_models/change-password-status.model';
import { ForgotPasswordChangeErrorType, ForgotPasswordChangeStatus } from '../_models/forgot-password-change-status.model';
import { GenerateFsTokenStatus, GenerateFsTokenStatusErrorType } from '../_models/generate-fs-token-status.model';
import { GenerateMultiFactorTokenStatus, GenerateMultiFactorTokenStatusErrorType } from '../_models/generate-multi-factor-token-status.model';
import { GenerateSecondFactorTokenStatus, GenerateSecondFactorTokenStatusErrorType } from '../_models/generate-second-factor-token-status.model';
import { TermsDoc } from '../_models/termsdoc.model';
import { FSTokenRequest, FSTokenServiceClient } from '../core/fs-token-api';
import {
  AuthServiceClient,
  ChangePasswordRequest,
  EnableTermsAcceptedRequest,
  EnableTermsAcceptedRequestLanguage,
  GenerateMultiFactorTokenRequest,
  GenerateSecondFactorTokenRequest,
  GenerateSecondFactorTokenRequestLanguage,
  GetAuthTokenInfoRequest,
  GetAuthTokenInfoRequestTokenType,
  GetTermsPolicyDocumentRequest,
  GetTermsPolicyDocumentResponse,
  GetUserInformationRequest,
  GetUserInformationResponse,
  MultiFactorTokenRequestChannel,
  MultiFactorTokenRequestLanguage,
  ProviderKey,
  RefreshBearerTokenRequest,
  ResetPasswordRequest,
  SendResetPasswordEmailRequest,
  SiteKey,
  SwaggerException,
  UserKey,
  ValidateFsTokenRequest,
  ValidateFsTokenResponse,
  ValidateReCaptchaRequest
} from '../core/gateway-api';
import { FaultCodes } from '../shared/FaultCodes';
import { IdleService } from '../shared/_helper-services/idle.service';
import { LanguageService } from '../shared/_helper-services/language.service';
import { StorageHelperService } from '../shared/_helper-services/storage-helper.service';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from "../shared/store/app.store";
import { ConnectedAppSessionIsExpiredActions } from '../shared/store/reducers/connected-app-session-is-expired.reducer';
import { FSTokenErrorHandler } from './../shared/_errorhandler/gobal-error-handler';
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class AuthService {
  private getAuthTokenInfoRequest: GetAuthTokenInfoRequest;
  private resetPasswordRequest: ResetPasswordRequest;
  private sendResendPasswordEmailRequest: SendResetPasswordEmailRequest;
  private subscriptionToHandleToken: any;
  private subscriptionToLogout: any;
  private changePasswordStatus: ChangePasswordStatus;
  private changePasswordRequest: ChangePasswordRequest;
  public userTwoFactoredSuccessfully: EventEmitter<any> = new EventEmitter<any>();
  public storeAppConfig: ApplicationConfig;
  constructor(private authServiceClient: AuthServiceClient,
    private userService: UserService,
    private idleService: IdleService,
    private store: Store<IAppState>,
    private fsTokenErrorhandler: FSTokenErrorHandler,
    private languageService: LanguageService,
    private connestedAppSessionIsExpiredActions: ConnectedAppSessionIsExpiredActions,
    private fsTokenServiceClient: FSTokenServiceClient,
    private storageHelperService: StorageHelperService) { }

  //terms
  getTermsHtml(): Observable<TermsDoc> {
    let request = new GetTermsPolicyDocumentRequest();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    request.format = this.storeAppConfig.POLICY_FORMAT;
    request.policyId = this.storeAppConfig.POLICY_ID;
    request.language = this.languageService.getLanguageFromStore().toUpperCase();
    let provideKey = new ProviderKey();
    provideKey.providerId = this.storeAppConfig.CLIENT_ID;
    request.providerKey = provideKey;
    return this.authServiceClient.getTermsPolicyDocument(request)
      .pipe(mergeMap((response) => { return this.afterPostGetPolicyDocumentSuccess(response); })
        , catchError((error: any) => {
          return this.afterPostGetPolicyDocumentFailure(error);
        }));
  }

  getProfileTermsHtml(): Observable<TermsDoc> {
    let request = new GetTermsPolicyDocumentRequest();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig= x);
    request.format = this.storeAppConfig.POLICY_FORMAT;
    request.language = this.storeAppConfig.POLICY_LANGUAGE;
    request.policyId = this.storeAppConfig.PROFILE_TC_POLICY_NAME;
    let provideKey = new ProviderKey();
    provideKey.providerId = this.storeAppConfig.CLIENT_ID;
    request.providerKey = provideKey;
    request.usePolicyId = true;
    return this.authServiceClient.getTermsPolicyDocument(request)
      .pipe(mergeMap((response) => { return this.afterPostGetPolicyDocumentSuccess(response); })
        , catchError((error: any) => {
          return this.afterPostGetPolicyDocumentFailure(error);
        }));
  }

  acceptTermsAndConditions(gcid: string, acceptProfileTC: boolean = false): Observable<boolean> {
    //can be called after registration or login
    let userGcid = new UserKey();
    let request = new EnableTermsAcceptedRequest();
    let providerKey = new ProviderKey();
    userGcid.userId = gcid;
    request.userKey = userGcid;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    request.providerKey = providerKey;
    request.bearerToken = this.userService.getUserBearerToken();
    let policyLanguage: string = this.storeAppConfig.POLICY_LANGUAGE;
    if (policyLanguage == EnableTermsAcceptedRequestLanguage.EN.toString()) {
      request.language = EnableTermsAcceptedRequestLanguage.EN;
    }
    else {
      request.language = EnableTermsAcceptedRequestLanguage.FR;
    }
    if (acceptProfileTC) {
      request.policyName = this.storeAppConfig.PROFILE_TC_POLICY_NAME;
    }
    return this.authServiceClient.enableTermsAccepted(request).pipe(mergeMap((response) => { return of(true); })
      , catchError((error: any) => { return of(false); }));
  }

  private afterPostGetPolicyDocumentSuccess(response: any) {
    let doc = new TermsDoc();
    let getTermsPolicyDocumentResponse = new GetTermsPolicyDocumentResponse();

    if (response) {
      getTermsPolicyDocumentResponse = response;
      doc.success = true;
      doc.htmlDocument = getTermsPolicyDocumentResponse.policyDocument;
    } else {
      doc.success = false;
    }

    return of(doc);
  }

  private afterPostGetPolicyDocumentFailure(error: any) {
    let doc = new TermsDoc();
    doc.success = false;
    return of(doc);
  }

  public getTokenStatus(token: string): Observable<boolean> {
    this.getAuthTokenInfoRequest = new GetAuthTokenInfoRequest();
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    this.getAuthTokenInfoRequest.providerKey = providerKey;
    this.getAuthTokenInfoRequest.token = token;
    this.getAuthTokenInfoRequest.tokenType = GetAuthTokenInfoRequestTokenType.RESET;
    return this.authServiceClient.getAuthTokenInfo(this.getAuthTokenInfoRequest)
      .pipe(mergeMap((response) => { return this.afterTokenStatusSuccess(response); })
        , catchError((error: any) => {
          return this.afterTokenStatusFailure(error);
        }));
  }


  public resetPassword(newPassword: string, token: string): Observable<ForgotPasswordChangeStatus> {
    this.resetPasswordRequest = new ResetPasswordRequest();
    this.resetPasswordRequest.password = newPassword;
    this.resetPasswordRequest.token = token;
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    this.resetPasswordRequest.providerKey = providerKey;
    let siteKey = new SiteKey();
    siteKey.siteId = this.storeAppConfig.SITEID;
    this.resetPasswordRequest.siteKey = siteKey;
    return this.authServiceClient.resetPassword(this.resetPasswordRequest)
      .pipe(mergeMap((response) => { return this.afterChangePasswordSuccess(response); })
        , catchError((error: any) => {
          return this.afterChangePasswordFailure(error);
        }));
  }

  public resetPasswordEmail(emailAddress: string): Observable<boolean> {
    this.sendResendPasswordEmailRequest = new SendResetPasswordEmailRequest();
    this.sendResendPasswordEmailRequest.username = emailAddress;
    let siteKey = new SiteKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    siteKey.siteId = this.storeAppConfig.SITEID;
    this.sendResendPasswordEmailRequest.siteKey = siteKey;
    this.sendResendPasswordEmailRequest.postalCode = null;
    let providerKey = new ProviderKey();
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    this.sendResendPasswordEmailRequest.providerKey = providerKey;
    return this.authServiceClient.sendResetPasswordEmail(this.sendResendPasswordEmailRequest)
      .pipe(mergeMap((response) => { return of(true); })
        , catchError((error: any) => {
          return of(false);
        }));
  }

  private afterChangePasswordSuccess(result: any): Observable<ForgotPasswordChangeStatus> {
    let forgotPasswordChangeStatus = new ForgotPasswordChangeStatus();
    forgotPasswordChangeStatus.error = false;
    return of(forgotPasswordChangeStatus);
  }

  private afterChangePasswordFailure(error: SwaggerException): Observable<ForgotPasswordChangeStatus> {
    let forgotPasswordChangeStatus = new ForgotPasswordChangeStatus();
    forgotPasswordChangeStatus.error = true;

    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_InvalidToken_V201109_InvalidTokenFault:
          {
            forgotPasswordChangeStatus.errortype = ForgotPasswordChangeErrorType.InvalidTokenFault;
            break;
          }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_AuthenticationFailed_V201109_AuthenticationFailedFault:
          {
            forgotPasswordChangeStatus.errortype = ForgotPasswordChangeErrorType.AuthenticationFailedFault;
            break;
          }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserAccountPasswordLocked_V201109_UserAccountPasswordLockedFault:
          {
            forgotPasswordChangeStatus.errortype = ForgotPasswordChangeErrorType.UserAccountPasswordLockedFault;
            break;
          }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_InvalidPasswordFault:
          {
            forgotPasswordChangeStatus.errortype = ForgotPasswordChangeErrorType.InvalidPasswordFault;
            break;
          }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserNotFound_V201109_UserNotFoundFault:
          {
            forgotPasswordChangeStatus.errortype = ForgotPasswordChangeErrorType.UserNotFoundFault;
            break;
          }
      }
    } else {
      forgotPasswordChangeStatus.errortype = ForgotPasswordChangeErrorType.ForgotPasswordChangeFailed;
    }
    return of(forgotPasswordChangeStatus);
  }

  private afterTokenStatusSuccess(result: any): Observable<boolean> {
    let isExpired = result.expired;
    return of(isExpired);
  }

  private afterTokenStatusFailure(error: any): Observable<boolean> {
    let isExpired = true;
    return of(isExpired);
  }

  public generateSecondFactorToken(gcid: string): Observable<GenerateSecondFactorTokenStatus> {
    let generateSecondFactorTokenRequest = new GenerateSecondFactorTokenRequest();
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    generateSecondFactorTokenRequest.gCID = gcid;
    generateSecondFactorTokenRequest.providerKey = providerKey;
    generateSecondFactorTokenRequest.language = GenerateSecondFactorTokenRequestLanguage[this.languageService.getCurrentLanguage().toUpperCase()];

    return this.authServiceClient.generateSecondFactorToken(generateSecondFactorTokenRequest).
      pipe(mergeMap((response) => { return this.afterGenerateSecondFactorTokenRequestPostSuccess(response); })
        , catchError((error: any) => { return this.afterGenerateSecondFactorTokenRequestPostFailure(error); }));

  }


  private afterGenerateSecondFactorTokenRequestPostSuccess(result: any): Observable<GenerateSecondFactorTokenStatus> {
    let generateSecondFactorTokenStatus = new GenerateSecondFactorTokenStatus();
    if (result && result.transactionID) {
      generateSecondFactorTokenStatus.transactionId = result.transactionID;
    }
    else {
      generateSecondFactorTokenStatus.error = true;
      generateSecondFactorTokenStatus.errortype = GenerateSecondFactorTokenStatusErrorType.GenericServerError;
    }
    return of(generateSecondFactorTokenStatus);
  }

  private afterGenerateSecondFactorTokenRequestPostFailure(error: any): Observable<GenerateSecondFactorTokenStatus> {
    let generateSecondFactorToken = new GenerateSecondFactorTokenStatus();
    generateSecondFactorToken.error = true;
    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_TokenUnexpired_V201109_TokenUnexpiredFault: {
          generateSecondFactorToken.errortype = GenerateSecondFactorTokenStatusErrorType.TokenUnexpired;
          break;
        }
        default: {
          generateSecondFactorToken.errortype = GenerateSecondFactorTokenStatusErrorType.GenericServerError;
          break;
        }
      }
    }
    else {
      //generic server error
      generateSecondFactorToken.errortype = GenerateSecondFactorTokenStatusErrorType.GenericServerError;
    }


    return of(generateSecondFactorToken);
  }

  public generateFsToken(transactionId: string, tanCode: string): Observable<GenerateFsTokenStatus> {
    // Generate the FSToken along with Bearer Token
    let request = new FSTokenRequest();
    request.grant_type = "grant_type=password&username=" + transactionId + "&password=" + tanCode;
    return this.fsTokenServiceClient.getFSToken(request).pipe(mergeMap((response) => { return this.getFsTokenSuccess(response); })
      , catchError((error: any) => { return this.getFsTokenError(error); }));
  }

  private getFsTokenSuccess(result: any): Observable<GenerateFsTokenStatus> {
    this.userService.setFs(result.access_token);
    let generateFsTokenStatus = new GenerateFsTokenStatus();
    generateFsTokenStatus.error = false;

    // Raise an event post fs token generation
    this.userTwoFactoredSuccessfully.emit(true);
    return of(generateFsTokenStatus);
  }

  private getCookieExpirationTime(): Date {
    let timeNow = new Date();
    let twoFactorAuthCookie: any;
    this.store.select(state => state.EnvironmentConfig.TWO_FACTOR_AUTH_COOKIE_EXPIRATION_DAYS).subscribe(x => twoFactorAuthCookie = x);
    let timeAfterMins = new Date(timeNow.setMinutes(timeNow.getMinutes() + twoFactorAuthCookie * 24 * 60));
    return timeAfterMins;
  };

  public generateFsTokenCookie(deviceToken: string) {
    const gcid = this.userService.getGcid();
    let cookieKey = this.storageHelperService.getFSCookieKey(gcid);
    let cookieExpiration = this.getCookieExpirationTime();
    this.storageHelperService.setCookie(cookieKey, deviceToken, cookieExpiration);
  }

  private getFsTokenError(error: any): Observable<GenerateFsTokenStatus> {
    this.userService.setFs("");
    let generateFsTokenStatus = new GenerateFsTokenStatus();
    generateFsTokenStatus.error = true;
    generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.GenericServerError;
    if (error.response != undefined) {
      let response = JSON.parse(error.response);
      let faultType = response ? response.error : null;
      if (faultType) {
        switch (faultType) {
          case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_InvalidToken_V201109_InvalidTokenFault: {
            generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.InvalidToken;
            break;
          }
          case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_TokenExpired_V201109_TokenExpiredFault: {
            generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.TokenExpired;
            break;
          }
          case FaultCodes.CLIENT_AUTHENTICATION_FAILED: {
            generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.InvalidToken;
            break;
          }
          case FaultCodes.ERROR_OCCURRED: {
            generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.InvalidToken;
            break;
          }
          case FaultCodes.INVALID_CREDENTIALS: {
            generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.InvalidToken;
            break;
          }
          default: {
            generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.GenericServerError;
            break;
          }
        }
      }
      else {
        //generic server error
        generateFsTokenStatus.errortype = GenerateFsTokenStatusErrorType.GenericServerError;
      }
    }
    return of(generateFsTokenStatus);
  }


  //refresh bearer token
  public handleTokenExpiryAfterInterval() {
    //todo: to be removed when connected app is integrated
    this.resetExistingSubs();
    let refreshBearerTokenInterval: any;
    this.store.select(state => state.EnvironmentConfig.REFRESH_BEARER_TOKEN_INTERVAL_IN_SEC).subscribe(x => refreshBearerTokenInterval = x);
    let intervalTimeSec = this.userService.getUserBearerTokenExpireTime() - refreshBearerTokenInterval; // 55 minutes
    if (intervalTimeSec > 0) {
      let intervalTimeMs = intervalTimeSec * 1000;
      this.subscriptionToHandleToken = interval(intervalTimeMs).subscribe(() => this.handleTokenExpiry())
    }
  }

  private resetExistingSubs() {
    if (this.subscriptionToHandleToken) {
      this.subscriptionToHandleToken.unsubscribe();
    }
    if (this.subscriptionToLogout) {
      this.subscriptionToLogout.unsubscribe();
    }
  }

  private handleTokenExpiry() {
    if (!this.userService.isInConnectedAppMode()) {
      this.refreshBearerToken();
    }
    // For Connected App integration,
    // there won't be refresh bearer token and session inactivity warning should stop
    else {
      this.stopIdleAndLogoutAfterInterval()

    }
  }

  public refreshBearerToken() {
    if (this.userService.isAuthenticated()) {
      let refreshBearerTokenRequest = new RefreshBearerTokenRequest();
      let providerKey = new ProviderKey();
      this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
      providerKey.providerId = this.storeAppConfig.CLIENT_ID;

      refreshBearerTokenRequest.gCID = this.userService.getGcid();
      refreshBearerTokenRequest.providerKey = providerKey;
      refreshBearerTokenRequest.refreshToken = this.userService.getUserRefreshToken();

      this.authServiceClient.refreshBearerToken(refreshBearerTokenRequest)
        .subscribe(response => { this.postRefreshToken(response); },
          error => { this.refreshTokenPostFailure(error); });
    }
    else {
      this.subscriptionToHandleToken.unsubscribe();
    }
  }

  private stopIdleAndLogoutAfterInterval() {
    if (this.userService.isAuthenticated()) {
      this.idleService.stopIdle();
      let intervalTimeForLogoutMs: number;
      this.store.select(state => state.EnvironmentConfig.LOGOUT_FOR_BEARER_TOKEN_EXPIRY_INTERVAL_IN_MS).subscribe(x => intervalTimeForLogoutMs = x);
      this.subscriptionToLogout = timer(intervalTimeForLogoutMs).subscribe(() => this.logoutUser());
    }
  }

  private logoutUser() {
    this.store.dispatch(this.connestedAppSessionIsExpiredActions.setConnectedAppSessionIsExpired(true));
    this.resetExistingSubs();
    this.userService.logout();
  }

  private postRefreshToken(result: any) {
    this.userService.resetBearerToken(result.accessToken, result.expiresIn, result.refreshToken);
  }

  private refreshTokenPostFailure(error: any) {
    this.logoutUser();
  }


  //change password
  public changePassword(currentPassword: string, newPassword: string): Observable<ChangePasswordStatus> {

    this.changePasswordRequest = new ChangePasswordRequest();

    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    let siteKey = new SiteKey();
    siteKey.siteId = this.storeAppConfig.SITEID;
    let userKey = new UserKey();
    userKey.userId = this.userService.getUserName();

    this.changePasswordRequest.username = this.userService.getUserName();
    this.changePasswordRequest.gCID = this.userService.getGcid();
    this.changePasswordRequest.password = newPassword;
    this.changePasswordRequest.currentPassword = currentPassword;
    this.changePasswordRequest.siteKey = siteKey;
    this.changePasswordRequest.userKey = userKey;
    this.changePasswordRequest.providerKey = providerKey;

    this.changePasswordStatus = new ChangePasswordStatus();

    return this.authServiceClient.changePassword(this.changePasswordRequest).
      pipe(mergeMap((response) => { return this.afterChangePasswordPostSuccess(response); })
        , catchError((error: any) => { return this.afterChangePasswordPostFailure(error); }));
  }

  private afterChangePasswordPostSuccess(result: any): Observable<ChangePasswordStatus> {
    this.changePasswordStatus.error = false;
    return of(this.changePasswordStatus);
  }

  private afterChangePasswordPostFailure(error: any): Observable<ChangePasswordStatus> {
    this.changePasswordStatus.error = true;

    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserNotFound_V201109_UserNotFoundFault: {
          this.changePasswordStatus.errortype = ChangePasswordErrorType.UserNotFound;
          break;
        }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_AuthenticationFailed_V201109_AuthenticationFailedFault: {
          this.changePasswordStatus.errortype = ChangePasswordErrorType.AuthenticationFailed;
          break;
        }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserAccountPasswordLocked_V201109_UserAccountPasswordLockedFault: {
          this.changePasswordStatus.errortype = ChangePasswordErrorType.UserAccountPasswordLocked;
          break;
        }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_InvalidClientI_V201112_InvalidClientIdFault: {
          this.changePasswordStatus.errortype = ChangePasswordErrorType.InvalidClientId;
          break;
        }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_InvalidPasswordFault: {
          this.changePasswordStatus.errortype = ChangePasswordErrorType.InvalidPassword;
          break;
        }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_DataValidation_V201112_DataValidationFault: {
          this.changePasswordStatus.errortype = ChangePasswordErrorType.DataValidation;
          break;
        }
        default: {
          this.changePasswordStatus.errortype = ChangePasswordErrorType.AuthenticationFailed;
          break;
        }
      }
    }
    else {
      //generic server error
      this.changePasswordStatus.errortype = ChangePasswordErrorType.GenericServerError;
    }

    return of(this.changePasswordStatus);
  }

  public validateFSToken(gcid: string, fsToken: string): Observable<ValidateFsTokenResponse> {
    // Validate the FS token
    let request = new ValidateFsTokenRequest();
    request.fstoken = fsToken;
    request.gCID = gcid;
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    request.providerKey = providerKey;
    return this.authServiceClient.validateFsToken(request).pipe(mergeMap((response) => { return this.validateFsTokenSuccess(response); })
      , catchError((error: any) => { return this.validateFsTokenError(error); }));
  }

  private validateFsTokenSuccess(result: any): Observable<ValidateFsTokenResponse> {
    let validateFsTokenResponse = new ValidateFsTokenResponse();
    validateFsTokenResponse.valid = true;
    return of(validateFsTokenResponse);
  }

  private validateFsTokenError(error: any): Observable<ValidateFsTokenResponse> {
    let validateFsTokenResponse = new ValidateFsTokenResponse();
    validateFsTokenResponse.valid = false;
    this.fsTokenErrorhandler.handleFSTokenError(error);
    return of(validateFsTokenResponse);
  }

  public validateCaptcha(token: string): Observable<boolean> {
    //Captch validation logic
    let captchRequest = new ValidateReCaptchaRequest();
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    captchRequest.providerKey = providerKey;
    captchRequest.token = token;
    return this.authServiceClient.validateReCaptcha(captchRequest).pipe(mergeMap((response) => { return of(response.success); }))
      .pipe(catchError((error: any) => { return of(false); }));
  }

  public getUserInformation(): Observable<GetUserInformationResponse> {
    let request = new GetUserInformationRequest();
    request.gCID = this.userService.getGcid();
    let provideKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    provideKey.providerId = this.storeAppConfig.CLIENT_ID;
    request.providerKey = provideKey;

    return this.authServiceClient.getUserInformation(request)
      .pipe(mergeMap((response) => { return this.afterGetUserInformationSuccess(response); }), catchError((error: any) => {
        return this.afterGetUserInformationFailure(error);
      }));
  }

  public generateFsTokenMfa(username: string, password: string, phone: string, channel: string, transactionId: string): Observable<GenerateFsTokenStatus> {
    // Generate the FSToken along with Bearer Token
    const request = new FSTokenRequest();
    phone = phone.trim();
    phone = phone === '' ? phone :
      `+1(${phone.substring(0, 3)})${phone.substring(3, 6)}-${phone.substring(6)}`;
    phone = encodeURIComponent(phone);
    let clientId: string;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    clientId = this.storeAppConfig.CLIENT_ID;
    let siteId: number = this.storeAppConfig.SITEID;
    username = encodeURIComponent(username.trim());
    password = encodeURIComponent(password.trim());
    request.grant_type = `grant_type=password&clientId=${clientId}&username=${username}&password=${password}&phoneno=${phone}&channel=${channel}&siteId=${siteId}&transId=${transactionId}`;
    return this.fsTokenServiceClient.getFSToken(request).pipe(mergeMap((response) => this.getFsTokenSuccess(response))
      , catchError((error: any) => this.getFsTokenError(error)));
  }

  public generateMultiFactorToken(gcid: string, phone: string, email: string, channel: MultiFactorTokenRequestChannel): Observable<GenerateMultiFactorTokenStatus> {
    let generateMultiFactorTokenRequest = new GenerateMultiFactorTokenRequest();
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;

    generateMultiFactorTokenRequest.gCID = gcid;
    generateMultiFactorTokenRequest.siteKey = new SiteKey();
    generateMultiFactorTokenRequest.siteKey.siteId = this.storeAppConfig.SITEID;
    generateMultiFactorTokenRequest.transactionId = uuidv4();
    generateMultiFactorTokenRequest.providerKey = providerKey;
    generateMultiFactorTokenRequest.language = MultiFactorTokenRequestLanguage[this.languageService.getCurrentLanguage().toUpperCase()];
    generateMultiFactorTokenRequest.emailAddress = email.trim();
    phone = phone.trim();
    phone = phone === '' ? phone : `+1(${phone.substring(0, 3)})${phone.substring(3, 6)}-${phone.substring(6)}`;
    generateMultiFactorTokenRequest.phoneNumber = phone;
    generateMultiFactorTokenRequest.channel = channel;

    return this.authServiceClient.generateMultiFactorToken(generateMultiFactorTokenRequest).pipe(
      mergeMap((response) => { return this.afterGenerateMultiFactorTokenRequestPostSuccess(response, generateMultiFactorTokenRequest.transactionId); }),
      catchError((error: any) => { return this.afterGenerateMultiFactorTokenRequestPostFailure(error); }));

  }

  private afterGenerateMultiFactorTokenRequestPostSuccess(result: any, transactionId: string): Observable<GenerateMultiFactorTokenStatus> {
    let generateMultiFactorTokenStatus = new GenerateMultiFactorTokenStatus();
    generateMultiFactorTokenStatus.transactionId = transactionId;
    return of(generateMultiFactorTokenStatus);
  }

  private afterGenerateMultiFactorTokenRequestPostFailure(error: any): Observable<GenerateMultiFactorTokenStatus> {
    let generateMultiFactorTokenStatus = new GenerateMultiFactorTokenStatus();
    generateMultiFactorTokenStatus.error = true;
    generateMultiFactorTokenStatus.errortype = GenerateMultiFactorTokenStatusErrorType.MFAFailed;
    return of(generateMultiFactorTokenStatus);
  }

  private afterGetUserInformationSuccess(result: any): Observable<GetUserInformationResponse> {
    return of(result);
  }

  private afterGetUserInformationFailure(error: any): Observable<GetUserInformationResponse> {
    return of(error);
  }
}
