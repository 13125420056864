import { Component, OnInit, OnDestroy, Renderer2, Inject, Input, Injector, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { MidtermMileageAdjustmentService } from '../../../../../../_web-services/midterm-mileage-adjustment.service';
import { SelectMiles } from '../../../../../../_models/map/select-miles.model';
import { SelectMilesActions } from '../../../../../../shared/store/reducers/select-miles.reducers';
import { Observable, forkJoin } from 'rxjs';
import { MidtermMileageAdjustment } from '../../../../../../_models/map/midterm-mileage-adjustment.model';
import * as _ from 'lodash-es';
import { FaultCodes } from '../../../../../../shared/FaultCodes';
import { MidtermMileageAdjustmentActions } from '../../../../../../shared/store/reducers/midterm-mileage-adjustment.reducers';
import { ValidatorService } from '../../../../../../_validators/validator.service';
import { IsInMAPFlowActions } from '../../../../../../shared/store/reducers/is-in-map-flow.reducer';
import { ContractAccountDetailDTO } from '../../../../../../core/gateway-api';
import { WindowService } from '../../../../../../shared/_helper-services/window.service';
import { AccountInfoService } from '../../../../../../shared/_helper-services/account-info.service';

@Component({
  selector: 'app-current-miles',
  templateUrl: './current-miles.component.html',
  styleUrls: ['./current-miles.component.scss']
})


export class CurrentMilesComponent extends BaseApplicationComponent<CurrentMilesComponent> implements OnInit, OnDestroy {

  currentMileageGroup: UntypedFormGroup;
  selectMilesData: SelectMiles;
  midtermMileageAdjustment: MidtermMileageAdjustment;
  globalTechError: boolean;
  @Input() stepper: any;
  errorMessage: string;
  contractDetail: ContractAccountDetailDTO;
  @ViewChild('currentMilesTopDiv', { read: ElementRef }) currentMilesTopDiv: any;

  constructor(private translateService: TranslateService, fb: UntypedFormBuilder,
    private midtermMileageAdjustmentService: MidtermMileageAdjustmentService,
    private selectMilesActions: SelectMilesActions,
    private midtermMileageAdjustmentActions: MidtermMileageAdjustmentActions,
    private validatorService: ValidatorService,
    private windowService: WindowService,
    private accountInfoService: AccountInfoService,
    private isInMAPFlowActions: IsInMAPFlowActions,
    injector: Injector, @Inject(DOCUMENT) document) {
    super(injector, document);
    this.currentMileageGroup = fb.group({
      currentMileage: ''
    });
  }

  ngOnInit() {
    this.store.dispatch(this.selectMilesActions.clearSelectMiles());
    this.store.dispatch(this.midtermMileageAdjustmentActions.clearMidtermMileageAdjustment());
    this.selectMilesData = new SelectMiles();
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    let vehicleShadowData = this.accountInfoService.getCachedVehicleShadowData(this.contractDetail);
    if (vehicleShadowData) {
      this.currentMileageGroup.controls.currentMileage.setValue(vehicleShadowData.mileage);
    }
    this.midtermMileageAdjustmentService.getMidtermMileageAdjustment(this.contractDetail.accountNumber).subscribe(response => {
      this.postGetMidtermMileage(response);
    });
  }

  ngOnDestroy() {
  }

  isCurrentMilageValid(): boolean {
    return ((this.currentMileageGroup.controls.currentMileage.value == null || this.currentMileageGroup.controls.currentMileage.value < 1 || this.validatorService.showMilesError(this.currentMileageGroup.controls.currentMileage)));
  }

  getMileageCalculation(): void {
    super.setDataLoadedOnRequest(false);
    this.midtermMileageAdjustmentService.getMileageCalculation(this.contractDetail.accountNumber, this.currentMileageGroup.controls.currentMileage.value).subscribe(response => {
      this.postGetMileageCalculation(response);
    });
  }

  private postGetMileageCalculation(response: any) {
    this.selectMilesData = new SelectMiles();
    this.navigateToNextStep(response);
    this.selectMilesData.currentMiles = this.currentMileageGroup && this.currentMileageGroup.controls && this.currentMileageGroup.controls.currentMileage
      ? this.currentMileageGroup.controls.currentMileage.value
      : 0;
    this.store.dispatch(this.selectMilesActions.setSelectMiles(this.selectMilesData));
    super.setDataLoadedOnRequest(true);
    this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(true));
  }

  private navigateToNextStep(response: any): void {
    this.globalTechError = false;
    if (response && response.error) {
      if (response.faultType != FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageMinimumFault &&
        response.faultType != FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageThresholdFault) {
        const errorTranslationKey = "ngw.global.technical-error";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          this.errorMessage = res;
        });
        this.pushErrorEvent(errorTranslationKey);
        this.globalTechError = true;
      }
      else {
        this.selectMilesData.error = response.error;
        this.selectMilesData.faultType = response.faultType;
        this.stepper.selectedIndex = 2;
      }
      this.selectMilesData.recommendedMilesSectionEnabled = false;
    }
    else if (response && response.mileageCalculation) {
      this.selectMilesData.mileageCalculation = response.mileageCalculation;
      this.selectMilesData.recommendedMilesSectionEnabled = true;
      this.stepper.selectedIndex = 3;
    }
  }

  private postGetMidtermMileage(response: MidtermMileageAdjustment) {
    this.store.dispatch(this.midtermMileageAdjustmentActions.setMidtermMileageAdjustment(response));
    this.windowService.scrollDialogToTop(this.currentMilesTopDiv);
  }
}
