<div class="due-date-change dialog">
  <div #dueDateChangeTopDiv id="dueDateChangeTopDiv"></div>
  <div class="due-date-change-container">
    <h1>{{'ngw.due-date-change.title' | translate}}</h1>
    <p>{{'ngw.due-date-change.informational.title' | translate}}</p>
    <div class="general error-container" role="alert" *ngIf="error === true">
      <div *ngIf="hasFault">
        <div class="not-eligible">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span analytics-error="ngw.due-date-change.informational.error-message">{{'ngw.due-date-change.informational.error-message' | translate}}</span>
        </div>
        <p>
          {{'ngw.due-date-change.informational.error-questions' | translate}} <a (click)="navigateToContactUs()">{{'ngw.due-date-change.informational.lnk-contact-us' | translate}}</a>
        </p>
      </div>
      <div *ngIf="!hasFault">
        <em class="error-validation" id="errorMessage" analytics-error="ngw.global.technical-error">{{"ngw.global.technical-error" | translate}}</em>
      </div>
    </div>
    <p *ngIf="error === false">{{'ngw.due-date-change.informational.message-top' | translate}}</p>
    <div *ngIf="error === false">
      <ul>
        <li>
          <p>{{'ngw.due-date-change.informational.message-advise' | translate}}</p>
        </li>
        <li *ngIf="isLeaseAccount">
          <p>{{'ngw.due-date-change.informational.message-note-lease' | translate}}</p>
        </li>
        <li *ngIf="!isLeaseAccount">
          <p>{{'ngw.due-date-change.informational.message-fee-loan' | translate}}</p>
        </li>
        <li>
          <p>{{'ngw.due-date-change.informational.message-payment-date-change' | translate}}</p>
        </li>
        <li>
          <p>{{'ngw.due-date-change.informational.message-already-billed' | translate}}</p>
        </li>
      </ul>
    </div>
    <form>
      <div>
        <div class="action-buttons">
          <button id="btnPrevious" type="button" class="btn" [ngClass]="error ? 'btn-primary' : 'btn-secondary'" (click)="cancelUserAction()" analytics-event="body.cancel-due-date-change.cancel-due-date-change.button">
            {{'ngw.general.btn-cancel' | translate}}</button>
          <button id="btnNext" type="submit" *ngIf="error === false" setFocus class="btn btn-primary" (click)="navigateToDueDateEntry(); $event.preventDefault();">{{'ngw.due-date-change.entry.btn-continue' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
