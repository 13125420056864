import { ElementRef, Injectable, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Validators } from '@angular/forms';
import { Store } from "@ngrx/store"
import { BehaviorSubject, Observable, Subscription, defer, from } from 'rxjs';
import { PaymentCardAppearanceType, PaymentCardService, paymentElementIsReady, paymentElementIsValid, constants as serviceConstants, PaymentCardServiceResponse, PaymentMethodModel } from 'customerdigital-service-lib';
import { AddCardFormModel, AlertModel, AlertStatus, AlertType, constants, DialogDynamicModel, DialogDynamicResponseModel, DialogService as DialogLibraryService, addCardFormInputData, ButtonState, dialogDynConfirmBtnAction, IconTextModel } from 'customerdigital-ui-lib';
import { ValidationRule, ValidationType } from '../../../_shared/_models/validators.model';
import { PaymentHelperService } from "./payment-helper.service";
import { IAppState } from "../store/app.store";
import { UserService } from "./user.service";
import { ValidatorService } from "../../../_shared/_validators/validator.service";
import { CountryCode, FinancialAccountDTOAccountType, PaymentCardServiceClient, SavePaymentMethodRequest, SavePaymentMethodRequestFinancialAccountType, SavePaymentMethodResponse } from '../../core/gateway-api';
import { CustomerFinancialAccountService } from '../../_web-services/customer-financial-account.service'
import { FaultCodes } from "../FaultCodes";
import { PaymentEntry, PaymentSource } from "../../../_shared/_models/payment.model";
import { ObjectService } from '../../shared/_helper-services/object.service';
import * as _ from 'lodash-es';
import { PaymentEntryActions } from "../../shared/store/reducers/payment-entry.reducer";
import { LoggerHelperService } from "./logger-helper.service";
import { DataLoadedActions } from '../store/reducers/dataloaded.reducer';
import { finalize } from "rxjs/operators";
import { ApplicationConfig } from "../../../_shared/_models/application-config";

export const onAddCardBtnSuccess = new BehaviorSubject<boolean>(false);
@Injectable()
export class CardHelperService {
  private readonly addPayCardTranslationKey = "ngw.payment-source.stripe-payment-card.";
  private market: string;
  private addCardDialogRef: any;
  private addCardFormIsReady: boolean;
  private addCardFormIsValid: boolean;
  private addCardFormNameInputIsValid: boolean = true;
  private nameOnCardValidation: ValidationRule[] = [];
  @ViewChild('cardForm') cardForm: ElementRef<HTMLInputElement>;
  public isAddCreditCardSelected: boolean = false;
  public isRetainCheckbox: boolean = false;
  private storeAppConfig: ApplicationConfig;
  private paymentAlertMessage: AlertModel;
  paymentCardData: PaymentMethodModel;
  loadingSub: Subscription;
  isLoading: boolean = true;
  errorMessage: string;
  addCardButtonSub: Subscription;
  isSaveCardChecked: boolean = false;
  objAddCardDialogData: DialogDynamicModel;
  achPaymentSource: PaymentSource;
  isDialog: boolean = false;
  isRetainCard: boolean;

  constructor(private translateService: TranslateService,
    private paymentHelperService: PaymentHelperService,
    private paymentCardService: PaymentCardService,
    private dialogLibraryService: DialogLibraryService,
    private userService: UserService,
    private validatorService: ValidatorService,
    private  store: Store<IAppState>, 
    private paymentCardServiceClient: PaymentCardServiceClient, 
    private customerFinancialAccountService: CustomerFinancialAccountService,
    private objectService: ObjectService,
    private paymentEntryActions: PaymentEntryActions,
    private logErrorService: LoggerHelperService,
    private dataLoadedActions: DataLoadedActions ) {
  }

  get addCardDialogData(): DialogDynamicModel {
    let data: DialogDynamicModel = new DialogDynamicModel();

    let titleTrKey = this.isAddCreditCardSelected ? `${this.addPayCardTranslationKey}title-credit` : `${this.addPayCardTranslationKey}title-debit`;
    let subtitleTrKey = `${this.addPayCardTranslationKey}subtitle`;
    let inputLabelTrKey = `${this.addPayCardTranslationKey}lbl-name`;
    let chkTrKey = `${this.addPayCardTranslationKey}checkbox`;
    let submitBtnTrKey = `${this.addPayCardTranslationKey}btn-submit`;
    let closeBtnTrKey = `${this.addPayCardTranslationKey}btn-close`;
    let securityNoteTrKey = `${this.addPayCardTranslationKey}security-note`;
    let translationKeys = [titleTrKey, subtitleTrKey, inputLabelTrKey, chkTrKey, submitBtnTrKey, closeBtnTrKey, securityNoteTrKey];

    this.translateService.get(translationKeys).subscribe(result => {
      data.title = result[titleTrKey];
      data.body = result[subtitleTrKey];
      data.primaryButton = {
        isPrimary: true,
        text: result[submitBtnTrKey],
        state: ButtonState.Disable
      };
      data.cancelButton = {
        isPrimary: true,
        text: result[closeBtnTrKey],
        ariaLabel: result[closeBtnTrKey]
      };
      if (this.isRetainCheckbox) {
        data.checkBoxes = [
          {
            checkboxLabel: result[chkTrKey],
            checked: this.isSaveCardChecked,
            checkRequired: false,
            isDisabled: this.isLoading
          }
        ];
      }
      else {
        data.checkBoxes = [];
      }
      data.alertMessage = this.errorMessage;
      data.alertModel = this.paymentAlertMessage;
      data.isLoading = this.isLoading;
      data.disableClose = false;
      data.cardFormData = new AddCardFormModel();
      data.cardFormData.inputLabel = result[inputLabelTrKey];
      data.cardFormData.inputData = this.paymentHelperService.getCustomerFullName();
      data.cardFormData.descriptionText = result[securityNoteTrKey];
      data.cardFormData.descriptionIcon = constants.IconName.Lock;
      data.cardFormData.inputValidations = this.nameOnCardValidation;
    })

    return data;
  }

  public showAddCardDialog(isCreditCard: boolean, paymentCardAppearanceType: PaymentCardAppearanceType = PaymentCardAppearanceType.MyAccountAppearance, country: string, isRetainCheckbox: boolean = true) {
    this.isLoading = true;
    this.isSaveCardChecked = false;
    this.errorMessage = serviceConstants.empty;
    this.validateNameOnCard();
    this.isAddCreditCardSelected = isCreditCard;
    this.isRetainCheckbox = isRetainCheckbox;
    this.initializeAddCardForm(paymentCardAppearanceType, country);
    this.subscribeToAddCardFormChanges();
    this.setupCardPaymentMethod();
  }

  public subscribeToAddCardFormChanges(isDialog: boolean = true) {
    paymentElementIsValid.subscribe(isValid => {
      this.addCardFormIsValid = isValid;
      this.updateAddCardFormData();
    });
    paymentElementIsReady.subscribe(res => {
      this.addCardFormIsReady = res ? true : false;
      this.isLoading = res ? false : true;
      this.updateAddCardFormData();
    });
    if (isDialog) {
      addCardFormInputData.subscribe(inputValue => {
        this.addCardFormNameInputIsValid = inputValue;
        this.updateAddCardFormData();
      });    
      this.addCardButtonSub = dialogDynConfirmBtnAction.subscribe(resp => {
        if (resp) {
          this.showHideSpinner(true);
          this.getPaymentResponseMessage(this.market, true, this.isAddCreditCardSelected).subscribe((response) => {
            this.handlePaymentInfo(response);
          })
        }
      });    
    }   
  }

  private showHideSpinner(show : boolean){
    this.objAddCardDialogData.primaryButton.state = show===true ? ButtonState.Disable : ButtonState.Active;
    this.store.dispatch(this.dataLoadedActions.setDataLoaded(!show));
  }

  private updateAddCardFormData(): DialogDynamicModel {
    if (this.addCardDialogRef?.componentInstance) {
      let enabled = this.addCardFormIsReady && this.addCardFormIsValid && this.addCardFormNameInputIsValid;
      this.objAddCardDialogData.primaryButton.state = enabled ? ButtonState.Active : ButtonState.Disable;
      this.objAddCardDialogData.checkBoxes.forEach(cb => {
        cb.isDisabled = this.isLoading;
      });
      this.objAddCardDialogData.isLoading = this.isLoading;
      this.addCardDialogRef.componentInstance.dialogModel = this.objAddCardDialogData;
      return this.objAddCardDialogData;
    }
  }

  private initializeAddCardForm(paymentCardAppearanceType: PaymentCardAppearanceType = PaymentCardAppearanceType.MyAccountAppearance, country: string): void {
    this.objAddCardDialogData = this.addCardDialogData;
    this.addCardDialogRef = this.dialogLibraryService.openDialogDynamic(this.objAddCardDialogData, "add-card-form");
    this.addCardDialogRef.afterOpened().subscribe(() => {      
      this.market = country;
      let appearanceType = paymentCardAppearanceType;
      let publicKey: any;
      this.store.select(state => state.EnvironmentConfig.STRIPE_PK).subscribe(x => publicKey = x);
      this.intializePaymentCardForm(this.market, appearanceType, publicKey)
    });
    this.addCardDialogRef.afterClosed().subscribe(() => {
      onAddCardBtnSuccess.next(true);
    });
  }

  public intializePaymentCardForm(market: string, appearanceType: PaymentCardAppearanceType, stripeKey: string) {
    let locale = "";
    this.translateService.get("ngw.general.locale").subscribe((res: string) => {
      locale = res;
    });
    
    this.paymentCardService.initializePaymentCardForm(stripeKey, appearanceType, market, locale);
  }

  getPaymentResponseMessage(market: string, isDialog: boolean = true, isAddCreditCardSelected: boolean): Observable<PaymentCardServiceResponse> {
    let observable$: Observable<PaymentCardServiceResponse>;
    this.isDialog = isDialog;
    this.isAddCreditCardSelected = isAddCreditCardSelected;
    let customerName = this.paymentHelperService.getCustomerFullName();
    let customerNumber = this.paymentHelperService.getCustomerNumber();
    let genericErrorMsgKey = `${this.addPayCardTranslationKey}error.generic`;
    let cardTypeErrorMsgKey = `${this.addPayCardTranslationKey}error.card-type`;
    let paymentTypeErrorMsgKey = `${this.addPayCardTranslationKey}error.payment-type`;
    this.translateService.get([genericErrorMsgKey, cardTypeErrorMsgKey, paymentTypeErrorMsgKey]).subscribe(result => {
      let paymentCardData = {
        isCreditCardTypeSelected: isAddCreditCardSelected,
        customerName: customerName,
        customerNumber: customerNumber,
        gcid: this.userService.getGcid(),
        market: market,
        errorMessage: {
          generic: result[genericErrorMsgKey],
          paymentType: result[paymentTypeErrorMsgKey],
          cardType: result[cardTypeErrorMsgKey]
        }
      }
      observable$ = defer(() => from(this.paymentCardService.setupPaymentMethod(paymentCardData)));
    });
    return observable$;
  }

  private handlePaymentInfo(paymentResp: PaymentCardServiceResponse) {
    if (paymentResp && paymentResp.status === serviceConstants.error) {
      this.errorMessage = paymentResp.message;
      this.paymentAlertMessage = this.errorAlertModel();
      this.objAddCardDialogData.alertMessage = this.errorMessage;
      this.objAddCardDialogData.alertModel = this.paymentAlertMessage;
      this.showHideSpinner(false);
    } else if (paymentResp?.status === serviceConstants.success) {
      this.errorMessage = serviceConstants.empty;
      this.savePaymentCardDetails(paymentResp).pipe(
        finalize(() => this.showHideSpinner(false)))
        .subscribe((response) => {
        this.callSavePaymentInfo(response);
      },
        err => {
          this.getSavePaymentError(err);
          this.paymentAlertMessage = this.errorAlertModel();
          this.objAddCardDialogData.alertMessage = this.errorMessage;
          this.objAddCardDialogData.alertModel = this.paymentAlertMessage;
        }
      );
    }
  }

  private callSavePaymentInfo(savePaymentMethodResponse: SavePaymentMethodResponse): void {
    if (this.isDialog) {
      this.addCardDialogRef.componentInstance.data?.checkBoxes?.forEach((cb: { checked: any; }) => {
        this.isRetainCard = cb.checked;
      });
    }
    this.handleSavePaymentSuccess(savePaymentMethodResponse);     
    let response = new DialogDynamicResponseModel(true);
    this.dialogLibraryService.closeDialogDynamic(response);
  }

  private setupCardPaymentMethod() {
    this.addCardDialogRef.beforeClosed().subscribe((result: DialogDynamicResponseModel) => {
      if (result) {
        dialogDynConfirmBtnAction.next(null);
        this.isLoading = true;
        paymentElementIsReady.next(false);        
        this.addCardButtonSub.unsubscribe();
      }
    });
  }

  validateNameOnCard(): void {
    let requiredErrorKey = `${this.addPayCardTranslationKey}validation.name-required`;
    let invalidErrorKey = `${this.addPayCardTranslationKey}validation.invalid-credit-card`;

    this.nameOnCardValidation = [
      new ValidationRule(this.translateService, ValidationType.required, Validators.required, requiredErrorKey),
      new ValidationRule(this.translateService, ValidationType.pattern, Validators.pattern(this.validatorService.regexExpressions.nameOnCardEnglishFrench), invalidErrorKey)
    ];
  }

  public savePaymentCardDetails(response: PaymentCardServiceResponse): Observable<SavePaymentMethodRequest> {
    let savePaymentMethodRequest = new SavePaymentMethodRequest();
    let countryCode = new CountryCode();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    countryCode.codeValue = this.storeAppConfig.COUNTRY_CODE;
    savePaymentMethodRequest.paymentMethodId = response.payId;
    savePaymentMethodRequest.financialAccountType = this.isAddCreditCardSelected ?
      SavePaymentMethodRequestFinancialAccountType.Credit : SavePaymentMethodRequestFinancialAccountType.Debit;
    savePaymentMethodRequest.displaySuffix = response.displaySuffix;
    savePaymentMethodRequest.expirationDate = response.expirationDate;
    savePaymentMethodRequest.customerNumber = this.paymentHelperService.getCustomerNumber();
    savePaymentMethodRequest.cardType = response.cardType;
    savePaymentMethodRequest.gcId = this.userService.getGcid();
    savePaymentMethodRequest.countryCode = countryCode;

    const observable$ = defer(() => from(this.paymentCardServiceClient.savePaymentMethod(savePaymentMethodRequest)));
    return observable$;
  }

  private handleSavePaymentSuccess(savePaymentMethodResponse: SavePaymentMethodResponse) {
    if (savePaymentMethodResponse && savePaymentMethodResponse.cardDetails) { 
      if (this.isRetainCard === false) {
        let financialAccountId = savePaymentMethodResponse.cardDetails?.financialAccountId;
        this.customerFinancialAccountService.expireFinancialAccount(financialAccountId)
          .subscribe(result => { this.postExpirePaymentCard(result, financialAccountId); });
      }
      this.refreshPaymentDetails(savePaymentMethodResponse);
    } 
  }

  public getSavePaymentError(result: any): string {
    let genericErrorMsgKey = `${this.addPayCardTranslationKey}error.generic`;
    let networkErrorMsgKey = `${this.addPayCardTranslationKey}error.network-error`;
    this.translateService.get([genericErrorMsgKey, networkErrorMsgKey]).subscribe(result => {
      genericErrorMsgKey = result[genericErrorMsgKey];
      networkErrorMsgKey = result[networkErrorMsgKey];
    })
    this.errorMessage = networkErrorMsgKey;
    if (result.response) {
      let response = JSON.parse(result.response);
      if (response.FaultDetail !== undefined && response.FaultDetail.Message !== undefined) {
        let faultType = response.FaultType;
        if (faultType === FaultCodes.BMWFSAM_Services_CustomerCashManagement_RegistrationSystemFailureFault) {
          this.errorMessage = genericErrorMsgKey;
        } else {
          this.errorMessage = networkErrorMsgKey;
        }
      }
    }
    return this.errorMessage;
  }

  public postExpirePaymentCard(result: any, financialAccountId: string) {
    if (result.error) {
      let errorMessage = `Error occurred in: CardHelperService, ExpireFinancialAccount failed for FinancialAccountId: ${financialAccountId}`
      this.logErrorService.logCritical(errorMessage)
    }
  }

  
  public refreshPaymentDetails(savePaymentMethodResponse: SavePaymentMethodResponse) {
    let cardDetails = savePaymentMethodResponse?.cardDetails;
    let financialAccountId = cardDetails.financialAccountId;
    let cardType = this.isAddCreditCardSelected ? FinancialAccountDTOAccountType.Credit : FinancialAccountDTOAccountType.Debit;
    this.achPaymentSource = new PaymentSource();
    this.achPaymentSource.financialAccountId = financialAccountId;
    cardDetails.accountType = cardType
    this.achPaymentSource.displayAccountNumber = this.paymentHelperService.getPaymentDisplayName(cardDetails);
    let lastUsedPaymentEntry: { lastUsed: number; };
    let paymentEntry: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => paymentEntry = x);
    if (!this.objectService.isEmptyObject(paymentEntry)) {
        lastUsedPaymentEntry = _.maxBy(paymentEntry.sources, 'lastUsed');
        this.achPaymentSource.lastUsed = lastUsedPaymentEntry ? lastUsedPaymentEntry.lastUsed + 1 : 1;
        this.achPaymentSource.accountType = cardType;
        this.store.dispatch(this.paymentEntryActions.pushPaymentSource(this.achPaymentSource));
    }
    else {
        let paymentEntry = new PaymentEntry();
        let sources = [];
        sources.push(this.achPaymentSource);
        paymentEntry.sources = sources;
        paymentEntry.selectedPaymentSource = this.achPaymentSource;
        this.store.dispatch(this.paymentEntryActions.setPaymentEntry(paymentEntry));
    }
  }

   private errorAlertModel() : AlertModel{
    return new AlertModel(serviceConstants.error, AlertType.TextMode,
      AlertStatus.Warning,
      new IconTextModel(constants.IconName.Caution, constants.IconName.Caution),
      serviceConstants.empty, false);
   }
}