import { Component, OnInit, OnDestroy, Renderer2, Inject, Input, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { BooleanModel } from '../../../../../../_models/boolean.model';
import { DialogService } from '../../../../../../shared/_helper-services/dialog.service';

@Component({
  selector: 'app-quote-saved',
  templateUrl: './quote-saved.component.html',
  styleUrls: ['./quote-saved.component.scss']
})


export class QuoteSavedComponent extends BaseApplicationComponent<QuoteSavedComponent> implements OnInit, OnDestroy {

  constructor(private translateService: TranslateService,
    private dialogService: DialogService,
    injector: Injector,
    @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  @Input() stepper: any;
  quoteSaved: boolean;
  title: string;
  message: string;
  quoteSavedSubscription: any;

  ngOnInit() {
    this.quoteSavedSubscription = this.store.select(state => state.MapQuoteSaved)
      .subscribe(quoteSaved => this.setQuoteSaved(quoteSaved));
  }

  ngOnDestroy() {
  }

  public returnToPaymentOptions(): void {
    this.stepper.selectedIndex = 4;
  }

  public closeDialog(): void {
    this.dialogService.closeMileageAdjustmentEntryDialog(true);
  }

  private setQuoteSaved(quoteSaved: BooleanModel) {
    let titleKey: string;
    let msgKey: string;
    this.quoteSaved = quoteSaved.booleanValue;
    if (this.quoteSaved) {
      titleKey = "ngw.map.entry.quote-saved.title";
      msgKey = "ngw.map.entry.quote-saved.msg";
    }
    else {
      titleKey = "ngw.map.entry.quote-saved.error.title";
      msgKey = "ngw.global.technical-error";
      this.pushErrorEvent(msgKey);
    }
    this.translateService.get(titleKey).subscribe((res: string) => {
      this.title = res;
    });
    this.translateService.get(msgKey).subscribe((res: string) => {
      this.message = res;
    });
  }

}
