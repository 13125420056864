<form>
  <div class="modal-consent">
    <h3>{{dialogTitle}}</h3>
    <p>{{dialogText}}</p>
    <div class="action-buttons">
      <button id="btnCancel" class="btn btn-secondary" (click)="cancelUserAction(); $event.preventDefault();" analytics-event="body.cancel-user-consent.cancel-user-consent.button">
        {{btnCancel}}</button>
      <button class="btn btn-primary" (click)="confirmUserAction(); $event.preventDefault();" type="submit" setFocus>{{btnConfirm}}</button>
    </div>
  </div>
</form>



