export class FaultCodes
{
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserNotFound_V201109_UserNotFoundFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserNotFound.V201109.UserNotFoundFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UsernameDisabled_V201109_UsernameDisabledFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UsernameDisabled.V201109.UsernameDisabledFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_AuthenticationFailed_V201109_AuthenticationFailedFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.AuthenticationFailed.V201109.AuthenticationFailedFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAccountExpired_V201109_UserAccountExpiredFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAccountExpired.V201109.UserAccountExpiredFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAccountPasswordLocked_V201109_UserAccountPasswordLockedFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAccountPasswordLocked.V201109.UserAccountPasswordLockedFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_DataValidation_V201112_DataValidationFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.DataValidation.V201112.DataValidationFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAccountNotActivated_V201109_UserAccountNotActivatedFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAccountNotActivated.V201109.UserAccountNotActivatedFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAlreadyRegisteredFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAlreadyRegisteredFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidFirstNameFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidFirstNameFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidLastNameFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidLastNameFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidPasswordFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidPasswordFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidUserNameFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidUserNameFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidClientI_V201112_InvalidClientIdFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidClientId.V201112.InvalidClientIdFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_ResendActivationLimitExceeded_V201112_ResendActivationLimitExceededFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.ResendActivationLimitExceeded.V201112.ResendActivationLimitExceededFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidAccountState_V201112_InvalidAccountStateFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidAccountState.V201112.InvalidAccountStateFault";
    //Legitimization Fault Codes
    public static BMWFSAM_Services_CustomerRelationshipManagement_DataValidation_V201112_DataValidationFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.DataValidation.V201112.DataValidationFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_NoMatchesFoundFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.NoMatchesFoundFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_UniqueMatchNotFoundFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.UniqueMatchNotFoundFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_GCIDAlreadyLegitimizedFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.GCIDAlreadyLegitimizedFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_OwnershipValidationFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.OwnershipValidationFault";
    //Login
    public static BMWFSAM_Services_CustomerRelationshipManagement_GCDMAccountNotActivatedFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.GCDMAccountNotActivatedFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_ContactNotFound_V201112_ContactNotFoundFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.ContactNotFound.V201112.ContactNotFoundFault";
    //Forgot Password
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidToken_V201109_InvalidTokenFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidToken.V201109.InvalidTokenFault";

    // Post Payment
    public static BMWFSAM_Services_CustomerCashManagement_PaymentSystemFault: string = "BMWFSAM.Services.CustomerCashManagement.PaymentSystemFault";
    public static BMWFSAM_Services_CustomerCashManagement_AccountIneligibleFault: string = "BMWFSAM.Services.CustomerCashManagement.AccountIneligibleFault";
    public static BMWFSAM_Services_AccountNotFound_V200804_AccountNotFoundFault : string = "BMWFSAM.Services.AccountNotFound.V200804.AccountNotFoundFault";
    public static BMWFSAM_Services_CustomerCashManagement_PaymentDateNotAllowedFault: string = "BMWFSAM.Services.CustomerCashManagement.PaymentDateNotAllowedFault";
    public static BMWFSAM_Services_CustomerCashManagement_PaymentAmountNotAllowedFault: string = "BMWFSAM.Services.CustomerCashManagement.PaymentAmountNotAllowedFault";
    public static BMWFSAM_Services_CustomerCashManagement_DuplicatePaymentExceptionFault: string = "BMWFSAM.Services.CustomerCashManagement.DuplicatePaymentExceptionFault";
    public static BMWFSAM_Services_FinancialAccountNotFoundFault: string = "BMWFSAM.Services.FinancialAccountNotFoundFault";
    public static BMWFSAM_Services_CustomerCashManagement_LumpSumPaymentNotAllowedFault: string = "BMWFSAM.Services.CustomerCashManagement.LumpSumPaymentNotAllowedFault";
    public static BMWFSAM_Services_CustomerCashManagement_CardPaymentTransactionFailedFault: string = "BMWFSAM.Services.CustomerCashManagement.CardPaymentTransactionFailedFault";
    public static BMWFSAM_Services_CustomerCashManagement_RegistrationSystemFailureFault: string = "BMWFSAM.Services.CustomerCashManagement.RegistrationSystemFailureFault"

    //Second Factor Authentication
    public static BMWFSAM_Services_IdentityAndAccessManagement_TokenUnexpired_V201109_TokenUnexpiredFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.TokenUnexpired.V201109.TokenUnexpiredFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_TokenExpired_V201109_TokenExpiredFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.TokenExpired.V201109.TokenExpiredFault";
    //Stop Sale Flag
    public static BMWFSAM_Services_AccountManagement_IneligibleToQuoteContractPayoffFault: string = "BMWFSAM.Services.AccountManagement.IneligibleToQuoteContractPayoffFault";
    public static StopSaleFlagErrorMessage: string = "Payoff quote cannot be issued on this account because of Open Recalls.";
    //GetInvoicePdf
    public static BMWFSAM_Services_CustomerCashManagement_InvoiceNotFoundFault: string = "BMWFSAM.Services.CustomerCashManagement.InvoiceNotFoundFault";
    public static BMWFSAM_Services_CustomerCashManagement_InvoiceVendorServiceInvoiceNotFoundFault: string = "BMWFSAM.Services.CustomerCashManagement.InvoiceVendorServiceInvoiceNotFoundFault";

    //add payment source
    public static BMWFSAM_Services_CustomerCashManagement_InvalidRoutingNumberFault: string = "BMWFSAM.Services.CustomerCashManagement.InvalidRoutingNumberFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_CustomerNotFound_V201109_CustomerNotFoundFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.CustomerNotFound.V201109.CustomerNotFoundFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_CustomerIdentifierRequiredFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.CustomerIdentifierRequiredFault";
    public static BMWFSAM_Services_CustomerRelationshipManagement_FinancialAccountMustHaveAccountNumberFault: string = "BMWFSAM.Services.CustomerRelationshipManagement.FinancialAccountMustHaveAccountNumberFault";

    // Recurring Payment Schedule
    public static BMWFSAM_Services_CustomerCashManagement_InvalidScheduleFault: string = "BMWFSAM.Services.CustomerCashManagement.InvalidScheduleFault";
    public static BMWFSAM_Services_CustomerCashManagement_ExistingScheduleFoundFault: string = "BMWFSAM.Services.CustomerCashManagement.ExistingScheduleFoundFault";
   
    // Update Recurring Payment Schedule
    public static BMWFSAM_Services_CustomerCashManagement_ScheduledItemNotFoundFault: string = "BMWFSAM.Services.CustomerCashManagement.ScheduledItemNotFoundFault";

    //Edit Address fault codes
    public static BMWFSAM_Services_AccountManagement_AddressValidationFault: string = "BMWFSAM.Services.AccountManagement.AddressValidationFault";
    public static BMWFSAM_Services_AccountManagement_POBoxAddressFault: string = "BMWFSAM.Services.AccountManagement.POBoxAddressFault";
    public static BMWFSAM_Services_AccountManagement_DataValidation_V201112_DataValidationFault: string = "BMWFSAM.Services.AccountManagement.DataValidation.V201112.DataValidationFault";

    //Maturity Billing
    public static BMWFSAM_Services_CustomerCashManagement_MaturityBillNotFound_V201112_MaturityBillNotFoundFault: string = "BMWFSAM.Services.CustomerCashManagement.MaturityBillNotFound.V201112.MaturityBillNotFoundFault";
    public static BMWFSAM_Services_AccountNotFound_V200906_AccountNotFoundFault: string = "BMWFSAM.Services.AccountNotFound.V200906.AccountNotFoundFault";

    //DueDate Edit fault codes
    public static BMWFSAM_Services_CustomerCashManagement_GetDueDateFault: string = "BMWFSAM.Services.CustomerCashManagement.GetDueDateFault";
    public static BMWFSAM_Services_CustomerCashManagement_ValidateDueDateChangeFault: string = "BMWFSAM.Services.CustomerCashManagement.ValidateDueDateChangeFault";

    //MAP Fault Codes  
    public static BMWFSAM_Services_AccountManagement_AccountNotFoundFault: string = "BMWFSAM.Services.AccountManagement.AccountNotFound.V200906.AccountNotFoundFault";
    public static BMWFSAM_Services_AccountManagement_LeaseMileageMinimumFault: string = "BMWFSAM.Services.AccountManagement.LeaseMileageMinimumFault";
    public static BMWFSAM_Services_AccountManagement_LeaseMileageThresholdFault: string = "BMWFSAM.Services.AccountManagement.LeaseMileageThresholdFault";
    public static BMWFSAM_Services_AccountManagement_SecondAttemptPurchaseMilesFault: string = "BMWFSAM.Services.AccountManagement.SecondAttemptPurchaseMilesFault";

  //Vehicle Return Estimate
  public static BMWFSAM_Services_EndOfTerm_VehicleReturnEstimate_V200906_AutoVinInspectionNotFoundFault: string = "BMWFSAM.Services.EndOfTerm.VehicleReturnEstimate.V200906.AutoVinInspectionNotFoundFault";
  public static BMWFSAM_Services_EndOfTerm_VehicleReturnEstimate_V200906_AddCustomerIntentFault: string = "BMWFSAM.Services.EndOfTerm.VehicleReturnEstimate.V200906.AddCustomerIntentFault";
  public static BMWFSAM_Services_EndOfTerm_VehicleReturnEstimate_V200906_DataValidationFault:string = "BMWFSAM.Services.EndOfTerm.VehicleReturnEstimate.V200906.DataValidationFault";

  public static BMWFSAM_Services_Collections_DeferralNotEligibleFault: string = "BMWFSAM.Services.Collections.DeferralNotEligibleFault";
  public static BMWFSAM_Services_Collections_SeibelEAIExceptionFault: string = "BMWFSAM.Services.SiebelEAIExceptionFault";

  

  public static CLIENT_AUTHENTICATION_FAILED: string = "Client authentication failed.";
  public static ERROR_OCCURRED: string = "Error occurred.";
  public static INVALID_CREDENTIALS: string = "Invalid Credentials";
}
