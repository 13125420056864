import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { GuardService } from '../shared/_helper-services/guard.service';
import { routes } from '../shared/_helper-services/router.service';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from '../shared/store/app.store';

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  const guardService = inject(GuardService);
  const store = inject(Store<IAppState>);
  const userService = inject(UserService);

  guardService.setLanguage(state);
  let cannotAuthenticate: boolean;
  store.select(state => state.CannotAuthenticate.booleanValue).subscribe(x => cannotAuthenticate = x);
  if (cannotAuthenticate) {
    return guardService.guardFailed(routes.error.toString(), false);
  }
  if (userService.isAuthenticated())
    return guardService.guardSucceded();
  return guardService.guardFailed(routes.login.toString(), false, { origUrl: state.url });

}
