import * as _ from "lodash-es";

export class TitleParagraph {
    title: string;
    paragraph: string;
    paragraphs: string[];

    constructor(private translateService, titleKey: string, paragraphKey?: string, paragraphsKeys?: string[]){
        this.translateService.get(titleKey).subscribe((res: string) => {
            this.title = res;
        })
        if (paragraphKey) {
            this.translateService.get(paragraphKey).subscribe((res: string) => {
                this.paragraph = res;
            })
        } else {
            this.paragraph = null;
        }
        let self = this;
        let paragraphs: string[] = new Array();
        if (paragraphsKeys) {
            _.each(paragraphsKeys, function (key) {
                self.translateService.get(key).subscribe((res: string) => {
                    paragraphs.push(res);
                })
            });
        }
        this.paragraphs = paragraphs;
    }
}
