<div class="payment-option">
  <div #prefPaymentTopDiv id="prefPaymentTopDiv"></div>
  <h3 class="pre-header">{{'ngw.map.entry.choose-payment.pre-header' | translate}}</h3>
  <h2>{{'ngw.map.entry.choose-payment.title' | translate}}</h2>
  <div class="error-container" *ngIf="globalTechError">
    <em class="error-validation" id="errorMessage" [innerHtml]="errorMessage"></em>
  </div>
  <div *ngIf="accountInEligible">
    <div class="not-eligible">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <span analytics-error="ngw.map.entry.choose-payment.error.account-not-eligible-title">{{'ngw.map.entry.choose-payment.error.account-not-eligible-title' | translate}}</span>
    </div>
    <div class="not-eligible-desc">
      <p class="error-msg-content" analytics-error="ngw.map.entry.choose-payment.error.account-not-eligible-desc2">
        {{'ngw.map.entry.choose-payment.error.account-not-eligible-desc2' | translate}}
        <a (click)="navigateToContactUs()">{{'ngw.general.contact-us-with-dot' | translate}}</a>
      </p>
    </div>
  </div>
  <div *ngIf="!globalTechError && !accountInEligible">
    <p class="current-allowed">
      <strong>{{'ngw.map.entry.choose-payment.lbl-miles-to-purchase' | translate}} </strong>{{midtermMileageAdjustmentData.milesToPurchase | number}}
    </p>
    <mat-accordion multi="true">
      <mat-expansion-panel *ngIf="monthlyPaymentAllowed" [disabled]="monthlyOpened" (opened)="afterExpanded(optionMonthly)" [ngClass]="{'selected' : monthlySelected}" [expanded]="isMonthlyExpanded()">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto" analytics-event-accordian>
          <mat-panel-title>
            <mat-radio-group class="example-radio-group" [(ngModel)]="prefferedOption">
              <mat-radio-button class="example-radio-button" [value]="optionMonthly" (change)="setSelected(optionMonthly)">
                <span class="radio-label">{{'ngw.map.entry.choose-payment.monthly-payment.title' | translate}}</span>
              </mat-radio-button>
            </mat-radio-group>
          </mat-panel-title>
          <div class="mat-panel-title-secondary">
            <div class="sec-title">{{'ngw.map.entry.choose-payment.monthly-payment.sub-title' | translate}}</div>
          </div>
        </mat-expansion-panel-header>
        <div class="mileage-row-container">
          <div class="mileage-row">
            <div class="form-group">
              <label class="control-label required" for="state">{{'ngw.map.entry.choose-payment.monthly-payment.lbl-start-date' | translate}}</label>
              <mat-select [(ngModel)]="paymentStartDate" [disabled]="lastPayment" class="form-control" aria-label="dialogValue" aria-labelledby="dialogValue" (ngModelChange)="onPaymentStartDateChange($event)">
                <mat-option *ngFor="let date of paymentStartDates" [value]="date.toString()">{{date | date:'longDate'}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="error-container" *ngIf="mileageCalculationError">
            <em class="error-validation" id="errorMessage" [innerHtml]="errorMessage"></em>
          </div>
          <div *ngIf="!mileageCalculationError && calculateMilesData && calculateMilesData.midtermMileageCalculation">
            <div class="mileage-row">
              <span class="label">
                {{'ngw.map.entry.choose-payment.monthly-payment.lbl-current-monthly-payment' | translate}}
              </span>
              <span class="value">
                {{calculateMilesData.midtermMileageCalculation.monthlyPaymentAmount | currency : 'USD':true}}
              </span>
            </div>
            <div class="mileage-row">
              <span class="label">
                {{'ngw.map.entry.choose-payment.monthly-payment.lbl-monthly-cost' | translate}}
              </span>
              <span class="value">
                {{calculateMilesData.midtermMileageCalculation.monthlyMMATotal | currency : 'USD':true}}
              </span>
            </div>
            <div class="mileage-row">
              <span class="label">
                <strong>{{'ngw.map.entry.choose-payment.monthly-payment.lbl-new-monthly-payment' | translate}}</strong>
              </span>
              <span class="value">
                <strong>{{calculateMilesData.midtermMileageCalculation.newMonthlyPaymentAmount | currency : 'USD':true}}</strong>
              </span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="singlePaymentAllowed" [disabled]="chooseSingleOpened" (opened)="afterExpanded(optionSingle)" [ngClass]="{'selected' : singleSelected}" [expanded]="isSingleExpanded()">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto" analytics-event-accordian>
          <mat-panel-title>
            <mat-radio-group class="example-radio-group" [(ngModel)]="prefferedOption">
              <mat-radio-button class="example-radio-button" [value]="optionSingle" (change)="setSelected(optionSingle)">
                <span class="radio-label">{{'ngw.map.entry.choose-payment.single-payment.title' | translate}}</span>
              </mat-radio-button>
            </mat-radio-group>
          </mat-panel-title>
          <div class="mat-panel-title-secondary">
            <div class="sec-title">{{'ngw.map.entry.choose-payment.single-payment.sub-title' | translate}}</div>
          </div>
        </mat-expansion-panel-header>
        <div class="mileage-row-container" *ngIf="calculateMilesData && calculateMilesData.midtermMileageCalculation">
          <div class="mileage-row">
            <span class="label">
              <strong>{{'ngw.map.entry.choose-payment.single-payment.lbl-total-cost' | translate}}</strong>
            </span>
            <span class="value">
              <strong>{{calculateMilesData.midtermMileageCalculation.singleMMATotalAmount | currency : 'USD':true}}</strong>
            </span>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="note-text" *ngIf="!singlePaymentAllowed || !monthlyPaymentAllowed">
      <strong>{{'ngw.map.entry.choose-payment.please-note' | translate}}</strong> {{noteText}}
      <a (click)="navigateToInbox()" *ngIf="!singlePaymentAllowed">{{"ngw.map.entry.choose-payment.message-center-link" | translate}}</a>
    </div>
    <div class="action-buttons">
      <button class="btn btn-secondary" type="button" [disabled]="!isFormValid()" (click)="saveAsQuote()">{{'ngw.map.entry.choose-payment.btn-save-quote' | translate}}</button>
      <button class="btn btn-primary" type="button" [disabled]="!isFormValid()" (click)="continue()">{{'ngw.map.entry.choose-payment.btn-continue' | translate}}</button>
    </div>
  </div>
</div>
