<div class="page-add-ach-payment-source">
  <app-breadcrumb [title]="'ngw.add-chequing-account.title' | translate"></app-breadcrumb>
  <app-contract-header [contractDetail]="contractDetail"></app-contract-header>
  <div class="add-chequing-account-upload-container" *ngIf="dataLoaded" [scrollTop]="scrollToTop">
    <div class="submit-error" *ngIf="hasSubmitAchPaymentError">
      <span analytics-error="ngw.add-chequing-account.error.submit-error">{{'ngw.add-chequing-account.error.submit-error' | translate}}</span><a (click)="navigateToContactUs()">{{'ngw.general.contact-us-with-dot' | translate}}</a>
    </div>
    <app-download-pad-form></app-download-pad-form>
    <app-upload-cheque-pad-form [addAchPaymentSourceCanModel]="addAchPaymentSourceCanModel"></app-upload-cheque-pad-form>
    <div class="action-buttons">
      <button id="btnPrevious" type="button" class="btn btn-secondary" (click)="navigateToHome()">{{'ngw.add-chequing-account.chequing-account-details.cancel-btn' | translate}}</button>
      <button id="btnSubmitAddAchCan" type="submit" setFocus [disabled]="!isFormValid()" class="btn btn-primary" (click)="submitChequingAccountDetails(); $event.preventDefault();">{{'ngw.add-chequing-account.chequing-account-details.submit-btn' | translate}}</button>
    </div>
  </div>
</div>
