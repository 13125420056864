import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { AccountNumberMaskPipe } from '../_pipes/account-number-mask.pipe';

@Directive({ selector: "[accountNumberMask]" })
export class AccountNumberMaskDirective implements OnInit {
  private origionalValue: any;
  private element: any;

  constructor(
    private elementRef: ElementRef,
    private accountNumberMaskPipe: AccountNumberMaskPipe
  ) {
    this.element = this.elementRef.nativeElement;

  }

  ngOnInit() {
    this.element.value = this.accountNumberMaskPipe.transform(this.element.value);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    if (value) {
      this.element.value = this.origionalValue;
    }
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.origionalValue = value;
    this.element.value = this.accountNumberMaskPipe.transform(value);
  }
}
