<div *ngIf="dataLoaded" class="page-payment-confirmation add-chequing-account">
  <app-breadcrumb [title]="'ngw.add-chequing-account.title' | translate" (click)="navigateToHome()" [backToEntryPage]="true"></app-breadcrumb>
  <app-contract-header [contractDetail]="contractDetail"></app-contract-header>

  <div class="payment-confirmation-container">
    <div class="confirmation-header">
      <h2>{{'ngw.add-chequing-account.chequing-account-confirmation.title' | translate}}</h2>
      <button class="btn btn-primary print btn-icon no-print" (click)="print()" id="btnPrint">
        <span class="hidden">{{'ngw.payment.confirmation.print' | translate}}</span>
        <i class="fa fa-print" aria-hidden="true"></i>
      </button>
    </div>

    <h3>
      <i class="fa fa-check-circle" aria-hidden="true"></i>
      {{'ngw.add-chequing-account.chequing-account-confirmation.header' | translate}}
    </h3>
    <span class="subhead">{{ 'ngw.add-chequing-account.chequing-account-confirmation.description' | translate }}</span>
    <div class="payment-confirmation-row">

    </div>
    <div class="payment-confirmation-row">
      <span class="label">
        {{ 'ngw.add-chequing-account.chequing-account-confirmation.bank-transit-number-lbl' | translate }}

      </span>
      <span class="value">{{bankTransitNumber}}</span>
    </div>
    <div class="payment-confirmation-row">
      <span class="label">
        {{ 'ngw.add-chequing-account.chequing-account-confirmation.bank-number-lbl' | translate }}

      </span>
      <span class="value">{{bankNumber}}</span>
    </div>
    <div class="payment-confirmation-row">
      <span class="label">
        {{ 'ngw.add-chequing-account.chequing-account-confirmation.bank-account-number-lbl' | translate }}

      </span>
      <span class="value">{{bankAccountNumber}}</span>
    </div>
    <div class="payment-confirmation-row">
      <span class="label">
        {{ 'ngw.add-chequing-account.chequing-account-confirmation.bank-account-type-lbl' | translate }}

      </span>
      <span class="value">{{ 'ngw.add-chequing-account.chequing-account-confirmation.bank-account-type' | translate }}</span>
    </div>
    <div class="payment-confirmation-row">
      <span class="label">
        {{ 'ngw.add-chequing-account.chequing-account-confirmation.confirmation-number-lbl' | translate }}

      </span>
      <span class="value">{{confirmationNumber}}</span>
    </div>
    <div class="action-buttons no-print">
      <button class="btn btn-primary"
              type="submit" (click)="navigateToHome()">
        {{'ngw.add-chequing-account.chequing-account-confirmation.submit-btn' | translate}}
      </button>
    </div>
  </div>
  </div>

