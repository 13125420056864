import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PaymentSource } from '../../_models/payment.model';
import { TitleParagraph } from '../../_models/title-paragraph.model';
import { PaymentPreference, PaymentPreferenceType } from '../../_models/recurring-payment/payment-preference.model';
import { RecurringPaymentEntry } from '../../_models/recurring-payment/recurring-payment-entry.model';
import { EmailAddressDTOEmailAddressType, EmailAddressDTO, FinancialAccountDTO, FinancialAccountDTOAccountType, GetRecurringPaymentViewDataResponse, FindEasyPayScheduleResponse, PaymentAccountDTO, ContactDTO } from '../../core/gateway-api';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { IAppState } from "../../shared/store/app.store";
import { Constants } from "../constants";
import { TranslateService } from '@ngx-translate/core';
import { AccountInfoService } from './account-info.service';
import { ObjectService } from './object.service';
import { RecurringPaymentEntryActions } from '../store/reducers/recurring-payment.reducer';
import { PaymentEntryActions } from '../store/reducers/payment-entry.reducer';
import { MaskService } from './mask.service';
import { RecurringPaymentCancel } from '../../_models/recurring-payment/recurring-payment-cancel-model';
import { ValidatorService } from "../../_validators/validator.service";
import { FaultCodes } from '../../shared/FaultCodes';
import { PaymentReviewDetails } from '../../_models/payment.model';
import { UserService } from './user.service';
import { AnalyticsService } from './analytics.service';

@Injectable()
//this service should be used for any payment data manipulation
export class PaymentHelperService {



  private requiredFieldsErrorMsg: string;
  private requiredMaxAmountErrorTranslationKey = "ngw.recurring-payment.entry.errors.required-max-amount";
  private requiredMaxAmountErrorMsg: string;
  private requiredAdditionalAmountErrorTranslationKey = "ngw.recurring-payment.entry.errors.required-additional-amount";
  private requiredAdditionalAmountErrorMsg: string;
  private minValueMaxAmountErrorTranslationKey = "ngw.recurring-payment.entry.errors.min-value-max-amount";
  private minValueMaxAmountErrorMsg: string;
  private maxValueMaxAmountErrorMsg: string;
  private minimumAdditionalAmountErrorTranslationKey = "ngw.recurring-payment.entry.errors.minimum-additional-amount";
  private minimumAdditionalAmountErrorMsg: string;

  constructor(
    private store: Store<IAppState>,
    private translateService: TranslateService,
    private accountInfoService: AccountInfoService,
    private objectService: ObjectService,
    private validatorService: ValidatorService,
    private userService: UserService,
    private recurringPaymentEntryActions: RecurringPaymentEntryActions,
    private paymentEntryActions: PaymentEntryActions,
    private maskService: MaskService,
    private currencyPipe: CurrencyPipe,
    private analyticsService: AnalyticsService) { }


  public getCustomerBillingEmailAddress(): string {
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    if (contact != null) {
      let billingEmailAddress = _.find(contact.emailAddresses, function (emailAddress: EmailAddressDTO) {
        return emailAddress.emailAddressType === EmailAddressDTOEmailAddressType.Billing
      })
      return billingEmailAddress ? billingEmailAddress.emailAddress : Constants.EMPTY;
    }
  }

  public getCustomerFullName(): string {
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    if (contact != null) {
      return `${contact.firstName} ${contact.lastName}`;
    }
  }

  public getCustomerNumber(): number {
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    if (contact != null) {
      return contact.eDBCustomerNumber;
    }
  }

  public getPaymentPreferences(monthlyPayment: number, maxPayment): PaymentPreference[] {
    let paymentPreferences = new Array();
    paymentPreferences.push(this.getMonthlyPaymentPreference(monthlyPayment), this.getOutstandingPaymentPreference(maxPayment, monthlyPayment));

    return paymentPreferences;
  }

  public getAdditionalPaymentPreference(additionalAmountFromResponse: number): PaymentPreference {
    let recurringPaymentEntry: RecurringPaymentEntry;
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => recurringPaymentEntry = x);
    let paymentPreference: PaymentPreference = recurringPaymentEntry.additionalPaymentPreference;
    let additionalPaymentPreferenceFromStore = this.objectService.isEmptyObject(recurringPaymentEntry) || recurringPaymentEntry === null
      ? null
      : paymentPreference;
    if (!additionalPaymentPreferenceFromStore) {
      let additionalPreferenceLblTxt: string;
      let additionalPreferenceValueTxt: string;
      let additionalPreferenceTooltipTxt: string;
      this.translateService.get("ngw.recurring-payment.entry.lbl-add-additional").subscribe((res: string) => {
        additionalPreferenceLblTxt = res;
      });
      this.translateService.get("ngw.recurring-payment.entry.lbl-set-additional").subscribe((res: string) => {
        additionalPreferenceValueTxt = res;
      });
      this.translateService.get("ngw.recurring-payment.entry.tooltip-txt-additional-label").subscribe((res: string) => {
        additionalPreferenceTooltipTxt = res;
      });
      let additionalPaymentPreference = new PaymentPreference();
      additionalPaymentPreference.type = PaymentPreferenceType.Additional;
      additionalPaymentPreference.label = additionalPreferenceLblTxt;
      additionalPaymentPreference.value = additionalAmountFromResponse;
      additionalPaymentPreference.valueLabel = additionalPreferenceValueTxt;
      additionalPaymentPreference.isChecked = additionalAmountFromResponse !== null;
      additionalPaymentPreference.tootlipTxt = additionalPreferenceTooltipTxt;
      return additionalPaymentPreference;
    }
    else {
      return additionalPaymentPreferenceFromStore;
    }
  }

  public getFilteredAndSortedPaymentSources(paymentAccounts: FinancialAccountDTO[]): PaymentSource[] {
    //todo: refactor one time payment to use this method
    let lastUsed: number = 1;
    let displayAccountNumber: string;
    let paymentSource: PaymentSource;
    let sources = [];
    let addAchSource: string;

    this.translateService.get("ngw.payment.entry.add-ach-source").subscribe((res: string) => {
      addAchSource = res;
    });

    for (let paymentAccount of paymentAccounts) {
      if (paymentAccount.accountType === FinancialAccountDTOAccountType.Checking ||
        paymentAccount.accountType === FinancialAccountDTOAccountType.Savings) {
        displayAccountNumber = this.getPaymentDisplayName(paymentAccount);
        paymentSource = new PaymentSource(paymentAccount.financialAccountNumber, displayAccountNumber, paymentAccount.financialAccountId, lastUsed, paymentAccount.bankId, paymentAccount.accountType);

        sources.push(paymentSource);
        if (lastUsed === 1) {
          lastUsed = 0;
        }
      }
    }

    if (!_.includes(sources, sources.find(source => source.displayAccountNumber === addAchSource))) {
      let newAchSource = new PaymentSource(Constants.EMPTY, addAchSource, Constants.EMPTY, -1);
      sources.push(newAchSource);
    }
    sources = _.orderBy(sources, ['lastUsed', 'displayAccountNumber'], ['desc', 'asc']);

    return sources
  }

  public getPaymentDisplayName(paymentAccount: any): string {
    let displayAccountNumber: string;
    let displaySuffix: string;
    displaySuffix = paymentAccount.displaySuffix ? paymentAccount.displaySuffix : this.maskService.getLastFourAccountNo(paymentAccount.financialAccountNumber);

    let displayTextKey = "ngw.payment-source.view.lbl-account-" + paymentAccount.accountType.toLowerCase();
    if ((paymentAccount.accountType === FinancialAccountDTOAccountType.Checking) || (paymentAccount.accountType === FinancialAccountDTOAccountType.Savings)) {
      this.translateService.get(displayTextKey,
        { displaySuffix: displaySuffix })
        .subscribe((res: string) => { displayAccountNumber = res; });
    }
    else {
      if (paymentAccount.accountType === FinancialAccountDTOAccountType.Credit) {
        this.translateService.get("ngw.payment-source.view.lbl-account-number",
          { accountType: paymentAccount.subType, displaySuffix: displaySuffix })
          .subscribe((res: string) => { displayAccountNumber = res; });
      }
      else {
        this.translateService.get(displayTextKey,
          { displaySuffix: displaySuffix })
          .subscribe((res: string) => { displayAccountNumber = res; });
      }
    }


    return displayAccountNumber;
  }

  public getMaxMonthlyAmountErrorMsg(recurringPaymentEntry: RecurringPaymentEntry): string {
    if (recurringPaymentEntry && recurringPaymentEntry.getRecurringPaymentViewDataResponse) {
      if (this.validatorService.showAmountError(recurringPaymentEntry.selectedPaymentPreference.value)) {
        this.translateService.get(this.requiredMaxAmountErrorTranslationKey).subscribe((res: string) => {
          this.requiredMaxAmountErrorMsg = res;
        });
        return this.requiredMaxAmountErrorMsg;
      }
      else {
        let maxAmountAsCurrency = this.currencyPipe.transform(recurringPaymentEntry.getRecurringPaymentViewDataResponse.maxPaymentLimitAmount.toString(), Constants.DOLLAR_CURRENCY, true);
        if (recurringPaymentEntry.selectedPaymentPreference.type == PaymentPreferenceType.MonthlyAndOutstanding) {
          this.getErrorMessagesForMaxMonthly(maxAmountAsCurrency);
          let maxAmountIsNotPopulated = !recurringPaymentEntry.selectedPaymentPreference.value;
          if (maxAmountIsNotPopulated) {
            return this.requiredMaxAmountErrorMsg;
          }
          else if (recurringPaymentEntry.selectedPaymentPreference.value <= recurringPaymentEntry.getRecurringPaymentViewDataResponse.monthlyPaymentAmount) {
            return this.minValueMaxAmountErrorMsg;
          }
        }
      }
    }
    return Constants.EMPTY;
  }

  public getAdditionalAmountErrorMsg(recurringPaymentEntry: RecurringPaymentEntry) {
    if (recurringPaymentEntry && recurringPaymentEntry.getRecurringPaymentViewDataResponse) {
      if (this.validatorService.showAmountError(recurringPaymentEntry.additionalPaymentPreference.value)) {
        this.translateService.get(this.requiredAdditionalAmountErrorTranslationKey).subscribe((res: string) => {
          this.requiredAdditionalAmountErrorMsg = res;
        });
        // this.analyticsService.pushErrorEvent(this.requiredAdditionalAmountErrorTranslationKey);
        return this.requiredAdditionalAmountErrorMsg;
      }
      else {
        let maxAmountAsCurrency = this.currencyPipe.transform(recurringPaymentEntry.getRecurringPaymentViewDataResponse.maxPaymentLimitAmount.toString(), Constants.DOLLAR_CURRENCY, true);
        if (recurringPaymentEntry.additionalPaymentPreference && recurringPaymentEntry.additionalPaymentPreference.isChecked) {
          let additionalAmountIsNotPopulated = recurringPaymentEntry.additionalPaymentPreference.value === undefined
            || recurringPaymentEntry.additionalPaymentPreference.value === null
            || recurringPaymentEntry.additionalPaymentPreference.value.toString() == Constants.EMPTY;
          //doing to string and parse int because the default value is "" somehow
          let additionalMonthlySum = !additionalAmountIsNotPopulated
            ? parseFloat(recurringPaymentEntry.additionalPaymentPreference.value.toString())
            + parseFloat(recurringPaymentEntry.getRecurringPaymentViewDataResponse.monthlyPaymentAmount.toString())
            : null;
          let additionalMonthlySumAsCurrency = additionalMonthlySum
            ? this.currencyPipe.transform(additionalMonthlySum.toString(), Constants.DOLLAR_CURRENCY, true)
            : null;
          this.getErrorMessagesForAdditionalAmount(maxAmountAsCurrency, additionalMonthlySumAsCurrency)
          if (additionalAmountIsNotPopulated) {
            return this.requiredAdditionalAmountErrorMsg;
          }
          //if addional < 1
          else if (recurringPaymentEntry.additionalPaymentPreference.value < Constants.MINIMUM_PAYMENT_AMOUNT) {
            return this.minimumAdditionalAmountErrorMsg;
          }
        }
      }
    }
    return Constants.EMPTY;
  }

  public getRecurringPaymentEntry(accountNumber: string, contractType: any, responseFromGetRecurringpaymentViewData: GetRecurringPaymentViewDataResponse, responseFromGetEasyPaySchedule: FindEasyPayScheduleResponse) {
    let recurringPaymentEntry;
    if (accountNumber && contractType && responseFromGetRecurringpaymentViewData && responseFromGetEasyPaySchedule) {
      let emailAddress = this.getCustomerBillingEmailAddress();
      let paymentAccounts = responseFromGetRecurringpaymentViewData.paymentAccounts;
      let sources = this.getFilteredAndSortedPaymentSources(paymentAccounts);
      let isLeaseAccount: boolean = this.accountInfoService.isLeaseContractType(contractType);
      let isOCAccount: boolean = this.accountInfoService.isOwnersChoiceContractType(contractType);
      let isInAddMode = responseFromGetEasyPaySchedule.easyPaySchedule === undefined;
      let titleKey = isInAddMode ? "ngw.recurring-payment.entry.add.title" : "ngw.recurring-payment.entry.modify.title";
      let subtitle = isInAddMode ? "ngw.recurring-payment.entry.sub-title" : "ngw.recurring-payment.entry.modify.subtitle";
      let titleSection = new TitleParagraph(this.translateService, titleKey, subtitle)
      let currentEasypayPaymentAccount = !isInAddMode
        ? responseFromGetEasyPaySchedule.easyPaySchedule.nextScheduledItem.paymentAccount
        : null;
      let selectedPaymentSource = this.getSelectedSource(!isInAddMode, paymentAccounts, sources, currentEasypayPaymentAccount);
      let shouldDrawBalance = !isInAddMode
        ? responseFromGetEasyPaySchedule.easyPaySchedule.shouldDrawFullBalance
        : null;
      let maxPaymentAmount = !isInAddMode
        ? responseFromGetEasyPaySchedule.easyPaySchedule.maxPaymentAmount
        : null;
      let selectedPaymentPreference = this.getSelectedPaymentPreference(!isInAddMode, shouldDrawBalance, maxPaymentAmount);
      let preferences = this.getPaymentPreferences(responseFromGetRecurringpaymentViewData.monthlyPaymentAmount, maxPaymentAmount);
      let startDate = this.getSelectedStartDate(!isInAddMode ? responseFromGetEasyPaySchedule.easyPaySchedule.startDate : null);
      let nextEffectiveDate = responseFromGetEasyPaySchedule.easyPaySchedule && responseFromGetEasyPaySchedule.easyPaySchedule.nextScheduledItem ? responseFromGetEasyPaySchedule.easyPaySchedule.nextScheduledItem.scheduledDate : null;
      recurringPaymentEntry = new RecurringPaymentEntry(isInAddMode, accountNumber, titleSection, emailAddress, false, false, false, null, null, responseFromGetRecurringpaymentViewData, responseFromGetEasyPaySchedule, selectedPaymentSource, sources, preferences, null, selectedPaymentPreference, startDate, nextEffectiveDate, null);
      if (!(isLeaseAccount || isOCAccount)) {
        let additionalAmount: number = !isInAddMode
          ? responseFromGetEasyPaySchedule.easyPaySchedule.additionalAmount
          : null;
        let additionalPreference = this.getAdditionalPaymentPreference(additionalAmount);
        recurringPaymentEntry.additionalPaymentPreference = additionalPreference;
      }


    }
    return recurringPaymentEntry;
  }

  public getRequiredErrorMsg() {
    this.translateService.get("ngw.recurring-payment.entry.errors.required-fields").subscribe((res: string) => {
      this.requiredFieldsErrorMsg = res;
    });
    return this.requiredFieldsErrorMsg;
  }

  private getErrorMessagesForMaxMonthly(maxAmount: string) {
    this.translateService.get(this.requiredMaxAmountErrorTranslationKey).subscribe((res: string) => {
      this.requiredMaxAmountErrorMsg = res;
    });
    this.translateService.get(this.minValueMaxAmountErrorTranslationKey).subscribe((res: string) => {
      this.minValueMaxAmountErrorMsg = res;
    });
  }

  private getErrorMessagesForAdditionalAmount(maxAmount: string, additionalAmount: string) {
    this.translateService.get(this.requiredAdditionalAmountErrorTranslationKey).subscribe((res: string) => {
      this.requiredAdditionalAmountErrorMsg = res;
    });
    this.translateService.get(this.minimumAdditionalAmountErrorTranslationKey, { value: additionalAmount }).subscribe((res: string) => {
      this.minimumAdditionalAmountErrorMsg = res;
    });
  }

  public getSelectedPaymentPreference(hasEasyPaySchedule: boolean, shouldDrawFullBalance: boolean, maxPaymentAmount: number): PaymentPreference {
    let recurringPaymentEntry: RecurringPaymentEntry;
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => recurringPaymentEntry = x);
    let selectedPaymentPreference: PaymentPreference = recurringPaymentEntry.selectedPaymentPreference;
    let selectedPaymentPreferenceFromStore = this.objectService.isEmptyObject(recurringPaymentEntry) || recurringPaymentEntry === null ||  Object.values(recurringPaymentEntry).every(el => el === undefined)
      ? null
      : selectedPaymentPreference;
    if (selectedPaymentPreferenceFromStore === null) {
      let paymentPreference = new PaymentPreference();
      if (hasEasyPaySchedule) {
        let type = shouldDrawFullBalance
          ? PaymentPreferenceType.MonthlyAndOutstanding
          : PaymentPreferenceType.Monthly;
        let maxMonthlyAmount = maxPaymentAmount;
        paymentPreference.type = type;
        paymentPreference.value = maxMonthlyAmount;
      }
      else {
        paymentPreference.type = PaymentPreferenceType.Monthly;
      }
      return paymentPreference;
    }
    else {
      return selectedPaymentPreferenceFromStore;
    }

  }

  private getSelectedStartDate(startdateFromEasypaySchedule: Date): any {
    let recurringPaymentEntry: RecurringPaymentEntry;
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => recurringPaymentEntry = x);
    let startDates: string = recurringPaymentEntry.startDate;
    let selectedStartDateFromStore = this.objectService.isEmptyObject(recurringPaymentEntry)
      || recurringPaymentEntry === null
      ? null
      : startDates;
    let startDateFromEasypaySchedule = startdateFromEasypaySchedule ? startdateFromEasypaySchedule.toString() : null;
    return selectedStartDateFromStore ? selectedStartDateFromStore : startdateFromEasypaySchedule;
  }

  private getMonthlyPaymentPreference(monthlyPayment: number): PaymentPreference {
    let monthlyPreferenceTxt: string;
    let monthlyPreferenceTooltipTxt: string;
    this.translateService.get("ngw.recurring-payment.entry.lbl-monthly-payment-amount").subscribe((res: string) => {
      monthlyPreferenceTxt = res;
    });
    this.translateService.get("ngw.recurring-payment.entry.tooltip-monthly").subscribe((res: string) => {
      monthlyPreferenceTooltipTxt = res;
    });
    let monthlyPaymentPreference = new PaymentPreference();
    monthlyPaymentPreference.type = PaymentPreferenceType.Monthly;
    let monthlyPaymentToCurrency = this.currencyPipe.transform(monthlyPayment, Constants.DOLLAR_CURRENCY, true);
    monthlyPaymentPreference.label = monthlyPreferenceTxt + " " + monthlyPaymentToCurrency;
    monthlyPaymentPreference.tootlipTxt = monthlyPreferenceTooltipTxt;

    return monthlyPaymentPreference;

  }

  private getOutstandingPaymentPreference(maxPayment: number, monthlyPayment: number): PaymentPreference {
    let outstandingPreferenceLblTxt: string;
    let outstandingPreferenceTooltipTxt: string;
    let maxMonthlyTooltipTxt: string;
    let monthlyPaymentCurrency = this.currencyPipe.transform(monthlyPayment, 'USD', 'symbol');
    this.translateService.get("ngw.recurring-payment.entry.lbl-outstanding", { value: monthlyPaymentCurrency }).subscribe((res: string) => {
      outstandingPreferenceLblTxt = res;
    });
    this.translateService.get("ngw.recurring-payment.entry.tooltip-txt-outstanding").subscribe((res: string) => {
      outstandingPreferenceTooltipTxt = res;
    });
    this.translateService.get("tooltip-txt-max-monthly").subscribe((res: string) => {
      maxMonthlyTooltipTxt = res;
    });
    let outstandingPreference = new PaymentPreference();
    outstandingPreference.type = PaymentPreferenceType.MonthlyAndOutstanding;
    outstandingPreference.label = outstandingPreferenceLblTxt;
    outstandingPreference.tootlipTxt = outstandingPreferenceTooltipTxt;
    outstandingPreference.valueTooltipTxt = maxMonthlyTooltipTxt;

    return outstandingPreference;

  }

  private getSelectedSourceFromEasyPaySchedule(hasEasyPaySchedule: boolean, paymentAccounts: FinancialAccountDTO[], sources: PaymentSource[], currentEasypayPaymentAccount?: PaymentAccountDTO): PaymentSource {
    let paymentAccount: FinancialAccountDTO = hasEasyPaySchedule
      ? _.find(paymentAccounts, function (pacc: FinancialAccountDTO) {
        return pacc.financialAccountNumber === currentEasypayPaymentAccount.financialAccountNumber
          && pacc.bankId === currentEasypayPaymentAccount.bankId;
      })
      : null;
    let selectedPaymentSource = paymentAccount
      ? _.find(sources, function (source: PaymentSource) {
        return source.financialAccountId === paymentAccount.financialAccountId
      })
      : new PaymentSource(null, null, null);

    return selectedPaymentSource;
  }

  public getSelectedSource(hasEasyPaySchedule: boolean, paymentAccounts: FinancialAccountDTO[], sources: PaymentSource[], currentEasypayPaymentAccount: PaymentAccountDTO): PaymentSource {
    let recurringPaymentEntry:  RecurringPaymentEntry;
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => recurringPaymentEntry = x);
    let selectedPaymentSource: any = recurringPaymentEntry.selectedPaymentSource;
    let selectedPaymentSourceFromState = this.objectService.isEmptyObject(recurringPaymentEntry) || recurringPaymentEntry === null
      ? null
      : selectedPaymentSource;
    return selectedPaymentSourceFromState !== null
      ? selectedPaymentSourceFromState
      : this.getSelectedSourceFromEasyPaySchedule(hasEasyPaySchedule, paymentAccounts, sources, currentEasypayPaymentAccount);
  }

  public getRecurringPaymentCancelationPaymentAmount(recurringPaymentCancel: RecurringPaymentCancel): string {
    let totalDue: string;
    if (!recurringPaymentCancel.shouldDrawFullBalance) {
      return recurringPaymentCancel.schedulePaymentAmount.toString();
    }
    else if (recurringPaymentCancel.shouldDrawFullBalance && recurringPaymentCancel.additionalAmount > 1) {
      let additionalAmount = this.currencyPipe.transform(recurringPaymentCancel.additionalAmount, 'USD', 'symbol');
      this.translateService.get("ngw.payment.cancel.total-and-additional", { value: additionalAmount }).subscribe((res: string) => {
        totalDue = res;
      });
      return totalDue;
    }
    else {
      this.translateService.get("ngw.payment.cancel.total-due").subscribe((res: string) => {
        totalDue = res;
      });
      return totalDue;
    }
  }

  public getPaymentPreferenceConfirmation(recurringPaymentEntry: RecurringPaymentEntry): PaymentPreference[] {
    let paymentPreferences = new Array();
    let monthlyOrOutstandingPayment = new PaymentPreference();
    if (recurringPaymentEntry.selectedPaymentPreference && recurringPaymentEntry.selectedPaymentPreference.type == 1) {
      this.translateService.get("ngw.recurring-payment.lbl-payment-preference").subscribe((res: string) => {
        monthlyOrOutstandingPayment.label = res;
      });
      let amount = this.currencyPipe.transform(recurringPaymentEntry.getRecurringPaymentViewDataResponse.monthlyPaymentAmount, "USD", "symbol");
      this.translateService.get("ngw.recurring-payment.review.lbl-monthly-payment", { value: amount }).subscribe((res: string) => {
        monthlyOrOutstandingPayment.valueLabel = res;
      });
      paymentPreferences.push(monthlyOrOutstandingPayment);
      let additionalPayment = this.getAdditionalPaymentField(recurringPaymentEntry);
      if (additionalPayment !== null) {
        paymentPreferences.push(additionalPayment);
      }
    } else if (recurringPaymentEntry.selectedPaymentPreference.type == 2) {
      this.translateService.get("ngw.recurring-payment.lbl-payment-preference").subscribe((res: string) => {
        monthlyOrOutstandingPayment.label = res;
      });
      let amount = this.currencyPipe.transform(recurringPaymentEntry.getRecurringPaymentViewDataResponse.monthlyPaymentAmount, "USD", "symbol");
      this.translateService.get("ngw.recurring-payment.entry.lbl-outstanding", { value: amount }).subscribe((res: string) => {
        monthlyOrOutstandingPayment.valueLabel = res;
      });
      paymentPreferences.push(monthlyOrOutstandingPayment);
      let additionalPayment = this.getAdditionalPaymentField(recurringPaymentEntry);
      if (additionalPayment !== null) {
        paymentPreferences.push(additionalPayment);
      }
    }

    return paymentPreferences;
  }

  private getAdditionalPaymentField(recurringPaymentEntry: RecurringPaymentEntry): PaymentPreference {
    if (recurringPaymentEntry.additionalPaymentPreference && recurringPaymentEntry.additionalPaymentPreference.isChecked) {
      let additionalPayment = new PaymentPreference();
      this.translateService.get("ngw.recurring-payment.review.lbl-additional").subscribe((res: string) => {
        additionalPayment.label = res;
      });
      additionalPayment.valueLabel = this.currencyPipe.transform(recurringPaymentEntry.additionalPaymentPreference.value, "USD", "symbol");
      return additionalPayment;
    } else {
      return null;
    }
  }

  public getPostCustomerPaymentFaultErrorMessage(paymentReviewDetails: PaymentReviewDetails): string {
    let errorMessage: string;
    let errorTranslationKey: string = "ngw.global.technical-error";
    switch (paymentReviewDetails.faultType) {
      case FaultCodes.BMWFSAM_Services_CustomerCashManagement_PaymentSystemFault:
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      case FaultCodes.BMWFSAM_Services_CustomerCashManagement_CardPaymentTransactionFailedFault:
        errorTranslationKey = "ngw.payment.review.error.card-payment-transaction-fault";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      case FaultCodes.BMWFSAM_Services_CustomerCashManagement_DuplicatePaymentExceptionFault:
        errorTranslationKey = "ngw.payment.review.error.duplicate-payment";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      case FaultCodes.BMWFSAM_Services_CustomerCashManagement_LumpSumPaymentNotAllowedFault:
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      case FaultCodes.BMWFSAM_Services_CustomerCashManagement_PaymentAmountNotAllowedFault:
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      case FaultCodes.BMWFSAM_Services_CustomerCashManagement_PaymentDateNotAllowedFault:
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      case FaultCodes.BMWFSAM_Services_CustomerCashManagement_AccountIneligibleFault:
        errorTranslationKey = "ngw.payment.review.error.account-ineligible";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      case FaultCodes.BMWFSAM_Services_FinancialAccountNotFoundFault:
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
      default:
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          errorMessage = res;
        })
        break;
    }
    this.analyticsService.pushErrorEvent(errorTranslationKey);
    return errorMessage;
  }

  public getPaymentEmailAddresses(): string[] {
    let primaryEmailAddress: string;
    let billingEmailAddress: string;
    let gcdmUserName: string;
    let emailAddresses: EmailAddressDTO[];
    let paymentEmailAddresses: string[] = new Array();
    let contact: any;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    if (contact && contact.emailAddresses) {
      emailAddresses = contact.emailAddresses;
      for (let emailAddress of emailAddresses) {
        if (emailAddress.isPrimary === true) {
          primaryEmailAddress = emailAddress.emailAddress.toLowerCase();
        }
        else if (emailAddress.emailAddressType === EmailAddressDTOEmailAddressType.Billing) {
          billingEmailAddress = emailAddress.emailAddress.toLowerCase();
        }
      }
    }

    gcdmUserName = this.userService.getUserName().toLowerCase();
    if (primaryEmailAddress) {
      paymentEmailAddresses.push(primaryEmailAddress);
    }
    if (billingEmailAddress) {
      if (billingEmailAddress != primaryEmailAddress) {
        paymentEmailAddresses.push(billingEmailAddress);
      }
    }
    if (gcdmUserName != primaryEmailAddress && gcdmUserName != billingEmailAddress) {
      paymentEmailAddresses.push(gcdmUserName);
    }

    return paymentEmailAddresses;
  }

  public lumpSumAmountAllowed(sourceValue: number, accountPrincipalBalance: number, maxPaymentForToday: number): number {

    if (sourceValue > accountPrincipalBalance || sourceValue > maxPaymentForToday) {
      return accountPrincipalBalance > maxPaymentForToday ? maxPaymentForToday : accountPrincipalBalance
    }

    return sourceValue;
  }

}

