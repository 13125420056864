import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EndOfTermService } from '../../../../_web-services/end-of-term.service';
import { LogSiteActivityService } from '../../../../_web-services/log-site-activity.service';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { UpdateCustomerIntentRequest } from '../../../../core/gateway-api';
import { CustomerIntentService } from '../../../_helper-services/customer-intent.service';
import { UserService } from '../../../_helper-services/user.service';
import { ActivityTypes, ContractTypeText } from '../../../enums';

@Component({
  selector: 'customer-intent-dialog',
  templateUrl: './customer-intent-dialog.component.html',
  styleUrls: ['./customer-intent-dialog.component.scss']
})

export class CustomerIntentDialog extends BaseApplicationComponent<CustomerIntentDialog> implements OnInit {

  intentList: any[] = [];
  errorMsg:string='';
  updateIntentError:boolean;

  constructor(private dialogRef: MatDialogRef<CustomerIntentDialog>, private endOfTermService: EndOfTermService,
    private customerIntentService: CustomerIntentService, private translateService: TranslateService,
    private userService: UserService,
    private logSiteActivityService: LogSiteActivityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) document,
   injector: Injector) {
    super(injector, document);
  }

  ngOnInit() {
    if (this.data.accountType === ContractTypeText.Retail) {
      this.intentList = this.customerIntentService.GetRetialIntentList();
    }
    else if (this.data.accountType === ContractTypeText.Select) {
      this.intentList = this.customerIntentService.GetSelectIntentList();
    }
    else if (this.data.accountType === ContractTypeText.OC) {
      this.intentList = this.customerIntentService.GetOCIntentList();
    }
    else {
      this.intentList = this.customerIntentService.GetLeaseIntentList();
    }

  }

  updateCustomerIntent(index: number) {
    this.setDataLoadedOnRequest(false);
    let request = new UpdateCustomerIntentRequest();
    request.accountNumber = this.data.accountNumber;
    request.conditionCode = this.intentList[index].code;
    this.endOfTermService.updateCustomerIntent(request).subscribe(response => {
      this.postUpdatCustomerIntent(response, request.conditionCode,
        this.intentList[index].acivityType)
    });
  }

  private postUpdatCustomerIntent(response: any, conditionCode: number, acivityType: ActivityTypes) {
    if (response.error) {
      this.setDataLoadedOnRequest(true);
      let errorTranslationKey: string;
      this.updateIntentError = true;
      errorTranslationKey = "ngw.global.technical-error";
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.errorMsg = res;
      });
    }
    else {
      this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.data.accountNumber, acivityType);
      this.dialogRef.close(conditionCode);
    }
  }

}
