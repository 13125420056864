import { Injectable } from "@angular/core";
import { Constants } from "../constants";

@Injectable()
export class DownloadPdfService {
  
    downloadPdf(pdfData:any, fileName:string): void {
      let byteArray = this.base64ToArrayBuffer(pdfData);
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        var blob = new Blob([byteArray], { type: "application/pdf" });
        nav.msSaveOrOpenBlob(blob, fileName.concat(".pdf"));
      }
      else {
        this.saveByteArray(fileName.concat(".pdf"), byteArray)
      }
    }

  private base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  private saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = (window.URL || (window as any).webkitURL).createObjectURL(blob);
    link.setAttribute("download", reportName);
    document.body.appendChild(link);
    setTimeout(function () {
      link.click();
      document.body.removeChild(link);
    }, 66);
  }

  printPdfInNewTab(pdfData: any): void {
    let byteArray = this.base64ToArrayBuffer(pdfData);
    var blobURL = URL.createObjectURL(
      new Blob([byteArray], { type: Constants.FileDownloadTypePdf })
    );
    window.open(blobURL).print();
  }
}
