import {  VehicleReturnEstimateFeesAndCreditsDTO} from "../../core/gateway-api";


export class DispostionFee {
  message: string;
  showButton: boolean;
  //data: VehicleReturnEstimateFeesAndCreditsDTO;
  dispostionData: VehicleReturnEstimateFeesAndCreditsDTO;
}

