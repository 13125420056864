import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { DueDateChangeEntryModel, GetDueDateErrorType, ValidateDueDateErrorType } from '../_models/due-date-change/due-date-change-entry.model';
import { DueDateChangeReviewModel } from '../_models/due-date-change/due-date-change-review.model';
import { ErrorCodes } from '../shared/ErrorCodes';
import { FaultCodes } from '../shared/FaultCodes';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { GetPaymentDueDateRequest, PaymentDueDateServiceClient, PostPaymentDueDateRequest, PostPaymentDueDateResponse, ValidatePaymentDueDateRequest } from './../core/gateway-api';

@Injectable()
export class PaymentDueDateService {
    dueDateModel = DueDateChangeEntryModel;
 
    constructor(private paymentDueDateServiceClient: PaymentDueDateServiceClient,
                private fsTokenErrorHandler: FSTokenErrorHandler) {
    }

   
    public getPaymentDueDate(accountNumber: string): Observable<DueDateChangeEntryModel> {
        let getPaymentDueDateRequest = new GetPaymentDueDateRequest();
        getPaymentDueDateRequest.accountNumber = accountNumber;
        return this.paymentDueDateServiceClient.getPaymentDueDate(getPaymentDueDateRequest)
        .pipe(mergeMap((response) => { return this.afterGetPaymentDueDateSuccess(response); })
        ,catchError((error: any) => { return this.afterGetPaymentDueDateError(error); }));
    }

    public validatePaymentDueDate(accountNumber: string, newDueDay: number): Observable<DueDateChangeEntryModel> {
        let validatePaymentDueDateRequest = new ValidatePaymentDueDateRequest();
        validatePaymentDueDateRequest.accountNumber = accountNumber;
        validatePaymentDueDateRequest.newDueDay = newDueDay;
        return this.paymentDueDateServiceClient.validatePaymentDueDate(validatePaymentDueDateRequest)
        .pipe(mergeMap((response) => { return this.afterValidatePaymentDueDateSuccess(response); })
        ,catchError((error: any) => { return this.afterValidatePaymentDueDateError(error); }));
    }
   
   private afterGetPaymentDueDateSuccess(result: any): Observable<DueDateChangeEntryModel> {
       let dueDateModel = new DueDateChangeEntryModel(result, null);
        return of(dueDateModel);
    }

   private afterGetPaymentDueDateError(error: any): Observable<DueDateChangeEntryModel> {
       this.fsTokenErrorHandler.handleFSTokenError(error);
       let errorType: GetDueDateErrorType = this.getDueDateErrorType(error);
       let dueDateModel = new DueDateChangeEntryModel(null, null, true, errorType, null);        
        
       return of(dueDateModel);
   }

    private afterValidatePaymentDueDateSuccess(result: any): Observable<DueDateChangeEntryModel> {
        let dueDateModel = new DueDateChangeEntryModel(null, result, false, null);   
        return of(dueDateModel);
   }

    private afterValidatePaymentDueDateError(error: any): Observable<DueDateChangeEntryModel> {
       this.fsTokenErrorHandler.handleFSTokenError(error);
       let errorType: ValidateDueDateErrorType = this.getValidateDueDateErrorType(error);       
       let dueDateModel = new DueDateChangeEntryModel(null, null, true, null, errorType);        
        
       return of(dueDateModel);
   }

  public postPaymentDueDate(accountNumber: string, customerNumber: number, newDueDay: number): Observable<DueDateChangeReviewModel> {
        let postPaymentDueDateRequest = new PostPaymentDueDateRequest();
        postPaymentDueDateRequest.accountNumber = accountNumber;
        postPaymentDueDateRequest.customerNumber = customerNumber;
        postPaymentDueDateRequest.newDueDay = newDueDay;
        return this.paymentDueDateServiceClient.postPaymentDueDate(postPaymentDueDateRequest)
            .pipe(mergeMap((response) => { return this.afterPostPaymentDueDateSuccess(response); })
            ,catchError((error: any) => { return this.afterPostPaymentDueDateError(error); }));
    }

  private afterPostPaymentDueDateSuccess(result: PostPaymentDueDateResponse): Observable<DueDateChangeReviewModel> {
    let dueDateReviewModel = new DueDateChangeReviewModel();
    dueDateReviewModel.error = false;
    return of(dueDateReviewModel);
    }

  private afterPostPaymentDueDateError(error: any): Observable<DueDateChangeReviewModel> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    let dueDateReviewModel = new DueDateChangeReviewModel();
    dueDateReviewModel.error = true;
    dueDateReviewModel.faultType = error.faultType;
    return of(dueDateReviewModel);
    }

    private getDueDateErrorType(error: any): GetDueDateErrorType {
       let errorType: GetDueDateErrorType;
       let errorCode: any;      
       
       if (error.faultType) {
           switch (error.faultType) {
               case FaultCodes.BMWFSAM_Services_CustomerCashManagement_GetDueDateFault: {
                   errorType = this.getDueDateErrorCode(JSON.parse(error.response).FaultDetail.IneligibleReasonCode);
                   break;
               }
               default: {
                   errorType = GetDueDateErrorType.GetDueDateFailed;
                   break;
               }
           }
       }
       else {
           errorType = GetDueDateErrorType.GetDueDateFailed;
       }
       return errorType;
   }

   private getDueDateErrorCode(faultCode: any): GetDueDateErrorType {
       let errorCode: GetDueDateErrorType;
       if (faultCode) {
           switch (faultCode) {
               case ErrorCodes.GetDueDate_GetDueDateValidationFailed: {
                   errorCode = GetDueDateErrorType.GetDueDateValidationFailed;
                   break;
               }
               case ErrorCodes.GetAndValidateDueDate_DueDateSemiMonthly: {
                   errorCode = GetDueDateErrorType.DueDateSemiMonthly;
                   break;
               }
               default: {
                  errorCode = GetDueDateErrorType.GetDueDateFailed; 
                  break;        
               }
   
           }      
       }
       else {
           errorCode = GetDueDateErrorType.GetDueDateFailed;
       }
       return errorCode;
   }

  private getValidateDueDateErrorType(error: any): ValidateDueDateErrorType {
         let errorType: ValidateDueDateErrorType;
         let errorCode: any;      
       
         if (error.faultType) {
             switch (error.faultType) {
                 case FaultCodes.BMWFSAM_Services_CustomerCashManagement_GetDueDateFault: {
                     errorType = this.validateDueDateErrorCode(JSON.parse(error.response).FaultDetail.IneligibleReasonCode);
                     break;
                 }
                 default: {
                     errorType = ValidateDueDateErrorType.ValidateDueDateFailed;
                     break;
                 }
             }
         }
         else {
             errorType = ValidateDueDateErrorType.ValidateDueDateFailed;
         }
         return errorType;
     }

     private validateDueDateErrorCode(faultCode: any): ValidateDueDateErrorType {
         let errorCode: ValidateDueDateErrorType;
         if (faultCode) {
             switch (faultCode) {
                 case ErrorCodes.ValidateDueDate_DueDaysAreEqual: {
                     errorCode = ValidateDueDateErrorType.DueDaysAreEqual;
                     break;
                 }
                 case ErrorCodes.ValidateDueDate_InvalidDueDay: {
                     errorCode = ValidateDueDateErrorType.InvalidDueDay;
                     break;
                 }
                 case ErrorCodes.ValidateDueDate_InvalidFinalContractDueDate: {
                   errorCode = ValidateDueDateErrorType.InvalidFinalContractDueDate;
                   break;
                 }
                 case ErrorCodes.ValidateDueDate_BusinessRuleValidationFailed: {
                     errorCode = ValidateDueDateErrorType.BusinessRuleValidationFailed;
                     break;
                 }
                 case ErrorCodes.GetAndValidateDueDate_DueDateSemiMonthly: {
                     errorCode = ValidateDueDateErrorType.DueDateSemiMonthly;
                     break;
                 }
                 case ErrorCodes.ValidateDueDate_ExtendedLease: {
                     errorCode = ValidateDueDateErrorType.ExtendedLease;
                     break;
                 }
                 case ErrorCodes.ValidateDueDate_TerminatedStatus: {
                     errorCode = ValidateDueDateErrorType.TerminatedStatus;
                     break;
                 }
                 case ErrorCodes.ValidateDueDate_ContractType: {
                     errorCode = ValidateDueDateErrorType.ContractType;
                     break;
                 }
                 case ErrorCodes.ValidateDueDate_ActiveDeferral: {
                     errorCode = ValidateDueDateErrorType.ActiveDeferral;
                     break;
                 }
                 default: {
                    errorCode = ValidateDueDateErrorType.ValidateDueDateFailed;   
                    break;      
                 }
   
             }      
         }
         else {
             errorCode = ValidateDueDateErrorType.ValidateDueDateFailed;
         }
         return errorCode;
     }
}
