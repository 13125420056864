import { AnalyticsPageTracking } from '../_models/analytics/analytics-data.model';
import { Constants } from './constants';
import { route } from './_helper-services/router.service';

interface AnalyticsPageDetailComplex {
  path: string,
  details: Partial<AnalyticsPageTracking>,
  name?: never
}

interface AnalyticsPageDetailSimple {
  path: string,
  details?: never,
  name: string
}

export type AnalyticsPageDetail = AnalyticsPageDetailComplex | AnalyticsPageDetailSimple

export const AnalyticsPageDetails: AnalyticsPageDetail[] = [
  {
    path: route.empty, details: {
      name: `${Constants.MyAccount}`,
      pageType: Constants.MyAccount,
      pageLevel2: Constants.MyAccount,
      title: Constants.MyAccount
    }
  },
  {
    path: route.redirect, details: {
      // defined in component to accomodate redirect
    }
  },
  {
    path: route.home, name: Constants.DashboardComponentName,
  },
  {
    path: route.accountdetail, name: route.accountdetail,
  },
  {
    path: route.accounts, name: Constants.Accounts
  },
  { path: route.accountlinks, name: route.accountlinks },
  {
    path: route.login, details: {
      name: `${Constants.MyAccount}:${Constants.LoginComponentName}`,
      pageType: Constants.LoginComponentName,
      pageLevel2: Constants.LoginComponentName,
      title: Constants.LoginComponentName
    }
  },
  {
    path: route.twoFactorAuth, name: Constants.AuthenticationComponentName
  },
  {
    path: route.contactus, name: Constants.ContactUsComponentName
  },
  {
    path: route.error, details: {
      name: `${Constants.MyAccount}:${Constants.ErrorComponentName}`,
      pageType: Constants.ErrorPage,
      pageLevel2: Constants.ErrorComponentName,
      title: Constants.ErrorComponentName
    }
  },
  {
    path: route.serviceerror, details: {
      name: `${Constants.MyAccount}:${route.serviceerror}`,
      pageType: Constants.ErrorPage,
      pageLevel2: route.serviceerror,
      title: route.serviceerror
    }
  },
  {
    path: route.faq, name: Constants.FaqComponentName
  },
  { path: route.forgotpassword, name: route.forgotpassword },
  { path: route.forgotpassworderror, name: route.forgotpassworderror },
  { path: route.forgotpasswordconfirmation, name: route.forgotpasswordconfirmation },
  { path: route.forgotpasswordchange, name: route.forgotpasswordchange },
  { path: route.forgotpasswordsuccess, name: route.forgotpasswordsuccess },

  {
    path: route.legitimization, name: Constants.LegitimizationComponentName
  },
  {
    path: route.maturity, details: {
      name: `${Constants.MyAccount}:${Constants.MaturityBill}`,
      pageType: Constants.MaturityBill,
      pageLevel2: Constants.MaturityBill,
      pageLevel3: Constants.LeaseEndStatementComponentName,
      title: `${Constants.MaturityBill}:${Constants.LeaseEndStatementComponentName}`
    }
  },
  {
    path: route.maturityBilling, details: {
      name: `${Constants.MyAccount}:${Constants.MaturityBill}:${Constants.LeaseEndStatementComponentName}`,
      pageType: Constants.MaturityBill,
      pageLevel2: Constants.MaturityBill,
      pageLevel3: Constants.LeaseEndStatementComponentName,
      title: `${Constants.MaturityBill}:${Constants.LeaseEndStatementComponentName}`
    }
  },
  {
    path: route.estimate, details: {
      name: `${Constants.MyAccount}:${Constants.VehicleEstimate}`,
      pageType: Constants.VehicleReturnEstimateComponentName,
      pageLevel2: Constants.Dashboard,
      pageLevel3: Constants.VehicleReturnEstimateComponentName,
      title: Constants.VehicleEstimate
    }
  },
  {
    path: route.uploadreceipts, details: {
      name: `${Constants.MyAccount}:${Constants.UploadRepairReceiptComponentName}`,
      pageType: Constants.Dashboard,
      pageLevel2: Constants.Dashboard,
      pageLevel3: Constants.Upload,
      title: `${Constants.Dashboard}:${Constants.UploadRepairReceiptComponentName}`
    }
  },
  {
    path: route.viewPaymentSource, details: {
      name: `${Constants.MyAccount}:${Constants.ViewRepairReceiptsComponentName}`,
      pageType: Constants.Dashboard,
      pageLevel2: Constants.Dashboard,
      pageLevel3: Constants.Upload,
      title: `${Constants.Dashboard}:${Constants.ViewRepairReceiptsComponentName}`
    }
  },
  {
    path: route.myaccount, name: Constants.Accounts
  },
  {
    path: `${route.accountdetails}`, details: {
      name: `${Constants.MyAccount}:${Constants.AccountDetailsComponentName}`,
      pageType: Constants.AccountDetailsComponentName,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.AccountDetailsComponentName,
      title: Constants.AccountDetailsComponentName
    }
  },
  {
    path: `${route.payoffinformation}`, details: {
      name: `${Constants.MyAccount}:${Constants.PayoffInformationComponentName}`,
      pageType: Constants.PayoffInformationComponentName,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PayoffInformationComponentName,
      title: Constants.PayoffInformationComponentName
    }
  },
  {
    path: `${route.statements}`, details: {
      name: `${Constants.MyAccount}:${Constants.AccountStatementsComponentName}`,
      pageType: Constants.AccountStatementsComponentName,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.AccountStatementsComponentName,
      title: Constants.AccountStatementsComponentName
    }
  },
  {
    path: `${route.paymentdetails}`, details: {
      name: `${Constants.MyAccount}:${Constants.PaymentDetailsComponentName}`,
      pageType: Constants.PaymentDetailsComponentName,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDetailsComponentName,
      title: Constants.PaymentDetailsComponentName
    }
  },
  {
    path: `${route.notifications}`, details: {
      name: `${Constants.MyAccount}:${Constants.NotificationsComponentName}`,
      pageType: Constants.NotificationsComponentName,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.NotificationsComponentName,
      title: Constants.NotificationsComponentName
    }
  },
  {
    path: route.myProfile, details: {
      name: `${Constants.MyAccount}:${Constants.Profile}`,
      pageType: Constants.MyProfileComponent,
      pageLevel2: Constants.Profile,
      title: Constants.MyProfileComponent
    }
  },
  {
    path: `${route.paymententry}`, details: {
      name: `${Constants.MyAccount}:${Constants.PaymentEntryComponentName}`,
      pageType: Constants.PaymentEntryComponentName,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.MakeAPayment,
      pageLevel4: Constants.Entry,
      title: Constants.PaymentEntryComponentName
    }
  },
  {
    path: `${route.paymentconfirmation}`, details: {
      name: `${Constants.MyAccount}:${Constants.MakeAPayment}:${Constants.Confirmation}`,
      pageType: Constants.PaymentConfirmationComponentName,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.MakeAPayment,
      pageLevel4: Constants.Confirmation,
      title: Constants.PaymentConfirmationComponentName
    }
  },
  {
    path: route.duedateentry, details: {
      name: `${Constants.MyAccount}:${Constants.DueDateChangeEntryComponentName}`,
      pageType: Constants.PaymentDetails,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDetails,
      pageLevel4: Constants.DueDateChangeEntryComponentName,
      title: Constants.DueDateChangeEntryComponentName
    }
  },
  {
    path: route.duedatereview, details: {
      name: `${Constants.MyAccount}:${Constants.DueDateChangeReviewComponentName}`,
      pageType: Constants.PaymentDetails,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDetails,
      pageLevel4: Constants.DueDateChangeReviewComponentName,
      title: Constants.DueDateChangeReviewComponentName
    }
  },
  {
    path: route.duedateconfirmation, details: {
      name: `${Constants.MyAccount}:${Constants.DueDateChangeConfirmationComponentName}`,
      pageType: Constants.PaymentDetails,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDetails,
      pageLevel4: Constants.DueDateChangeConfirmationComponentName,
      title: Constants.DueDateChangeConfirmationComponentName
    }
  },
  {
    path: route.mapconfirmation, details: {
      name: `${Constants.MyAccount}:${Constants.MAP}:${Constants.Confirmation}`,
      pageType: Constants.MAP,
      pageLevel2: Constants.MAP,
      pageLevel3: Constants.Confirmation,
      title: Constants.DiscountMileagePurchase
    }
  },
  {
    path: route.mappayment, details: {
      name: `${Constants.MyAccount}:${Constants.MAP}:${Constants.Payment}`,
      pageType: Constants.MAP,
      pageLevel2: Constants.MAP,
      pageLevel3: Constants.Payment,
      title: Constants.DiscountMileagePurchase
    }
  },
  {
    path: route.mapreview, details: {
      name: `${Constants.MyAccount}:${Constants.MAP}:${Constants.Review}`,
      pageType: Constants.MAP,
      pageLevel2: Constants.MAP,
      pageLevel3: Constants.Review,
      title: Constants.DiscountMileagePurchase
    }
  },
  {
    path: route.recurringpaymententry, details: {
      name: `${Constants.MyAccount}:${Constants.RecurringPaymentEntryComponentName}`,
      pageType: Constants.EasyPay,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.EasyPay,
      pageLevel4: Constants.Entry,
      title: `${Constants.EasyPay}:${Constants.RecurringPaymentEntryComponentName}`
    }
  },
  {
    path: route.recurringpaymentreview, details: {
      name: `${Constants.MyAccount}:${Constants.EasyPay}:${Constants.Review}`,
      pageType: Constants.EasyPay,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.EasyPay,
      pageLevel4: Constants.Review,
      title: `${Constants.EasyPay}:${Constants.RecurringPaymentReviewName}`
    }
  },
  {
    path: route.recurringpaymentconfirmation, details: {
      name: `${Constants.MyAccount}:${Constants.EasyPay}:${Constants.Confirmation}`,
      pageType: Constants.EasyPay,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.EasyPay,
      pageLevel4: Constants.Confirmation,
      title: `${Constants.EasyPay}:${Constants.RecurringPaymentConfirmationComponentName}`
    }
  },
  {
    path: route.smcinbox, details: {
      name: `${Constants.MyAccount}:${Constants.MessageCenter}:${Constants.Inbox}`,
      pageType: Constants.MessageCenter,
      pageLevel2: Constants.MessageCenter,
      pageLevel3: Constants.Inbox,
      title: Constants.SMCInboxComponentName
    }
  },
  {
    path: route.smcview, details: {
      name: `${Constants.MyAccount}:${Constants.MessageCenter}:${Constants.View}`,
      pageType: Constants.MessageCenter,
      pageLevel2: Constants.MessageCenter,
      pageLevel3: Constants.View,
      title: Constants.SMCViewComponentName
    }
  },
  {
    path: route.smccompose, details: {
      name: `${Constants.MyAccount}:${Constants.MessageCenter}:${Constants.Compose}`,
      pageType: Constants.MessageCenter,
      pageLevel2: Constants.MessageCenter,
      pageLevel3: Constants.Compose,
      title: Constants.SMCComposeComponentName
    }
  },
  {
    path: route.clickToSign, name: Constants.ClictoSignComponentName
  },
  {
    path: route.termsView, details: {
      // defined in component to accomodate conditional details
    }
  },
  {
    path: route.acceptTerms, details: {
      name: `${Constants.MyAccount}:${Constants.TermsAcceptComponentName}`
    }
  },
  {
    path: route.leasepayment, details: {
      name: `${Constants.MyAccount}:${Constants.LeaseExtensionPaymentComponentName}`,
      pageType: Constants.Dashboard,
      pageLevel2: Constants.Dashboard,
      pageLevel3: Constants.Entry,
      title: Constants.LeaseExtensionPaymentComponentName
    }
  },
  {
    path: route.leasereview, details: {
      name: `${Constants.MyAccount}:${Constants.LeaseExtensionPaymentReviewComponentName}`,
      pageType: Constants.Dashboard,
      pageLevel2: Constants.Dashboard,
      pageLevel3: Constants.Review,
      title: Constants.LeaseExtensionPaymentReviewComponentName
    }
  },
  {
    path: route.leaseconfirmation, details: {
      name: `${Constants.MyAccount}:${Constants.LeaseExtensionPaymentConfirmationComponentName}`,
      pageType: Constants.Dashboard,
      pageLevel2: Constants.Dashboard,
      pageLevel3: Constants.Confirmation,
      title: Constants.LeaseExtensionPaymentConfirmationComponentName
    }
  },
  {
    path: route.paymentdeferralinfo, details: {
      name: `${Constants.MyAccount}:${Constants.PaymentDeferralInfoComponentName}`,
      pageType: Constants.PaymentDetails,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDeferral,
      title: Constants.PaymentDeferralInfoComponentName
    }
  },
  {
    path: route.paymentdeferralentry, details: {
      name: `${Constants.MyAccount}:${Constants.PaymentDeferralEntryComponentName}`,
      pageType: Constants.PaymentDetails,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDeferral,
      pageLevel4: Constants.Entry,
      title: Constants.PaymentDeferralEntryComponentName
    }
  },
  {
    path: route.paymentdeferralrequest, details: {
      name: `${Constants.MyAccount}:${Constants.PaymentDeferralRequestComponentName}`,
      pageType: Constants.PaymentDetails,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDeferral,
      pageLevel4: Constants.Request,
      title: Constants.PaymentDeferralRequestComponentName
    }
  },
  {
    path: route.paymentdeferralconfirmation, details: {
      name: `${Constants.MyAccount}:${Constants.PaymentDeferralConfirmationComponentName}`,
      pageType: Constants.PaymentDetails,
      pageLevel2: Constants.Accounts,
      pageLevel3: Constants.PaymentDeferral,
      pageLevel4: Constants.Confirmation,
      title: Constants.PaymentDeferralConfirmationComponentName
    }
  },
  { path: route.sso, name: Constants.SsoComponentName },
  { path: route.ssoapp, name: Constants.SsoComponentName },
  { path: route.ssologout, name: Constants.SsoLogoutComponentName },
  { path: route.registration, name: Constants.RegistrationComponentName },
  { path: route.registrationconfirmation, name: Constants.RegistrationCompleteComponentName },
  { path: route.registrationresend, name: Constants.RegistrationCompleteComponentName },
  { path: route.registrationactivation, name: route.registrationactivation },
  { path: route.payment, name: route.payment },
  { path: route.paymentchangeemail, name: route.paymentchangeemail },
  { path: route.paymentpaymentdate, name: route.paymentpaymentdate },
  { path: route.paymententry, name: route.paymententry },
  { path: route.paymentreview, name: route.paymentreview },
  { path: route.paymentconfirmation, name: route.paymentconfirmation },
  { path: route.paymentAmount, name: route.paymentAmount },
  { path: route.addAchPaymentSource, name: route.addAchPaymentSource },
  { path: route.addAchPaymentConfirmation, name: route.addAchPaymentConfirmation },
  { path: route.addPaymentCard, name: route.addPaymentCard },
  { path: route.addPaymentDebit, name: route.addPaymentDebit },
  { path: route.paymentSource, name: route.paymentSource },
  { path: route.addEmailAddress, name: route.addEmailAddress },
  { path: route.addPaymentSource, name: route.addPaymentSource },
  { path: route.paperless, name: Constants.PaperLessStatement },
  { path: route.twoFactorAuth, name: route.twoFactorAuth },
  { path: route.nocode, name: route.nocode },
  { path: route.method, name: route.method },
  { path: route.entercode, name: route.entercode },
  { path: route.rememberme, name: route.rememberme },
  { path: route.easypay, name: route.easypay },
  { path: route.easypayview, name: route.easypayview },
  { path: route.easypaypause, name: route.easypaypause },
  { path: route.easypayresume, name: route.easypayresume },
  { path: route.easypayenroll, name: route.easypayenroll },
  { path: route.easypaynoteligible, name: route.easypaynoteligible },
  { path: route.easypaypaymentsource, name: route.easypaypaymentsource },
  { path: route.easypayviewpaymentsource, name: route.easypayviewpaymentsource },
  { path: route.easypaypaymentamount, name: route.easypaypaymentamount },
  { path: route.easypaystartdate, name: route.easypaystartdate },
  { path: route.easypayreview, name: route.easypayreview },
  { path: route.easypayconfirmation, name: route.easypayconfirmation },
  { path: route.easypayviewpaymentamount, name: route.easypayviewpaymentamount },
  { path: route.easypayunenroll, name: route.easypayunenroll },
  { path: route.easypaypaymentamountoptions, name: route.easypaypaymentamountoptions },
  { path: route.offers, name: route.offers },
  { path: route.amortization, name: route.amortization },
  { path: route.mileagemanagement, name: route.mileagemanagement },
  { path: route.mmagetstarted, name: route.mmagetstarted },
  { path: route.mmacurrentmileage, name: route.mmacurrentmileage },
  { path: route.mmapayment, name: route.mmapayment },
  { path: route.mmasavedquote, name: route.mmasavedquote },
  { path: route.mmaselectmiles, name: route.mmaselectmiles },
  { path: route.mmareview, name: route.mmareview },
  { path: route.mmapaymentoption, name: route.mmapaymentoption },
  { path: route.mmaconfirmation, name: route.mmaconfirmation }
];