import { GetMaturityBillingResponse } from '../../core/gateway-api';

export class MaturityBillingModel {
  error: boolean;
  accountNumber: string;
  maturityBilling: GetMaturityBillingResponse;
  faultType: MaturityBillingFaultType;
}

export enum MaturityBillingFaultType {
  MaturityBillNotFoundFault,
  AccountNotFoundFault
}

