<app-spinner></app-spinner>
<div class="page-payment-source">
  <div class="payment-source-container" [scrollTop]="scrollToTop">
    <app-title [title]="title"></app-title>
    <hr />
    <div class="general error-container" role="alert">
      <em *ngIf="addACHError === true" class="error-validation" id="errorMessage" [innerHtml]="addACHErrorMsg">
      </em>
    </div>
    <form #addACHForm="ngForm" autocomplete="off" (keydown.enter)="showValidationError(addACHForm)">
      <div class="form-group">
        <label class="control-label" for="accountType">{{'ngw.payment-source.ach.account-type' | translate}}</label>

        <mat-radio-group required  [(ngModel)]="accountType" name="accountType">
          <mat-radio-button *ngFor="let achSource of achSources; let i=index;" [value]="achSource.value" id="{{achSource.value}}">
            {{achSource.display}}
          </mat-radio-button>
        </mat-radio-group>

      </div>
      <div class="form-group" [ngClass]="{'has-error': validatorService.showError(addACHForm.controls.routingNumber) || validatorService.showRegexError(addACHForm.controls.routingNumber, 'achRoutingNumber')}">
        <label class="control-label" for="routingNumber">{{'ngw.payment-source.ach.routing-number' | translate}}</label>
        <input (ngModel)="routingNumber" name="routingNumber" required id="routingNumber" type="text" class="form-control" #routingNumber="ngModel"/>
        <em class="error-validation" id="routingNumberRequiredError" *ngIf="validatorService.showError(addACHForm.controls.routingNumber, 'required')">{{"ngw.validation.error.required" | translate}}</em>
        <em class="error-validation" id="routingNumberPatternError" *ngIf="validatorService.showRegexError(addACHForm.controls.routingNumber, 'achRoutingNumber', showError)">{{"ngw.payment-source.ach.error.invalid-routing" | translate}}</em>
      </div>
      <div class="form-group" [ngClass]="{'has-error': validatorService.showError(addACHForm.controls.accountNumber) || validatorService.showRegexError(addACHForm.controls.accountNumber, 'achAccountNumber')}">
        <label class="control-label" for="accountNumber">{{'ngw.payment-source.ach.account-number' | translate}}</label>
        <input (ngModel)="accountNumber" name="accountNumber" required id="accountNumber" type="text" class="form-control" (paste)="false" #accountNumber="ngModel" accountNumberMask />
        <em class="error-validation" id="accountNumberRequiredError" *ngIf="validatorService.showError(addACHForm.controls.accountNumber, 'required')">{{"ngw.validation.error.required" | translate}}</em>
        <em class="error-validation" id="accountNumberPatternError" *ngIf="validatorService.showRegexError(addACHForm.controls.accountNumber, 'achAccountNumber', showError)">{{"ngw.payment-source.ach.error.account-number-invalid" | translate}}</em>
      </div>
      <div class="form-group" [ngClass]="{'has-error': validatorService.showError(addACHForm.controls.confirmAccountNumber) || validatorService.showConfirmPasswordError(confirmAccountNumber, accountNumber)}">
        <label class="control-label" for="confirmAccountNumber">{{'ngw.payment-source.ach.confirm-account-number' | translate}}</label>
        <input (ngModel)="confirmAccountNumber" name="confirmAccountNumber" required id="confirmAccountNumber" type="text" class="form-control" #confirmAccountNumber="ngModel" (paste)="false" accountNumberMask />
        <em class="error-validation" id="confirmAccountNumberRequiredError" *ngIf="validatorService.showError(addACHForm.controls.confirmAccountNumber, 'required')">{{'ngw.payment-source.ach.error.reconfirm-account-number' | translate}}</em>
        <em class="error-validation" id="confirmAccountNumberMatchError" *ngIf="validatorService.showConfirmPasswordError(confirmAccountNumber, accountNumber)">{{'ngw.payment-source.ach.error.account-numbers-do-not-match' | translate}}</em>
      </div>
      <div *ngIf="!mustRetain" class="retain-checkbox">
        <mat-checkbox name="retain" [(ngModel)]="retain">
          {{'ngw.payment-source.ach.lbl-retain' | translate}}
        </mat-checkbox>
      </div>
      <p>{{'ngw.payment-source.ach.sub-title' | translate}}</p>
      <div class="action-buttons">
        <button type="button" (click)="cancel()" class="btn btn-secondary" id="cancelAddACHBtn" analytics-event="body.cancel-add-ach-payment-source.cancel-add-ach-payment-source.button">
          {{'ngw.general.btn-cancel' | translate}}</button>
        <button type="submit" (click)="addAch(addACHForm.value)" class="btn btn-primary" id="addACHBtn" [disabled]="addACHForm.invalid || validatorService.showError(addACHForm.controls.routingNumber, 'required') || validatorService.showRegexError(addACHForm.controls.routingNumber, 'achRoutingNumber', showError) || validatorService.showError(addACHForm.controls.accountNumber, 'required') || validatorService.showRegexError(addACHForm.controls.accountNumber, 'achAccountNumber', showError) || validatorService.showConfirmPasswordError(confirmAccountNumber, accountNumber)">{{'ngw.payment-source.ach.save' | translate}}</button>
      </div>
    </form>
  </div>
</div>
