import { Injectable } from '@angular/core';
import { FinanceProduct, AccountStatus, UserAction, VehicleImageAngle, VehicleImageQuality } from '../enums';
import { Constants } from '../constants';
import { ContractAccountDetailDTO, ContractAccountDetailDTOContractType, FinancialProductDTO, AddressTypes, AddressDTO, ContractAccountDetailDTOAccountStatus, VehicleShadowDTO } from '../../core/gateway-api';
import * as _ from 'lodash-es';
import { IAppState } from "../../shared/store/app.store";
import { Store } from '@ngrx/store';
import { SortedActiveContractAccountDetailsActions } from '../store/reducers/sorted-active-contract-account-details.reducers';
import { SortedTerminatedContractAccountDetailsActions } from '../store/reducers/sorted-terminated-contract-account-details.reducers';
import { ContractAccountDetailsActions } from '../store/reducers/contract-account-details.reducers';
import { ContractAccountDetailActions } from '../store/reducers/contract-account-detail.reducers';
import { PayoffsActions } from '../store/reducers/payoffs.reducers';
import { AutopaysActions } from '../store/reducers/autopays.reducers';
import { EasypaySchedulesActions } from '../store/reducers/easypay-schedules.reducers';
import { ContactActions } from '../store/reducers/contact.reducers';
import { ScheduledItemsActions } from '../store/reducers/scheduled-items.reducers';
import { StatementCollectionActions } from '../store/reducers/statement-collection.reducers';
import { PaymentEntryActions } from '../store/reducers/payment-entry.reducer';
import { RecurringPaymentEntryActions } from '../store/reducers/recurring-payment.reducer';
import { UserTokenActions } from '../store/reducers/smc-user-token.reducer';
import { UserActionActions } from '../store/reducers/user-action.reducer';
import { AccountActivitiesActions } from '../store/reducers/account-activities.reducers';
import { DeepLinkParamActions } from "../store/reducers/deeplink-param.reducer";
import { VehicleImageModel } from '../../_models/contract/vehicle-image.model';
import { VehicleImageActions } from '../store/reducers/vehicle-image.reducers';
import { DueDateChangeReviewActions } from '../store/reducers/due-date-change-review.reducer';
import { PreferencesActions } from '../store/reducers/preferences.reducers';
import { CustomerBulletinActions } from '../store/reducers/customer-bulletin.reducer';
import { CosyURLEncoder } from '../../core/cosy-url-encoder';
import { TranslateService } from '@ngx-translate/core';
import { ObjectService } from './object.service';
import { MaturityBillingModel } from '../../_models/contract/maturity-billing.model';
import { ConditionCode } from '../enums';
import { PipeService } from './pipe.service';
import { LeaseExtActions } from '../store/reducers/lease-extension.reducer';

@Injectable()
//this service should be used for any account/contract info/data manipulation
export class AccountInfoService {

  constructor(private store: Store<IAppState>,
    private contractAccountDetailsActions: ContractAccountDetailsActions,
    private sortedActiveContractAccountDetailsActions: SortedActiveContractAccountDetailsActions,
    private sortedTerminatedContractAccountDetailsActions: SortedTerminatedContractAccountDetailsActions,
    private contractAccountDetailActions: ContractAccountDetailActions,
    private autopaysActions: AutopaysActions,
    private payoffsActions: PayoffsActions,
    private statementCollectionActions: StatementCollectionActions,
    private scheduledItemsActions: ScheduledItemsActions,
    private paymentEntryActions: PaymentEntryActions,
    private recurringPaymentEntryActions: RecurringPaymentEntryActions,
    private contactActions: ContactActions,
    private userTokenActions: UserTokenActions,
    private userActionActions: UserActionActions,
    private easypaySchedulesActions: EasypaySchedulesActions,
    private accountActivitiesActions: AccountActivitiesActions,
    private deepLinkParamActions: DeepLinkParamActions,
    private dueDateChangeReviewActions: DueDateChangeReviewActions,
    private vehicleImageActions: VehicleImageActions,
    private preferencesActions: PreferencesActions,
    private customerBulletinActions: CustomerBulletinActions,
    private objectService: ObjectService,
    private translateService: TranslateService,
    private pipeService: PipeService, 
  private leaseExtActions: LeaseExtActions) { }

  public getAccountCategory(portfolioCategoryCode: string): string {
    let lease, financing: string;
    this.translateService.get("ngw.general.lease").subscribe((res: string) => {
      lease = res;
    });
    this.translateService.get("ngw.general.financing").subscribe((res: string) => {
      financing = res;
    });
    if (portfolioCategoryCode === FinanceProduct.Lease.toString()) {
      return lease;
    }
    else if (portfolioCategoryCode === FinanceProduct.Loan.toString()) {
      return financing;
    }
    else {
      return Constants.EMPTY;
    }
  }

  public getAccountStatusLabel(portfolioCategoryCode: string): string {
    let account: string;
    this.translateService.get("ngw.general.account").subscribe((res: string) => {
      account = res;
    });
    let accountType: string = this.getAccountCategory(portfolioCategoryCode);
    if (account && accountType) {
      return accountType.concat(' ', account);
    }
    return Constants.EMPTY;
  }

  public getAccountTypeLabel(portfolioCategoryCode: string): string {
    let leaseAccount, financingAccount: string;
    this.translateService.get("ngw.general.lease-account").subscribe((res: string) => {
      leaseAccount = res;
    });
    this.translateService.get("ngw.general.financing-account").subscribe((res: string) => {
      financingAccount = res;
    });
    if (portfolioCategoryCode === FinanceProduct.Lease.toString()) {
      return leaseAccount;
    }
    else if (portfolioCategoryCode === FinanceProduct.Loan.toString()) {
      return financingAccount;
    }
    else {
      return Constants.EMPTY;
    }
  }
  public getTotalextendedMiles(contractDetail: ContractAccountDetailDTO): number {
    let months = contractDetail.currentContractEndDate.getMonth() - contractDetail.originalContractEndDate.getMonth() + (12 * (contractDetail.currentContractEndDate.getFullYear() - contractDetail.originalContractEndDate.getFullYear()));
    return (contractDetail.annualMileage / 12 * months);
  }
  public getTotalAllowedMiles(contractDetail: ContractAccountDetailDTO): number {
    return contractDetail.additionalPurchasedMiles + contractDetail.milesAtInception + contractDetail.totalContractMileage;
  }

  public getAdditionalPurchasedMiles(additionalPurchasedMiles: number): number {
    return !additionalPurchasedMiles ? 0 : additionalPurchasedMiles;
  }

  public getInterestPaidYearToDateAmount(interestPaid: number): number {
    return interestPaid !== null ? interestPaid : 0;
  }

  public getAutomatedPayments(accountHasPaymentSchedule: boolean): string {
    return accountHasPaymentSchedule ? Constants.ENROLLED : Constants.NOT_ENROLLED;
  }

  public isLeaseAccount(portfolioCategoryCode): boolean {
    return portfolioCategoryCode === FinanceProduct.Lease.toString();
  }

  public isAccountActiveOrExtendedLease(accountStatus: ContractAccountDetailDTOAccountStatus): boolean {
    return accountStatus == ContractAccountDetailDTOAccountStatus.Active
      || accountStatus == ContractAccountDetailDTOAccountStatus.ExtendedLease;
  }

  public isActiveAccount(statusCategoryCode: string): boolean {
    return statusCategoryCode == AccountStatus.Active.toString();
  }

  public isTerminatedAccount(statusCategoryCode: any): boolean {
    return statusCategoryCode == AccountStatus.Terminated.toString();
  }

  public isOwnersChoiceContractType(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.OwnersChoiceRetail;
  }

  public isOwnersChoiceOrOCExtensionContractType(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.OwnersChoiceRetail || contractType === ContractAccountDetailDTOContractType.OCExtension;
  }

  public isLeaseContractType(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.Lease;
  }

  public isPrePayLeaseContractType(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.PrePayLease;
  }

  public isRetailRefiDirectContractType(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.RetailRefiDirect;
  }

  public isReLeaseContractType(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.ReLease;
  }

  public checkContactEndDate(contract: ContractAccountDetailDTO): boolean {
    let todayDate = new Date();
    let daysToExtendLease: number;
    this.store.select(state => state.ApplicationConfig.DAYS_FOR_LEASE_EXTENSION).subscribe(x => daysToExtendLease = x);
    let futureDate = new Date(todayDate.setDate(todayDate.getDate() + daysToExtendLease));
    let originalMaturityDate = new Date(contract.originalContractEndDate);
    let isAtValidDaysToOriginalContractEndDate = (originalMaturityDate <= futureDate) ? true : false;
    return isAtValidDaysToOriginalContractEndDate && this.isActiveAccount(contract.statusCategoryCode.toString());
  }

  public isLeaseOrOCContractType(contractType: ContractAccountDetailDTOContractType) {
    return this.isLeaseContractType(contractType) || this.isOwnersChoiceContractType(contractType);
  }

  public isSelectAccount(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.Select;
  }

  public isPrePayLeaseType(contractType: ContractAccountDetailDTOContractType) {
    return this.isPrePayLeaseContractType(contractType);
  }

  public isReLeaseType(contractType: ContractAccountDetailDTOContractType) {
    return this.isReLeaseContractType(contractType);
  }

  public isRetailRefiDirectLeaseType(contractType: ContractAccountDetailDTOContractType) {
    return this.isRetailRefiDirectContractType(contractType);
  }

  public isPrePayLeaseOrOCContractType(contractType: ContractAccountDetailDTOContractType) {
    return this.isPrePayLeaseContractType(contractType) || this.isOwnersChoiceContractType(contractType);
  }

  public isReLeaseOrOCContractType(contractType: ContractAccountDetailDTOContractType) {
    return this.isReLeaseContractType(contractType) || this.isOwnersChoiceContractType(contractType);
  }

  public isRetailRefiDirectLeaseOrOCContractType(contractType: ContractAccountDetailDTOContractType) {
    return this.isRetailRefiDirectContractType(contractType) || this.isOwnersChoiceContractType(contractType);
  }

  public isRetailAccount(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.Retail;
  }

  public isRetailContractType(contractType: ContractAccountDetailDTOContractType): boolean {
    switch (contractType) {
      case ContractAccountDetailDTOContractType.Retail:
      case ContractAccountDetailDTOContractType.ServiceLoaner:
      case ContractAccountDetailDTOContractType.LeasetoRetail:
      case ContractAccountDetailDTOContractType.RetailRefiDirect:
      case ContractAccountDetailDTOContractType.RetailRefiIndirect:
      case ContractAccountDetailDTOContractType.PrePayBuyback:
      case ContractAccountDetailDTOContractType.CDC:
      case ContractAccountDetailDTOContractType.FinanceLease:
        return true;
      default:
        return false;
    }
  }

  public isSelectOrRetailContractType(contractType: ContractAccountDetailDTOContractType) {
    return this.isSelectAccount(contractType) || this.isRetailAccount(contractType);
  }

  public showPreviousPaymentInfo(statusCategoryCode: any, portfolioCategoryCode: any, contractType: ContractAccountDetailDTOContractType): boolean {
    if (this.isTerminatedAccount(statusCategoryCode) && (this.isLeaseAccount(portfolioCategoryCode) || this.isOwnersChoiceContractType(contractType))) {
      return false;
    }
    return true;
  }

  public isPastDue(pastDueAmount: number) {
    return pastDueAmount > 0;
  }

  public isActiveContract(accountNumber: string): boolean {
    let contractAccountNumber: string;
    this.store.select(state => state.ContractAccountDetail.accountNumber).subscribe(x => contractAccountNumber = x);
    return accountNumber === contractAccountNumber;
  }

  public isMaturityBilled(accountNumber: string): boolean {
    let maturityBillings: MaturityBillingModel[];
    this.store.select(state => state.MaturityBillings).subscribe(x => maturityBillings = x);
    let maturityBilling = _.find(maturityBillings, function (maturityBilling) { return maturityBilling.accountNumber === accountNumber; });
    return maturityBilling ? true : false;
  }

  public getMaturityBillingDate(accountNumber: string): any {
    let maturityBillings: MaturityBillingModel[];
    this.store.select(state => state.MaturityBillings).subscribe(x => maturityBillings = x);
    let maturityDate;
    if (maturityBillings && maturityBillings.length > 0) {
      let maturityBilling: MaturityBillingModel = _.find(maturityBillings, function (maturityBilling) { return maturityBilling.accountNumber === accountNumber; });
      if (maturityBilling && maturityBilling.maturityBilling && maturityBilling.maturityBilling.maturityBillinDate) {
        maturityDate = new Date(maturityBilling.maturityBilling.maturityBillinDate);
      }
    }
    return maturityDate;
  }

  public isOnlyOneContract() {
    let contractDetails: ContractAccountDetailDTO[];
    this.store.select(state => state.ContractAccountDetails).subscribe(x => contractDetails = x);
    return contractDetails && Object.keys(contractDetails).length === 1;
  }


  public getVehicleImageData(accountNumber: string, vechicleImageAngleType?: VehicleImageAngle, quality?: VehicleImageQuality): string {
    let vehicleImageData: string
    let vehicleImage: VehicleImageModel;
    let vehicleImages: VehicleImageModel[];
    this.store.select(state => state.VehicleImages).subscribe(x => vehicleImages = x);
    if (vehicleImages) {
      vehicleImage = _.find(vehicleImages, function (vi) {
        return vi.financialProductId === accountNumber;
      });
    }
    if (vehicleImage) {
      switch (vechicleImageAngleType) {
        case VehicleImageAngle.Front: {
          vehicleImageData = quality && quality == VehicleImageQuality.Low
            ? vehicleImage.vehicleImageFrontLowQuality
            : vehicleImage.vehicleImageFrontHighQuality;
          break;
        }
        case VehicleImageAngle.Angled: {
          vehicleImageData = quality && quality == VehicleImageQuality.Low
            ? vehicleImage.vehicleImageAngledLowQuality
            : vehicleImage.vehicleImageAngledHighQuality;
          break;
        }
        default: {
          vehicleImageData = vehicleImage.vehicleImageData;
          break;
        }
      }
    }
    else {
      vehicleImageData = null;
    }
    return vehicleImageData;
  }

  public getContractInfo(contract: ContractAccountDetailDTO): string {
    if (contract && !this.objectService.isEmptyObject(contract))
      return contract.year + " " + contract.model;
    else return Constants.EMPTY;
  }

  public getContractInfoWithAccNo(contract: ContractAccountDetailDTO): string {
    if (contract && !this.objectService.isEmptyObject(contract)) {
      return this.getAccountTypeLabel(contract.portfolioCategoryCode.toString()) + " | " + contract.accountNumber;
    }
    else {
      return "";
    }
  }

  public getContractTypeLabel(contract: ContractAccountDetailDTO): string {
    if (contract) {
      let accountString: string;
      this.translateService.get("ngw.payment.header.account-string").subscribe((res: string) => {
        accountString = res;
      })
      return this.getAccountCategory(contract.portfolioCategoryCode.toString()) + accountString;
    }
    else {
      return "";
    }
  }

  public changeContract(contract: ContractAccountDetailDTO) {
    this.store.dispatch(this.contractAccountDetailActions.setContractAccountDetail(contract));
  }

  public hasAmountDue(amountDue: number): boolean {
    return amountDue > 0;
  }

  public isLeaseEndContract(accountNumber: string, amountDue: number) {
    return this.isMaturityBilled(accountNumber) && this.hasAmountDue(amountDue);
  }

  public clearCache(clearAll, keepDeeplink?: boolean) {
    if (!clearAll) {
      let _contractAccountDetail: ContractAccountDetailDTO;
      this.store.select(state => state.ContractAccountDetail).subscribe(x => _contractAccountDetail = x);
      this.store.dispatch(this.payoffsActions.removePayoff(_contractAccountDetail.fSAccountId));
      this.store.dispatch(this.autopaysActions.removeAutopay(_contractAccountDetail.accountNumber));
      this.store.dispatch(this.accountActivitiesActions.removeAccountActivity(_contractAccountDetail.accountNumber));
    } else {
      this.store.dispatch(this.payoffsActions.clearPayoffs());
      this.store.dispatch(this.autopaysActions.clearAutopays());
      this.store.dispatch(this.accountActivitiesActions.clearAccountActivities());
      this.store.dispatch(this.preferencesActions.clearPreferences());
    }
    this.store.dispatch(this.contactActions.clearContact());
    this.store.dispatch(this.contractAccountDetailsActions.clearContracts());
    this.store.dispatch(this.sortedActiveContractAccountDetailsActions.clearSortedActiveContracts());
    this.store.dispatch(this.sortedTerminatedContractAccountDetailsActions.clearSortedTerminatedContracts());
    this.store.dispatch(this.contractAccountDetailActions.clearContractAccountDetail());
    this.store.dispatch(this.scheduledItemsActions.clearScheduledItems());
    this.store.dispatch(this.statementCollectionActions.clearStatementCollection());
    this.store.dispatch(this.paymentEntryActions.clearPaymentEntry());
    this.store.dispatch(this.recurringPaymentEntryActions.clearRecurringPaymentEntry());
    this.store.dispatch(this.userTokenActions.clearSecureMessageUserToken());
    this.store.dispatch(this.leaseExtActions.clearLeaseExtDaysCount());
    this.store.dispatch(this.userActionActions.setUserAction(UserAction[UserAction.Logout]));
    if (!keepDeeplink) {
      this.store.dispatch(this.deepLinkParamActions.clearDeepLinkParam());
    }
    this.store.dispatch(this.dueDateChangeReviewActions.clearDueDateChangeReview());
    this.store.dispatch(this.customerBulletinActions.clearCustomerBulletin());
    this.store.dispatch(this.easypaySchedulesActions.clearEasypaySchedules());
  }

  public clearContracts() {
    this.store.dispatch(this.contractAccountDetailsActions.clearContracts());
  }

  public getSortedAccounts(activeContracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let sortedContracts: ContractAccountDetailDTO[] = null;
    if (activeContracts) {
      sortedContracts = _.orderBy(activeContracts, ['year', 'make', 'model'], ['desc', 'asc', 'asc']);
    }
    return sortedContracts;
  }

  public getLoanAccounts(contracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let self = this;
    let loanAccounts = _.filter(contracts, function (contract: ContractAccountDetailDTO) {
      return !self.isLeaseAccount(contract.portfolioCategoryCode);
    });
    return loanAccounts;
  }

  public getActiveAccounts(contracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let self = this;
    let activeAccounts = _.filter(contracts, function (contract: ContractAccountDetailDTO) {
      return self.isActiveAccount(contract.statusCategoryCode.toString());
    });
    return activeAccounts;
  }

  public getActiveAccountCount(contracts: ContractAccountDetailDTO[]): number {
    let self = this;
    let activeContracts = self.getActiveAccounts(contracts);
    let contractsSize: number;
    if (activeContracts) {
      contractsSize = _.size(activeContracts);
    }
    else {
      contractsSize = 0;
    }

    return contractsSize;
  }

  public getPastDuesAccounts(contracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let self = this;
    let pastDueAccounts = _.filter(contracts, function (contract: ContractAccountDetailDTO) {
      return self.isActiveAccount(contract.statusCategoryCode.toString()) && self.isPastDue(contract.pastDueAmount);
    });
    return pastDueAccounts;
  }

  public getTerminatedLeaseAccountsWithMaturityBillBalance(contracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let self = this;
    let terminatedLeaseAccounts = _.filter(contracts, function (contract: ContractAccountDetailDTO) {
      return self.isLeaseEndContract(contract.accountNumber, contract.totalAmountDue);
    });
    return terminatedLeaseAccounts;
  }

  public getTerminatedLeaseAccountsWithMaturityBillWithNoBalance(contracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let self = this;
    let terminatedLeaseAccounts = _.filter(contracts, function (contract: ContractAccountDetailDTO) {
      return self.isMaturityBilled(contract.accountNumber) && !self.hasAmountDue(contract.totalAmountDue);
    });
    return terminatedLeaseAccounts;
  }

  public getTerminatedAccounts(contracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let self = this;
    let terminatedAccounts = _.filter(contracts, function (contract: ContractAccountDetailDTO) {
      return self.isTerminatedAccount(contract.statusCategoryCode.toString());
    });
    return terminatedAccounts;
  }

  private getLowQualityImage(defaultImage: string): string {
    let qualityReplacedImg = defaultImage.replace(Constants.VEHICLE_IMAGE_DEFAULT_QUALITY, Constants.VEHICLE_IMAGE_LOW_QUALITY);
    let qualityAndSizeReplacedImg = qualityReplacedImg.replace(Constants.VEHICLE_IMAGE_DEFAULT_SIZE, Constants.VEHICLE_IMAGE_SMALL_SIZE);
    return qualityAndSizeReplacedImg;
  }

  private getEncryptedImage(defaultImage: string, cosyUrl: string): string {
    return cosyUrl.concat('?', CosyURLEncoder.encode2(defaultImage)) as string;
  }

  public enCodeAndSaveCosyUrl(financialProducts: FinancialProductDTO[]): void {
    let vehicleImageFrontHighQuality: string;
    let vehicleImageFrontLowQuality: string;
    let vehicleImageAngledHighQuality: string;
    let vehicleImageAngledLowQuality: string;

    let self = this;

    _.each(financialProducts, (financialProduct: FinancialProductDTO) => {
      vehicleImageFrontHighQuality = vehicleImageAngledHighQuality = vehicleImageFrontLowQuality = vehicleImageAngledLowQuality = Constants.EMPTY;
      if (financialProduct.vehicleImageData) {
        vehicleImageFrontHighQuality = financialProduct.vehicleImageData.replace(Constants.VEHICLE_IMAGE_DEFAULT_ANGLE, Constants.VEHICLE_IMAGE_FRONT_ANGLE);
        vehicleImageFrontLowQuality = this.getLowQualityImage(vehicleImageFrontHighQuality);
        vehicleImageAngledHighQuality = financialProduct.vehicleImageData.replace(Constants.VEHICLE_IMAGE_DEFAULT_ANGLE, Constants.VEHICLE_IMAGE_ANGLED);
        vehicleImageAngledLowQuality = this.getLowQualityImage(vehicleImageAngledHighQuality);
      }
      //if it's already encrypted - don't encrypt
      let cosyURL: string;
      const objCopy = {...financialProduct};
      this.store.select(state => state.EnvironmentConfig.COSY_URL).subscribe(x => cosyURL = x);
      if (financialProduct.vehicleImageData && financialProduct.vehicleImageData.indexOf(cosyURL) < 0) {
        //encrypt the image url
       
        objCopy.vehicleImageData = this.getEncryptedImage(financialProduct.vehicleImageData, cosyURL) as string;
        vehicleImageFrontHighQuality = this.getEncryptedImage(vehicleImageFrontHighQuality, cosyURL);
        vehicleImageFrontLowQuality = this.getEncryptedImage(vehicleImageFrontLowQuality, cosyURL);
        vehicleImageAngledHighQuality = this.getEncryptedImage(vehicleImageAngledHighQuality, cosyURL);
        vehicleImageAngledLowQuality = this.getEncryptedImage(vehicleImageAngledLowQuality, cosyURL);
      }

      let vehicleModel = new VehicleImageModel(objCopy.vehicleImageData,
        financialProduct.financialProductId,
        vehicleImageFrontHighQuality,
        vehicleImageFrontLowQuality,
        vehicleImageAngledHighQuality,
        vehicleImageAngledLowQuality)
      self.store.dispatch(self.vehicleImageActions.pushVehicleImage(vehicleModel));
    });
  }

  public setContractDetails(contracts?: ContractAccountDetailDTO[], accountNumber?: string): void {
    if (contracts && contracts.length > 0) {
      let contractDetail: ContractAccountDetailDTO;
      if (accountNumber) {
        contractDetail = _.find(contracts, function (contractDetail: ContractAccountDetailDTO) {
          return contractDetail.accountNumber === accountNumber;
        });
      }
      else {
        contractDetail = contracts[0];
      }
      if (contractDetail) {
        this.store.dispatch(this.contractAccountDetailActions.setContractAccountDetail(contractDetail));
      }
    }
  }

  public isActiveLeaseOrSelectAccountAtLeaseEnd(contractDetail: ContractAccountDetailDTO): boolean {
    let isActiveLease: boolean = contractDetail && contractDetail.statusCategoryCode &&
      this.isActiveAccount(contractDetail.statusCategoryCode.toString()) &&
      this.isLeaseAccount(contractDetail.portfolioCategoryCode);
    let isSelectAccountAtLeaseEnd: boolean = this.isSelectAccount(contractDetail.contractType) &&
      (this.dateDiff(new Date(), contractDetail.currentContractEndDate) <= 270);
    let isLeaseAccountAtleaseEnd = (this.dateDiff(new Date(), contractDetail.currentContractEndDate) <= 180);
    return (isActiveLease && isLeaseAccountAtleaseEnd) || (isActiveLease && isSelectAccountAtLeaseEnd);
  }

  public isActiveSelectAccountAtLeaseEnd(contractDetail: ContractAccountDetailDTO): boolean {
    let isSelectAccountAtLeaseEnd: boolean = contractDetail && contractDetail.statusCategoryCode &&
      this.isActiveAccount(contractDetail.statusCategoryCode.toString()) &&
      this.isSelectAccount(contractDetail.contractType) &&
      (this.dateDiff(new Date(), contractDetail.currentContractEndDate) <= 270);
    return isSelectAccountAtLeaseEnd;
  }

  public isEligibleForLeaseExtn(contractDetail: ContractAccountDetailDTO): boolean {
    let isActiveLease: boolean = contractDetail && contractDetail.statusCategoryCode &&
      this.isActiveAccount(contractDetail.statusCategoryCode.toString()) &&
      this.isLeaseAccount(contractDetail.portfolioCategoryCode);
    let todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    let daysToExtendLease: number;
    this.store.select(state => state.ApplicationConfig.DAYS_FOR_LEASE_EXTENSION).subscribe(x => daysToExtendLease = x);
    let orgnlMaturityDate = new Date(contractDetail.originalContractEndDate);
    orgnlMaturityDate.setDate(orgnlMaturityDate.getDate() - daysToExtendLease);
    let isAtValidDaysToOriginalContractEndDate = (todayDate >= orgnlMaturityDate);
    return isActiveLease && isAtValidDaysToOriginalContractEndDate;
  }

  public dateDiff(startDate: Date, endDate: Date): number {
    let diff = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  public getSortedActiveContractsForHomePage(activeContracts: ContractAccountDetailDTO[]) {
    let pastDueContracts = _.filter(activeContracts, function (product) {
      return product && product.pastDueAmount > 0;
    });
    let currentContracts = _.filter(activeContracts, function (product) {
      return product && product.totalAmountDue > 0 && product.pastDueAmount <= 0;
    });
    let paidContracts = _.filter(activeContracts, function (product) {
      return product && product.totalAmountDue <= 0 && product.pastDueAmount <= 0;
    });
    let orderedPastDueContracts = _.orderBy(pastDueContracts, [function (pastDueContract) {
      return new Date(pastDueContract.maxDaysOver);
    }, 'year', 'model'], ['desc', 'desc', 'asc']);
    let orderedCurrentContracts = _.orderBy(currentContracts, function (currentContract) {
      return new Date(currentContract.nextPaymentDueDate);
    });
    let orderedPaidContracts = _.sortBy(paidContracts, function (paidContract) {
      return new Date(paidContract.nextPaymentDueDate);
    });
    let orderedContracts = orderedPastDueContracts.concat(orderedCurrentContracts, orderedPaidContracts);
    return orderedContracts;
  }

  public getSortedActiveContractsAndTerminated(contracts: ContractAccountDetailDTO[]) {
    let activeContracts = this.getActiveAccounts(contracts);
    let orderedContracts = activeContracts.concat(this.getTerminatedAccounts(contracts));
    return orderedContracts;
  }

  public getNoOfDaysLeftInContract(contractEndDate: Date) {
    // Calculate # of days from today's date to contract end data
    let noOfdays: number = Math.abs(Math.round((new Date().getTime() - contractEndDate.getTime()) / (1000 * 3600 * 24)));
    return noOfdays;
  }

  public isContractEndDateLessThanOrEqualMonths(contractEndDate: Date, months: number) {
    let date: Date = new Date();
    return date > this.subMonths(contractEndDate, months);
  }

  public isContractEndDateMoreThanMonths(contractEndDate: Date, months: number) {
    let date: Date = new Date();
    return date < this.subMonths(contractEndDate, months);
  }

  public isLeaseContractEndLessThanOrEqual18Month(contract: ContractAccountDetailDTO) {
    if (this.isLeaseAccount(contract.portfolioCategoryCode)) {
      let date: Date = new Date();
      return date > this.subMonths(contract.currentContractEndDate, 18);
    }
  }

  public isLoanContractEndLessThanOrEqual18Month(contract: ContractAccountDetailDTO) {
    if (!this.isLeaseAccount(contract.portfolioCategoryCode)) {
      let date: Date = new Date();
      return date > this.subMonths(contract.currentContractEndDate, 18);
    }
  }

  public provinceCode(contract: ContractAccountDetailDTO): string {
    let primaryAddress = this.gePrimaryAddress(contract);
    return primaryAddress ? primaryAddress.state : Constants.EMPTY;
  }

  public retailerId(contract: ContractAccountDetailDTO): string {
    return contract.originatingDealerNumber;
  }

  public gePrimaryAddress(contract: ContractAccountDetailDTO): AddressDTO {
    let address = _.find(contract.addresses, function (address) {
      return address.addressTypes.indexOf(AddressTypes.Primary) > -1;
    });
    return address;
  }

  public getMailingAddress(contract: ContractAccountDetailDTO): AddressDTO {
    let address = _.find(contract.addresses, function (address) {
      return address.addressTypes.indexOf(AddressTypes.Payor) > -1;
    });
    return address;
  }

  public getGaragingAddress(contract: ContractAccountDetailDTO): AddressDTO {
    let address = _.find(contract.addresses, function (address) {
      return address.addressTypes.indexOf(AddressTypes.Driver) > -1;
    });
    return address;
  }

  private isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0))
      || (year % 400 === 0));
  }

  private getDaysInMonth(year, month) {
    return [31, (this.isLeapYear(year) ? 29 : 28),
      31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  private subMonths(date, value) {
    var d = new Date(date),
      n = date.getDate() + 1;
    d.setDate(1);
    d.setMonth(d.getMonth() - value);
    d.setDate(Math.min(n, this.getDaysInMonth(d.getFullYear(),
      d.getMonth())));
    return d;
  }

  public isTerminatedAccountWithBalanceDue(contract: ContractAccountDetailDTO) {
    return (contract.totalAmountDue > 0 && contract.statusCategoryCode.toString() == AccountStatus.Terminated.toString());
  }

  public isMaturityBillingPastFifteenDays(maturityBillingDate: Date): boolean {
    let todaysDate = new Date();
    return this.dateDiff(maturityBillingDate, todaysDate) > Constants.MaxMaturityBillDueDaysAllowed + 1;
  }

  public isAccountConditionCodeExist(contract: ContractAccountDetailDTO, accountConditionCode: ConditionCode): boolean {
    let accountWithConditionCode = _.find(contract.conditionCodes, function (accountCondition) { return accountCondition.conditionCode == accountConditionCode; })
    return accountWithConditionCode ? true : false;
  }

  public isHoldMaturityBillingLetterForAnyAccount(contracts: ContractAccountDetailDTO[], maturityBillings: MaturityBillingModel[]): boolean {
    let self = this;
    let isHoldMaturityBillingLetterForAnyAccount: boolean = true;
    _.each(maturityBillings, function (maturityBilling) {
      let maturityAccount = _.find(contracts, function (contractAccountDetail) { return contractAccountDetail.accountNumber == maturityBilling.accountNumber; })
      if (maturityAccount) {
        let isHoldMaturityBillingLetter = self.isAccountConditionCodeExist(maturityAccount, ConditionCode.HoldMaturityBillingLetter);
        if (!isHoldMaturityBillingLetter) {
          isHoldMaturityBillingLetterForAnyAccount = false;
          return;
        }
      }
    });
    return isHoldMaturityBillingLetterForAnyAccount;
  }

  public isChargeOfStatusAccount(accountStatus: ContractAccountDetailDTOAccountStatus): boolean {
    return accountStatus == ContractAccountDetailDTOAccountStatus.ChargeOff;
  }

  public isEligibleForOtherPayment(contract: ContractAccountDetailDTO): boolean {
    let accountWithConditionCode = _.find(contract.conditionCodes, function (accountCondition) { return accountCondition.conditionCode == ConditionCode.OutForRepo || accountCondition.conditionCode == ConditionCode.LegalGeneralLitigation || accountCondition.conditionCode == ConditionCode.LegalHold || accountCondition.conditionCode == ConditionCode.PaymentFreeze || accountCondition.conditionCode == ConditionCode.CanadaFraud; })
    return accountWithConditionCode ? false : true;
  }

  public isConditionCodeEligibleForLeaseExt(contract: ContractAccountDetailDTO): boolean {
    let accountWithConditionCode = _.find(contract.conditionCodes, function (accountCondition) {
      return accountCondition.conditionCode == ConditionCode.InsDefNew ||
        accountCondition.conditionCode == ConditionCode.LegalGeneralLitigation ||
        accountCondition.conditionCode == ConditionCode.InsDefExpired ||
        accountCondition.conditionCode == ConditionCode.InsDefCancelled ||
        accountCondition.conditionCode == ConditionCode.InsDefInadequate ||
        accountCondition.conditionCode == ConditionCode.InsDefInsLoss ||
        accountCondition.conditionCode == ConditionCode.InsDefFTC ||
        accountCondition.conditionCode == ConditionCode.InsuranceDeficiency;
    })
    return accountWithConditionCode ? false : true;
  }

  public isOutstandingBillExist(contract: ContractAccountDetailDTO): boolean {
    return (contract.psdFees && contract.psdFees.length > 0);
  }

  public getOutstandingBillAccountsForOtherPayment(contracts: ContractAccountDetailDTO[]): ContractAccountDetailDTO[] {
    let self = this;
    let outstandingBillAccounts = _.filter(contracts, function (contract: ContractAccountDetailDTO) {
      return self.isOutstandingBillExist(contract) && self.isEligibleForOtherPayment(contract) && self.isActiveAccount(contract.statusCategoryCode.toString()) && !self.isChargeOfStatusAccount(contract.accountStatus);
    });
    return outstandingBillAccounts;
  }

  public showOtherPayment(contract: ContractAccountDetailDTO): boolean {
    let self = this;
    return self.isOutstandingBillExist(contract) && self.isEligibleForOtherPayment(contract) && self.isActiveAccount(contract.statusCategoryCode.toString()) && !self.isChargeOfStatusAccount(contract.accountStatus);
  }

  public getCachedVehicleShadowData(contrct: ContractAccountDetailDTO): VehicleShadowDTO {
    let vehicleShadows: VehicleShadowDTO[];
    this.store.select(state => state.VehicleShadow).subscribe(x => vehicleShadows = x);
    if (vehicleShadows && vehicleShadows.length > 0) {
      let cachedVehicleShadowData: VehicleShadowDTO = _.find(vehicleShadows, function (vehicleShadowData) { return vehicleShadowData.vin === contrct.vIN; });
      return (cachedVehicleShadowData) ? cachedVehicleShadowData : null;
    }

    return null;
  }

  public getFormattedDueDate(contractDetail: ContractAccountDetailDTO): string {
    return this.pipeService.getDateAsFormattedDate(contractDetail.nextPaymentDueDate, true);
  }

  public isCountryCanada(contract: ContractAccountDetailDTO): boolean {
    return contract.countryCode.codeValue === Constants.Country_CAN;
  }

  public eligibleForLumpSumPayment(contract: ContractAccountDetailDTO): boolean {
    if (contract && contract.statusCategoryCode && contract.contractType) {
      let self = this;
      return self.isActiveAccount(contract.statusCategoryCode.toString()) && (self.isRetailContractType(contract.contractType) || self.isOwnersChoiceOrOCExtensionContractType(contract.contractType));
    }

    return false;
  }
}
