import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { CalculateMiles } from '../_models/map/calculate-miles.model';
import {
    AcceptAndPayMidtermMileageAdjustmentQuote,
    AcceptMidtermMileageAdjustmentQuote,
    MidtermMileageAdjustmentQuote
} from '../_models/map/midterm-mileage-adjustment-quote.model';
import { MMAAgreementPdf, MidtermMileageAdjustment } from '../_models/map/midterm-mileage-adjustment.model';
import { SelectMiles } from '../_models/map/select-miles.model';
import { ObjectService } from '../shared/_helper-services/object.service';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from "../shared/store/app.store";
import { MapQuotesActions } from '../shared/store/reducers/map-quotes.reducers';
import {
    AcceptAndPayMidtermMileageAdjustmentQuoteRequest,
    AcceptAndPayMidtermMileageAdjustmentQuoteRequestChannel,
    AcceptAndPayMidtermMileageAdjustmentQuoteRequestPaymentFeeType,
    AcceptAndPayMidtermMileageAdjustmentQuoteRequestPaymentSource,
    AcceptAndPayMidtermMileageAdjustmentQuoteRequestSystem,
    AcceptMidtermMileageAdjustmentQuoteRequest,
    CalculateMidtermMileageAdjustmentRequest,
    GetMidtermMileageAdjustmentAgreementPdfRequest,
    GetMidtermMileageAdjustmentQuoteRequest,
    GetMidtermMileageAdjustmentQuoteResponse,
    GetMidtermMileageAdjustmentRequest,
    GetMidtermMileageAdjustmentResponse,
    GetMileageCalculationRequest,
    MMAQuoteDTO,
    MidtermMileageAdjustmentServiceClient,
    PostCustomerPaymentRequest,
    SaveMidtermMileageAdjustmentQuoteRequest
} from './../core/gateway-api';
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class MidtermMileageAdjustmentService {
    storeAppConfig: ApplicationConfig;
    constructor(private midtermMileageAdjustmentServiceClient: MidtermMileageAdjustmentServiceClient,
        private store: Store<IAppState>,
        private objectService: ObjectService,
        private userService: UserService,
        private mapQuotesActions: MapQuotesActions) {
    }

    public getMileageCalculation(accountNumber: string, currentMileage: number): Observable<SelectMiles> {
        let getMileageCalculationRequest = new GetMileageCalculationRequest;
        getMileageCalculationRequest.accountNumber = accountNumber;
        getMileageCalculationRequest.currentMileage = currentMileage;
        return this.midtermMileageAdjustmentServiceClient.getMileageCalculation(getMileageCalculationRequest)
            .pipe(mergeMap((response) => { return this.afterGetMileageCalculationSuccess(response); })
                , catchError((error: any) => { return this.afterGetMileageCalculationFailure(error); }));
    }

    public getMidtermMileageAdjustment(accountNumber: string): Observable<MidtermMileageAdjustment> {
        let getMidtermMileageAdjustmentRequest = new GetMidtermMileageAdjustmentRequest();
        getMidtermMileageAdjustmentRequest.accountNumber = accountNumber;
        return this.midtermMileageAdjustmentServiceClient.getMidtermMileageAdjustment(getMidtermMileageAdjustmentRequest)
            .pipe(mergeMap((response) => { return this.afterGetMidtermMileageAdjustmentSuccess(response); })
                , catchError((error: any) => { return this.afterGetMidtermMileageAdjustmentFailure(error); }));
    }

    public getMidtermMileageAdjustmentQuote(accountNumber: string, quoteId: number = 0): Observable<MidtermMileageAdjustmentQuote> {
        this.store.dispatch(this.mapQuotesActions.clearQuotes());
        let getMidtermMileageAdjustmentQuoteRequest = new GetMidtermMileageAdjustmentQuoteRequest();
        getMidtermMileageAdjustmentQuoteRequest.accountNumber = accountNumber;
        if (quoteId) {
            getMidtermMileageAdjustmentQuoteRequest.mMAQuoteID = quoteId;
        }
        return this.midtermMileageAdjustmentServiceClient.getMidtermMileageAdjustmentQuote(getMidtermMileageAdjustmentQuoteRequest)
            .pipe(mergeMap((response) => { return this.afterGetMidtermMileageAdjustmentQuoteSuccess(response); })
                , catchError((error: any) => { return this.afterGetMidtermMileageAdjustmentQuoteFailure(error); }));
    }

    public saveMidtermMileageAdjustmentQuote(accountNumber: string, purchaseMiles: number, startDate: Date, isMonthlyPayment: boolean): Observable<boolean> {
        let saveMidtermMileageAdjustmentQuoteRequest = new SaveMidtermMileageAdjustmentQuoteRequest();
        saveMidtermMileageAdjustmentQuoteRequest.accountNumber = accountNumber;
        saveMidtermMileageAdjustmentQuoteRequest.purchaseMiles = purchaseMiles;
        saveMidtermMileageAdjustmentQuoteRequest.mMAStartDate = startDate;
        saveMidtermMileageAdjustmentQuoteRequest.paymentOptionCode = isMonthlyPayment ? 0 : 1;
        return this.midtermMileageAdjustmentServiceClient.saveMidtermMileageAdjustmentQuote(saveMidtermMileageAdjustmentQuoteRequest)
            .pipe(mergeMap(() => { return of(true); })
                , catchError(() => { return of(false); }));
    }

    public calculateMidTermMileageAdjustment(accountNumber: string, startDate: Date, purchaseMiles: number): Observable<CalculateMiles> {
        let calculateMidtermMileageAdjustmentRequest = new CalculateMidtermMileageAdjustmentRequest;
        calculateMidtermMileageAdjustmentRequest.accountNumber = accountNumber;
        calculateMidtermMileageAdjustmentRequest.mMAStartDate = startDate;
        calculateMidtermMileageAdjustmentRequest.purchaseMiles = purchaseMiles;
        return this.midtermMileageAdjustmentServiceClient.calculateMidtermMileageAdjustment(calculateMidtermMileageAdjustmentRequest)
            .pipe(mergeMap((response) => { return this.afterCalculateMidTermMileageAdjustmentSuccess(response); })
                , catchError((error: any) => { return this.afterCalculateMidTermMileageAdjustmentFailure(error); }));
    }

    public acceptMidTermMileageAdjustmentQuote(accountNumber: string, quoteId: number): Observable<AcceptMidtermMileageAdjustmentQuote> {
        let acceptMidtermMileageAdjustmentQuoteRequest = new AcceptMidtermMileageAdjustmentQuoteRequest;
        acceptMidtermMileageAdjustmentQuoteRequest.accountNumber = accountNumber;
        acceptMidtermMileageAdjustmentQuoteRequest.mMAQuoteID = quoteId;
        return this.midtermMileageAdjustmentServiceClient.acceptMidtermMileageAdjustmentQuote(acceptMidtermMileageAdjustmentQuoteRequest)
            .pipe(mergeMap(() => { return this.postAcceptMidtermMileageAdjustmentQuoteSuccess(); })
                , catchError((result) => { return this.postAcceptMidtermMileageAdjustmentQuoteFailure(result); }));
    }

    private postAcceptMidtermMileageAdjustmentQuoteSuccess(): Observable<AcceptMidtermMileageAdjustmentQuote> {
        let response = new AcceptMidtermMileageAdjustmentQuote();
        response.error = false;
        return of(response);
    }

    private postAcceptMidtermMileageAdjustmentQuoteFailure(result: any): Observable<AcceptMidtermMileageAdjustmentQuote> {
        let response = new AcceptMidtermMileageAdjustmentQuote();
        response.error = true;
        response.faultType = result.faultType;
        return of(response);
    }

    private afterGetMidtermMileageAdjustmentSuccess(result: GetMidtermMileageAdjustmentResponse): Observable<MidtermMileageAdjustment> {
        let midtermMileageAdjustment = new MidtermMileageAdjustment();
        midtermMileageAdjustment.midtermMileageAdjustmentData = result;
        midtermMileageAdjustment.error = false;
        return of(midtermMileageAdjustment);
    }

    private afterGetMidtermMileageAdjustmentFailure(result: any): Observable<MidtermMileageAdjustment> {
        let midtermMileageAdjustment = new MidtermMileageAdjustment();
        midtermMileageAdjustment.midtermMileageAdjustmentData = null;
        midtermMileageAdjustment.error = true;
        return of(midtermMileageAdjustment);
    }

    private afterGetMidtermMileageAdjustmentQuoteSuccess(result: GetMidtermMileageAdjustmentQuoteResponse): Observable<MidtermMileageAdjustmentQuote> {
        let midtermMileageAdjustmentQuote = new MidtermMileageAdjustmentQuote();
        let filteredQuotes = _.filter(result.mMAQuoteList, (quote) => { return this.quoteIsNotExpiredAndNotAccepted(quote) });
        let orderedQuotes = _.orderBy(filteredQuotes, ['quoteDate'], ['desc']);
        let quotes = _.take(orderedQuotes, 5);
        midtermMileageAdjustmentQuote.quoteList = quotes;
        this.store.dispatch(this.mapQuotesActions.setQuotes(quotes));
        midtermMileageAdjustmentQuote.error = false;
        return of(midtermMileageAdjustmentQuote);
    }

    private afterGetMidtermMileageAdjustmentQuoteFailure(error: any): Observable<MidtermMileageAdjustmentQuote> {
        let midtermMileageAdjustmentQuote = new MidtermMileageAdjustmentQuote();
        midtermMileageAdjustmentQuote.quoteList = null;
        this.store.dispatch(this.mapQuotesActions.clearQuotes());
        midtermMileageAdjustmentQuote.error = true;
        return of(midtermMileageAdjustmentQuote);
    }

    private quoteIsNotExpiredAndNotAccepted(quote: MMAQuoteDTO): boolean {
        let currentDate: Date = new Date();
        let quoteIsNotExpired: boolean = quote.goodThruDate > currentDate;
        let quoteIsNotAccepted: boolean = !quote.quoteAccepted;
        return quoteIsNotExpired && quoteIsNotAccepted;
    }

    private afterGetMileageCalculationSuccess(result: any): Observable<SelectMiles> {
        let selectMilesModel = this.getSelectMilesModel(null, result);
        return of(selectMilesModel);
    }

    private afterGetMileageCalculationFailure(error: any): Observable<SelectMiles> {
        let selectMilesModel = this.getSelectMilesModel(error, null);
        return of(selectMilesModel);
    }

    private getSelectMilesModel(error: any, response: any): SelectMiles {
        let selectMiles = new SelectMiles();
        if (error) {
            selectMiles.error = true;
            selectMiles.faultType = error.faultType;
        }
        else if (response) {
            selectMiles.error = false;
            selectMiles.mileageCalculation = response;
        }
        return selectMiles;
    }

    private afterCalculateMidTermMileageAdjustmentSuccess(result: any): Observable<CalculateMiles> {
        let calculateMilesModel = this.getCalculateMilesModel(null, result);
        return of(calculateMilesModel);
    }

    private afterCalculateMidTermMileageAdjustmentFailure(error: any): Observable<CalculateMiles> {
        let calculateMilesModel = this.getCalculateMilesModel(error, null);
        return of(calculateMilesModel);
    }

    private getCalculateMilesModel(error: any, response: any): CalculateMiles {
        let calculateMiles = new CalculateMiles();
        if (error) {
            calculateMiles.error = true;
            calculateMiles.faultType = error.faultType;
        }
        else if (response) {
            calculateMiles.error = false;
            calculateMiles.midtermMileageCalculation = response;
        }
        return calculateMiles;
    }

    public acceptAndPayMidtermMileageAdjustmentQuote(acceptAndPayMidtermMileageAdjustmentQuoteRequest: AcceptAndPayMidtermMileageAdjustmentQuoteRequest): Observable<AcceptAndPayMidtermMileageAdjustmentQuote> {
        return this.midtermMileageAdjustmentServiceClient.acceptAndPayMidtermMileageAdjustmentQuote(acceptAndPayMidtermMileageAdjustmentQuoteRequest)
            .pipe(mergeMap((result) => { return this.postAcceptAndPayMidtermMileageAdjustmentQuoteSuccess(result); })
                , catchError((result) => { return this.postAcceptAndPayMidtermMileageAdjustmentQuoteFailure(result); }));
    }

    private postAcceptAndPayMidtermMileageAdjustmentQuoteSuccess(result: any): Observable<AcceptAndPayMidtermMileageAdjustmentQuote> {
        let acceptAndPayMidtermMileageAdjustmentQuote = new AcceptAndPayMidtermMileageAdjustmentQuote();
        acceptAndPayMidtermMileageAdjustmentQuote.error = false;
        acceptAndPayMidtermMileageAdjustmentQuote.paymentTranId = result.paymentTranId;
        return of(acceptAndPayMidtermMileageAdjustmentQuote);
    }

    private postAcceptAndPayMidtermMileageAdjustmentQuoteFailure(result: any): Observable<AcceptAndPayMidtermMileageAdjustmentQuote> {
        let acceptAndPayMidtermMileageAdjustmentQuote = new AcceptAndPayMidtermMileageAdjustmentQuote();
        acceptAndPayMidtermMileageAdjustmentQuote.error = true;
        acceptAndPayMidtermMileageAdjustmentQuote.faultType = result.faultType;
        return of(acceptAndPayMidtermMileageAdjustmentQuote);
    }

    public getAcceptAndPayMidtermMileageAdjustmentQuoteRequest(mMAQuoteID: number, accountNumber: string, postCustomerPaymentRequest: PostCustomerPaymentRequest, emailAddress: string, isMonthlyPayment: boolean): AcceptAndPayMidtermMileageAdjustmentQuoteRequest {
        let acceptAndPayMidtermMileageAdjustmentQuoteRequest = new AcceptAndPayMidtermMileageAdjustmentQuoteRequest();
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.mMAQuoteID = mMAQuoteID;
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.accountNumber = accountNumber;
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.channel = AcceptAndPayMidtermMileageAdjustmentQuoteRequestChannel.Web;
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.isThirdPartyMakingPayment = false;
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.shouldSendEmail = true;
        this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.siteId = this.storeAppConfig.SITEID.toString();
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.system = AcceptAndPayMidtermMileageAdjustmentQuoteRequestSystem.MyBMW;
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.userId = this.storeAppConfig.USER_ID;
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.paymentSource = AcceptAndPayMidtermMileageAdjustmentQuoteRequestPaymentSource.Unknown;
        acceptAndPayMidtermMileageAdjustmentQuoteRequest.emailAddress = emailAddress;

        //single payment option - payment details
        if (!isMonthlyPayment) {
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.customerNumber = postCustomerPaymentRequest.customerNumber;
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.emailAddress = postCustomerPaymentRequest.emailAddress;
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.financialAccountId = postCustomerPaymentRequest.financialAccountId;
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.lumpSumAmount = postCustomerPaymentRequest.lumpSumAmount;
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.originalPaymentAmount = postCustomerPaymentRequest.originalPaymentAmount;
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.paymentDate = postCustomerPaymentRequest.paymentDate;
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.paymentFeeType = AcceptAndPayMidtermMileageAdjustmentQuoteRequestPaymentFeeType[postCustomerPaymentRequest.paymentFeeType.toString()];
            acceptAndPayMidtermMileageAdjustmentQuoteRequest.paymentSource = AcceptAndPayMidtermMileageAdjustmentQuoteRequestPaymentSource[postCustomerPaymentRequest.paymentSource.toString()];
        }
        return acceptAndPayMidtermMileageAdjustmentQuoteRequest;
    }

    public getMidtermMileageAdjustmentAgreementPdf(accountNumber: string, mmaQuoteId: number): Observable<MMAAgreementPdf> {
        let getMidtermMileageAdjustmentAgreementPdfRequest = new GetMidtermMileageAdjustmentAgreementPdfRequest();
        getMidtermMileageAdjustmentAgreementPdfRequest.accountNumber = accountNumber;
        getMidtermMileageAdjustmentAgreementPdfRequest.customerNumber = this.userService.getCustomerNumber();
        this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
        getMidtermMileageAdjustmentAgreementPdfRequest.userId = this.storeAppConfig.USER_ID;
        getMidtermMileageAdjustmentAgreementPdfRequest.mmaQuoteId = mmaQuoteId;
        return this.midtermMileageAdjustmentServiceClient.getMidtermMileageAdjustmentAgreementPdf(getMidtermMileageAdjustmentAgreementPdfRequest)
            .pipe(mergeMap((response) => { return this.afterGetMidtermMileageAdjustmentAgreementPdfSuccess(response); })
                , catchError((error: any) => { return this.afterGetMidtermMileageAdjustmentAgreementPdfFailure(error); }));
    }

    private afterGetMidtermMileageAdjustmentAgreementPdfSuccess(result: any): Observable<MMAAgreementPdf> {
        let mmaAgreementPdf = new MMAAgreementPdf();
        mmaAgreementPdf.error = false;
        mmaAgreementPdf.agreementDocumentData = result ? result.legalAgreementBase64 : undefined;
        return of(mmaAgreementPdf);
    }

    private afterGetMidtermMileageAdjustmentAgreementPdfFailure(error: any): Observable<MMAAgreementPdf> {
        let mmaAgreementPdf = new MMAAgreementPdf();
        mmaAgreementPdf.error = true;
        mmaAgreementPdf.faultType = error.faultType;
        return of(mmaAgreementPdf);
    }
}
