import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountInfoService } from "../../../shared/_helper-services/account-info.service";
import { RouterService } from '../../_helper-services/router.service';
import { DialogService } from '../../_helper-services/dialog.service';
import { ContractAccountDetailDTO } from '../../../core/gateway-api';
import { Store } from '@ngrx/store';
import { IAppState } from "./../../../shared/store/app.store";


@Component({
    selector: 'app-contract-header',
    templateUrl: './contract-header.component.html',
    styleUrls: ['contract-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContractHeaderComponent implements OnInit, OnDestroy {
    dialogService: DialogService;
    accountString: string;
    isActiveContract: boolean;
    contractYearAndModel: string;
    contractTypeAndNumber: string;
    vehicleImageData: string;

    private contractAccountDetailSubscription: any;


    @Input() shouldDisableCurrentContract: boolean;
    @Input() contractDetail: ContractAccountDetailDTO;

    constructor(private accountInfoService: AccountInfoService,
        private routerService: RouterService,
        private translateService: TranslateService,
        private store: Store<IAppState>,
        _dialogService: DialogService) {
        this.contractAccountDetailSubscription = this.store.select(state => state.ContractAccountDetail)
            .subscribe(contract => this.setData(contract));
        this.dialogService = _dialogService;
        this.translateService.get("ngw.payment.header.account-string").subscribe((res: string) => {
            this.accountString = res;
        })
    }

    ngOnInit() {
        this.setData(this.contractDetail);
    }


    ngOnDestroy() {
        if (this.contractAccountDetailSubscription)
            this.contractAccountDetailSubscription.unsubscribe();
    }

    private setData(contract: ContractAccountDetailDTO): void {
        this.vehicleImageData = this.accountInfoService.getVehicleImageData(contract.accountNumber);
        this.isActiveContract = this.accountInfoService.isActiveContract(contract.accountNumber)
        this.contractYearAndModel = this.accountInfoService.getContractInfo(contract);
        this.contractTypeAndNumber = this.accountInfoService.getContractInfoWithAccNo(contract);
    }

}
