import { Injectable } from '@angular/core';
import { GenerateSecondFactorTokenStatusErrorType } from '../../_models/generate-second-factor-token-status.model';
import { TranslateService } from '@ngx-translate/core';
import { FaultCodes } from '../FaultCodes';
import { ErrorMessagingModel } from '../../_models/error-messaging.model';
import { GenerateMultiFactorTokenStatusErrorType } from '../../_models/generate-multi-factor-token-status.model';
import { AnalyticsService } from './analytics.service';

@Injectable()
export class ErrorMessagingService {

  constructor(
    private translateService: TranslateService,
    private analyticsService: AnalyticsService) { }

  public getGenerateMultiFactorTokenErrorMessage(errorType: GenerateMultiFactorTokenStatusErrorType): ErrorMessagingModel {
    let errorMessagingModel = new ErrorMessagingModel();
    const messageKey = 'ngw.global.technical-error';
    switch (errorType) {
      case GenerateMultiFactorTokenStatusErrorType.MFAFailed:
        this.translateService.get(messageKey).subscribe((res: string) => {
          errorMessagingModel.errorMessage = res;
        });
        errorMessagingModel.errorLogDescription = "general service error";
        break;
      default:
        this.translateService.get(messageKey).subscribe((res: string) => {
          errorMessagingModel.errorMessage = res;
        });
        errorMessagingModel.errorLogDescription = "general service error";
        break;
      }
      this.analyticsService.pushErrorEvent(messageKey);
      return errorMessagingModel;
    }

  public getGenerateSecondFactorTokenErrorMessage(errorType: GenerateSecondFactorTokenStatusErrorType): ErrorMessagingModel{
    let errorMessagingModel = new ErrorMessagingModel();
      switch (errorType) {
        case GenerateSecondFactorTokenStatusErrorType.TokenUnexpired:
          this.translateService.get("ngw.user-management.authentication.error.token-exists").subscribe((res: string) => {
            errorMessagingModel.errorMessage = res;
          });
          errorMessagingModel.errorLogDescription = FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_TokenUnexpired_V201109_TokenUnexpiredFault;
          break;
        case GenerateSecondFactorTokenStatusErrorType.UserNotFound:
          this.translateService.get("ngw.user-management.login.error.account-locked").subscribe((res: string) => {
            errorMessagingModel.errorMessage = res;
          });
          errorMessagingModel.errorLogDescription = FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserNotFound_V201109_UserNotFoundFault;
          break;
        case GenerateSecondFactorTokenStatusErrorType.UserNameDisabled:
          this.translateService.get("ngw.user-management.login.error.account-locked").subscribe((res: string) => {
            errorMessagingModel.errorMessage = res;
          });
          errorMessagingModel.errorLogDescription = FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UsernameDisabled_V201109_UsernameDisabledFault;
          break;
        case GenerateSecondFactorTokenStatusErrorType.UserAccountPasswordLocked:
          this.translateService.get("ngw.user-management.login.error.account-locked").subscribe((res: string) => {
            errorMessagingModel.errorMessage = res;
          });
          errorMessagingModel.errorLogDescription = FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserAccountPasswordLocked_V201109_UserAccountPasswordLockedFault;
          break;
        case GenerateSecondFactorTokenStatusErrorType.DataValidation:
          this.translateService.get("ngw.manage-settings.change-password.error.operation-failed").subscribe((res: string) => {
            errorMessagingModel.errorMessage = res;
          });
          errorMessagingModel.errorLogDescription = FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_DataValidation_V201112_DataValidationFault;
          break;
        default:
          this.translateService.get("ngw.global.technical-error").subscribe((res: string) => {
            errorMessagingModel.errorMessage = res;
          });
          errorMessagingModel.errorLogDescription = "general service error";
          break;
    }
    return errorMessagingModel;
    }

   

}

