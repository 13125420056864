import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Autopay } from '../_models/my-account/autopay';
import { PaymentInfo } from '../_models/payment.model';
import { RecurringPaymentEntry } from '../_models/recurring-payment/recurring-payment-entry.model';
import { ObjectService } from '../shared/_helper-services/object.service';
import { UserService } from '../shared/_helper-services/user.service';
import { Constants } from "../shared/constants";
import { IAppState } from "../shared/store/app.store";
import {
  CountryCode,

  GetPaymentPostponementViewDataRequest,
  GetPaymentPostponementViewDataRequestChannel,
  GetPaymentPostponementViewDataRequestSystemCode,
  GetPaymentPostponementViewDataResponse,
  GetRecurringPaymentViewDataRequest,
  GetRecurringPaymentViewDataRequestChannel,
  GetSinglePaymentViewDataRequest,
  GetSinglePaymentViewDataRequestChannel,
  GetSinglePaymentViewDataRequestSystemCode,
  PaymentViewServiceClient
} from './../core/gateway-api';
import { FSTokenErrorHandler } from './../shared/_errorhandler/gobal-error-handler';
import { AutopaysActions } from './../shared/store/reducers/autopays.reducers';
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class PaymentViewService {
  storeAppConfig: ApplicationConfig;
  constructor(private paymentViewServiceClient: PaymentViewServiceClient,
    private userService: UserService,
    private autopaysActions: AutopaysActions,
    private store: Store<IAppState>,
    private objectService: ObjectService,
    private fsTokenErrorHandler: FSTokenErrorHandler) {
  }

  public getPaymentPostponementViewData(accountNumber: string): Observable<PaymentInfo> {
    let getPaymentPostponementViewDataRequest = new GetPaymentPostponementViewDataRequest();
    getPaymentPostponementViewDataRequest.accountNumber = accountNumber.toString();
    getPaymentPostponementViewDataRequest.customerNumber = this.userService.getCustomerNumber();
    getPaymentPostponementViewDataRequest.channel = GetPaymentPostponementViewDataRequestChannel.Web;
    getPaymentPostponementViewDataRequest.systemCode = GetPaymentPostponementViewDataRequestSystemCode.CanadaOwnersCircle;
    return this.paymentViewServiceClient.getPaymentPostponementViewData(getPaymentPostponementViewDataRequest)
      .pipe(mergeMap((response) => { return this.afterGetPaymentPostponementViewDataSuccess(response); })
        , catchError((error: any) => { return this.afterGetPaymentPostponementViewDataFailure(error); }));

  }

  private afterGetPaymentPostponementViewDataSuccess(result: GetPaymentPostponementViewDataResponse): Observable<PaymentInfo> {
    let paymentInfo = new PaymentInfo();
    paymentInfo.error = false;
    paymentInfo.paymentAccounts = result.paymentAccounts;
    paymentInfo.aCHRestrictions = result.paymentRestrictions;
    paymentInfo.scheduledItemToHold = result.scheduledItemToHold;
    return of(paymentInfo);
  }

  private afterGetPaymentPostponementViewDataFailure(error: any): Observable<PaymentInfo> {
    let paymentInfo = new PaymentInfo();
    paymentInfo.error = true;
    paymentInfo.faultType = error.faultType;
    paymentInfo.errorResponse = error.response;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(paymentInfo);
  }

  public getSinglePaymentViewData(accountNumber: string, refresh: boolean): Observable<Autopay> {
    let cachedAutopayModel = this.getCachedAutopay(accountNumber);
    if (refresh || this.objectService.isEmptyObject(cachedAutopayModel) || cachedAutopayModel == null) {
      let getSinglePaymentViewDataRequest = new GetSinglePaymentViewDataRequest();
      getSinglePaymentViewDataRequest.accountNumber = accountNumber.toString();
      return this.paymentViewServiceClient.getSinglePaymentViewData(getSinglePaymentViewDataRequest)
        .pipe(mergeMap((response) => { return this.afterGetSinglePaymentViewDataSuccess(accountNumber, response, refresh); })
          , catchError((error: any) => { return this.afterGetSinglePaymentViewDataFailure(accountNumber, error); }));
    }
    return of(this.getCachedAutopay(accountNumber));
  }

  public getRecurringPaymentViewData(accountNumber: string): Observable<RecurringPaymentEntry> {
    let request = this.getRecurringPaymentRequest(accountNumber);
    return this.paymentViewServiceClient.getRecurringPaymentViewData(request)
      .pipe(mergeMap((response) => { return this.afterGetRecurringPaymentViewDataSuccess(response); })
        , catchError((error: any) => { return this.afterGetRecurringPaymentViewDataFailure(error); }));
  }

  public getCachedAutopay(accountNumber: string): Autopay {
    let autopay: Autopay[];
    this.store.select(state => state.Autopays).subscribe(x => autopay = x);
    let cachedAutopayModel = _.find(autopay, function (autoPays) { return autoPays.accountNumber === accountNumber; });
    return cachedAutopayModel;
  }

  private getAutopay(accountNumber: string, error: boolean, result?: any): Autopay {
    let autoPay = new Autopay;
    if (result) {
      autoPay.accountNumber = accountNumber;
      autoPay.accountHasPaymentSchedule = result.accountHasPaymentSchedule ? result.accountHasPaymentSchedule : null;
      autoPay.cardPaymentFeeAmount = result.cardPaymentFeeAmount ? result.cardPaymentFeeAmount : Constants.DECIMAL_ZERO;
      autoPay.paymentAmount = result.paymentsDue && result.paymentsDue.paymentAmount ? result.paymentsDue.paymentAmount : null;
    }
    return autoPay;
  }

  private afterGetSinglePaymentViewDataSuccess(accountNumber: string, result: any, refresh: boolean): Observable<Autopay> {
    let autoPay = this.getAutopay(accountNumber, false, result)
    //caching => if the service call requests fresh version we won't cache the results
    if (!refresh) {
      this.store.dispatch(this.autopaysActions.pushAutopay(autoPay));
    }
    return of(autoPay);
  }

  private afterGetSinglePaymentViewDataFailure(accountNumber: string, error: any): Observable<Autopay> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    let autoPay = this.getAutopay(accountNumber, true);
    return of(autoPay);
  }

  private afterGetRecurringPaymentViewDataSuccess(result: any): Observable<RecurringPaymentEntry> {
    let recurringPaymentEntry = this.getRecurringPaymentEntry(result, false, null)
    return of(recurringPaymentEntry);
  }

  private afterGetRecurringPaymentViewDataFailure(error: any): Observable<RecurringPaymentEntry> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    let recurringPaymentEntry = this.getRecurringPaymentEntry(error, true, error.faultType);
    return of(recurringPaymentEntry);
  }

  private getRecurringPaymentRequest(accountNumber: string): GetRecurringPaymentViewDataRequest {
    let getRecurringPaymentViewDataRequest = new GetRecurringPaymentViewDataRequest();
    getRecurringPaymentViewDataRequest.accountNumber = accountNumber;
    getRecurringPaymentViewDataRequest.channel = GetRecurringPaymentViewDataRequestChannel.Web;
    let countryCode = new CountryCode();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    countryCode.codeValue = this.storeAppConfig.COUNTRY_CODE;
    getRecurringPaymentViewDataRequest.countryCode = countryCode;
    getRecurringPaymentViewDataRequest.customerNumber = this.userService.getCustomerNumber();
    let userId: string = this.storeAppConfig.USER_ID;
    getRecurringPaymentViewDataRequest.userId = userId;
    return getRecurringPaymentViewDataRequest;
  }

  private getRecurringPaymentEntry(response: any, error: boolean, faultType: string): RecurringPaymentEntry {
    let recurringPaymentEntry = new RecurringPaymentEntry();
    recurringPaymentEntry.error = error;
    recurringPaymentEntry.faultType = faultType;
    recurringPaymentEntry.errorMessage = response.response;
    recurringPaymentEntry.getRecurringPaymentViewDataResponse = response;
    return recurringPaymentEntry;
  }

  public getOneTimePaymentDetails(accountNumber: string): Observable<PaymentInfo> {
    let oneTimePaymentRequest = new GetSinglePaymentViewDataRequest();
    oneTimePaymentRequest.accountNumber = accountNumber;
    oneTimePaymentRequest.channel = GetSinglePaymentViewDataRequestChannel.Web;
    oneTimePaymentRequest.customerNumber = this.userService.getCustomerNumber();
    oneTimePaymentRequest.userId = this.userService.getUserName();
    oneTimePaymentRequest.systemCode = GetSinglePaymentViewDataRequestSystemCode.MyBMW;

    return this.paymentViewServiceClient.getSinglePaymentViewData(oneTimePaymentRequest)
      .pipe(mergeMap((response) => { return this.afterGetPaymentInfoPostSuccess(response); })
        , catchError((error: any) => { return this.afterGetPaymentInfoPostFailure(error); }));
  }

  private afterGetPaymentInfoPostSuccess(result: any): Observable<PaymentInfo> {
    let paymentInfo = new PaymentInfo();
    paymentInfo.error = false;
    paymentInfo.paymentAccounts = result.paymentAccounts;
    paymentInfo.recurringPaymentInfo = result.recurringPaymentInfo;
    paymentInfo.aCHRestrictions = result.aCHRestrictions;
    paymentInfo.cardRestrictions = result.cardRestrictions;
    paymentInfo.paymentsDue = result.paymentsDue;
    paymentInfo.paymentEligibilityFlags = result.paymentEligibilityFlags;
    paymentInfo.cardPaymentFeeAmount = result.cardPaymentFeeAmount ? result.cardPaymentFeeAmount : Constants.DECIMAL_ZERO;
    paymentInfo.paymentOverrideLimit = result.paymentOverrideLimit;
    return of(paymentInfo);
  }

  private afterGetPaymentInfoPostFailure(error: any): Observable<PaymentInfo> {
    let paymentInfo = new PaymentInfo();
    paymentInfo.error = true;
    paymentInfo.faultType = error.faultType;
    paymentInfo.errorResponse = error.response;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(paymentInfo);
  }
}
