import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { CosyURLEncoder } from '../../_shared/core/cosy-url-encoder';
import { AccountIncentiveModel } from '../_models/account-incentive.model';
import { OfferModel } from '../_models/offerModel';
import { AccountIncentivesRequest, CreateNoteForOfferRequest, GetAccountOffersRequest, OfferServiceClient } from '../core/gateway-api';
import { AccountInfoService } from '../shared/_helper-services/account-info.service';
import { ObjectService } from '../shared/_helper-services/object.service';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from "../shared/store/app.store";
import { AccountIncentiveActions } from '../shared/store/reducers/account-incentive.reducer';
import { OfferActions } from '../shared/store/reducers/offers.reducers';
import { VehicleImageService } from './vehicle-image.service';
@Injectable()
export class OfferService {
  accountIncentive: AccountIncentiveModel;
  offerModel: OfferModel;
  currentCulture: string;
  private languageChangeSubscription: any;

  constructor(private store: Store<IAppState>,
    private offerServiceClient: OfferServiceClient,
    private accountIncentiveActions: AccountIncentiveActions,
    private userService: UserService,
    private offerActions: OfferActions,
    private accountInfoService: AccountInfoService,
    private vehicleImageService: VehicleImageService,
    private objectService: ObjectService) {
  }

  public accountOffers(accountNumber: string): Observable<OfferModel> {
    let accountOfferRequest = new GetAccountOffersRequest();
    accountOfferRequest.accountNumber = accountNumber;
    return this.offerServiceClient.getAccountOffers(accountOfferRequest)
      .pipe(mergeMap((response) => { return this.afterGetOfferSuccess(response, accountNumber); })
        , catchError((error: any) => { return this.afterGetOfferError(error, accountNumber); }));
  }

  private afterGetOfferSuccess(result: any, accountNumber: string): Observable<OfferModel> {
    this.offerModel = new OfferModel();
    this.offerModel.accountNumber = accountNumber;
    this.offerModel.error = false;
    this.offerModel.offerList = result.offerList;
    return of(this.offerModel);
  }
  private afterGetOfferError(result: any, accountNumber: string): Observable<OfferModel> {
    this.offerModel = new OfferModel();
    this.offerModel.accountNumber = accountNumber;
    this.offerModel.error = true;
    return of(this.offerModel);
  }
  public accountIncentives(fsAccountIds: number[], refresh: boolean): Observable<AccountIncentiveModel> {
    let cachedAccountIncentives = this.getCachedAccountIncentives();
    if (!cachedAccountIncentives || this.objectService.isEmptyObject(cachedAccountIncentives) || refresh) {
      let accountIncentivesRequest = new AccountIncentivesRequest();
      accountIncentivesRequest.customerNumber = this.userService.getCustomerNumber();
      accountIncentivesRequest.fsAccountIds = fsAccountIds;
      return this.offerServiceClient.accountIncentives(accountIncentivesRequest)
        .pipe(mergeMap((response) => { return this.afterAccountIncentivesSuccess(response); })
          , catchError((error: any) => { return this.afterAccountIncentivesError(error); }));
    }
    return of(cachedAccountIncentives);
  }

  private getCachedAccountIncentives(): AccountIncentiveModel {
    let cachedAccountIncentive: AccountIncentiveModel;
    this.store.select(state => state.AccountIncentive).subscribe(x => cachedAccountIncentive = x);
    return cachedAccountIncentive;
  }

  private afterAccountIncentivesSuccess(result: any): Observable<AccountIncentiveModel> {
    this.accountIncentive = new AccountIncentiveModel();
    this.accountIncentive.error = false;
    this.accountIncentive.incentiveList = result.incentiveList;
    this.store.dispatch(this.accountIncentiveActions.setAccountIncentive(this.accountIncentive));
    return of(this.accountIncentive);
  }

  private afterAccountIncentivesError(error: any): Observable<AccountIncentiveModel> {
    this.accountIncentive = new AccountIncentiveModel();
    this.accountIncentive.error = true;
    return of(this.accountIncentive);
  }

  public createNoteForOffer(accountNumber: string, note: string): Observable<boolean> {
    let request = new CreateNoteForOfferRequest();
    request.accountNumber = accountNumber;
    request.note = note;
    return this.offerServiceClient.createNoteForOffer(request)
      .pipe(mergeMap(() => { return of(true); })
        , catchError(() => { return of(false); }));
  }

  private getEncryptedImage(defaultImage: string, cosyUrl: string): string {
    return cosyUrl.concat('?', CosyURLEncoder.encode2(defaultImage));
  }

  public getCozyImage(modelID: number, angleID: number, quality?: number, width?: number): Observable<string> {
    return new Observable<string>((subscriber: any) => {
      this.vehicleImageService.getCozyImage(modelID, angleID, quality, width).subscribe(path => {
        if (!path.error) {
          let cosyURL: string;
          this.store.select(state => state.EnvironmentConfig.COSY_URL).subscribe(x => cosyURL = x);
          subscriber.next(this.getEncryptedImage(path.cozyURL, cosyURL));
          subscriber.complete();
        } else {
          subscriber.error(path.error);
          subscriber.complete();
        }
      });
    });
  }
}