import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ObjectService } from '../shared/_helper-services/object.service';
import { ContractAccountDetailsActions } from '../shared/store/reducers/contract-account-details.reducers';
import { ContactDetail } from './../_models/contract/contact-detail.model';
import { CashAccountViewServiceClient, ContractAccountDetailDTO, FindAccountsRequest } from './../core/gateway-api';
import { FSTokenErrorHandler } from './../shared/_errorhandler/gobal-error-handler';
import { IAppState } from "./../shared/store/app.store";

@Injectable()
export class CashAccountViewService {

    constructor(private cashAccountViewServiceClient: CashAccountViewServiceClient,
        private fsTokenErrorHandler: FSTokenErrorHandler,
        private store: Store<IAppState>,
        private objectService: ObjectService,
        private contractAccountDetailsActions: ContractAccountDetailsActions) {
    }

    private getCachedContracts(): ContractAccountDetailDTO[] {
        let contractAccountDetails: ContractAccountDetailDTO[];
        this.store.select(state => state.ContractAccountDetails).subscribe(x => contractAccountDetails = x);
        return contractAccountDetails;
    }

    public findAccounts(customerNumber: number, accountNumbers: string[], refresh: boolean): Observable<ContactDetail> {
        let cachedContracts = this.getCachedContracts();
        if (refresh || this.objectService.isEmptyObject(cachedContracts) || cachedContracts == null) {
            let findAccountsRequest = new FindAccountsRequest();
            findAccountsRequest.customerNumber = customerNumber;
            findAccountsRequest.accountNumbers = accountNumbers;
            return this.cashAccountViewServiceClient.findAccounts(findAccountsRequest)
                .pipe(mergeMap((response) => { return this.afterFindAccountsSuccess(response, refresh); })
                    , catchError((error: any) => { return this.afterFindAccountsFailure(error); }));
        }
        let contactDetail = this.getContactDetail(this.getCachedContracts());
        return of(contactDetail);
    }

    private getContactDetail(contractAccountDetails: ContractAccountDetailDTO[]): ContactDetail {
        let contactDetail = new ContactDetail();
        contactDetail.contractAccountDetails = contractAccountDetails;
        return contactDetail;
    }

    private afterFindAccountsSuccess(result: any, refresh: boolean): Observable<ContactDetail> {
        //caching
        //if the service call requests fresh version we won't cache the results
        if (!refresh) {
            this.store.dispatch(this.contractAccountDetailsActions.setContracts(result.contractAccountDetails));
        }
        let contactDetail = this.getContactDetail(result.contractAccountDetails);
        return of(contactDetail);
    }

    private afterFindAccountsFailure(result: any): Observable<ContactDetail> {
        let contactDetail = new ContactDetail();
        contactDetail.error = true;
        this.fsTokenErrorHandler.handleFSTokenError(result);
        return of(contactDetail);
    }

}
