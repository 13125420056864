import { Component, OnInit, Inject, Injector, Input, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from "../../../../../../../../_shared/core/basecomponent/base.component";
import { DOCUMENT } from '@angular/common';
import { AddAchPaymentSourceCanModel } from "../../../../../../../../_shared/_models/add-ach-payment-source-can.model";
import { Attachments, UploadAttachment } from "../../../../../../../../_shared/_models/uploadAttachment.model";
import { UploadAnimation } from "../../../../../../../../_shared/shared/upload-animation";
import { UploadAttachmentService } from '../../../../../../../../_shared/shared/_helper-services/upload-attachment.service';
import { Constants } from '../../../../../../../../_shared/shared/constants';

@Component({
  selector: 'app-upload-cheque-pad-form',
  templateUrl: './upload-cheque-pad-form.component.can.html',
  styleUrls: ['./upload-cheque-pad-form.component.can.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    UploadAnimation
  ]
})


export class UploadChequeAndPadComponent extends BaseApplicationComponent<UploadChequeAndPadComponent> implements OnInit {
  showCheckboxError: boolean = false;
  attachPadAgreementDescription: string;
  attachPadAgreementFormBtn: string;
  formChequeDisclaimerText: string;

  @Input() addAchPaymentSourceCanModel: AddAchPaymentSourceCanModel;
  constructor(private _location: Location,
    private translateService: TranslateService,
    private uploadAttachmentService: UploadAttachmentService,
    injector: Injector,
    @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  ngOnInit() {
    let brandName : string;
    this.store.select(state => state.ApplicationConfig.BRAND).subscribe(x => brandName = x);
    this.translateService.get("ngw.add-chequing-account.upload-form-cheque.attach-pad-agreement-description", { Brand: brandName }).subscribe((res: string) => {
      this.attachPadAgreementDescription = res;
    });
    this.translateService.get("ngw.add-chequing-account.upload-form-cheque.attach-pad-agreement-form-btn", { Brand: brandName }).subscribe((res: string) => {
      this.attachPadAgreementFormBtn = res;
    });

    this.translateService.get("ngw.add-chequing-account.upload-form-cheque.disclaimer-checkbox-text", { Brand: brandName }).subscribe((res: string) => {
      this.formChequeDisclaimerText = res;
    });
    
  }

  public onUploadedPadAgreementAttachmentsCompleted(attachments: Attachments): void {
    this.addAchPaymentSourceCanModel.attachment = attachments;

    if (attachments && attachments.attachments) {
      this.addAchPaymentSourceCanModel.padAgreementAttachment.attachments = attachments.attachments.filter(x => x.attachmentDesc == Constants.PADFormAttachment);
      this.addAchPaymentSourceCanModel.chequeAttachment.attachments = attachments.attachments.filter(x => x.attachmentDesc == Constants.ChequeAttachment);
    }
  }

  public deleteAttachment(uploadAttachment: UploadAttachment): void {
    this.addAchPaymentSourceCanModel.attachment.errorMessage = undefined;
    if (uploadAttachment != undefined
      && uploadAttachment.name.length > 0) {
      // filtering by removing an item from array
      this.uploadAttachmentService.deleteAttachment(uploadAttachment);
      this.addAchPaymentSourceCanModel.padAgreementAttachment.delete(uploadAttachment);
      this.addAchPaymentSourceCanModel.chequeAttachment.delete(uploadAttachment);
      this.addAchPaymentSourceCanModel.attachment.delete(uploadAttachment);
      this.addAchPaymentSourceCanModel.attachment.calculateTotalUploadedAttachmentsSize();
    }
  }
}
