import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { PaymentEntry, PaymentReviewDetails } from '../_models/payment.model';
import { ContractAccountDetailDTO, FinancialAccountDTOAccountType, PaymentServiceClient, PostCustomerPaymentRequest, PostCustomerPaymentRequestChannel, PostCustomerPaymentRequestPaymentFeeType, PostCustomerPaymentRequestPaymentSource, PostCustomerPaymentRequestPaymentType, PostCustomerPaymentRequestSystem } from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { AccountInfoService } from "../shared/_helper-services/account-info.service";
import { Constants } from '../shared/constants';
import { IAppState } from "../shared/store/app.store";
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class PaymentService {
  paymentReviewDetails: PaymentReviewDetails;
  contractDetail: ContractAccountDetailDTO;
  storeAppConfig: ApplicationConfig;
  constructor(
    public store: Store<IAppState>,
    private paymentServiceClient: PaymentServiceClient,
    private fsTokenErrorHandler: FSTokenErrorHandler,
    private accountInfoService: AccountInfoService) {

  }

  public postCustomerPayment(postCustomerPaymentRequest: PostCustomerPaymentRequest): Observable<PaymentReviewDetails> {
    return this.paymentServiceClient.postCustomerPayment(postCustomerPaymentRequest)
      .pipe(mergeMap((response) => { return this.afterPostPaymentSuccess(response); })
        , catchError((error: any) => { return this.afterPostPaymentError(error); }));
  }

  private afterPostPaymentSuccess(result: any): Observable<PaymentReviewDetails> {
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => this.paymentReviewDetails = x);
    this.paymentReviewDetails.nextPaymentDueDate = result.nextPaymentDueDate;
    this.paymentReviewDetails.confirmationNumber = result.payment[0].confirmationId;
    this.paymentReviewDetails.error = false;
    return of(this.paymentReviewDetails);
  }

  private afterPostPaymentError(error: any): Observable<PaymentReviewDetails> {
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => this.paymentReviewDetails = x);
    this.paymentReviewDetails.error = true;
    this.paymentReviewDetails.faultType = error.faultType;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(this.paymentReviewDetails);
  }

  private getDate(days: any): Date {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }

  public getPostPaymentRequest(contractDetail: ContractAccountDetailDTO, isMAPPayment?: boolean): PostCustomerPaymentRequest {
    let postCustomerPaymentRequest = new PostCustomerPaymentRequest();
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => this.paymentReviewDetails = x);
    let paymentEntry: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => paymentEntry = x);
    postCustomerPaymentRequest.accountNumber = this.paymentReviewDetails.accountno;
    postCustomerPaymentRequest.channel = PostCustomerPaymentRequestChannel.Web;
    postCustomerPaymentRequest.shouldSendEmail = true;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    postCustomerPaymentRequest.siteId = this.storeAppConfig.SITEID.toString();
    postCustomerPaymentRequest.system = PostCustomerPaymentRequestSystem.MyBMW;
    postCustomerPaymentRequest.userId = this.storeAppConfig.USER_ID;
    postCustomerPaymentRequest.financialAccountId = this.paymentReviewDetails.financialAccountId;
    postCustomerPaymentRequest.isThirdPartyMakingPayment = false;
    postCustomerPaymentRequest.lumpSumAmount = this.paymentReviewDetails.lumpSumAmount ?? 0;
    postCustomerPaymentRequest.originalPaymentAmount = this.paymentReviewDetails.paymentAmount ?? 0;
    postCustomerPaymentRequest.paymentDate = this.paymentReviewDetails.paymentDate;
    postCustomerPaymentRequest.paymentNote = null;
    postCustomerPaymentRequest.paymentDistributionDetails = paymentEntry.paymentDistributionDetailDTO;

    if (this.paymentReviewDetails.accountType === FinancialAccountDTOAccountType.Checking ||
      this.paymentReviewDetails.accountType === FinancialAccountDTOAccountType.Savings) {
      postCustomerPaymentRequest.customerNumber = this.paymentReviewDetails.customerno;
      postCustomerPaymentRequest.emailAddress = this.paymentReviewDetails.emailAddress;
      postCustomerPaymentRequest.paymentFeeType = PostCustomerPaymentRequestPaymentFeeType.NoFeeApplicable;
      postCustomerPaymentRequest.paymentSource = PostCustomerPaymentRequestPaymentSource.UltimatePay;
    }
    else if (this.paymentReviewDetails.accountType === FinancialAccountDTOAccountType.Debit) {
      postCustomerPaymentRequest.customerNumber = this.paymentReviewDetails.customerno;
      postCustomerPaymentRequest.emailAddress = null;
      postCustomerPaymentRequest.paymentFeeType = (paymentEntry && paymentEntry.cardPaymentFeeAmount === Constants.DECIMAL_ZERO) ? PostCustomerPaymentRequestPaymentFeeType.NoFeeApplicable : PostCustomerPaymentRequestPaymentFeeType.ChargeFee;
      postCustomerPaymentRequest.paymentSource = PostCustomerPaymentRequestPaymentSource.DebitCard;
    }
    else if (this.paymentReviewDetails.accountType === FinancialAccountDTOAccountType.Credit) {
      postCustomerPaymentRequest.customerNumber = this.paymentReviewDetails.customerno;
      postCustomerPaymentRequest.emailAddress = null;
      postCustomerPaymentRequest.paymentFeeType = PostCustomerPaymentRequestPaymentFeeType.NoFeeApplicable;
      postCustomerPaymentRequest.paymentSource = PostCustomerPaymentRequestPaymentSource.CreditCard;
    }

    let recoveryEOLCharges: PostCustomerPaymentRequestPaymentType;
    let paymentType: PostCustomerPaymentRequestPaymentType;
    if (contractDetail.countryCode.codeValue === Constants.Country_CAN) {
      postCustomerPaymentRequest.emailAddress = this.paymentReviewDetails.paymentConfEmailPreference ? this.paymentReviewDetails.primaryEmailAddress : null;
      postCustomerPaymentRequest.shouldSendEmail = this.paymentReviewDetails.paymentConfEmailPreference ? true : false;      
      this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
      postCustomerPaymentRequest.siteId = this.storeAppConfig.SITEID.toString();
      recoveryEOLCharges = PostCustomerPaymentRequestPaymentType.RecoveryEOLCharges_CAN;
      paymentType = PostCustomerPaymentRequestPaymentType.Payment_CAN;
      postCustomerPaymentRequest.system = PostCustomerPaymentRequestSystem.CanadaOwnersCircle;
    }
    else {
      recoveryEOLCharges = PostCustomerPaymentRequestPaymentType.RecoveryEOLCharges_USA;
      paymentType = PostCustomerPaymentRequestPaymentType.Payment_USA;
    }

    if (isMAPPayment) {
      postCustomerPaymentRequest.paymentType = PostCustomerPaymentRequestPaymentType.MMA_USA;
    }
    else {
      let isTerminatedAccount: boolean = this.isTerminatedAccount(contractDetail.statusCategoryCode);
      postCustomerPaymentRequest.paymentType = isTerminatedAccount
        ? recoveryEOLCharges
        : paymentType;
    }



    return postCustomerPaymentRequest;
  }

  private isTerminatedAccount(statusCategoryCode: any) {
    return this.accountInfoService.isTerminatedAccount(statusCategoryCode);
  }
}


