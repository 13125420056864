import { Injectable } from '@angular/core';
import { AccountIncentiveDto, ContractAccountDetailDTO } from '../../core/gateway-api';
import { Store } from '@ngrx/store';
import { IAppState } from "../../shared/store/app.store";
import { AccountInfoService } from './account-info.service';
import { OfferService } from '../../_web-services/offer.service';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import * as _ from 'lodash-es';
import { Offers, OfferModel } from '../../../_shared/_models/offerModel';
import { filter, mergeMap } from 'rxjs/operators';
import { OfferActions } from '../store/reducers/offers.reducers';
import { FeatureIndicatorService } from '../../_web-services/feature-indicator.service';
import { FeatureIndicatorNames } from '../enums';
import { FeatureIndicatorActions } from '../store/reducers/feature-indicator.reducers';
import { BooleanModel } from '../../../_shared/_models/boolean.model';
import { EpaasHelperService } from './epaas-helper.service';
import { Constants } from '../constants';

@Injectable()
export class OffersHelperService {

  private accountIds: number[] = [];
  private contracts: ContractAccountDetailDTO[] = [];

  constructor(
    private accountInfoService: AccountInfoService,
    private featureIndicatorActions: FeatureIndicatorActions,
    private featureIndicatorService: FeatureIndicatorService,
    private offerActions: OfferActions,
    private offerService: OfferService,
    private store: Store<IAppState>,
    private epaasHelperService: EpaasHelperService
  ) { }

  get accountOffers() {
    let offer: Offers;
    this.store.select(state => state.offers).subscribe(x => offer = x);
    return offer;
  }

  get offersEnabledIndicator() {
    let offersEnabled: BooleanModel;
    this.store.select(state => state.OffersEnabled).subscribe(x => offersEnabled = x);
    return offersEnabled;
  }

  private composeAndSaveOffers({ hasOffer, offerModel }: Partial<Offers>): Offers {
    const offers = new Offers();
    offers.hasOffer = Boolean(offerModel) || hasOffer;
    offers.offerModel = offerModel;
    const validOffers = offers.offerModel.filter(x => !x.error)
    offers.offerModel = validOffers;
    this.store.dispatch(this.offerActions.setOffer(offers));
    return offers;
  }

  public displayIntentSection(contract: ContractAccountDetailDTO): boolean {
    return this.accountInfoService.isLeaseAccount(contract.portfolioCategoryCode) &&
      this.accountInfoService.isAccountActiveOrExtendedLease(contract.accountStatus) &&
      this.accountInfoService.isContractEndDateLessThanOrEqualMonths(contract.originalContractEndDate, 5);
  }

  public displayLeaseEndInfo(contract: ContractAccountDetailDTO): boolean {
    return this.accountInfoService.isLeaseAccount(contract.portfolioCategoryCode) &&
      this.accountInfoService.isAccountActiveOrExtendedLease(contract.accountStatus) &&
      this.accountInfoService.isContractEndDateLessThanOrEqualMonths(contract.originalContractEndDate, 12);
  }

  public displayOffersSection(contract: ContractAccountDetailDTO): Observable<boolean> {
    return of(this.accountInfoService.isLeaseAccount(contract.portfolioCategoryCode)
      && this.accountInfoService.isAccountActiveOrExtendedLease(contract.accountStatus)
      && this.accountInfoService.isContractEndDateLessThanOrEqualMonths(contract.originalContractEndDate, 12));
  }

  private getAccountIncentives(accountIds: number[]): Observable<AccountIncentiveDto[]> {
    return new Observable(subscriber => {
      if (!accountIds || !accountIds.length) subscriber.error();

      this.offerService.accountIncentives(accountIds, false).subscribe(incentives => {
        if (incentives && incentives.error) subscriber.error(incentives.error);

        const incentiveList = incentives.incentiveList;

        subscriber.next(incentiveList);
        subscriber.complete();
      }, error => subscriber.error(error));
    });
  }

  private getAccountOffers(incentives: AccountIncentiveDto[]): Observable<OfferModel[]> {
    const offersCalls = incentives.map(incentive => {
      const showOffers = (incentive.accountIncentives || {}).ShowOffers == 'true';
      const contract = this.contracts.find(contract => {
        return contract.fSAccountId == incentive.fsAccountId;
      });

      return contract && showOffers ? this.offerService.accountOffers(contract.accountNumber) : null;
    }).filter(call => Boolean(call));
    if (!offersCalls || offersCalls.length==0) {
      return (of ( new Array<OfferModel>()));
    }
    else{
      return forkJoin(offersCalls);
    }

    
  }

  public getAndSaveContractOffers(): Observable<Offers> {
    if (this.offersEnabledIndicator.booleanValue === null) this.setIndicator();

    return new Observable(subscriber => {
      combineLatest([
        this.store.select<{ [key: number]: ContractAccountDetailDTO } | []>(state => state.ActiveContractAccountDetails),
        this.store.select<BooleanModel>(state => state.OffersEnabled),
      ]).pipe(
        filter(([contracts, offersEnabled]) => {
          const offersIndicatorSet = offersEnabled.booleanValue !== null;
          const offersLoaded = this.accountOffers.hasOffer !== undefined;
          if (offersLoaded || Object.values(contracts).length==0) {
            subscriber.next(this.accountOffers);
            subscriber.complete();
          }
          const neededDataLoaded = offersIndicatorSet && Object.values(contracts).length;
          return !offersLoaded && neededDataLoaded && offersEnabled.booleanValue;
        }),
        mergeMap(([contracts]) => {
          this.contracts = Object.values(contracts);
          const accountIds = this.contracts.map(contract => contract.fSAccountId);
          this.accountIds = accountIds;
          return this.getAccountIncentives(accountIds);
        }),
        mergeMap(incentives => {
          return this.getAccountOffers(incentives);
        })
      ).subscribe(accountOffers => {
        const offers = this.composeAndSaveOffers({ offerModel: accountOffers });
        subscriber.next(offers);
        subscriber.complete();
      });
    });
  }

  public hasOfferWithNoCreditAppWithActType(contract: ContractAccountDetailDTO): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.accountInfoService.isLeaseAccount(contract.contractType)) {
        this.hasOfferWithNoCreditApp(contract).then(result => {
          resolve(result);

        })
      }
    });
  }

  private hasOfferWithNoCreditApp(contract: ContractAccountDetailDTO): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.store.select<Offers>(state => state.offers).subscribe((offers: Offers) => {
        if (offers && offers.hasOffer && _.toArray(offers.offerModel).length > 0) {
          let offerModel: OfferModel = _.find(offers.offerModel, function (offer: OfferModel) {
            return offer.accountNumber === contract.accountNumber;
          });
          if (offerModel) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });
    });
  }

  public hasOffersWithNoCreditAppWithMonth(contract: ContractAccountDetailDTO): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const monthWiseConditions = this.accountInfoService.isLeaseAccount(contract.portfolioCategoryCode) &&
        !(this.accountInfoService.isContractEndDateLessThanOrEqualMonths(contract.originalContractEndDate, 12) &&
          this.accountInfoService.isContractEndDateMoreThanMonths(contract.originalContractEndDate, 5)) &&
        this.accountInfoService.isContractEndDateLessThanOrEqualMonths(contract.originalContractEndDate, 5);
      if (!monthWiseConditions) {
        resolve(false);
      }
      if (monthWiseConditions) {
        this.hasOfferWithNoCreditApp(contract).then(result => {
          resolve(result);
        })
      }
    });
  }

  public offersWithNoCreditAppForAllContracts(contracts: ContractAccountDetailDTO[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      _.forEach(contracts, contract => {
        if (this.accountInfoService.isLeaseAccount(contract.contractType) || this.accountInfoService.isRetailAccount(contract.contractType)
          || this.accountInfoService.isOwnersChoiceContractType(contract.contractType)) {
          this.hasOfferWithNoCreditApp(contract).then(result => {
            if (result) {
              resolve(result);
            }
          })
        }
      });
    });
  }

  private setIndicator(): void {
    this.featureIndicatorService.getFeatureIndicatorByClientId(FeatureIndicatorNames.Offers).subscribe(offersEnabled => {
      this.store.dispatch(this.featureIndicatorActions.setOffers(offersEnabled));
    });
  }

  public get isAdvertisingPersistenceAllowed(): Observable<boolean> {
    return this.epaasHelperService.isUsageAllowed(Constants.EPaasProcessingId.AdobeAnalytics);
  }

  public getEndOfTermOfferStartYear(): number {
    return new Date().getFullYear() - 8;
  }

  public getEndOfTermOfferEndYear(): number {
    return new Date().getFullYear() + 1;
  }

  public getCurrentYear(): number {
    return new Date().getFullYear();
  }

  public showOffers(): boolean{
    let offers: Offers;
    let offersEnabled: boolean;
    let EndofTermEnabled: boolean;
    let showOffer : boolean = false;

    this.store.select(state => state.OffersEnabled.booleanValue).subscribe(x => offersEnabled = x);
    this.store.select(state => state.EndOfTerm.booleanValue).subscribe(x => EndofTermEnabled = x);
    this.store.select(state => state.offers).subscribe(x => offers = x);
    if(offersEnabled === true && EndofTermEnabled === true && offers?.offerModel?.length > 0){
      showOffer = true;
    }
    return showOffer;
  }

}

