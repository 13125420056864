import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StringModel } from '../../../../_shared/_models/string.model';

export class LanguageActions {
  static SET_LANGUAGE: string = 'Language';

  setLanguage(language: string): FSA<string, string, null> {
    return {
      type: LanguageActions.SET_LANGUAGE,
      payload: language,
      meta: null
    };
  }
}

@Injectable()
export class LanguageReducers {
  static LanguageReducer: Reducer<StringModel | undefined,FSA<string, string,void>> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
    switch (action.type) {
      case LanguageActions.SET_LANGUAGE:
        return tassign(state, new StringModel(action.payload));
      default:
        return state;
    }
  }
}