import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA } from 'flux-standard-action';
import { tassign } from 'tassign';
import { SelectMiles } from '../../../_models/map/select-miles.model';

@Injectable()
export class SelectMilesActions
{
    static SET_SELECT_MILES: string = 'SET_SELECT_MILES';
    static CLEAR_SELECT_MILES: string = 'CLEAR_SELECT_MILES';

  setSelectMiles(selectMiles: SelectMiles): FSA<string, SelectMiles, null>{
    return {
      type: SelectMilesActions.SET_SELECT_MILES,
      payload: selectMiles,
      meta: null
    };
  }

  clearSelectMiles(): FSA<string, SelectMiles, null> {
      return {
          type: SelectMilesActions.CLEAR_SELECT_MILES,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class SelectMilesReducers
{
  static SelectMilesReducer: Reducer<SelectMiles> = (state: SelectMiles=new SelectMiles(), action: FSA<string, SelectMiles, void>) => {
    switch(action.type){
    case SelectMilesActions.SET_SELECT_MILES:
            return tassign(state, action.payload);
    case SelectMilesActions.CLEAR_SELECT_MILES:
        return tassign(undefined);
    default:
      return state;
    }
  }
}
