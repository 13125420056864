<div class="page-path-not-found">
  <div class="header-image"></div>
  <div class="path-not-found-container">
    <h1>
      {{'ngw.page-not-found.somethings-missing-msg' | translate}}
    </h1>
    <div class="try-again"><p>{{'ngw.page-not-found.try-again-msg' | translate}}</p></div>
    <ul class="page-not-found-nav">
      <li>
        <a (click)="navigateToBaseURL()">{{'ngw.page-not-found.btn-home' | translate}}</a>
      </li>
    </ul>
  </div>
</div>
