import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";

@Directive({ selector: "[currency-mask]" })
export class CurrencyMaskDirective implements OnInit {
    private element: any;

    constructor(
        private elementRef: ElementRef
    ) {
        this.element = this.elementRef.nativeElement;
    }

    ngOnInit() {
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        let e = <KeyboardEvent>event;
        if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+V
            // (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }

        //Allow only one decimal point
        if (!e.shiftKey && (e.keyCode === 110 || e.keyCode === 190)) {
            var decimalCheck = this.element.value.split('.');
            if (decimalCheck != undefined && decimalCheck.length > 1) {
                e.preventDefault();
            }
            //Allow decimal point only before two numbers
            if (decimalCheck != undefined && decimalCheck.length <= 1 && this.element.selectionStart < (this.element.value.length - 2)) {
                e.preventDefault();
            }
            return;
        }

        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
        else {
            var decimalCheck = this.element.value.split('.');
            // Ensure upto two decimal places
            if (decimalCheck != undefined && decimalCheck.length > 1 && decimalCheck[1].length >= 2 && (this.element.selectionStart > (this.element.value.length - 3))) {
                e.preventDefault();
            }
        }
    }
}
