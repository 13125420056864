import { Injectable } from '@angular/core';
import {
    AddressDTO,
    TaxJurisdictionDTO,
    PaymentAdjustmentDTO
} from '../../core/gateway-api';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { IAppState } from "../../shared/store/app.store";
import { TranslateService } from '@ngx-translate/core';
import { TaxJurisdictionModel, TaxCounty } from '../../_models/tax-jurisdiction.model';
import { NotificationsHelperService } from './notifications-helper.service';
import { MaskService } from './mask.service';
import { CurrencyPipe } from '@angular/common';
import { ApplicationConfig } from '../../_models/application-config';

@Injectable()
export class TaxJurisdictionHelperService {
    private accountLbl: string;
    private vehicleLbl: string;
    private accountCaption: string;
    private vehicleCaption: string;
    private msgCreditCardSbj: string;

    constructor(
        private store: Store<IAppState>,
        private translateService: TranslateService,
        private maskService: MaskService,
        private currencyPipe: CurrencyPipe,
        private notificationsHelperService: NotificationsHelperService) { }

    public setTJAddressData(validatedAddress: AddressDTO, taxJurisdictions: TaxJurisdictionDTO[]): TaxJurisdictionModel {
        let tjModel: TaxJurisdictionModel;
        if (taxJurisdictions && validatedAddress) {
            let taxCounties: TaxCounty[] = this.getTaxCounties(taxJurisdictions);
            let address: string = this.maskService.getConcatenatedString(validatedAddress.address1, validatedAddress.address2, validatedAddress.address3);
            let formattedZip = this.maskService.getFormattedZip(validatedAddress.postalCode);
            if (taxCounties && taxCounties.length > 1) {
                tjModel = new TaxJurisdictionModel(taxCounties, null, address, validatedAddress.city, validatedAddress.state, formattedZip, taxCounties[0]);
            }
            else {
                tjModel = new TaxJurisdictionModel(null, taxCounties[0], address, validatedAddress.city, validatedAddress.state, formattedZip);
            }

        }
        return tjModel;
    }

    public setTJPaymentData(taxJurisdiction: TaxJurisdictionModel, paymentAdjustments: PaymentAdjustmentDTO[]): TaxJurisdictionModel {
        let selectedGeocode: string = taxJurisdiction.taxCounty ? taxJurisdiction.taxCounty.geocode : taxJurisdiction.selectedTaxCounty.geocode;
        let selectedPaymentAdjustment: PaymentAdjustmentDTO = _.find(paymentAdjustments, function (pa: PaymentAdjustmentDTO) {
            return pa.geocode === selectedGeocode;
        });
        taxJurisdiction.currentPayment = selectedPaymentAdjustment.oldMonthlyPaymentAmount;
        taxJurisdiction.basePayment = selectedPaymentAdjustment.basePaymentAmount;
        taxJurisdiction.salesTax = selectedPaymentAdjustment.newSalesTaxAmount;
        taxJurisdiction.newPayment = selectedPaymentAdjustment.newMonthlyPaymentAmount;
        taxJurisdiction.singleCharge = selectedPaymentAdjustment.singleChargeAmount;
        let appConfig: ApplicationConfig;
        this.store.select(state => state.ApplicationConfig).subscribe(x => appConfig = x);
        let movepack_Fee: number = appConfig.MOVEPACK_FEE;
        taxJurisdiction.movePackFee = appConfig ? movepack_Fee : null;
        taxJurisdiction.movePackSelected = false;
        let movePackFeeAsCurrency = this.currencyPipe.transform(taxJurisdiction.movePackFee, 'USD', 'symbol');
        this.translateService.get('ngw.profile.contact-information.tax-jurisdiction.step2.sub-title-move-pack', { movePackFee: movePackFeeAsCurrency }).subscribe((res: string) => {
            taxJurisdiction.movePackSubtitle = res;
        });
        taxJurisdiction.emailConfirmationSelected = false;
        taxJurisdiction.emailAddress = this.notificationsHelperService.getCustomerPrimaryEmailAddress();
        return taxJurisdiction;
    }


    private getTaxCounties(taxJurisdictions: TaxJurisdictionDTO[]): TaxCounty[] {
        let taxCounties: TaxCounty[] = new Array();
        let self = this;
        _.each(taxJurisdictions, function (tj: TaxJurisdictionDTO) {
            let county: string = self.maskService.getConcatenatedString(tj.city, tj.county, tj.state);
            let taxCounty = new TaxCounty(county, tj.geocode)
            if (taxCounties) {
                taxCounties.push(taxCounty);
            }
        })
        return taxCounties;
    }

}

