import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { BooleanModel } from '../../../../../../_models/boolean.model';
import { AuthService } from '../../../../../../_web-services/auth.service';
import { LogSiteActivityService } from '../../../../../../_web-services/log-site-activity.service';
import { PreferenceCenterService } from '../../../../../../_web-services/preference-center.service';
import { BaseApplicationComponent } from '../../../../../../core/basecomponent/base.component';
import { ContractAccountDetailDTO, ServicingPreferenceDTO, ServicingPreferenceDTOPreferenceStatus } from '../../../../../../core/gateway-api';
import { DialogService } from '../../../../../_helper-services/dialog.service';
import { ModuleOnBoardingHelperService } from '../../../../../_helper-services/module-onboarding-helper.service';
import { NotificationsHelperService } from '../../../../../_helper-services/notifications-helper.service';
import { RouterService } from '../../../../../_helper-services/router.service';
import { UserService } from '../../../../../_helper-services/user.service';
import { Constants } from '../../../../../constants';
import { ActivityTypes } from '../../../../../enums';

@Component({
  selector: 'app-legal-agreement-dialog-footer',
  templateUrl: './legal-agreement-dialog-footer.component.html',
  styleUrls: ['./legal-agreement-dialog-footer.component.scss']
})

export class LegalAgreementDialogFooter extends BaseApplicationComponent<LegalAgreementDialogFooter> implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Output() setError$ = new EventEmitter<boolean>();
  @Output() setMode$ = new EventEmitter<boolean>();

  termsHtmlContent;
  onboardingAgreementsChecked: boolean;
  termsCheckedSubscription: any;
  esignCheckedSubscription: any;
  termsAcceptedSubscription: any;
  esignAcceptedSubscription: any;
  paperlessToggleSubscription: any;
  profileCheckedSubscription: any;
  esignChecked: boolean;
  termsChecked: boolean;
  profileChecked: boolean;
  paperlessToggled: boolean;
  hasError: boolean;
  isInPaperlessEnrollMode: boolean;
  contractDetail: ContractAccountDetailDTO;

  constructor(@Inject(DOCUMENT) document,
    injector: Injector,
    private userService: UserService,
    private authService: AuthService,
    private preferenceCenterService: PreferenceCenterService,
    private logSiteActivityService: LogSiteActivityService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private routerService: RouterService,
    public moduleOnBoardingHelperService: ModuleOnBoardingHelperService,
    private notificationsHelperService: NotificationsHelperService) {
    super(injector, document);
    this.termsCheckedSubscription = this.store.select(state => state.TermsAgreementChecked)
      .subscribe(booleanModel => this.setTermsChecked(booleanModel));
    this.esignCheckedSubscription = this.store.select(state => state.EsignAgreementChecked)
      .subscribe(booleanModel => this.setEsignChecked(booleanModel));
    this.profileCheckedSubscription = this.store.select(state => state.ProfileAgreementChecked)
      .subscribe(booleanModel => this.setProfileChecked(booleanModel));
    this.paperlessToggleSubscription = this.store.select(state => state.PaperlessToggle)
      .subscribe(booleanModel => this.setPaperlessToggled(booleanModel));
    this.isInPaperlessEnrollMode = true;

  }

  ngOnInit() {
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
  }

  ngOnDestroy() {
    if (this.termsCheckedSubscription) {
      this.termsCheckedSubscription.unsubscribe();
    }
    if (this.esignCheckedSubscription) {
      this.esignCheckedSubscription.unsubscribe();
    }
    if (this.profileCheckedSubscription) {
      this.profileCheckedSubscription.unsubscribe();
    }
  }

  private setPaperlessToggled(booleanModel: BooleanModel) {
    this.paperlessToggled = booleanModel ? booleanModel.booleanValue : false;
  }

  public getRightButtonText(): string {
    let text: string;
    let key: string = 'ngw.onboarding.lnk-continue';
    if (this.currentStepIsAgreements()) {
      key = 'ngw.onboarding.lnk-continue';
    }
    else if (this.currentStepIsPaperless()) {
      if (this.isInPaperlessEnrollMode) {
        key = this.paperlessToggled ? 'ngw.onboarding.lnk-accept' : 'ngw.onboarding.lnk-done';
      }
      else {
        key = 'ngw.onboarding.lnk-done';
      }
    }
    this.translateService.get(key).subscribe((res: string) => { text = res; });
    return text;
  }

  public cancel() {
    this.closeDialog();
    this.userService.logout();
  }

  public closeDialog() {
    this.dialogService.closeLegalAgreementDialog();
  }

  public getCurrentStep(): number {
    return this.stepper ? this.stepper.selectedIndex : 0;
  }

  public currentStepIsAgreements(): boolean {
    return this.currentStepIs(Constants.AgreementsStepName);
  }

  public currentStepIsPaperless(): boolean {
    return this.currentStepIs(Constants.PaperlessStepName);
  }

  private currentStepIs(stepName: string): boolean {
    if (this.stepper && this.stepper.selected) {
      return this.stepper.selected.label === stepName;
    }
    return false;
  }

  public getStepsCount(): number {
    return this.stepper && this.stepper._steps ? this.stepper._steps.length : 0;
  }

  public showNavigationDots(): boolean {
    return this.getStepsCount() == 2 && (this.currentStepIsAgreements() ||
      (this.currentStepIsPaperless() && this.isInPaperlessEnrollMode && this.paperlessToggled));
  }

  public showNavigationDotsAndButton(): boolean {
    return this.currentStepIsPaperless() && this.paperlessToggled;
  }

  public showOnlyButton(): boolean {
    return (this.currentStepIsPaperless() && !this.paperlessToggled) || (this.currentStepIsPaperless() && !this.isInPaperlessEnrollMode);
  }

  public continue() {
    if (this.currentStepIsAgreements()) {
      this.acceptAgreements();
    }
    else if (this.currentStepIsPaperless()) {
      if (this.isInPaperlessEnrollMode) {
        this.enrollInPaperless();
      }
      else {
        this.routerService.navigateToTarget();
        this.closeDialog();
      }

    }
  }

  private enrollInPaperless() {
    let customerNumber = this.userService.getCustomerNumber();
    let paperlessAccountNumber = this.moduleOnBoardingHelperService.getPaperlessNotEnrolledAccount();
    let paperlessPreference: ServicingPreferenceDTO = this.notificationsHelperService.getPaperlessPreference(null, paperlessAccountNumber);

    if (this.paperlessToggled) {
      paperlessPreference.preferenceStatus = ServicingPreferenceDTOPreferenceStatus.On;
      this.preferenceCenterService.updateServicingPreferencesStatus(customerNumber, [paperlessPreference])
        .subscribe(response => { this.postUpdatePreference(response, ServicingPreferenceDTOPreferenceStatus.On); });
      this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.contractDetail.accountNumber, ActivityTypes.OnboardPaperless);
    }
    else {
      paperlessPreference.preferenceStatus = ServicingPreferenceDTOPreferenceStatus.Off;
      this.preferenceCenterService.updateServicingPreferencesStatus(customerNumber, [paperlessPreference])
        .subscribe(response => { this.postUpdatePreference(response, ServicingPreferenceDTOPreferenceStatus.Off); });
      this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.contractDetail.accountNumber, ActivityTypes.OnboardPaperlessOff);
    }
  }

  public continueToNextStep() {
    this.stepper.next();
  }

  private postUpdatePreference(response: boolean, status: ServicingPreferenceDTOPreferenceStatus) {
    if (response) {
      this.moduleOnBoardingHelperService.setPaperLessPrefrencesAccount(undefined);
      this.moduleOnBoardingHelperService.setPaperLessPrefrences(response);
      if (status == ServicingPreferenceDTOPreferenceStatus.On) {
        //hide enroll content and show confirmation content
        this.isInPaperlessEnrollMode = false;
        this.setMode$.emit(false);
      }
      else {
        this.closeDialog();
        this.routerService.navigateToTarget();
      }

    }
    else {
      this.closeDialog();
      this.routerService.navigateToErrorPage();
    }
  }

  private setTermsChecked(checked: BooleanModel) {
    this.termsChecked = checked ? checked.booleanValue : false;
  }

  private setEsignChecked(checked: BooleanModel) {
    this.esignChecked = checked ? checked.booleanValue : false;
  }

  private setProfileChecked(checked: BooleanModel) {
    this.profileChecked = checked ? checked.booleanValue : false;
  }

  public continueEnabled() {
    return (this.currentStepIsAgreements() &&
      ((!this.moduleOnBoardingHelperService.isTermsAccepted() && this.termsChecked) || this.moduleOnBoardingHelperService.isTermsAccepted()) &&
      ((!this.moduleOnBoardingHelperService.isEsignAccepted() && this.esignChecked) || this.moduleOnBoardingHelperService.isEsignAccepted()) &&
      ((!this.moduleOnBoardingHelperService.isProfileTermsAccepted() && this.profileChecked) || this.moduleOnBoardingHelperService.isProfileTermsAccepted())) ||
      this.currentStepIsPaperless();
  }

  public showCancelButton() {
    return this.currentStepIsAgreements();
  }

  public acceptAgreements() {
    super.setDataLoadedOnRequest(false);
    let promisesList = new Array();
    if (!this.moduleOnBoardingHelperService.isTermsAccepted()) {
      let gcid = this.userService.getGcid();
      promisesList.push(this.authService.acceptTermsAndConditions(gcid));
    }
    if (!this.moduleOnBoardingHelperService.isEsignAccepted()) {
      let customerNumber = this.userService.getCustomerNumber();
      promisesList.push(this.preferenceCenterService.updateServicingPreferencesStatus(customerNumber));
    }
    if (!this.moduleOnBoardingHelperService.isProfileTermsAccepted()) {
      let gcid = this.userService.getGcid();
      promisesList.push(this.authService.acceptTermsAndConditions(gcid, true));
    }

    forkJoin(promisesList).subscribe(responses => {
      this.postAcceptAgreements(responses);
    });

  }

  private postAcceptConscents() {
    if (!this.moduleOnBoardingHelperService.isEsignAccepted())
      this.postAcceptEsign(true);

    if (!this.moduleOnBoardingHelperService.isTermsAccepted())
      this.postAcceptTerms(true);

    if (!this.moduleOnBoardingHelperService.isProfileTermsAccepted())
      this.postAcceptProfile(true);

    this.setError$.emit(false);
    if (this.getStepsCount() > 1) {
      this.continueToNextStep();
    }
    else {
      this.closeDialog();
      this.routerService.navigateToTarget();
    }
  }

  public postAcceptAgreements(responses: any[]) {
    responses.find(state => !state) ? this.setError$.emit(true) : this.postAcceptConscents();
    super.setDataLoadedOnRequest(true);
  }

  private postAcceptTerms(response: boolean) {
    this.moduleOnBoardingHelperService.setTermsAccepted(response);
  }

  private postAcceptEsign(response: boolean) {
    this.moduleOnBoardingHelperService.setEsignAccepted(response);
  }

  private postAcceptProfile(response: boolean) {
    this.moduleOnBoardingHelperService.setProfileAccepted(response);
  }

}




