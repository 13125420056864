import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { FaultCodes } from '../shared/FaultCodes';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { Payoff } from './../_models/my-account/payoff.model';
import { CalculatePayoffRequest, ContractKey, ContractualPayoffServiceClient } from './../core/gateway-api';
import { IAppState } from "./../shared/store/app.store";
import { PayoffsActions } from './../shared/store/reducers/payoffs.reducers';

@Injectable()
export class ContractualPayoffService {

    constructor(private contractualPayoffServiceClient: ContractualPayoffServiceClient,
        private fsTokenErrorHandler: FSTokenErrorHandler,
        private store: Store<IAppState>,
        private payoffsActions: PayoffsActions) {
    }

    public getCachedPayoff(fsAccountId: number): Payoff {
        let payoffs: Payoff[];
        this.store.select(state => state.Payoffs).subscribe(x => payoffs = x);
        let cachedPayoffModel = _.find(payoffs, function (payoff) { return payoff.fSAccountId === fsAccountId; });
        return cachedPayoffModel;
    }

    public calculatePayoff(fsAccountId: number, refresh: boolean): Observable<Payoff> {
        let cachedPayoffModel = this.getCachedPayoff(fsAccountId);
        if (refresh || !cachedPayoffModel) {
            let contractKey = new ContractKey();
            contractKey.fsAccountId = fsAccountId;
            let calculatePayoffRequest = new CalculatePayoffRequest();
            calculatePayoffRequest.contractKey = contractKey;
            return this.contractualPayoffServiceClient.calculatePayoff(calculatePayoffRequest)
                .pipe(mergeMap((response) => { return this.afterCalculatePayoffSuccess(response, fsAccountId, refresh); })
                    , catchError((error: any) => { return this.afterCalculatePayoffFailure(error); }));
        }
        return of(cachedPayoffModel);
    }

    private getPayoff(error: boolean, stopSaleFlag: boolean, fSAccountId?: number, payoffAmount?: number, goodThroughDate?: Date, quoteDate?: Date, securityDeposit?: number): Payoff {
        let payoff = new Payoff;
        if (payoffAmount) {
            payoff.fSAccountId = fSAccountId;
            payoff.payoffAmount = payoffAmount;
            payoff.goodThroughDate = goodThroughDate;
            payoff.quoteDate = quoteDate;
            payoff.securityDeposit = securityDeposit;
            payoff.error = error;
            payoff.stopSaleFlag = stopSaleFlag;
        }
        return payoff;
    }

    private afterCalculatePayoffSuccess(result: any, fSAccountId: number, refresh: boolean): Observable<Payoff> {
        let payoff = this.getPayoff(false, false, fSAccountId, result.contractualPayoffQuote.mutable.grossPayoffAmount, result.contractualPayoffQuote.mutable.goodThroughDate, result.contractualPayoffQuote.mutable.quoteDate, result.contractualPayoffQuote.mutable.securityDeposit);
        //caching => if the service call requests fresh version we won't cache the results
        if (!refresh) {
            this.store.dispatch(this.payoffsActions.pushPayoff(payoff));
        }
        return of(payoff);
    }

    private afterCalculatePayoffFailure(error: any): Observable<Payoff> {
        this.fsTokenErrorHandler.handleFSTokenError(error);
        let payoff = new Payoff;
        if (error.faultType === FaultCodes.BMWFSAM_Services_AccountManagement_IneligibleToQuoteContractPayoffFault) {
            payoff = this.getPayoff(true, true);
        }
        else {
            payoff = this.getPayoff(true, false);
        }
        return of(payoff);
    }

}
