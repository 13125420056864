import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ProviderKey, SiteKey, CustomerDeviceServiceClient, ValidateCustomerDeviceRequest, CustomerDeviceDTO, DeleteCustomerDeviceRequest, InsertCustomerDeviceRequest, ValidateCustomerDeviceResponse, DeleteCustomerDeviceResponse, InsertCustomerDeviceResponse } from '../core/gateway-api'
import { CustomerDevice } from "../_models/customer-device.model";
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { Store } from '@ngrx/store';
import { IAppState } from "../shared/store/app.store";
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class CustomerDeviceService {

  customerDeviceStatus: CustomerDevice;
  storeAppConfig: ApplicationConfig;
  constructor(
    public store: Store<IAppState>,
    private customerDeviceServiceClient: CustomerDeviceServiceClient,
    private fsTokenErrorHandler: FSTokenErrorHandler) {

  }

  public validateCustomerDevice(deviceId: string, gcId: string): Observable<CustomerDevice> {
    let validateCustoemrRequest = new ValidateCustomerDeviceRequest();
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    validateCustoemrRequest.providerKey = providerKey;
    let siteKey = new SiteKey();
    siteKey.siteId = this.storeAppConfig.SITEID;
    validateCustoemrRequest.siteKey = siteKey;
    validateCustoemrRequest.gCID = gcId;
    let customerDeviceDTO = new CustomerDeviceDTO();
    customerDeviceDTO.os = navigator.platform;
    customerDeviceDTO.browser = navigator.appName;
    customerDeviceDTO.deviceAlias = navigator.appName;
    customerDeviceDTO.deviceName = navigator.appName;
    customerDeviceDTO.deviceId = deviceId;
    validateCustoemrRequest.customerDevice = customerDeviceDTO;

    return this.customerDeviceServiceClient.validateCustomerDevice(validateCustoemrRequest)
      .pipe(mergeMap((response) => { return this.afterValidateCustomerDeviceSuccess(response); })
        , catchError((error: any) => { return this.afterValidateCustomerDeviceError(error); }));
  }

  private afterValidateCustomerDeviceSuccess(result: any): Observable<CustomerDevice> {
    this.customerDeviceStatus = new CustomerDevice();
    this.customerDeviceStatus.error = false;
    return of(this.customerDeviceStatus);
  }

  private afterValidateCustomerDeviceError(error: any): Observable<CustomerDevice> {
    this.customerDeviceStatus = new CustomerDevice();
    this.customerDeviceStatus.error = false;
    return of(this.customerDeviceStatus);
  }

  public insertCustomerDevice(deviceId: string, gcId: string): Observable<CustomerDevice> {
    let insertCustoemrDeviceRequest = new InsertCustomerDeviceRequest();
    let providerKey = new ProviderKey();
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    providerKey.providerId = this.storeAppConfig.CLIENT_ID;
    insertCustoemrDeviceRequest.providerKey = providerKey;
    let siteKey = new SiteKey();
    siteKey.siteId = this.storeAppConfig.SITEID;
    insertCustoemrDeviceRequest.siteKey = siteKey;
    insertCustoemrDeviceRequest.gCID = gcId;
    let customerDeviceDTO = new CustomerDeviceDTO();
    customerDeviceDTO.os = navigator.platform;
    customerDeviceDTO.browser = navigator.appName;
    customerDeviceDTO.deviceAlias = navigator.appName;
    customerDeviceDTO.deviceName = navigator.appName;
    customerDeviceDTO.deviceId = deviceId;
    insertCustoemrDeviceRequest.customerDevice = customerDeviceDTO;
    return this.customerDeviceServiceClient.insertCustomerDevice(insertCustoemrDeviceRequest)
      .pipe(mergeMap((response) => { return this.afterInsertCustomerDeviceSuccess(response); })
        , catchError((error: any) => { return this.afterInsertCustomerDeviceError(error); }));
  }

  private afterInsertCustomerDeviceSuccess(result: any): Observable<CustomerDevice> {
    this.customerDeviceStatus = new CustomerDevice();
    this.customerDeviceStatus.responseCode = result.responseCode;
    this.customerDeviceStatus.error = false;
    return of(this.customerDeviceStatus);
  }

  private afterInsertCustomerDeviceError(error: any): Observable<CustomerDevice> {
    this.customerDeviceStatus = new CustomerDevice();
    this.customerDeviceStatus.responseCode = undefined;
    this.customerDeviceStatus.error = true;
    return of(this.customerDeviceStatus);
  }

  public deleteCustomerDevice(deleteCustomerDeviceRequest: DeleteCustomerDeviceRequest): Observable<CustomerDevice> {
    return this.customerDeviceServiceClient.deleteCustomerDevice(deleteCustomerDeviceRequest)
      .pipe(mergeMap((response) => { return this.afterDeleteCustomerDeviceSuccess(response); })
        , catchError((error: any) => { return this.afterDeleteCustomerDeviceError(error); }));
  }

  private afterDeleteCustomerDeviceSuccess(result: any): Observable<CustomerDevice> {
    this.customerDeviceStatus = new CustomerDevice();
    this.customerDeviceStatus.error = false;
    return of(this.customerDeviceStatus);
  }

  private afterDeleteCustomerDeviceError(error: any): Observable<CustomerDevice> {
    this.customerDeviceStatus = new CustomerDevice();
    this.customerDeviceStatus.error = true;
    return of(this.customerDeviceStatus);
  }

}


