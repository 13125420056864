import { AmortizationDTO } from '../core/gateway-api';

export class AmortizationModel {
    amortization: AmortizationDTO; 
    error: boolean;

    constructor(amortization: AmortizationDTO, error?: boolean) {
        this.amortization = amortization;
        this.error = error;
  }
}

export class AmortizationDocument {
  error: boolean;
  faultType: string;
  amortizationData: any;
}
