<form *ngIf="notification">
  <div class="modal-notification-dialog">
    <h1>{{ notification.dialogTitle }}</h1>
    <h4 *ngIf="notification.dialogSubTitle">{{ notification.dialogSubTitle }}</h4>
    <div class="alert alert-danger" role="alert" *ngIf="error">
      <p class="error-validation" id="errorMessage">
        {{errorMessage}}
      </p>
    </div>
    <p>{{ message }}</p>
    <div *ngIf="notification.isSelected">
      <div *ngIf="notification.dialogValue" class="dropdown-container">
        <span class="bold" *ngIf="notification.dialogSubTitle">{{"ngw.my-account.notifications.payment-reminder.lbl-monthly-payments" | translate}}</span>
        <span class="label">{{"ngw.my-account.notifications.payment-reminder.lbl-number-of-days" | translate}}</span>
        <mat-select floatPlaceholder="never" [(ngModel)]="notification.dialogValue" name="dialogValue" required id="dialogValue" class="form-control" aria-label="dialogValue" aria-labelledby="dialogValue">
          <mat-option *ngFor="let number of numberOfDays" [value]="number">{{number}}</mat-option>
        </mat-select>
      </div>
      <div class="checkbox-container" *ngIf="notification.dialogOptionPrimaryText || notification.dialogOptionSecondaryText">
        <mat-checkbox name="primaryemail" [(ngModel)]="notification.dialogOptionPrimaryIsChecked" *ngIf="notification.dialogOptionPrimaryText" disabled="true">{{notification.dialogOptionPrimaryText}}</mat-checkbox>

        <mat-checkbox name="secondaryemail" [(ngModel)]="notification.dialogOptionSecondaryIsChecked" *ngIf="notification.dialogOptionSecondaryText">
          {{notification.dialogOptionSecondaryText}}&nbsp;<span *ngIf="notification.dialogTooltipText" #tooltipSecondaryEmail="matTooltip" (mouseenter)="tooltipSecondaryEmail.toggle()" (touchstart)="tooltipSecondaryEmail.toggle()" class="icon icon-info" matTooltip="{{notification.dialogTooltipText}}" (click)="secondaryEmailCheckboxTooltipClick()"></span>
        </mat-checkbox>
      </div>

      </div>

      <div class="action-buttons">
        <button class="btn btn-secondary" analytics-event="body.cancel-notification-dialog.cancel-notification-dialog.button" (click)="cancelUserAction(); $event.preventDefault()">{{cancelBtnText}}</button>
        <button class="btn btn-primary" (click)="confirmUserAction(); $event.preventDefault();" type="submit" setFocus>{{submitBtnText}}</button>
      </div>
    </div>

</form>
