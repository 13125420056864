<div class="modal-wrapper">
  <h1 class="modal-head">{{'ngw.how-to-reedem-dialog.title' | translate}}</h1>
  <p class="modal-text">{{'ngw.how-to-reedem-dialog.context-text' | translate}}</p>
  <ul class="modal-text modal-list">
    <li><i class="fa fa-arrow-circle-o-right modal-list-icon"></i>{{'ngw.how-to-reedem-dialog.first-item-text' | translate}}</li>
    <li><i class="fa fa-arrow-circle-o-right modal-list-icon"></i>{{'ngw.how-to-reedem-dialog.second-item-text' | translate}}.</li>
    <li><i class="fa fa-arrow-circle-o-right modal-list-icon"></i>{{'ngw.how-to-reedem-dialog.third-item-text' | translate}}</li>
    <li><i class="fa fa-arrow-circle-o-right modal-list-icon"></i>{{'ngw.how-to-reedem-dialog.fourth-item-text' | translate}}</li>
  </ul>
  <div class="modal-button-wrapper">
    <button class="rrd-button modal-button modal-button--white" (click)="closeDialog()">{{'ngw.how-to-reedem-dialog.btn-close' | translate | uppercase}}</button>
  </div>
</div>
