import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Base64BtnModel } from '../../../_models/base-64-btn.model';
import { SecureMessageAttachmentInfoDTO, SecureMessageDTO } from '../../../core/gateway-api';
import { MessageCenterService } from '../../../_web-services/message-center.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../shared/constants';
import { AnalyticsService } from '../../_helper-services/analytics.service';

@Component({
  selector: 'app-base-64-btn',
  templateUrl: './base-64-btn.component.html',
  styleUrls: ['./base-64-btn.component.scss']
})

export class Base64BtnComponent implements OnInit {

  @Input() base64Btn: Base64BtnModel;
  @Input() attachment?: SecureMessageAttachmentInfoDTO;
  @Input() message?: SecureMessageDTO;
  @Output() attachmentError$ = new EventEmitter<string>();
  @Input() style: string;

  constructor(private messageCenterService: MessageCenterService, private translateService: TranslateService, private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    if (this.attachment) {
      this.base64Btn = new Base64BtnModel(this.translateService, null, null, this.attachment.displayName);
    }
  }

  public prepareToOpenDocument() {
    if (this.attachment) {
      this.messageCenterService.getAttachmentForDisplay(this.message.messageId, this.attachment.storedDocumentId)
        .subscribe(response => this.postGetAttachment(response));
    }
    else {
      this.openDocument();
    }
  }
  
  private openDocument() {
    if (this.base64Btn.base64 != undefined) {
      let byteArray = this.base64ToArrayBuffer(this.base64Btn.base64);
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
          var blob2 = new Blob([byteArray], { type: "application" });
          nav.msSaveOrOpenBlob(blob2, this.base64Btn.docName + this.base64Btn.fileType);
      }
      this.saveByteArray(this.base64Btn.docName, byteArray, this.base64Btn.fileType);
      this.analyticsService.pushDownloadEvent(this.base64Btn.docName);
    }
  }
  
  private postGetAttachment(response: any) {
    if (response.error) {
      this.attachmentError$.emit();
    }
    else {
      let docName = response.attachment.displayName.split('.');
      let docType = docName.pop();
      this.base64Btn.fileType = docType;
      this.base64Btn.docName = docName;
      this.base64Btn.base64 = response.attachment.documentContent;
      this.openDocument();
    }
  }

  private base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  private saveByteArray(reportName, byte, fileType) {
    let blob: any;
    switch (fileType.toUpperCase()) {
      case 'PNG':
        blob = new Blob([byte], { type: "image/png" });
        break;
      case 'PDF':
        blob = new Blob([byte], { type: "application/pdf" });
        break;
      case 'GIF':
        blob = new Blob([byte], { type: "image/gif" });
        break;
      case 'JPEG':
        blob = new Blob([byte], { type: "image/jpeg" });
        break;
      case 'JPG':
        blob = new Blob([byte], { type: "image/jpg" });
        break;
      case 'TIF':
        blob = new Blob([byte], { type: "image/tif" });
        break;
      case 'TIFF':
        blob = new Blob([byte], { type: "image/tiff" });
        break;
      default:
        blob = new Blob([byte], { type: "application/pdf" });
        break;
    }
    
    var link = document.createElement('a');
    link.href = (window.URL || (window as any).webkitURL).createObjectURL(blob);
    link.setAttribute("download", reportName + Constants.Dot + fileType.replace(Constants.Dot, Constants.EMPTY));
    document.body.appendChild(link);
    setTimeout(function () {
      link.click();
      document.body.removeChild(link);
    }, 66);
  }
}
