import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { pairwise, filter } from 'rxjs/operators';
import { StorageType } from '../../_models/storagetype.model';
import { Constants } from '../constants';
import { StorageHelperService } from './storage-helper.service';


/** A router wrapper, adding extra functions. */
@Injectable()
export class PreviousRouteService {
  constructor(private router: Router, private storageService:  StorageHelperService) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        if (events && events.length > 0) {
          this.storageService.removeItem(Constants.PreviousUrl, StorageType.local);
          this.storageService.setItem(Constants.PreviousUrl, events[0].urlAfterRedirects, StorageType.local);
        }
      });
  }

  public getPreviousUrl() {
    return this.storageService.getItem(Constants.PreviousUrl, StorageType.local);
  }
  public removePreviousUrl() {
    this.storageService.removeItem(Constants.PreviousUrl, StorageType.local);
  }

}
