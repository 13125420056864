<h2>{{'ngw.map.entry.exceed-total-miles.title' | translate}}</h2>
<div class="general error-container" role="alert">
  <em *ngIf="globalTechError === true" class="error-validation" id="errorMessage" [innerHtml]="errorMessage">
  </em>
</div>
<p *ngIf="!globalTechError">{{descriptionText}}</p>
<div class="action-buttons margin-bottom" >
  <button class="btn btn-secondary" [mat-dialog-close] analytics-event="body.cancel-exceed-total-miles.cancel-exceed-total-miles.button">
    {{btnCancelText}}</button>
  <button class="btn btn-primary" matStepperNext type="button" *ngIf="!globalTechError && !thresholdFault">{{'ngw.map.entry.exceed-total-miles.btn-continue' | translate}}</button>
</div>
