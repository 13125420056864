import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EasyPayEnrollmentHelperService } from '../../../_helper-services/easy-pay-enrollment-helper.service';
import { RouterService } from '../../../_helper-services/router.service';
import { Constants } from '../../../constants';

@Component({
  selector: 'easy-pay-enrollment-dialog',
  templateUrl: './easy-pay-enrollment-dialog.component.html',
  styleUrls: ['./easy-pay-enrollment-dialog.component.scss']
})

export class EasyPayEnrollmentDialog {
  dialogTitle: string;
  dialogText: string;
  btnCancel: string;
  btnConfirm: string;

  constructor(private translateService: TranslateService,
        private location: Location,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private easyPayEnrollmentHelperService: EasyPayEnrollmentHelperService,
        private routerService: RouterService,
        private dialogRef: MatDialogRef<EasyPayEnrollmentDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.setPageData(this.data);
    }
  }

  setPageData(data: any){
    this.dialogText = this.easyPayEnrollmentHelperService.setEasyPayEnrollmentMessage(data);
  }

  confirmUserAction() {
    this.dialogRef.close(true);
  }

  cancelUserAction() {
    this.dialogRef.close();
  }

  public navigateToAccountDetails(): void {
    this.dialogRef.close(Constants.AccountDetails);    
  }

}
