import { Component, OnInit, HostListener, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { UserService } from './../../_helper-services/user.service';
import { RouterService, route, routes } from '../../_helper-services/router.service';
import { DialogService } from '../../_helper-services/dialog.service';
import { CanNavigateBackActions } from '../../store/reducers/can-navigate-back.reducer';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/app.store';
import { MatMenuTrigger } from '@angular/material/menu';
import { UrlBuilderService, constants as serviceLibConstants } from 'customerdigital-service-lib';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';
import { HeaderIconType, HeaderItemType, HeaderMenuModel, constants } from 'customerdigital-ui-lib';
import { ContractAccountDetailDTO } from '../../../core/gateway-api';
import { ObjectService } from '../../_helper-services/object.service';
import { HeaderHelperService } from '../../_helper-services/header-helper.service';
import { ApplicationConfig } from '../../../_models/application-config';
import { isEmpty } from 'lodash-es';
import { Observable, switchMap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { LanguageService } from '../../_helper-services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  public navIsFixed: boolean;
  public profileMenuList: any[] = [];
  public allMenuItem: string[];
  public matMenuImgPath: string;
  public selectedIndex: number;
  private menuOpened: boolean = false;
  public isAccountActive: boolean;
  public isDashboardActive: boolean;
  public isOffersActive: boolean;
  public isProfileActive: boolean;
  private contractsSubs: any;
  public resetHeaderItems: boolean;
  public dashboardMenuItem: HeaderMenuModel;
  public accountsMenuItem: HeaderMenuModel;
  public profileMenuItem: HeaderMenuModel;
  private eotMenutItem: HeaderMenuModel;
  private offersMenutItem: HeaderMenuModel;
  private const = constants;
  private currentMenuItem: string;
  public isBMW: boolean;
  private baseTranslationKey = 'ngw.shared.sub-nav-header.';
  public headerTitle: string;
  public skipContentText: string;
  public ariaLabelMainNav: string;
  public ariaLabelSecNav: string;
  public headerMenuItems: HeaderMenuModel[] = [];
  backBtnMode = HeaderIconType.BackMode;
  headerBackBtnEnabled: boolean = true;
  headerRoutes: { [key: string]: string } = {};
  onClickofBackBtn: boolean = false;
  currentUrl: string;
  previousUrl: string;
  routeHistory: any;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(private userService: UserService,
    private routerService: RouterService,
    private dailogService: DialogService,
    private store: Store<IAppState>,
    private canNavigateBackActions: CanNavigateBackActions,
    private urlBuilderService: UrlBuilderService,
    private translateService: TranslateService,
    private objectService: ObjectService,
    private headerHelperService: HeaderHelperService,
    private router: Router,
    private langugaeService: LanguageService,

    @Inject(DOCUMENT) private document: Document) {
  }
  ngOnInit() {
    this.headerBackBtnEnabled = false;
    this.headerHelperService.routerHistory.next({internalRoutes: [], externalRoutes: null});
    this.handleBackButtonClick();
    this.translateService.get('ngw.header.mat-menu-img-path').subscribe(value => { this.matMenuImgPath = value });
    let profile_Menu_Item: string;
    let appConfig: ApplicationConfig;
    this.store.select(state => state.ApplicationConfig!).subscribe(x => appConfig = x);
    profile_Menu_Item = appConfig!.PROFILE_MENU_ITEM;
    this.isBMW = appConfig!.BRAND == "BMW";
    this.allMenuItem = JSON.parse(profile_Menu_Item);
    this.allMenuItem.forEach(item => this.pushMenuItem(item));
    this.setTranslations();
    this.contractsSubs = this.store.select(state => state.ContractAccountDetails);
    this.routerService.getRouteData().pipe(
      switchMap((event: Event): Observable<any> => {
        if (event && !isEmpty(event) && event['subNavType']) {
          this.setMenuItems(event['subNavType']);
          this.currentMenuItem = event['subNavType'];
          this.setActiveItem();
        }
        else {
          this.headerMenuItems = [];
        }
        return this.translateService.onLangChange
      })
    ).subscribe(() => {
      if (this.dashboardMenuItem || this.accountsMenuItem || this.profileMenuItem || this.eotMenutItem || this.offersMenutItem) {
        this.setTranslations();
        this.setMenuItemsText();
        this.contractsSubs.subscribe((contracts: ContractAccountDetailDTO[]) => this.setMenuOptions(contracts, true));
      }
    })
  }

  pushMenuItem(item) {
    this.profileMenuList.push(item);
  }

  private setTranslations() {
    let headerTitleKey = `${this.baseTranslationKey}lbl-my-account`;
    let skipContentTextKey = `${this.baseTranslationKey}skipToContentText`;
    let ariaLabelMainNavKey = `${this.baseTranslationKey}ariaLabel.mainNav`;
    let ariaLabelSecNavKey = `${this.baseTranslationKey}ariaLabel.secNav`;
    this.translateService.get([skipContentTextKey, ariaLabelMainNavKey, ariaLabelSecNavKey, headerTitleKey]).subscribe(res => {
      this.skipContentText = res[skipContentTextKey];
      this.ariaLabelMainNav = res[ariaLabelMainNavKey];
      this.ariaLabelSecNav = res[ariaLabelSecNavKey];
      this.headerTitle = res[headerTitleKey]
    });

  }


  private initMenuItems() {
    if (!this.dashboardMenuItem || !this.accountsMenuItem || !this.profileMenuItem || !this.eotMenutItem || !this.offersMenutItem) {
      this.setMenuItemsText();
    }
    this.contractsSubs.subscribe((contracts) => this.setMenuOptions(contracts));
  }

  private setMenuItemsText() {
    let dashboardTextKey = `${this.baseTranslationKey}lbl-dashboard`;
    let accountsTextKey = `${this.baseTranslationKey}lbl-accounts`;
    let profileTextKey = `${this.baseTranslationKey}lbl-profile`;
    let eotCenterTextKey = `${this.baseTranslationKey}lbl-eot-center`;
    let offersTextKey = `${this.baseTranslationKey}lbl-special-offers`;
    this.translateService.get([dashboardTextKey, accountsTextKey, profileTextKey, eotCenterTextKey, offersTextKey]).subscribe(res => {
      this.dashboardMenuItem = {
        id: 'dashboard',
        eventName: 'navigateToHome',
        innerText: res[dashboardTextKey],
        type: HeaderItemType.link,
        active: this.isDashboardActive
      };
      this.accountsMenuItem =
      {
        id: 'accounts',
        eventName: 'navigateToAccounts',
        innerText: res[accountsTextKey],
        type: HeaderItemType.link,
        active: this.isAccountActive
      };
      this.eotMenutItem =
      {
        id: 'eot',
        eventName: 'navigateToEndOfTerm',
        innerText: res[eotCenterTextKey],
        type: HeaderItemType.link,
        active: false
      };
      this.offersMenutItem =
      {
        id: 'offers',
        eventName: 'navigateToOffers',
        innerText: res[offersTextKey],
        type: HeaderItemType.link,
        active: false
      };
      this.profileMenuItem =
      {
        id: 'settings',
        eventName: 'navigateToMyProfile',
        type: HeaderItemType.icon,
        innerText: res[profileTextKey],
        iconName: this.const.IconName.Settings,
        active: this.isProfileActive
      };
    });
  }

  private setMenuOptions(contracts: ContractAccountDetailDTO[], override: boolean = false) {
    if (!override && this.headerMenuItems.length > 0 && !this.resetHeaderItems) return;
    let isEotEnabled = this.headerHelperService.eotEnabled();
    this.resetHeaderItems = this.objectService.isEmptyObject(contracts) || contracts.length == 0 || isEotEnabled === undefined;;
    this.headerMenuItems = [this.dashboardMenuItem, this.accountsMenuItem, this.profileMenuItem];

    if (isEotEnabled) {
      let shouldAddEOTOption = this.headerHelperService.shouldAddCAEOTOption(contracts);
      if (shouldAddEOTOption) {
        this.headerMenuItems.push(this.eotMenutItem);
      }
    }
    else {
      let hasOffers = this.headerHelperService.showOffers();
      if (hasOffers)
        this.headerMenuItems.push(this.offersMenutItem);
    }
  }


  private setMenuItems(event: any) {
    this.isAccountActive = event == Constants.Accounts;
    this.isProfileActive = event == Constants.Profile;
    this.isDashboardActive = event == Constants.Dashboard;
    this.initMenuItems();
  }


  public navigateTo(event: any) {
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.dailogService.closeContractChangeDialog();
    switch (event.eventName) {
      case this.dashboardMenuItem.eventName:
        this.routerService.navigateToHome();
        return
      case this.accountsMenuItem.eventName:
        this.routerService.navigateToAccounts();
        return
      case this.profileMenuItem.eventName:
        this.routerService.navigateToMyProfile();
        return
      case this.eotMenutItem.eventName:
        this.headerHelperService.endOfTermNavigation(this.headerMenuItems, this.currentMenuItem);
        return;
      case this.offersMenutItem.eventName:
        this.routerService.navigateToOffers();
        return
    }
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll(event) {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
    let position = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (position > 65) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && position < 65) {
      this.navIsFixed = false;
    }
  }
  public scrollToTop(): void {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }
  isLoggedIn(): boolean {
    return this.userService.isAuthenticated();
  }
  isHomeAccess(): boolean {
    return this.userService.isHomeAccess(true);
  }
  isBrandBMW(): boolean {
    return this.isBMW;
  }
  getBrandBasedHeaderClass(divType: any): string {
    let className;
    switch (divType) {
      case 'navOuterDiv': {
        className = this.isBMW ? 'nav-primary' : 'nav-secondary';
        break;
      }
      case 'navInnerDiv': {
        className = this.isBMW ? 'desktop-header-container desktop-container no-print' : 'desktop-container';
        break;
      }
      default: {
        break;
      }
    }
    return className;
  }

  getMatMenuClass(): string {
    if (this.isBMW)
      return 'nav-user-menu';
  }
  navigateToHome() {
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.dailogService.closeContractChangeDialog();
    this.routerService.navigateTo(routes.home);
  }

  logout() {
    this.selectedIndex = -1;
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.userService.logout();
    this.dailogService.closeContractChangeDialog();
  }

  login() {
    this.routerService.navigateToLogin();
  }

  isActive(index: number): boolean {
    if (this.selectedIndex === index) {
      return true;
    }
    else {
      return false;
    }
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  public navigateToManageSettings(index: number) {
    this.selectedIndex = index;
    let profileUrl = this.urlBuilderService.getEditUsernameRedirectUrl();
    this.routerService.navigateToExternalUrlInNewTab(profileUrl);

  }

  public navigateToMyAccount(index: number) {
    this.selectedIndex = index;
    this.dashboardMenuItem.active = true;
    this.accountsMenuItem.active = false;
    this.profileMenuItem.active = false;
    this.routerService.navigateToHome();
  }
  public onHeaderBackBtnClick() {
    this.onClickofBackBtn = true;
    if (this.routeHistory.internalRoutes.length == 0) {
      const url = this.routeHistory.externalRoutes.includes(`${serviceLibConstants.UrlParametersConcat}${serviceLibConstants.LanguageParameterName}`) ? this.routeHistory.externalRoutes : `${this.routeHistory.externalRoutes}${serviceLibConstants.UrlParametersConcat}${serviceLibConstants.LanguageParameterName}${serviceLibConstants.UrlParameterNameAndValueConcat}${this.langugaeService.getCurrentLanguage()}`;
      this.routerService.navigateToExternalUrl(url);
      return;
    }
    this.routeHistory.internalRoutes.pop();
    this.headerHelperService.setRouteHistory(this.routeHistory);
    this.routerService.navigateBack();
  } 

  public setActiveItem(): void {
    if (this.headerMenuItems.length > 0) {
      this.headerMenuItems.forEach((menuItem) => {
        menuItem.active = false;
        if (menuItem?.id === this.currentMenuItem.toLowerCase()) {
          return menuItem.active = true;
        }
      })
    }
  }

  public handleBackButtonClick() {
    this.headerHelperService.routerHistory.subscribe(data =>{
      this.routeHistory = data;
    })
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.headerBackBtnEnabled = false;
        if (!this.onClickofBackBtn) {
          this.previousUrl = this.currentUrl;
          if (!this.previousUrl&&
            document.referrer && new URL(document.referrer).origin === new URL(window.location.href).origin &&
             document.referrer !== window.location.href) {
           this.routeHistory.externalRoutes = document.referrer;
           this.headerHelperService.setRouteHistory(this.routeHistory);
         }
          if (this.previousUrl && (!this.previousUrl.includes(route.sso) && !this.previousUrl.includes(route.twoFactorAuth)) && !this.headerHelperService.languageChanged)
            {
              this.routeHistory.internalRoutes.push(this.previousUrl);
              this.headerHelperService.setRouteHistory(this.routeHistory);
            }
          if (this.previousUrl && this.previousUrl.includes(event.url)) {
            this.routeHistory.internalRoutes.pop();
            this.headerHelperService.setRouteHistory(this.routeHistory);
          }
          this.headerBackBtnEnabled = this.routeHistory.internalRoutes.length > 0 && !this.headerHelperService.languageChanged ? true : false;
        }
        if (this.routeHistory.internalRoutes.length > 0 || (this.routeHistory.internalRoutes.length == 0 && this.routeHistory.externalRoutes && !this.headerHelperService.languageChanged))
          this.headerBackBtnEnabled = true;
          this.currentUrl = event.url.split(serviceLibConstants.UrlParametersPrefix)[0];
          this.onClickofBackBtn = false;
      }
    })
  }

}