export class MileageEntryDialogData {
  reload: boolean;
  navigateTo: MileageEntryDialogDataNavigationType;

  constructor(reload?: boolean, navigateTo?: MileageEntryDialogDataNavigationType) {
    this.reload = reload ? reload : false;
    this.navigateTo = navigateTo ? navigateTo : MileageEntryDialogDataNavigationType.None
  }
}

export enum MileageEntryDialogDataNavigationType {
  None = 0,
  MAPPayment = 1,
  MAPReview = 2,
  ContactUs = 3,
  SecureMessageCenter = 4
}

