<app-spinner></app-spinner>
<div class="dialog-delete-payment-source mat-mdc-dialog-container dialog">
  <h1 class="title">{{"ngw.payment-source.delete-payment-source.title-msg" | translate}}</h1>
  <div class="alert alert-danger" role="alert" *ngIf="deletePaymentSourceError == true">
    <p class="error-validation" id="errorMessage">
      {{errorMessage}}
    </p>
  </div>
  <div class="savings-or-checking" *ngIf="isSavingOrChecking()">
    <div class="payment-source-info-row">
      <span class="label">{{"ngw.payment-source.delete-payment-source.lbl-bank-name" | translate}}</span>
      <span class="value" id="paymentSource">{{this.paymentSourceDeleteReview.bankName}}</span>
    </div>
    <div class="payment-source-info-row">
      <span class="label">{{"ngw.payment-source.delete-payment-source.lbl-account" | translate}}</span>
      <span class="value" id="paymentEmail">{{this.paymentSourceDeleteReview.nickName}}</span>
    </div>
    <div class="payment-source-info-row">
      <span class="label">{{"ngw.payment-source.delete-payment-source.lbl-routing-number" | translate}}</span>
      <span class="value" id="paymentAmount">{{this.paymentSourceDeleteReview.routingNumber }}</span>
    </div>
  </div>
  <div class="debit-or-credit" *ngIf="isCreditOrDebit()">
    <div class="payment-source-info-row">
      <span class="label">{{"ngw.payment-source.delete-payment-source.lbl-account-type" | translate}}</span>
      <span class="value" id="paymentDate">{{this.paymentSourceDeleteReview.nickName}}</span>
    </div>
    <div class="payment-source-info-row">
      <span class="label">{{'ngw.payment-source.delete-payment-source.lbl-expiration-date' | translate}}</span>
      <span class="value" id="paymentEmail">{{this.paymentSourceDeleteReview.expirationdate | date:"MM/yy"}}</span>
    </div>
  </div>
  <div class="action-buttons">
    <button (click)="cancelDeletePaymentSource()" class="btn btn-secondary" id="deletePaymentSourceBtn" analytics-event="body.cancel-delete-payment-source.cancel-delete-payment-source.button">
      {{"ngw.payment-source.delete-payment-source.btn-cancel-delete" | translate}}</button>
    <button (click)="deletePaymentSource()" class="btn btn-primary" id="deletePaymentSourceBtn">{{"ngw.payment-source.delete-payment-source.btn-delete-paymnt-source" | translate}}</button>
  </div>
</div>
