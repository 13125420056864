import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { ActivityTypes } from '../../../enums';
import { LogSiteActivityService } from '../../../../_web-services/log-site-activity.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vehicle-service-dialog',
  templateUrl: './vehicle-service-dialog.component.html',
  styleUrls: ['./vehicle-service-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VehicleServiceDialog extends BaseApplicationComponent<VehicleServiceDialog> {

  public dialogTitle: string;
  public dialogMessage: string;
  public dialogDuringLeaseEndMessage: string;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<VehicleServiceDialog>,
    private logSiteActivityService: LogSiteActivityService,
    @Inject(DOCUMENT) document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector) {
    super(injector, document);
  }

  ngOnInit() {
    this.translateService.get('ngw.dashboard.account-activity.vehicle-service-dialog.title').subscribe(value => { this.dialogTitle = value });
    if (this.data.isCompleted) {
      this.translateService.get('ngw.dashboard.account-activity.vehicle-service-dialog.complete-message').subscribe(value => { this.dialogMessage = value });
    }
    else {
      this.translateService.get('ngw.dashboard.account-activity.vehicle-service-dialog.not-complete-message').subscribe(value => { this.dialogMessage = value });
      if (this.data.isLeaseEnd) {
        this.translateService.get('ngw.dashboard.account-activity.vehicle-service-dialog.during-lease-end-message').subscribe(value => { this.dialogDuringLeaseEndMessage = value });
      }
    }
  }

  public cancel() {
    this.dialogRef.close(null);
  }

  public continue() {
    let myGarageURL: string;
    this.store.select(state => state.EnvironmentConfig.MY_GARAGE_URL).subscribe(x => myGarageURL = x);
    this.logSiteActivityService.logUserActivity(this.data.customerNumber, this.data.accountNumbers, ActivityTypes.VehicleServiceContinue);
    this.document.location.href = myGarageURL;
  }

}
