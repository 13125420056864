export class LeaseExtensionEligbility {
  isEligible: boolean;
  description: string;
  showContactUs: boolean;
  showMakeAPayment: boolean;

  constructor() {
    this.isEligible = false;
    this.showContactUs = false;
    this.showMakeAPayment = false;
  }
}



