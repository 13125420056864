import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { PaperlessPreferenceStatus } from '../../../_models/my-account/paperless-statement.model';

@Injectable()
export class PaperlessPreferenceStatusActions {
  static SET_PAPERLESS_PREFERENCE: string = 'SET_PAPERLESS_PREFERENCE';
  static CLEAR_PAPERLESS_PREFERENCE: string = 'CLEAR_PAPERLESS_PREFERENCE';

  setPaperlessPrefrenceStatus(paperlessPreference: PaperlessPreferenceStatus): FSA<string, any, null> {
    return {
      type: PaperlessPreferenceStatusActions.SET_PAPERLESS_PREFERENCE,
      payload: paperlessPreference,
      meta: null
    };
  }

  clearPaperlessPrefrenceStatus(): FSA<string, any, null> {
    return {
      type: PaperlessPreferenceStatusActions.CLEAR_PAPERLESS_PREFERENCE,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class PaperlessPreferenceStatusReducers {
  static PaperlessPreferenceStatusReducer: Reducer<PaperlessPreferenceStatus> = (state: PaperlessPreferenceStatus = new PaperlessPreferenceStatus(), action: FSA<string, PaperlessPreferenceStatus, void>) => {
    switch (action.type) {
      case PaperlessPreferenceStatusActions.SET_PAPERLESS_PREFERENCE:
        return tassign(state, action.payload);
      case PaperlessPreferenceStatusActions.CLEAR_PAPERLESS_PREFERENCE:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
