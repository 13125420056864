import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from "lodash-es";
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AccountActivity } from '../_models/payment-details/account-activity.model';
import { ObjectService } from '../shared/_helper-services/object.service';
import { AccountActivitiesActions } from '../shared/store/reducers/account-activities.reducers';
import { ContractAccountDetailsActions } from '../shared/store/reducers/contract-account-details.reducers';
import { AccountActivityDetailDTO, AccountServiceClient, GetAccountActivityRequest } from './../core/gateway-api';
import { FSTokenErrorHandler } from './../shared/_errorhandler/gobal-error-handler';
import { IAppState } from "./../shared/store/app.store";

@Injectable()
export class AccountService {

  constructor(private accountServiceClient: AccountServiceClient,
        private fsTokenErrorHandler: FSTokenErrorHandler,
        private accountActivitiesActions: AccountActivitiesActions,
        private store: Store<IAppState>,
        private objectService: ObjectService,
        private contractAccountDetailsActions: ContractAccountDetailsActions) {
    }

      public getAccountActivity(accountNumber: string): Observable<AccountActivity> {
            let getAccountActivityRequest = new GetAccountActivityRequest();
            getAccountActivityRequest.accountNumber = accountNumber;
            return this.accountServiceClient.getAccountActivity(getAccountActivityRequest)
              .pipe(mergeMap((response) => { return this.afterGetAccountActivitySuccess(response, accountNumber); }),
              catchError((error: any) => { return this.afterGetAccountActivityFailure(error); }));
    }

    private getActivityDetails(response: any): AccountActivityDetailDTO[] {
      let details: AccountActivityDetailDTO[] = new Array();
      _.each(response.accountActivity[0].activityDetail, function (detail: AccountActivityDetailDTO) {
        details.push(detail);
      })
      return details;
    }
  
    private afterGetAccountActivitySuccess(response: any, accountNumber: string): Observable<AccountActivity> {
        let details: AccountActivityDetailDTO[] = this.getActivityDetails(response);
        let accountActivity = new AccountActivity(false, accountNumber, details);
        this.store.dispatch(this.accountActivitiesActions.pushAccountActivity(accountActivity));
        return of(accountActivity);
    }

    private afterGetAccountActivityFailure(result: any): Observable<AccountActivity> {
        let accountActivity = new AccountActivity(true);
        this.fsTokenErrorHandler.handleFSTokenError(result);
        return of(accountActivity);
    }

}
