import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/app.store';

@Component({
  selector: 'app-epaas-error',
  templateUrl: './epaas-error.component.html',
  styleUrls: ['./epaas-error.component.scss']
})
export class EpaasErrorComponent implements OnInit {

  public isEpaasError: boolean = false;
  constructor(
    private store: Store<IAppState>
  ) {
    this.store.select(state => state.CannotAuthenticate.booleanValue)
      .subscribe(error => {
         this.isEpaasError = error;
      });
  }

  ngOnInit() {
  }

  close() {
    this.isEpaasError = false;
  }

}