import { PaymentAccountDTOAccountType, FinancialAccountDTO, RecurringPaymentInfoDTO, OneTimePaymentRestrictionsDTO, PaymentInfoDTO, ContractAccountDetailDTO, ScheduledItemDTO, FinancialAccountDTOAccountType, PaymentDistributionDetailDTO } from '../core/gateway-api';

export class PaymentInfo {
  error: boolean;
  faultType: string;
  errorResponse: string;
  paymentAccounts: FinancialAccountDTO[];
  recurringPaymentInfo: RecurringPaymentInfoDTO;
  aCHRestrictions: OneTimePaymentRestrictionsDTO;
  cardRestrictions: OneTimePaymentRestrictionsDTO;
  paymentsDue: PaymentInfoDTO[];
  paymentEligibilityFlags: { [key: string]: boolean; };
  cardPaymentFeeAmount: number;
  scheduledItemToHold: ScheduledItemDTO;
  paymentOverrideLimit: number;
}

export class PaymentEntry {
  accountNumber: string;

  //Payment Source
  selectedPaymentSource: PaymentSource;
  sources: PaymentSource[];
  noPaymentSource: boolean;
  isAch: boolean;

  //Email Address
  emails: string[];
  emailAddress: string;
  selectedEmailAddress: string;
  enterNewEmail: boolean;
  differentEmail: boolean;

  //Payment Date
  paymentdate: Date;
  currentDueDate: Date;
  firstDatePaymentAllowed: Date;
  lastDatePaymentAllowed: Date;
  paymentDateMessage: string;
  paymentdateReadOnly: string;
  aCHRestrictions: OneTimePaymentRestrictionsDTO;
  cardRestrictions: OneTimePaymentRestrictionsDTO;
  cardPaymentFeeAmount: number;
  showPaymentDateMessage: boolean;

  //Previous Payment Details
  lastPaymentInformationText: string;
  showLastPaymentDetail: boolean;

  //Payment Amount
  paymentDueList: PaymentAmountDDL[];
  selectedPaymetAmount: PaymentAmountDDL;
  paymentAmount: number;
  paymentOverrideLimit: number;
  otherAmountSelected: boolean;
  showPaymentAmountToolTip: boolean;
  contractAccountDetails: ContractAccountDetailDTO[];
  paymentEligibilityFlags: { [key: string]: boolean; };
  paymentDistributionDetailDTO: PaymentDistributionDetailDTO[];

  // myBMW link
  myBMWLink: string;
  scheduledItemToHold: ScheduledItemDTO;
}

//export class ACHPaymentSource {
export class PaymentSource {
  financialAccountNumber: string;
  displayAccountNumber: string;
  financialAccountId: string;
  lastUsed: number;
  routingNumber: number;
  accountType: FinancialAccountDTOAccountType;
  retain: boolean;
  accountNickname: string;
  bankId: number;
  error: boolean;
  errorType: ACHErrorType;
  bankName: string;
  expirationdate: Date;
  isACH: boolean;
  subType: string;
  maskedRoutingNumber: string;
  accountNumber: string;

  constructor(_financialAccountNumber?: string, _displayAccountNumber?: string, _financialAccountId?: string, _lastUsed?: number, _bankId?: number, _accountType?: FinancialAccountDTOAccountType) {
    this.financialAccountNumber = _financialAccountNumber;
    this.displayAccountNumber = _displayAccountNumber;
    this.financialAccountId = _financialAccountId;
    this.lastUsed = _lastUsed;
    this.bankId = _bankId;
    this.accountType = _accountType;
  }
}

export class PaymentReviewDetails {
  accountno: string;
  customerno: number;
  faultType: string;
  error: boolean;
  faultMessage: string;
  confirmationNumber: number;
  currentAccountBalance: number;
  nextPaymentDueDate: Date;
  paymentSource: string;
  paymentAmount: number;
  paymentDate: Date;
  paymentDateFormatted: string;
  emailAddress: string;
  financialAccountId: string;
  paymentDateStatus: string;
  accountType: FinancialAccountDTOAccountType;
  easyPayAccountType: PaymentAccountDTOAccountType;
  selectedPaymentMethod: string;
  isAllowToBackward: boolean;
  residualValue: number;
  newMaturityDate: Date;
  cancelEasyPay: boolean;
  extensionQuoteId: number;
  successfullyBooked: boolean;
  paymentConfEmailPreference: boolean;
  primaryEmailAddress: string;
  scheduledItemToHold: ScheduledItemDTO;
  lumpSumAmount: number;
}


export class PaymentDueSource {
  paymentType: string;
  paymentAmount: number;

  constructor(_paymentType: string, _paymentAmount: number) {
    this.paymentType = _paymentType;
    this.paymentAmount = _paymentAmount;
  }
}

export class PaymentAmountDDL {
  key: string;
  keyDesc: string;
  value: number;

  constructor(_key: string, _keyDesc: string, _value: number) {
    this.key = _key;
    this.value = _value;
    this.keyDesc = _keyDesc;
  }
}

export enum ACHErrorType {
  InvalidRoutingNumber,
  AddACHFailed
}

export class PSDCheckboxes {
  name: string;
  value: number;
  checked: boolean;
  fees: number;

  constructor(_name: string, _value: number,_checked:boolean, _fees: number) {
    this.name = _name;
    this.value = _value;
    this.checked = _checked;
    this.fees = _fees;
  }
}
