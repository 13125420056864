<div class="rrd-container">
  <div class="rrd-wrapper">
    <div class="rrd-leftsec">
      <img src="../../../../assets/images/card-image.png" alt="rewards-img">
      <div *ngIf="!isNotCreditCardHolder" class="rrd-pricesec">
        <h1 class="rrd-price"><span class="rrd-priceactive">{{cardHolderModel.rewardPoints | number}}</span> / {{cardHolderModel.dollarValue | currency : 'USD':true}}</h1>
        <p class="rrd-pricetext">{{'ngw.credit-card-banner.reward-point-text' | translate}}</p>
      </div>
      <div *ngIf="isNotCreditCardHolder" class="rrd-pricesec rrd-priceholder">
        <h1 class="rrd-price"><span class="rrd-priceactive">{{'ngw.credit-card-banner.high-performance-text' | translate}}</span></h1>
      </div>
    </div>
    <div class="rrd-seperator"></div>
    <div *ngIf="(isEnoughPoints && !isNotCreditCardHolder)" class="rrd-rightsec">
      <p>{{'ngw.credit-card-banner.right-content-text-greater-than-minimum' | translate}}</p>
      <div class="rrd-buttonsec">
        <button class="rrd-button rrd-button--transparent" (click)="applyNow(); $event.preventDefault();"><img src="../../../../assets/images/icon.png" alt="btn-icon">{{'ngw.credit-card-banner.text-redeem-point' | translate}}</button>
        <a (click)="openHowItWorkDialog();" class="rrd-links rrd-links--white">{{'ngw.credit-card-banner.link-how-it-work' | translate}}</a>
      </div>
    </div>
    <div *ngIf="(!isEnoughPoints && !isNotCreditCardHolder)" class="rrd-rightsec-1">
      <div class="rrd-rightsec-baseline">
        <p>{{textLessThanMinumum}}</p>
        <a (click)="openHowItWorkDialog();" class="rrd-links rrd-links--white">{{'ngw.credit-card-banner.link-how-it-work' | translate}}</a>
      </div>
    </div>
    <div *ngIf="isNotCreditCardHolder" class="rrd-rightsec-2">
      <p>{{'ngw.credit-card-banner.right-content-text-no-card' | translate}}</p>
      <div class="rrd-buttonsec">

        <button class="rrd-button rrd-button--transparent" type="button" (click)="applyNow(); $event.preventDefault();">
          <img src="../../../../assets/images/icon.png" alt="btn-icon">{{'ngw.credit-card-banner.text-apply-now' | translate}}
        </button>
      </div>
    </div>
  </div>
  <p class="rrd-disclaimer">{{'ngw.credit-card-banner.disclamer-text' | translate}}</p>

  <div *ngIf="cardHolderModel.cardHolderSsoEnabled">
    <form ngNoForm #creditCardSsoPostForm name="creditCardSsoPostForm" action="{{cardHolderModel.ssoLoginUri}}" method="POST" target="_blank">     
      <input type="hidden" name="SAMLResponse" value="{{cardHolderModel.samlBase64}}" />
    </form>
  </div>
     
</div>


