import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { SsoComponent } from './sso.component';
import { SsoRoutingModule } from './sso-routing.module';
import { LoginService } from '../../_web-services/login.service';
import { LoginServiceClient, LoginServiceByTokenClient } from '../../core/gateway-api';
import { SsoLogoutComponent } from './_components/sso-logout/sso-logout.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SsoRoutingModule
  ],
  exports: [SsoComponent, SsoLogoutComponent, SsoRoutingModule],
  declarations: [SsoComponent, SsoLogoutComponent],
  providers: [LoginService, LoginServiceClient, LoginServiceByTokenClient]
})
export class SsoModule { }
