import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseApplicationComponent } from "../../../core/basecomponent/base.component";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent extends BaseApplicationComponent<SpinnerComponent> implements OnInit, OnDestroy {

  
    constructor(
        injector: Injector,
        @Inject(DOCUMENT) document) { 
        super(injector, document);
    }


}
