import { NgModule } from '@angular/core';

import { FeatureToggleDirective } from './feature-toggle.directive';
import { FeatureToggleService } from './feature-toggle.service';
import { FeatureToggleGuard } from './feature-toggle.guard';

const ANGULAR_FEATURE_TOGGLE_DIRECTIVES: any[] = [
    FeatureToggleDirective
];

@NgModule({
    declarations: ANGULAR_FEATURE_TOGGLE_DIRECTIVES,
    exports: ANGULAR_FEATURE_TOGGLE_DIRECTIVES,
    providers: [FeatureToggleService]
})

export class FeatureToggleModule { }
