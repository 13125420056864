import { Injectable, Injector } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Constants } from '../constants';
import { Store } from '@ngrx/store';
import { DeepLinkParam } from "../../_models/deeplink-param.model";
import { IAppState } from "../store/app.store";
import { UrlBuilderService } from "./url-builder.service";
import { Location } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NewSourceType } from '../enums';
import { ContractAccountDetailDTO } from '../../../_shared/core/gateway-api';

export const routeV2 = {
  maturityBilling: "/paymentcenter/finalbill",
  endofterm: '/intenthub/endofterm',
  vehiclereturnestimate: '/vehiclereturnestimate',
  payoff: '/paymentcenter/payoff',
  makeapayment: '/payment/entry',
  statements: '/paymentcenter/statements',
  paymentdetails: '/paymentcenter/paymentdetails',
  easypay: '/easypay',
  duedatechange: '/duedatechange',
  leaseextension: '/leaseextension',
  paymentoverview: '/paymentcenter/paymentoverview',
  mileageadjustment: '/mileageadjustment'
};

export const route = {
  baseUrl: "",
  empty: '',
  home: "home",
  vehicles: "vehicles",
  accounts: "accounts",
  terms: "terms",
  termsView: "terms/view",
  termsViewLink: "/terms/view",
  faq: "faq",
  faqLink: "/faq",
  contactus: "contactus",
  contactusLink: "/contactus",
  forgotpassword: "forgotpassword",
  forgotpasswordconfirmation: "forgotpassword/confirmation",
  forgotpassworderror: "forgotpassword/error",
  forgotpasswordsuccess: "forgotpassword/success",
  forgotpasswordchange: "forgotpassword/change/:token",
  login: "login",
  registration: "registration",
  registrationconfirmation: "registration/confirmation",
  registrationactivation: "registration/activation",
  registrationresend: "registration/resend",
  legitimization: "legitimization",
  twoFactorAuth: "authentication",
  nocode: "authentication/nocode",
  method: "authentication/method",
  entercode: "authentication/entercode",
  rememberme: "authentication/rememberme",
  accountlinks: "accountlinks",
  acceptTerms: "terms/accept",
  changepassword: "managesettings/changepassword",
  managesettings: "managesettings",
  myaccount: "myaccount",
  estimate: "estimate",
  accountdetails: "myaccount/details",
  paymentdetails: "myaccount/paymentdetails",
  payoffinformation: "myaccount/payoff",
  statements: "myaccount/statements",
  notifications: "myaccount/notifications",
  leasematurityinvoice: "myaccount/maturityinvoice",
  payment: "payment",
  paymentchangeemail: "payment/changeemail",
  paymentpaymentdate: "payment/paymentdate",
  paymententry: "payment/entry",
  paymentreview: "payment/review",
  paymentconfirmation: "payment/confirmation",
  paymentAuthorization: 'payment/authorization',
  duedateentry: "duedatechange/entry",
  duedatereview: "duedatechange/review",
  duedateconfirmation: "duedatechange/confirmation",
  duedateinformational: "duedatechange/informational",
  mappayment: "map/payment",
  mapreview: "map/review",
  mapagreement: "map/agreement",
  mapconfirmation: "map/confirmation",
  leasepayment: "extension/payment",
  leasereview: "extension/review",
  leaseagreement: "extension/agreement",
  leaseduration: "extension/duration",
  leaseconfirmation: "extension/confirmation",
  paymentSource: "payment/paymentsource",
  addEmailAddress: "payment/changeemail/add",
  addPaymentSource: "payment/paymentsource/add",
  addAchPaymtSource: "payment/paymentsource/addach",
  addCard: "payment/paymentsource/addcard",
  paymentAmount: "payment/paymentamount",
  addAchPaymentSource: "paymentsource/addach",
  addAchPaymentConfirmation: "paymentsource/confirmach",
  addPaymentCard: "paymentsource/addcard",
  addPaymentDebit: "paymentsource/adddebit",
  recurringpaymententry: "recurringpayment/entry",
  recurringpaymentreview: "recurringpayment/review",
  recurringpaymentconfirmation: "recurringpayment/confirmation",
  serviceerror: "serviceerror",
  error: "error",
  errorPage: "errorPage",
  changePasswordConfirmation: 'managesettings/changepassword/confirmation',
  viewPaymentSource: "paymentsource/paymentsources",
  smcinbox: "securemessagecenter/inbox",
  smcview: "securemessagecenter/view",
  smccompose: "securemessagecenter/compose",
  myProfile: "myProfile",
  maturity: "maturitybilling",
  maturityBilling: "maturitybilling/leaseend",
  clickToSign: "securemessagecenter/clicktosign",
  clickToSignImg: "documentpage/document",
  cookiePolicy: "cookiepolicy",
  esign: "esign",
  esignAccept: "esign/accept",
  scra: "scra",
  userInactivity: "userinactivity",
  redirect: "redirect",
  sso: "sso",
  ssoapp: "ssoapp",
  pathNotFound: "pathNotFound",
  ssologout: 'sso/logout',
  maintenance: 'maintenance',
  paperless: 'paperless',
  payoff: 'payoff',
  onboarding: 'onboarding',
  paymentdeferralinfo: 'payment-deferral/info',
  paymentdeferralentry: 'payment-deferral/entry',
  paymentdeferralconfirmation: 'payment-deferral/confirmation',
  paymentdeferralrequest: 'payment-deferral/request',
  uploadreceipts: 'repairreceipt/upload',
  viewreceipts: 'repairreceipt/view',
  reschedulePayment: "reschedule/entry",
  reschedulePaymentConformation: "reschedule/confirmation",
  selectaccount: "selectaccount",
  accountdetail: "accountdetail",
  statementyear: "myaccount/statements/year",
  easypay: "easypay",
  easypayview: "easypay/view",
  easypaypause: "easypay/pause",
  easypayresume: "easypay/resume",
  easypayenroll: "easypay/enroll",
  easypaynoteligible: "easypay/noteligible",
  easypaypaymentsource: "easypay/enroll/paymentsource",
  easypayviewpaymentsource: "easypay/view/paymentsource",
  easypaypaymentamount: "easypay/enroll/paymentamount",
  easypaystartdate: "easypay/enroll/startdate",
  easypayreview: "easypay/enroll/review",
  easypayconfirmation: "easypay/enroll/confirmation",
  easypayviewpaymentamount: "easypay/view/paymentamount",
  easypayunenroll: "easypay/unenroll",
  easypaypaymentamountoptions: "easypay/paymentamountoptions",
  offers: "offers",
  amortization: "amortization",
  mileagemanagement: 'mileagemanagement',
  mmagetstarted: 'mileagemanagement/getstarted',
  mmacurrentmileage: 'mileagemanagement/currentmileage',
  mmapayment: 'mileagemanagement/mmapayment',
  mmaAuthorization: 'mileagemanagement/mmapayment/authorization',
  mmasavedquote: 'mileagemanagement/savedquote',
  mmaselectmiles: 'mileagemanagement/selectmiles',
  mmareview: 'mileagemanagement/review',
  mmapaymentoption: 'mileagemanagement/paymentoption',
  mmaconfirmation: 'mileagemanagement/confirmation',
  profileVerifyToken: 'profileVerifyToken',
  navigation: 'navigation',
  v2: "/v2"
}

let nonAccountSpecificRoutes: string[] = ["/accounts",
  "/terms",
  "/terms/view",
  "/faq",
  "/contactus",
  "/forgotpassword",
  "/forgotpassword/confirmation",
  "/forgotpassword/error",
  "/forgotpassword/success",
  "/forgotpassword/change/:token",
  "/login",
  "/registration",
  "/registration/confirmation",
  "/registration/activation",
  "/registration/resend",
  "/legitimization",
  "/authentication",
  "/terms/accept",
  "/managesettings/changepassword",
  "/managesettings",
  "/serviceerror",
  "/error",
  "/errorPage",
  "/managesettings/changepassword/confirmation",
  "/securemessagecenter/inbox",
  "/securemessagecenter/view",
  "/securemessagecenter/compose",
  "/myProfile",
  "/securemessagecenter/clicktosign",
  "/documentpage/document",
  "/cookiepolicy",
  "/esign",
  "/esign/accept",
  "/scra",
  "/userinactivity",
  "/redirect",
  "/sso",
  "/ssoapp",
  "/pathNotFound",
  "/sso/logout",
  "/maintenance",
  "/paperless",
  "/onboarding",
  "/profileVerifyToken"];

export enum routes {
  baseUrl = <any>"baseUrl",
  home = <any>"home",
  vehicles = <any>"vehicles",
  accounts = <any>"accounts",
  termsView = <any>"termsView",
  faq = <any>"faq",
  contactus = <any>"contactus",
  forgotpassword = <any>"forgotpassword",
  forgotpasswordconfirmation = <any>"forgotpasswordconfirmation",
  forgotpassworderror = <any>"forgotpassworderror",
  forgotpasswordsuccess = <any>"forgotpasswordsuccess",
  forgotpasswordchange = <any>"forgotpasswordchange",
  login = <any>"login",
  registrationconfirmation = <any>"registrationconfirmation",
  registrationactivation = <any>"registrationactivation",
  registrationresend = <any>"registrationresend",
  legitimization = <any>"legitimization",
  twoFactorAuth = <any>"twoFactorAuth",
  accountlinks = <any>"accountlinks",
  acceptTerms = <any>"acceptTerms",
  esignAccept = <any>"esignAccept",
  changepassword = <any>"changepassword",
  managesettings = <any>"managesettings",
  myaccount = <any>"myaccount",
  estimate = <any>"estimate",
  accountdetails = <any>"accountdetails",
  paymentdetails = <any>"paymentdetails",
  payoffinformation = <any>"payoffinformation",
  statements = <any>"statements",
  notifications = <any>"notifications",
  leasematurityinvoice = <any>"leasematurityinvoice",
  serviceerror = <any>"serviceerror",
  error = <any>"error",
  changePasswordConfirmation = <any>"changePasswordConfirmation",
  registration = <any>"registration",
  paymentreview = <any>"paymentreview",
  paymententry = <any>"paymententry",
  paymentchangeemail = <any>"paymentchangeemail",
  paymentdate = <any>"paymentpaymentdate",
  duedateentry = <any>"duedateentry",
  duedatereview = <any>"duedatereview",
  duedateconfirmation = <any>"duedateconfirmation",
  duedateinformational = <any>"duedateinformational",
  mappayment = <any>"mappayment",
  mapreview = <any>"mapreview",
  mapconfirmation = <any>"mapconfirmation",
  mapagreement = <any>"mapagreement",
  leasepayment = <any>"leasepayment",
  leasereview = <any>"leasereview",
  leaseagreement = <any>"leaseagreement",
  leaseduration = <any>"leaseduration",
  leaseconfirmation = <any>"leaseconfirmation",
  paymentSource = <any>"paymentSource",
  paymentAmount = <any>"paymentAmount",
  addAchPaymentSource = <any>"addAchPaymentSource",
  addAchPaymentConfirmation = <any>"addAchPaymentConfirmation",
  addPaymentCard = <any>"addPaymentCard",
  paymentconfirmation = <any>"paymentconfirmation",
  recurringpaymentreview = <any>"recurringpaymentreview",
  recurringpaymentconfirmation = <any>"recurringpaymentconfirmation",
  recurringpaymententry = <any>"recurringpaymententry",
  viewPaymentSource = <any>"viewPaymentSource",
  addAchPaymtSource = <any>"addAchPaymtSource",
  addPaymentSource = <any>"addPaymentSource",
  addCard = <any>"addCard",
  addEmailAddress = <any>"addEmailAddress",
  smcinbox = <any>"smcinbox",
  smcview = <any>"smcview",
  smccompose = <any>"smccompose",
  myProfile = <any>"myProfile",
  maturityBilling = <any>"maturityBilling",
  maturity = <any>"maturity",
  clickToSign = <any>"clickToSign",
  clickToSignImg = <any>"clickToSignImg",
  scra = <any>"scra",
  userInactivity = <any>"userInactivity",
  cookiePolicy = <any>"cookiePolicy",
  sso = <any>"sso",
  ssoapp = <any>"ssoapp",
  redirect = <any>"redirect",
  pathNotFound = <any>"pathNotFound",
  paperless = <any>"paperless",
  onboarding = <any>"onboarding",
  paymentdeferralinfo = <any>"paymentdeferralinfo",
  paymentdeferralentry = <any>"paymentdeferralentry",
  paymentdeferralconfirmation = <any>"paymentdeferralconfirmation",
  paymentdeferralrequest = <any>"paymentdeferralrequest",
  uploadreceipts = <any>"uploadreceipts",
  viewreceipts = <any>"viewreceipts",
  reschedulePayment = <any>"reschedulePayment",
  reschedulePaymentConfirmation = <any>"reschedulePaymentConformation",
  selectaccount = <any>"selectaccount",
  accountdetail = <any>"accountdetail",
  nocode = <any>"nocode",
  method = <any>"method",
  entercode = <any>"entercode",
  rememberme = <any>"rememberme",
  payoff = <any>"payoff",
  easypay = <any>"easypay",
  easypayview = <any>"easypayview",
  easypaypause = <any>"easypaypause",
  easypayresume = <any>"easypayresume",
  easypayenroll = <any>"easypayenroll",
  easypaynoteligible = <any>"easypaynoteligible",
  easypaypaymentsource = <any>"easypaypaymentsource",
  easypayreview = <any>"easypayreview",
  easypaystartdate = <any>"easypaystartdate",
  easypayconfirmation = <any>"easypayconfirmation",
  easypayunenroll = <any>"easypayunenroll",
  easypaypaymentamountoptions = <any>"easypaypaymentamountoptions",
  easypayviewpaymentsource = <any>"easypayviewpaymentsource",
  statementyear = <any>"statementyear",
  offers = <any>"offers",
  amortization = <any>"amortization",
  easypaypaymentamount = <any>'easypaypaymentamount',
  easypayviewpaymentamount = <any>"easypayviewpaymentamount",
  mileageManagement = <any>'mileagemanagement',
  mmagetstarted = <any>'mmagetstarted',
  mmacurrentmileage = <any>'mmacurrentmileage',
  mmapayment = <any>'mmapayment',
  mmasavedquote = <any>"mmasavedquote",
  mmaselectmiles = <any>'mmaselectmiles',
  mmareview = <any>'mmareview',
  mmapaymentoption = <any>'mmapaymentoption',
  mmaconfirmation = <any>'mmaconfirmation',
  profileVerifyToken = <any>'profileVerifyToken',
  paymentAuthorization = <any>'paymentAuthorization',
  mmaAuthorization = <any>'mmaAuthorization',
  navigation = <any>'navigation'
}

export enum routesV2 {
  maturityBilling = <any>'maturityBilling',
  endofterm = <any>'endofterm',
  vehiclereturnestimate = <any>'vehiclereturnestimate',
  payoff = <any>'payoff',
  makeapayment = <any>'makeapayment',
  statements = <any>'statements',
  easypay = <any>"easypay",
  paymentDetails = <any>'paymentdetails',
  duedateChange = <any>'duedatechange',
  leaseextension = <any>'leaseextension',
  paymentoverview = <any>'paymentoverview',
  mileageadjustment = <any>'mileageadjustment',

}

//page identifiers used in deep links
export const DeepLinkPageIds = {
  "as": "statements",
  "ep": "recurringpaymententry",
  "up": "paymententry",
  "ap": "notifications",
  "mc": "smcinbox",
  "mp": "maturityBilling",
  "po": "payoffinformation",
  "pd": "paymentdetails",
  "fq": "faq",
  "ad": "accountdetails",
  "cu": "contactus",
  "pf": "myProfile",
  "pp": "paymentdeferralinfo",
  "pt": "profileVerifyToken",
  "v2": "navigation",
  "so": "offers",
  "mi": "leasematurityinvoice"
}

//page identifiers used in deep links for mobile app
export const MobileDeepLinkPageIds = {
  "as": "statements",
  "up": "paymententry",
  "ad": "accountdetail",
  "po": "payoff"
}

export const DeepLinkPageIdsV2 = {
  "as": "statements",
  "ep": "easypay",
  "et": "endofterm",
  "mp": "maturityBilling",
  "pd": "paymentDetails",
  "po": "payoff",
  "up": "makeapayment",
}

@Injectable()
export class RouterService {
  router: Router;
  activatedRoute: ActivatedRoute;
  constructor(
    private injector: Injector,
    public store: Store<IAppState>,
    private urlBuilderService: UrlBuilderService,
    private _location: Location,
  ) { }

  public navigateTo(page: routes, parameters?: object, fragmentValue?: string): void {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    if (parameters) {
      if (fragmentValue) {
        this.router.navigate([route[page]], { queryParams: parameters, fragment: fragmentValue });
      }
      else {
        this.router.navigate([route[page]], { queryParams: parameters });
      }
    }
    else {
      if (fragmentValue) {
        this.router.navigate([route[page]], { fragment: fragmentValue });
      }
      else {
        this.router.navigate([route[page]]);
      }
    }
  }

  public navigateToV2(page: routesV2, parameters?: object, fragmentValue?: string): void {
    let url = `${route.v2}${routeV2[page]}`;

    if (parameters) {
      url += '?';
      url += Object.keys(parameters).map((key) => `${key}=${parameters[key]}`).join('&');
    }
    if (fragmentValue) {
      url += '#';
      url += fragmentValue;
    }

    this.navigateToExternalUrl(url);

  }

  public navigateToPreviousRoute() {
    if (this.activatedRoute == undefined) { this.activatedRoute = this.injector.get(ActivatedRoute); }
    const previousUrlParam = this.activatedRoute.snapshot.queryParams;
    if (previousUrlParam && previousUrlParam[Constants.ReturnUrl]) {
      this.navigateTo(previousUrlParam[Constants.ReturnUrl]);
    }
    else {
      this.navigateToHome();
    }
  }

  public navigateToHome(fragmentValue?: string) {
    if (fragmentValue) {
      this.navigateTo(routes.home, null, fragmentValue);
    }
    else {
      this.navigateTo(routes.home);
    }
  }

  public navigateToPathNotFound() {
    this.navigateTo(routes.pathNotFound);
  }

  public navigateToVehicles() {
    this.navigateToAccounts();
  }
  public navigateToAccounts() {
    this.navigateTo(routes.accounts);
  }
  public navigateToOffers(account?: string) {
    this.navigateTo(routes.offers, { account });
  }

  public navigateToLogin() {
    let disabled_Routes: string[];
    this.store.select(state => state.ApplicationConfig.DISABLED_ROUTES).subscribe(x => disabled_Routes = x);
    if (disabled_Routes && disabled_Routes.length > 0 && disabled_Routes[0].includes("/sso")) {
      this.navigateTo(routes.login);
    }
    else {
      this.urlBuilderService.getIdToken()
        .then((token: string) => {
          let authHubExternalUrl = this.urlBuilderService.getAuthProviderLoginRedirectUrl(routes.home.toString(), token);
          window.location.href = authHubExternalUrl;
        })
        .catch(() => {
          this.navigateToServiceError(routes.home);
        });
    }
  }

  public navigateToLogout() {
    let disabled_Routes: string[];
    this.store.select(state => state.ApplicationConfig.DISABLED_ROUTES).subscribe(x => disabled_Routes = x);
    if (disabled_Routes && disabled_Routes.length > 0 && disabled_Routes[0].includes("/sso")) {
      this.navigateTo(routes.login);
    }
    else {
      if (this.activatedRoute == undefined) { this.activatedRoute = this.injector.get(ActivatedRoute); }
      let language = this.activatedRoute.snapshot.queryParams['language'];
      let authHubExternalUrl = this.urlBuilderService.getAuthProviderLogoutRedirectUrl(language);
      window.location.href = authHubExternalUrl;
    }
  }

  public navigateToBaseUrl() {
    this.navigateTo(routes.baseUrl);
  }

  public navigateBack() {
    this._location.back();
  }

  public navigateToTermsAccept(lang?: string): void {
    if (lang)
      this.navigateTo(routes.acceptTerms, { language: lang });
    else
      this.navigateTo(routes.acceptTerms);
  }

  public navigateToTermsView() {
    this.navigateTo(routes.termsView);
  }

  public getTermsView(): any {
    let termsLink;
    return termsLink = route.termsView;
  }

  public navigateToFAQ() {
    this.navigateTo(routes.faq);
  }


  public getFAQLink() {
    return route.faqLink;
  }

  public navigateToSCRA() {
    this.navigateTo(routes.scra);
  }

  public navigateToContactUs() {
    this.navigateTo(routes.contactus);
  }

  public navigateToAcceptTermsAndCondition() {
    this.navigateTo(routes.acceptTerms);
  }

  public navigateToEsign() {
    this.navigateTo(routes.esignAccept);
  }

  public navigateToRegistration() {
    this.navigateTo(routes.registration);
  }

  public navigateToRegistrationConfirmation() {
    this.navigateTo(routes.registrationconfirmation);
  }

  public navigateToRegistrationActivation() {
    this.navigateTo(routes.registrationactivation);
  }

  public navigateToRegistrationResendToken() {
    this.navigateTo(routes.registrationresend);
  }

  public navigateToLegitimization() {
    this.navigateTo(routes.legitimization);
  }

  public navigateToForgotPassword() {
    this.navigateTo(routes.forgotpassword);
  }

  public navigateToForgotPasswordConfirmation() {
    this.navigateTo(routes.forgotpasswordconfirmation);
  }

  public navigateToForgotPasswordError() {
    this.navigateTo(routes.forgotpassworderror);
  }

  public navigateToForgotPasswordSuccess() {
    this.navigateTo(routes.forgotpasswordsuccess);
  }

  public navigateToAuthentication() {
    this.navigateTo(routes.twoFactorAuth);
  }

  public navigateToAccountLinks() {
    this.navigateTo(routes.accountlinks);
  }

  public navigateManageSettingsChangePassword() {
    this.navigateTo(routes.changepassword);
  }
  public navigateToEasyPayNotEligible() {
    this.navigateTo(routes.easypaynoteligible);
  }
  public navigateToManageSettings() {
    this.navigateTo(routes.managesettings);
  }

  public navigateToPaymentReview() {
    this.navigateTo(routes.paymentreview);
  }

  public navigateToPaymentEntry(newsourcetype?: NewSourceType) {
    let params = newsourcetype ? { newsourcetype: newsourcetype.toString() } : null;
    let v2MakeAPayment: boolean;
    let contractDetail: ContractAccountDetailDTO;

    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.store.select(state => state.V2MakePaymentEnabled.booleanValue).subscribe(enabled => { v2MakeAPayment = enabled });

    if (v2MakeAPayment) {
      this.navigateToV2(routesV2.makeapayment, { vin: contractDetail.vIN });
    }
    else {
      this.navigateTo(routes.paymententry, params);
    }
  }

  public navigateToPaymentChangeEmail() {
    this.navigateTo(routes.paymentchangeemail);
  }

  public navigateToPaymentDate() {
    this.navigateTo(routes.paymentdate);
  }

  public navigateToDueDateEntry() {
    this.navigateTo(routes.duedateentry);
  }
  public navigateToDueDateInformational() {
    this.navigateTo(routes.duedateinformational);
  }

  public navigateToDueDateReview() {
    this.navigateTo(routes.duedatereview);
  }

  public navigateToDueDateConfirmation() {
    this.navigateTo(routes.duedateconfirmation);
  }

  public navigateToMapReview(fragment?: string) {
    if (fragment) {
      this.navigateTo(routes.mapreview, null, fragment);
    }
    else {
      this.navigateTo(routes.mapreview);
    }
  }

  public navigateToMapConfirmation() {
    this.navigateTo(routes.mapconfirmation);
  }

  public navigateToMapAgreement() {
    this.navigateTo(routes.mapagreement);
  }

  public navigateToMapPayment(fragment?: string) {
    if (fragment) {
      this.navigateTo(routes.mappayment, null, fragment);
    }
    else {
      this.navigateTo(routes.mappayment);
    }
  }

  public navigateToPaymentConfirmation() {
    this.navigateTo(routes.paymentconfirmation);
  }

  public navigateToRecurringPaymentEntry() {
    let v2EasyPayEnabled: boolean;
    let contractDetail: ContractAccountDetailDTO;

    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.store.select(state => state.V2EasyPay.booleanValue).subscribe(enabled => { v2EasyPayEnabled = enabled });

    if (v2EasyPayEnabled) {
      this.navigateToV2(routesV2.easypay, { vin: contractDetail.vIN });
    }
    else {
      this.navigateTo(routes.recurringpaymententry, null);
    }
  }

  public navigateToRecurringPaymentReview() {
    this.navigateTo(routes.recurringpaymentreview);
  }

  public navigateToRecurringPaymentConfirmation() {
    this.navigateTo(routes.recurringpaymentconfirmation);
  }

  public navigateToServiceError(currentRoute?: any) {
    if (currentRoute)
      this.navigateTo(routes.serviceerror, { returnurl: currentRoute });
    else
      this.navigateTo(routes.serviceerror);
  }

  public navigateToErrorPage() {
    this.navigateTo(routes.error);
  }

  public navigateToChangePasswordConfirmation() {
    this.navigateTo(routes.changePasswordConfirmation);
  }

  public navigateToAccountDetails(fragment?: string) {
    if (fragment) {
      this.navigateTo(routes.accountdetails, null, fragment);
    }
    else {
      this.navigateTo(routes.accountdetails);

    }
  }

  public navigateToMidterMileageV2(fragment?: string) {
    let v2MidtermMileageEnabled: boolean;
    let contractDetail: ContractAccountDetailDTO;

    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.store.select(state => state.V2MidtermMileage.booleanValue).subscribe(enabled => { v2MidtermMileageEnabled = enabled });

    if (v2MidtermMileageEnabled) {
      this.navigateToV2(routesV2.mileageadjustment, { vin: contractDetail.vIN });
    }
    else {
      this.navigateToAccountDetails(fragment);
    }

  }

  public navigateToAccountDetailsWithVin(vin: string) {
    if (vin) {
      this.navigateTo(routes.accountdetails, { vin: vin });
    }
  }

  public navigateToPaymentDetails(fragment?: string) {
    let v2PaymentDetailsEnabled: boolean;
    let contractDetail: ContractAccountDetailDTO;

    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.store.select(state => state.V2PaymentDetails.booleanValue).subscribe(enabled => { v2PaymentDetailsEnabled = enabled });

    if (v2PaymentDetailsEnabled) {
      this.navigateToV2(routesV2.paymentDetails, { vin: contractDetail.vIN }, fragment);
    }
    else {
      this.navigateTo(routes.paymentdetails, null, fragment);
    }

  }

  public navigateToPayoffInformation() {
    this.navigateTo(routes.payoffinformation);
  }

  public navigateToLeaseMaturityInvoice() {
    this.navigateTo(routes.leasematurityinvoice);
  }

  public navigateToStatements() {
    this.navigateTo(routes.statements);
  }

  public navigateToV2Statements() {
    let v2StatementsEnabled: boolean;
    let contractDetail: ContractAccountDetailDTO;

    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.store.select(state => state.V2Statements.booleanValue).subscribe(enabled => { v2StatementsEnabled = enabled });

    if (v2StatementsEnabled) {
      this.navigateToV2(routesV2.statements, { vin: contractDetail.vIN });
    }
    else {
      this.navigateTo(routes.statements);
    }
  }

  public navigateToNotifications(fragment?: string) {
    if (fragment) {
      this.navigateTo(routes.notifications, null, fragment);
    }
    else {
      this.navigateTo(routes.notifications);
    }
  }

  public navigateToAddEmailAddress() {
    this.navigateTo(routes.addEmailAddress);
  }

  public navigateToPaymentSourcesView(newsourcetype?: NewSourceType) {
    let params = newsourcetype ? { newsourcetype: newsourcetype.toString() } : null;
    this.navigateTo(routes.paymentSource, params);
  }

  public navigateToAddAchPaymtSource(currentRoute?: any) {
    if (currentRoute)
      this.navigateTo(routes.addAchPaymtSource, { returnurl: currentRoute });
    else
      this.navigateTo(routes.addAchPaymtSource);
  }

  public navigateToAddPaymentSource() {
    this.navigateTo(routes.addPaymentSource);
  }

  public navigateToAddCard(cardType: NewSourceType) {
    this.navigateByUrl(route.addCard, { cardtype: cardType });
  }

  public navigateToPaymentSources() {
    this.navigateTo(routes.viewPaymentSource);
  }

  public navigateToAddACHPaymentSource(parameters: any) {
    this.navigateTo(routes.addAchPaymentSource, parameters);
  }

  public navigateToAddACHConfirmation() {
    this.navigateTo(routes.addAchPaymentConfirmation);
  }

  public navigateToAddPaymentCard() {
    this.navigateTo(routes.addPaymentCard);
  }

  public navigateToSecureMessageInbox() {
    this.navigateTo(routes.smcinbox);
  }

  public navigateToReadMessage() {
    this.navigateTo(routes.smcview);
  }

  public navigateToComposeMesssage() {
    this.navigateTo(routes.smccompose);
  }

  public navigateToComposeMesssageInPaymentDeferralMode(vin?: string) {
    this.navigateTo(routes.smccompose, { mode: Constants.PaymentDeferralParameter1Value, vin: vin });
  }

  public navigateToMyProfile(fragment?: string) {
    if (fragment) {
      this.navigateTo(routes.myProfile, null, fragment);
    }
    else {
      this.navigateTo(routes.myProfile);
    }
  }

  public navigateToCookiePolicy() {
    this.navigateTo(routes.cookiePolicy);
  }

  public navigateToOnboarding() {
    this.navigateTo(routes.onboarding);
  }

  public navigateToUserInactivity() {
    this.navigateTo(routes.userInactivity);
  }

  public navigateToClickToSign(paymentDeferralId: string, vin: string) {
    this.router.navigate([route[routes.clickToSign]], { queryParams: { paymentDeferralId: paymentDeferralId, vin: vin } });
  }

  public navigateToSmcComposeScra(parameters?: object): void {
    if (parameters) {
      this.navigateTo(routes.smccompose, parameters);
    }
    else {
      this.navigateTo(routes.smccompose);
    }
  }

  public navigateToPaperlessStatement(parameters?: object) {
    this.navigateTo(routes.paperless, parameters);
  }

  public navigateToPaymentDeferralInfo() {
    this.navigateTo(routes.paymentdeferralinfo);
  }

  public navigateToPaymentDeferralEntry() {
    this.navigateTo(routes.paymentdeferralentry);
  }

  public navigateToPaymentDeferralConfirmation() {
    this.navigateTo(routes.paymentdeferralconfirmation);
  }

  public navigateToPaymentDeferralRequest() {
    this.navigateTo(routes.paymentdeferralrequest);
  }

  public navigateToAmortization() {
    this.navigateTo(routes.amortization);
  }

  public navigateToAccountDetail(vin?: string) {
    if (vin) {
      this.navigateTo(routes.accountdetail, { vin: vin });
    }
    else {
      this.navigateTo(routes.accountdetail)
    }
  }

  public navigateForRefresh(vin: string) {
    var newUrl = `${window.location.origin}${window.location.pathname}?vin=${vin}`;
    window.location.href = newUrl;
    return false;
  }
  public navigateToPayOff() {
    this.navigateTo(routes.payoff)
  }
  public navigateToSelectAccount() {
    this.navigateTo(routes.home)
  }

  public navigateToTarget(page?: string, paramsObject?: object): void {
    //if target page is requested, nav to the target with deeplink in querystring (used by guards), else nav to deeplink (used by components)
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    let deepLinkParam: DeepLinkParam;
    this.store.select(state => state.DeepLinkParam).subscribe(x => deepLinkParam = x);
    if (deepLinkParam && deepLinkParam.pageId) {
      if (!deepLinkParam.vin || deepLinkParam.vin.length < Constants.Vin7Length) {
        if (page) {
          this.router.navigate([page], { queryParams: { returnUrl: this.getDeepLinkQS(route[deepLinkParam.pageId]) } });
        }
        else {
          this.router.navigate([route[deepLinkParam.pageId]]);
        }
      }
      else {
        if (page) {
          let returnUrl = this.getDeepLinkQS(route[deepLinkParam.pageId], deepLinkParam.vin, deepLinkParam.anchor);
          this.router.navigate([page], { queryParams: { returnUrl: returnUrl } });
        }
        else {
          this.router.navigate([route[deepLinkParam.pageId]], { queryParams: { vin: deepLinkParam.vin }, fragment: deepLinkParam.anchor });
        }
      }
    }
    else {
      if (page && page !== Constants.EMPTY && page != "undefined") {
        if (page.indexOf('/') !== -1) {
          this.router.navigateByUrl(page);
        }
        else {
          if (paramsObject) {
            this.router.navigate([page], { queryParams: paramsObject });
          }
          else {
            this.router.navigate([page]);
          }

        }
      }
      else {
        this.navigateToHome();
      }
    }
  }

  public navigateByUrl(url: string, data?: any) {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    const state = data ? { state: data } : null;
    this.router.navigateByUrl(url, state);
  }

  public navigateByUrlWithReload(url: string) {
    this.navigateByUrl(url, { onSameUrlNavigation: 'reload' });
  }

  public navigateBackToV2() {
    let deepLinkParam: DeepLinkParam;
    this.store.select(state => state.DeepLinkParam).subscribe(x => deepLinkParam = x);
    console.log(deepLinkParam);
    if (deepLinkParam && deepLinkParam.source) {
      let targetUrl = deepLinkParam.vin ? `${route.v2}${deepLinkParam.source}?vin=${deepLinkParam.vin}` :
        `${route.v2}${deepLinkParam.source}`;
      this.navigateToExternalUrl(targetUrl);
    } else {
      this.navigateToHome();
    }
  }

  public navigateToExternalUrl(url: string) {
    window.location.href = url;
  }
  public navigateToExternalUrlInNewTab(url: string) {
    window.open(url, '_blank');
  }

  public navigateToMyAccount() {
    let myAccountUrl: string;
    this.store.select(state => state.EnvironmentConfig.NGD_URL).subscribe(x => myAccountUrl = x);
    if (myAccountUrl) {
      this.navigateToExternalUrl(myAccountUrl);
    }
  }

  public navigateToMileageManagement() {
    this.navigateTo(routes.mileageManagement);
  }

  private getDeepLinkQS(route: string, vin?: string, anchor: string = null) {
    return (!vin || vin && vin.length < Constants.Vin7Length)
      ? route
      : anchor !== null
        ? `${route}?vin=${vin}#${anchor}`
        : `${route}?vin=${vin}`;
  }

  public getRouteData(): any {
    if (this.activatedRoute == undefined) { this.activatedRoute = this.injector.get(ActivatedRoute); }
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    let routeData = this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }))
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
    return routeData;
  }

  public getUrl(): any {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    return this.router.url;
  }

  public getRouterEvents(): any {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    return this.router.events;
  }

  public getRouterSnapshotUrl(): any {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    return this.router.routerState.snapshot.url;
  }

  public getContactUsLink(): string {
    let ngdUrl: string;
    this.store.select(state => state.EnvironmentConfig.NGD_URL).subscribe(x => ngdUrl = x);
    return ngdUrl + route.contactusLink;
  }

  public getTermsViewLink(): string {
    let ngdUrl: string;
    this.store.select(state => state.EnvironmentConfig.NGD_URL).subscribe(x => ngdUrl = x);
    return ngdUrl + route.termsViewLink;
  }

  public getAddCardURL(): string {
    return route.addPaymentDebit;
  }

  public navigateToVehicleReturnEstimate(parameters: any): void {
    let v2BooleanValue: boolean;
    this.store.select(state => state.V2Enabled.booleanValue).subscribe(x => v2BooleanValue = x);
    if (v2BooleanValue) {
      this.navigateToV2(routesV2.vehiclereturnestimate, parameters);
    } else {
      this.navigateTo(routes.estimate, parameters);
    }
  }

  public navigateToV2PayOff(): void {
    let contractDetail: ContractAccountDetailDTO;
    let V2PayOffEnabled: boolean;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.store.select(state => state.V2PayoffEnabled.booleanValue).subscribe(enabled => { V2PayOffEnabled = enabled });
    if (V2PayOffEnabled) {
      this.navigateToV2(routesV2.payoff, { vin: contractDetail.vIN });
    } else {
      this.navigateToPayoffInformation();
    }
  }

  public navigateToMaturityBillingLeaseEnd(parameters: any): void {
    let v2BooleanValue: boolean;
    this.store.select(state => state.V2Enabled.booleanValue).subscribe(x => v2BooleanValue = x);
    if (v2BooleanValue) {
      this.navigateToV2(routesV2.maturityBilling, parameters);
    } else {
      this.navigateTo(routes.maturityBilling, parameters);
    }
  }

  public navigateToLeasePaymentEntry(): void {
    let v2LeaseExtensionEnabled: boolean;
    let contractDetail: ContractAccountDetailDTO;

    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    this.store.select(state => state.V2LeaseExtension.booleanValue).subscribe(enabled => {
      v2LeaseExtensionEnabled = enabled
    });

    if (v2LeaseExtensionEnabled) {
      this.navigateToV2(routesV2.leaseextension, { vin: contractDetail.vIN });
    }
    else {
      this.navigateTo(routes.leasepayment);
    }
  }

  public navigateToLeasePaymentDuration(): void {
    this.navigateTo(routes.leaseduration);
  }

  public navigateToLeasePaymentReview(): void {
    this.navigateTo(routes.leasereview);
  }

  public navigateToLeaseReviewAgreement(): void {
    this.navigateTo(routes.leaseagreement);
  }

  public navigateToLeasePaymentConfirmation(): void {
    this.navigateTo(routes.leaseconfirmation);
  }

  public navigateToRepairReceiptUpload(): void {
    this.navigateTo(routes.uploadreceipts);
  }

  public navigateToViewRepairReceipts(parameters: any, fragment?: string): void {
    this.navigateTo(routes.viewreceipts, parameters, fragment);
  }

  public getNonAccountSpecificRoutes(): string[] {
    return nonAccountSpecificRoutes;
  }

  public navigateToReschedulePayment(): void {
    this.navigateTo(routes.reschedulePayment);
  }

  public navigateToReschedulePaymentConfirmation() {
    this.navigateTo(routes.reschedulePaymentConfirmation);
  }

  public navigateToMfaNoCode() {
    this.navigateTo(routes.nocode);
  }

  public navigateToMfaMethod() {
    this.navigateTo(routes.method);
  }

  public navigateToMfaEnterCode() {
    this.navigateTo(routes.entercode);
  }

  public navigateToMfaRememberMe() {
    this.navigateTo(routes.rememberme);
  }

  public navigateToStatementYear(parameters: any, fragment?: string) {
    this.navigateTo(routes.statementyear, parameters, fragment);
  }

  public navigateToEasyPayView() {
    this.navigateTo(routes.easypayview);
  }

  public navigateToEasyPayEnroll() {
    this.navigateTo(routes.easypayenroll);
  }

  public navigateToEasyPayReview() {
    this.navigateTo(routes.easypayreview);
  }

  public navigateToEasyPayEnrollPaymentSource() {
    this.navigateTo(routes.easypaypaymentsource);
  }

  public navigateToEasyPayPaymentAmount() {
    this.navigateTo(routes.easypaypaymentamount);
  }

  public navigateToEasyPayStartDate() {
    this.navigateTo(routes.easypaystartdate);
  }

  public navigateToEasyPayConfirmation() {
    this.navigateTo(routes.easypayconfirmation);
  }

  public navigateToEasyPayPause() {
    this.navigateTo(routes.easypaypause);
  }

  public navigateToEasyPayResume() {
    this.navigateTo(routes.easypayresume);
  }

  public navigateToEasyPayUnEnroll() {
    this.navigateTo(routes.easypayunenroll);
  }

  public navigateToEasyPayPaymentAmountOptions(currentRoute: any) {
    this.navigateTo(routes.easypaypaymentamountoptions, { returnurl: currentRoute });
  }

  public navigateToEasyPayViewPaymentSource() {
    this.navigateTo(routes.easypayviewpaymentsource);
  }

  public navigateToEasyPayViewPaymentAmount() {
    this.navigateTo(routes.easypayviewpaymentamount);
  }

  public navigateToMmaGetStarted() {
    this.navigateTo(routes.mmagetstarted);
  }

  public navigateToMmaCurrentMileage() {
    this.navigateTo(routes.mmacurrentmileage);
  }

  public navigateToMmaPayment(quote?: any, newsourcetype?: NewSourceType) {
    if (quote)
      this.navigateByUrl(route.mmapayment, quote);
    else {
      let params = newsourcetype ? { newsourcetype: newsourcetype.toString() } : null;
      this.navigateTo(routes.mmapayment, params);
    }
  }

  public navigateToMmaSavedQuote(quote?: any) {
    this.navigateByUrl(route.mmasavedquote, quote);
  }

  public navigateToMmaSelectMiles() {
    this.navigateTo(routes.mmaselectmiles);
  }

  public navigateToMmaReview(quote?: any) {
    this.navigateByUrl(route.mmareview, quote);
  }
  public navigateToMmaPaymentOption() {
    this.navigateTo(routes.mmapaymentoption);
  }
  public navigateToMmaConfirmation() {
    this.navigateTo(routes.mmaconfirmation);
  }

  public navigateToNewTabWithParam(url: any, queryParam: any) {
    let deeplink = url.concat(Constants.UrlParametersPrefix, queryParam);
    window.open(deeplink);
  }
  public navigateToPaymentAuthorization(currentRoute: any, destinationRoute: any) {
    this.navigateTo(destinationRoute, { returnurl: currentRoute });
  }

  public navigateToEndOfTerm(parameters: any): void {
    this.navigateToV2(routesV2.endofterm, parameters);
  }

  public navigateToDueDateChange(parameters?: any): void {
    this.navigateToV2(routesV2.duedateChange, parameters);
  }

  public navigateToPaymentOverview(parameters: any): void {
    this.navigateToV2(routesV2.paymentoverview, parameters);
  }

  public navigateToPaymentDetailsV2(parameters: any): void {
    this.navigateToV2(routesV2.paymentDetails, parameters);
  }

}
