import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


export class AdobeAnalyticsActions
{
  static SET_ADOBE_ANALYTICS_ENABLED: string = 'SET_ADOBE_ANALYTICS_ENABLED';

   

  setAdobeAnalyticsEnabled(enabled: boolean): FSA<string, boolean, null>{
        return {
          type: AdobeAnalyticsActions.SET_ADOBE_ANALYTICS_ENABLED,
          payload: enabled,
            meta: null
        };
    }

}

@Injectable()
export class AdobeAnalyticsReducers
{
  static AdobeAnalyticsEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
          case AdobeAnalyticsActions.SET_ADOBE_ANALYTICS_ENABLED:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
