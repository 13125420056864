<div class="extend-lease-review dialog">
  <div class="extend-lease-review-container">
      <h1>{{'ngw.lease-extension.duration-review-dialog.title' | translate}}</h1>
      
      <div class="form-group">
        <p>{{'ngw.lease-extension.duration-review-dialog.new-contract-date' | translate}}  {{newContractDate | date: dateFormat}}</p>
      </div>

      <div class="form-group">
        <p>{{'ngw.lease-extension.duration-review-dialog.current-odometer' | translate}}  {{leaseExtReviewDetails.currentOdometer | number}}</p>
      </div>      

      <div class="action-buttons">
          <button id="btnCancel" type="button" class="btn btn-secondary"  (click)="closeDialog()" >
          {{'ngw.lease-extension.duration-review-dialog.btn-cancel' | translate}}</button>
          <button id="btnContinue" type="button" class="btn btn-primary"  (click)="confirmAction()" >
            {{'ngw.lease-extension.duration-review-dialog.btn-continue' | translate}}</button>
      </div>
  </div>
</div>