<div class="extend-lease-contract-dialog-container">
  <h1>{{"ngw.shared.extend-lease-contract-dialog.title" | translate}}</h1>

  <div id="Eligible" *ngIf="isEligibleToExtend(); else outStanding_Balance">
    <p>{{elgibilityDescription}} <a *ngIf="showContactUs" (click)="navigateToContactUs()">{{'ngw.shared.extend-lease-contract-dialog.contact-us-dot' | translate}}</a></p>
  </div>

  <ng-template #outStanding_Balance>
    <div id="outStanding_Balance_OR_Valid_Future_Matrity_Date" *ngIf="anyOutStandingBalanceOrValidFurtureMaturityDate(); else checkOtherEligibility">
      <p>{{elgibilityDescription}} <a (click)="navigateToContactUs()">{{'ngw.shared.extend-lease-contract-dialog.contact-us' | translate}}</a>.</p>

      <ul>
        <li *ngFor="let leaseExtensionEligbility of leaseExtensionEligibilities; let i = index">
          
          <p *ngIf="(i==0&&leaseExtensionEligbility.showMakeAPayment) || i ==1">
            <i *ngIf="!leaseExtensionEligbility.isEligible" mat-list-icon class="fa fa-times-circle"></i>
            <span>
              {{leaseExtensionEligbility.description | translate}}
              <a *ngIf="leaseExtensionEligbility.showMakeAPayment" (click)="navigateToMakeAPayment()">{{'ngw.general.make-a-payment' | translate}}.</a>
            </span>
          </p>
        </li>
      </ul>
    </div>
  </ng-template>  

  <ng-template #checkOtherEligibility>
    <div id="PastMaturity_Date" *ngIf="!isEligibileForOther">
      <p>{{'ngw.shared.extend-lease-contract-dialog.not-eligible-description-3' | translate}}<a (click)="navigateToContactUs()">{{'ngw.shared.extend-lease-contract-dialog.contact-us' | translate}}</a>.</p>
    </div>
  </ng-template>

  <div class="action-buttons">
    <button type="button" class="btn btn-secondary" id="cancelleaseExtendBtn" (click)="closeDialogOnly()" analytics-event="body.cancel-extend-lease-contract.cancel-extend-lease-contract.button">
      {{"ngw.shared.extend-lease-contract-dialog.btn-cancel" | translate}}</button>
    <button type="submit" class="btn btn-primary" id="continueleaseExtendBtn" [disabled]="disableButton" *ngIf="showButton" (click)="navigateToLeasePayment()">{{"ngw.shared.extend-lease-contract-dialog.btn-continue" | translate}}</button>
  </div>
</div>
