import { Component, OnInit, OnDestroy, Renderer2, Inject, Input, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { IsInMAPFlowActions } from '../../../../../../shared/store/reducers/is-in-map-flow.reducer';
import { RouterService, routes } from "../../../../../../shared/_helper-services/router.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MileageAdjustmentEntryDialog } from "../../mileage-adjustment-entry-dialog.component";
import { MileageEntryDialogData, MileageEntryDialogDataNavigationType } from '../../../../../../_models/map/mileage-entry-dialog-data.model';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss']
})


export class ErrorModalComponent extends BaseApplicationComponent<ErrorModalComponent> implements OnInit, OnDestroy {

    constructor(private translateService: TranslateService,
        private isInMAPFlowActions: IsInMAPFlowActions,
        private routerService: RouterService,        
        private dialogRef: MatDialogRef<MileageAdjustmentEntryDialog>,
        injector: Injector, @Inject(DOCUMENT) document) {
      super(injector, document);
    }

    ngOnInit() {

    }

    ngOnDestroy() {
    }

    public navigateToContactUs() {
      this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(false));
      let dialogData = new MileageEntryDialogData(true, MileageEntryDialogDataNavigationType.ContactUs);
      this.dialogRef.close(dialogData);
    }
   
}
