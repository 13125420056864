import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { MMAQuoteDTO } from '../../../core/gateway-api';

@Injectable()
export class MapQuotesActions
{
    static SET_QUOTES: string = 'SET_QUOTES';
    static SET_QUOTE: string = 'SET_QUOTE';
    static CLEAR_QUOTES: string = 'CLEAR_QUOTES';
    static CLEAR_QUOTE: string = 'CLEAR_QUOTE';

  setQuotes(contracts: MMAQuoteDTO[]): FSA<string, MMAQuoteDTO[], null>{
    return {
      type: MapQuotesActions.SET_QUOTES,
      payload: contracts,
      meta: null
    };
  }

  setQuote(quote: MMAQuoteDTO): FSA<string, MMAQuoteDTO, null>{
    return {
      type: MapQuotesActions.SET_QUOTE,
      payload: quote,
      meta: null
    };
  }

  clearQuotes(): FSA<string, MMAQuoteDTO[], null> {
      return {
          type: MapQuotesActions.CLEAR_QUOTES,
          payload: null,
          meta: null
      };
  }
  
  clearQuote(): FSA<string, MMAQuoteDTO, null> {
    return {
        type: MapQuotesActions.CLEAR_QUOTE,
        payload: null,
        meta: null
    };
  }

}

@Injectable()
export class MapQuotesReducers
{
    static MapQuotesReducer: Reducer<MMAQuoteDTO[]> = (state: MMAQuoteDTO[] = [], action: FSA<string, MMAQuoteDTO[], void>) => {
    switch(action.type){
        case MapQuotesActions.SET_QUOTES:
            return tassign(state, action.payload);
        case MapQuotesActions.CLEAR_QUOTES:
            return state = [];
        default:
          return state;
    }
  }
}

@Injectable()
export class MapQuoteReducers
{
    static MapQuoteReducer: Reducer<MMAQuoteDTO> = (state: MMAQuoteDTO = new MMAQuoteDTO(), action: FSA<string, MMAQuoteDTO, void>) => {
    switch(action.type){
        case MapQuotesActions.SET_QUOTE:
            return tassign(state, action.payload);
        case MapQuotesActions.CLEAR_QUOTE:
             return tassign(undefined) ;
       default:
          return state;
    }
  }
}