import { Injector } from "@angular/core";
import { IdTokenProvider } from "../../../_shared/_models/interface/id-token-provider.service";
import { BridgeHelperService } from "./bridge-helper.service";

export class IdTokenFromBridgeService implements IdTokenProvider {

  private bridgeHelperService: BridgeHelperService;

  constructor(
    injector: Injector,
  ) {
    this.bridgeHelperService = injector.get(BridgeHelperService);
  }

  public idToken(): Promise<string> {
    return this.bridgeHelperService.getIdToken()
      .then((promisedToken: string) => {
        return Promise.resolve(promisedToken);
      })
      .catch((error: unknown) => {
        return Promise.reject(error)
      });
  }
}