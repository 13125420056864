import { Component, OnInit, OnDestroy, Renderer2, Inject, Input, Injector, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { RouterService, routes } from "../../../../../../shared/_helper-services/router.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MileageAdjustmentEntryDialog } from "../../mileage-adjustment-entry-dialog.component";
import { PaymentInfo } from '../../../../../../_models/payment.model';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { MidtermMileageAdjustment } from '../../../../../../_models/map/midterm-mileage-adjustment.model';
import { PaymentViewService } from "../../../../../../_web-services/payment-view.service";
import { ObjectService } from "../../../../../../shared/_helper-services/object.service";
import { FaultCodes } from '../../../../../../shared/FaultCodes';
import { Constants } from '../../../../../../shared/constants';
import { MidtermMileageAdjustmentService } from '../../../../../../_web-services/midterm-mileage-adjustment.service';
import { CalculateMiles } from '../../../../../../_models/map/calculate-miles.model';
import { MapQuoteSavedActions } from '../../../../../../shared/store/reducers/map-quote-saved.reducer';
import { GetMidtermMileageAdjustmentResponse, CalculateMidtermMileageAdjustmentResponse } from '../../../../../../core/gateway-api';
import { MapPaymentReviewDetails } from '../../../../../../_models/map/map-payment-review-details.model';
import { MapPaymentReviewDetailsActions } from '../../../../../../shared/store/reducers/map-payment-review-details.reducer';
import { MidtermMileageAdjustmentActions } from '../../../../../../shared/store/reducers/midterm-mileage-adjustment.reducers';
import { MapPaymentOption } from '../../../../../../shared/enums';
import { NotificationsHelperService } from '../../../../../../shared/_helper-services/notifications-helper.service';
import { PaymentHelperService } from '../../../../../../shared/_helper-services/payment-helper.service';
import { IsInMAPFlowActions } from '../../../../../../shared/store/reducers/is-in-map-flow.reducer';
import { MileageAdjustmentHelperService } from '../../../../../../shared/_helper-services/mileage-adjustment-helper.service';
import { MileageEntryDialogData, MileageEntryDialogDataNavigationType } from '../../../../../../_models/map/mileage-entry-dialog-data.model';
import { WindowService } from '../../../../../../shared/_helper-services/window.service';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

@Component({
    selector: 'app-preferred-payment-option',
    templateUrl: './preferred-payment-option.component.html',
    styleUrls: ['./preferred-payment-option.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'mileage-overlay' },
        },
    ],
})


export class PreferredPaymentOptionComponent extends BaseApplicationComponent<PreferredPaymentOptionComponent> implements OnInit, OnDestroy {

    @Input() stepper: any;
    @ViewChild('prefPaymentTopDiv', { read: ElementRef }) prefPaymentTopDiv: any;
    midtermMileageAdjustmentData: MidtermMileageAdjustment;
    midtermMileageAdjustmentSubscription: any;
    purchaseMiles: string = "";
    optionMonthly: string = 'Monthly';
    optionSingle: string = 'Single';
    monthlyOpened: boolean = false;
    chooseSingleOpened: boolean = false;
    monthlySelected: boolean = false;
    singleSelected: boolean = false;
    lastPayment: boolean = false;
    currentAccountNumber: string;
    scheduledPaymentAllowed: boolean = false;
    singlePaymentAllowed: boolean = false;
    monthlyPaymentAllowed: boolean = false;
    errorMessage: string = '';
    globalTechError = false;
    mileageCalculationError = false;
    accountInEligible = false;
    noteText: string = '';
    scheduledPaymentAllowedToday: boolean = false;
    isActionBtnsActive: boolean = false;
    prefferedOption: string;
    paymentStartDates: Date[];
    paymentStartDate: string;
    calculateMilesData: CalculateMiles;
    paymentInfo: PaymentInfo;

    constructor(private translateService: TranslateService,
        private windowService: WindowService,
        private dialogRef: MatDialogRef<MileageAdjustmentEntryDialog>,
        private paymentViewService: PaymentViewService,
        private objectService: ObjectService,
        private midtermMileageAdjustmentService: MidtermMileageAdjustmentService,
        private mapQuoteSavedActions: MapQuoteSavedActions,
        private mapPaymentReviewDetailsActions: MapPaymentReviewDetailsActions,
        private currencyPipe: CurrencyPipe,
        private midtermMileageAdjustmentActions: MidtermMileageAdjustmentActions,
        private mileageAdjustmentHelperService: MileageAdjustmentHelperService,
        private paymentHelperService: PaymentHelperService,
        private isInMAPFlowActions: IsInMAPFlowActions,
        injector: Injector, @Inject(DOCUMENT) document) {
        super(injector, document);
    }

    ngOnInit() {
        this.store.select(state => state.ContractAccountDetail.accountNumber).subscribe(x => this.currentAccountNumber = x);
        this.paymentViewService.getOneTimePaymentDetails(this.currentAccountNumber).subscribe(response => {
            this.postGetOneTimePaymentDetails(response);
        });
        this.midtermMileageAdjustmentSubscription = this.store.select(state => state.MidtermMileageAdjustment)
            .subscribe((midtermMileageAdjustment) => {
                this.setMidtermMileageAdjustmentData(midtermMileageAdjustment)
            });
    }


    private postGetOneTimePaymentDetails(response: any): void {
        this.paymentInfo = response;
        if (this.paymentInfo && this.paymentInfo.error) {
            switch (this.paymentInfo.faultType) {
                case FaultCodes.BMWFSAM_Services_CustomerCashManagement_AccountIneligibleFault:
                    if (this.paymentInfo.errorResponse) {
                        let errorResponse = JSON.parse(this.paymentInfo.errorResponse);
                        if (this.paymentInfo.errorResponse && errorResponse.FaultDetail && (errorResponse.FaultDetail.IneligibleReasonCode === Constants.PAYMENTFREEZE || errorResponse.FaultDetail.IneligibleReasonCode === Constants.UPAYHOLD)) {
                            this.showEligiblePaymentOptions(false, true);
                        }
                        else {
                            this.showEligiblePaymentOptions(false, false);
                        }
                    }
                    else {
                        this.showEligiblePaymentOptions(false, false);
                    }
                    break;
                default:
                    const errorTranslationKey = "ngw.global.technical-error";
                    this.translateService.get(errorTranslationKey).subscribe((res: string) => {
                        this.errorMessage = res;
                    });
                    this.pushErrorEvent(errorTranslationKey);
                    this.globalTechError = true;
                    this.isActionBtnsActive = false;
                    break;
            }
        }
        else {
            this.showEligiblePaymentOptions(true, true);
        }
    }

    private setMidtermMileageAdjustmentData(midtermMileageAdjustment: MidtermMileageAdjustment): void {
        this.midtermMileageAdjustmentData = midtermMileageAdjustment;
        if (this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData && !this.objectService.isEmptyObject(this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.mMAStartDateList) && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.mMAStartDateList.length > 0) {
            this.paymentStartDates = this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.mMAStartDateList.slice(0, 2);
            this.paymentStartDate = this.paymentStartDates[0].toString();
            this.onPaymentStartDateChange(this.paymentStartDate);
        }
        else {
            this.onPaymentStartDateChange(new Date());
        }

        if (this.monthlyPaymentAllowed && this.singlePaymentAllowed) {
            this.prefferedOption = '';
            this.monthlySelected = false;
            this.singleSelected = false;
            this.monthlyOpened = false;
            this.chooseSingleOpened = false;
        }
        this.windowService.scrollDialogToTop(this.prefPaymentTopDiv);
    }


    public isMonthlyExpanded(): boolean {
        return this.monthlyOpened;
    }

    public isSingleExpanded(): boolean {
        return this.chooseSingleOpened;
    }

    public onPaymentStartDateChange(sourceValue: any) {
        if (this.midtermMileageAdjustmentData.milesToPurchase !== undefined && this.midtermMileageAdjustmentData.milesToPurchase > 0)
            super.setDataLoadedOnRequest(false);
        this.midtermMileageAdjustmentService.calculateMidTermMileageAdjustment(this.currentAccountNumber, new Date(sourceValue), this.midtermMileageAdjustmentData.milesToPurchase).subscribe(response => {
            this.postGetMileageCalculation(response);
        });
    }

    private postGetMileageCalculation(response: any) {
        super.setDataLoadedOnRequest(true);
        this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(true));
        if (response && response.error) {
            this.translateService.get("ngw.global.technical-error").subscribe((res: string) => {
                this.errorMessage = res;
            });
            this.mileageCalculationError = true;
            this.isActionBtnsActive = false;
        }
        else if (response && response.midtermMileageCalculation) {
            this.calculateMilesData = new CalculateMiles();
            this.calculateMilesData.midtermMileageCalculation = response.midtermMileageCalculation;
            this.mileageCalculationError = false;
            this.isActionBtnsActive = true;
            this.midtermMileageAdjustmentData.milesToPurchaseCost = this.calculateMilesData.midtermMileageCalculation.singleMMATotalAmount;
        }
    }

    public isFormValid(): boolean {
        return ((this.monthlySelected && this.isActionBtnsActive) || this.singleSelected);
    }

    private showEligiblePaymentOptions(singlePaymentAllowed: boolean, monthlyPaymentAllowed: boolean): void {
        this.singlePaymentAllowed = singlePaymentAllowed;
        this.monthlyPaymentAllowed = monthlyPaymentAllowed;
        if (!singlePaymentAllowed) {
            this.singlePaymentAllowed = false;
            this.translateService.get("ngw.map.entry.choose-payment.single-payment-not-allowed").subscribe((res: string) => {
                this.noteText = res;
            });
        }

        //check for monthly payment eligibility
        if (this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData && this.objectService.isEmptyObject(this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.mMAStartDateList)) {
            this.monthlyPaymentAllowed = false;
            this.translateService.get("ngw.map.entry.choose-payment.lease-end-status").subscribe((res: string) => {
                this.noteText = res;
            });
            this.expandAndSelectSingleOption();
        }
        else if (this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.monthlyMmaWithNoQuote === 1) {
            this.monthlyPaymentAllowed = false;
            this.translateService.get("ngw.map.entry.choose-payment.previous-purchase-miles").subscribe((res: string) => {
                this.noteText = res;
            });
            this.expandAndSelectSingleOption();
        }

        if (!this.singlePaymentAllowed && !this.monthlyPaymentAllowed) {
            this.accountInEligible = true;
        }
    }

    private expandAndSelectSingleOption(): void {
        this.prefferedOption = this.optionSingle;
        this.setSelected(this.optionSingle);
        this.chooseSingleOpened = true;
    }


    ngOnDestroy() {
        if (this.midtermMileageAdjustmentSubscription) {
            this.midtermMileageAdjustmentSubscription.unsubscribe();
        }
    }

    public continue(): void {
        let isSinglePayment = this.singleSelected;
        if (isSinglePayment) {
            this.store.dispatch(this.midtermMileageAdjustmentActions.setMidtermMileageAdjustment(this.midtermMileageAdjustmentData));
            this.setMapReviewDetails(MapPaymentOption.Single);
        }
        else {
            this.setMapReviewDetails(MapPaymentOption.Monthly);
        }
        this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(true));
        let navigationType: MileageEntryDialogDataNavigationType = isSinglePayment
            ? MileageEntryDialogDataNavigationType.MAPPayment
            : MileageEntryDialogDataNavigationType.MAPReview;
        let dialogData = new MileageEntryDialogData(true, navigationType);
        this.dialogRef.close(dialogData);
    }

    private setMapReviewDetails(paymentOption: MapPaymentOption): void {
        let previousMilesAllowed, milesToPurchase, totalMilesAllowed, totalCost, monthlyCost, newMonthlyPayment;
        if (this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData) {
            previousMilesAllowed = this.mileageAdjustmentHelperService.getOriginalContractedMileage();
            milesToPurchase = this.midtermMileageAdjustmentData.milesToPurchase;
            totalMilesAllowed = this.mileageAdjustmentHelperService.getTotalContractedMileage(milesToPurchase);
        }
        if (this.calculateMilesData && this.calculateMilesData.midtermMileageCalculation) {
            let data: CalculateMidtermMileageAdjustmentResponse = this.calculateMilesData.midtermMileageCalculation;
            totalCost = data.singleMMATotalAmount;
            monthlyCost = data.monthlyMMATotal;
            newMonthlyPayment = data.newMonthlyPaymentAmount;
        }
        let emaillAddresses: string[] = this.paymentHelperService.getPaymentEmailAddresses();
        let emailAddress: string = emaillAddresses && emaillAddresses[0] ? emaillAddresses[0] : null;
        let mapPaymentReviewDetails: MapPaymentReviewDetails = new MapPaymentReviewDetails(previousMilesAllowed,
            milesToPurchase,
            totalMilesAllowed,
            paymentOption,
            totalCost,
            monthlyCost,
            newMonthlyPayment,
            new Date(this.paymentStartDate),
            null,
            null,
            emailAddress);
        this.store.dispatch(this.mapPaymentReviewDetailsActions.setMapPaymentReviewDetails(mapPaymentReviewDetails));
    }

    public setSelected(option): void {
        if (option === this.optionMonthly) {
            this.singleSelected = false;
            this.monthlySelected = true;
        } else {
            this.singleSelected = true;
            this.monthlySelected = false;
        }
    }

    public afterExpanded(option): void {
        if (option === this.optionMonthly) {
            this.monthlyOpened = true;
            this.prefferedOption = this.optionMonthly;
        } else {
            this.chooseSingleOpened = true;
            this.prefferedOption = this.optionSingle;
        }
        this.setSelected(option);
    }

    public saveAsQuote() {
        let selectedDate: Date;
        let mmaData: GetMidtermMileageAdjustmentResponse = this.midtermMileageAdjustmentData.midtermMileageAdjustmentData;
        if (mmaData) {
            if (this.singleSelected) {
                selectedDate = new Date();
            }
            else if (this.monthlySelected) {
                selectedDate = new Date(this.paymentStartDate);
            }
        }
        if (this.currentAccountNumber && this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.milesToPurchase && selectedDate) {
            this.midtermMileageAdjustmentService.saveMidtermMileageAdjustmentQuote(this.currentAccountNumber,
                this.midtermMileageAdjustmentData.milesToPurchase,
                selectedDate,
                this.monthlySelected)
                .subscribe(response => this.postSaveMidtermMileageAdjustmentQuote(response));
        }
    }

    private postSaveMidtermMileageAdjustmentQuote(success: boolean) {
        this.store.dispatch(this.mapQuoteSavedActions.setMapQuoteSaved(success));
        this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(true));
        this.stepper.selectedIndex = 6;
    }

    public navigateToInbox() {
        this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(false));
        let dialogData = new MileageEntryDialogData(true, MileageEntryDialogDataNavigationType.SecureMessageCenter);
        this.dialogRef.close(dialogData);
    }

    public navigateToContactUs() {
        this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(false));
        let dialogData = new MileageEntryDialogData(true, MileageEntryDialogDataNavigationType.ContactUs);
        this.dialogRef.close(dialogData);
    }
}
