import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';
import { tassign } from 'tassign';


@Injectable()
export class ShowSpinnerActions
{
    static SET_SHOW_SPINNER: string = 'SET_SPINNER_VISIBLE';     

    setShowSpinner(flag: boolean): FSA<string, boolean, null>{
        return {
            type: ShowSpinnerActions.SET_SHOW_SPINNER,
            payload: flag,
            meta: null
        };
    }

}

@Injectable()
export class ShowSpinnerReducers
{
    static ShowSpinnerReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
            case ShowSpinnerActions.SET_SHOW_SPINNER:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
