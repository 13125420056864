import { Subject } from "rxjs";
import { AnalyticsSiteToolName, AnalyticsSiteToolStep } from "./enums";

export class Constants {
  public static VEHICLE_IMAGE_FRONT_ANGLE: string = "angle=5";
  public static VEHICLE_IMAGE_DEFAULT_ANGLE = "angle=90";
  public static VEHICLE_IMAGE_ANGLED = "angle=45";
  public static VEHICLE_IMAGE_LOW_QUALITY: string = "quality=1";
  public static VEHICLE_IMAGE_DEFAULT_QUALITY = "quality=100";
  public static VEHICLE_IMAGE_SMALL_SIZE: string = "width=100";
  public static VEHICLE_IMAGE_DEFAULT_SIZE = "width=400";
  public static USER_DATA: string = "User_Data";
  public static REMEMBER_ME_KEY: string = "Remember_me_key";
  public static READONLY: string = "readonly";
  public static LEASE = "Lease";
  public static LOAN = "Loan";
  public static EMPTY = "";
  public static UNDEFINED = undefined;
  public static MAX_BODY_LENGTH = 1200;
  public static MINIMUM_PAYMENT_AMOUNT = 1;
  public static FINANCING = "Financing";
  public static FSTOKEN_EXPIRED_ERROR = "invalid_token";
  public static FSTOKEN_SPOOFING_ERROR = "FsToken spoofing detected.";
  public static FSTOKEN_AUTHENTICATION_ERROR =
    "Authorization has been denied for this request.";
  public static ENROLLED = "Enrolled";
  public static NOT_ENROLLED = "Not Enrolled";
  public static DOLLAR_CURRENCY = "USD";
  public static ONE_TIME_FLOW = "ONE_TIME_FLOW";
  public static RECURRING_FLOW = "RECURRING_FLOW";
  public static VIEW_SOURCES = "VIEW_SOURCES";
  public static SELECT_SOURCE = "SELECT_SOURCE";
  public static DEBIT_CARD = "DEBIT_CARD";
  public static CREDIT_CARD = "CREDIT_CARD";
  public static ACTIVE = "Active";
  public static TERMINATED = "Terminated";
  public static TERMINATED_ZERO_BALANCE = "TerminatedZeroBalance";
  public static DAYS_DELINQUENT_EXCEEDED = "DaysDelinquentExceeded";
  public static PAYMENTFREEZE = "PaymentFreeze";
  public static UPAYHOLD = "UPayHold";
  public static COMPANY = "company";
  public static Y = "Y";
  public static ONBOARDING_MODULE_DATA = "ONBOARDING_MODULE_DATA";
  public static OTHER = "Other";
  public static TIRES = "Tires";
  public static DEBITCARDALLOWED = "DebitCardAllowed";
  public static CREDITCARDALLOWED = "CreditCardAllowed";
  public static ResetErrors = "ResetErrors";
  public static UnknownError = "UnknownError";
  public static MyAccount = "MyAccount";
  public static PayoffDocument = "PayoffDocument";
  public static ShowOffers = "ShowOffers";
  public static PLACEHOLDER_TOKEN = "browsertoken";
  public static NotCalculated = "---";
  public static CREHostName: string = "creHost";

  public static Logger = {
    Space: " ",
    Initialize: "Initialize",
    Destroy: "Destroy",
    errorAt: "Error occurred at the following route:",
    errorMessage: "Error message:",
    errorRoute: "Error occurred while loading the route:",
    Payment: {
      EmptyPaymentSource: "Payment source is empty.",
      EmptyPaymentAmount: "Empty payment amount or empty payment date.",
    },
    MFA: {
      TokenFailure: "MultiFactor token failed.",
      MethodNotFound:
        "Method(Phone/Email) information's could not read from bridge service.",
      MethodValidationFailure: "Method(Phone/Email) validation failed.",
      ClientDeviceTokenInsertionFailure:
        "failed to insert client device token.",
    },
  };
  //Component names used for Adobe site tracking
  public static ESignComponentName = "ESign";
  public static HomeComponentName = "Home";
  public static DashboardComponentName = "Dashboard";
  public static MyProfileComponent = "MyProfile";
  public static ChangePasswordComponentName = "ChangePassword";
  public static ChangePasswordConfirmationComponentName =
    "ChangePasswordConfirmation";
  public static ManageSettingsComponentName = "ManageSettings";
  public static PaymentConfirmationComponentName = "PaymentConfirmation";
  public static PayoffInformationComponentName = "PayoffInformation";
  public static LeaseMaturityInvoiceComponentName = "LeaseMaturityInvoice";
  public static PaymentEntryComponentName = "PaymentEntry";
  public static RecurringPaymentEntryComponentName = "RecurringPaymentEntry";
  public static RecurringPaymentReviewComponentName = "RecurringPaymentReview";
  public static RecurringPaymentConfirmationComponentName =
    "RecurringPaymentConfirmation";
  public static DueDateChangeDialogName = "ChangeDueDate";
  public static DueDateChangeEntryComponentName = "ChangeDueDateEntry";
  public static DueDateChangeReviewComponentName = "ChangeDueDateReview";
  public static DueDateChangeConfirmationComponentName =
    "ChangeDueDateConfirmation";
  public static PaymentDeferralEntryComponentName = "PaymentDeferralEntry";
  public static PaymentDeferralInfoComponentName = "PaymentDeferralInfo";
  public static PaymentDeferralRequestComponentName = "PaymentDeferralRequest";
  public static PaymentDeferralConfirmationComponentName =
    "PaymentDeferralConfirmation";
  public static CookiePolicyComponentName = "CookiePolicy";
  public static ErrorComponentName = "Error";
  public static AuthenticationComponentName = "Authentication";
  public static AuthenticationMultistepComponentName =
    "AuthenticationMultistep";
  public static TermsAcceptComponentName = "AcceptTerms";
  public static TermsViewComponentName = "ViewTerms";
  public static SCRAComponentName = "SCRA";
  public static ForgotPasswordComponentName = "ForgotPassword";
  public static ForgotPasswordChangeComponentName = "ForgotPasswordChange";
  public static ForgotPasswordConfirmationComponentName =
    "ForgotPasswordConfirmation";
  public static ForgotPasswordSuccessComponentName = "ForgotPasswordSuccess";
  public static LegitimizationComponentName = "Legitimization";
  public static LoginComponentName = "Login";
  public static RegistrationComponentName = "Registration";
  public static RegistrationActivationComponentName = "RegistrationActivation";
  public static RegistrationActivationComponentSuccessName =
    "RegistrationActivationSuccess";
  public static RegistrationActivationComponentFailedName =
    "RegistrationActivationFailed";
  public static RegistrationCompleteComponentName = "RegistrationComplete";
  public static FaqComponentName = "FAQ";
  public static ContactUsComponentName = "ContactUs";
  public static AccountDetailsComponentName = "AccountDetails";
  public static AccountStatementsComponentName = "AccountStatements";
  public static QuoteInformationComponentName = "QuoteInformation";
  public static VehicleReturnEstimateHeaderComponent =
    "VehicleReturnEstimateHeaderComponent";
  public static ViewRepairReceiptsComponentName = "ViewRepairReceipts";
  public static UploadRepairReceiptComponentName = "UploadRepairReceipt";
  public static NotificationsComponentName = "Notifications";
  public static LeaseExtensionPaymentComponentName = "LeaseExtendPayment";
  public static LeaseExtensionPaymentReviewComponentName =
    "LeaseExtendPaymentReview";
  public static LeaseExtensionPaymentConfirmationComponentName =
    "LeaseExtendPaymentConfirmation";
  public static ViewModeAdd = "add";
  public static ViewModeModify = "modify";
  public static AddACHPaymentSourceComponentName = "AddACHPaymentSource";
  public static DeletePaymentSourceComponentName = "DeletePaymentSource";
  public static ViewPaymentSourceComponentName = "ViewPaymentSource";
  public static CancelRecurringPaymentComponentName = "CancelRecurringPayment";
  public static RecurringPaymentReviewName = "RecurringPaymentReview";
  public static AddPaymentCardComponentName = "AddPaymentCard";
  public static SMCInboxComponentName = "SMCInbox";
  public static SMCViewComponentName = "SMCView";
  public static SMCComposeComponentName = "SMCCompose";
  public static PaymentDetailsComponentName = "PaymentDetails";
  public static CancelPendingOneTimePaymentComponentName =
    "CancelOneTimePayment";
  public static ContactInformationComponent = "ContactInformationComponent";
  public static EditAddressDialogComponentName = "EditAddress";
  public static TaxJurisdictionDialogComponentName = "Taxjurisdiction";
  public static EditPhoneNumbersDialogComponent = "EditPhoneNumbers";
  public static NoPhoneNumber: string = "0000000000";
  public static ClictoSignComponentName = "ClictoSign";
  public static LeaseEndStatementComponentName = "LeaseEndStatement";
  public static VehicleReturnEstimateComponentName = "VehicleReturnEstimate";
  public static RedirectComponentName = "Redirect";
  public static SsoComponentName = "Sso";
  public static ViewAmortizationScheduleComponentName =
    "ViewAmortizationSchedule";
  public static SsoLogoutComponentName = "SsoLogout";
  public static PageNotFoundComponentName = "PageNotFound";
  public static ToggleRecurringPaymentComponentName = "ToggleRecurringPayment";
  public static termsURIsuffix = "/terms/view";
  public static PayoffTermAcctComponentName = "PayoffTerminatedAccount";
  public static MobileDevice = "Mobile";
  public static DesktopDevice = "Desktop";
  public static TabletDevice = "Tablet";
  public static AddACHPaymentSourceCanComponentName = "AddACHPaymentSourceCan";
  public static AddChequingAccountConfirmationCanComponentName =
    "AddChequingAccountConfirmationCan";
  public static UploadAttachmentComponentName = "UploadAttachment";
  public static ElvDelear = "06950";
  public static ElvDelearPricingPkg = "06177";

  public static MAPReviewComponentName = "MAPReview";
  public static MAPConfirmationComponentName = "MAPConfirmation";
  public static MAPSinglePaymentComponentName = "MAPSinglePayment";
  public static OnboardingComponentName = "Onboarding";
  public static OnboardingDialogComponentName = "OnboardingDialog";
  public static LeaseExtensionPayment = "LeasePayment";
  public static LeaseExtensionPaymentDuration = "LeasePaymentDuration";
  public static LeaseExtensionPaymentReview = "LeasePaymentReview";
  public static LeaseExtensionReviewAgreement = "LeaseReviewAgreement";
  public static LeaseExtensionPaymentConfirmation = "LeasePaymentConfirmation";
  public static ErrorPage = "error page";
  public static ReschedulePaymentEntry = "ReschedulePaymentEntry";
  public static ReschedulePaymentConfirmation = "ReschedulePaymentConfirmation";
  public static ActivityComponentName = "ActivityComponent";
  public static PaymentReviewComponentName = "PaymentReview";

  //Component's custom classes
  public static RecurringPaymentEntryCustomClass = "recurring-payment-entry";
  public static MessageHeaderCustomClass = "msg-header-component";
  public static TitleCustomClass = "title";
  public static ApplicationRootCustomClass = "app-root";
  public static ContactUsAddressesCustomClass = "app-contact-us-address";
  public static ContactUsPhoneCustomClass = "app-contact-us-phone";
  public static MessageCenterCustomClass = "app-contact-us-msg";
  public static NavigationCustomClass = "my-account-navigation secondary-nav";
  public static MyProfileLeftNavCustomClass = "app-my-profile-left-nav";
  public static PrimaryClass = "PRIMARY";
  public static ExpansionPanelHeaderClass = "mat-expansion-panel-header";
  public static ExpansionPanelHeaderExpandedClass = "mat-expanded";

  //Analytics launch constants
  public static Entry = "Entry";
  public static Review = "Review";
  public static Cancel = "Cancel";
  public static Confirmation = "Confirmation";
  public static VehicleEstimate = "VehicleEstimate";
  public static ExtendLease = "ExtendLease";
  public static RepairReceipt = "RepairReceipt";
  public static PaymentDetails = "PaymentDetails";
  public static EasyPay = "Easypay";
  public static PaymentDeferral = "PostPone payment";
  public static MessageCenter = "MessageCenter";
  public static View = "View";
  public static Compose = "Compose";
  public static Inbox = "Inbox";
  public static Upload = "Upload";
  public static MAP = "MAP";
  public static Payment = AnalyticsSiteToolName.PAYMENT;
  public static DiscountMileagePurchase = "DiscountMileagePurchase";
  public static MaturityBill = "MaturityBill";
  public static OSA = "OSA";
  public static OSAAcceptedSiteToolStep = "Terms Accepted";
  public static ESIGNAcceptedSiteToolStep = "ESIGN Accepted";
  public static Terms = "TermsOfUse";
  public static PaymentSource = "PaymentSource";
  public static Add = "Add";
  public static ReschedulePayment = "ReschedulePayment";
  public static ButtonTagName = "BUTTON";
  public static BodyTagName = "BODY";
  public static HtmlTagName = "HTML";
  public static LinkTagName = "A";
  public static footer = "footer";
  public static FooterSelector = "#footer";
  public static FooterComponentSelector = "fs-footer";
  public static FooterWebviewClassSelector = ".webview-footer";
  public static HeaderSelector = "header";
  public static HeaderComponentSelector = "fs-header";
  public static LoggedInState = "LoggedIn";
  public static AnonymousState = "Not LoggedIn";
  public static ExitLinkSuffix = "exit";
  public static LinkNameSeparator = ".";
  public static ErrorPageType = "error page";
  public static OpenedExpansionPanel = "opened";
  public static ClosedExpansionPanel = "closed";
  public static Request = "Request";
  public static page404 = "404";
  public static AnalyticsScriptId = "analyticsLaunchScript";
  public static AnalyticsScriptLanguage = "JavaScript";
  public static AnalyticsScriptTagName = "script";
  public static AnalyticsScriptType = "text/javascript";
  public static TargetScriptId = "adobeTargetScript";
  public static Edit = "edit";
  public static Hyperlink = "hyperlink";

  //Analytics Site Tool Constants
  public static SiteToolStart = AnalyticsSiteToolStep.START;
  public static SiteToolComplete = AnalyticsSiteToolStep.COMPLETE;
  public static EasyPayEnrollment = AnalyticsSiteToolName.EASY_PAY_ENROLLMENT;
  public static EasyPayPause = AnalyticsSiteToolName.EASY_PAY_PAUSE;
  public static EasyPayResume = AnalyticsSiteToolName.EASY_PAY_RESUME;
  public static PurchaseDiscountedMiles = "Purchase discounted miles";
  public static RepairReceiptUpload = "Repair Receipt Upload";
  public static RepairAdded = "Repair Added";
  public static RepairRemoved = "Repair Removed";
  public static RepairReceiptAdded = "Repair Receipt Added";
  public static RepairReceiptRemoved = "Repair Receipt Removed";
  public static PaperLessStatement = "Paperless Statement";
  public static notificationRemainderOn = "turned on";
  public static notificationRemainderOff = "turned off";
  public static DeleteSMCMessage = "Delete SMC Message";
  public static EditPhoneNumbers = "Edit Phone Numbers";
  public static EditEmailAddress = "Edit Email Address";
  public static EditMailingAddress = "Edit Mailing Address";
  public static EditVehicleRegistrationAddress =
    "Edit Vehicle Registration Address";
  public static MileageAdjustmentGeneralSource = "general";
  public static MileageAdjustmentQuoteSource = "available quotes";
  public static MileageAdjustmentSavedQuote = "yes";
  public static MileageAdjustmentUnsavedQuote = "no";
  public static PaymentAmountChange = "ChangePaymentAmount";
  public static PaymentSourceChange = "ChangePaymentSource";
  public static PaymentDateChange = "ChangePaymentDate";
  public static PaymentEmailChange = "ChangePaymentEmail";
  public static ToggleSecondaryEmail = "Toggle Secondary Email";
  public static LeaseExtension = "Extend lease contract";
  public static ContinueToPayment = "ContinueToPayment";
  public static PaymentReview = "PaymentReview";
  public static PaymentTypeChange = "PaymentTypeChange";

  //ContentLoadingDelay
  public static ContentLoadingDelay = 500;
  //screen sizes
  public static SmallScreenSize = 600;
  public static MediumScreenSize = 760;
  public static LargeScreenSize = 1024;

  //fa icon names
  public static CreditCard = "credit_card";
  public static Schedule = "schedule";
  public static AttachMoney = "attach_money";
  public static Autorenew = "autorenew";
  public static Notifications = "notifications";
  public static DescriptionIcon = "outline-description";
  public static MobileIcon = "outline-phone_iphone";
  public static EmailIcon = "outline-email";

  //Assets paths
  public static EsignConsentAgreementPdfPath =
    "../assets/pdf/BMW_Esign_Disclosure.pdf";
  public static EsignHtmlPath = "../../assets/html/esign.html";
  public static EsignCaConsentAgreementPdfPath =
    "/assets/pdf/BMW_Esign_Disclosure.pdf";
  public static EsignCaHtmlPath = "assets/html/esign.html";
  public static MessagesJsonPath = "../../assets/json/messages.json";
  public static PageIdsJsonPath = "../../assets/json/pageids.json";
  public static OnboardingTourImagePath = "../../assets/images/tour-hero.jpg";
  public static AutoEnrollTourImagePath =
    "../../assets/images/tour-auto-enroll.jpg";
  public static PaperlessIcon = "assets/images/Icon_Paperless.svg";
  public static PdfPath = "../assets/pdf/";
  public static TitleCalendarImgAltPath =
    "./../../../../../../assets/images/calendar-alt.svg";

  //deeplink parameters
  public static DeepLinkPageIdParam = "id1";
  public static DeepLinkVINParam = "id2";
  public static DeepLinkAnchorParam = "id3";
  public static DeepLinkSourceParam = "id4";
  public static DeepLinkLanguageParam = "language";
  public static DeepLinkSource = {
    QrCode: "qr",
  };
  public static Vin7Length = 7;
  public static SmcScraSubjectId = 63;
  public static SmcPaymentIssueSubjectId = 2;
  public static SmcPaymentIssueSubjectName = "Payment Issue";
  public static OrigUrlParam = "origUrl";

  //ADD PAYMENT SOURCE
  public static Checking = "Checking";
  public static Saving = "Savings";
  public static Checkmark = "checkmark";
  public static LastUsed = "lastUsed";
  public static routingNumLength = 9;
  public static AddAch = "AddAch";
  public static AddCard = "AddCard";
  public static AddCreditCard = "AddCreditCard";
  public static NewSourceTypeParam = "newsourcetype";

  //SSO
  public static SsoRoute = "/sso";
  public static SsoCodeParam = "code";
  public static SsoStateParam = "state";
  public static SsoUriParam = "uri";
  public static SsoGrantTypeParam = "granttype";
  public static SsoAuthenticateUserGrantType: string = "authorization_code";
  public static SsoConnectedAppGrantType: string = "jwt_code";
  public static SsoConnectedAppBearerTokenGrantType: string = "bearer_token";
  public static SsoAuthHubRouteScopeParameterName = "scope";
  public static SsoAuthHubRouteScopeParameterValue = "authenticate_user";
  public static SsoAuthHubRouteResponseTypeParameterName = "response_type";
  public static SsoAuthHubRouteResponseTypeParameterValue = "code";
  public static SsoAuthHubRouteClientIdParameterName = "client_id";
  public static SsoAuthHubRouteRedirectUriParameterName = "redirect_uri";
  public static SsoAuthHubRouteStateParameterName = "state";
  public static SsoAuthHubLogoutRoute = "/logout";
  public static SsoAuthHubRouteLogoutUriParameterName = "logout_uri";
  public static SsoAuthHubManageSettingsRoute = "/managesettings";
  public static MockGatewayToken = "mock-gatewayToken";
  public static PreviousUrl = "previousUrl";
  public static SsoIdToken = "id_token";
  public static ReturnUrl = "returnurl";

  //GCDM ONE LOGIN
  public static GcdmOneLoginClient: string = "client";
  public static GcdmOneLoginBrand: string = "brand";
  public static GcdmOneLoginLanguage: string = "language";
  public static GcdmOneLoginScope: string = "scope";
  public static GcdmOneLoginResponseType: string = "response_type";
  public static GcdmOneLoginRedirectUri: string = "redirect_uri";
  public static GcdmOneLogoutRedirectUrl: string = "redirectUrl";
  public static GcdmOneLoginCountry: string = "country";
  public static GcdmOneLoginState: string = "state";
  public static GcdmOneLoginIdToken: string = "#id_token";

  public static OcWebUser = "OCWeb";

  //Url Builder
  public static UrlParametersPrefix = "?";
  public static UrlParametersConcat = "&";
  public static UrlParameterNameAndValueConcat = "=";
  public static LanguageParameterName = "language";
  public static LanguageConcat = "-";

  //Header file types
  public static FileTypeText: string = "text/html";
  public static FileTypeJson: string = "application/json";

  //File extensions
  public static FileExtensionText: string = "txt";
  public static FileExtensionHtml: string = "html";
  public static FileExtensionJson: string = "json";

  //Header used for activity tracking
  public static ActivityIdHeaderName: string = "X-BMW-ETW-ACTIVITY-ID";

  //ePaas cookie category
  public static EPaasProcessingId = {
    AdobeAnalytics: "AdobeAnalytics",
  };

  //country codes
  public static Country_USA: string = "USA";
  public static Country_CA: string = "CA";
  public static Country_NJ: string = "NJ";
  public static Country_CAN: string = "CAN";

  public static NumberOfDays: number[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ];
  public static DefaultDaysForPaymentReminder: number = 1;
  public static DefaultDaysForConnectedAppPaymentReminder: number = 5;
  public static DECIMAL_ZERO: number = 0.0;
  public static Empty: string = "Empty";
  public static SixtyDays: number = 60;
  public static SchedulePaymentPreference: number = 10;
  public static OneMonth: number = 1;
  public static TwelveDays: number = 12;
  public static TwentyFourDays: number = 24;
  public static OneThousandDays: number = 1000;
  public static ThirtySixHundredDays: number = 3600;
  public static MinDueDaysForCreditPointsRedeem: number = 5;
  public static eligibleDaysForLeaseExt: number = 30;

  public static NumberZero = 0;
  public static AllowedMonthsToExtend = 2;
  public static NumberThirteen = 13;
  public static ForwardSlash = "/";
  public static Dot = ".";
  public static ZeroOutStandingBalance = 0;
  public static MaxMaturityBillDueDaysAllowed = 15;
  public static MaxMonthsToExtend = 1;

  //ach pause
  public static MinimumRemainingPayments = 2;

  //Hold and Reschedule
  public static resheduleWeekly = "weekly";

  //masking characters
  public static MaskCharRoutingNumber: string = "X";
  public static MaskCharAccountNo: string = "X";
  public static MaskWithStar: string = "*";

  //tooltip values
  public static LeftString: string = "left";
  public static RightString: string = "right";
  public static WindowWidthBreakpoint: number = 769;

  // Click To Sign
  public static ClickToSign = "ClickToSign";
  public static AWSTrigger = "awsApprovalBlock";
  public static Click2SignDocumentName = "PaymentDeferral";
  public static Click2SignFinalize = "FINALIZE";
  public static PaymentDeferralId = "paymentDeferralId";
  public static Vin = "vin";

  // Mobile Wallet
  public static Apple = "Apple";
  public static Android = "Android";
  public static src = "&src=";
  public static iOS = "iOS";

  //Browsers
  public static Edge = "Edge";

  // Sub Nav Types
  public static Accounts = "Accounts";
  public static Profile = "Profile";
  public static Dashboard = "Dashboard";
  public static EditLogin = "Edit Login";
  public static SpecialOffers = "Special Offers";

  // tour dialog close parameters
  public static OnlyCreateCookie = "create cookie";
  public static GoToNotifications = "notifications";

  //Ca Features
  public static LegitimizationFeature = "LEGITIMIZATION";

  //Store Items
  public static ScheduledItems = "ScheduledItems";

  // Anchors values
  public static MyProfileAnchors = { paymentSources: "paymentsources" };
  public static AccountDetailsAnchors = {
    mileageManagement: "mileageManagement",
  };

  public static NotificationsAnchors = {
    billingPreferenceNotification: "billingPreferenceNotification",
  };

  //Onboarding Module
  public static OnboardingModuleName = "Enhanced Onboarding";
  public static AgreementsStepName = "TermsAndCondition";
  public static PaperlessStepName = "PaperlessEnrollment";
  public static ConfirmationStepName = "Confirmation";
  public static EsignStepDataKey = "Esign";
  public static TermsStepDataKey = "TermsAndCondition";
  public static PaperlessStepDataKey = "Paperless";
  public static BooleanStepDataValue = "TRUE";
  public static ProfileStepDataKey = "ProfileTermsAndCondition";

  // Upload attachment constants
  public static KiloBit = 1024;
  public static FixedDecimalPoint = 2;

  //Payment Sub Distribution
  public static BalloonPayment = "Balloon Pmt";

  //Condition Codes
  public static LeaseEndConditionCode = 3;

  //Payment Label Value
  public static EasyPayLabelValue = "1";
  public static PaymentSourceLabelValue = "2";
  public static SchedulePaymentLabelValue = "3";

  //date pipe formate text
  public static datePipeLongFormat = "longDate";
  public static datePipeMediumFormat = "mediumDate";
  public static datePipeMddyyyFormat = "M/dd/yyyy";
  public static datePipeMMddyyyFormat = "MM/dd/yyyy";

  //currency pipe format
  public static CurrencyPipeSymbolNarrow = "symbol-narrow";
  public static CurrencyPipeDigitFormat = "1.2-2";
  public static CurrencyUSD = "USD";

  //Back Button Mode
  public static BackBtnModeClose = "CLOSE";

  //regular expression
  public static safariTimeStampReplace = "T";
  public static whiteSpaceString = " ";

  //ui component
  public static BackMode = "BACK";
  public static CloseMode = "CLOSE";
  public static TextMode = "TEXT";
  public static None = "NONE";

  //Action Text Mode
  public static StanderdMode = "standard";
  public static PositiveMode = "positive";
  public static WarningMode = "warning";

  //Road Ahead Url Parameters

  public static UTM_SOURCE = "utm_source";
  public static BMWFS = "BMWFS";
  public static UTM_MEDIUM = "utm_medium";
  public static UTM_CAMPAIGN = "utm_campaign";
  public static MATDAT = "matdat";
  public static WEB = "web";

  public static PaymentDetailsAnchors = {
    recurringPayments: "recurringpayments",
    pendingPayments: "pendingpayments",
    principleAndInterest: "principleandinterest",
    recentActivity: "recentActivity",
    enrolleasypay: "enrolleasypay",
  };
  public static QuickLinkAnchors = {
    accountDetails: "accountDetails",
    payOff: "payOff",
    notifications: "notifications",
    accountStatements: "accountStatements",
    principleInterestAndPayments: "principleInterestAndPayments",
    contactus: "contactus",
    faq: "faq",
  };
  public static MAPAnchors = {
    mileagePaymentDetail: "mileagePaymentDetail",
    mileagePaymentReview: "mileagePaymentReview",
  };

  public static DeepLinkPageCodes = {
    statements: "as",
    recurringpaymententry: "ep",
    paymententry: "up",
    notifications: "ap",
    smcinbox: "mc",
    maturityBilling: "mp",
    payoffinformation: "po",
    paymentdetails: "pd",
    myprofile: "pf",
    faq: "fq",
    accountdetails: "ad",
    contactus: "cu",
    interestpaid: "ip",
    endOfTerm: "et",
  };

  //Header used for activity tracking
  public static LandingPage: string = "LandingPage";
  public static Outlet = "primary";

  //Events
  public static logOutEvent: Subject<number> = new Subject<number>();

  //Other
  public static States: string[] = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  static readonly bulletinBoxMaxLength: number = 146;
  public static EditProfile: string = "EditProfile";
  public static PurchaseLeaseMiles: string = "PurchaseLeaseMiles";

  public static DaysofMonth: number[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  public static startDOBYear = 1920;
  public static endDOBYear = 2007;
  public static noOfDaysOldMaturityBilling = 60;
  public static phoneNumberValue = 10;
  public static minimumFilesToUploadAchPaymentSourceCan = 2;
  public static PADFormAttachment = "PADForm";
  public static ChequeAttachment = "ChequeForm";
  public static ContractEndDaysForSelect = 190;

  public static getYears(startYear: number, endYear: number): string[] {
    let years: string[] = [];
    while (startYear <= endYear) {
      years.push(endYear.toString());
      endYear = endYear - 1;
    }
    return years;
  }

  // snackbar constants
  public static SnackPosition = "top";
  public static SuccessSnackDuration: number = 3000;
  public static TimedOutSnackDuration: number = 4500;
  public static SnackTimeout: number = 500;
  public static SuccessSnackLabel = "Success";
  public static SuccessSnackAuthContent =
    "We have successfully authenticated your Financial Services account.";
  public static SuccessSnackPymntSourceContent =
    "Your new payment source has been added.";
  public static TimedOutSnackLabel = "Timed Out";
  public static TimedOutSnackContent =
    "For Secuirty reasons, you were timed out of your account due to inactivity.";
  public static SuccessStylingClass = "success";
  public static TimedOutStylingClass = "timedOut";
  public static PaperLessEnabledSnackLabel =
    "Paperless Statements has been enabled to your account ending with ";
  public static PaperLessEnabledSnackDuration: number = 4500;

  // user-inactivity dialog notifications
  public static UserInactivityDialogTitle = "Your session is about to time out";
  public static UserIanctivityDialogWarning =
    "For your security, we will automatically time you out in approximately 2 minutes.";

  //css classes
  public static ActiveClass = "active";

  //Spotlight tour steps ids
  public static AccountsLnkId = "Accounts";
  public static AccountDetailsBtnId = "contractaccountdetails";
  public static NotificationsMenuItemId = "notifications";
  public static NotificationsPage = "notificationPage";
  public static AccountDetails = "accountdetails";
  public static SelectDashboard = "SelectDashboard";
  public static SelectAccount = "SelectAccount";
  public static OpenDashBoardMenu = "OpenDashBoardMenu";
  public static CloseMenu = "CloseMenu";
  public static AccountRoute = "AccountRoute";
  public static AccountScroll = "AccountScroll";
  public static AccountDetailRoute = "AccountDetailRoute";
  public static NotificationScroll = "NotificationScroll";
  public static NotificationRoute = "NotificationRoute";
  public static AccountDetailScroll = "AccountDetailScroll";

  //url
  public static protectionPlanUrl =
    "https://www.bmwusa.com/financial-services/protection-program.html";

  //File download Types
  public static FileDownloadPdf: string = "PDF";
  public static FileDownloadTypePdf: string = "application/pdf";
  public static FileDownloadExtPdf: string = ".pdf";
  public static LeaseEndDocument: string = "LeaseEndDocument";
  public static FileDownloadPng: string = "PNG";
  public static FileDownloadTypePng: string = "image/png";

  public static FileDownloadGif: string = "GIF";
  public static FileDownloadTypeGif: string = "image/gif";

  public static FileDownloadJpeg: string = "JPEG";
  public static FileDownloadTypeJpeg: string = "image/jpeg";

  public static FileDownloadJpg: string = "JPG";
  public static FileDownloadTypeJpg: string = "image/jpg";

  public static FileDownloadTif: string = "TIF";
  public static FileDownloadTypeTif: string = "image/tif";

  public static FileDownloadTiff: string = "TIFF";
  public static FileDownloadTypeTiff: string = "image/tiff";

  // Lease Extension Service
  public static ContinueAutomaticRecurringPayments =
    "Continue Automatic Recurring Payments";
  public static MakeAPayment = "Make a Payment";
  public static ScheduleAPayment = "Schedule a Payment";
  public static LeaseExtensionPaymentNote = "LeaseExtensionPay";

  //Repair Receipt
  public static PartialUpload = "partialUpload";

  //mileage management
  public static RecommendedMiles: string = "recommended";
  public static Monthly: string = "monthly";
  public static Single: string = "single";
  public static ChooseYourOwn: string = "chooseyourown";
  public static Miles: string = "mi";

  //2Factor
  public static Remember: string = "remember";
  public static NotRemember: string = "notremember";

  //Chatbot
  public static ChatbotNavigationDelay: number = 3000;

  //Scroll to top
  public static ScrollToTopDelay: number = 50;

  //Payment Deferral
  public static PaymentDeferralParameter1Name: string = "mode";
  public static PaymentDeferralParameter2Name: string = "vin";
  public static PaymentDeferralParameter1Value: string = "payment-deferral";
  public static PaymentDeferralRequestorComment: string =
    "Customer requested via web";
  public static ClickToSignDeliveryChannelName: string = "Click To Sign";

  public static dateSeperator: string = "-";
  public static repairReceiptApprovedStatus = 1;
  public static repairReceiptDeclinedStatus = 2;
  public static repairReceiptPendingStatus = 0;
  public static newLineSeperator = "\n";
  public static colonSeperator = ":";
  public static repairItemIndex = 2;
  public static repairItemValueIndex = 1;
  public static LeaseMaturityInvoiceValue = "leasematurityinvoice";
  public static FileLeaseMaturityInvoicePDF: string = "LeaseMaturityInvoice";
  public static leftParanthesis = "(";
  public static rightParanthesis = ")";
  public static maxPsdToolTipLength = 45;
  public static totalLineSeparator = "-------------------";
  public static removeTwoParanthesis = 2;

  public static NavigationItems = {
    Statements: "statements",
    PayOff: "payoffinformation",
    PaymentDetails: "paymentdetails",
  };

  //log constants
  public static ServerLogLevel: string = "ServerLogLevel";
  //chatbot
  public static AvailableRouteData = "AvailableRouteData";
  public static CHATBOTDATA = "CHAT_BOT_DATA";

  // Add ACH Payment Source CAN
  public static AccountNumber: string = "accountnumber";
  public static CustomerNumber: string = "customernumber";
  public static CustomerName: string = "customername";
  public static BankNumber: string = "banknumber";
  public static BankTransitNumber: string = "banktransitnumber";
  public static BankAccountNumber: string = "bankaccountnumber";
  public static SmcAddAchCanSubjectId: number = 69;

  // Authentication
  public static MFA: string = "MFA";
  public static FS: string = "FS";
  public static AuthenticationChannelEmail: string = "0";
  public static AuthenticationChannelSms: string = "1";
  public static AuthenticationPhone: string = "phone";

  //keycode constants
  public static backSpaceCode: number = 8;
  public static minNumericCode: number = 48;
  public static maxNumericCode: number = 57;

  //payoff - title information.
  public static CustomerNonCertified: number = 1;
  public static CustomerCertified: number = 12;
  public static CertfiedCheck: string = "1";
  public static TitleSent: number = 3;
  public static LienSent: number = 33;
  public static OdoMissing: string = "1";
  public static CheckCopy: string = "0";
  public static Brand: string = "Brand";
  public static UserId: string = "UserId";
  public static PayoffDataQuery: string = "PayoffDataQuery";
  public static PayoffDataQueryValue: string = "order by PayoffQuoteId desc";
  public static OdoMeterMissingLibDocId: number = 84;
  public static RecallMissingLibDocId: number = 643;
  public static SecDepAuthMissingLibDocId: number = 74;
  public static OdoDocName: string = "Federal Odometer Disclosure Statement";
  public static RecallDocName: string = "Recall Letter";
  public static SecDepAuthDocName: string = "Security Deposit Authorization";
  public static AccountNumberKey: string = "AccountNumber";
  public static CustomerNumberKey: string = "CustomerNo";
  public static OverDue: string = "OVERDUE";
  public static DeliveryCustomerRoleId: string = "DeliveryCustomerRoleId";
  public static QuebecState: string = "QC";
  public static UnknownDeviceType: string = "unknown";

  public static toastDuration_5Seconds: number = 5000;
  public static App2ClientAppId: string = "APP2";
  public static ClientAppIdNone: string = "None";

  // MFE Constants
  public static hostEventName: string = "NGD_EVENT";
  public static remoteEventName: string = "NGD_NOTIFY";
  public static startRemoteEventName: string = "NGD_REMOTE";
  public static elementIdPrefix: string = "single-spa-application:";

  public static MaxMonthlyAmountLimit: number = 7500;

  //click to sign actions
  public static printPDF: string = "printPDF";
  public static displayPDF: string = "displayPDF";
  public static showComplete: string = "showComplete";
  public static showMissOther: string = "showMissOther";
  public static displayChkBox: string = "displayChkBox";
  public static displayAgreeCancel: string = "displayAgreeCancel";
  public static displayDashboard: string = "displayDashboard";
  public static displaySignMsg: string = "displaySignMsg";
  public static displayMsgCenter: string = "displayMsgCenter";
  //ChatBot Constants
  public static brandColor: string = "#2767db";
  public static rootElementId: string = "root-chatbot";

  //PayOff Constants
  public static payOffThresholdAmount: number = 30000;

  //Language
  public static languageParam: "language";
}

export class HTMLConstants {
  public static getWhiteSpaces(numberOfSpaces: number): string {
    let whiteSpaces: string = "";
    while (numberOfSpaces >= 1) {
      whiteSpaces = whiteSpaces + "\xa0";
      numberOfSpaces--;
    }
    return whiteSpaces;
  }

  public static removeExtraSpaces(
    inputString: string,
    spacesToRemove: number
  ): string {
    let whiteSpaces: string = "";
    while (spacesToRemove >= 1) {
      whiteSpaces = whiteSpaces + "\xa0";
      spacesToRemove--;
    }
    return inputString.replace(whiteSpaces, "\xa0");
  }
}
