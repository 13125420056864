import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StringModel } from '../../../_models/string.model';

@Injectable()
export class InitiationIdActions {
  static SET_INITIATION_ID: string = 'SET_INITIATION_ID';
  static CLEAR_INITIATION_ID: string = 'CLEAR_INITIATION_ID';

  setInitiationId(initiationId: string): FSA<string, string, null> {
    return {
      type: InitiationIdActions.SET_INITIATION_ID,
      payload: initiationId,
      meta: null
    };
  }

  clearInitiationId(): FSA<string, string, null> {
      return {
          type: InitiationIdActions.CLEAR_INITIATION_ID,
          payload: null,
          meta: null
      };
  }
}

@Injectable()
export class InitiationIdReducers {
  static InitiationIdReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
    switch (action.type) {
      case InitiationIdActions.SET_INITIATION_ID:
            return tassign(state, new StringModel(action.payload));
      case InitiationIdActions.CLEAR_INITIATION_ID:
          return tassign(undefined);
      default:
        return state;
    }
  }
}
