export class TaxJurisdictionModel {
  taxCounties: TaxCounty[];
  taxCounty: TaxCounty;
  selectedTaxCounty: TaxCounty;
  address: string;
  city: string;
  state: string;
  zip: string;
  currentPayment: number;
  basePayment: number;
  salesTax: number;
  newPayment: number;
  singleCharge: number;
  emailAddress: string;
  movePackFee: number;
  movePackSelected: boolean;
  movePackSubtitle: string;
  emailConfirmationSelected: boolean;
  error: boolean;
  errorMessage: string;

  constructor(
      taxCounties?: TaxCounty[],
      taxCounty?: TaxCounty,
      address?: string,
      city?: string,
      state?: string,
      zip?: string,
      selectedTaxCounty?: TaxCounty,
      currentPayment?: number,
      basePayment?: number,
      salesTax?: number,
      newPayment?: number,
      singleCharge?: number,
      emailAddress?: string,
      movePackFee?: number,
      movePackSelected?: boolean,
      movePackSubtitle? : string,
      emailConfirmationSelected?: boolean)
  {
      this.taxCounties = taxCounties;
      this.taxCounty = taxCounty;
      this.selectedTaxCounty = selectedTaxCounty;
      this.address = address;
      this.city = city;
      this.state = state;
      this.zip = zip;
      this.currentPayment = currentPayment;
      this.basePayment = basePayment;
      this.salesTax = salesTax;
      this.newPayment = newPayment;
      this.singleCharge = singleCharge;
      this.emailAddress = emailAddress;
      this.movePackFee = movePackFee;
      this.movePackSelected = movePackSelected;
      this.movePackSubtitle = movePackSubtitle;
      this.emailConfirmationSelected = emailConfirmationSelected;
  }

}

export class TaxCounty {
    county: string;
    geocode: string;

    constructor(
        county: string,
        geocode: string,
        ) {
        this.county = county;
        this.geocode = geocode;
    }
}

