import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../../../../shared/constants';
import { AccountInfoService } from '../../../../shared/_helper-services/account-info.service';
import { CustomerACHService } from '../../../../_web-services/customer-ach.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ScheduledItemDTO, HoldInfoDTO } from '../../../../core/gateway-api';
import { SchedulePaymentService } from '../../../../_web-services/schedule-payment.service';
import { SchedulePaymentModel } from '../../../../_models/recurring-payment/schedule-payment.model';
import { RecurringPaymentHoldsActions } from '../../../../shared/store/reducers/recurring-payment-holds.reducer';
import { UserService } from '../../../../shared/_helper-services/user.service';
import { LogSiteActivityService } from '../../../../_web-services/log-site-activity.service';
import { ActivityTypes } from '../../../../shared/enums';
import { RecurringPaymentHoldsModel } from '../../../../_models/recurring-payment/recurring-payment-holds.model';
import { Observable, forkJoin } from 'rxjs';
import { RouterService } from '../../../../shared/_helper-services/router.service';


@Component({
  selector: 'toggle-recurring-payment',
  templateUrl: './recurring-payment-toggle.component.html',
  styleUrls: ['./recurring-payment-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToggleRecurringPayment extends BaseApplicationComponent<ToggleRecurringPayment> {
  public autopayPaused: boolean = false;
  public resumeAutopayDate: Date;
  public cancelRecurringPaymentError: boolean;
  public upComingEasyPayDates: Date[] = [];
  public accountNumber: string;
  public pauseParagraph1Txt: string;
  public pauseParagraph2Txt: string;
  public pauseStartDate: any;
  public currentDate: Date;
  private holdStartDate: Date;
  private holdEndDate: Date;

  constructor(
    public accountInfoService: AccountInfoService,
    public customerACHService: CustomerACHService,
    private translateService: TranslateService,
    private schedulePaymentService: SchedulePaymentService,
    private recurringPaymentHoldsActions: RecurringPaymentHoldsActions,
    private userService: UserService,
    private logSiteActivityService: LogSiteActivityService,
    public datePipe: DatePipe,
    private routerService: RouterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ToggleRecurringPayment>, @Inject(DOCUMENT) document,
    injector: Injector
  ) {
    super(injector, document);
  }

  ngOnInit() {
    this.currentDate = new Date();
    this.setDataLoadedOnRequest(false);
    this.setDate();

    this.schedulePaymentService.findUpComingEasyPaySchedule(this.accountNumber).subscribe(response => {
      this.postFindUpComingEasyPaySchedule(response);
    });


  }

  public setDate(): void {
    if (this.data) {
      this.autopayPaused = !this.data.isPlaceHold;
      this.accountNumber = this.data.accountNo;

      if (!this.autopayPaused) {
        this.pauseStartDate = this.datePipe.transform(this.data.nextPaymentDueDate, Constants.datePipeLongFormat);
        this.setAutoPayPauseData()
        this.schedulePaymentService.findUpComingEasyPaySchedule(this.accountNumber).subscribe(response => {
          this.postFindUpComingEasyPaySchedule(response);
        });
      }
      else {
        if (this.data.holdEndDate) {
          this.holdEndDate = new Date(this.data.holdEndDate);
          this.setAutoPayResumeText();

        }
      }
    }
  }

  public setAutoPayPauseData() {
    this.translateService.get('ngw.recurring-payment.toggle.paragraph1-pause', { pauseStartDate: this.pauseStartDate }).subscribe((res: string) => {
      this.pauseParagraph1Txt = res;
    });
  }

  public setAutoPayResumeText() {
    this.translateService.get('ngw.recurring-payment.toggle.paragraph1-resume', { pauseEndDate: this.datePipe.transform(this.holdEndDate, Constants.datePipeLongFormat) }).subscribe((res: string) => {
      this.pauseParagraph1Txt = res;
    });
    this.translateService.get('ngw.recurring-payment.toggle.paragraph2-resume', { pauseEndDate: this.datePipe.transform(this.holdEndDate, Constants.datePipeLongFormat) }).subscribe((res: string) => {
      this.pauseParagraph2Txt = res;
    });
  }

  private postFindUpComingEasyPaySchedule(response: SchedulePaymentModel) {
    if (response && response.response && response.response.scheduledItems && !response.error) {

      this.upComingEasyPayDates = _.map(response.response.scheduledItems, item => {
        return item.scheduledDate;
      });
      this.upComingEasyPayDates = _.sortBy(_.filter(this.upComingEasyPayDates, item => {
        return item > this.currentDate;
      }), item => { return item; });


      if (this.autopayPaused) {
        this.resumeAutopayDate = _.find(this.upComingEasyPayDates, item => {
          return item.getTime() == this.holdEndDate.getTime();
        });
      }
      else {
        this.upComingEasyPayDates = _.takeRight(this.upComingEasyPayDates, this.upComingEasyPayDates.length - 1);
        this.resumeAutopayDate = this.upComingEasyPayDates[0];
        this.translateService.get('ngw.recurring-payment.toggle.paragraph2-pause', { pauseStartDate: this.pauseStartDate, pauseEndDate: this.datePipe.transform(this.resumeAutopayDate, Constants.datePipeLongFormat) }).subscribe((res: string) => {
          this.pauseParagraph2Txt = res;
        })
      }
    }

    this.setDataLoadedOnRequest(true);
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }

  public cancelToggleRecurringPayment() {
    this.closeDialog()
  }

  public pauseRecurringPayment(autopayPaused: boolean) {
    this.setDataLoadedOnRequest(false);
    this.cancelRecurringPaymentError = false;
    if (!autopayPaused) {
      this.analytics.pushSiteToolEvent({ siteTool: Constants.EasyPayPause, siteToolStep: Constants.SiteToolComplete });
      let autoPayResumeDate = new Date(this.resumeAutopayDate);
      this.placeHold(autoPayResumeDate, autopayPaused);
    }
    else {
      this.analytics.pushSiteToolEvent({ siteTool: Constants.EasyPayResume, siteToolStep: Constants.SiteToolComplete });
      let recurringPaymentHold: RecurringPaymentHoldsModel[];
      this.store.select(state => state.RecurringPaymentHolds).subscribe(x => recurringPaymentHold = x);
      let recurringPaymentHoldsModel: RecurringPaymentHoldsModel = _.find(recurringPaymentHold, item => {
        return item.accountNumber == this.accountNumber;
      });

      let promisesList = new Array();
      let userID: string;
      this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userID = x);
      _.each(recurringPaymentHoldsModel.holds, item => {
        promisesList.push(this.customerACHService.removeHold(this.accountNumber, this.removeTimeStamp(item.effectiveDate), this.removeTimeStamp(item.expirationDate), userID));
      });

      forkJoin(promisesList).subscribe(responses => { this.afterResumeSucess(responses, autopayPaused); })
    }
  }

  public afterPauseRecurringPaymentSucess(response: boolean, autopayPaused: boolean) {
    if (response) {
      this.logUserActivity(this.userService.getCustomerNumber(), this.accountNumber, autopayPaused);
      this.customerACHService.getHolds(this.accountNumber, true).subscribe(response => { this.closeDialog(); })
    }
    this.cancelRecurringPaymentError = !response;
    this.setDataLoadedOnRequest(true);
  }

  logUserActivity(customerNumber: number, accountNumber: string, autopayPaused: boolean) {
    this.logSiteActivityService.logUserActivity(customerNumber, accountNumber, autopayPaused ? ActivityTypes.ResumeRecurringPayment : ActivityTypes.PauseRecurringPayment);
  }

  public afterResumeSucess(responses: any, autopayPaused: boolean) {
    if (responses && _.find(responses, response => !response) === undefined) {
      let autoPayResumeDate = new Date(this.resumeAutopayDate);
      this.placeHold(autoPayResumeDate, autopayPaused);
    }
    else {
      this.cancelRecurringPaymentError = true;
      this.setDataLoadedOnRequest(true);
    }

  }

  public placeHold(autoPayResumeDate: Date, autopayPaused: boolean) {
    let userID: string;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userID = x);
    this.customerACHService.placeHold(this.accountNumber, this.removeTimeStamp(this.currentDate), this.removeTimeStamp(new Date(autoPayResumeDate.setDate(autoPayResumeDate.getDate() - 1))),
      userID).subscribe(response => {
        this.afterPauseRecurringPaymentSucess(response, autopayPaused);
      });
  }

  public onSelectionChange(event: any) {
    if (event && event.value) {
      this.holdEndDate = new Date(event.value);
      if (this.autopayPaused) {
        this.setAutoPayResumeText();
      }
      else {
        this.translateService.get('ngw.recurring-payment.toggle.paragraph2-pause', { pauseStartDate: this.pauseStartDate, pauseEndDate: this.datePipe.transform(this.holdEndDate, Constants.datePipeLongFormat) }).subscribe((res: string) => {
          this.pauseParagraph2Txt = res;
        });
      }
    }
  }

  public removeTimeStamp(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public navigateToContactUs() {
    this.dialogRef.close(Constants.ContactUsComponentName);
  }
}
