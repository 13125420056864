import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { PaymentDeferralEligibilityModel } from '../../../_models/payment-deferral/payment-deferral-eligibility.model';


@Injectable()
export class PaymentDeferralEntryActions {
  static SET_PAYMENT_DEFERRAL_ENTRY_DETAILS: string = 'SET_PAYMENT_DEFERRAL_ENTRY_DETAILS';
  static CLEAR_PAYMENT_DEFERRAL_ENTRY_DETAILS: string = 'CLEAR_PAYMENT_DEFERRAL_ENTRY_DETAILS';

  setPaymentDeferralEntry(paymentDeferralEntry: PaymentDeferralEligibilityModel): FSA<string, PaymentDeferralEligibilityModel, null> {
    return {
      type: PaymentDeferralEntryActions.SET_PAYMENT_DEFERRAL_ENTRY_DETAILS,
      payload: paymentDeferralEntry,
        meta: null
    };
  }

  clearPaymentDeferralEntry(): FSA<string, PaymentDeferralEligibilityModel, null> {
      return {
        type: PaymentDeferralEntryActions.CLEAR_PAYMENT_DEFERRAL_ENTRY_DETAILS,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class PaymentDeferralEntryReducers {
  static PaymentDeferralEntryReducer: Reducer<PaymentDeferralEligibilityModel> = (state: PaymentDeferralEligibilityModel = new PaymentDeferralEligibilityModel(), action: FSA<string, PaymentDeferralEligibilityModel, void>) => {
        switch (action.type) {
          case PaymentDeferralEntryActions.SET_PAYMENT_DEFERRAL_ENTRY_DETAILS:
                return tassign(state, action.payload);
          case PaymentDeferralEntryActions.CLEAR_PAYMENT_DEFERRAL_ENTRY_DETAILS:
                return tassign(undefined);

            default:
                return state;
        }
    }
}
