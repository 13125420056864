import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { PaymentConfirmationComponent } from '../feature/payment/_components/payment-confirmation/payment-confirmation.component';


export const PaymentConfirmationDeactivateGuard: CanDeactivateFn<any> = (target:PaymentConfirmationComponent) => {
    if (target.isAllowToBackward())
        return false;
    return true;


}