import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { PaymentReviewDetails } from '../../../../_models/payment.model';
import { RouterService } from '../../../../shared/_helper-services/router.service';
import { IAppState } from "../../../../shared/store/app.store";

export const PaymentConfirmationResolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {

  const store = inject(Store<IAppState>);
  const routerService = inject(RouterService);

  let paymentReviewDetails: PaymentReviewDetails;
  store.select(state => state.PaymentReviewDetails).subscribe(x => paymentReviewDetails = x);
  if (!paymentReviewDetails || (paymentReviewDetails && !paymentReviewDetails.confirmationNumber)) {
    routerService.navigateToHome();
    return;
  }

};
