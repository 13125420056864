import { Component, OnInit, ViewChild, Inject, Injector, HostListener, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatStepper } from '@angular/material/stepper';
import { RouterService, routes } from "../../../../shared/_helper-services/router.service";
import { DOCUMENT } from "@angular/common";
import { BaseApplicationComponent } from "../../../../core/basecomponent/base.component";
import { DialogService } from "../../../../shared/_helper-services/dialog.service";
import { UntypedFormGroup } from "@angular/forms";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { SelectMiles } from '../../../../_models/map/select-miles.model';
import { SelectMilesActions } from '../../../../shared/store/reducers/select-miles.reducers';
import { MileageAdjustmentHelperService } from "../../../../shared/_helper-services/mileage-adjustment-helper.service";
import { MileageEntryDialogData, MileageEntryDialogDataNavigationType } from "../../../../_models/map/mileage-entry-dialog-data.model";
import { Constants } from '../../../../shared/constants';

@Component({
  selector: "mileage-adjustment-entry-dialog",
  templateUrl: "./mileage-adjustment-entry-dialog.component.html",
  styleUrls: ["./mileage-adjustment-entry-dialog.component.scss"]
})
export class MileageAdjustmentEntryDialog extends BaseApplicationComponent<MileageAdjustmentEntryDialog>
  implements OnInit {
  enterMiles: UntypedFormGroup;
  currentMiles: UntypedFormGroup;
  selectMiles: UntypedFormGroup;
  totalMiles: UntypedFormGroup;
  preferredPaymentOption: UntypedFormGroup;
  cancelPurchaseMiles: UntypedFormGroup;
  quoteSaved: UntypedFormGroup;
  milesEntered: boolean = false;
  showModifyButton: boolean = false;
  showSkipButton: boolean = true;
  hideActionsBar: boolean = true;
  selectMilesSubscription: any;
  selectMilesData: SelectMiles;
  displayAccountInEligible: boolean = false;

  constructor(
    private routerService: RouterService,
    private mileageAdjustmentHelperService: MileageAdjustmentHelperService,
    private dialogRef: MatDialogRef<MileageAdjustmentEntryDialog>,
    private selectMilesActions: SelectMilesActions,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector
  ) {
    super(injector, document);
    this.selectMilesSubscription = this.store.select(state => state.SelectMiles)
      .subscribe(selectMiles => this.setSelectMilesData(selectMiles));
  }

  ngOnInit() {
    this.displayAccountInEligible = this.mileageAdjustmentHelperService.accountIsInElligibleForMAP();
    this.analytics.pushSiteToolEvent({
      siteTool: Constants.PurchaseDiscountedMiles,
      siteToolStep: Constants.SiteToolStart,
      source: Constants.MileageAdjustmentGeneralSource,
    });
  }

  ngOnDestroy() {
    if (this.selectMilesSubscription) {
      this.selectMilesSubscription.unsubscribe();
    }
  }

  setSelectMilesData(selectMiles: SelectMiles): void {
    this.selectMilesData = selectMiles;
  }

  public changedStep(event) {
    if (event.selectedIndex == 1 || event.selectedIndex == 3 || event.selectedIndex == 4) {
      this.hideActionsBar = false;
    } else {
      this.hideActionsBar = true;
    }
    if (event.selectedIndex > 2) {
      this.showModifyButton = true;
      this.showSkipButton = false;
    } else {
      this.showModifyButton = false;
      this.showSkipButton = true;
    }
  }

  public modifyStepper(stepper: MatStepper) {
    if (stepper.selectedIndex === 3)
      stepper.selectedIndex = 1;
    else
      stepper.selectedIndex = stepper.selectedIndex - 1;
  }

  public closeMileageAdjustmentDialog(): void {
    this.dialogRef.close(true);
  }

  public navigateToMileageAdjustmentReview(): void {
    let dialogData = new MileageEntryDialogData(true, MileageEntryDialogDataNavigationType.MAPReview);
    this.dialogRef.close(dialogData);
  }

  public skipEnterMiles(stepper: MatStepper): void {
    this.store.dispatch(this.selectMilesActions.clearSelectMiles());
    stepper.selectedIndex = 3;
  }

  public activeBullet(stepper: MatStepper, activebul) {
    if (stepper.selectedIndex + 1 == activebul) {
      return true;
    } else {
      return false;
    }
  }
}
