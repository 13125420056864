import { Component, OnInit, ViewChild, Inject, Injector, HostListener, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterService, routes } from '../../../../shared/_helper-services/router.service';
import { ContractAccountDetailDTO } from '../../../../core/gateway-api';
import { Constants, HTMLConstants } from '../../../../shared/constants';
import { LogSiteActivityService } from '../../../../_web-services/log-site-activity.service';
import { DOCUMENT } from '@angular/common';
import { BaseApplicationComponent } from "../../../../core/basecomponent/base.component";
import { ActivityTypes, UserAction } from '../../../../shared/enums';
import { UserService } from '../../../../shared/_helper-services/user.service';
import { DueDateChangeEntryModel, GetDueDateErrorType, ValidateDueDateErrorType } from '../../../../_models/due-date-change/due-date-change-entry.model';
import { PaymentDueDateService } from '../../../../_web-services/payment-due-date.service';
import { ObjectService } from '../../../../shared/_helper-services/object.service';
import { AccountInfoService } from '../../../../shared/_helper-services/account-info.service';
import { DueDateChangeEntryActions } from '../../../../shared/store/reducers/due-date-change-entry.reducer';
import { DueDateDialogDataModel, DueDateDialogDataNavigationType } from '../../../../_models/due-date-change/due-date-dialog-data.model';
import { WindowService } from '../../../../shared/_helper-services/window.service';

@Component({
  selector: 'due-date-change',
  templateUrl: './due-date-change-dialog.component.html',
  styleUrls: ['./due-date-change-dialog.component.scss']
})


export class DueDateChangeDialog extends BaseApplicationComponent<DueDateChangeDialog> implements OnInit {

  constructor(private windowService: WindowService,
    private translateService: TranslateService,
    private dueDateService: PaymentDueDateService,
    private logSiteActivityService: LogSiteActivityService,
    private objectService: ObjectService,
    private userService: UserService,
    private accountInfoService: AccountInfoService,
    private dueDateChangeEntryActions: DueDateChangeEntryActions,
    private dialogRef: MatDialogRef<DueDateChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector) {
    super(injector, document);
    this.store.select(state => state.ApplicationConfig.FS_ACCOUNT_HELP_PHONE_NUMBER).subscribe(x => this.fsAccountHelpPhoneNumber = x);
  }

  error: boolean;
  hasFault: boolean;
  public contractDetail: ContractAccountDetailDTO;
  public duedateModel: DueDateChangeEntryModel;
  isLeaseAccount: boolean;
  fsAccountHelpPhoneNumber: string;
  scrollToTop: boolean = false;
  @ViewChild('dueDateChangeTopDiv', { read: ElementRef }) dueDateChangeTopDiv: any;

  ngOnInit() {
    super.pushDataLayer();
    this.scrollToTop = true;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    if (this.contractDetail) {
      this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.contractDetail.accountNumber, ActivityTypes.ChangeDueDateInformational)
    }
    this.isLeaseAccount = this.accountInfoService.isLeaseAccount(this.contractDetail.portfolioCategoryCode);
    this.error = undefined;
    let accountNumber = this.contractDetail.accountNumber;
    let clientAPPId: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPId = x);
    super.getLoggerForComponent(this, clientAPPId)
      .then(rtrn => {
        this.logger = rtrn;
        this.getDueDateData();
      });
  }

  private getDueDateData(): void {
    this.setDataLoadedOnRequest(false);
    if (!this.objectService.isEmptyObject(this.contractDetail)) {
      this.dueDateService.getPaymentDueDate(this.contractDetail.accountNumber)
        .subscribe(response => {
          this.postGetPaymentDueDate(response);
        });
    }
  }
  private postGetPaymentDueDate(response: DueDateChangeEntryModel): void {
    if (response && !response.error) {
      this.error = false;
      this.duedateModel = response;
      this.store.dispatch(this.dueDateChangeEntryActions.setDueDateChangeEntry(this.duedateModel));
    }
    else {
      this.handleGetDueDateError(response);
    }
    this.setDataLoadedOnRequest(true);
    this.windowService.scrollDialogToTop(this.dueDateChangeTopDiv)

  }

  private handleGetDueDateError(result: DueDateChangeEntryModel) {
    this.error = true;
    this.hasFault = result.getDueDateErrorType !== GetDueDateErrorType.GetDueDateFailed;
    this.logger.logcritical(["Error occurred in: " + Constants.DueDateChangeDialogName + ", fault: " + result ? result.getDueDateErrorType : Constants.EMPTY]);
  }
  public navigateToDueDateEntry() {
    let dialogData: DueDateDialogDataModel = new DueDateDialogDataModel(DueDateDialogDataNavigationType.DueDateEntry);
    this.dialogRef.close(dialogData);
  }
  public navigateToPaymentDetails() {
    let dialogData: DueDateDialogDataModel = new DueDateDialogDataModel(DueDateDialogDataNavigationType.PaymentDetails);
    this.dialogRef.close(dialogData);
  }
  public navigateToContactUs(): void {
    let dialogData: DueDateDialogDataModel = new DueDateDialogDataModel(DueDateDialogDataNavigationType.ContactUs);
    this.dialogRef.close(dialogData);
  }
  confirmUserAction() {
    this.dialogRef.close(true);
  }

  cancelUserAction() {
    this.dialogRef.close();
  }

}
