import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BooleanModel } from '../../../_models/boolean.model';
import { FilterService } from '../../_helper-services/filter.service';
import { RouterService, route } from '../../_helper-services/router.service';
import { IAppState } from "../../store/app.store";
import { CanNavigateBackActions } from '../../store/reducers/can-navigate-back.reducer';
import { LanguageService } from './../../_helper-services/language.service';
import { UserService } from './../../_helper-services/user.service';
import { FooterMenuItem, Language, selectedLanguageIndexCode } from './../../enums';
import { HeaderHelperService } from '../../_helper-services/header-helper.service';

@Component({
  selector: 'app-footer-mini',
  templateUrl: './footer-mini.component.html',
  styleUrls: ['./footer-mini.component.scss']
})
export class FooterMiniComponent implements OnInit {
  public currentDate: Date;
  public privacyPolicyUrl: string;
  public myMiniUrl: string;
  public data: any;
  public termsRoute: any;
  public allMenuItem: any[];
  public footerMenuList: any[] = [];
  public selectedIndex: number;
  public epassInitializedSubscribe: any;

  constructor(private userService: UserService,
    private translateService: TranslateService,
    private store: Store<IAppState>,
    private canNavigateBackActions: CanNavigateBackActions,
    private languageService: LanguageService,
    private routerService: RouterService,
    private headerHelperService: HeaderHelperService,
    private filterService: FilterService) {
    this.currentDate = new Date();
  }

  ngOnInit() {
    this.termsRoute = this.routerService.getTermsView();
    this.filterFooterMenu();
    this.getExternalLinks();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setSelectedLanguageIndex(event.lang);
      this.getExternalLinks();
    });
  }

  private filterFooterMenu(): void {
    let footer_menu_Item: string;
    this.store.select(state => state.ApplicationConfig.FOOTER_MENU_ITEM).subscribe(x => footer_menu_Item = x);
    this.allMenuItem = JSON.parse(footer_menu_Item);
    this.epassInitializedSubscribe = this.store.select(state => state.IsEpassInitialized).subscribe(
      (value: BooleanModel) => {
        if (value?.booleanValue) {
          this.footerMenuList = this.filterService.filterFooterMenu(this.allMenuItem);
        }
        else {
          this.footerMenuList = this.filterService.filterFooterMenu(this.allMenuItem, FooterMenuItem.cookiePolicyLink);
        }
      }
    )

    let currentLang = this.languageService.getCurrentLanguage();
    this.setSelectedLanguageIndex(currentLang);
  }

  private getExternalLinks() {
    this.translateService.get('ngw.footer.links.my-bmw-url').subscribe(value => { this.myMiniUrl = value });
    this.translateService.get('ngw.footer.links.privacy-policy-url').subscribe(value => { this.privacyPolicyUrl = value });
  }

  pushMenuItem(item) {
    this.footerMenuList.push(item);
  }

  isLoggedIn(): boolean {
    return this.userService.isAuthenticated();
  }

  navigateToTerms(): void {
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.routerService.navigateToNewTabWithParam(route.termsView, this.languageService.getLanguageParameter());
  }

  navigateToPrivacyLink(): void {
    window.open(this.privacyPolicyUrl);
  }

  navigateToFAQ(): void {
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.routerService.navigateToFAQ();
  }

  navigateToCookiePolicy() {
    this.routerService.navigateToCookiePolicy();
  }

  navigateToContactUs(): void {
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.routerService.navigateToContactUs();
  }

  navigateToSite(): void {
    window.open(this.myMiniUrl);
  }

  navigateToUnsubscribe(): void {
    let unsubscribeUrl: string;
    this.translateService.get('ngw.footer.links.unsubscribe-url').subscribe(value => { unsubscribeUrl = value });
    window.open(unsubscribeUrl);
  }

  toogleToEnglish(index: number) {
    this.languageService.setCurrentLanguage(Language.English);
    this.selectedIndex = index;
    this.handleNavigationOnLangChange();
  }

  toogleToFrench(index: number) {
    this.languageService.setCurrentLanguage(Language.French);
    this.selectedIndex = index;
    this.handleNavigationOnLangChange();
  }

  handleNavigationOnLangChange() {
    if (this.userService.hasCheckedClientDeviceToken() === true) {
      this.headerHelperService.setRouteHistory({internalRoutes: [], externalRoutes: null});
      this.headerHelperService.languageChanged = true;
      this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
      this.routerService.navigateToHome();
    }
  }

  public isActive(index: number): boolean {
    return this.selectedIndex === index;
  }

  setSelectedLanguageIndex(currentLang: string) {
    switch (currentLang) {
      case Language.English:
        this.selectedIndex = selectedLanguageIndexCode.english;
        break;
      case Language.French:
        this.selectedIndex = selectedLanguageIndexCode.french;
        break;
    }
  }
  
}
