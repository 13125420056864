import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, Observable, throwError } from 'rxjs';
import { Constants } from '../constants';

@Injectable()
export class FileLoaderService {

  constructor(private http: HttpClient) { }


  public getFileContent(fileUrl: string): Observable<any> {
    let fileType = this.getFileType(fileUrl);
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": fileType
      })
    };

    return this.http.request("get", fileUrl, options_).pipe(mergeMap((response_: any) => {
      return this.processFileContent(response_);
    }), catchError((response_: any) => {
      if (response_ instanceof Response) {
        try {
          return this.processFileContent(<any>response_);
        } catch (e) {
          return <Observable<any>><any>throwError(e);
        }
      } else
        return <Observable<any>><any>throwError(response_);
    }));
  }

  protected processFileContent(response: HttpResponseBase): Observable<any> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob)
    }
  }

  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;


  private getFileType(fileUrl: string): string {
    let extension = fileUrl.substring(fileUrl.lastIndexOf('.'));
    switch (extension) {
      case Constants.FileExtensionHtml:
        return Constants.FileTypeText
      case Constants.FileExtensionText:
        return Constants.FileTypeText
      case Constants.FileExtensionJson:
        return Constants.FileTypeJson
      default:
        return ""
    }
  }
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = function () {
        observer.next(this.result);
        observer.complete();
      }
      reader.readAsText(blob);
    }
  });
}
