<div class="legal-agreement-paperless-container">
  <div class="banner-content">
    <span class="model-name">{{contractYearAndModel}}</span>
    <span class="account-number">{{contractTypeAndNumber}}</span>
    <div class="banner-img">
      <img src={{vehicleImageData}} onerror="this.src = '../../../../assets/images/car-silhouette.png'" alt="{{'ngw.paperless-enrollment.banner-content.banner-img-alt' | translate}}" />
    </div>
  </div>
  <div class="confirmation-text" *ngIf="toggle.checked">
    <h2>{{'ngw.paperless-enrollment.confirmation-text.switch-on.title' | translate}}</h2>
    <p>{{primaryEmailTextMsg}}</p>
  </div>
  <div class="confirmation-text" *ngIf="!toggle.checked" [ngClass]="{'custom-style' : !toggle.checked}">
    <h2>{{'ngw.paperless-enrollment.confirmation-text.switch-off.title' | translate}}</h2>
    <p>
      {{'ngw.paperless-enrollment.confirmation-text.switch-off.description.text-1' | translate}}
      <span class="confirmation-text-1">{{'ngw.paperless-enrollment.confirmation-text.switch-off.description.text-2' | translate}}</span>
      {{'ngw.paperless-enrollment.confirmation-text.switch-off.description.text-3' | translate}}
      <span class="confirmation-text-2">{{'ngw.paperless-enrollment.confirmation-text.switch-off.description.text-4' | translate}}</span>
    </p>
  </div>
  <div class="switch-section" [ngClass]="{'custom-style-switch' : !toggle.checked}">
    <div class="switch-section-text">
      <h5>{{'ngw.paperless-enrollment.switch.title' | translate}}</h5>
      <span *ngIf="toggle.checked">{{'ngw.paperless-enrollment.switch.description-off' | translate}}</span>
      <span *ngIf="!toggle.checked">{{'ngw.paperless-enrollment.switch.description-on' | translate}}</span>
    </div>
    <mat-slide-toggle class="switch-slide-toggle" #toggle [checked]="toggled" (change)="onToggle($event)"></mat-slide-toggle>
  </div>
</div>
