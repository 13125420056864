import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { FinancialProductDTO } from '../../../core/gateway-api';

@Injectable()
export class FinancialProductsActions {
    static SET_FINANCIAL_PRODUCTS: string = 'SET_FINANCIAL_PRODUCTS';

    setFinancialProducts(financialProducts: FinancialProductDTO[]): FSA<string, FinancialProductDTO[], null> {
        return {
            type: FinancialProductsActions.SET_FINANCIAL_PRODUCTS,
            payload: financialProducts,
            meta: null
        };
    }


}

@Injectable()
export class FinancialProductsReducers {
    static FinancialProductsReducer: Reducer<FinancialProductDTO[]> = (state: FinancialProductDTO[] = [], action: FSA<string, FinancialProductDTO[], void>) => {
        switch (action.type) {
            case FinancialProductsActions.SET_FINANCIAL_PRODUCTS:
                return tassign(state, action.payload);
            default:
                return state;
        }
    }
}
