import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { FeatureIndicatorService } from '../_web-services/feature-indicator.service';
import { FeatureIndicatorNames } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";
import { FeatureIndicatorActions } from '../shared/store/reducers/feature-indicator.reducers';
import { UserService } from '../shared/_helper-services/user.service';

export const EndOfTermFeatureIndicatorResolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const userService = inject(UserService);
 
  if(userService.isAuthenticated()){
    const store = inject(Store<IAppState>);
    const featureIndicatorService = inject(FeatureIndicatorService);
    const featureIndicatorActions = inject(FeatureIndicatorActions);
    return featureIndicatorService.getFeatureIndicatorByClientId(FeatureIndicatorNames.EndOfTerm).pipe(map(endOfTerm => {
      store.dispatch(featureIndicatorActions.setEndOfTermEnabled(endOfTerm));
    }));
  }
};