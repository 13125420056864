import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { ContractAccountDetailDTO } from '../../../core/gateway-api';

@Injectable()
export class ContractAccountDetailsActions
{
    static SET_CONTRACTS: string = 'SET_CONTRACTS';
    static CLEAR_CONTRACTS: string = 'CLEAR_CONTRACTS';

  setContracts(contracts: ContractAccountDetailDTO[]): FSA<string, ContractAccountDetailDTO[], null>{
    return {
      type: ContractAccountDetailsActions.SET_CONTRACTS,
      payload: contracts,
      meta: null
    };
  }

  clearContracts(): FSA<string, ContractAccountDetailDTO[], null> {
      return {
          type: ContractAccountDetailsActions.CLEAR_CONTRACTS,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class ContractAccountDetailsReducers
{
    static ContractAccountDetailsReducer: Reducer<ContractAccountDetailDTO[]> = (state: ContractAccountDetailDTO[] = [], action: FSA<string, ContractAccountDetailDTO[], void>) => {
    switch(action.type){
        case ContractAccountDetailsActions.SET_CONTRACTS:
            return tassign(state, action.payload);
        case ContractAccountDetailsActions.CLEAR_CONTRACTS:
            return state = [];
        default:
          return state;
    }
  }
}
