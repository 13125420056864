import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ProviderKey, SiteKey, CustomerBulletinServiceClient, GetCustomerBulletinsRequest } from '../core/gateway-api'
import { CustomerBulletinModel } from "../_models/customer-bulletin.model";
import { Store } from '@ngrx/store';
import { IAppState } from "../shared/store/app.store";
import { CustomerBulletinActions } from '../shared/store/reducers/customer-bulletin.reducer';
import { ObjectService } from "../shared/_helper-services/object.service";
import { TranslateService } from '@ngx-translate/core';
import { ApplicationConfig } from '../_models/application-config';

@Injectable()
export class CustomerBulletinService {
  customerBulletin: CustomerBulletinModel;
  currentCulture: string;
  private languageChangeSubscription: any;
  storeAppConfig: ApplicationConfig;
  constructor(private store: Store<IAppState>,
    private customerBulletinServiceClient: CustomerBulletinServiceClient,
    private customerBulletinActions: CustomerBulletinActions,
    private transService: TranslateService,
    private objectService: ObjectService) {
  }

  public getCustomerBulletins(refresh: boolean): Observable<CustomerBulletinModel> {
    let cachedCustomerBulletins = this.getCachedCustomerBulletins();
    if (!cachedCustomerBulletins || this.objectService.isEmptyObject(cachedCustomerBulletins) || refresh) {
      let getCustomerBulletinsRequest = new GetCustomerBulletinsRequest();
      getCustomerBulletinsRequest.culture = this.getCurrentCulture();
      let provideKey = new ProviderKey();
      this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
      provideKey.providerId = this.storeAppConfig.CLIENT_ID;
      getCustomerBulletinsRequest.providerKey = provideKey;

      let siteKey = new SiteKey();
      siteKey.siteId = this.storeAppConfig.SITEID;
      getCustomerBulletinsRequest.siteKey = siteKey;
      return this.customerBulletinServiceClient.getCustomerBulletins(getCustomerBulletinsRequest)
        .pipe(mergeMap((response) => { return this.afterGetCustomerBulletinsSuccess(response); })
          , catchError((error: any) => { return this.afterGetCustomerBulletinsError(error); }));
    }
    return of(cachedCustomerBulletins);
  }

  private getCachedCustomerBulletins(): CustomerBulletinModel {
    let cachedCustomerBulletin: CustomerBulletinModel;
    this.store.select(state => state.CustomerBulletin).subscribe(x => cachedCustomerBulletin = x);
    return cachedCustomerBulletin;
  }

  private afterGetCustomerBulletinsSuccess(result: any): Observable<CustomerBulletinModel> {
    this.customerBulletin = new CustomerBulletinModel();
    this.customerBulletin.error = false;
    this.customerBulletin.customerBulletins = result.customerBulletins;
    this.store.dispatch(this.customerBulletinActions.setCustomerBulletin(this.customerBulletin));
    return of(this.customerBulletin);
  }

  private afterGetCustomerBulletinsError(error: any): Observable<CustomerBulletinModel> {
    this.customerBulletin = new CustomerBulletinModel();
    this.customerBulletin.error = true;
    return of(this.customerBulletin);
  }
  private getCurrentCulture(): string {
    let culture: string;
    this.transService.get("ngw.general.culture").subscribe((res: string) => {
      culture = res;

    });
    return culture;
  }
}
