<div class="modal-consent">
  <h3>{{'ngw.shared.easy-pay-enrollment-dialog.title' | translate}}</h3>
  <p>{{dialogText}}</p>
  <p>{{'ngw.shared.easy-pay-enrollment-dialog.content-text-2' | translate}} <a (click)="navigateToAccountDetails()">{{'ngw.shared.easy-pay-enrollment-dialog.lnk-account-details' | translate}}</a>{{'ngw.shared.easy-pay-enrollment-dialog.content-text-3-dot' | translate}}</p>
  <div class="action-buttons">
    <button id="btnCancel" class="btn btn-secondary" (click)="cancelUserAction(); $event.preventDefault();">
      {{'ngw.shared.easy-pay-enrollment-dialog.btn-cancel' | translate}}</button>
    <button class="btn btn-primary" (click)="confirmUserAction(); $event.preventDefault();" type="submit">{{'ngw.shared.easy-pay-enrollment-dialog.btn-continue' | translate}}</button>
  </div>
</div>
