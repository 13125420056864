import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GuardService } from './../shared/_helper-services/guard.service';
import { UserService } from './../shared/_helper-services/user.service';

export const TwoFactorAuthenticatedGuard: CanActivateFn = () => {
  const guardService = inject(GuardService);
  const userService = inject(UserService);
  if (userService.hasCheckedClientDeviceToken() === true) {
    return guardService.guardSucceded();
  }
  else {
    return guardService.guardFailed("authentication");;
  }
}
