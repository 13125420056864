export class LegitimizationStatus {
  error: boolean;
  errortype: LegitimizationErrorType;
}

export enum LegitimizationErrorType {
  DataValidation,
  NoMatchesFound,
  UniqueMatchNotFound,
  GCIDAlreadyLegitimized,
  OwnershipValidation,
  LegitimizationFailed
}
