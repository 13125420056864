import { Injectable } from '@angular/core';
import { FeatureIndicatorService } from '../../_web-services/feature-indicator.service';
import { FeatureIndicatorTypes } from '../enums';
import { Observable } from 'rxjs';
import { PartnerContactService } from "../../_web-services/partner-contact.service";
import { AddAchPaymentSourceCanModel } from "../../_models/add-ach-payment-source-can.model";
import { Store } from '@ngrx/store';
import { IAppState } from "../store/app.store";
import { UserService } from "./user.service";
import { UploadAttachment } from "../../_models/uploadAttachment.model";
import { Constants } from '../constants';
import {
  DeliverSmcMessageRequestMessageType, DeliverSmcMessageRequestTemplate,
  DeliverSmcMessageRequestSubjectTemplateName
} from '../../core/gateway-api';
import * as _ from "lodash";
import { ApplicationConfig } from '../../_models/application-config';

@Injectable()
export class AddChequingAccountHelperService {
  storeAppConfig: ApplicationConfig;
  constructor(private featureIndicatorService: FeatureIndicatorService, private partnerContactService: PartnerContactService, private store: Store<IAppState>,
    private userService: UserService) {
  }

  public isAddChequingAccountEnabled(): Observable<boolean> {
    return this.featureIndicatorService.getFeatureIndicator(FeatureIndicatorTypes.AddChequingAccountEnabledFlag);
  }

  public submitAddAchRequest(accountNumber: string, addAchPaymentSourceModel: AddAchPaymentSourceCanModel): Observable<AddAchPaymentSourceCanModel> {
    let customerNumber: number = this.userService.getCustomerNumber();
    let brand, country, systemCode,firstName,lastName: string;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    brand = this.storeAppConfig.BRAND;
    systemCode = this.storeAppConfig.SYSTEM;
    this.store.select(state => state.ContractAccountDetail.countryCode.codeValue).subscribe(x => country = x);
    let messageType: string = DeliverSmcMessageRequestMessageType.InBound.toString();
    let template: string = DeliverSmcMessageRequestTemplate.AddPaymentSource.toString();
    let subjectTemplateName: string = DeliverSmcMessageRequestSubjectTemplateName.PAYMENT_SOURCE.toString();
    let metaData = {} as any;

    // Check for attachments
    let addAchPaymentSourceAttachments = {} as any;
    if (addAchPaymentSourceModel.attachment && addAchPaymentSourceModel.attachment.attachments.length > 0) {
      _.each(addAchPaymentSourceModel.attachment.attachments, function (attachment: UploadAttachment) {
        addAchPaymentSourceAttachments[attachment.name] = attachment.base64Data;
      });
    }

    // Add metadata
    metaData[Constants.AccountNumber] = accountNumber;
    metaData[Constants.CustomerNumber] = customerNumber;
    this.store.select(state => state.Contact.firstName).subscribe(x => firstName = x);
    this.store.select(state => state.Contact.lastName).subscribe(x => lastName = x);
    metaData[Constants.CustomerName] = firstName.concat(Constants.whiteSpaceString, lastName);
      
    return this.partnerContactService.deliverSmcMessage(accountNumber
      , customerNumber
      , brand
      , country
      , messageType
      , systemCode
      , template
      , subjectTemplateName
      , addAchPaymentSourceAttachments
      , metaData
      , addAchPaymentSourceModel)
  }


}


