import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { OnBoardngModel } from '../../_models/onboarding-tour.model';
import { Constants } from '../constants';
import { StorageType } from '../../_models/storagetype.model';
import { UiModuleModel } from '../../_models/ui-module.model';
import { UiModuleDTO, UiStepDTO, UiStepDataEntity } from '../../core/gateway-api';
import { StorageHelperService } from './storage-helper.service';
import { UserService } from './user.service';

@Injectable()
export class ModuleOnBoardingHelperService {
  private currentOnBoardingModel: OnBoardngModel;

  constructor(private storageService: StorageHelperService, private userService: UserService) { }

  public getCurrentOnBoardingModuleStatus(response: UiModuleModel): boolean {
    let onboardingModule: UiModuleDTO = _.find(response.uiModules, uiModule => uiModule.name === Constants.OnboardingModuleName);
    let isEsignAccepted: boolean = true;
    let isTermsAccepted: boolean = true;
    let isProfileAccepted: boolean = true;
    let isPaperlessAccepted: boolean = true;
    let paperlessAccount: string = null;
    let agreementsStep: UiStepDTO = _.find(onboardingModule.steps, uiStep => uiStep.name === Constants.AgreementsStepName);
    if (agreementsStep) {
      let isProfileAcceptedData: UiStepDataEntity = _.find(agreementsStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.ProfileStepDataKey : false);
        isProfileAccepted = isProfileAcceptedData && isProfileAcceptedData.mutable && isProfileAcceptedData.mutable.data === Constants.BooleanStepDataValue;
      
      let isEsignAcceptedData: UiStepDataEntity = _.find(agreementsStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.EsignStepDataKey : false);
      isEsignAccepted = isEsignAcceptedData && isEsignAcceptedData.mutable && isEsignAcceptedData.mutable.data === Constants.BooleanStepDataValue;
      let isTermsAcceptedData: UiStepDataEntity = _.find(agreementsStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.TermsStepDataKey : false);
      isTermsAccepted = isTermsAcceptedData && isTermsAcceptedData.mutable && isTermsAcceptedData.mutable.data === Constants.BooleanStepDataValue;
    }
    let paperlessStep = _.find(onboardingModule.steps, uiStep => uiStep.name === Constants.PaperlessStepName);
    if (paperlessStep) {
      let paperlessData: UiStepDataEntity = _.find(paperlessStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.PaperlessStepDataKey : false);
      paperlessAccount = paperlessData && paperlessData.mutable ? paperlessData.mutable.data : null;
      isPaperlessAccepted = paperlessAccount ? true : false;
    }
    let onBoardingModel = new OnBoardngModel(isEsignAccepted, isTermsAccepted, isPaperlessAccepted, paperlessAccount, Constants.OnboardingModuleName, isProfileAccepted);

    this.setCurrentOnBoardingModuleSession(onBoardingModel);

    if (agreementsStep || paperlessStep) {
      return true;
    }


    return false;
  }

  public getCurrentOnBoardingModuleAgreementStatus(response: UiModuleModel): boolean {
    let onboardingModule: UiModuleDTO = _.find(response.uiModules, uiModule => uiModule.name === Constants.OnboardingModuleName);
    let isEsignAccepted: boolean = true;
    let isTermsAccepted: boolean = true;
    let isProfileAccepted: boolean = true;
    let isPaperlessAccepted: boolean = true;
    let paperlessAccount: string = null;
    let agreementsStep: UiStepDTO = _.find(onboardingModule.steps, uiStep => uiStep.name === Constants.AgreementsStepName);
    if (agreementsStep) {
      let isProfileAcceptedData: UiStepDataEntity = _.find(agreementsStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.ProfileStepDataKey : false);
      isProfileAccepted = isProfileAcceptedData && isProfileAcceptedData.mutable && isProfileAcceptedData.mutable.data === Constants.BooleanStepDataValue;
      
      let isEsignAcceptedData: UiStepDataEntity = _.find(agreementsStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.EsignStepDataKey : false);
      isEsignAccepted = isEsignAcceptedData && isEsignAcceptedData.mutable && isEsignAcceptedData.mutable.data === Constants.BooleanStepDataValue;
      let isTermsAcceptedData: UiStepDataEntity = _.find(agreementsStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.TermsStepDataKey : false);
      isTermsAccepted = isTermsAcceptedData && isTermsAcceptedData.mutable && isTermsAcceptedData.mutable.data === Constants.BooleanStepDataValue;
    }
    let paperlessStep = _.find(onboardingModule.steps, uiStep => uiStep.name === Constants.PaperlessStepName);
    if (paperlessStep) {
      let paperlessData: UiStepDataEntity = _.find(paperlessStep.stepDataCollection,
        stepDataEntity => stepDataEntity.key ? stepDataEntity.key.key === Constants.PaperlessStepDataKey : false);
      paperlessAccount = paperlessData && paperlessData.mutable ? paperlessData.mutable.data : null;
      isPaperlessAccepted = paperlessAccount ? true : false;
    }
    let onBoardingModel = new OnBoardngModel(isEsignAccepted, isTermsAccepted, isPaperlessAccepted, paperlessAccount, Constants.OnboardingModuleName, isProfileAccepted);

    this.setCurrentOnBoardingModuleSession(onBoardingModel);

    if (agreementsStep) {
      return true;
    }


    return false;
  }

  private setCurrentOnBoardingModuleSession(data: OnBoardngModel) {
    this.currentOnBoardingModel = data;
    this.storageService.setItem(Constants.ONBOARDING_MODULE_DATA, JSON.stringify(this.currentOnBoardingModel), StorageType.session);
  }

  private geOnBoardingModuleSession(): OnBoardngModel {
    let sessionValue = this.storageService.getItem(Constants.ONBOARDING_MODULE_DATA, StorageType.session);
    if (sessionValue) {
      let sessionUser: OnBoardngModel = JSON.parse(sessionValue);
      if (sessionUser) {
        // logged in so return true
        return sessionUser;
      }
    }
    return null;
  }

  public setTermsAccepted(accepted: boolean): void {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();
    if (this.currentOnBoardingModel) {
      this.currentOnBoardingModel.termsAccepted = accepted;
      this.setCurrentOnBoardingModuleSession(this.currentOnBoardingModel);
    }
    let currentUser = this.userService.getUserFromSession();
    if (currentUser) {
      currentUser.policyConsents.forEach(x => x.isAccepted = accepted);
      this.userService.setCurrentUser(currentUser);
    }
  }

  public setEsignAccepted(accepted: boolean): void {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();
    if (this.currentOnBoardingModel) {
      this.currentOnBoardingModel.esignAccepted = accepted;
      this.setCurrentOnBoardingModuleSession(this.currentOnBoardingModel);
    }
  }

  public setProfileAccepted(accepted: boolean): void {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();
    if (this.currentOnBoardingModel) {
      this.currentOnBoardingModel.profileTermsAccepted = accepted;
      this.setCurrentOnBoardingModuleSession(this.currentOnBoardingModel);
    }
  }

  public setPaperLessPrefrences(accepted: boolean): void {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();
    if (this.currentOnBoardingModel) {
      this.currentOnBoardingModel.paperLessEnrolled = accepted;
      this.setCurrentOnBoardingModuleSession(this.currentOnBoardingModel);
    }
  }

  public setPaperLessPrefrencesAccount(accountNumber: string): void {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();
    if (this.currentOnBoardingModel) {
      this.currentOnBoardingModel.accountNumber = accountNumber;
      this.setCurrentOnBoardingModuleSession(this.currentOnBoardingModel);
    }
  }

  isTermsAccepted(): boolean {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();

    if (this.currentOnBoardingModel) {
      return this.currentOnBoardingModel.termsAccepted;
    } else {
      return false;
    }
  }

  isEsignAccepted(): boolean {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();

    if (this.currentOnBoardingModel) {
      return this.currentOnBoardingModel.esignAccepted;
    } else {
      return false;
    }
  }

  isPaperlessEnrolled(): boolean {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();

    if (this.currentOnBoardingModel) {
      return this.currentOnBoardingModel.paperLessEnrolled;
    } else {
      return false;
    }
  }

  getPaperlessNotEnrolledAccount(): string {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();

    if (this.currentOnBoardingModel) {
      return this.currentOnBoardingModel.accountNumber;
    } else {
      return null;
    }
  }

  getOnBoardingModuleName(): string {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();

    if (this.currentOnBoardingModel) {
      return this.currentOnBoardingModel.moduleName;
    } else {
      return null;
    }
  }
  
  isProfileTermsAccepted(): boolean {
    this.currentOnBoardingModel = this.geOnBoardingModuleSession();

    if (this.currentOnBoardingModel) {
      return this.currentOnBoardingModel.profileTermsAccepted;
    } else {
      return false;
    }
  }
}
