import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { DueDateChangeEntryModel } from '../../../_models/due-date-change/due-date-change-entry.model';


@Injectable()
export class DueDateChangeEntryActions {
    static SET_DUE_DATE_CHANGE_ENTRY_DETAILS: string = 'SET_DUE_DATE_CHANGE_ENTRY_DETAILS';
    static CLEAR_DUE_DATE_CHANGE_ENTRY_DETAILS: string = 'CLEAR_DUE_DATE_CHANGE_ENTRY_DETAILS';

  setDueDateChangeEntry(dueDateChangeEntry: DueDateChangeEntryModel): FSA<string, DueDateChangeEntryModel, null> {
    return {
        type: DueDateChangeEntryActions.SET_DUE_DATE_CHANGE_ENTRY_DETAILS,
        payload: dueDateChangeEntry,
        meta: null
    };
  }

  clearDueDateChangeEntry(): FSA<string, DueDateChangeEntryModel, null> {
      return {
          type: DueDateChangeEntryActions.CLEAR_DUE_DATE_CHANGE_ENTRY_DETAILS,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class DueDateChangeEntryReducers {
    static DueDateChangeEntryReducer: Reducer<DueDateChangeEntryModel> = (state: DueDateChangeEntryModel = new DueDateChangeEntryModel(), action: FSA<string, DueDateChangeEntryModel, void>) => {
        switch (action.type) {
            case DueDateChangeEntryActions.SET_DUE_DATE_CHANGE_ENTRY_DETAILS:
                return tassign(state, action.payload);
            case DueDateChangeEntryActions.CLEAR_DUE_DATE_CHANGE_ENTRY_DETAILS:
                return tassign(undefined);

            default:
                return state;
        }
    }
}
