
export class CreatePaymentDeferralModel {
  postponePaymentDate: Date;
  confirmationNumber: string;
  error: boolean;

  constructor(postponePaymentDate?: Date, confirmationNumber?:string, error?: boolean) {
    this.postponePaymentDate = postponePaymentDate;
    this.confirmationNumber = confirmationNumber;
    this.error = error;
  }


}

