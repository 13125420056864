import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { PaymentEntry, PaymentSource } from '../../../_models/payment.model';
import { tassign } from 'tassign';


@Injectable()
export class PaymentEntryActions {
  static SET_PAYMENT_ENTRY: string = 'SET_PAYMENT_ENTRY';
  static CLEAR_PAYMENT_ENTRY: string = 'CLEAR_PAYMENT_ENTRY';
  static PUSH_PAYMENT_SOURCE: string = 'PUSH_PAYMENT_SOURCE';
  static SET_SELECTED_PAYMENT_SOURCE: string = 'SET_SELECTED_PAYMENT_SOURCE';
  static DELETE_PAYMENT_SOURCE: string = 'DELETE_PAYMENT_SOURCE';
  static SET_PAYMENT_DATE: string = 'SET_PAYMENT_DATE';
  static SET_EMAIL_ADDRESS: string = 'SET_EMAIL_ADDRESS';
  static SET_DUE_DATE: string = 'SET_DUE_DATE';

  setEmailAddress(emailAddress: string): FSA<string, any, null> {
    return {
      type: PaymentEntryActions.SET_EMAIL_ADDRESS,
      payload: emailAddress,
      meta: null
    };
  }

  setPaymentEntry(paymentEntry: PaymentEntry): FSA<string, PaymentEntry, null> {
    return {
      type: PaymentEntryActions.SET_PAYMENT_ENTRY,
      payload: paymentEntry,
      meta: null
    };
  }

  setDueDate(dueDate: Date): FSA<string, any, null> {
    return {
      type: PaymentEntryActions.SET_DUE_DATE,
      payload: dueDate,
      meta: null
    };
  }

  pushPaymentSource(paymentSource: PaymentSource): FSA<string, any, null> {
    return {
      type: PaymentEntryActions.PUSH_PAYMENT_SOURCE,
      payload: paymentSource,
      meta: null
    };
  }

  setSelectedPaymentSource(selectedPaymentSource: PaymentSource): FSA<string, any, null> {
    return {
      type: PaymentEntryActions.SET_SELECTED_PAYMENT_SOURCE,
      payload: selectedPaymentSource,
      meta: null
    };
  }

  deletePaymentSource(financialAccountId: string): FSA<string, any, null> {
    return {
      type: PaymentEntryActions.DELETE_PAYMENT_SOURCE,
      payload: financialAccountId,
      meta: null
    }
  }

  clearPaymentEntry(): FSA<string, any, null> {
    return {
      type: PaymentEntryActions.CLEAR_PAYMENT_ENTRY,
      payload: null,
      meta: null
    };
  }

  setPaymentDate(paymentDate: Date): FSA<string, any, null> {
    return {
      type: PaymentEntryActions.SET_PAYMENT_DATE,
      payload: paymentDate,
      meta: null
    }
  }

}

@Injectable()
export class PaymentEntryReducers {
  static PaymentEntryReducer: Reducer<PaymentEntry> = (state: PaymentEntry = new PaymentEntry(), action: FSA<string, any, void>) => {
    switch (action.type) {
      case PaymentEntryActions.SET_PAYMENT_ENTRY:
        return tassign(state, action.payload);
      case PaymentEntryActions.SET_EMAIL_ADDRESS:
        return {
          ...state,
          emailAddress: action.payload,
          selectedEmailAddress: action.payload
        }
      case PaymentEntryActions.PUSH_PAYMENT_SOURCE:
        return {
          ...state,
          sources: [
            ...state.sources, action.payload
          ], selectedPaymentSource: action.payload
        }
      case PaymentEntryActions.SET_SELECTED_PAYMENT_SOURCE:
        return {
          ...state,
          selectedPaymentSource: action.payload
        }
      case PaymentEntryActions.DELETE_PAYMENT_SOURCE:
        return {
          ...state,
          sources: [
            ...state.sources.filter(item => item.financialAccountId != action.payload)
          ]
        }
      case PaymentEntryActions.CLEAR_PAYMENT_ENTRY:
        return tassign(undefined);
      case PaymentEntryActions.SET_PAYMENT_DATE:
        return {
          ...state,
          paymentdate: action.payload
        }
      case PaymentEntryActions.SET_DUE_DATE:
        return {
          ...state,
          currentDueDate: action.payload
        }
      default:
        return state;
    }
  }
}
