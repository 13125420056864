import { Component, OnInit, OnDestroy, Inject, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AccountInfoService } from "../../../shared/_helper-services/account-info.service";
import { ContractAccountDetailDTO } from '../../../core/gateway-api';
import { MaturityBillingService } from "../../../_web-services/maturity-billing.service";
import { MaturityBillingModel } from "../../../_models/contract/maturity-billing.model";
import { Constants } from "../../constants";
import { DatePipe } from '@angular/common';
import { BaseApplicationComponent } from '../../../core/basecomponent/base.component';

@Component({
  selector: 'app-contract-header-amount-date-due',
  templateUrl: './contract-header-amount-date-due.component.html',
  styleUrls: ['contract-header-amount-date-due.component.scss']
})
export class ContractHeaderAmountDateDueComponent extends BaseApplicationComponent<ContractHeaderAmountDateDueComponent> implements OnInit, OnDestroy {
  amountDue: number;
  contractDetail: ContractAccountDetailDTO;
  private contractAccountDetailSubscription: any;
  maturityBillNetBalanceDueAmount: number;
  private maturityBillDate: string;
  dueDatePastDue: boolean = false;
  constructor(private accountInfoService: AccountInfoService,
    private maturityBillingService: MaturityBillingService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
    this.contractAccountDetailSubscription = this.store.select(state => state.ContractAccountDetail)
      .subscribe(contract => this.setData(contract));
  }

  ngOnInit() {
    this.init();
  }

  public init() {
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    this.setData(this.contractDetail);
  }

  ngOnDestroy() {
    this.destroy();
  }

  public destroy() {
    this.contractAccountDetailSubscription.unsubscribe();
  }


  public setData(contract: ContractAccountDetailDTO) {
    if (this.isTerminatedAccount(contract.statusCategoryCode)) {
      this.maturityBillingService.getMaturityBilling(contract.accountNumber, false, contract)
        .subscribe(response => {
          this.postGetMaturityBilling(response, contract);
        });
    }
    else {
      this.amountDue = this.getAmountDue(contract);
    }
  }

  public getAmountDue(contract: ContractAccountDetailDTO): number {
    return this.accountInfoService.isPastDue(contract.pastDueAmount)
      ? contract.pastDueAmount
      : contract.totalAmountDue >= 0
        ? contract.totalAmountDue
        : 0;
  }
  public hasOutstandingBalance(): boolean {
    return this.amountDue > Constants.DECIMAL_ZERO;
  }

  public isTerminatedAccount(statusCategoryCode: any) {
    return this.accountInfoService.isTerminatedAccount(statusCategoryCode);
  }

  private postGetMaturityBilling(response: MaturityBillingModel, contract: ContractAccountDetailDTO) {
    this.maturityBillNetBalanceDueAmount = Constants.DECIMAL_ZERO;
    if (response && !response.error && response.maturityBilling) {
      let maturityBillAmount = parseFloat(response.maturityBilling.netBalanceDueAmount);
      this.maturityBillNetBalanceDueAmount = isNaN(maturityBillAmount) ? Constants.DECIMAL_ZERO : maturityBillAmount;
      this.maturityBillDueDate(response.maturityBilling.maturityBillinDate);
    }
    this.amountDue = this.getAmountDue(contract);
  }

  public hasMaturityBill() {
    return this.maturityBillNetBalanceDueAmount > Constants.DECIMAL_ZERO;
  }

  maturityBillDueDate(maturityBillinDate) {
    this.dueDatePastDue = false;
    let maturityBillingDate = new Date(maturityBillinDate);
    if (this.accountInfoService.isMaturityBillingPastFifteenDays(maturityBillinDate)) {
      this.dueDatePastDue = true;
      this.maturityBillDate = Constants.EMPTY;
    }
    else {
      let dueDate = new Date(maturityBillingDate.setDate(maturityBillingDate.getDate() + Constants.MaxMaturityBillDueDaysAllowed));
      this.maturityBillDate = this.datePipe.transform(dueDate, this.dateFormat);
    }
  }
}
