import { Component, OnInit, ViewChild, Inject, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterService, routes } from "../../../../shared/_helper-services/router.service";

import { Constants } from '../../../../shared/constants';
import { DOCUMENT } from '@angular/common';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { UserService } from '../../../../shared/_helper-services/user.service';
import { UserIsTimedOutActions } from '../../../../shared/store/reducers/user-is-timed-out.reducer';

@Component({
  templateUrl: './sso-logout.component.html',
  styleUrls: ['./sso-logout.component.scss']
})
export class SsoLogoutComponent extends BaseApplicationComponent<SsoLogoutComponent> implements OnInit {

  public clientId: string;
  public isTimeOut: string;

  constructor(
    public routerService: RouterService,
    @Inject(DOCUMENT) document, public userService: UserService,
    injector: Injector,
    public route: ActivatedRoute, public userIsTimedOutActions: UserIsTimedOutActions) {

    super(injector, document);
    super.setDataLoadedOnRequest(true);
    this.clientId = "";
    this.isTimeOut = "";

  }

  ngOnInit() {
    super.pushDataLayer();

    this.init();
  }

  public init() {
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
      });

    this.clientId = this.route.snapshot.queryParams['client_id'];

    //This is to show the session inactivity error message on login screen.
    this.isTimeOut = this.route.snapshot.queryParams['isTimedOut'];
    if (this.isTimeOut === "true") {
      this.store.dispatch(this.userIsTimedOutActions.setUserIsTimedOut(true));
    }

    this.userService.logout();
    this.routerService.navigateToLogout();
  }

}
