import { trigger, style, animate, transition } from "@angular/animations";

export const UploadAnimation = trigger("animateInOut", [
  transition(":enter", [
    style({ transform: "scale(0.75)", opacity: 0 }), // initial
    animate(
      "0.35s cubic-bezier(.25,.5,.2,1)",
      style({ transform: "scale(1)", opacity: 1 })
    ) // final
  ]),
  transition(":leave", [
    style({ transform: "scale(1)", opacity: 1 }), // initial
    animate(
      "0.35s cubic-bezier(.25,.5,.2,1)",
      style({ transform: "scale(0.75)", opacity: 0 })
    ) // final
  ])
]);
