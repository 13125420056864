import { Component, OnInit, OnDestroy, Renderer2, Inject, Injector, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { SelectMiles } from '../../../../../../_models/map/select-miles.model';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { FaultCodes } from '../../../../../../shared/FaultCodes';

@Component({
  selector: 'app-exceed-total-miles',
  templateUrl: './exceed-total-miles.component.html',
  styleUrls: ['./exceed-total-miles.component.scss']
})


export class ExceedTotalMilesComponent extends BaseApplicationComponent<ExceedTotalMilesComponent> implements OnInit, OnDestroy {

  selectMilesSubscription: any;
  selectMilesData: SelectMiles;
  descriptionText: string;
  btnCancelText: string;
  globalTechError: boolean;
  thresholdFault: boolean;
  errorMessage: string;
  @Input() stepper: any;

  constructor(private translateService: TranslateService, injector: Injector, @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  ngOnInit() {
    this.selectMilesSubscription = this.store.select(state => state.SelectMiles)
      .subscribe(selectMiles => this.setSelectMilesData(selectMiles));
  }

  ngOnDestroy() {
    if (this.selectMilesSubscription) {
      this.selectMilesSubscription.unsubscribe();
    }
  }

  setSelectMilesData(selectMiles: SelectMiles): void {
    this.selectMilesData = selectMiles;
    this.setLabels();
  }

  setLabels(): void {
    this.globalTechError = false;
    this.thresholdFault = false;
    if (this.selectMilesData.error) {
      switch (this.selectMilesData.faultType) {
        case FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageMinimumFault:
          this.translateService.get("ngw.map.entry.exceed-total-miles.min-description").subscribe((res: string) => {
            this.descriptionText = res;
          });
          this.translateService.get("ngw.map.entry.exceed-total-miles.btn-no-cancel").subscribe((res: string) => {
            this.btnCancelText = res;
          });
          break;
        case FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageThresholdFault:
          this.translateService.get("ngw.map.entry.exceed-total-miles.max-description").subscribe((res: string) => {
            this.descriptionText = res;
          });
          this.translateService.get("ngw.map.entry.exceed-total-miles.btn-cancel").subscribe((res: string) => {
            this.btnCancelText = res;
          });
          this.thresholdFault = true;
          break;
        default:
          const errorTranslationKey = "ngw.global.technical-error";
          this.translateService.get(errorTranslationKey).subscribe((res: string) => {
            this.errorMessage = res;
          });
          this.translateService.get("ngw.map.entry.exceed-total-miles.btn-cancel").subscribe((res: string) => {
            this.btnCancelText = res;
          });
          this.pushErrorEvent(errorTranslationKey);
          this.globalTechError = true;
          break;
      }
    }
  }
}
