<div class="legal-agreement-terms-conditions-container" [ngClass]="{'height-container': termsAccepted || esignAccepted, 'accordion-expand': hasExpandedAccordion()}">
  <h1>{{'ngw.onboarding.legal-agreements.title' | translate}}</h1>
  <p>{{'ngw.onboarding.legal-agreements.msg' | translate}}</p>

  <div *ngIf="!profileTermsAccepted">
    <app-agreement [agreementType]="profileTermsType" (setExpanded$)="setExpanded($event, profileTermsType)"></app-agreement>
  </div>
  <div *ngIf="!termsAccepted">
    <app-agreement [agreementType]="termsType" (setExpanded$)="setExpanded($event, termsType)"></app-agreement>
  </div>
  <div *ngIf="!esignAccepted">
    <app-agreement [agreementType]="esignType" (setExpanded$)="setExpanded($event, esignType)"></app-agreement>
  </div>
  
  <div class="disclosure-text" *ngIf="!esignAccepted">
    <p>{{'ngw.onboarding.legal-agreements.esign.msg' | translate}}</p>
    <p>{{'ngw.onboarding.legal-agreements.esign.agree1' | translate}}</p>
    <p>{{'ngw.onboarding.legal-agreements.esign.agree2' | translate}}</p>
    <p>{{'ngw.onboarding.legal-agreements.esign.agree3' | translate}}</p>
  </div>
</div>
