<div class="summary-charge-container">
  <div class="title"><strong>{{'ngw.vehicle-return.estimated-purchase-summary.title' | translate}}</strong></div>
  <!-- charge table -->
  <div class="charge-table summary">
    <div class="charge-table-row">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-purchase-summary.lbl-outstanding" | translate}}</div>
      <div class="charge-value">{{outstandingBalance | currency : 'USD':true | minusToParens}}</div>
    </div>
    <div class="charge-table-row">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-purchase-summary.lbl-balloon-payment" | translate}}</div>
      <div class="charge-value">{{balloonPayment | currency : 'USD':true | minusToParens}}</div>
    </div>
    <div class="charge-table-row"  *ngIf="feesAndCredits && feesAndCredits!==0">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-purchase-summary.lbl-fees" | translate}}</div>
      <div class="charge-value">{{feesAndCredits | currency : 'USD':true | minusToParens}}</div>
    </div>
    <div class="charge-table-row" *ngIf="purchaseDiscount != 0">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-purchase-summary.lbl-purchase-discount" | translate}}</div>
      <div class="charge-value">{{purchaseDiscount | currency : 'USD':true | minusToParens}}</div>
    </div>
  </div>
  <!-- end charge table -->
  <div class="summary-separator"></div>
  <!-- charge table -->
  <div class="charge-table summary">
    <div class="charge-table-row highlight">
      <div class="charge-label">{{"ngw.vehicle-return.estimated-purchase-summary.lbl-total" | translate}}</div>
      <div class="charge-value">{{estimatedPurchaseTotal | currency : 'USD':true | minusToParens}}</div>
    </div>
  </div>
  <!-- end charge table -->
  <p class="info">
    {{"ngw.vehicle-return.estimated-purchase-summary.disclaimer1-purchase-summary" | translate}}
    <br />
    {{"ngw.vehicle-return.estimated-purchase-summary.disclaimer2-purchase-summary" | translate}}
  </p>
</div>
