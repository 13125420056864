import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StringModel } from '../../../_models/string.model';


@Injectable()
export class UserActionActions {
    static SET_USER_ACTION: string = 'SET_USER_ACTION';
    static CLEAR_USER_ACTION: string = 'CLEAR_USER_ACTION';

    setUserAction(userAction: string): FSA<string, string, null> {
    return {
        type: UserActionActions.SET_USER_ACTION,
        payload: userAction,
      meta: null
    };
  }

    clearUserAction(): FSA <string, null> {
      return {
          type: UserActionActions.CLEAR_USER_ACTION ,
        payload: null,
        meta: null
      };
    }
}

@Injectable()
export class UserActionReducers {
    static UserActionReducer: Reducer<StringModel> = (state: StringModel = new StringModel(undefined), action: FSA<string, string, void>) => {
    switch (action.type) {
        case UserActionActions.SET_USER_ACTION:
            return tassign(state, new StringModel(action.payload));
        case UserActionActions.CLEAR_USER_ACTION:
            return tassign(undefined);
      default:
        return state;
    }
  }
}
