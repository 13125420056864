import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StringModel } from '../../../_models/string.model';

@Injectable()
export class UserTokenActions {
    static SET_USER_TOKEN: string = 'SET_USER_TOKEN';
    static CLEAR_USER_TOKEN: string = 'CLEAR_USER_TOKEN';

    setSecureMessageUserToken(userToken: string): FSA<string, string, null> {
        return {
            type: UserTokenActions.SET_USER_TOKEN,
            payload: userToken,
            meta: null
        };
    }

    clearSecureMessageUserToken(): FSA<string, null > {
    return {
        type: UserTokenActions.CLEAR_USER_TOKEN,
        payload: null,
        meta: null
    };
    }

}

@Injectable()
export class UserTokenReducers {
    static UserTokenReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
        switch (action.type) {
            case UserTokenActions.SET_USER_TOKEN:
                return tassign(state, new StringModel(action.payload));
            case UserTokenActions.CLEAR_USER_TOKEN:
                return tassign(state, new StringModel(action.payload));
            default:
                return state;
        }
    }
    }
