import { Injectable } from '@angular/core';
import { Constants } from './../constants';

@Injectable()
export class ScreenSizeHelperService {

  public innerWidth: number;

  constructor() { }

  public setWidth(width: number) {
    this.innerWidth = width;
  }
  
  public isInSmallScreenMode():boolean {
    let isInSmallScreenSizeMode = this.innerWidth <= Constants.SmallScreenSize;
    return isInSmallScreenSizeMode;
  }

}

