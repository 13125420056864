import { GetPaymentDueDateResponse, ValidatePaymentDueDateResponse } from '../../core/gateway-api';

export class DueDateChangeEntryModel {
  dueDateData: GetPaymentDueDateResponse;
  validateDueDateData: ValidatePaymentDueDateResponse;
  error: boolean;
  getDueDateErrorType: GetDueDateErrorType;
  validateDueDateErrorType: ValidateDueDateErrorType;  

  constructor(dueDateData?: GetPaymentDueDateResponse, validateDueDateData?: ValidatePaymentDueDateResponse, error?: boolean, getDueDateErrorType?: GetDueDateErrorType, validateDueDateErrorType?: ValidateDueDateErrorType) {
    this.dueDateData = dueDateData;
    this.validateDueDateData = validateDueDateData;
    this.error = error;
    this.getDueDateErrorType = getDueDateErrorType;
    this.validateDueDateErrorType = validateDueDateErrorType;   
  }
}

export enum GetDueDateErrorType {
    GetDueDateValidationFailed = 1,
    DueDateSemiMonthly = 2,
    GetDueDateFailed = 3
}

export enum ValidateDueDateErrorType {
  DueDaysAreEqual = 1,
  InvalidDueDay = 2,
  InvalidFinalContractDueDate = 3,
  BusinessRuleValidationFailed = 4,
  DueDateSemiMonthly = 5,
  ExtendedLease = 6,
  TerminatedStatus = 7,
  ContractType  = 8,
  ActiveDeferral = 9,
  ValidateDueDateFailed = 10  
}
