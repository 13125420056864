export class GenerateSecondFactorTokenStatus {
  error: boolean;
  errortype: GenerateSecondFactorTokenStatusErrorType;
  transactionId: string;
}

export enum GenerateSecondFactorTokenStatusErrorType {
  DataValidation,
  TokenUnexpired,
  UserNotFound,
  UserAccountPasswordLocked,
  GenericServerError,
  UserNameDisabled
}
