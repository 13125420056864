import { PaymentSource } from '../payment.model';
import { ScheduledItemDTO } from '../../core/gateway-api';

export class ScheduledPayment {
    accountNumber: string;
    nextPaymentDate: Date;
    scheduledPaymentAmount: number;
    shouldDrawFullBalance: boolean;
    additionalAmount: number;
    paymentsRemaining: number;
}

export class PendingOneTimePayment {
  scheduledItem: any;
  paymentDate: Date;
  paymentAmount: number;
  paymentSource: PaymentSource;
  error: boolean;
}



