import { Injectable } from '@angular/core';
import { IAuthProviderUrllBuilderService } from '../../_models/interface/iauthprovider-url-builder.service';
import { routes } from './router.service';
import { EnvironmentConfig } from '../../_models/environment-config';
import { ApplicationConfig } from '../../_models/application-config';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.store';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { ObjectService } from './object.service';
import { Constants } from '../constants';
import { ContactDTO } from '../../core/gateway-api';
@Injectable({
  providedIn: 'root',
})
export class AuthhubUrlBuilderService implements IAuthProviderUrllBuilderService {

  public currentRoute: routes;
  public envConfig: EnvironmentConfig;
  public appConfig: ApplicationConfig;

  constructor(
    private store: Store<IAppState>,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private objectService: ObjectService) {

  }

  getLoginRedirectUrl(state: string): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.appConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_SSO_URL.concat(Constants.UrlParametersPrefix);
    let clientIdParameter = this.getRouteParameter(Constants.SsoAuthHubRouteClientIdParameterName, this.appConfig.CLIENT_ID);
    let scopeParameter = this.getRouteParameter(Constants.SsoAuthHubRouteScopeParameterName, Constants.SsoAuthHubRouteScopeParameterValue);
    let responseTypeParameter = this.getRouteParameter(Constants.SsoAuthHubRouteResponseTypeParameterName, Constants.SsoAuthHubRouteResponseTypeParameterValue);
    let redirectUriParameter = this.getRouteParameter(Constants.SsoAuthHubRouteRedirectUriParameterName, encodeURI(this.envConfig.SSO_REDIRECT_URI));
    let ssoLogoutUri = this.getRouteParameter(Constants.SsoAuthHubRouteLogoutUriParameterName, encodeURI(this.envConfig.SSO_LOGOUT_REDIRECT_URI));
    let langParameter = this.getRouteParameter(Constants.LanguageParameterName, this.languageService.getCurrentLanguage());
    let stateParameter = this.getRouteParameter(Constants.SsoAuthHubRouteStateParameterName, encodeURIComponent(state), true);
    return domainUrl.concat(clientIdParameter, scopeParameter, responseTypeParameter, redirectUriParameter, ssoLogoutUri, langParameter, stateParameter)
  }
  getLogoutRedirectUrl(language: string): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.appConfig = x);
    let clientIdParameter = this.getRouteParameter(Constants.SsoAuthHubRouteClientIdParameterName, this.appConfig.CLIENT_ID);
    let langParameter = this.getRouteParameter(Constants.LanguageParameterName, language == Constants.UNDEFINED ? this.languageService.getCurrentLanguage() : language, true);
    let url = this.envConfig.AUTH_HUB_SSO_URL.concat(Constants.SsoAuthHubLogoutRoute, Constants.UrlParametersPrefix, clientIdParameter, langParameter);
    return url;
  }

  getProfileUrl(): string {
    throw new Error('Method not implemented.');
  }

  getRedirectUrl(url: string): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_DOMAIN.concat(url);
    return domainUrl;
  }
  getRegistrationUrl(): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_REGISTER_URL.concat(Constants.UrlParametersPrefix);
    return domainUrl;
  }
  getManageSettingsRedirectUrl(): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_MANAGE_SETTINGS_REDIRECT_URI.concat(Constants.UrlParametersPrefix, Constants.LanguageParameterName, Constants.UrlParameterNameAndValueConcat, this.languageService.getCurrentLanguage());
    return domainUrl;
  }
  getTermsUseRedirectUr(): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_REDIRECT_URI.concat(Constants.termsURIsuffix + Constants.UrlParametersPrefix + this.languageService.getLanguageParameter());
    return domainUrl;
  }
  getUnsubscribeUrl(): string {
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    let firstName = Constants.EMPTY;
    let lastName = Constants.EMPTY;
    let primaryEmailAddress = Constants.EMPTY;
    let unsubscribeUrl: string;

    if (contact) {
      firstName = contact.firstName ? contact.firstName : Constants.EMPTY;
      lastName = contact.lastName ? contact.lastName : Constants.EMPTY;

      if (contact.emailAddresses) {
        for (let emailAddress of contact.emailAddresses) {
          if (emailAddress.isPrimary === true) {
            primaryEmailAddress = emailAddress.emailAddress.toLowerCase();
            break;
          }
        }
      }
    }

    this.translateService.get('ngw.footer.links.unsubscribe-url', { firstName: firstName, lastName: lastName, email: primaryEmailAddress })
      .subscribe(value => { unsubscribeUrl = value });

    return unsubscribeUrl;
  }

  private getRouteParameter(parameterName: string, parameterValue: string, isLastParameter?: boolean): string {
    return isLastParameter
      ? parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue)
      : parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue, Constants.UrlParametersConcat);
  }
}
