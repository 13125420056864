<div class="modal-cancel-pending-payment">
  <h1>{{'ngw.my-account.payment-details.pending-one-time-payments.cancel-pending-payment.title' | translate}}</h1>
  <div class="alert alert-danger" role="alert" *ngIf="error == true">
    <p class="error-validation" id="errorMessage">
      {{errorMessage}}
    </p>
  </div>
  <div *ngIf="pendingOneTimePaymentData && pendingOneTimePaymentData.scheduledItem && pendingOneTimePaymentData.scheduledItem.scheduledItemDTO">
    <div class="info-row">
      <span class="label">{{'ngw.my-account.payment-details.pending-one-time-payments.cancel-pending-payment.lbl-date' | translate}}</span>
      <span class="value" id="paymentSource">{{pendingOneTimePaymentData.scheduledItem.scheduledItemDTO.scheduledDate| date:'shortDate'}}</span>
    </div>
    <div class="info-row">
      <span class="label">{{'ngw.my-account.payment-details.pending-one-time-payments.cancel-pending-payment.lbl-account' | translate}}</span>
      <span class="value" id="paymentSource">{{paymentSourceName}}</span>
    </div>
    <div class="info-row">
      <span class="label">{{'ngw.my-account.payment-details.pending-one-time-payments.cancel-pending-payment.lbl-amount' | translate}}</span>
      <span class="value" id="paymentSource">{{pendingOneTimePaymentData.scheduledItem.scheduledItemDTO.scheduledAmount | currency : 'USD':true }}</span>
    </div>
  </div>
  <div class="action-buttons">
    <button (click)="keepPendingPayment()" class="btn btn-secondary btn-transparent" id="keepRecurringPaymentBtn">{{'ngw.my-account.payment-details.pending-one-time-payments.cancel-pending-payment.btn-keep' | translate}}</button>
    <button (click)="cancelPendingPayment()" class="btn btn-primary" id="cancelRecurringPaymentBtn" type="submit">{{'ngw.my-account.payment-details.pending-one-time-payments.cancel-pending-payment.btn-confirm' | translate}}</button>
  </div>
</div>
