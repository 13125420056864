import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { ViewRepairReceiptModel } from '../../../_models/view-repair-receipt.model';

@Injectable()
export class RepairReceiptsActions {
  static PUSH_REPAIR_RECEIPT: string = "PUSH_REPAIR_RECEIPT";
  static REMOVE_REPAIR_RECEIPT: string = "REMOVE_REPAIR_RECEIPT";
  static CLEAR_REPAIR_RECEIPT: string = "CLEAR_REPAIR_RECEIPT";


  pushRepairReceipts(repairReceiptModel: ViewRepairReceiptModel): FSA<string, any, null> {
    return {
      type: RepairReceiptsActions.PUSH_REPAIR_RECEIPT,
      payload: repairReceiptModel,
      meta: null
    };
  }

  removeRepairReceipts(accountNumber: string): FSA<string, any, null> {
    return {
      type: RepairReceiptsActions.REMOVE_REPAIR_RECEIPT,
      payload: accountNumber,
      meta: null
    };
  }

  clearRepairReceipts(): FSA<string, any, null> {
    return {
      type: RepairReceiptsActions.CLEAR_REPAIR_RECEIPT,
      payload: null,
      meta: null
    };
  }

}

@Injectable()
export class RepairReceiptsReducers {
  static RepairReceiptsReducer: Reducer<ViewRepairReceiptModel[]> = (state: ViewRepairReceiptModel[] = [], action: FSA<string, any, void>) => {
    switch (action.type) {
      case RepairReceiptsActions.PUSH_REPAIR_RECEIPT:
        return state = [...state, action.payload];
      case RepairReceiptsActions.REMOVE_REPAIR_RECEIPT:
        return state = state ? state.filter(repairReceipt => repairReceipt.accountNumber !== action.payload) : [];
      case RepairReceiptsActions.CLEAR_REPAIR_RECEIPT:
        return state = [];
      default:
        return state;
    }
  }
}
