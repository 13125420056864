import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserConsentDialogModel } from '../_models/user-cosent-dialog';
import { DialogService } from '../shared/_helper-services/dialog.service';
import { IAppState } from "../shared/store/app.store";
import { CanNavigateBackActions } from '../shared/store/reducers/can-navigate-back.reducer';


export const CancelAddChequingAccountGuard: CanDeactivateFn<any> = () => {
  const translateService = inject(TranslateService);
  const dialogService = inject(DialogService);
  const canNavigateBackActions = inject(CanNavigateBackActions);
  const store = inject(Store<IAppState>);
  let canNavigateBack: boolean;
  store.select(state => state.CanNavigateBack.booleanValue).subscribe(x => canNavigateBack = x);
  if (!canNavigateBack) {
    let data = new UserConsentDialogModel();
    translateService.get("ngw.payment-source.cancel-add-chequing-account.title").subscribe((res: string) => {
      data.dialogTitle = res;
    });

    translateService.get("ngw.payment-source.cancel-add-chequing-account.message").subscribe((res: string) => {
      data.dialogText = res;
    });

    translateService.get("ngw.payment-source.cancel-add-chequing-account.cancel-add-chequing-account-btn").subscribe((res: string) => {
      data.btnOkay = res;
    });

    translateService.get("ngw.payment-source.cancel-add-chequing-account.return-to-add-chequing-account-btn").subscribe((res: string) => {
      data.btnCancel = res;
    });

    return dialogService.openUserConsentDialog(data);
  }
  else {
    store.dispatch(canNavigateBackActions.setCanNavigateBack(true));
    return true;
  }

}
