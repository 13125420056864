<mat-dialog-content>
  <div class="modal-cancel-automated-payment">
    <h1>{{'ngw.payment.cancel.title' | translate}}</h1>
    <div class="alert alert-danger" role="alert" *ngIf="cancelRecurringPaymentError == true">
      <p class="error-validation" id="errorMessage">
        {{errorMessage}}
      </p>
    </div>
    <p><span>{{subtitle}}</span></p>
    <h2>{{'ngw.payment.cancel.account-info-section-heading' | translate}}</h2>
    <div>
      <div class="info-text">
        <span class="label">{{'ngw.payment.cancel.vehicle' | translate}}</span>
        <span class="value" id="paymentSource">{{this.recurringPaymentCancel.vehicleModelYear}}</span>
      </div>
      <div class="info-text">
        <span class="label">{{'ngw.payment.cancel.account-no' | translate}}</span>
        <span class="value" id="paymentSource">{{this.accountNumber}}</span>
      </div>
      <div class="info-text">
        <span class="label">{{'ngw.payment.cancel.billing-email' | translate}}</span>
        <span class="value" id="paymentSource">{{this.recurringPaymentCancel.billingEmail}}</span>
      </div>
    </div>
    <div class="action-buttons">
      <button (click)="keepRecurringPayment()" class="btn btn-secondary btn-transparent" id="keepRecurringPaymentBtn">{{'ngw.payment.cancel.keep-recurring-payment' | translate}}</button>
      <button (click)="cancelRecurringPayment()" class="btn btn-primary" id="cancelRecurringPaymentBtn">{{'ngw.payment.cancel.cancel-recurring-payment' | translate}}</button>
    </div>
  </div>
</mat-dialog-content>
