import { Component, OnInit} from '@angular/core';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-legal-agreement-done',
  templateUrl: './legal-agreement-done.component.html',
  styleUrls: ['./legal-agreement-done.component.scss']
})

export class LegalAgreementDone implements OnInit {

  constructor() {  }
  
  ngOnInit() {
  }
}
