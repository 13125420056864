import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { CreatePaymentDeferralModel } from '../../../_models/payment-deferral/create-payment-deferral.model';


@Injectable()
export class PaymentDeferralConfirmationActions {
  static SET_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS: string = 'SET_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS';
  static CLEAR_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS: string = 'CLEAR_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS';

  setPaymentDeferralConfirmation(paymentDeferralConfirmation: CreatePaymentDeferralModel): FSA<string, CreatePaymentDeferralModel, null> {
    return {
      type: PaymentDeferralConfirmationActions.SET_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS,
      payload: paymentDeferralConfirmation,
        meta: null
    };
  }

  clearPaymentDeferralConfirmation(): FSA<string, CreatePaymentDeferralModel, null> {
      return {
        type: PaymentDeferralConfirmationActions.CLEAR_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class PaymentDeferralConfirmationReducers {
  static PaymentDeferralConfirmationReducer: Reducer<CreatePaymentDeferralModel> = (state: CreatePaymentDeferralModel = new CreatePaymentDeferralModel(), action: FSA<string, CreatePaymentDeferralModel, void>) => {
        switch (action.type) {
          case PaymentDeferralConfirmationActions.SET_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS:
                return tassign(state, action.payload);
          case PaymentDeferralConfirmationActions.CLEAR_PAYMENT_DEFERRAL_CONFIRMATION_DETAILS:
                return tassign(undefined);

            default:
                return state;
        }
    }
}
