import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { ScheduledItem } from '../../../_models/contract/scheduled-item.model';

@Injectable()
export class ScheduledItemsActions
{
    static PUSH_SCHEDULED_ITEM: string = 'PUSH_SCHEDULED_ITEM';
    static CLEAR_SCHEDULED_ITEMS: string = 'CLEAR_SCHEDULED_ITEMS';

    pushScheduledItem(scheduledItem: ScheduledItem): FSA<string, any, null>{
    return {
      type: ScheduledItemsActions.PUSH_SCHEDULED_ITEM,
      payload: scheduledItem,
      meta: null
    };
    }

  clearScheduledItems(): FSA<string, any, null> {
      return {
          type: ScheduledItemsActions.CLEAR_SCHEDULED_ITEMS,
          payload: null,
          meta: null
      };
  }

 
}

@Injectable()
export class ScheduledItemsReducers {
    static ScheduledItemsReducer: Reducer<ScheduledItem[]> = (state: ScheduledItem[] = [], action: FSA<string, any, void>) => {
    switch(action.type){
        case ScheduledItemsActions.PUSH_SCHEDULED_ITEM:
            return state = [...state, action.payload];
        case ScheduledItemsActions.CLEAR_SCHEDULED_ITEMS:
            return state = [];
        default:
          return state;
    }
  }
}
