<div *ngIf="selectMilesData" id="selectMilesDiv">
  <div #selectMilesTopDiv id="selectMilesTopDiv"></div>
  <h3 class="pre-header">{{'ngw.map.entry.select-miles.pre-header' | translate}}</h3>
  <div *ngIf="selectMilesData.recommendedMilesSectionEnabled; else recommendedDisabled">
    <h2>{{'ngw.map.entry.select-miles.recommended-enabled.header' | translate}}</h2>
    <p>{{'ngw.map.entry.select-miles.recommended-enabled.description' | translate}}</p>
  </div>
  <ng-template #recommendedDisabled>
    <h2>{{'ngw.map.entry.select-miles.recommended-disabled.header' | translate}}</h2>
  </ng-template>
  <p class="current-allowed"><strong>{{'ngw.map.entry.select-miles.lbl-current-miles' | translate}} </strong>{{currentMilesAllowed | number}}</p>

  <mat-accordion multi="true">
    <mat-expansion-panel *ngIf="selectMilesData.recommendedMilesSectionEnabled && selectMilesData.mileageCalculation" [disabled]="recommendedOpened" (opened)="onOpenRecommended()" [ngClass]="{'selected' : recommendedSelected}" [expanded]="recommendedSelected || recommendedOpened">
      <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto" analytics-event-accordian>
        <mat-panel-title>
          <mat-radio-group class="example-radio-group" [(ngModel)]="selectMiles">
            <mat-radio-button class="example-radio-button" [ngClass]="{'recommended-radio' : selectMilesData.recommendedMilesSectionEnabled}" [value]="optionRecommended" (change)="setSelected(optionRecommended)">
              <span class="radio-label">{{selectMilesData.mileageCalculation.projectedMilesDeficit | number}} mi </span>
              <span class="radio-description">{{ 'ngw.map.entry.select-miles.lbl-recommended-option' | translate }}</span>
            </mat-radio-button>
          </mat-radio-group>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mileage-row-container">
        <div class="mileage-row">
          <span class="label">
            {{'ngw.map.entry.select-miles.lbl-new-total-miles' | translate}}
          </span>
          <span class="value">
            {{selectMilesData.mileageCalculation.projectedMiles | number}}
          </span>
        </div>
        <div class="mileage-row">
          <span class="label">
            {{'ngw.map.entry.select-miles.lbl-total-cost' | translate}}
          </span>
          <span class="value">
            {{selectMilesData.mileageCalculation.projectedCost | currency : 'USD':true}}
          </span>
        </div>
        <div class="mileage-row">
          <span class="total">
            {{'ngw.map.entry.select-miles.lbl-estimated-savings' | translate}} {{selectMilesData.mileageCalculation.projectedSavings | currency : 'USD':true}}
          </span>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [disabled]="chooseYourOwnOpened" (opened)="onOpenChooseYourOwn()" [ngClass]="{'selected' : chooseYourOwnSelected}" [expanded]="chooseYourOwnOptionIsExpanded()">
      <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto" analytics-event-accordian>
        <mat-panel-title [ngClass]="{'single-option' : !selectMilesData.recommendedMilesSectionEnabled}">
          <mat-radio-group class="example-radio-group" [(ngModel)]="selectMiles">
            <mat-radio-button class="example-radio-button" [ngClass]="{'recommended-radio' : selectMilesData.recommendedMilesSectionEnabled}" [value]="optionChooseYourOwn" (change)="setSelected(optionChooseYourOwn)">
              <span class="radio-label">{{ chooseYourOwnAmt | number }} mi </span>
              <span class="radio-description">{{ 'ngw.map.entry.select-miles.lbl-choose-your-own-option' | translate }}</span>
            </mat-radio-button>
          </mat-radio-group>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>{{'ngw.map.entry.select-miles.enter-how-many-miles' | translate}}</p>
      <form #milesToPurchaseForm="ngForm" autocomplete="off">
        <div class="form-group">
          <label class="control-label" for="milesToPurchase">{{'ngw.map.entry.select-miles.lbl-miles-to-purchase' | translate}}</label>
          <input [(ngModel)]="milesToPurchaseInput" name="milesToPurchase" id="milesToPurchase" numeric-value type="number" min="1" class="form-control" (ngModelChange)="onMilesToPurchaseChange()" />
        </div>
        <button class="btn btn-primary" [disabled]="isMilesToPurchaseInvalid(milesToPurchaseForm.controls.milesToPurchase)" (click)="updateMiles()">
          <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
        </button>
      </form>
      <em class="error-validation" id="milesToPurchaseError" *ngIf="milesToPurchaseIsInvalid(milesToPurchaseForm.controls.milesToPurchase)">{{"ngw.validation.error.miles-to-purchase-pattern" | translate}}</em>
      <div class="mileage-row-container" *ngIf="milesEntered && !mileageError && calculateMilesData.midtermMileageCalculation">
        <div class="mileage-row">
          <span class="label">
            {{'ngw.map.entry.select-miles.lbl-new-total-miles' | translate}}
          </span>
          <span class="value">
            {{newTotalMilesAllowed | number}}
          </span>
        </div>
        <div class="mileage-row">
          <span class="label">
            {{'ngw.map.entry.select-miles.lbl-total-cost' | translate}}
          </span>
          <span class="value">
            {{calculateMilesData.midtermMileageCalculation.singleMMAPaymentAmount | currency : 'USD':true}}
          </span>
        </div>
        <div class="mileage-row">
          <span class="total">
            {{'ngw.map.entry.select-miles.lbl-estimated-savings' | translate}} {{calculateMilesData.midtermMileageCalculation.projectedSavings | currency : 'USD':true}}
          </span>
        </div>
      </div>
      <em class="error-validation" *ngIf="mileageError">
        <strong *ngIf="mileageError">{{'ngw.map.entry.select-miles.error' | translate}}</strong> {{errorMessage}}
      </em>
      <em class="error-validation" *ngIf="mileageWarning">
        <strong *ngIf="mileageWarning">{{'ngw.map.entry.select-miles.note' | translate}}</strong> {{errorMessage}}
      </em>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="action-buttons">
    <button class="btn btn-secondary" type="button" (click)="confirmCancellation()" analytics-event="body.cancel-map-select-miles.cancel-map-select-miles.button">
      {{'ngw.map.entry.select-miles.btn-cancel' | translate}}</button>
    <button class="btn btn-primary" type="button" (click)="nextClick()" [disabled]="!isSelectMilageValid()">{{'ngw.map.entry.select-miles.btn-next' | translate}}</button>
  </div>


</div>

