export class GenerateFsTokenStatus {
  error: boolean;
  errortype: GenerateFsTokenStatusErrorType;
}

export enum GenerateFsTokenStatusErrorType {
  DataValidation,
  InvalidToken,
  TokenExpired,
  UserNotFound,
  UserAccountPasswordLocked,
  GenericServerError,
}
