import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { ContractAccountDetailDTO } from '../../../core/gateway-api';

@Injectable()
export class ActiveContractAccountDetailsActions
{
    static SET_ACTIVE_CONTRACTS: string = 'SET_ACTIVE_CONTRACTS';

  setActiveContracts(contracts: ContractAccountDetailDTO[]): FSA<string, ContractAccountDetailDTO[], null>{
    return {
      type: ActiveContractAccountDetailsActions.SET_ACTIVE_CONTRACTS,
      payload: contracts,
      meta: null
    };
  }

 
}

@Injectable()
export class ActiveContractAccountDetailsReducers
{
    static ActiveContractAccountDetailsReducer: Reducer<ContractAccountDetailDTO[]> = (state: ContractAccountDetailDTO[] = [], action: FSA<string, ContractAccountDetailDTO[], void>) => {
    switch(action.type){
        case ActiveContractAccountDetailsActions.SET_ACTIVE_CONTRACTS:
            return tassign(state, action.payload);
        default:
          return state;
    }
  }
}
