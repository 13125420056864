import {Component, Inject,Injector, ViewEncapsulation} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { BaseApplicationComponent } from '../../../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'bulletin-box-dialog',
    templateUrl: './bulletin-box-dialog.component.html',
    styleUrls: ['./bulletin-box-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BulletinBoxDialog extends BaseApplicationComponent<BulletinBoxDialog> {
    subtitle: string;
    bulletinMessage: string = "";
    constructor(public dialogRef: MatDialogRef<BulletinBoxDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(DOCUMENT) document,
        injector: Injector) {
        super(injector, document);
    }

     ngOnInit() {
      this.bulletinMessage = this.data ? this.data : "";
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
  
}
