import { FindEasyPayScheduleResponse, GetRecurringPaymentViewDataResponse } from '../../core/gateway-api';
import { PaymentSource } from '../payment.model';
import { TitleParagraph } from '../title-paragraph.model';
import { PaymentPreference } from './payment-preference.model';


export class RecurringPaymentEntry {
  isInAddMode: boolean;
  accountNumber: string;
  billingEmailAddress: string;
  titleSection: TitleParagraph;
  error: boolean;
  loadDataError: boolean;
  paymentPreferenceError: boolean;
  errorMessage: string;
  faultType: string;
  getRecurringPaymentViewDataResponse: GetRecurringPaymentViewDataResponse;
  findEasyPayScheduleResponse: FindEasyPayScheduleResponse;
  selectedPaymentSource: PaymentSource;
  sources: PaymentSource[];
  preferences: PaymentPreference[];
  additionalPaymentPreference: PaymentPreference;
  selectedPaymentPreference: PaymentPreference;
  startDate: string;
  nextEffectiveDate: Date;
  confirmationNumber: number;
  isRegularMonthlyPaymentDue: boolean;

  constructor(
      isInAddMode: boolean = undefined,
      accountNumber: string = undefined,
      titleSection: TitleParagraph = undefined,
      billingEmailAddress: string = undefined,
      error: boolean = undefined,
      loadDataError: boolean = undefined,
      paymentPreferenceError: boolean = undefined,
      errorMessage: string = undefined,
      faultType: string = undefined,
      getRecurringPaymentViewDataResponse: GetRecurringPaymentViewDataResponse = undefined,
      findEasyPayScheduleResponse: FindEasyPayScheduleResponse = undefined,
      selectedPaymentSource: PaymentSource = undefined,
      sources: PaymentSource[] = undefined,
      preferences: PaymentPreference[] = undefined,
      additionalPaymentPreference: PaymentPreference = undefined,
      selectedPaymentPreference: PaymentPreference = undefined,
      startDate: string = undefined,
      nextEffectiveDate: Date = undefined,
      isRegularMonthlyPaymentDue: boolean = undefined)
  {
      this.isInAddMode = isInAddMode ? isInAddMode : this.isInAddMode;
      this.accountNumber = accountNumber ? accountNumber : this.accountNumber;
      this.titleSection = titleSection ? titleSection : this.titleSection;
      this.billingEmailAddress = billingEmailAddress ? billingEmailAddress : this.billingEmailAddress;
      this.error = error ? error : this.error;
      this.loadDataError = loadDataError ? loadDataError : this.loadDataError;
      this.paymentPreferenceError = paymentPreferenceError ? paymentPreferenceError : this.paymentPreferenceError;
      this.errorMessage = errorMessage ? errorMessage : this.errorMessage;
      this.faultType = faultType ? faultType : this.faultType;
      this.getRecurringPaymentViewDataResponse = getRecurringPaymentViewDataResponse
          ? getRecurringPaymentViewDataResponse
          : this.getRecurringPaymentViewDataResponse;
      this.findEasyPayScheduleResponse = findEasyPayScheduleResponse ? findEasyPayScheduleResponse : this.findEasyPayScheduleResponse;
      this.sources = sources ? sources : this.sources;
      this.selectedPaymentSource = selectedPaymentSource ? selectedPaymentSource : this.selectedPaymentSource;
      this.preferences = preferences ? preferences : this.preferences;
      this.additionalPaymentPreference = additionalPaymentPreference ? additionalPaymentPreference : this.additionalPaymentPreference;
      this.selectedPaymentPreference = selectedPaymentPreference ? selectedPaymentPreference : this.selectedPaymentPreference;
      this.startDate = startDate ? startDate : this.startDate;
      this.nextEffectiveDate = nextEffectiveDate ? nextEffectiveDate : this.nextEffectiveDate;
      this.isRegularMonthlyPaymentDue = isRegularMonthlyPaymentDue ? isRegularMonthlyPaymentDue: this.isRegularMonthlyPaymentDue;
  }
}



