import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { CoBrandedCreditCardModel } from '../../../_models/cobranded-credit-card.model';

@Injectable()
export class CardHolderActions {
  static PUSH_CARD_HOLDER: string = 'PUSH_CARD_HOLDER';  
  static CLEAR_CARD_HOLDER: string = 'CLEAR_CARD_HOLDER';

  pushCardHolder(creditCardHolderData: CoBrandedCreditCardModel): FSA<string, CoBrandedCreditCardModel, null> {
    return {
      type: CardHolderActions.PUSH_CARD_HOLDER,
      payload: creditCardHolderData,
      meta: null
    };
  }
}

@Injectable()
export class CardHolderReducers {
  static CardHolderReducer: Reducer<CoBrandedCreditCardModel> = (state: CoBrandedCreditCardModel = new CoBrandedCreditCardModel(), action: FSA<string, CoBrandedCreditCardModel, void>) => {  
    switch (action.type) {
      case CardHolderActions.PUSH_CARD_HOLDER:
        return tassign(state, action.payload);
      default:
        return state;
    }
  }
}
