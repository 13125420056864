import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../constants';
import { IAppState } from "../store/app.store";
import { Store } from '@ngrx/store';
import { Language } from '../enums';
import { Languagecode } from '../enums';
import { SubjectTopicsActions } from '../../shared/store/reducers/subject-topics.reducers';
import { HelpServiceActions } from '../store/reducers/help.service.reducers';
import { Subscription } from 'rxjs';
import { LanguageActions } from '../store/reducers/language.reducer';
import { ApplicationConfig } from '../../_models/application-config';



export class TranslationMap {
  public text: string = '';

  constructor(
    public key: string
  ) { }
}

@Injectable()
export class LanguageService {
  storeAppConfig: ApplicationConfig;
  constructor(
    private translateService: TranslateService,
    private store: Store<IAppState>,
    private subjectTopicsActions: SubjectTopicsActions,
    private languageAction: LanguageActions,
    private helpServiceActions: HelpServiceActions
  ) { }

  public getCurrentLanguage(): string {
    return this.translateService.currentLang.substring(this.translateService.currentLang.lastIndexOf(Constants.LanguageConcat) + 1);
  }

  public getCurrentLanguageCode(): string {
    let languageCode: string;
    switch (this.getCurrentLanguage()) {
      case Language.French: {
        languageCode = Languagecode.French;
        break;
      }
      case Language.English: {
        languageCode = Languagecode.English;
        break;
      }
      default: {
        languageCode = Languagecode.English;
        break;
      }

    }

    return languageCode;
  }

  public getLanguageParameter(): string {
    return Constants.LanguageParameterName + Constants.UrlParameterNameAndValueConcat + this.getCurrentLanguage();
  }

  public setCurrentLanguage(language: string): void {
    let tenant, brand, client_APP_ID: string;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    tenant = this.storeAppConfig.TENANT;
    brand = this.storeAppConfig.BRAND;
    client_APP_ID = this.storeAppConfig.CLIENT_APP_ID;
    let lang = (tenant + Constants.LanguageConcat + brand + Constants.LanguageConcat + client_APP_ID + Constants.LanguageConcat + language).toLowerCase();
    this.store.dispatch(this.languageAction.setLanguage(language));
    if (this.translateService.langs.indexOf(lang) > -1) {
      this.clearCachedLanguageData();
      this.translateService.use(lang);
    }
  }

  public getCurrentLang(currentLang: string): string {
    return currentLang.substring(currentLang.lastIndexOf(Constants.LanguageConcat) + 1);
  }

  private clearCachedLanguageData(): void {
    this.store.dispatch(this.subjectTopicsActions.clearSubjectTopics());
    this.store.dispatch(this.helpServiceActions.clearFAQ());
  }

  public setTranslations(translationMaps: { [key: string]: TranslationMap }): Subscription {
    return this.translateService
      .get(Object.entries(translationMaps).map(([, translation]) => translation.key))
      .subscribe((translations: { [key: string]: string }) => {
        (Object.entries(translations)).forEach(([entryKey, text]) => {
          const translationEntry = Object.entries(translationMaps)
            .find(([, translation]) => translation.key == entryKey);

          translationEntry[1].text = text;
        });
      });
  }

  public getLanguageFromStore(): string {
    let language: string;
    this.store.select(state => state.Language!.stringValue).subscribe(x => language = x);
    if (language == undefined || language == Constants.EMPTY) {
      language = this.getCurrentLanguage();
    }
    return language;
  }

  public setLanguageFromQuery() {
    let language: string;
    let state: string;
    const url = new URL(window.location.href);
    language = url.searchParams.get(Constants.LanguageParameterName);
    state = url.searchParams.get(Constants.SsoStateParam);
    if (!language) {
      //language missing in query parameter, lets check if language in state value
      if (state && (state.indexOf(`${Constants.LanguageParameterName}${Constants.UrlParameterNameAndValueConcat}${Language.French}`) > -1)) {
        language = Language.French;
      }
      else {
        //default to English if we did not have language in any parameter.
        language = Language.English;
      }
    }
    //Set the current language.      
    this.setCurrentLanguage(language);
  }
}


