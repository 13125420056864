import { Injectable } from '@angular/core';

@Injectable()
export class ObjectService {
    public isEmptyObject(obj): boolean {
        if (!obj || (Object.keys(obj).length === 0)) {
            return true;
        }
        return false;
    }
}
