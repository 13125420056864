
export class DueDateDialogDataModel {
  navigateTo: DueDateDialogDataNavigationType;  

  constructor(navigateTo?: DueDateDialogDataNavigationType) {
    this.navigateTo = navigateTo ? navigateTo : DueDateDialogDataNavigationType.None;
  }
}

export enum DueDateDialogDataNavigationType {
    None = 0,
    DueDateEntry = 1,
    PaymentDetails = 2,
    ContactUs = 3
}

