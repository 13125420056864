import { Component, OnInit, Inject, Input, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from "../../../core/basecomponent/base.component";
import { DOCUMENT } from '@angular/common';
import { DatePipe } from '@angular/common';
import { ContractAccountDetailDTO, VehicleReturnEstimateDTO } from '../../../core/gateway-api';
import { AccountInfoService } from '../../../shared/_helper-services/account-info.service';
import * as _ from 'lodash-es';
import * as $ from "jquery";
import { Base64BtnModel } from '../../../_models/base-64-btn.model';
import { LeaseEndInspection } from '../../../_models/vehicle-return-estimate/lease-end-inspection.model';
import { PreInspectionDocumentPdfService } from '../../../_web-services/preInspectionDocumentPdf.service';
import { Constants } from '../../../shared/constants';
import { InspectionType } from '../../../shared/enums';

@Component({
  selector: 'app-vehicle-return-estimate-header',
  templateUrl: './vehicle-return-estimate-header.component.html',
  styleUrls: ['./vehicle-return-estimate-header.component.scss']
})

export class VehicleReturnEstimateHeaderComponent extends BaseApplicationComponent<VehicleReturnEstimateHeaderComponent> implements OnInit {
  title: string;
  contractDetail: ContractAccountDetailDTO;
  contractEndTxt: string;
  contractEndDays: number;
  insepctionEndDate: string;
  isPreInspection: boolean = false;
  inspectionTypeText: string;
  uploadedDateText: string;
  autovinURL: string;
  conditionReportUrl: string;
  leaseEndInspection: LeaseEndInspection;
  base64BtnInfo: Base64BtnModel;

  @Input() vehicleReturnEstimate: VehicleReturnEstimateDTO;

  constructor(private translateService: TranslateService,
    injector: Injector,
    private accountInfoService: AccountInfoService,
    private datePipe: DatePipe,
    private preInspectionDocumentPdfService: PreInspectionDocumentPdfService,
    @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  ngOnInit() {
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    let accountNumber = this.contractDetail.accountNumber;
    this.setContractEndTxt();

  }

  public scrollToSummary() {
    var elOffset = $('#vre-summary').offset().top;
    var offset = elOffset - 150;

    var speed = 700;
    $('html, body').animate({ scrollTop: offset }, speed);
  }

  private setContractEndTxt() {
    if (this.contractDetail) {
      let formattedContractEndDate = this.datePipe.transform(this.contractDetail.currentContractEndDate, 'longDate');
      this.translateService.get('ngw.vehicle-return.header.lbl-contract-ends', { endDate: formattedContractEndDate })
        .subscribe(result => this.contractEndTxt = result);

      // Calculate the # of days between today to Contract end date.
      this.contractEndDays = this.accountInfoService.getNoOfDaysLeftInContract(this.contractDetail.currentContractEndDate);

      if (this.vehicleReturnEstimate != undefined
        && this.vehicleReturnEstimate.inspection != undefined) {
        this.insepctionEndDate = this.datePipe.transform(this.vehicleReturnEstimate.inspection.inspectionDate, 'longDate');
      }

      if (this.contractDetail) {
        if (this.vehicleReturnEstimate.inspection.inspectionTypeId == InspectionType.PreInspection || this.vehicleReturnEstimate.inspection.inspectionTypeId == InspectionType.DSINextPreInspection) {
          this.isPreInspection = true;
        }
        else {
          this.isPreInspection = false;
        }
        let leaseEndInspectionLink = this.isPreInspection ? "ngw.vehicle-return.header.pre-inspection"
          : "ngw.vehicle-return.header.autovin-inspection";
        this.translateService.get(leaseEndInspectionLink).subscribe(result => this.inspectionTypeText = result);


        this.conditionReportUrl = this.vehicleReturnEstimate.inspection.conditionReportUrl;

        if (this.isPreInspection) {
          this.preInspectionDocumentPdfService.getInspectionReportPdfClient(this.contractDetail.accountNumber, this.vehicleReturnEstimate.inspection.inspectionTypeId).subscribe(response => {
            this.postgetInspectionDocumentPdf(response);
          });
        }
      }

    }
  }

  public openInspectionDocument() {
    this.analytics.pushDownloadEvent('Inspection Document');
    if (this.conditionReportUrl == null || this.conditionReportUrl == Constants.EMPTY) {
      this.translateService.get('ngw.vehicle-return.header.autovin-inspection-url').subscribe(value => { this.autovinURL = value });
      window.open(this.autovinURL);
    }
    else {
      window.open(this.conditionReportUrl);
    }

  }

  private postgetInspectionDocumentPdf(response: LeaseEndInspection): void {
    if (response) {
      if (response.error && this.logger) {
        this.logger.logcritical(["Error occurred in: " + Constants.VehicleReturnEstimateHeaderComponent + ", account number:" + this.contractDetail.accountNumber + ", preInspectionDocumentPdfService.getInspectionReportPdfClient()."]);
      }
      else {
        this.leaseEndInspection = new LeaseEndInspection();
        this.leaseEndInspection = response;
        this.base64BtnInfo = new Base64BtnModel(this.translateService,
          this.leaseEndInspection.contentStreamPdf,
          '.pdf',
          this.inspectionTypeText,
          'LeaseEndDocument');
      }
    }
  }
}
