import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class CanNavigateBackActions
{
  static SET_CAN_NAVIGATE_BACK: string = 'SET_CAN_NAVIGATE_BACK';

   

  setCanNavigateBack(canNavigateBack: boolean): FSA<string, boolean, null>{
        return {
          type: CanNavigateBackActions.SET_CAN_NAVIGATE_BACK,
          payload: canNavigateBack,
            meta: null
        };
    }

}

@Injectable()
export class CanNavigateBackReducers
{
  static CanNavigateBackReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
          case CanNavigateBackActions.SET_CAN_NAVIGATE_BACK:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
