<div class="extend-lease-contract-dialog-container">
  <h1>{{"ngw.shared.extend-lease-contract-dialog.title" | translate}}</h1>

  <div id="Eligible" *ngIf="eligibleToExtend; else Extended_Twice">
    <p>{{elgibilityDescription}}</p>
  </div>

  <ng-template #Extended_Twice>
    <div id="Extended_Twice" *ngIf="extendMoreThanTwoMonths; else PastMaturity_Date">
      <p>{{elgibilityDescription}}</p>
      <p>
        <b>{{elgibilityDescriptionNote}}</b>
        {{"ngw.shared.extend-lease-contract-dialog.eligible-description-2" | translate}}
        <a (click)="navigateToContactUs()">{{'ngw.shared.extend-lease-contract-dialog.contact-us' | translate}}</a>
        {{"ngw.shared.extend-lease-contract-dialog.eligible-description-2-part" | translate}}
      </p>
    </div>
  </ng-template>

  <ng-template #PastMaturity_Date>
    <div id="PastMaturity_Date" *ngIf="pastmaturityDate; else outStanding_Balance_OR_Valid_Future_Matrity_Date">
      <p>{{elgibilityDescription}}<a (click)="navigateToContactUs()">{{'ngw.shared.extend-lease-contract-dialog.contact-us' | translate}}</a>.</p>
    </div>
  </ng-template>

  <ng-template #outStanding_Balance_OR_Valid_Future_Matrity_Date>
    <div id="outStanding_Balance_OR_Valid_Future_Matrity_Date" *ngIf="outStandingBalance; else EnrolledInDrivingProgram">
      <p>{{elgibilityDescription}} <a (click)="navigateToContactUs()">{{'ngw.shared.extend-lease-contract-dialog.contact-us' | translate}}</a>.</p>

      <ul>
        <li *ngFor="let leaseExtensionEligbility of leaseExtensionEligibilities">
          <i *ngIf="!leaseExtensionEligbility.isEligible" mat-list-icon class="fa fa-times-circle"></i>
          <p>
            <span>{{leaseExtensionEligbility.description | translate}}
              <a *ngIf="leaseExtensionEligbility.showMakeAPayment" (click)="navigateToMakeAPayment()">{{'ngw.general.make-a-payment' | translate}}.</a>
            </span>
          </p>
        </li>
      </ul>
    </div>
  </ng-template>

  <ng-template #EnrolledInDrivingProgram>
    <div id="PastMaturity_Date" *ngIf="isEnrolledInDriveProgram">
      <p>{{'ngw.shared.extend-lease-contract-dialog.not-eligible-description-3' | translate}}<a (click)="navigateToContactUs()">{{'ngw.shared.extend-lease-contract-dialog.contact-us' | translate}}</a>.</p>
    </div>
  </ng-template>

  <div class="action-buttons">
    <button type="button" class="btn btn-secondary" id="cancelleaseExtendBtn" (click)="closeDialogOnly()" analytics-event="body.cancel-extend-lease-contract.cancel-extend-lease-contract.button">
      {{"ngw.shared.extend-lease-contract-dialog.btn-cancel" | translate}}</button>
    <button type="submit" class="btn btn-primary" id="continueleaseExtendBtn" [disabled]="disableButton" *ngIf="showButton" (click)="navigateToLeasePayment()">{{"ngw.shared.extend-lease-contract-dialog.btn-continue" | translate}}</button>
  </div>
</div>
