<div class="contract-amount-due no-print">
  <div class="contract-header-container">
    <span class="amount-due">{{"ngw.payment.header.amount-due" | translate}} {{amountDue | currency : 'USD':true}}</span>
    <div *ngIf="hasOutstandingBalance()">
      <span class="amount-due-date" *ngIf="hasMaturityBill(); else no_matbill">{{"ngw.payment.header.due-date" | translate }} {{ 'ngw.contract-list.terminated-accounts.lbl-due-immediately' | translate }}</span>
      <ng-template #no_matbill>
        <div class="amount-past-due badge badge-error" *ngIf="accountInfoService.isPastDue(contractDetail.pastDueAmount); else terminateAccountWithBalanceDue">{{'ngw.global.past-due' | translate}}</div>
        <ng-template #terminateAccountWithBalanceDue>
          <span class="value" *ngIf="accountInfoService.isTerminatedAccountWithBalanceDue(contractDetail); else not_pastdue">{{'ngw.contract-list.terminated-accounts.lbl-due-immediately' | translate}}</span>
        </ng-template>
        <ng-template #not_pastdue>
          <span class="amount-due-date">{{"ngw.payment.header.due-date" | translate }} {{contractDetail.nextPaymentDueDate | date:'mediumDate'}}</span>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
