import { FinancialAccountDTOAccountType, PaymentAccountDTOAccountType } from "../core/gateway-api";

export class LeaseExtensionMonths {
  name: Date;
  value: number;
  paymentDate: Date;

  constructor(_name: Date, _value: number, _paymentdate: Date) {
    this.name = _name;
    this.value = _value;
    this.paymentDate = _paymentdate

  }
}

export class LeaseExtReviewDetails {
  accountno: string;
  customerno: number;
  faultType: string;
  error: boolean;
  faultMessage: string;
  confirmationNumber: number;  
  nextPaymentDueDate: Date;  
  financialAccountId: string;
  paymentDateStatus: string;
  accountType: FinancialAccountDTOAccountType;
  easyPayAccountType: PaymentAccountDTOAccountType;
  selectedMonthsOption: number;
  monthlyAmountDue: number;
  totalAmountDue: number;
  extendatedDates: any[] = [];
  paymentSource: string;
  isAllowToBackward: boolean;
  residualValue: number;
  newMaturityDate: Date;  
  extensionQuoteId: number;
  successfullyBooked: boolean;
  primaryEmailAddress: string;
  paymentConfEmailPreference: boolean;
  currentOdometer: number;
}

export class LeaseExtensionAgreementPdf {
  error: boolean;
  faultType: string;
  agreementPdf: any;
}
