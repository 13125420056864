import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LogSiteActivityStatus } from '../_models/logsiteactivity.model';
import {
  ContractAccountDetailDTO,
  LogSiteActivityRequest,
  LogSiteActivityRequestApplication,
  LogSiteActivityRequestBrand,
  LogSiteActivityRequestMarket,
  LogSiteActivityResponse,
  LogSiteActivityServiceClient
} from '../core/gateway-api';
import { UserService } from '../shared/_helper-services/user.service';
import { Constants } from '../shared/constants';
import { ActivityTypes, DeviceOS } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";
import { ApplicationConfig } from '../_models/application-config';


@Injectable()
export class LogSiteActivityService {

  private logSiteActivityRequest: LogSiteActivityRequest;
  private logSiteActivityResponse: LogSiteActivityResponse;
  private additionalInfo3: string;
  storeAppConfig: ApplicationConfig;
  constructor(private logSiteActivityServiceClient: LogSiteActivityServiceClient, private deviceDetectorService: DeviceDetectorService, private userService: UserService, private store: Store<IAppState>,) { }

  public logUserActivity(customerNumber: number, accountNumbers: string | string[], activityType: ActivityTypes, isDashboardLandingPage?: boolean, additionalInfo3?: string): void {
    if (!customerNumber || !accountNumbers) { return; }

    let accountNumberList: string[] = [];
    if (additionalInfo3) {
      this.additionalInfo3 = additionalInfo3
    } else {
      this.additionalInfo3 = Constants.EMPTY;
    }
    if (typeof accountNumbers === "string")
      accountNumberList.push(accountNumbers);
    else
      accountNumberList = accountNumbers;

    if (activityType == ActivityTypes.Legitimizationcompleted) {
      if (this.userService.isLegitimizationCompletedInCurrentSession()) {
        this.userService.setLegitimizationCompletedInCurrentSession(false);
        this.postLogUserActivity(customerNumber, accountNumberList, ActivityTypes.Legitimizationcompleted);
      }
    }
    else if (activityType == ActivityTypes.MyAccountLogin) {
      if (!this.userService.isHomePageActivityLogged()) {
        this.userService.setHomePageActivityLogged(true);
        this.postLogUserActivity(customerNumber, accountNumberList, ActivityTypes.MyAccountLogin, isDashboardLandingPage);
      }
    }
    else
      this.postLogUserActivity(customerNumber, accountNumberList, activityType);
  }

  private postLogUserActivity(customerNumber: number, accountNumbers: string[], activityType: ActivityTypes, isDashboardLandingPage?: boolean): void {
    let self = this;
    let arrayList = [];
    _.each(accountNumbers, function (accountNumber) {
      arrayList.push(self.postLogSiteActivityService(customerNumber, accountNumber, activityType));
    })

    if (activityType == ActivityTypes.MyAccountLogin)
      forkJoin(arrayList).subscribe(responses => { this.postlogLoginActivity(responses, customerNumber, accountNumbers, isDashboardLandingPage); });
    else
      forkJoin(arrayList).subscribe(responses => { this.postAllPageActivity(responses); });
  }

  private postlogLoginActivity(responses, customerNumber: number, accountNumbers: string[], isDashboardLandingPage?: boolean): void {
    let isError: boolean = false;
    responses.forEach((response) => {
      if (response != undefined && response.error) {
        isError = true;
      }
    });
    if (isDashboardLandingPage)
      this.postLogUserActivity(customerNumber, accountNumbers, ActivityTypes.Dashboard);
  }

  private postAllPageActivity(responses): void {
    let isError: boolean = false;
    responses.forEach((response) => {
      if (response != undefined && response.error) {
        isError = true;
      }
    });
  }

  private postLogSiteActivityService(customerNumber: number, accountNumber: string, functionCode: ActivityTypes): Observable<LogSiteActivityStatus> {
    this.logSiteActivityRequest = new LogSiteActivityRequest();
    this.logSiteActivityRequest.accountNumber = accountNumber;
    this.logSiteActivityRequest.customerNumber = customerNumber;
    this.logSiteActivityRequest.functionCode = functionCode;
    this.logSiteActivityRequest.functionText = Constants.EMPTY;
    this.logSiteActivityRequest.additionalInfo3 = this.additionalInfo3;
    let applicationConfig, tenants, brands, clientAPPID;
    this.store.select(state => state.ApplicationConfig).subscribe(x => applicationConfig = x);
    tenants = applicationConfig.TENANT;
    brands = applicationConfig.BRAND;
    clientAPPID = applicationConfig.CLIENT_APP_ID;
    if (applicationConfig && tenants && brands && clientAPPID) {
      let tenant = tenants === 'CA' ? 'CAN' : tenants;
      this.logSiteActivityRequest.market = LogSiteActivityRequestMarket[LogSiteActivityRequestMarket[tenant]];
      this.logSiteActivityRequest.brand = LogSiteActivityRequestBrand[LogSiteActivityRequestBrand[brands]];
      this.logSiteActivityRequest.deviceType = this.getDeviceType();
      this.logSiteActivityRequest.deviceOS = this.getDeviceOS();
      this.logSiteActivityRequest.browserName = this.getBrowser();
      this.logSiteActivityRequest.application = this.getResourceType(this.logSiteActivityRequest.deviceOS.toUpperCase());
      this.logSiteActivityRequest.gcID = this.userService.getGcid();
      return this.logSiteActivityServiceClient.logSiteActivity(this.logSiteActivityRequest)
        .pipe(mergeMap((response) => { return this.afterLogSiteActivityPostSuccess(response); })
          , catchError((error: any) => { return this.afterLogSiteActivityPostFailure(error); }));
    }
    return of(null);
  }

  private afterLogSiteActivityPostSuccess(result: any): Observable<LogSiteActivityStatus> {
    this.logSiteActivityResponse = result;
    let logSiteActivityStatus = new LogSiteActivityStatus();
    logSiteActivityStatus.error = false;
    logSiteActivityStatus.responseCode = this.logSiteActivityResponse.responseCode;
    return of(logSiteActivityStatus);
  }

  private afterLogSiteActivityPostFailure(error: any): Observable<LogSiteActivityStatus> {
    this.logSiteActivityResponse = error;
    let logSiteActivityStatus = new LogSiteActivityStatus();
    logSiteActivityStatus.error = true;
    logSiteActivityStatus.responseCode = this.logSiteActivityResponse.responseCode;
    return of(logSiteActivityStatus);
  }
  private getDeviceType(): string {
    let deviceType: string;
    if (this.deviceDetectorService.device.toLowerCase() === Constants.UnknownDeviceType) {
      switch (true) {
        case this.deviceDetectorService.isMobile():
          deviceType = Constants.MobileDevice;
          break;
        case this.deviceDetectorService.isDesktop():
          deviceType = Constants.DesktopDevice;
          break;
        case this.deviceDetectorService.isTablet():
          deviceType = Constants.TabletDevice;
          break;
        default:
          deviceType = Constants.EMPTY;
          break;
      }
    }
    else {
      deviceType = this.deviceDetectorService.device;
    }
    return deviceType;
  }

  private getDeviceOS(): string {
    let deviceOS: string;
    deviceOS = this.deviceDetectorService.os;
    return deviceOS;
  }
  private getBrowser(): string {
    let browserType: string;
    browserType = this.deviceDetectorService.browser;
    return browserType;
  }
  private getResourceType(deviceOS: string): LogSiteActivityRequestApplication {
    let client_app_id: string | undefined;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    client_app_id = this.storeAppConfig.CLIENT_APP_ID;
    if (client_app_id === Constants.App2ClientAppId) {
      if (deviceOS === DeviceOS.IOS || deviceOS === DeviceOS.MAC) {
        return LogSiteActivityRequestApplication.APP2_IOS;
      }
      else if (deviceOS === DeviceOS.ANDROID) {
        return LogSiteActivityRequestApplication.APP2_ANDROID;
      }
      else {
        return LogSiteActivityRequestApplication.APP2_SSO;
      }
    }
    else {
      return LogSiteActivityRequestApplication[client_app_id];
    }
  }

  public logActivity(activityType: ActivityTypes, accountNumbers?: string | string[]): void {
    let customerNumber = this.userService.getCustomerNumber();
    if (!accountNumbers) {
      let contractDetail: ContractAccountDetailDTO;       
      this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
      accountNumbers = contractDetail.accountNumber;
    }
    this.logUserActivity(customerNumber, accountNumbers, activityType);
  }
}
