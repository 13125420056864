import { TranslateService } from '@ngx-translate/core';

export class UploadAttachment {
    size: number;
    sizeMB: number;
    base64Data: string;
    name: string;
    type: string;
    attachmentDesc: string;
    

    constructor() {
        this.type = undefined;
        this.size = undefined;
        this.base64Data = undefined;
        this.name = undefined;
        this.sizeMB = undefined;
    }
}

export class Attachments {
    attachments: UploadAttachment[] = [];
    error: boolean;
    errorMessage: string;
    uploadLimit: number = 0;
    totalUploadDocumentsSize: number = 0;
    remainingUploadDocumentsSize: number = 0;
      
    constructor(private translateService: TranslateService) {
      this.attachments = new Array();
    }

    add(uploadAttachment: UploadAttachment) {
        if (uploadAttachment != undefined
            && this.checkAttachmentsSize(uploadAttachment)) {
            this.attachments.push(uploadAttachment);
        }
        else {
            this.error = true;
            this.translateService.get("ngw.secure-message-center.attachments.error-upload-limit", {uploadLimit: this.uploadLimit}).subscribe((res: string) => {
                 this.errorMessage = res;
            });
        }
    }

    delete(uploadAttachment: UploadAttachment) {
        if (uploadAttachment != undefined
            && uploadAttachment.name.length > 0) {

            // filtering by removing an item from array
            this.attachments = this.attachments.filter(x => x.name != uploadAttachment.name);
        }
    }

    checkAttachmentsSize(uploadAttachment: UploadAttachment): boolean {
        return ((uploadAttachment.size + this.totalUploadDocumentsSize) / 1024 / 1024) > this.uploadLimit ? false : true;
    }

    calculateTotalUploadedAttachmentsSize(): void {
        this.totalUploadDocumentsSize = 0;
        for (let uploadAttachment of this.attachments) {
            this.totalUploadDocumentsSize = this.totalUploadDocumentsSize + uploadAttachment.size;
        }
        this.remainingUploadDocumentsSize = + (this.uploadLimit - (this.totalUploadDocumentsSize / 1024 / 1024)).toFixed(1);
    }
}
