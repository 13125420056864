import { Injectable } from '@angular/core';
import {FinancialProductDTO} from '../../core/gateway-api';
import * as _ from 'lodash-es';
import { Constants } from '../constants';
import { FooterMenuItem } from '../enums';
import { PipeService } from './pipe.service';


@Injectable()
export class FilterService {

  constructor(private pipeService:PipeService){
  }
    public getFilteredFinancialProducts(financialProducts: FinancialProductDTO[]): FinancialProductDTO[]{
        return this.filterFinancialProducts(financialProducts);
    }

    public getFilteredAccountNumbers(financialProducts: FinancialProductDTO[]): string[] {
        let filteredFinancialProducts = this.filterFinancialProducts(financialProducts);
        let accountNumbers = _.map(filteredFinancialProducts, 'financialProductId');
        return accountNumbers;
    }
    
    public filterNumbersOnly(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode == Constants.backSpaceCode || (charCode >= Constants.minNumericCode && charCode <= Constants.maxNumericCode)) {
          return true;
        }
        return false;
    }

    private filterFinancialProducts(financialProducts: FinancialProductDTO[]): FinancialProductDTO[] {
        let filteredFinancialProducts = _.filter(financialProducts, function (fp) {
            return fp.selfServiceAuthorized;
        });
        return filteredFinancialProducts;
    }

    public filterFooterMenu(allMenuItem:any[],footerMenuItem?:FooterMenuItem):any[]{
        let footerMenuList=[];
        if(footerMenuItem){
              let menuItem = allMenuItem.filter(item => {
                return item.id !== FooterMenuItem.cookiePolicyLink;
              });
              menuItem.forEach(item => footerMenuList.push(item));
        }
        else{
          allMenuItem.forEach(item => footerMenuList.push(item));
        }
        return footerMenuList;
      }

    public filterOdometer(event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      let odometerValue = event.target.value;
      if(odometerValue.length === 0) {
        if(charCode == 48){
          return false;
        }
      }
      else {
        if(odometerValue.replace(',','').length >= 6){
          return false; 
        } 
      }  
      if (charCode == Constants.backSpaceCode || (charCode >= Constants.minNumericCode && charCode <= Constants.maxNumericCode)) {
        return true;
      }
      return false;
    }

    public onOdometerChange(odometer: any): any {      
      if(odometer.length > 0) {
        if(parseInt(odometer).toString().substring(0,1) == '0') {
          odometer = '';
        }
      }                 
      return this.pipeService.getNumberAsFormattedNumber(odometer, null, true);              
    }
}

