export class VehicleImageModel {
  vehicleImageData: string;
  financialProductId: string;
  vehicleImageFrontHighQuality: string;
  vehicleImageFrontLowQuality: string;
  vehicleImageAngledHighQuality: string;
  vehicleImageAngledLowQuality: string;

  constructor(
     _vehicleImageData: string,
     _financialProductId: string,
     _vehicleImageFrontHighQuality?: string,
     _vehicleImageFrontLowQuality?: string,
     _vehicleImageAngledHighQuality?: string,
     _vehicleImageAngledLowQuality?: string
     ) {
    this.vehicleImageData = _vehicleImageData;
    this.financialProductId = _financialProductId;
    this.vehicleImageFrontHighQuality = _vehicleImageFrontHighQuality;
    this.vehicleImageFrontLowQuality = _vehicleImageFrontLowQuality;
    this.vehicleImageAngledHighQuality = _vehicleImageAngledHighQuality;
    this.vehicleImageAngledLowQuality = _vehicleImageAngledLowQuality;
  }
}
