export class QuoteInformation {
    error: boolean;  
    stopSaleFlag: boolean;
    accountNumber: number;
    payoffAmount: number;
    goodThroughDate: Date;
    quoteDate: Date;
    securityDeposit: number;
    payoffLetterPdf: any;
    interestPerDiem: number;
    serviceError:boolean;
}

