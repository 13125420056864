import { GetMileageCalculationResponse } from '../../core/gateway-api';


export class SelectMiles {
    currentMiles: number;
    mileageCalculation: GetMileageCalculationResponse;
    recommendedMilesSectionEnabled: boolean;
    faultType: string;
    error: boolean;
}



