<div *ngIf="dataLoaded">
  <app-breadcrumb [title]="'ngw.payment.entry.bread-crumb' | translate" [backToEntryPage]="true"></app-breadcrumb>
  <app-contract-header-change-account></app-contract-header-change-account>
  <app-contract-header [contractDetail]="contractDetail"></app-contract-header>
  <div class="page-payment-confirmation">
    <div class="payment-confirmation-container" id="paymentconfirmationprint">
      <div class="confirmation-header">
        <h2>{{'ngw.payment.confirmation.title' | translate}}</h2>
        <button class="btn btn-primary print btn-icon no-print" (click)="print()" id="btnPrint">
          <span class="hidden">{{'ngw.payment.confirmation.print' | translate}}</span>
          <i class="fa fa-print" aria-hidden="true"></i>
        </button>
      </div>
      <div *ngIf="!this.paymentReviewDetails.error">
        <h3><i class="fa fa-check-circle" aria-hidden="true"></i> {{'ngw.payment.confirmation.thank-you' | translate}}</h3>
        <p>{{this.paymentConfirmationMessage}}</p>
        <div class="payment-confirmation-row payment-amount">
          <span class="label">{{'ngw.payment.lbl-amount' | translate}}</span>
          <span class="value" id="paymentConfirmAmount">{{this.paymentAmountDisplay | currency : 'USD':true}}</span>
        </div>
        <div class="payment-confirmation-row payment-source">
          <span class="label">{{'ngw.payment.lbl-source' | translate}}</span>
          <span class="value" id="paymentConfirmSource">{{this.paymentReviewDetails.paymentSource}}</span>
        </div>
        <div class="payment-confirmation-row">
          <span class="label">{{'ngw.payment.lbl-date' | translate}}</span>
          <span class="value" id="paymentConfirmDate">{{this.paymentReviewDetails.paymentDate | date:'mediumDate'}}</span>
        </div>
        <div class="payment-confirmation-row" *ngIf="showEmailAddress">
          <span class="label">{{'ngw.payment.lbl-email' | translate}}</span>
          <span class="value" id="paymentConfirmEmailAddress">{{this.paymentReviewDetails.emailAddress}}</span>
        </div>
        <div class="payment-confirmation-row" *ngIf="isLoanAccount && !payoffServiceError">
          <span class="label">{{'ngw.payment.confirmation.lbl-payoff' | translate}}</span>
          <span class="value" id="payoffAmount">{{payoffAmount}}</span>
        </div>
        <div class="payment-confirmation-row payment-confirmation-number">
          <span class="label">{{'ngw.payment.lbl-confirmation-number' | translate}}</span>
          <span class="value" id="paymentConfirmationNumber">{{this.paymentReviewDetails.confirmationNumber}}</span>
        </div>
        <div *ngIf="showCreditCardBanner" class="payment-confirmation-row">
          <app-credit-card-banner [cardHolderModel]="cardHolderModel">
          </app-credit-card-banner>
        </div>
        <form (submit)="navigateToHome()">
          <div class="action-buttons no-print">
            <button type="button" hidden> button</button>
            <button type="submit" setFocus id="btnReturnToHome" class="btn btn-primary" (click)="navigateToHome(); $event.preventDefault();">{{'ngw.payment.btn-return' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
