import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { PaymentSourceDeleteReview } from '../../../_models/delete-payment-source.model';


@Injectable()
export class PaymentSourceActions {
    static SET_PAYMENT_SOURCE_DELETE_REVIEW: string = 'SET_PAYMENT_SOURCE_DELETE_REVIEW';
    static CLEAR_PAYMENT_SOURCE_DELETE_REVIEWS: string = 'CLEAR_PAYMENT_SOURCE_DELETE_REVIEWS';

    setPaymentSourceDeleteReview(paymentSourceDeleteReview: PaymentSourceDeleteReview): FSA<string, PaymentSourceDeleteReview, null> {
        return {
            type: PaymentSourceActions.SET_PAYMENT_SOURCE_DELETE_REVIEW,
            payload: paymentSourceDeleteReview,
            meta: null
        };
    }


    clearPaymentSourceDeleteReview(): FSA <string, PaymentSourceDeleteReview, null> {
      return {
          type: PaymentSourceActions.CLEAR_PAYMENT_SOURCE_DELETE_REVIEWS,
        payload: null,
        meta: null
      };
    }}

@Injectable()
export class  PaymentSourceDeleteReviewReducers {
    static PaymentSourceDeleteReviewReducers: Reducer<PaymentSourceDeleteReview> = (state: PaymentSourceDeleteReview = new PaymentSourceDeleteReview(), action: FSA<string, PaymentSourceDeleteReview, void>) => {
        switch (action.type) {
            case PaymentSourceActions.SET_PAYMENT_SOURCE_DELETE_REVIEW:
                return tassign(state, action.payload);
            case PaymentSourceActions.CLEAR_PAYMENT_SOURCE_DELETE_REVIEWS:
                return tassign(state, action.payload);
            default:
                return state;
        }
  }
    }
