import { Directive, OnInit, ElementRef, Input } from '@angular/core';
import { AnalyticsService } from '../shared/_helper-services/analytics.service';

@Directive({
    selector: '[analytics-error]'
})
export class AnalyticsErrorDirective implements OnInit {

    constructor(private elementRef: ElementRef, private analyticsService: AnalyticsService) { };

    @Input('analytics-error') errorName: string;
    @Input('analytics-error-details') errorDetails: any = undefined;

    ngOnInit(): void {
        this.analyticsService.pushErrorEvent(this.errorName, this.errorDetails);
    }

}
