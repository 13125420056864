import { GetMidtermMileageAdjustmentResponse } from '../../core/gateway-api';


export class MidtermMileageAdjustment {
    midtermMileageAdjustmentData: GetMidtermMileageAdjustmentResponse;  
    milesToPurchase: number;
    milesToPurchaseCost: number;
    faultType: string;
    error: boolean;
}

export class MMAAgreementPdf {
    error: boolean;
    faultType: string;
    agreementDocumentData: any;
}



