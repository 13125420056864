import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { map } from 'rxjs/operators';
import { CashAccountViewService } from "../_web-services/cash-account-view.service";
import { ContractAccountDetailDTO } from "../core/gateway-api";
import { AccountInfoService } from "../shared/_helper-services/account-info.service";
import { ObjectService } from "../shared/_helper-services/object.service";
import { UserService } from "../shared/_helper-services/user.service";
import { Constants } from "../shared/constants";
import { ActiveContractAccountDetailsActions } from "../shared/store/reducers/active-contract-account-details.reducers";
import { ContractAccountDetailActions } from "../shared/store/reducers/contract-account-detail.reducers";
import { ContractAccountDetailsActions } from "../shared/store/reducers/contract-account-details.reducers";
import { RouterService } from './../shared/_helper-services/router.service';
import { IAppState } from "./../shared/store/app.store";
import { PartnerContactService } from '../_web-services/partner-contact.service';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { FilterService } from '../shared/_helper-services/filter.service';
import { ContactActions } from '../shared/store/reducers/contact.reducers';

export const ContractsResolve: ResolveFn<any> = (activeRoute: ActivatedRouteSnapshot) => {
  const store = inject(Store<IAppState>);
  const routerService = inject(RouterService);
  const cashAccountViewService = inject(CashAccountViewService);
  const contractAccountDetailActions = inject(ContractAccountDetailActions);
  const contractAccountDetailsActions = inject(ContractAccountDetailsActions);
  const activeContractAccountDetailsActions = inject(ActiveContractAccountDetailsActions);
  const userService = inject(UserService);
  const accountInfoService = inject(AccountInfoService);
  const objectService = inject(ObjectService);
  const partnerContactService = inject(PartnerContactService);
  const fsTokenErrorHandler = inject(FSTokenErrorHandler);
  const filterService = inject(FilterService);
  const contactActions = inject(ContactActions);

  let contractsList: ContractAccountDetailDTO[];
  let currentContract: ContractAccountDetailDTO;
  let customerNumber: number;
  let accountNumbers: string[];
  let vin: string;
  let loadContractsAndSetCurrentContract = () => {
    if (accountNumbers.length > 0) {
      return fetchAccounts(customerNumber, accountNumbers, vin);
    }
    else {
      return partnerContactService.getContactByGcidClientId().pipe(map(response => {
        if (response && !response.error) {
          afterGetContactByGcidSuccess(response);
        }
        else {
          afterGetContactByGcidFailure(response.error);
        }
      }));
    }
  }

  let fetchAccounts = (customerNumber: number, accountNumbers: string[], vin: string) => {
    if (objectService.isEmptyObject(contractsList) || !contractsList) {
      return loadContractsListAndSetCurrentContract(customerNumber, accountNumbers, vin);
    } else {
      setCurrentContractFromList(vin);
    }
  }

  let loadContractsListAndSetCurrentContract = (customerNumber: number, accountNumbers: string[], vin: string) => {
    if (customerNumber && accountNumbers && accountNumbers.length > 0) {
      return cashAccountViewService.findAccounts(customerNumber, accountNumbers, false).pipe(map(response => {
        if (response != undefined && !response.error) {
          contractsList = response.contractAccountDetails;
          store.dispatch(contractAccountDetailsActions.setContracts(contractsList));

          let activeContractDetails = accountInfoService.getActiveAccounts(contractsList);
          store.dispatch(activeContractAccountDetailsActions.setActiveContracts(activeContractDetails));
          contractsList = accountInfoService.getSortedActiveContractsAndTerminated(contractsList);
          setCurrentContractFromList(vin);
        }
      }));
    }
  }

  let setCurrentContractFromList = (vin?: string) => {
    let currentContract: ContractAccountDetailDTO;
    //deeplink emails only contain last7 of the vin
    if (vin == null || undefined) {
      currentContract = contractsList[0];
    }
    else {
      currentContract = _.find(contractsList, function (contract: ContractAccountDetailDTO) {
        return contract.vIN == vin.toUpperCase() || contract.vIN.endsWith(vin.toUpperCase());
      })
    }
    if (currentContract) {
      store.dispatch(contractAccountDetailActions.setContractAccountDetail(currentContract));
      userService.setChatBotVisibleStatus(true);
    }
    else {
      routerService.navigateToHome();
    }

    if (userService.isInConnectedAppMode() && currentContract && accountInfoService.isTerminatedAccount(currentContract.statusCategoryCode)) {
      routerService.navigateToHome();
    }
  }

  let afterGetContactByGcidSuccess = (response: any) => {
    let filteredFinancialProducts = filterService.getFilteredFinancialProducts(response.contact.financialProducts);
    response.contact.financialProducts = filteredFinancialProducts;
    accountInfoService.enCodeAndSaveCosyUrl(filteredFinancialProducts);
    store.dispatch(contactActions.setContact(response.contact));
    let accountNumbers = filterService.getFilteredAccountNumbers(response.contact.financialProducts);
    userService.setAccountNumbers(accountNumbers);     
    return fetchAccounts(customerNumber, accountNumbers, vin);    
  }

  let afterGetContactByGcidFailure = (error: any) => {
    fsTokenErrorHandler.handleFSTokenError(error);
    routerService.navigateToHome();
  }
  
  vin = activeRoute.queryParams ? activeRoute.queryParams.vin : Constants.EMPTY;
  customerNumber = userService.getCustomerNumber();
  accountNumbers = userService.getAccountNumbers();
  store.select(state => state.ContractAccountDetail).subscribe(x => currentContract = x);
  //if the current contract is in state continue, if it's not in state try to get it either form state or from findaccounts
  if (objectService.isEmptyObject(currentContract) || !currentContract || (vin && currentContract.vIN != vin)) {
    //check if there're contracts - if not load them
    //if vin is defined -> look in all contracts list
    if (vin) {
      store.select(state => state.ContractAccountDetails).subscribe(x => contractsList = x);
      return loadContractsAndSetCurrentContract();
    }
    //if not -> look first in active contracts and then in terminated contracts
    else {
      store.select(state => state.SortedActiveContractAccountDetails).subscribe(x => contractsList = x);
      if (objectService.isEmptyObject(contractsList) || !contractsList) {
        store.select(state => state.SortedTerminatedContractAccountDetails).subscribe(x => contractsList = x);
        return loadContractsAndSetCurrentContract();
      }
      else {
        setCurrentContractFromList();
      }
    }
  }

};