import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { IAppState } from "../store/app.store";
import { Store } from '@ngrx/store';
import { Constants } from "../constants";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { OfferService } from "../../_web-services/offer.service";
import { DataLoadedActions } from "../store/reducers/dataloaded.reducer";
import { PaymentEstimationService } from "../../../_shared/_web-services/payment-estimation.service";
import { AccountInfoService } from "./account-info.service";
import { LogSiteActivityService } from "../../_web-services/log-site-activity.service";
import { RouterService } from './router.service';
import { Offers } from "projects/_shared/_models/offerModel";
import { EnvironmentConfig } from "projects/_shared/_models/environment-config";
import { ContractAccountDetailDTO } from "projects/_shared/core/gateway-api";

interface Dictionary<T> {
  [Key: string]: T;
}

export class EventBusCall {
  public constructor(
    public event: string,
    public params?: Dictionary<any> | string | boolean,
    public callback?: (response: any) => any
  ) { }
}

export type EventBusEvent = ({ params, callback }: Partial<EventBusCall>) => void;

@Injectable()
export class MicroFrontendHelperService {

  private subscription: Observable<any> = new Observable();

  public constructor(
    private dataLoadedActions: DataLoadedActions,
    private offerService: OfferService,
    private paymentEstimationService: PaymentEstimationService,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private userService: UserService, private accountInfoService: AccountInfoService,
    private logSiteActivityService: LogSiteActivityService,
    private routerService: RouterService,
  ) { }

  private events: Dictionary<EventBusEvent> = {
    GetBrand: ({ callback }): void => {
      let brand: string;
      this.store.select(state => state.ApplicationConfig.BRAND).subscribe(x => brand = x);
      if (callback) callback(brand);
    },

    GetContracts: ({ callback }): void => {
      let contracts: ContractAccountDetailDTO[];
      this.store.select(state => state.ContractAccountDetails).subscribe(x => contracts = x);
      if (callback) callback(contracts);
    },

    GetCountry: ({ callback }): void => {
      let country: string;
      this.store.select(state => state.ApplicationConfig.COUNTRY_CODE).subscribe(x => country = x);
      if (callback) callback(country);
    },

    GetEnvironmentConfig: ({ callback }): void => {
      let config: EnvironmentConfig;
      this.store.select(state => state.EnvironmentConfig).subscribe(x => config = x);
      if (callback) callback(config);
    },

    GetLanguage: ({ callback }): void => {
      const language = this.translate.currentLang;
      if (callback) callback(language);
    },

    GetLanguageSubscription: ({ callback }): void => {
      const subscription = this.translate.onLangChange;
      if (callback) callback(subscription);
    },

    GetOffers: ({ callback }): void => {
      if (callback) {
        let offers: Offers;
        this.store.select(state => state.offers).subscribe(x => offers = x);
        callback(offers)
      };
    },

    GetOfferService: ({ callback }): void => {
      if (callback) callback(this.offerService);
    },

    GetPaymentEstimationService: ({ callback }): void => {
      if (callback) callback(this.paymentEstimationService);
    },

    GetAccountInfoService: ({ callback }): void => {
      if (callback) callback(this.accountInfoService);
    },

    GetSubscription: ({ callback }): void => {
      if (callback) callback(this.subscription);
    },

    GetUserService: ({ callback }): void => {
      if (callback) callback(this.userService);
    },

    SetIsLoadedStatus: ({ params: isLoaded }): void => {
      if (typeof (isLoaded) != 'boolean') {
        console.warn(`SetIsLoadedStatus expected a boolean but was passed ${typeof (isLoaded)} instead`);
        return;
      }

      this.store.dispatch(this.dataLoadedActions.setDataLoaded(Boolean(isLoaded)));
    }
    ,
    GetLogSiteActivity: ({ callback }): void => {
      if (callback) callback(this.logSiteActivityService);
    },
    RouterService: ({ callback }): void => {
      if (callback) callback(this.routerService)
    }
  };

  public GetElementId(remoteName) {
    return `${Constants.elementIdPrefix}${remoteName}`;
  }

  public InitEventBus(): void {
    window.addEventListener(Constants.hostEventName, this.Listener)
  }

  private Listener = (e: any): void => {
    const { event: eventName, params, callback } = e.detail as EventBusCall;
    const event = this.events[eventName];
    if (!event) {
      console.warn(`Could not find the following ${Constants.hostEventName} ${eventName}`);
      return;
    }

    event({ params, callback });
  }

  public Notify(detail: EventBusCall): void {
    window.dispatchEvent(new CustomEvent(Constants.remoteEventName, { detail }))
  }

  public RemoveEventBus(): void {
    window.removeEventListener(Constants.hostEventName, this.Listener);
  }

  public StartRemote(remoteName: string): void {
    window.dispatchEvent(new CustomEvent(Constants.startRemoteEventName, { detail: remoteName }));
  }
}
