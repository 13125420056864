<div class="agreement-container">
  <mat-checkbox [(ngModel)]="isChecked" (change)="setAgreementAcceptanceStatus(isChecked)"></mat-checkbox>
  <div class="panel" id="panelContentScroll">
    <h4 *ngIf="isInEsignMode">{{'ngw.onboarding.legal-agreements.esign.title' | translate}}</h4>
    <div id="legalTerms" [innerHTML]="agreementHtml"></div>
  </div>
  <span class="panel-button" (click)="toggleAccordian($event)">
    <img src="../../../../../../../../../assets/images/panel-arrow.svg" alt="Open panel arrow down icon" />
  </span>
  <a *ngIf="isInTermsMode" target="_blank" href="javascript:void(0);" [routerLink]="termsViewRoute" class="full-doc-read" [ngClass]="{'active': isExpanded}">{{'ngw.onboarding.legal-agreements.terms.lnk-new-window' | translate}}</a>
  <a *ngIf="isInEsignMode" target="_blank" href="{{esignConsentAgreementPdfPath}}" class="full-doc-read" [ngClass]="{'active': isExpanded}">{{'ngw.onboarding.legal-agreements.esign.lnk-new-window' | translate}}</a>
  <a *ngIf="isInProfileMode" target="_blank" href="javascript:void(0);" [routerLink]="termsViewRoute" class="full-doc-read" [ngClass]="{'active': isExpanded}">{{'ngw.onboarding.legal-agreements.profile.lnk-new-window' | translate}}</a>
  <span class="panel-shadowbox-bottom"></span>
</div>

