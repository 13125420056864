import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { AccountIncentiveModel } from '../../../_models/account-incentive.model';

@Injectable()
export class AccountIncentiveActions {
  static SET_ACCOUNT_INCENTIVE: string = 'SET_ACCOUNT_INCENTIVE';
  static CLEAR_ACCOUNT_INCENTIVE: string = 'CLEAR_ACCOUNT_INCENTIVE';

  setAccountIncentive(accountIncentive: AccountIncentiveModel): FSA<string, any, null> {
    return {
      type: AccountIncentiveActions.SET_ACCOUNT_INCENTIVE,
      payload: accountIncentive,
      meta: null
    };
  }
    
  clearAccountIncentive(): FSA<string, any, null> {
    return {
      type: AccountIncentiveActions.CLEAR_ACCOUNT_INCENTIVE,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class AccountIncentiveReducers {
  static AccountIncentiveReducer: Reducer<AccountIncentiveModel> = (state: AccountIncentiveModel = new AccountIncentiveModel(), action: FSA<string, any, void>) => {
    switch (action.type) {
      case AccountIncentiveActions.SET_ACCOUNT_INCENTIVE:
        return tassign(state, action.payload);
      case AccountIncentiveActions.CLEAR_ACCOUNT_INCENTIVE:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
