import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageCenterService } from '../../../../_web-services/message-center.service'
import { ContractAccountDetailDTO, ExpireThreadsWebResponse } from '../../../../core/gateway-api';
import { LogSiteActivityService } from '../../../../_web-services/log-site-activity.service';
import { ActivityTypes } from '../../../../shared/enums';
import { UserService } from '../../../../shared/_helper-services/user.service';
import { AnalyticsService } from '../../../../shared/_helper-services/analytics.service';
import { IAppState } from "../../../../shared/store/app.store";
import * as _ from 'lodash-es';
import { Constants } from '../../../../shared/constants';

@Component({
    selector: 'app-delete-msg-dialog',
    templateUrl: './delete-msg-dialog.component.html',
    styleUrls: ['./delete-msg-dialog.component.scss']
})
export class DeleteMsgComponentDialog implements OnInit {

    onDeleteThreads = new EventEmitter();
    onExpiredThreadSuccessMessage: string;
    onExpiredThreadError: string;

    threadIds = [];
    constructor(private translateService: TranslateService,
        private dialogRef: MatDialogRef<DeleteMsgComponentDialog>,
        private messageCenterService: MessageCenterService,
        private logSiteActivityService: LogSiteActivityService,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private store: Store<IAppState>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.translateService.get("ngw.secure-message-center.inbox.delete-thread.success").subscribe((res: string) => {
            this.onExpiredThreadSuccessMessage = res;
        });

        this.translateService.get("ngw.global.technical-error").subscribe((res: string) => {
            this.onExpiredThreadError = res;
        });

        if (this.data) {
            this.threadIds = this.data.threadIds;
        }
        this.analyticsService.pushSiteToolEvent({ siteTool: Constants.DeleteSMCMessage, siteToolStep: Constants.SiteToolStart });
        this.logUserActivity();
    }

    deleteThreads() {
        if (this.threadIds.length > 0) {
            this.messageCenterService.expireThreads(this.threadIds)
                .subscribe(ExpireThreadsWebResponse => { this.postDeleteSMCThreads(ExpireThreadsWebResponse); });;
        }
    }

    private postDeleteSMCThreads(expireThreadResponse: ExpireThreadsWebResponse) {
        if (expireThreadResponse.expiredThreads !== undefined) {
            this.analyticsService.pushSiteToolEvent({ siteTool: Constants.DeleteSMCMessage, siteToolStep: Constants.SiteToolComplete });
        }
        this.onDeleteThreads.emit(expireThreadResponse.expiredThreads == undefined ? this.onExpiredThreadError : this.onExpiredThreadSuccessMessage);
        this.dialogRef.close();
    }

    private logUserActivity() {
        let contractAccountDetails: ContractAccountDetailDTO[];
        this.store.select(state => state.ContractAccountDetails).subscribe(x => contractAccountDetails = x);
        if (this.userService.getCustomerNumber() && contractAccountDetails) {
            let sortedContracts = _.orderBy(contractAccountDetails, ['year', 'model'], ['desc', 'asc'])
            this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), sortedContracts[0].accountNumber, ActivityTypes.SecureMessageCenterDeleteMessage);
        }
    }

    cancelDeleteThreads() {
        this.dialogRef.close(true);
    }
}
