import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContractAccountDetailDTO } from '../../../../../../_shared/core/gateway-api';
import { Constants } from '../../../../../../_shared/shared/constants';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AddAchPaymentSourceCanModel } from '../../../../../../_shared/_models/add-ach-payment-source-can.model';
import { ACHErrorType } from '../../../../../../_shared/_models/payment.model';
import { CustomerACHService } from '../../../../../../_shared/_web-services/customer-ach.service';
import { LogSiteActivityService } from '../../../../../../_shared/_web-services/log-site-activity.service';
import { BaseApplicationComponent } from '../../../../../../_shared/core/basecomponent/base.component';
import { AddChequingAccountHelperService } from '../../../../../../_shared/shared/_helper-services/add-chequing-account-helper.service';
import { RouterService } from '../../../../../../_shared/shared/_helper-services/router.service';
import { UserService } from '../../../../../../_shared/shared/_helper-services/user.service';
import { WindowService } from '../../../../../../_shared/shared/_helper-services/window.service';
import { ActivityTypes } from '../../../../../../_shared/shared/enums';
import { IAppState } from '../../../../../../_shared/shared/store/app.store';
import { AddChequingAccountActions } from '../../../../../../_shared/shared/store/reducers/add-chequing-account.reducer';
import { CanNavigateBackActions } from "../../../../../../_shared/shared/store/reducers/can-navigate-back.reducer";

@Component({
  selector: 'app-add-ach-payment-sourc-can',
  templateUrl: './add-ach-payment-source.component.can.html',
  styleUrls: ['./add-ach-payment-source.component.can.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddACHPaymentSourceCanComponent extends BaseApplicationComponent<AddACHPaymentSourceCanComponent> implements OnInit {
  hasSubmitAchPaymentError: boolean = false;
  contractDetail: ContractAccountDetailDTO;
  addAchPaymentSourceCanModel: AddAchPaymentSourceCanModel;
  disclaimerCheckbox: boolean = false;

  constructor(injector: Injector,
    private logSiteActivityService: LogSiteActivityService,
    private userService: UserService,
    private _location: Location,
    private translateService: TranslateService,
    private windowService: WindowService,
    private canNavigateBackActions: CanNavigateBackActions,
    private addChequingAccountActions: AddChequingAccountActions,
    private addChequingAccountHelperService: AddChequingAccountHelperService,
    private customerACHService: CustomerACHService,
    store: Store<IAppState>,
    @Inject(DOCUMENT) private _document,
    private routerService: RouterService
  ) {
    super(injector, _document);
    this.addAchPaymentSourceCanModel = new AddAchPaymentSourceCanModel(this.translateService);
  }

  ngOnInit() {
    this.dataLayer = {
      page: {
        name: `${Constants.MyAccount}:${Constants.PaymentSource}:${Constants.Add}`,
        pageType: Constants.PaymentDetails,
        pageLevel2: Constants.Accounts,
        pageLevel3: Constants.PaymentDetails,
        pageLevel4: Constants.PaymentSource,
        title: `${Constants.PaymentDetails}:${Constants.PaymentSource}`
      }
    }
    super.pushDataLayer();

    this.windowService.scrollToTop(true);
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);

    this.init();
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(false));
  }

  public init() {
  }

  public submitChequingAccountDetails() {
    this.setDataLoadedOnRequest(false);

    this.postValidateRoutingNumber();
  }

  private postValidateRoutingNumber() {
    this.addAchPaymentSourceCanModel.error = false;
    //Give the Service Call for Submit and then Redirect to Confirmation Page. Also fill the Model with the Confirmation Number
    this.bindAchPaymentSourceDetails();
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.logSiteActivityService.logUserActivity(this.userService.getCustomerNumber(), this.contractDetail.accountNumber, ActivityTypes.AddChequingAccountSubmit);
    this.addChequingAccountHelperService.submitAddAchRequest(this.contractDetail.accountNumber, this.addAchPaymentSourceCanModel).subscribe(response => {
      this.setDataLoadedOnRequest(true);
      if (response != null && !response.error) {
        this.store.dispatch(this.addChequingAccountActions.setAddChequingAccount(this.addAchPaymentSourceCanModel));
        this.routerService.navigateToAddACHConfirmation();
      }
      else {
        this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(false));
        this.hasSubmitAchPaymentError = true;
      }
    });
  }

  public navigateToHome() {
    this._location.back();
  }

  public bindAchPaymentSourceDetails() {
    if (this.isFormValid())
      this.store.dispatch(this.addChequingAccountActions.setAddChequingAccount(this.addAchPaymentSourceCanModel));
  }

  public validateForm(addAchPaymentSourceCanModel: AddAchPaymentSourceCanModel): boolean {
    this.addAchPaymentSourceCanModel = addAchPaymentSourceCanModel;
    return this.isFormValid();
  }

  isFormValid(): boolean {
    return this.isValidRequiredFieldValues()
      && this.addAchPaymentSourceCanModel
      && this.addAchPaymentSourceCanModel.attachment
      && this.addAchPaymentSourceCanModel.attachment.attachments
      && this.addAchPaymentSourceCanModel.padAgreementAttachment
      && this.addAchPaymentSourceCanModel.padAgreementAttachment.attachments
      && this.addAchPaymentSourceCanModel.chequeAttachment
      && this.addAchPaymentSourceCanModel.chequeAttachment.attachments
      && this.addAchPaymentSourceCanModel.attachment.attachments.length >= Constants.minimumFilesToUploadAchPaymentSourceCan
      && (this.addAchPaymentSourceCanModel.padAgreementAttachment.attachments.length > 0 && this.addAchPaymentSourceCanModel.chequeAttachment.attachments.length > 0);
  }

  private isValidRequiredFieldValues(): boolean {
    return this.addAchPaymentSourceCanModel
      && this.addAchPaymentSourceCanModel.disclaimer
  }

  public navigateToContactUs() {
    this.routerService.navigateToContactUs();
  }

  ngOnDestroy() {
  }
}
