export class ForgotPasswordChangeStatus {
  error: boolean;
  errortype: ForgotPasswordChangeErrorType;
}

export enum ForgotPasswordChangeErrorType {
  InvalidTokenFault,
  AuthenticationFailedFault,
  UserAccountPasswordLockedFault,
  InvalidPasswordFault,
  UserNotFoundFault,
  ForgotPasswordChangeFailed
}
