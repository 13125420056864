import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { StringModel } from '../../../_models/string.model';


@Injectable()
export class PageTitleActions {
  static SET_PAGE_TITLE: string = 'SET_PAGE_TITLE';


  setPageTitle(pagetitle: string): FSA<string, string, null> {
    return {
      type: PageTitleActions.SET_PAGE_TITLE,
      payload: pagetitle,
      meta: null
    };
  }
}

@Injectable()
export class PageTitleReducers {
  static PageTitleReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
    switch (action.type) {
      case PageTitleActions.SET_PAGE_TITLE:
        return tassign(state, new StringModel(action.payload));
      default:
        return state;
    }
  }
}