import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minusToParens'
})
export class MinusSignToParensPipe implements PipeTransform {

    transform(value: any, args?: any): any {
      if(value){
        let minusExist = value.charAt(0) === '-';
        if (!minusExist) return value;
        let valueWithoutMinus = value.replace('-', '(') + ')';
        return valueWithoutMinus;
      }
       return value;
       
    }
}
