import { VehicleReturnEstimateDTO } from "../../core/gateway-api";

export class VehicleReturnModel {

  vehicleReturnEstimate: VehicleReturnEstimateDTO;
  error: boolean;
  errorType: any;
  accountNumber: string;
  constructor() {
    this.vehicleReturnEstimate = new VehicleReturnEstimateDTO();
    this.error = false;
    this.errorType = null;
  }

}

export enum VehicleReturnErrorType {
  NoInspection = 1,
}

