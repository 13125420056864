import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { Offers } from '../../../_models/offerModel';


@Injectable()
export class OfferActions {
  static SET_OFFER:string = 'SET_OFFER';
  static CLEAR_OFFER: string = 'CLEAR_OFFER';

  setOffer(offer: Offers): FSA<string, any, null> {
    return {
      type: OfferActions.SET_OFFER,
      payload: offer,
      meta: null
    };
  }
    
  clearOffer(): FSA<string, any, null> {
    return {
      type: OfferActions.CLEAR_OFFER,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class OffersReducers {
  static OffersReducer: Reducer<Offers> = (state: Offers=new Offers(), action: FSA<string, any, void>) => {
    switch (action.type) {
      case OfferActions.SET_OFFER:
        return tassign(state, action.payload);   
      case OfferActions.CLEAR_OFFER:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
