import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { PaymentReviewDetails } from '../../../_models/payment.model';
import { tassign } from 'tassign';


@Injectable()
export class PaymentReviewDetailsActions {
  static SET_PAYMENT_REVIEW_DETAILS: string = 'SET_PAYMENT_REVIEW_DETAILS';
  static CLEAR_PAYMENT_REVIEW_DETAILS: string = 'CLEAR_PAYMENT_REVIEW_DETAILS';

  setPaymentReviewDetails(paymentReviewDetails: PaymentReviewDetails): FSA<string, PaymentReviewDetails, null> {
    return {
      type: PaymentReviewDetailsActions.SET_PAYMENT_REVIEW_DETAILS,
      payload: paymentReviewDetails,
      meta: null
    };
  }

  clearPaymentReviewDetails(): FSA<string, PaymentReviewDetails, null> {
      return {
        type: PaymentReviewDetailsActions.CLEAR_PAYMENT_REVIEW_DETAILS,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class PaymentReviewDetailsReducers {
  static PaymentReviewDetailsReducer: Reducer<PaymentReviewDetails> = (state: PaymentReviewDetails = new PaymentReviewDetails(), action: FSA<string, PaymentReviewDetails, void>) => {
    switch (action.type) {
      case PaymentReviewDetailsActions.SET_PAYMENT_REVIEW_DETAILS:
        return tassign(state, action.payload);
      case PaymentReviewDetailsActions.CLEAR_PAYMENT_REVIEW_DETAILS:
         return tassign(undefined);
      default:
        return state;
    }
  }
}
