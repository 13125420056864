import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { StringModel } from '../../../_models/string.model';

import { tassign } from 'tassign';


@Injectable()
export class ToastActions {
  static SET_TOAST: string = 'SET_TOAST';
  static CLEAR_TOAST: string = 'CLEAR_TOAST';


  setToast(toast: string): FSA<string, string, null> {
    return {
      type: ToastActions.SET_TOAST,
      payload: toast,
      meta: null
    };
  }

  clearToast(): FSA<string, string, null> {
      return {
          type: ToastActions.CLEAR_TOAST,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class ToastReducers {
  static ToastReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
    switch (action.type) {
      case ToastActions.SET_TOAST:
            return tassign(state, new StringModel(action.payload));
      case ToastActions.CLEAR_TOAST:
          return tassign(undefined);
      default:
        return state;
    }
  }
}
