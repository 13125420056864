import { MessageSubjectClassificationDTO, MessageSubjectTypeDTO, SecureMessageAttachmentInputDTO, ContractAccountDetailDTO } from '../../core/gateway-api';
import { Constants } from '../../shared/constants';

export class ComposeMessageModel {
    error: boolean;
    errorMessage: string;
    faultType: string;
    subject: Subject;
    account: Account;
    creditCardMessage: string;
    email: string;
    attachments: SecureMessageAttachmentInputDTO[];
    body: string;


    constructor() {
        this.error = false;
        this.errorMessage = Constants.EMPTY;
        this.faultType = Constants.EMPTY;
        this.subject = new Subject();
        this.account = new Account();
        this.creditCardMessage = Constants.EMPTY;
        this.email = Constants.EMPTY;
        this.attachments = [];
    }
}

export class Account {
    options: MessageSubjectClassificationDTO[];
    selected: ContractAccountDetailDTO;
    label: string;
    caption: string;
    isVisible: boolean;
    accountType: AccountType

    constructor() {
        this.options = [];
        this.selected = null;
        this.label = Constants.EMPTY;
        this.caption = Constants.EMPTY;
        this.isVisible = false;
        this.accountType = AccountType.Contract;
    }
}

export enum AccountType {
    Contract = 1,
    Vehicle = 2
}

export class Subject {
    options: MessageSubjectClassificationDTO[];
    selected: SubjectTopic;

    constructor() {
      this.options = [];
      this.selected = new SubjectTopic();
    }
}

export class SubjectTopic {
    topic: MessageSubjectClassificationDTO;
    subtopic: MessageSubjectTypeDTO;

    constructor() {
        this.topic = new MessageSubjectClassificationDTO();
        this.subtopic = new MessageSubjectTypeDTO();
    }
}




