import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: { '[class]': 'customClass' }
})
export class TitleComponent implements OnInit {

    @Input() title: string;
    @Input() titleKey: string;
    customClass: string = Constants.TitleCustomClass;

    constructor(private translateService: TranslateService) { }

    ngOnInit() {
        if (this.titleKey && this.titleKey !== "") {
            this.translateService.get(this.titleKey).subscribe((res: string) => {
                this.title = res;
            })
        }
    }

}
