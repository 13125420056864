import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../shared/constants';
import { Attachments } from './uploadAttachment.model';


export class AddAchPaymentSourceCanModel {
  error: boolean;
  errorMessage: string;
  attachment: Attachments;
  chequeAttachment: Attachments;
  padAgreementAttachment: Attachments;

  bankNumber: string = Constants.EMPTY;
  bankTransitNumber: string = Constants.EMPTY;
  bankAccountNumber: string = Constants.EMPTY;
  confirmationNumber: string = Constants.EMPTY;
  disclaimer: boolean = false;
  isValidBankNumber: boolean = false;
  isValidBankTransitNumber: boolean = false;
  isValidBankAccountNumber: boolean = false;

  constructor(private translateService: TranslateService) {
    this.attachment = new Attachments(translateService);
    this.chequeAttachment = new Attachments(translateService);
    this.padAgreementAttachment = new Attachments(translateService);
  }

}
