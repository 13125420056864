import { Injectable } from '@angular/core';
import { RouterService } from "./router.service";
import { LanguageService } from '../../shared/_helper-services/language.service';
import { Constants } from '../constants';

@Injectable()
export class GuardService {

  public routeBeforeGuardFailed: string;
  public guardHasFailed: boolean;

  constructor(private routerService: RouterService, private languageService: LanguageService) {
    this.routeBeforeGuardFailed = null;
    this.guardHasFailed = false;
  }

  public guardSucceded(): boolean {
    this.guardHasFailed = false;
    return true;
  }

  public guardFailed(route?: string, isExternal?: boolean, paramsObject?: object): boolean {
    this.guardHasFailed = true;
    if (isExternal) {
      this.routerService.navigateToExternalUrl(route);
    }
    else {
      this.routeBeforeGuardFailed = route;
      this.routerService.navigateToTarget(route, paramsObject);
    }
    return false;
  }

  public setLanguage(state: any): void {
    let language = state._root.value.queryParams[Constants.LanguageParameterName];
    if (language !== undefined)
      this.languageService.setCurrentLanguage(language);
  }
}
