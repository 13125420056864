<div class="contract-header-component-container">
  <app-contract-header [contractDetail]="contractDetail"></app-contract-header>
  <a class="summary-link" (click)="scrollToSummary()">{{'ngw.vehicle-return.estimated-summary.title' | translate}}</a>
</div>

<div class="contract-completion-container">
  <div class="title"><strong>{{'ngw.vehicle-return.title' | translate}}</strong></div>
  <div class="days">{{contractEndTxt}}</div>
  <p *ngIf="isPreInspection">{{'ngw.vehicle-return.header.thank-you-pre-inspection' | translate}}</p>
  <p *ngIf="!isPreInspection">{{'ngw.vehicle-return.header.thank-you-auto-vin-inspection' | translate}}</p>
  <div class="pre-inspection-container">
    <img src="./../../../assets/images/inspection-thumbnail.png" alt="" class="pre-inspection-thumb">
    <div class="pre-inspection-info">
      <div class="header" *ngIf="!isPreInspection">
        <p class="header"><a (click)="openInspectionDocument()" analytics-event="false">{{inspectionTypeText | translate}}</a><i class="fa fa-check-circle"></i></p>
      </div>
      <div class="header" *ngIf="isPreInspection">
        <app-base-64-btn *ngIf="base64BtnInfo" [base64Btn]="base64BtnInfo" [style]="'link'" analytics-event="false"></app-base-64-btn><i class="fa fa-check-circle"></i>
      </div>
      <div class="copy">{{'ngw.vehicle-return.header.uploaded' | translate}} {{insepctionEndDate}}</div>
    </div>
  </div>
</div>
