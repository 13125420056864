import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  GetCozyImageRequest, VehicleImageServiceClient
} from '../core/gateway-api';

import { vehicleImageModel } from '../_models/vehicleimage.model';
@Injectable()
export class VehicleImageService {
  vehicleImageModel: vehicleImageModel;

  constructor(private vehicleImageServiceClient: VehicleImageServiceClient
  ) { }


  public getCozyImage(modelID: number, angleID: number, quality?: number, width?: number): Observable<vehicleImageModel> {
    let cozyImageRequest = new GetCozyImageRequest();
    cozyImageRequest.modelID = modelID;
    cozyImageRequest.angleID = angleID;
    cozyImageRequest.quality = quality;
    cozyImageRequest.width = width;
    return this.vehicleImageServiceClient.getCozyImage(cozyImageRequest)
      .pipe(mergeMap((response) => { return this.afterGetOfferSuccess(response); })
        , catchError((error: any) => { return this.afterGetOfferError(error); }));
  }

  private afterGetOfferSuccess(result: any): Observable<vehicleImageModel> {
    this.vehicleImageModel = new vehicleImageModel();
    this.vehicleImageModel.cozyURL = result.cozyURL;
    this.vehicleImageModel.error = false;
    return of(this.vehicleImageModel);
  }
  private afterGetOfferError(result: any): Observable<vehicleImageModel> {
    this.vehicleImageModel = new vehicleImageModel();
    this.vehicleImageModel.error = true;
    return of(this.vehicleImageModel);
  }
}
