import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { SMCViewThreadModel } from '../../../_models/secure-message-center/view-thread.model';
import { SecureMessageThreadDTO } from '../../../core/gateway-api';

import { tassign } from 'tassign';


@Injectable()
export class ViewThreadActions {
  static SET_FIND_SECURE_MESSAGE_THREAD_DETAIL: string = 'SET_FIND_SECURE_MESSAGE_THREAD_DETAIL';
  static CLEAR_THREAD: string = 'CLEAR_THREAD';

  setFindSecureMessageThreadDetail(findSecureMessageThreadDetail: SecureMessageThreadDTO): FSA<string, any, null> {
    return {
      type: ViewThreadActions.SET_FIND_SECURE_MESSAGE_THREAD_DETAIL,
      payload: findSecureMessageThreadDetail,
      meta: null
    };
  }

  clearThread(): FSA<string, any, null> {
        return {
          type: ViewThreadActions.CLEAR_THREAD,
          payload: null,
          meta: null
        };
    }

}

@Injectable()
export class ViewThreadReducers {
  static ViewThreadReducer: Reducer<SMCViewThreadModel> = (state: SMCViewThreadModel = new SMCViewThreadModel(), action: FSA<string, any, void>) => {
    switch (action.type) {
      case ViewThreadActions.SET_FIND_SECURE_MESSAGE_THREAD_DETAIL:
        return {
          ...state,
          findSecureMessageThreadDetail: [
            action.payload
          ]
        }
      case ViewThreadActions.CLEAR_THREAD:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
