import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { SchedulePaymentModel } from '../../../_models/recurring-payment/schedule-payment.model';

@Injectable()
export class UpcomingEasyPaySchedulesActions{

  static PUSH_UPCOMING_EASY_PAY_SCHEDULES: string = "PUSH_UPCOMING_EASY_PAY_SCHEDULES";
  static REMOVE_UPCOMING_EASY_PAY_SCHEDULES: string = "REMOVE_UPCOMING_EASY_PAY_SCHEDULES";
  static CLEAR_UPCOMING_EASY_PAY_SCHEDULES: string = "CLEAR_UPCOMING_EASY_PAY_SCHEDULES";

  pushUpcomingEasyPaySchedules(schedulePaymentModel: SchedulePaymentModel): FSA<string, any, null> {
    return {
      type: UpcomingEasyPaySchedulesActions.PUSH_UPCOMING_EASY_PAY_SCHEDULES,
      payload: schedulePaymentModel,
      meta: null
    };
  }

  removeUpcomingEasyPaySchedules(accountNumber: string): FSA<string, any, null> {
    return {
      type: UpcomingEasyPaySchedulesActions.REMOVE_UPCOMING_EASY_PAY_SCHEDULES,
      payload: accountNumber,
      meta: null
    };
  }

  clearUpcomingEasyPayScheduless(): FSA<string, any, null> {
    return {
      type: UpcomingEasyPaySchedulesActions.CLEAR_UPCOMING_EASY_PAY_SCHEDULES,
      payload: null,
      meta: null
    };
  }

}

@Injectable()
export class UpcomingEasyPaySchedulesReducers {
  static UpcomingEasyPaySchedulesReducer: Reducer<SchedulePaymentModel[]> = (state: SchedulePaymentModel[] = [], action: FSA<string, any, void>) => {
    switch (action.type) {
      case UpcomingEasyPaySchedulesActions.PUSH_UPCOMING_EASY_PAY_SCHEDULES:
        return state = [...state, action.payload];
      case UpcomingEasyPaySchedulesActions.REMOVE_UPCOMING_EASY_PAY_SCHEDULES:
        return state = state ? state.filter(repairReceipt => repairReceipt.accountNumber !== action.payload) : [];
      case UpcomingEasyPaySchedulesActions.CLEAR_UPCOMING_EASY_PAY_SCHEDULES:
        return state = [];
      default:
        return state;
    }
  }
}
