import { CalculateMidtermMileageAdjustmentResponse } from '../../core/gateway-api';


export class CalculateMiles {
    midtermMileageCalculation: CalculateMidtermMileageAdjustmentResponse;
    faultType: string;
    error: boolean;
}



