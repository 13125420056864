import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';
import { FSA } from 'flux-standard-action';
import { tassign } from 'tassign';


@Injectable()
export class V2DueDateChangeActions {
  static DUE_DATE_CHANGE: string = 'DUE_DATE_CHANGE';

  dueDateChangeEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: V2DueDateChangeActions.DUE_DATE_CHANGE,
      payload: featureState,
      meta: null
    }
  }

}

@Injectable()
export class V2DueDateChangeReducer {

  static V2DueDateChangeReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(true), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case V2DueDateChangeActions.DUE_DATE_CHANGE:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
}
