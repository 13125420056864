import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { VehicleReturnEstimateRoutingModule } from './vehicle-return-estimate-routing.module';
import { VehicleReturnEstimateComponent } from './vehicle-return-estimate.component';
import { VehicleReturnEstimateResolve } from './vehicle-return-estimate.resolve';
import { VehicleReturnEstimateHeaderComponent } from './vehicle-return-estimate-header/vehicle-return-estimate-header.component';
import { EstimatedSummaryComponent } from './estimated-summary/estimated-summary.component';
import { EstimatedPurchaseSummaryComponent } from './estimated-summary/estimated-purchase-summary/estimated-purchase-summary.component';
import { EstimatedChargesComponent } from './estimated-charges/estimated-charges.component';
import { EndOfTermService } from '../../_web-services/end-of-term.service';

@NgModule({
    imports: [
        CommonModule,
        VehicleReturnEstimateRoutingModule,
        SharedModule,
        TranslateModule
    ],
    declarations: [ 
      VehicleReturnEstimateComponent,
      VehicleReturnEstimateHeaderComponent,
      EstimatedSummaryComponent,
      EstimatedChargesComponent,
      EstimatedPurchaseSummaryComponent
  ],
  providers: [EndOfTermService]
})
export class VehicleReturnEstimateModule { }
