import { Injectable } from '@angular/core';
import { CustomerIntentCode, CustomerIntentDisplayName, ContractTypeText, ActivityTypes } from '../enums';
import * as _ from 'lodash-es';

@Injectable()
export class CustomerIntentService {


  public intentList: any[] = [];

  constructor() {
    this.intentList = [
      { accountType: ContractTypeText.Retail, code: CustomerIntentCode.NewCar, btnText: CustomerIntentDisplayName.NewCar, acivityType: ActivityTypes.IntentGetNew },
      { accountType: ContractTypeText.Retail, code: CustomerIntentCode.KeepAndDrive, btnText: CustomerIntentDisplayName.KeepAndDrive, acivityType: ActivityTypes.IntentKeep },
      { accountType: ContractTypeText.Retail, code: CustomerIntentCode.NotSureYet, btnText: CustomerIntentDisplayName.NotSureYet, acivityType: ActivityTypes.IntentNotSure },
      { accountType: ContractTypeText.Select, code: CustomerIntentCode.NewCar, btnText: CustomerIntentDisplayName.NewCar, acivityType: ActivityTypes.IntentGetNew },
      { accountType: ContractTypeText.Select, code: CustomerIntentCode.PayBalloon, btnText: CustomerIntentDisplayName.PayBalloon, acivityType: ActivityTypes.IntentPurchase },
      { accountType: ContractTypeText.Select, code: CustomerIntentCode.Refinance, btnText: CustomerIntentDisplayName.Refinance, acivityType: ActivityTypes.IntentRefinance },
      { accountType: ContractTypeText.Select, code: CustomerIntentCode.NotSureYet, btnText: CustomerIntentDisplayName.NotSureYet, acivityType: ActivityTypes.IntentNotSure },
      { accountType: ContractTypeText.OC, code: CustomerIntentCode.NewCar, btnText: CustomerIntentDisplayName.NewCar, acivityType: ActivityTypes.IntentGetNew },
      { accountType: ContractTypeText.OC, code: CustomerIntentCode.PayBalloon, btnText: CustomerIntentDisplayName.PayBalloon, acivityType: ActivityTypes.IntentPurchase },
      { accountType: ContractTypeText.OC, code: CustomerIntentCode.TurnIn, btnText: CustomerIntentDisplayName.TurnIn, acivityType: ActivityTypes.IntentReturn },
      { accountType: ContractTypeText.OC, code: CustomerIntentCode.NotSureYet, btnText: CustomerIntentDisplayName.NotSureYet, acivityType: ActivityTypes.IntentNotSure },
      { accountType: ContractTypeText.Lease, code: CustomerIntentCode.NewCar, btnText: CustomerIntentDisplayName.NewCar, acivityType: ActivityTypes.IntentGetNew },
      { accountType: ContractTypeText.Lease, code: CustomerIntentCode.Refinance, btnText: CustomerIntentDisplayName.KeepAndDrive, acivityType: ActivityTypes.IntentKeep },
      { accountType: ContractTypeText.Lease, code: CustomerIntentCode.TurnIn, btnText: CustomerIntentDisplayName.TurnIn, acivityType: ActivityTypes.IntentReturn },
      { accountType: ContractTypeText.Lease, code: CustomerIntentCode.NotSureYet, btnText: CustomerIntentDisplayName.NotSureYet, acivityType: ActivityTypes.IntentNotSure }
    ];
  }

  public GetRetialIntentList(): any[] {
    return _.filter(this.intentList, item => {
      return item.accountType == ContractTypeText.Retail;
    });
  }

  public GetLeaseIntentList(): any[] {
    return _.filter(this.intentList, item => {
      return item.accountType == ContractTypeText.Lease;
    });
  }

  public GetOCIntentList(): any[] {
    return _.filter(this.intentList, item => {
      return item.accountType == ContractTypeText.OC;
    });
  }


  public GetSelectIntentList(): any[] {
    return _.filter(this.intentList, item => {
      return item.accountType == ContractTypeText.Select;
    });
  }

  public GetIntentText(accountType:string,conditionCode:number): string {
    return _.find(this.intentList, item => {
      return (item.accountType === accountType && item.code===conditionCode);
    })
  }

    
}
