import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { Autopay } from '../../../_models/my-account/autopay';

@Injectable()
export class AutopaysActions {
    static PUSH_AUTOPAY: string = 'PUSH_AUTOPAY';
    static REMOVE_AUTOPAY: string = 'REMOVE_AUTOPAY';
    static CLEAR_AUTOPAYS: string = 'CLEAR_AUTOPAYS';

    pushAutopay(autopay: Autopay): FSA<string, any, null>{
    return {
      type: AutopaysActions.PUSH_AUTOPAY,
      payload: autopay,
      meta: null
    };
    }

    removeAutopay(accountNumber: string): FSA<string, any, null> {
      return {
          type: AutopaysActions.REMOVE_AUTOPAY,
          payload: accountNumber,
          meta: null
      };
  }

    clearAutopays(): FSA<string, any, null> {
      return {
          type: AutopaysActions.CLEAR_AUTOPAYS,
          payload: null,
          meta: null
      };
  }
}

@Injectable()
export class AutopaysReducers
{
    static AutopaysReducer: Reducer<Autopay[]> = (state: Autopay[] = [], action: FSA<string, any, void>) => {
    switch(action.type){
        case AutopaysActions.PUSH_AUTOPAY:
            return state = [...state, action.payload];
        case AutopaysActions.REMOVE_AUTOPAY:
            return state = state ? state.filter(autoPay => autoPay.accountNumber !== action.payload) : [];
        case AutopaysActions.CLEAR_AUTOPAYS:
            return state = [];
        default:
          return state;
    }
  }
}
