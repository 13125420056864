import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountNumberMaskPipe'
})
export class AccountNumberMaskPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value !== ''? '******' : value; 
  }
}
