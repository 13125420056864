<div>
  <h2>{{'ngw.map.entry.choose-payment.pre-header' | translate}}</h2>
  <div class="not-eligible">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <span analytics-error="ngw.map.entry.choose-payment.error.account-not-eligible-title">{{'ngw.map.entry.choose-payment.error.account-not-eligible-title' | translate}}</span>
  </div>
  <div class="not-eligible-desc">
    <p analytics-error="ngw.map.entry.choose-payment.error.account-not-eligible-desc2">
      {{'ngw.map.entry.choose-payment.error.account-not-eligible-desc2' | translate}}
      <a (click)="navigateToContactUs()">{{'ngw.general.contact-us-with-dot' | translate}}</a>
    </p>
  </div>
  <div class="action-buttons">
    <button class="btn btn-primary" type="button" [mat-dialog-close] analytics-event="body.cancel-map-payment-error.cancel-map-payment-error.button">
      {{'ngw.map.entry.btn-cancel' | translate}}</button>
  </div>
</div>
