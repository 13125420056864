<div class="page-terms-and-conditions" role="main" *ngIf="dataLoaded">
    <div class="terms-and-conditions-container terms-view">
      <div class="brand-image-div print-this">
        <img src="../../../assets/images/logo-bmw.png" class="brand-image" alt="{{'terms.view.brand-alt' | translate}}">
      </div>
      <div *ngIf="termsLoadFailed" class="row no-print">
        <fs-text type="error">{{'global.technical-error' | translate}}</fs-text>
      </div>
      <div [ngClass]="{'terms-view-failed': termsLoadFailed}">
        <fs-print-page [htmlContent]="htmlContent" buttonText="{{'terms.view.btn-close' | translate}}" printButtonAriaLabel="{{'terms.view.btn-print' | translate}}"  closeButtonAriaLabel ="{{'terms.view.btn-label' | translate}}"  (onClick)="closeTerms()"></fs-print-page>
      </div>
    </div>
  </div>  