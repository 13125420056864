import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';

import { VehicleReturnEstimateInspectionDTO } from '../../../core/gateway-api';

@Injectable()
export class VehicleReturnInspectionActions {
  static SET_VEHICLE_INSPECTION: string = 'SET_VEHICLE_INSPECTION';
  static CLEAR_VEHICLE_INSPECTION: string = 'CLEAR_VEHICLE_INSPECTION';

  setVehicleReturnInspection(vehicleReturnInspection: VehicleReturnEstimateInspectionDTO): FSA<string, VehicleReturnEstimateInspectionDTO, null> {
    return {
      type: VehicleReturnInspectionActions.SET_VEHICLE_INSPECTION,
      payload: vehicleReturnInspection,
      meta: null
    };
  }

 cleartVehicleReturnInspection(): FSA<string, any, null> {
    return {
      type: VehicleReturnInspectionActions.CLEAR_VEHICLE_INSPECTION,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class VehicleReturnInspectionActionsReducers {
  static VehicleReturnInspectionActionsReducer: Reducer<VehicleReturnEstimateInspectionDTO> = (state: VehicleReturnEstimateInspectionDTO = new VehicleReturnEstimateInspectionDTO(), action: FSA<string, VehicleReturnEstimateInspectionDTO, void>) => {
    switch (action.type) {
      case VehicleReturnInspectionActions.SET_VEHICLE_INSPECTION:
        return tassign(state, action.payload);
      case VehicleReturnInspectionActions.CLEAR_VEHICLE_INSPECTION:
        return tassign(undefined);
      default:
        return state;
    }
  }
}
