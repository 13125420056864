import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { Language } from '../enums';

@Injectable()
export class FrenchDateAdapterService extends NativeDateAdapter {
  parse(value: string) {
    let it = value.split('/');

    if (it.length == 3 && this.locale === Language.French) {
      let timestamp = Date.parse(it[1] + "/" + it[0] + "/" + it[2]);
      return isNaN(timestamp) ? null : new Date(+it[2], +it[1] - 1, +it[0]);
    }
    else
      return new Date(value);
  }
} 
