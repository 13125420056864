import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-contract-image',
    templateUrl: './contract-image.component.html',
    styleUrls: ['./contract-image.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContractImageComponent {
    public imageUrl: string;

    @Input() vehicleImageData: string;


}
