import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApplicationConfig } from '../_models/application-config';
import { IAppState } from "../shared/store/app.store";
import {
  FeatureIndicatorKey,
  FeatureIndicatorServiceClient,
  GetFeatureClientIdRequest,
  GetFeatureClientIdResponse,
  GetFeatureIndicatorByClientIdRequest,
  GetFeatureIndicatorRequest
} from './../core/gateway-api';

@Injectable()
export class FeatureIndicatorService {

  constructor(private featureIndicatorServiceClient: FeatureIndicatorServiceClient,
    private store: Store<IAppState>) {
  }

  public getFeatureIndicatorByClientId(featureName: string): Observable<boolean> {
    const getFeatureClientIdRequest: GetFeatureClientIdRequest = new GetFeatureClientIdRequest();
    let appConfig: ApplicationConfig;
    this.store.select(state => state.ApplicationConfig!).subscribe(x => appConfig = x);
    getFeatureClientIdRequest.market = appConfig!.TENANT;
    getFeatureClientIdRequest.product = appConfig!.PRODUCT;
    getFeatureClientIdRequest.brand = appConfig!.BRAND;
    return this.featureIndicatorServiceClient.getFeatureClientId(getFeatureClientIdRequest)
      .pipe(mergeMap((response: GetFeatureClientIdResponse) => {
        const getFeatureIndicatorRequest: GetFeatureIndicatorByClientIdRequest = new GetFeatureIndicatorByClientIdRequest();
        getFeatureIndicatorRequest.featureClientId = response.featureClientId;
        getFeatureIndicatorRequest.featureName = featureName;
        return this.featureIndicatorServiceClient.getFeatureIndicatorByClientId(getFeatureIndicatorRequest);
      }))
      .pipe(mergeMap((response) => { return this.afterGetFeatureIndicatorSuccess(response); })
        , catchError((error: any) => { return this.afterGetFeatureIndicatorFailure(error); }));
  }

  public getFeatureIndicator(featureIndicatorId: number): Observable<boolean> {
    let featureIndicatorKey = new FeatureIndicatorKey();
    featureIndicatorKey.featureIndicatorId = featureIndicatorId
    let getFeatureIndicatorRequest = new GetFeatureIndicatorRequest;
    getFeatureIndicatorRequest.featureIndicatorKey = featureIndicatorKey;
    return this.featureIndicatorServiceClient.getFeatureIndicator(getFeatureIndicatorRequest)
      .pipe(mergeMap((response) => { return this.afterGetFeatureIndicatorSuccess(response); })
        , catchError((error: any) => { return this.afterGetFeatureIndicatorFailure(error); }));
  }

  private afterGetFeatureIndicatorSuccess(result: any): Observable<boolean> {
    if (result && result.featureIndicator) {
      return of(result.featureIndicator.mutable.isAvailable);
    } else if (result && result.enabled) {
      return of(true);
    }
    return of(false);
  }

  private afterGetFeatureIndicatorFailure(error: any): Observable<boolean> {
    return of(false);
  }
}
