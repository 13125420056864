import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  GetVehicleShadowDetailsRequest, GetVehicleShadowDetailsResponse, VehicleShadowDTO,
  VehicleShadowServiceClient
} from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { FeatureIndicatorNames } from '../shared/enums';
import { IAppState } from '../shared/store/app.store';
import { VehicleShadowDataActions } from '../shared/store/reducers/vehicle-shadow-data.reducer';
import { FeatureIndicatorService } from './feature-indicator.service';

@Injectable()
export class VehicleShadowService {

  constructor(private vehicleShadowServiceClient: VehicleShadowServiceClient
    , private fsTokenErrorHandler: FSTokenErrorHandler
    , private featureIndicatorService: FeatureIndicatorService
    , private vehicleShadowDataActions: VehicleShadowDataActions
    , private store: Store<IAppState>) { }

  public getVehicleShadowDetails(vin: string, customerNumber: number, accountNumber: string): Observable<GetVehicleShadowDetailsResponse> {

    return this.featureIndicatorService.getFeatureIndicatorByClientId(FeatureIndicatorNames.VehicleShadowData)
      .pipe(mergeMap((response) => {
        if (response) {
          var getVehicleShadowDetailsRequest = new GetVehicleShadowDetailsRequest();
          getVehicleShadowDetailsRequest.customerNumber = customerNumber;
          getVehicleShadowDetailsRequest.vin = vin;
          getVehicleShadowDetailsRequest.accountNumber = accountNumber;
          return this.vehicleShadowServiceClient.getVehicleShadowDetails(getVehicleShadowDetailsRequest).pipe(mergeMap((response) => { return this.afterGetVehicleShadowDetailsSuccess(response); })
            , catchError((error: any) => { return this.afterGetVehicleShadowDetailsError(error); }));
        }
        else {
          var vehicleShadowResponse = new GetVehicleShadowDetailsResponse();
          vehicleShadowResponse.vehicleShadowDto = new VehicleShadowDTO();
          vehicleShadowResponse.vehicleShadowDto.mileage = 0;
          return of(vehicleShadowResponse);
        }
      }));
  }
  private afterGetVehicleShadowDetailsSuccess(response: GetVehicleShadowDetailsResponse): Observable<GetVehicleShadowDetailsResponse> {
    if (response.vehicleShadowDto
      && response.vehicleShadowDto.mileage > 0) {
      this.store.dispatch(this.vehicleShadowDataActions.pushVehicleShadowData(response.vehicleShadowDto));
    }

    return of(response);
  }

  private afterGetVehicleShadowDetailsError(error: any): Observable<GetVehicleShadowDetailsResponse> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    var response = new GetVehicleShadowDetailsResponse();
    response.vehicleShadowDto = new VehicleShadowDTO();
    response.vehicleShadowDto.mileage = 0;
    return of(response);
  }
}
