import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Attachments } from '../../../_models/uploadAttachment.model';
import { Store } from '@ngrx/store';
import { IAppState } from "../../store/app.store";
import { UploadAttachmentService } from '../../_helper-services/upload-attachment.service';
import { AnalyticsService } from '../../_helper-services/analytics.service';
import { Constants } from '../../constants';

@Component({
  selector: 'app-upload-attachment',
  templateUrl: './upload-attachment.component.html',
  styleUrls: ['./upload-attachment.component.scss']
})
export class UploadAttachmentComponent implements OnInit, OnChanges, OnDestroy {
  private onAttachmentAddedSubscription: any;
  allowableFileExtension: string[] = ["application/pdf", "image/png", "image/gif", "image/jpeg", "image/tif", "image/gif", "image/tiff"]; // Need to check if this can go to enviornment config.
  fileAttachments: Attachments;
  imagelimitationInformation: string;
  remainingFileSize: string;
  @Input() title: string;
  @Input() fileName: string;
  @Input() isHide: boolean = false;
  @Input() attachmentDesc: string;
  @Input() analyticsTracking: boolean = true;

  @ViewChild('form') form;
  uploadFileInput: any;

  @Output() onUploadedAttachments = new EventEmitter<Attachments>();

  constructor(private translateService: TranslateService
    , private store: Store<IAppState>
    , private uploadAttachmentService: UploadAttachmentService
    , private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.fileAttachments = this.uploadAttachmentService.initAttachments();
    this.translateService.get("ngw.shared.upload-attachment.attachment-image-type", { value: this.fileAttachments.uploadLimit }).subscribe((res: string) => {
      this.imagelimitationInformation = res;
    });
 
    this.translateService.get("ngw.recurring-payment.entry.errors.max-value-max-amount", { value: this.fileAttachments.remainingUploadDocumentsSize }).subscribe((res: string) => {
      this.remainingFileSize = res;
    });

    this.onAttachmentAddedSubscription = this.uploadAttachmentService.attachedmentAdded.subscribe({
      next: (event: any) => {
        if (this.analyticsTracking) this.analyticsService.pushSiteToolEvent({ 
          siteTool: Constants.UploadAttachmentComponentName, 
          siteToolStep: Constants.SiteToolComplete 
        });
        // Send an event to parent component for available attachments
        this.onUploadedAttachments.emit(this.fileAttachments);
      }
    });
  }

  ngOnDestroy() {
    this.fileAttachments = null;
    this.uploadAttachmentService.clearAttachments();
    if (this.onAttachmentAddedSubscription) {
      this.onAttachmentAddedSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title.currentValue) {
      this.title = changes.title.currentValue;
    }
  }

  onChange(event) {
    if (this.analyticsTracking) this.analyticsService.pushSiteToolEvent({ 
      siteTool: Constants.UploadAttachmentComponentName, 
      siteToolStep: Constants.SiteToolStart 
    });
    this.fileAttachments = this.uploadAttachmentService.onAttachmentChange(event, this.attachmentDesc);
  }
}
