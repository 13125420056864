<mat-dialog-content>
  <div class="modal-toggle-automated-payment">
    <h1><span *ngIf="autopayPaused">{{'ngw.recurring-payment.toggle.txt-resume' | translate}}</span><span *ngIf="!autopayPaused">{{'ngw.recurring-payment.toggle.txt-pause' | translate}}</span>
     <span *ngIf="!autopayPaused"> {{'ngw.recurring-payment.toggle.txt-autopay' | translate}}</span><span *ngIf="autopayPaused">
  {{'ngw.recurring-payment.toggle.txt-autopay-resume' | translate}}
</span></h1>
    <div class="alert alert-danger" role="alert" *ngIf="cancelRecurringPaymentError">
      <p class="error-validation" id="errorMessage" analytics-error="ngw.recurring-payment.toggle.error-text">
        {{'ngw.recurring-payment.toggle.error-text' | translate}}<a (click)="navigateToContactUs()">{{'ngw.general.contact-us-with-dot' | translate}}</a>
      </p>
    </div>
    <div *ngIf="autopayPaused">
      <p>{{pauseParagraph1Txt}}</p>
      <p><strong>{{pauseParagraph2Txt}}</strong></p>
    </div>
    <div *ngIf="!autopayPaused">
      <p>{{pauseParagraph1Txt}}</p>
      <p><strong>{{pauseParagraph2Txt}}</strong></p>
    </div>
    <div class="form-group">
      <span class="label" id="resumeAutopayDateLabel">{{'ngw.recurring-payment.toggle.lbl-resume-autopay' | translate}}</span>
      <mat-select name="resumeAutopayDate" required id="resumeAutopayDate" class="form-control" (selectionChange)="onSelectionChange($event)" aria-label="Resume AutoPay" aria-labelledby="resumeAutopayDateLabel" [(ngModel)]="resumeAutopayDate">
        <mat-option *ngFor="let item of upComingEasyPayDates" [value]="item">{{item | date:'longDate'}}</mat-option>
      </mat-select>
    </div>
    <div class="action-buttons">
      <button (click)="cancelToggleRecurringPayment()" class="btn btn-secondary btn-transparent" id="keepRecurringPaymentBtn" analytics-event="body.cancel-recurring-payment-toggle.cancel-recurring-payment-toggle.button">
        {{'ngw.recurring-payment.toggle.btn-cancel' | translate}}</button>
      <button (click)="pauseRecurringPayment(autopayPaused)" class="btn btn-primary" id="cancelRecurringPaymentBtn">
        <span *ngIf="autopayPaused">{{'ngw.recurring-payment.toggle.btn-resume-easy-pay' | translate}}</span>
        <span *ngIf="!autopayPaused">{{'ngw.recurring-payment.toggle.btn-pause-easy-pay' | translate}}</span>
      </button>
    </div>
  </div>
</mat-dialog-content>
