import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Inject,
  Injector,
  ElementRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CashAccountViewService } from "../../../../_web-services/cash-account-view.service";
import {
  PaymentSource,
  PaymentInfo,
  PaymentReviewDetails,
  PaymentEntry,
  PaymentDueSource,
  PaymentAmountDDL,
} from "../../../../_models/payment.model";
import { ContactDetail } from "../../../../_models/contract/contact-detail.model";
import { ContactUpcoming } from "../../../../_models/contract/contact-upcoming.model";
import { ScheduledItem } from "../../../../_models/contract/scheduled-item.model";
import {
  RouterService,
  routes,
  routesV2,
} from "../../../../shared/_helper-services/router.service";
import { PaymentViewService } from "../../../../_web-services/payment-view.service";
import { UserService } from "../../../../shared/_helper-services/user.service";
import { ValidatorService } from "../../../../_validators/validator.service";
import {
  FinancialAccountDTOAccountType,
  FinancialAccountDTO,
  ContractAccountDetailDTO,
} from "../../../../core/gateway-api";
import { Constants, HTMLConstants } from "../../../../shared/constants";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { FaultCodes } from "../../../../shared/FaultCodes";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { LogSiteActivityService } from "../../../../_web-services/log-site-activity.service";
import {
  ActivityTypes,
  FeatureIndicatorTypes,
  Market,
} from "../../../../shared/enums";
import { DOCUMENT } from "@angular/common";
import { forkJoin } from "rxjs";
import { BaseApplicationComponent } from "../../../../core/basecomponent/base.component";
import { CustomerACHService } from "../../../../_web-services/customer-ach.service";
import { IsInPaymentFlowActions } from "../../../../shared/store/reducers/is-in-payment-flow.reducer";
import { CanNavigateBackActions } from "../../../../shared/store/reducers/can-navigate-back.reducer";
import { PaymentReviewDetailsActions } from "../../../../shared/store/reducers/payment-review-details.reducer";
import { PaymentEntryActions } from "../../../../shared/store/reducers/payment-entry.reducer";
import { ObjectService } from "../../../../shared/_helper-services/object.service";
import { DialogService } from "../../../../shared/_helper-services/dialog.service";
import { PaymentHelperService } from "../../../../shared/_helper-services/payment-helper.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AddACHPaymentSourceComponent } from "../../../../shared/_components/dialogs/add-ach-payment-source/add-ach-payment-source.component";
import { AccountInfoService } from "../../../../shared/_helper-services/account-info.service";
import { AutoEnrollService } from "../../../../shared/_helper-services/auto-enroll.service";
import { PaymentService } from "../../../../_web-services/payment.service";
import { WindowService } from "../../../../shared/_helper-services/window.service";
import { DialogService as DialogLibraryService } from "customerdigital-ui-lib";
import {
  PaymentCardAppearanceType,
  PaymentCardService,
  PaymentMethodModel,
} from "customerdigital-service-lib";
import {
  CardHelperService,
  onAddCardBtnSuccess,
} from "../../../../../_shared/shared/_helper-services/card-helper.service";
import { FeatureIndicatorService } from "../../../../../_shared/_web-services/feature-indicator.service";

@Component({
  selector: "app-payment-entry",
  templateUrl: "./payment-entry.component.html",
  styleUrls: ["./payment-entry.component.scss"],
})
export class PaymentEntryComponent
  extends BaseApplicationComponent<PaymentEntryComponent>
  implements OnInit, OnDestroy
{
  @ViewChild("cardForm") cardForm: ElementRef<HTMLInputElement>;
  public errorMessage: string;
  public servicesDownError: boolean;
  public paymentEntryError: boolean;
  public paymentEntryErrorWithContactUs: boolean;
  public currentPaymentSource: PaymentSource = null;
  emailValidationError: boolean;
  emailPatternError: boolean;
  invalidEmailError = false;
  otherAmountRequiredError: boolean;
  invalidOtherAmountError: boolean;
  public amountValidationError: boolean;
  paymentDateError: boolean;
  addAchSource: string;
  addDebitCardSource: string;
  addCreditCardSource: string;
  selectSource: string;
  enterDifferentEmail: string;
  paymentInfoLoaded: boolean;
  contractAccountDetailsLoaded: boolean;
  schedulePaymentLoaded: boolean;
  contractDetail: ContractAccountDetailDTO;
  private paymentEntrySubscription: any;
  totalAmountDue: number;
  terminatedAccountPaymentNotAllowed: boolean = false;
  public paymentNotAllowedForCa: boolean = false;
  isLumpSumPayment: boolean = false;
  public isAddCreditCardSelected: boolean = false;

  scheduledPayments: ScheduledItem[];
  datepickerOverlay: any;
  scheduledItem: any;
  myBMWLink: string;
  inCorrectDateFormatMessage: string;
  paymentEligibilityTodayMessage: string;
  paymentEligibilityMessage: string;
  pastDueDateSelectMessage: string;
  paymentIneligibleHelpNumber: string;
  invalidOtherAmountErrorMessage: string;
  debitCardFeeMsg: string;
  public addAchDialogRef: MatDialogRef<AddACHPaymentSourceComponent>;

  amountDueText: string;
  monthlyAmountDueText: string;
  minimumAmountDueText: string;
  otherAmountText: string;

  paymentReviewDetails: PaymentReviewDetails;
  paymentEntry: PaymentEntry;
  touch: boolean;
  terminatedAccount: boolean;
  showPreviousPaymentInfo: boolean;

  // Tooltip variables
  position: string;
  innerHeight: number;
  innerWidth: number;

  paymentAmountsForCa: any[];
  paymentSourcesForCa: any[];
  paymentDatesForCa: Date;
  paymentEmailsForCa: any[];
  public paymentEntryDataLoaded: boolean;

  resetCheckBox: boolean;
  showAlertMessage: boolean;
  disabledPayment: boolean;
  longDateFormat: string;
  public defaultReadonlyValue = Constants.UNDEFINED;
  private addCardDialogRef: any;
  paymentCardData: PaymentMethodModel;
  public isAddCardDisabled: boolean;

  constructor(
    public routerService: RouterService,
    public translateService: TranslateService,
    public paymentViewService: PaymentViewService,
    public userService: UserService,
    private validatorService: ValidatorService,
    public customerACHService: CustomerACHService,
    private cashAccountViewService: CashAccountViewService,
    public currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    public logSiteActivityService: LogSiteActivityService,
    private isInPaymentFlowActions: IsInPaymentFlowActions,
    public canNavigateBackActions: CanNavigateBackActions,
    public paymentReviewDetailsActions: PaymentReviewDetailsActions,
    public paymentEntryActions: PaymentEntryActions,
    public objectService: ObjectService,
    public dialogService: DialogService,
    public accountInfoService: AccountInfoService,
    private dialog: MatDialog,
    private windowService: WindowService,
    public paymentHelperService: PaymentHelperService,
    private autoEnrollService: AutoEnrollService,
    public paymentService: PaymentService,
    protected sanitizer: DomSanitizer,
    public dialogLibraryService: DialogLibraryService,
    public paymentCardService: PaymentCardService,
    private cardHelperService: CardHelperService,
    private featureIndicatorService: FeatureIndicatorService,
    @Inject(DOCUMENT) document,
    injector: Injector
  ) {
    super(injector, document);
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
      });
    this.paymentEntry = new PaymentEntry();
    this.paymentEntry.sources = [];
    this.paymentEntry.noPaymentSource = false;
    this.paymentEntry.emails = [];
    this.paymentEntry.enterNewEmail = false;
    this.paymentEntry.differentEmail = false;
    this.paymentEntry.showPaymentDateMessage = false;
    this.paymentEntry.showPaymentAmountToolTip = false;
    this.paymentEntry.otherAmountSelected = false;
    this.paymentEntry.paymentdateReadOnly = Constants.READONLY;
    this.paymentEntry.paymentdate = new Date();
    this.paymentEntry.currentDueDate = new Date();
    this.paymentEntry.paymentDueList = [];
    this.paymentEntry.paymentDateMessage = Constants.EMPTY;
    let contractAccount: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractAccount = x);
    this.paymentEntry.accountNumber = contractAccount.accountNumber;
    this.scheduledPayments = [];
    this.errorMessage = Constants.EMPTY;
    this.paymentEntryError = false;
    this.paymentEntryErrorWithContactUs = false
    this.servicesDownError = false;
    this.amountValidationError = false;
    this.emailValidationError = false;
    this.emailPatternError = false;
    this.otherAmountRequiredError = false;
    this.paymentDateError = false;
    this.paymentInfoLoaded = false;
    this.contractAccountDetailsLoaded = false;
    this.schedulePaymentLoaded = false;
    this.innerWidth = window.innerWidth;
    let myBMWLinks: string;
    this.store.select(state => state.EnvironmentConfig.MY_BMW_WEB_URL).subscribe(x => myBMWLinks = x);
    this.myBMWLink = myBMWLinks;
    let fsAccountHelpPhoneNumber: string;
    this.store.select(state => state.ApplicationConfig.FS_ACCOUNT_HELP_PHONE_NUMBER).subscribe(x => fsAccountHelpPhoneNumber = x);
    this.paymentIneligibleHelpNumber = fsAccountHelpPhoneNumber;
    super.setDataLoadedOnRequest(this.havePaymentEntryData() ? true : false);
    this.paymentEntryDataLoaded = false;
    this.paymentEntry.lastPaymentInformationText = Constants.EMPTY;
    this.paymentEntry.showLastPaymentDetail = false;
    this.invalidOtherAmountError = false;
    this.showPreviousPaymentInfo = false;

    // Set the position of the tooltip (based on browser width).
    this.position = "right";

    this.translateService
      .get("ngw.payment.entry.lbl-amount-due")
      .subscribe((res: string) => {
        this.amountDueText = res;
      });
    this.translateService
      .get("ngw.payment.entry.lbl-monthly-payment")
      .subscribe((res: string) => {
        this.monthlyAmountDueText = res;
      });
    this.translateService
      .get("ngw.payment.entry.lbl-min-due")
      .subscribe((res: string) => {
        this.minimumAmountDueText = res;
      });
    this.translateService
      .get("ngw.payment.entry.lbl-other")
      .subscribe((res: string) => {
        this.otherAmountText = res;
      });
    this.translateService
      .get("ngw.payment.entry.add-ach-source")
      .subscribe((res: string) => {
        this.addAchSource = res;
      });
    this.translateService
      .get("ngw.payment.entry.add-debit-card-source")
      .subscribe((res: string) => {
        this.addDebitCardSource = res;
      });
    this.translateService
      .get("ngw.payment.entry.add-credit-card-source")
      .subscribe((res: string) => {
        this.addCreditCardSource = res;
      });
    this.translateService
      .get("ngw.payment.entry.enter-new-email")
      .subscribe((res: string) => {
        this.enterDifferentEmail = res;
      });
    this.longDateFormat = Constants.datePipeLongFormat;
  }

  ngOnInit() {
    super.pushDataLayer();
    this.analytics.pushSiteToolEvent({
      siteTool: Constants.MakeAPayment,
      siteToolStep: Constants.SiteToolStart,
    });
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public init() {
    onAddCardBtnSuccess.subscribe((data) => {
      if (data) {
        this.manageSetPaymentSource();
      }
    });
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    if (this.objectService.isEmptyObject(this.contractDetail)) {
      this.routerService.navigateToHome();
    }
    let isInPaymentFlow: boolean;
    this.store.select(state => state.IsInPaymentFlow.booleanValue).subscribe(x => isInPaymentFlow = x);
    isInPaymentFlow
      ? this.store.dispatch(this.paymentEntryActions.clearPaymentEntry())
      : this.store.dispatch(
          this.isInPaymentFlowActions.setIsInPaymentFlow(true)
        );
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.touch = true;
    if (this.innerWidth < 600) {
      this.position = "left";
    }

    this.resetCheckBox = false;
    this.showAlertMessage = false;
    this.disabledPayment = false;
    this.paymentReviewDetails = new PaymentReviewDetails();
    this.paymentReviewDetails.error = false;

    let accountNumber: string;
    let accountNumbers: string[] = [];
    accountNumber = this.contractDetail.accountNumber;
    accountNumbers.push(accountNumber);

    let cachedPaymentEntry: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => cachedPaymentEntry = x);
    if (
      this.objectService.isEmptyObject(cachedPaymentEntry) ||
      cachedPaymentEntry == null ||
      (!this.objectService.isEmptyObject(cachedPaymentEntry) &&
        cachedPaymentEntry.accountNumber !== accountNumber)
    ) {
      let arrayList = [
        this.cashAccountViewService.findAccounts(
          this.userService.getCustomerNumber(),
          accountNumbers,
          true
        ),
        this.paymentViewService.getOneTimePaymentDetails(accountNumber),
        this.customerACHService.findUpcomingUpays(accountNumber, true)];
      forkJoin(arrayList).subscribe(responses => {
        this.postPaymentEntryServiceLoaded(responses);
      });
    } else {
      this.paymentEntry = cachedPaymentEntry;
      if (this.paymentEntry.cardPaymentFeeAmount > Constants.DECIMAL_ZERO) {
        this.translateService
          .get("ngw.payment-source.payment-card.debit-card-fee-amount-msg", {
            paymentcardfeeamount: this.paymentEntry.cardPaymentFeeAmount,
          })
          .subscribe((res: string) => {
            this.debitCardFeeMsg = res;
          });
      }
    }
    this.terminatedAccount = this.isTerminatedAccount(
      this.contractDetail.statusCategoryCode
    );
    this.showPreviousPaymentInfo =
      this.accountInfoService.showPreviousPaymentInfo(
        this.contractDetail.statusCategoryCode,
        this.contractDetail.portfolioCategoryCode,
        this.contractDetail.contractType
      );
    //Initiate autoenrollment
    this.autoEnrollService.autoEnrollElligiblePreferences();
    //this.identifyOverlay();
    this.logUserActivity(this.userService.getCustomerNumber(), accountNumber);
  }

  private logUserActivity(
    customerNumber: number,
    accountNumber: string,
    accountType?: FinancialAccountDTOAccountType
  ) {
    if (
      this.isTerminatedAccount(this.contractDetail.statusCategoryCode) &&
      this.isLeaseAccountWithMaturityBilled(
        this.contractDetail.portfolioCategoryCode,
        accountNumber
      ) &&
      accountType == FinancialAccountDTOAccountType.Credit
    )
      this.logSiteActivityService.logUserActivity(
        customerNumber,
        accountNumber,
        ActivityTypes.MaturityBillingCreditCardPayment
      );
    else if (accountType == undefined) {
      if (
        this.contractDetail?.countryCode?.codeValue == Constants.Country_CAN
      ) {
        if (
          this.isLeaseAccountWithMaturityBilled(
            this.contractDetail.portfolioCategoryCode,
            accountNumber
          )
        )
          this.logSiteActivityService.logUserActivity(
            customerNumber,
            accountNumber,
            ActivityTypes.OneTimeMaturityBillingPaymentEntry
          );
        else if (
          this.accountInfoService.isPastDue(this.contractDetail.pastDueAmount)
        )
          this.logSiteActivityService.logUserActivity(
            customerNumber,
            accountNumber,
            ActivityTypes.OneTimeCollectionsPaymentEntry
          );
        else if (
          !this.accountInfoService.showOtherPayment(this.contractDetail) &&
          this.accountInfoService.eligibleForLumpSumPayment(this.contractDetail)
        )
          this.logSiteActivityService.logUserActivity(
            customerNumber,
            accountNumber,
            ActivityTypes.LumpSumPaymentEntry
          );
        else
          this.logSiteActivityService.logUserActivity(
            customerNumber,
            accountNumber,
            ActivityTypes.OneTimePaymentDataEntry
          );
      } else
        this.logSiteActivityService.logUserActivity(
          customerNumber,
          accountNumber,
          ActivityTypes.OneTimePaymentDataEntry
        );
    }
  }

  private isLeaseAccountWithMaturityBilled(
    portfolioCategoryCode: any,
    accountNumber: string
  ): boolean {
    return (
      this.accountInfoService.isLeaseAccount(portfolioCategoryCode) &&
      this.accountInfoService.isMaturityBilled(accountNumber)
    );
  }

  public destroy() {
    this.store.dispatch(this.isInPaymentFlowActions.setIsInPaymentFlow(false));
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(false));
  }

  public isTerminatedAccount(statusCategoryCode: any) {
    return this.accountInfoService.isTerminatedAccount(statusCategoryCode);
  }

  private postPaymentEntryServiceLoaded(responses: any) {
    if (responses && responses.length > 2) {
      let contactDetail: ContactDetail = responses[0];
      let paymentInfo: PaymentInfo = responses[1];
      let contactUpComing: ContactUpcoming = responses[2];

      if (contactDetail && contactDetail.error && this.logger) {
        this.logger.logcritical([
          "Error occurred in: " +
            Constants.PaymentEntryComponentName +
            ", customer number:" +
            this.userService.getCustomerNumber() +
            ", cashAccountViewService.findAccounts().",
        ]);
        this.handleServicesDownError();
      }
      if (paymentInfo && paymentInfo.error && this.logger) {
        this.logger.logcritical([
          "Error occurred in: " +
            Constants.PaymentEntryComponentName +
            ", account number:" +
            this.contractDetail.accountNumber +
            ", paymentViewService.getOneTimePaymentDetails().",
        ]);
      }
      if (contactUpComing && contactUpComing.error && this.logger) {
        this.logger.logcritical([
          "Error occurred in: " +
            Constants.PaymentEntryComponentName +
            ", account number:" +
            this.contractDetail.accountNumber +
            ", customerACHService.findUpcomingUpays().",
        ]);
      }
      //getSinglePaymentViewdata returns fault for terminated accounts with no maturity bill
      if (
        contactDetail &&
        !contactDetail.error &&
        paymentInfo &&
        contactUpComing &&
        !contactUpComing.error
      ) {
        this.setPaymentAmountSource(contactDetail);
        this.postGetOneTimePaymentDetails(paymentInfo);
        this.postFindUpcomingUpays(
          contactUpComing,
          this.contractDetail.accountNumber
        );
        this.setEmailAddresses();
        this.checkLastPaymentDetails();
        if (paymentInfo.cardPaymentFeeAmount > Constants.DECIMAL_ZERO) {
          this.translateService
            .get("ngw.payment-source.payment-card.debit-card-fee-amount-msg", {
              paymentcardfeeamount: paymentInfo.cardPaymentFeeAmount,
            })
            .subscribe((res: string) => {
              this.debitCardFeeMsg = res;
            });
        }
      }
    }
    this.pageDataLoaded();
    this.setDefaultPaymentDate();
    this.setDataForCa();
  }
  private postFindUpcomingUpays(
    response: ContactUpcoming,
    accountNumber: string
  ): void {
    if (response.error) {
      this.handleServicesDownError();
    } else {
      if (response.scheduledItem) {
        this.scheduledPayments.push(response.scheduledItem);
      }
      this.scheduledItem = _.find(this.scheduledPayments, function (sp) {
        return sp.accountNumber === accountNumber;
      });
    }
  }

  private setDefaultPaymentDate() {
    //Payment date default to First allowed date range
    this.paymentEntry.paymentdate = this.paymentEntry.firstDatePaymentAllowed
      ? this.paymentEntry.firstDatePaymentAllowed
      : this.getTodaysDate();
    this.onPaymentDateChange(this.paymentEntry.paymentdate);
  }

  getTodaysDate(): Date {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
  }

  public setDefaultPaymentSource() {
    this.paymentEntry.selectedPaymentSource = this.paymentEntry.sources[0];
  }

  private onGetOneTimePaymentDetailsSuccess(paymentInfo: PaymentInfo) {
    this.featureIndicatorService
      .getFeatureIndicator(FeatureIndicatorTypes.DisableCardRegistration)
      .subscribe((addCardDisabledFeatureSwitch) => {
        this.isAddCardDisabled = addCardDisabledFeatureSwitch;
        if (paymentInfo.aCHRestrictions != null) {
          this.paymentEntry.aCHRestrictions = paymentInfo.aCHRestrictions;
        }
        if (paymentInfo.cardRestrictions != null) {
          this.paymentEntry.cardRestrictions = paymentInfo.cardRestrictions;
        }
        this.paymentEntry.cardPaymentFeeAmount =
          paymentInfo.cardPaymentFeeAmount;
        this.paymentEntry.paymentEligibilityFlags =
          paymentInfo.paymentEligibilityFlags;
        this.paymentEntry.paymentOverrideLimit =
          paymentInfo.paymentOverrideLimit;

        if (paymentInfo.paymentAccounts.length > 0) {
          this.setPaymentSource(paymentInfo);
          if (
            this.paymentEntry &&
            this.paymentEntry.sources &&
            this.paymentEntry.sources.length > 0 &&
            this.paymentEntry.sources[0].displayAccountNumber !=
              this.addAchSource &&
            this.paymentEntry.sources[0].displayAccountNumber !=
              this.addDebitCardSource &&
            this.paymentEntry.sources[0].displayAccountNumber !=
              this.addCreditCardSource
          ) {
            this.setDefaultPaymentSource();
          } else {
            this.paymentEntry.selectedPaymentSource = null;
          }
        } else {
          this.pushPaymentSourcesOptionsWithoutPaymentSources();
          this.paymentEntry.selectedPaymentSource = null;
          this.paymentEntry.noPaymentSource = true;
        }
      });
  }

  private postGetOneTimePaymentDetails(paymentInfo: PaymentInfo) {
    if (paymentInfo.error === false) {
      this.onGetOneTimePaymentDetailsSuccess(paymentInfo);
    } else {
      this.paymentEntryError = true;
      this.paymentNotAllowedForCa = true;
      let errorTranslationKey: string;
      if (paymentInfo.faultType) {
        this.logger.logcritical([
          "Error occurred in: " +
            Constants.PaymentEntryComponentName +
            ", account number:" +
            this.contractDetail.accountNumber +
            ", paymentViewService.getOneTimePaymentDetails(), fault: " +
            paymentInfo.faultType,
        ]);

        switch (paymentInfo.faultType) {
          case FaultCodes.BMWFSAM_Services_AccountNotFound_V200804_AccountNotFoundFault:
            errorTranslationKey = "ngw.global.technical-error";
            this.translateService
              .get(errorTranslationKey)
              .subscribe((res: string) => {
                this.errorMessage = res;
              });
            this.analytics.pushSiteToolError({
              siteToolError: errorTranslationKey,
            });
            break;
          case FaultCodes.BMWFSAM_Services_CustomerCashManagement_AccountIneligibleFault:
            if (paymentInfo.errorResponse) {
              let errorResponse = JSON.parse(paymentInfo.errorResponse);
              if (
                errorResponse.FaultDetail &&
                errorResponse.FaultDetail.IneligibleReasonCode ===
                  Constants.TERMINATED_ZERO_BALANCE
              ) {
                this.terminatedAccountPaymentNotAllowed = true;
                const errorTranslationKey =
                  "ngw.payment.entry.error.term-account-ineligible-for-payments";
                this.translateService
                  .get(errorTranslationKey, { route: routes.contactus })
                  .subscribe((res: string) => {
                    this.errorMessage = res;
                  });
                this.analytics.pushSiteToolError({
                  siteToolError: errorTranslationKey,
                });
              }
            }
            if (!this.terminatedAccountPaymentNotAllowed) {
              let contactUsTxt, contactUsLink;
              this.translateService
                .get("ngw.general.contact-us")
                .subscribe((res: string) => {
                  contactUsTxt = res;
                });
              this.paymentEntryErrorWithContactUs = true;
              contactUsLink =
                '<a href="' + routes.contactus + '">' + contactUsTxt + "</a>";
              const errorTranslationKey =
                "ngw.payment.entry.error.account-ineligible";
              this.translateService
                .get(errorTranslationKey, { contactUsLink: contactUsLink })
                .subscribe((res: string) => {
                  this.errorMessage = res;
                });
              this.analytics.pushSiteToolError({
                siteToolError: errorTranslationKey,
              });
            }
            break;
          default:
            errorTranslationKey = "ngw.global.technical-error";
            this.translateService
              .get(errorTranslationKey)
              .subscribe((res: string) => {
                this.errorMessage = res;
              });
            this.analytics.pushSiteToolError({
              siteToolError: errorTranslationKey,
            });
            break;
        }
      } else {
        this.handleServicesDownError();
      }
    }
  }

  public manageSetPaymentSource() {
    this.paymentEntry.showPaymentDateMessage = false;
    this.paymentEntry.paymentDateMessage = Constants.EMPTY;
    let paymentEntryStore: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => paymentEntryStore = x);
    if (!this.objectService.isEmptyObject(paymentEntryStore)) {
      this.setPaymentSource(null);
      this.paymentEntry.selectedPaymentSource =
        paymentEntryStore.selectedPaymentSource;
      if (
        this.paymentEntry.selectedPaymentSource &&
        (this.paymentEntry.selectedPaymentSource.accountType ===
          FinancialAccountDTOAccountType.Debit ||
          this.paymentEntry.selectedPaymentSource.accountType ===
            FinancialAccountDTOAccountType.Credit)
      ) {
        this.paymentEntry.isAch = false;
        //for payment cards, payment cannot be scheduled in future and date must be reset to current date
        if (this.paymentEntry.paymentdate > new Date()) {
          let errorTranslationKey: string;
          if (
            this.paymentEntry.selectedPaymentSource.accountType ===
            FinancialAccountDTOAccountType.Debit
          ) {
            errorTranslationKey =
              "ngw.payment.entry.error.debit-card-cannot-schedule";
            this.translateService
              .get(errorTranslationKey)
              .subscribe((res: string) => {
                this.paymentEntry.paymentDateMessage = res;
              });
          } else {
            errorTranslationKey =
              "ngw.payment.entry.error.credit-card-cannot-schedule";
            this.translateService
              .get(errorTranslationKey)
              .subscribe((res: string) => {
                this.paymentEntry.paymentDateMessage = res;
              });
          }
          this.paymentEntry.showPaymentDateMessage = true;
          this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
        }
        if (this.paymentEntry.cardRestrictions != undefined) {
          this.paymentEntry.paymentdate =
            this.paymentEntry.cardRestrictions.firstDatePaymentAllowed;
        }
        this.paymentEntry.paymentdateReadOnly = Constants.READONLY;
      } else {
        this.paymentEntry.isAch = true;
        if (this.paymentEntry.aCHRestrictions !== undefined) {
          this.paymentEntry.firstDatePaymentAllowed =
            this.paymentEntry.aCHRestrictions.firstDatePaymentAllowed;
          this.paymentEntry.lastDatePaymentAllowed =
            this.paymentEntry.aCHRestrictions.lastDatePaymentAllowed;
          this.paymentEntry.paymentdate =
            this.paymentEntry.cardRestrictions.firstDatePaymentAllowed;
        }
        this.paymentEntry.showPaymentDateMessage = false;
        this.paymentEntry.paymentDateMessage = "";
        this.paymentEntry.paymentdateReadOnly = this.defaultReadonlyValue;
      }
      if (
        this.paymentEntry.selectedPaymentSource?.displayAccountNumber ==
          this.addAchSource ||
        this.paymentEntry.selectedPaymentSource?.displayAccountNumber ==
          this.addDebitCardSource ||
        this.paymentEntry.selectedPaymentSource?.displayAccountNumber ==
          this.addCreditCardSource
      ) {
        this.paymentEntry.selectedPaymentSource = null;
      } else {
        this.paymentEntry.noPaymentSource = false;
      }
    }
  }

  public getPaymentSourceWithRestrictions(
    paymentInfo: PaymentInfo,
    paymentAccount: FinancialAccountDTO,
    lastUsed: number
  ): void {
    if (
      (paymentInfo.paymentEligibilityFlags &&
        paymentInfo.paymentEligibilityFlags["DebitCardAllowed"] &&
        paymentAccount.accountType === FinancialAccountDTOAccountType.Debit) ||
      (paymentInfo.paymentEligibilityFlags["CreditCardAllowed"] &&
        paymentAccount.accountType === FinancialAccountDTOAccountType.Credit)
    ) {
      let displayAccountNumber =
        this.paymentHelperService.getPaymentDisplayName(paymentAccount);
      this.currentPaymentSource = new PaymentSource(
        paymentAccount.financialAccountNumber,
        displayAccountNumber,
        paymentAccount.financialAccountId,
        lastUsed,
        null,
        paymentAccount.accountType
      );
    }
  }

  public pushPaymentSourceWithRestrictions(): void {
    if (!this.isAddCardDisabled) {
      let addDebitCardSource = new PaymentSource(
        Constants.EMPTY,
        this.addDebitCardSource,
        Constants.EMPTY,
        -1
      );
      if (
        this.paymentEntry.paymentEligibilityFlags &&
        this.paymentEntry.paymentEligibilityFlags["DebitCardAllowed"]
      ) {
        this.paymentEntry.sources.push(addDebitCardSource);
      }
      let addCreditCardSource = new PaymentSource(
        Constants.EMPTY,
        this.addCreditCardSource,
        Constants.EMPTY,
        -2
      );
      if (
        !this.isAddCardDisabled &&
        this.paymentEntry.paymentEligibilityFlags &&
        this.paymentEntry.paymentEligibilityFlags["CreditCardAllowed"]
      ) {
        this.paymentEntry.sources.push(addCreditCardSource);
      }
    }
  }

  public pushPaymentSourcesWithRestrictionsInExistingArray(): void {
    if (!this.isAddCardDisabled) {
      if (
        this.paymentEntry.paymentEligibilityFlags &&
        this.paymentEntry.paymentEligibilityFlags["DebitCardAllowed"] &&
        !this.isAddCardDisabled &&
        !_.includes(
          this.paymentEntry.sources,
          this.paymentEntry.sources.find(
            (source) => source.displayAccountNumber === this.addDebitCardSource
          )
        )
      ) {
        let addDebitCardSource = new PaymentSource(
          Constants.EMPTY,
          this.addDebitCardSource,
          Constants.EMPTY,
          -2
        );
        this.paymentEntry.sources.push(addDebitCardSource);
      }
      if (
        this.paymentEntry.paymentEligibilityFlags &&
        this.paymentEntry.paymentEligibilityFlags["CreditCardAllowed"] &&
        !this.isAddCardDisabled &&
        !_.includes(
          this.paymentEntry.sources,
          this.paymentEntry.sources.find(
            (source) => source.displayAccountNumber === this.addCreditCardSource
          )
        )
      ) {
        let addCreditCardSource = new PaymentSource(
          Constants.EMPTY,
          this.addCreditCardSource,
          Constants.EMPTY,
          -3
        );
        this.paymentEntry.sources.push(addCreditCardSource);
      }
    }
  }

  public pushPaymentSourcesOptionsWithoutPaymentSources(): void {
    this.paymentEntry.sources.push(
      new PaymentSource(Constants.EMPTY, this.addAchSource, Constants.EMPTY, 0)
    );
    if (!this.isAddCardDisabled) {
      if (
        this.paymentEntry.paymentEligibilityFlags &&
        this.paymentEntry.paymentEligibilityFlags["DebitCardAllowed"]
      ) {
        let newCardSource = new PaymentSource(
          Constants.EMPTY,
          this.addDebitCardSource,
          Constants.EMPTY,
          -1
        );
        this.paymentEntry.sources.push(newCardSource);
      }
      if (
        !this.isAddCardDisabled &&
        this.paymentEntry.paymentEligibilityFlags &&
        this.paymentEntry.paymentEligibilityFlags["CreditCardAllowed"]
      ) {
        let newCardSource1 = new PaymentSource(
          Constants.EMPTY,
          this.addCreditCardSource,
          Constants.EMPTY,
          -2
        );
        this.paymentEntry.sources.push(newCardSource1);
      }
    }
  }

  public setPaymentSource(paymentInfo: PaymentInfo) {
    let lastUsed: number = 1;
    let displayAccountNumber: string;
    let sources:PaymentSource[]=[];

    if (paymentInfo) {
      let paymentAccounts: FinancialAccountDTO[] = paymentInfo.paymentAccounts;

      for (let paymentAccount of paymentAccounts) {
        this.currentPaymentSource = null;
        if (
          paymentAccount.accountType ===
            FinancialAccountDTOAccountType.Checking ||
          paymentAccount.accountType === FinancialAccountDTOAccountType.Savings
        ) {
          displayAccountNumber =
            this.paymentHelperService.getPaymentDisplayName(paymentAccount);
          this.currentPaymentSource = new PaymentSource(
            paymentAccount.financialAccountNumber,
            displayAccountNumber,
            paymentAccount.financialAccountId,
            lastUsed,
            null,
            paymentAccount.accountType
          );
        } else if (paymentInfo.cardRestrictions) {
          this.getPaymentSourceWithRestrictions(
            paymentInfo,
            paymentAccount,
            lastUsed
          );
        }
        if (this.currentPaymentSource) {
          sources.push(this.currentPaymentSource);
          if (lastUsed === 1) {
            lastUsed = 0;
          }
        }
      }
      this.paymentEntry.sources = sources;
      this.paymentEntry.paymentEligibilityFlags =
        paymentInfo.paymentEligibilityFlags;
      this.store.dispatch(
        this.paymentEntryActions.setPaymentEntry(this.paymentEntry)
      );
    } else {
      this.store.select(state => state.PaymentEntry).subscribe(x => this.paymentEntry = x);
    }
    if (this.paymentEntry) {
      if (this.paymentEntry.sources.length === 0) {
        this.currentPaymentSource = new PaymentSource(
          Constants.EMPTY,
          this.addAchSource,
          Constants.EMPTY,
          0
        );
        this.paymentEntry.sources.push(this.currentPaymentSource);

        this.pushPaymentSourceWithRestrictions();
        this.paymentEntry.noPaymentSource = true;
        this.paymentEntry.selectedPaymentSource = null;
      } else {
        if (
          !_.includes(
            this.paymentEntry.sources,
            this.paymentEntry.sources.find(
              (source) => source.displayAccountNumber === this.addAchSource
            )
          )
        ) {
          let newAchSource = new PaymentSource(
            Constants.EMPTY,
            this.addAchSource,
            Constants.EMPTY,
            -1
          );
          this.paymentEntry = Object.assign({}, this.paymentEntry);
          let sourceArr: PaymentSource[] = [];
          Object.keys(this.paymentEntry.sources).forEach(key => {
            sourceArr.push(this.paymentEntry.sources[key]);
          });
          this.paymentEntry.sources = sourceArr;
          this.paymentEntry.sources.push(newAchSource);
        }
        if (!this.isAddCardDisabled) {
          this.pushPaymentSourcesWithRestrictionsInExistingArray();
        }
      }
    }
    this.paymentEntry.sources = _.orderBy(
      this.paymentEntry.sources,
      ["lastUsed", "displayAccountNumber"],
      ["desc", "asc"]
    );
  }

  private handleServicesDownError() {
    this.paymentEntryError = true;
    this.servicesDownError = true;
    const errorTranslationKey = "ngw.global.technical-error";
    this.translateService.get(errorTranslationKey).subscribe((res: string) => {
      this.errorMessage = res;
    });
    this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
  }

  private bindPaymentDate() {
    //Payment Date binding
    this.setCurrentDueDate();
    this.setACHRestrictions();
    this.setPaymentDate();
    //Payment date label if user have a pending payment
    this.checkPaymentAlreadySchedule();
  }

  private setACHRestrictions() {
    if (this.paymentEntry.selectedPaymentSource) {
      if (
        this.paymentEntry.selectedPaymentSource.accountType ===
          FinancialAccountDTOAccountType.Debit ||
        this.paymentEntry.selectedPaymentSource.accountType ===
          FinancialAccountDTOAccountType.Credit
      ) {
        this.paymentEntry.isAch = false;
        if (this.paymentEntry.cardRestrictions !== undefined) {
          this.paymentEntry.firstDatePaymentAllowed =
            this.paymentEntry.cardRestrictions.firstDatePaymentAllowed;
          this.paymentEntry.lastDatePaymentAllowed =
            this.paymentEntry.cardRestrictions.lastDatePaymentAllowed;
        }
        //card payment cannot be scheduled in future
        if (this.paymentEntry.paymentdate > new Date()) {
          let errorTranslationKey: string;
          if (
            this.paymentEntry.selectedPaymentSource.accountType ===
            FinancialAccountDTOAccountType.Debit
          ) {
            errorTranslationKey =
              "ngw.payment.entry.error.debit-card-cannot-schedule";
            this.translateService
              .get(errorTranslationKey)
              .subscribe((res: string) => {
                this.paymentEntry.paymentDateMessage = res;
              });
          } else {
            errorTranslationKey =
              "ngw.payment.entry.error.credit-card-cannot-schedule";
            this.translateService
              .get(errorTranslationKey)
              .subscribe((res: string) => {
                this.paymentEntry.paymentDateMessage = res;
              });
          }
          this.paymentEntry.showPaymentDateMessage = true;
          this.paymentDateError = false;
          this.analytics.pushSiteToolError({
            siteToolError: errorTranslationKey,
          });
        }
      } else {
        this.paymentEntry.isAch = true;
        if (this.paymentEntry.aCHRestrictions !== undefined) {
          this.paymentEntry.firstDatePaymentAllowed =
            this.paymentEntry.aCHRestrictions.firstDatePaymentAllowed;
          this.paymentEntry.lastDatePaymentAllowed =
            this.paymentEntry.aCHRestrictions.lastDatePaymentAllowed;
        }
        this.paymentEntry.showPaymentDateMessage = false;
        this.paymentEntry.paymentDateMessage = "";
      }
    } else {
      this.paymentEntry.isAch = true;
    }
  }

  private setCurrentDueDate() {
    if (
      this.paymentEntry.contractAccountDetails !== undefined &&
      this.paymentEntry.contractAccountDetails.length > 0
    )
      this.paymentEntry.currentDueDate =
        this.paymentEntry.contractAccountDetails[0].nextPaymentDueDate;
  }

  private setPaymentDate() {
    //Payment date default to First allowed date range
    if (
      this.paymentEntry.firstDatePaymentAllowed != undefined &&
      (this.paymentEntry.selectedPaymentSource.accountType ===
        FinancialAccountDTOAccountType.Debit ||
        this.paymentEntry.selectedPaymentSource.accountType ===
          FinancialAccountDTOAccountType.Credit)
    ) {
      this.paymentEntry.paymentdate = this.paymentEntry.firstDatePaymentAllowed;
    } else {
      // to set info message for date
      this.onPaymentDateChange(this.paymentEntry.paymentdate);
    }
  }

  private checkPaymentAlreadySchedule() {
    this.paymentEntry.paymentdateReadOnly = this.defaultReadonlyValue;
    if (
      this.paymentEntry.selectedPaymentSource &&
      (this.paymentEntry.selectedPaymentSource.accountType ===
        FinancialAccountDTOAccountType.Debit ||
        this.paymentEntry.selectedPaymentSource.accountType ===
          FinancialAccountDTOAccountType.Credit)
    ) {
      this.paymentEntry.paymentdateReadOnly = Constants.READONLY;
    } else if (
      this.scheduledItem !== undefined &&
      this.scheduledItem !== null &&
      this.scheduledItem.scheduledItemDTO !== null
    ) {
      const errorTranslationKey = "ngw.payment.entry.error.payment-scheduled";
      this.translateService
        .get(errorTranslationKey, {
          paymentDate: String(
            this.datePipe.transform(
              this.scheduledItem.scheduledItemDTO.scheduledDate,
              this.longDateFormat
            )
          ),
          nextPaymentAmount: String(
            this.currencyPipe.transform(
              this.scheduledItem.scheduledItemDTO.scheduledAmount,
              "USD",
              Constants.CurrencyPipeSymbolNarrow,
              Constants.CurrencyPipeDigitFormat,
              this.locale
            )
          ),
        })
        .subscribe((res: string) => {
          this.paymentEntry.paymentDateMessage = res;
        });
      this.paymentEntry.paymentdateReadOnly = Constants.READONLY;
      this.paymentDateError = false;
      this.paymentEntry.showPaymentDateMessage = true;
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
    }
  }

  private checkLastPaymentDetails() {
    if (
      this.paymentEntry.contractAccountDetails !== undefined &&
      this.paymentEntry.contractAccountDetails.length > 0 &&
      this.paymentEntry.contractAccountDetails[0].lastPaymentDate !==
        undefined &&
      this.paymentEntry.contractAccountDetails[0].lastPaymentAmount !== null
    ) {
      this.translateService
        .get("ngw.payment.entry.prev-payment-detail", {
          lastPaymentDate: String(
            this.datePipe.transform(
              this.paymentEntry.contractAccountDetails[0].lastPaymentDate,
              this.longDateFormat
            )
          ),
          lastPaymentAmount: String(
            this.currencyPipe.transform(
              this.paymentEntry.contractAccountDetails[0].lastPaymentAmount,
              "USD",
              Constants.CurrencyPipeSymbolNarrow,
              Constants.CurrencyPipeDigitFormat,
              this.locale
            )
          ),
        })
        .subscribe((res: string) => {
          this.paymentEntry.lastPaymentInformationText = res;
        });
      this.paymentEntry.showLastPaymentDetail = true;
    }
  }

  public datepickerIsReadonly() {
    return this.paymentEntry.paymentdateReadOnly === Constants.READONLY;
  }

  public onPaymentDateChange(dateValue) {
    this.paymentDateError = false;
    this.paymentEntry.showPaymentDateMessage = false;
    this.setPaymentDateMessages();

    if (!this.isDate(dateValue)) {
      this.paymentEntry.paymentDateMessage = this.inCorrectDateFormatMessage;
      this.paymentDateError = true;
    } else {
      this.paymentEntry.paymentDateMessage =
        this.getPaymentDateValidationMessage(dateValue);
    }
  }

  private getPaymentDateValidationMessage(dateValue): string {
    let dateVal: Date = new Date(this.datePipe.transform(dateValue, "medium"));
    if (
      this.paymentEntry.firstDatePaymentAllowed &&
      this.paymentEntry.lastDatePaymentAllowed &&
      this.paymentEntry.firstDatePaymentAllowed.toLocaleDateString() ==
        this.paymentEntry.lastDatePaymentAllowed.toLocaleDateString() &&
      (dateVal < this.paymentEntry.firstDatePaymentAllowed ||
        dateVal > this.paymentEntry.lastDatePaymentAllowed)
    ) {
      this.paymentDateError = true;
      return this.paymentEligibilityTodayMessage;
    } else if (
      dateVal < this.paymentEntry.firstDatePaymentAllowed ||
      dateVal > this.paymentEntry.lastDatePaymentAllowed
    ) {
      this.paymentDateError = true;
      return this.paymentEligibilityMessage;
    } else if (
      dateVal > this.paymentEntry.currentDueDate &&
      this.paymentEntry.currentDueDate >= new Date()
    ) {
      this.paymentEntry.showPaymentDateMessage = true;
      return this.pastDueDateSelectMessage;
    } else return Constants.EMPTY;
  }

  private setPaymentDateMessages() {
    this.translateService
      .get("ngw.payment.entry.eligible-today", {
        firstPaymentDate: String(
          this.datePipe.transform(
            this.paymentEntry.firstDatePaymentAllowed,
            this.longDateFormat
          )
        ),
      })
      .subscribe((res) => {
        this.paymentEligibilityTodayMessage = res;
      });
    this.translateService
      .get("ngw.payment.entry.eligible", {
        firstPaymentDate: String(
          this.datePipe.transform(
            this.paymentEntry.firstDatePaymentAllowed,
            this.longDateFormat
          )
        ),
        lastPaymentDate: String(
          this.datePipe.transform(
            this.paymentEntry.lastDatePaymentAllowed,
            this.longDateFormat
          )
        ),
      })
      .subscribe((res) => {
        this.paymentEligibilityMessage = res;
      });
    this.translateService
      .get("ngw.payment.entry.past-due-select", {
        currentDueDate: String(
          this.datePipe.transform(
            this.paymentEntry.currentDueDate,
            this.longDateFormat
          )
        ),
      })
      .subscribe((res) => {
        this.pastDueDateSelectMessage = res;
      });
    this.translateService
      .get("ngw.payment.entry.eligible", {
        firstPaymentDate: String(
          this.datePipe.transform(
            this.paymentEntry.firstDatePaymentAllowed,
            this.longDateFormat
          )
        ),
        lastPaymentDate: String(
          this.datePipe.transform(
            this.paymentEntry.lastDatePaymentAllowed,
            this.longDateFormat
          )
        ),
      })
      .subscribe((res) => {
        this.inCorrectDateFormatMessage = res;
      });
  }

  private isDate(inputDateValue: any): boolean {
    let dateval = <any>{};
    if (inputDateValue instanceof Date) {
      dateval.value = this.datePipe.transform(inputDateValue, "shortDate");
    } else {
      dateval.value = inputDateValue;
    }
    dateval.touched = true;
    if (this.validatorService.showRegexError(dateval, "date")) return false;
    else return true;
  }

  private setPaymentAmountSource(response: ContactDetail) {
    if (response.error) {
      this.handleServicesDownError();
    } else {
      this.paymentEntry.contractAccountDetails =
        response.contractAccountDetails;
      this.paymentEntry.showPaymentAmountToolTip = false;

      if (
        this.paymentEntry.contractAccountDetails.length > 0 &&
        !this.isLumpSumPayment
      ) {
        this.bindPaymentDueList();
        this.setPaymentAmount();
      }
    }
    this.contractAccountDetailsLoaded = true;
    this.pageDataLoaded();
  }

  public bindPaymentDueList() {
    let paymentDueSource: PaymentDueSource;
    let paymentDue: PaymentDueSource[] = [];

    paymentDueSource = new PaymentDueSource(
      this.amountDueText,
      this.paymentEntry.contractAccountDetails[0].totalAmountDue > 0
        ? this.paymentEntry.contractAccountDetails[0].totalAmountDue
        : 0
    );
    paymentDue.push(paymentDueSource);
    paymentDueSource = new PaymentDueSource(
      this.minimumAmountDueText,
      this.paymentEntry.contractAccountDetails[0].pastDueAmount > 0
        ? this.paymentEntry.contractAccountDetails[0].pastDueAmount
        : 0
    );
    paymentDue.push(paymentDueSource);
    paymentDueSource = new PaymentDueSource(
      this.monthlyAmountDueText,
      this.paymentEntry.contractAccountDetails[0].monthlyTotalPaymentAmount > 0
        ? this.paymentEntry.contractAccountDetails[0].monthlyTotalPaymentAmount
        : 0
    );
    paymentDue.push(paymentDueSource);

    if (
      paymentDue.filter(
        (item) => item.paymentType == this.minimumAmountDueText
      )[0].paymentAmount > 0 &&
      paymentDue.filter(
        (item) => item.paymentType == this.minimumAmountDueText
      )[0].paymentAmount !=
        paymentDue.filter((item) => item.paymentType == this.amountDueText)[0]
          .paymentAmount
    ) {
      this.paymentEntry.paymentDueList.push(
        new PaymentAmountDDL(
          paymentDue.filter(
            (item) => item.paymentType == this.minimumAmountDueText
          )[0].paymentType +
            HTMLConstants.getWhiteSpaces(7) +
            this.currencyPipe.transform(
              paymentDue.filter(
                (item) => item.paymentType == this.minimumAmountDueText
              )[0].paymentAmount,
              "USD",
              Constants.CurrencyPipeSymbolNarrow,
              Constants.CurrencyPipeDigitFormat,
              this.locale
            ),
          paymentDue.filter(
            (item) => item.paymentType == this.minimumAmountDueText
          )[0].paymentType,
          paymentDue.filter(
            (item) => item.paymentType == this.minimumAmountDueText
          )[0].paymentAmount
        )
      );
    }

    if (
      paymentDue.filter((item) => item.paymentType == this.amountDueText)[0]
        .paymentAmount <= 0
    ) {
      this.paymentEntry.paymentDueList.push(
        new PaymentAmountDDL(
          paymentDue.filter(
            (item) => item.paymentType == this.monthlyAmountDueText
          )[0].paymentType +
            HTMLConstants.getWhiteSpaces(7) +
            this.currencyPipe.transform(
              paymentDue.filter(
                (item) => item.paymentType == this.monthlyAmountDueText
              )[0].paymentAmount,
              "USD",
              Constants.CurrencyPipeSymbolNarrow,
              Constants.CurrencyPipeDigitFormat,
              this.locale
            ),
          paymentDue.filter(
            (item) => item.paymentType == this.monthlyAmountDueText
          )[0].paymentType,
          paymentDue.filter(
            (item) => item.paymentType == this.monthlyAmountDueText
          )[0].paymentAmount
        )
      );
    }

    if (
      paymentDue.filter((item) => item.paymentType == this.amountDueText)[0]
        .paymentAmount > 0
    ) {
      this.paymentEntry.paymentDueList.push(
        new PaymentAmountDDL(
          paymentDue.filter((item) => item.paymentType == this.amountDueText)[0]
            .paymentType +
            HTMLConstants.getWhiteSpaces(7) +
            this.currencyPipe.transform(
              paymentDue.filter(
                (item) => item.paymentType == this.amountDueText
              )[0].paymentAmount,
              "USD",
              Constants.CurrencyPipeSymbolNarrow,
              Constants.CurrencyPipeDigitFormat,
              this.locale
            ),
          paymentDue.filter(
            (item) => item.paymentType == this.amountDueText
          )[0].paymentType,
          paymentDue.filter(
            (item) => item.paymentType == this.amountDueText
          )[0].paymentAmount
        )
      );
      this.paymentEntry.showPaymentAmountToolTip = true;
    }
    this.paymentEntry.paymentDueList.push(
      new PaymentAmountDDL(this.otherAmountText, this.otherAmountText, 0)
    );
  }

  private setPaymentAmount() {
    if (
      this.paymentEntry.paymentDueList.filter(
        (item) => item.keyDesc == this.amountDueText
      )[0] != undefined &&
      this.paymentEntry.paymentDueList.filter(
        (item) => item.keyDesc == this.amountDueText
      )[0].value > 0
    ) {
      if (
        this.paymentEntry.paymentDueList.filter(
          (item) => item.keyDesc == this.minimumAmountDueText
        )[0] != undefined &&
        this.paymentEntry.paymentDueList.filter(
          (item) => item.keyDesc == this.minimumAmountDueText
        )[0].value > 0 &&
        this.paymentEntry.paymentDueList.filter(
          (item) => item.keyDesc == this.minimumAmountDueText
        )[0].value !=
          this.paymentEntry.paymentDueList.filter(
            (item) => item.keyDesc == this.amountDueText
          )[0].value
      ) {
        this.paymentEntry.selectedPaymetAmount =
          this.paymentEntry.paymentDueList.filter(
            (item) => item.keyDesc == this.minimumAmountDueText
          )[0];
        this.paymentEntry.paymentAmount =
          this.paymentEntry.paymentDueList.filter(
            (item) => item.keyDesc == this.minimumAmountDueText
          )[0].value;
      } else {
        this.paymentEntry.selectedPaymetAmount =
          this.paymentEntry.paymentDueList.filter(
            (item) => item.keyDesc == this.amountDueText
          )[0];
        this.paymentEntry.paymentAmount =
          this.paymentEntry.paymentDueList.filter(
            (item) => item.keyDesc == this.amountDueText
          )[0].value;
      }
    } else if (
      this.paymentEntry.paymentDueList.filter(
        (item) => item.keyDesc == this.monthlyAmountDueText
      ).length > 0
    ) {
      this.paymentEntry.selectedPaymetAmount =
        this.paymentEntry.paymentDueList.filter(
          (item) => item.keyDesc == this.monthlyAmountDueText
        )[0];
      this.paymentEntry.paymentAmount = this.paymentEntry.paymentDueList.filter(
        (item) => item.keyDesc == this.monthlyAmountDueText
      )[0].value;
    }
  }

  public getAmountDue() {
    let totalAmountDue: number = Constants.DECIMAL_ZERO;

    if (
      this.paymentEntry &&
      this.paymentEntry.paymentDueList &&
      this.paymentEntry.paymentDueList.length > 0 &&
      this.paymentEntry.paymentDueList.filter(
        (item) => item.keyDesc == this.amountDueText
      ).length > 0
    ) {
      totalAmountDue = this.paymentEntry.paymentDueList.filter(
        (item) => item.keyDesc == this.amountDueText
      )[0].value;
    }
    return totalAmountDue;
  }

  private padLeft(value: string, padding: number, paddingChar: string): string {
    return Array(padding + 1).join(paddingChar) + value;
  }

  public onPaymentAmountChange(sourceValue) {
    if (sourceValue.keyDesc === "Other") {
      this.paymentEntry.otherAmountSelected = true;
      this.paymentEntry.paymentAmount = null;
    } else {
      this.paymentEntry.otherAmountSelected = false;
      this.otherAmountRequiredError = false;
      this.paymentEntry.paymentAmount = sourceValue.value;
    }
    this.paymentEntry.selectedPaymetAmount = sourceValue;
    this.invalidOtherAmountError = false;
    this.invalidOtherAmountErrorMessage = Constants.EMPTY;
  }

  public onOtherAmountChange(sourceValue) {
    if (!this.showAmountError(sourceValue)) {
      let _otherAmount: string;
      let _amountDue: string;
      this.invalidOtherAmountErrorMessage = Constants.EMPTY;
      this.invalidOtherAmountError = false;
      if (sourceValue) {
        _otherAmount = this.currencyPipe.transform(
          sourceValue,
          "USD",
          "symbol"
        );
        _amountDue = this.currencyPipe.transform(
          this.getAmountDue(),
          "USD",
          "symbol"
        );

        if (this.terminatedAccount && this.getAmountDue() < +sourceValue) {
          const errorTranslationKey =
            "ngw.payment.entry.error.maturity-bill-invalid-amount";
          this.translateService
            .get(errorTranslationKey, {
              otherAmount: _otherAmount,
              amountDue: _amountDue,
            })
            .subscribe((res: string) => {
              this.invalidOtherAmountErrorMessage = res;
            });
          this.invalidOtherAmountError = true;
          this.analytics.pushSiteToolError({
            siteToolError: errorTranslationKey,
          });
        }
        this.paymentEntry.paymentAmount = sourceValue;
      }
    }
  }

  public eventHandler(event) {
    this.errorMessage = Constants.EMPTY;
    this.amountValidationError = false;
    this.paymentEntryError = false;
  }

  public onPaymentSourceChange(sourceValue) {
    this.paymentEntry.showPaymentDateMessage = false;
    this.paymentEntry.paymentDateMessage = Constants.EMPTY;
    let paymentEntryStore: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => paymentEntryStore = x);
    if (this.objectService.isEmptyObject(paymentEntryStore)) {
      this.store.dispatch(
        this.paymentEntryActions.setPaymentEntry(this.paymentEntry)
      );
    }
    if (
      (paymentEntryStore &&
        sourceValue.displayAccountNumber == this.addAchSource) ||
      sourceValue.displayAccountNumber == this.addDebitCardSource ||
      sourceValue.displayAccountNumber == this.addCreditCardSource
    ) {
      this.setPaymentDateInStoreBeforeOpenDialog(
        this.paymentEntry,
        paymentEntryStore
      );
    }
    if (sourceValue.displayAccountNumber === this.addAchSource) {
      this.addAchDialogRef = this.dialogService.openAddAchDialog({
        data: { component: Constants.ONE_TIME_FLOW },
        disableClose: true,
      });
      this.addAchDialogRef.afterClosed().subscribe((result) => {
        this.manageSetPaymentSource();
      });
    } else if (sourceValue.displayAccountNumber === this.addDebitCardSource) {
      this.showAddCardDialog(false);
    } else if (sourceValue.displayAccountNumber === this.addCreditCardSource) {
      this.showAddCardDialog(true);
    } else {
      this.paymentEntry.noPaymentSource = false;
      this.paymentEntry.selectedPaymentSource = sourceValue;
      this.pageDataLoaded();
    }
  }


  public setPaymentDateInStoreBeforeOpenDialog(
    paymentEntry: any,
    paymentEntryStore: any
  ) {
    let selectedDate: Date = paymentEntry ? paymentEntry.paymentdate : null;
    let storeDate: Date = paymentEntryStore
      ? paymentEntryStore.paymentdate
      : null;
    if (selectedDate && storeDate && selectedDate !== storeDate) {
      this.store.dispatch(
        this.paymentEntryActions.setPaymentEntry(this.paymentEntry)
      );
    }
  }

  public setEmailAddresses() {
    this.paymentEntry.emails =
      this.paymentHelperService.getPaymentEmailAddresses();
    this.paymentEntry.emails.push(this.enterDifferentEmail);
    this.paymentEntry.emailAddress = this.paymentEntry.emails[0];
    this.paymentEntry.selectedEmailAddress = this.paymentEntry.emails[0];
  }

  public onEmailChange(emailValue) {
    if (emailValue === this.enterDifferentEmail) {
      this.paymentEntry.enterNewEmail = true;
      this.paymentEntry.emailAddress = Constants.EMPTY;
    } else {
      this.paymentEntry.emailAddress = emailValue;
      this.paymentEntry.enterNewEmail = false;
      this.emailPatternError = false;
      this.emailValidationError = false;
      this.emailPatternError = false;
    }
    this.paymentEntry.differentEmail = false;
    this.paymentEntry.selectedEmailAddress = emailValue;
  }

  public setEmailValue(newEmailValue) {
    this.paymentEntry.emailAddress = newEmailValue;
    this.paymentEntry.differentEmail = true;
  }

  public validateFormAndBindPaymentReviewDetails(): boolean {
    let maximnumAllowedPayment: number;
    let paymentDate: Date;
    let maxAmountInfoTxt: string;
    paymentDate = this.paymentEntry.paymentdate;

    if (
      this.paymentEntry.selectedPaymentSource.accountType ===
        FinancialAccountDTOAccountType.Debit ||
      this.paymentEntry.selectedPaymentSource.accountType ===
        FinancialAccountDTOAccountType.Credit
    ) {
      if (paymentDate.getMonth() === new Date().getMonth()) {
        maximnumAllowedPayment =
          this.paymentEntry.cardRestrictions.maximumPaymentAllowedThisMonth;
      } else {
        maximnumAllowedPayment =
          this.paymentEntry.cardRestrictions.maximumPaymentAllowedNextMonth;
      }
      maxAmountInfoTxt = "ngw.payment.entry.error.card-max-payment-amount";
    } else {
      if (paymentDate.getMonth() === new Date().getMonth()) {
        maximnumAllowedPayment =
          this.paymentEntry.aCHRestrictions.maximumPaymentAllowedThisMonth;
      } else {
        maximnumAllowedPayment =
          this.paymentEntry.aCHRestrictions.maximumPaymentAllowedNextMonth;
      }
      maxAmountInfoTxt = "ngw.payment.entry.error.ach-max-payment-amount";
    }

    if (this.paymentEntry.paymentAmount < 1) {
      const errorTranslationKey = "ngw.payment.entry.error.min-payment-amount";
      this.translateService
        .get(errorTranslationKey)
        .subscribe((res: string) => {
          this.errorMessage = res;
        });
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
      return false;
    } else if (this.paymentEntry.paymentAmount > maximnumAllowedPayment) {
      this.translateService
        .get(maxAmountInfoTxt, {
          value: String(
            this.currencyPipe.transform(
              maximnumAllowedPayment,
              "USD",
              Constants.CurrencyPipeSymbolNarrow,
              Constants.CurrencyPipeDigitFormat,
              this.locale
            )
          ),
        })
        .subscribe((res) => {
          this.errorMessage = res;
        });
      this.analytics.pushSiteToolError({ siteToolError: maxAmountInfoTxt });
      return false;
    } else if (
      this.paymentEntry.differentEmail == true &&
      this.isEmailNotValid()
    ) {
      return false;
    } else {
      this.bindPaymentReviewDetails();
      return true;
    }
  }

  public bindPaymentReviewDetails() {
    this.paymentEntryError = false;
    this.amountValidationError = false;
    this.paymentReviewDetails = new PaymentReviewDetails();
    this.paymentReviewDetails.accountno = this.contractDetail.accountNumber;
    this.paymentReviewDetails.customerno = this.userService.getCustomerNumber();
    this.paymentReviewDetails.paymentSource = this.paymentEntry
      .selectedPaymentSource[0]
      ? this.paymentEntry.selectedPaymentSource[0].displayAccountNumber
      : this.paymentEntry.selectedPaymentSource.displayAccountNumber;
    this.paymentReviewDetails.financialAccountId = this.paymentEntry
      .selectedPaymentSource[0]
      ? this.paymentEntry.selectedPaymentSource[0].financialAccountId
      : this.paymentEntry.selectedPaymentSource.financialAccountId;
    this.paymentReviewDetails.emailAddress = this.paymentEntry.emailAddress;
    this.paymentReviewDetails.paymentDate = this.paymentEntry.paymentdate;
    if (!this.isLumpSumPayment) {
      this.paymentReviewDetails.paymentAmount = this.paymentEntry.paymentAmount;
    } else {
      this.paymentReviewDetails.lumpSumAmount = this.paymentEntry.paymentAmount;
    }
    this.paymentReviewDetails.currentAccountBalance =
      this.paymentEntry.contractAccountDetails[0].pastDueAmount;
    this.paymentReviewDetails.nextPaymentDueDate =
      this.paymentEntry.currentDueDate;
    this.paymentReviewDetails.accountType =
      this.paymentEntry.selectedPaymentSource.accountType;
    this.store.dispatch(
      this.paymentReviewDetailsActions.setPaymentReviewDetails(
        this.paymentReviewDetails
      )
    );
    this.store.dispatch(
      this.paymentEntryActions.setPaymentEntry(this.paymentEntry)
    );
  }

  public navigateToPaymentConfirmation() {
    super.setDataLoadedOnRequest(false);
    this.scrollToTop = false;
    if (this.validateFormAndBindPaymentReviewDetails()) this.makePayment();
    else {
      this.paymentEntryError = true;
      this.amountValidationError = true;
      setTimeout(() => {
        this.scrollToTop = true;
        super.setDataLoadedOnRequest(true);
      }, 500);
      this.resetCheckBox = false;
    }
  }

  private makePayment() {
    if (this.resetCheckBox == false) {
      this.showAlertMessage = true;
      return;
    }
    this.showAlertMessage = false;
    this.disabledPayment = true;
    this.postPayment();
  }

  public postPayment() {
    let paymentReviewDetails: PaymentReviewDetails;
    this.store.select(state => state.PaymentReviewDetails).subscribe(x => paymentReviewDetails = x);
    if (paymentReviewDetails) {
      super.setDataLoadedOnRequest(false);
      this.scrollToTop = false;
      this.paymentService
        .postCustomerPayment(
          this.paymentService.getPostPaymentRequest(this.contractDetail)
        )
        .subscribe((paymentReviewDetails) => {
          this.postCustomerPaymentSuccess(paymentReviewDetails);
        });
    }
  }

  public postCustomerPaymentSuccess(
    paymentReviewDetails: PaymentReviewDetails
  ) {
    super.setDataLoadedOnRequest(true);

    this.paymentReviewDetails = paymentReviewDetails;
    this.store.dispatch(
      this.paymentReviewDetailsActions.setPaymentReviewDetails(
        this.paymentReviewDetails
      )
    );
    if (paymentReviewDetails.error == true) {
      this.scrollToTop = true;
      this.disabledPayment = false;
      this.resetCheckBox = false;
      this.showPaymentError();
    } else {
      this.routerService.navigateToPaymentConfirmation();
      this.logUserActivity(
        paymentReviewDetails.customerno,
        paymentReviewDetails.accountno,
        paymentReviewDetails.accountType
      );
    }
  }

  public showPaymentError() {
    this.paymentEntryError = true;
    if (
      this.paymentReviewDetails.faultType != null &&
      this.paymentReviewDetails.faultType.length > 0
    ) {
      this.logger.logcritical([
        "Error occurred in: " +
          Constants.PaymentEntryComponentName +
          ", account number:" +
          this.paymentReviewDetails.accountno +
          ", paymentService.postCustomerPayment(), fault: " +
          this.paymentReviewDetails.faultType,
      ]);
      this.errorMessage =
        this.paymentHelperService.getPostCustomerPaymentFaultErrorMessage(
          this.paymentReviewDetails
        );
    } else {
      const errorTranslationKey = "ngw.global.technical-error";
      this.translateService
        .get(errorTranslationKey)
        .subscribe((res: string) => {
          this.errorMessage = res;
        });
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
    }
  }

  public navigateToHome() {
    this.userService.resetPaymentHeaderDetails();
    this.routerService.navigateToHome();
  }

  public showAmountError(fieldValue: any, type?: string): boolean {
    this.otherAmountRequiredError =
      this.validatorService.showAmountError(fieldValue);
    return this.otherAmountRequiredError;
  }

  public showEmailError(fieldValue: any, type?: string): boolean {
    this.emailValidationError = this.showError(fieldValue, type);
    if (this.emailValidationError === true) {
      this.emailPatternError = false;
      this.invalidEmailError = false;
    }
    return this.emailValidationError;
  }

  public isEmailNotValid(): boolean {
    let emailValue = <any>{};
    emailValue.value = this.paymentEntry.emailAddress;
    emailValue.touched = true;
    this.invalidEmailError = this.showRegexError(emailValue, "email");
    return this.invalidEmailError;
  }

  private showError(fieldValue: any, type?: string): boolean {
    return this.validatorService.showError(fieldValue, type);
  }

  public showRegexError(fieldValue: any, type?: string): boolean {
    this.emailPatternError = this.validatorService.showRegexError(
      fieldValue,
      type
    );
    if (this.emailPatternError === true) {
      this.emailValidationError = false;
    }
    return this.emailPatternError;
  }

  private pageDataLoaded() {
    this.bindPaymentDate();
    super.setDataLoadedOnRequest(true);
    this.windowService.scrollToTop(true);
  }

  private setDataForCa() {
    this.setPaymentAmountsForCa();
    this.setPaymentSourcesForCa();
    this.setPaymentEmailsForCa();
    this.paymentEntryDataLoaded = true;
  }

  private setPaymentAmountsForCa() {
    let data: { label: string; value: string }[] = [];
    this.paymentEntry.paymentDueList.forEach((el) => {
      data.push({
        label: HTMLConstants.removeExtraSpaces(el.key, 6),
        value: el.value.toString(),
      });
    });
    this.paymentAmountsForCa = [
      {
        data: data,
        alignment: "center",
      },
    ];
  }

  public setPaymentSourcesForCa() {
    let data: { label: string; value: any }[] = [];
    this.paymentEntry.sources.forEach((el) => {
      if (el.financialAccountId !== "") {
        data.push({
          label: el.displayAccountNumber,
          value: el.financialAccountId,
        });
      } else {
        if (el.displayAccountNumber !== this.addDebitCardSource)
          // remove this condition to get 'Add Debit Card' option in payment source on CA
          data.push({
            label: el.displayAccountNumber,
            value: el.displayAccountNumber,
          });
      }
    });
    this.paymentSourcesForCa = [
      {
        data: data,
        alignment: "center",
      },
    ];
  }

  private setPaymentEmailsForCa() {
    let data: { label: string; value: string }[] = [];
    this.paymentEntry.emails.forEach((el) => {
      data.push({ label: el, value: el });
    });
    this.paymentEmailsForCa = [
      {
        data: data,
        alignment: "center",
      },
    ];
  }

  private havePaymentEntryData() {
    let paymentEntryData: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => paymentEntryData = x);
    let contract: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contract = x);
    return this.objectService.isEmptyObject(paymentEntryData) || paymentEntryData == null || paymentEntryData.accountNumber !== contract.accountNumber ? false : true;
  }

  public paymentFormIsInvalid(paymentEntryForm: any) {
    let isInvalid =
      paymentEntryForm.invalid ||
      this.paymentEntry.noPaymentSource ||
      this.otherAmountRequiredError ||
      this.emailValidationError ||
      this.emailPatternError ||
      this.paymentDateError;
    return isInvalid;
  }

  public navigateToPayoffInformation(): void {
    this.routerService.navigateToV2PayOff();
  }

  public navigateToPaymentSources() {
    this.routerService.navigateToPaymentSources();
  }

  public navigateToProfile() {
    this.routerService.navigateTo(routes.myProfile);
  }

  public selectedPaymentSourceIsInvalid(
    field: any,
    showErrorAtStart: boolean = false
  ) {
    return (
      this.validatorService.showError(field, null, true) &&
      this.paymentSourceIsEmpty(field)
    );
  }

  private paymentSourceIsEmpty(field: any) {
    return (
      this.paymentEntry.selectedPaymentSource == null &&
      field &&
      (field.touched || field.dirty)
    );
  }

  public showEnterNewEmail(): boolean {
    return this.paymentEntry.isAch && this.paymentEntry.enterNewEmail;
  }

  public showOneTimeAuthDialog() {
    this.dialogService.openOneTimePaymentAuthDialog();
  }

  public showAddCardDialog(
    isCreditCard: boolean,
    paymentCardAppearanceType: PaymentCardAppearanceType = PaymentCardAppearanceType.MyAccountAppearance,
    country: string = Market.US.toUpperCase(),
    isRetainCheckbox: boolean = true
  ) {
    this.cardHelperService.showAddCardDialog(
      isCreditCard,
      paymentCardAppearanceType,
      country,
      isRetainCheckbox
    );
  }
}
