import { Injectable } from "@angular/core";
import { FSA } from "flux-standard-action";
import { Reducer } from "redux";
import { Financialstatus } from "../../../core/gateway-api";

@Injectable({
  providedIn: 'root'
})
export class LeaseExtActions {
  static SET_LEASEEXT_DAYS_COUNT: string = 'SET_LEASEEXT_DAYS_COUNT';
  static CLEAR_LEASEEXT_DAYS_COUNT: string = 'CLEAR_LEASEEXT_DAYS_COUNT';

  setLeaseExtDaysCount(pastDueDayCount: Financialstatus): FSA<string, any, null> {
    return {
      type: LeaseExtActions.SET_LEASEEXT_DAYS_COUNT,
      payload: pastDueDayCount,
      meta: null
    };
  }

  clearLeaseExtDaysCount(): FSA<string, any, null> {
    return {
      type: LeaseExtActions.CLEAR_LEASEEXT_DAYS_COUNT,
      payload: null,
      meta: null
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class LeaseExtDaysCountReducers {
  static LeaseExtDaysCountReducer: Reducer<Financialstatus[]> = (state: Financialstatus[] = [], action: FSA<string, any, void>) => {
    switch (action.type) {
      case LeaseExtActions.SET_LEASEEXT_DAYS_COUNT:
        return [...state, action.payload];
      case LeaseExtActions.CLEAR_LEASEEXT_DAYS_COUNT:
        return [];
      default:
        return state;
    }
  }
}
