<div class="statement-section">
  <div class="title"><strong>{{'ngw.vehicle-return.estimated-charges.title' | translate}}</strong></div>
</div>
<!-- start statement section -->
<div class="statement-section">
  <mat-accordion>
    <mat-expansion-panel class="vehicle-return-charge-section">
      <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'" [attr.analytics-event-accordian]="'ngw.vehicle-return.estimated-charges.remaining-payment-summary.lbl-remaining-payment' | translate">
        <div class="statement-section-header">
          <div class="statement-section-title">{{'ngw.vehicle-return.estimated-charges.remaining-payment-summary.lbl-remaining-payment' | translate}}</div>
          <div class="statement-section-total">
            {{remainingPaymentsummary.data.total | currency : 'USD':true | minusToParens}}
          </div>
        </div>
      </mat-expansion-panel-header>
      <!-- charge table -->
      <div class="charge-table">
        <div class="charge-table-row">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.remaining-payment-summary.lbl-remaining-payment' | translate}}</div>
          <div class="charge-value">{{remainingPaymentsummary.data.total | currency : 'USD':true | minusToParens}}</div>
        </div>
        <div class="charge-table-row highlight">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.remaining-payment-summary.lbl-remaining-payment-total' | translate}}</div>
          <div class="charge-value">{{remainingPaymentsummary.data.total | currency : 'USD':true | minusToParens}}</div>
        </div>
      </div>
      <!-- end charge table -->
    </mat-expansion-panel>
  </mat-accordion>
  <div class="statement-section-footer">
    <p class="info">{{remainingPaymentsummary.message}}</p>
  </div>
</div>
<!-- end statement section -->
<!-- start mileage summary section -->
<div class="statement-section">
  <mat-accordion>
    <mat-expansion-panel class="vehicle-return-charge-section">
      <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'" [attr.analytics-event-accordian]="'ngw.vehicle-return.estimated-charges.mileage-summary.lbl-excess-mileage-charge' | translate">
        <div class="statement-section-header">
          <div class="statement-section-title">{{'ngw.vehicle-return.estimated-charges.mileage-summary.lbl-excess-mileage-charge' | translate}}</div>
          <div class="statement-section-total">
            {{mileageSummary.data.total | currency : 'USD':true}}
          </div>
        </div>
      </mat-expansion-panel-header>
      <!-- charge table -->
      <div class="charge-table">
        <div class="charge-table-row">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.mileage-summary.lbl-miles-allowed' | translate}}</div>
          <div class="charge-value">{{mileageSummary.data.totalAllowedMiles | number}} mi</div>
        </div>
        <div class="charge-table-row">
          <div class="charge-label">
          {{'ngw.vehicle-return.estimated-charges.mileage-summary.lbl-odometer-reading' | translate}}
          {{mileageSummary.inspectionDate | date : 'longDate'}}
          </div>
          <div class="charge-value">{{mileageSummary.data.actualMiles | number}} mi</div>
        </div>
        <div class="charge-table-row">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.mileage-summary.lbl-excess-mileage' | translate}}</div>
          <div class="charge-value">{{mileageSummary.data.excessMiles | number}} mi</div>
        </div>
        <div class="charge-table-row">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.mileage-summary.lbl-charge-mile' | translate}}</div>
          <div class="charge-value">{{mileageSummary.data.excessChargePerMile | currency : 'USD':true | minusToParens}}</div>
        </div>
        <div class="charge-table-row highlight">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.mileage-summary.lbl-mileage-charge' | translate}}</div>
          <div class="charge-value">{{mileageSummary.data.total | currency : 'USD':true | minusToParens}}</div>
        </div>
      </div>
      <!-- end charge table -->
    </mat-expansion-panel>
  </mat-accordion>
  <div class="statement-section-footer">
    <p class="info">{{mileageSummary.message}}</p>
    <div class="btn-container">
      <div class="btn btn-primary" *ngIf="mileageSummary.showButton" (click)="navigateToAccountDetails()" analytics-event="body.purchase-discounted-miles.purchase-discounted-miles.cta-button">{{'ngw.vehicle-return.estimated-charges.mileage-summary.btn-purchase-miles' | translate}}</div>
    </div>
  </div>
</div>
<!-- end statement section -->
<!-- start statement section -->
<div class="statement-section">
  <mat-accordion>
    <mat-expansion-panel class="vehicle-return-charge-section">
      <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'" [attr.analytics-event-accordian]="'ngw.vehicle-return.estimated-charges.wear-tear-charges.lbl-wear-tear' | translate">
        <div class="statement-section-header">
          <div class="statement-section-title">{{'ngw.vehicle-return.estimated-charges.wear-tear-charges.lbl-wear-tear' | translate}}</div>
          <div class="statement-section-total">
            {{inspectionCharges.totalInspectionCharges  | currency : 'USD':true | minusToParens}}
          </div>
        </div>
      </mat-expansion-panel-header>
      <!-- charge table -->
      <div class="charge-table">
        <div class="charge-table-row" *ngFor="let item of inspectionCharges.inspectionChargesItems;">
          <div class="charge-label">{{item.partName | translate}} : {{item.damageDescription | translate}}</div>
          <div class="charge-value">{{item.partAmount | currency : 'USD':true | minusToParens}}</div>
        </div>
        <div class="charge-table-row highlight">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.wear-tear-charges.lbl-wear-tear-total' | translate}}</div>
          <div class="charge-value">{{inspectionCharges.totalInspectionCharges  | currency : 'USD':true | minusToParens}}</div>
        </div>
      </div>
      <!-- end charge table -->
      <!-- coverage container -->
      <div class="coverage-container" *ngIf="inspectionCharges.isProtectionPlanexist">
        <div class="coverage-title">{{'ngw.vehicle-return.estimated-charges.wear-tear-charges.lbl-protection-plan-text' | translate}}</div>
        <div class="coverage-list">
          <div class="coverage-list-item" *ngFor="let item of inspectionCharges.protectionPlans;">
            {{item.productDescription | translate}}
          </div>
        </div>
      </div>
      <!-- coverage container -->
    </mat-expansion-panel>
  </mat-accordion>
  <div class="statement-section-footer">
    <p class="info">{{inspectionCharges.message | translate}}</p>
    <div class="accordion-action-buttons" *ngIf="isRepairReceiptEnabled">
      <button class="btn" *ngIf="showUploadRepairReceipt" (click)="navigateToRepairReceiptUpload()">{{'ngw.vehicle-return.estimated-charges.wear-tear-charges.btn-view-upload-receipts' | translate}}</button>
      <button class="btn btn-secondary" *ngIf="showSubmittedReceiptButton" (click)="navigateToViewRepairReceipts()">{{'ngw.vehicle-return.estimated-charges.wear-tear-charges.btn-view-submitted-receipts' | translate}}</button>
    </div>
  </div>
</div>
<!-- end statement section -->
<!-- start statement section -->
<div class="statement-section">
  <mat-accordion>
    <mat-expansion-panel class="vehicle-return-charge-section">
      <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'" [attr.analytics-event-accordian]="'ngw.vehicle-return.estimated-charges.dispostion-fee.lbl-disposition-fee' | translate">
        <div class="statement-section-header">
          <div class="statement-section-title">{{'ngw.vehicle-return.estimated-charges.dispostion-fee.lbl-disposition-fee' | translate}}</div>
          <div class="statement-section-total">
            {{disPostionFee.dispostionData.dispositionFeeTotal| currency : 'USD':true | minusToParens  }}
          </div>
        </div>
      </mat-expansion-panel-header>
      <!-- charge table -->
      <div class="charge-table">
        <div class="charge-table-row">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.dispostion-fee.lbl-disposition-fee' | translate}}</div>
          <div class="charge-value">{{disPostionFee.dispostionData.dispositionFee | currency : 'USD':true | minusToParens  }}</div>
        </div>        
        <div class="charge-table-row highlight">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.dispostion-fee.lbl-disposition-fee-total' | translate}}</div>
          <div class="charge-value">{{disPostionFee.dispostionData.dispositionFeeTotal | currency : 'USD':true | minusToParens }}</div>
        </div>
      </div>
      <!-- end charge table -->
    </mat-expansion-panel>
  </mat-accordion>
  <div class="statement-section-footer">
      <p class="info ">{{'ngw.vehicle-return.estimated-charges.dispostion-fee.footer-disposition-fee-2' | translate}}</p>
  </div>
</div>
<!-- end statement section -->
<!-- start statement section -->
<div class="statement-section" *ngIf="feesAndCreditDetailsModel.feesAndCreditDetails && feesAndCreditDetailsModel.feesAndCreditsTotal!==0">
  <mat-accordion>
    <mat-expansion-panel class="vehicle-return-charge-section">
      <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'" [attr.analytics-event-accordian]="'ngw.vehicle-return.estimated-charges.fees-and-credit.lbl-fees-and-credits' | translate">
        <div class="statement-section-header">
          <div class="statement-section-title">{{'ngw.vehicle-return.estimated-charges.fees-and-credit.lbl-fees-and-credits' | translate}}</div>
          <div class="statement-section-total">
            {{feesAndCreditDetailsModel.feesAndCreditsTotal | currency : 'USD':true | minusToParens}}
          </div>
        </div>
      </mat-expansion-panel-header>
      <!-- charge table -->
      <div class="charge-table">
        <div class="charge-table-row" *ngFor="let item of feesAndCreditDetailsModel.feesAndCreditDetails">
          <div class="charge-label">{{item.Key}}</div>
          <div class="charge-value">{{item.Value | currency : 'USD':true | minusToParens}}</div>
        </div>
        <div class="charge-table-row highlight">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.fees-and-credit.lbl-total-fees-and-credits' | translate}}</div>
          <div class="charge-value">{{feesAndCreditDetailsModel.feesAndCreditsTotal | currency : 'USD':true | minusToParens}}</div>
        </div>
      </div>
      <!-- end charge table -->
    </mat-expansion-panel>
  </mat-accordion>
</div>
<!-- end statement section -->
<!-- start statement section -->
<div class="statement-section" *ngIf="depositsAndRefund && depositsAndRefund!==0">
  <mat-accordion>
    <mat-expansion-panel class="vehicle-return-charge-section">
      <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'" [attr.analytics-event-accordian]="'ngw.vehicle-return.estimated-charges.deposits-and-refunds.lbl-deposits-refund' | translate">
        <div class="statement-section-header">
          <div class="statement-section-title">{{'ngw.vehicle-return.estimated-charges.deposits-and-refunds.lbl-deposits-refund' | translate}}</div>
          <div class="statement-section-total">
            {{depositsAndRefund | currency : 'USD':true| minusToParens }}
          </div>
        </div>
      </mat-expansion-panel-header>
      <!-- charge table -->
      <div class="charge-table">
        <div class="charge-table-row">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.deposits-and-refunds.lbl-security-deposits' | translate}}</div>
          <div class="charge-value">{{depositsAndRefund | currency : 'USD':true | minusToParens}}</div>
        </div>
        <div class="charge-table-row highlight">
          <div class="charge-label">{{'ngw.vehicle-return.estimated-charges.deposits-and-refunds.lbl-total-deposits-refund' | translate}}</div>
          <div class="charge-value">{{depositsAndRefund | currency : 'USD':true | minusToParens}}</div>
        </div>
      </div>
      <!-- end charge table -->
    </mat-expansion-panel>
  </mat-accordion>
</div>
<!-- end statement section -->
<!-- start statement section -->
<div class="statement-section">
  <mat-accordion>
    <mat-expansion-panel class="vehicle-return-charge-section total" [disabled]="true">
      <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'" [attr.analytics-event-accordian]="'ngw.vehicle-return.lbl-estimated-total' | translate">
        <div class="statement-section-header">
          <div class="statement-section-title">{{'ngw.vehicle-return.lbl-estimated-total' | translate}}</div>
          <div class="statement-section-total">
            {{estimatedTotal | currency : 'USD':true| minusToParens }}
          </div>
        </div>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="statement-section-footer">
    <p class="info">{{'ngw.vehicle-return.not-a-bill' | translate}}</p>
  </div>
</div>
<!-- end statement section -->
        
<!-- end charges -->
