import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { Payoff } from '../../../_models/my-account/payoff.model';

@Injectable()
export class PayoffsActions {
    static PUSH_PAYOFF: string = 'PUSH_PAYOFF';
    static REMOVE_PAYOFF: string = 'REMOVE_PAYOFF';
    static CLEAR_PAYOFFS: string = 'CLEAR_PAYOFFS';

    pushPayoff(payoff: Payoff): FSA<string, any, null> {
        return {
            type: PayoffsActions.PUSH_PAYOFF,
            payload: payoff,
            meta: null
        };
    }

    removePayoff(fsAccountId: number): FSA<string, any, null> {
        return {
            type: PayoffsActions.REMOVE_PAYOFF,
            payload: fsAccountId,
            meta: null
        };
    }

    clearPayoffs(): FSA<string, any, null> {
        return {
            type: PayoffsActions.CLEAR_PAYOFFS,
            payload: null,
            meta: null
        };
    }
}

@Injectable()
export class PayoffsReducers {
    static PayoffsReducer: Reducer<Payoff[]> = (state: Payoff[] = [], action: FSA<string, any, void>) => {
        switch (action.type) {
            case PayoffsActions.PUSH_PAYOFF:
                return state = [...state, action.payload];
            case PayoffsActions.REMOVE_PAYOFF:
                return state = state ? state.filter(autoPay => autoPay.fSAccountId !== action.payload) : [];
            case PayoffsActions.CLEAR_PAYOFFS:
                return state = [];
            default:
                return state;
        }
    }
}
