<form>
  <div class="dialog modal-delete-smcthreads">
    <h3>{{"ngw.shared.delete-smcthreads-dialog.title" | translate}}</h3>
    <p>{{"ngw.shared.delete-smcthreads-dialog.warning" | translate}}</p>
    <div class="action-buttons">
      <button class="btn btn-secondary" (click)="cancelDeleteThreads(); $event.preventDefault();" type="submit" setFocus analytics-event="body.cancel-delete-msg.cancel-delete-msg.button">
        {{"ngw.shared.delete-smcthreads-dialog.btn-cancel" | translate}}</button>
      <button class="btn btn-primary" tabindex="-1" (click)="deleteThreads()">{{"ngw.shared.delete-smcthreads-dialog.btn-ok" | translate}}</button>
    </div>
  </div>
</form>
