import { Component, OnInit, OnDestroy, Renderer2, Inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-entry-miles',
    templateUrl: './entry-miles.component.html',
    styleUrls: ['./entry-miles.component.scss']
})


export class EntryMilesComponent implements OnInit, OnDestroy{

    constructor(private translateService: TranslateService) {
    }

    ngOnInit() {

    }

    ngOnDestroy() {
    }
   
}
