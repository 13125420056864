import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AmortizationDocument, AmortizationModel } from '../_models/amortization.model';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { UserService } from '../shared/_helper-services/user.service';
import { AmortizationDTO, AmortizationServiceClient, GetAccountAmortizationDocumentRequest, GetAccountAmortizationDocumentRequestAmortType, GetAccountAmortizationDocumentRequestSystem, GetWebAccountAmortizationRequest, GetWebAccountAmortizationRequestSystem, GetWebAccountAmortizationResponse } from './../core/gateway-api';

@Injectable()
export class AmortizationService {

  constructor(private amortizationServiceClient: AmortizationServiceClient,
    private userService: UserService,
    private fsTokenErrorHandler: FSTokenErrorHandler
    ) {
  }

  public getWebAccountAmortization(accountNumber: string): Observable<AmortizationModel> {
    let getWebAccountAmortizationRequest = new GetWebAccountAmortizationRequest();
    getWebAccountAmortizationRequest.accountNumber = accountNumber;
    getWebAccountAmortizationRequest.system = GetWebAccountAmortizationRequestSystem.NGD;
    return this.amortizationServiceClient.getWebAccountAmortization(getWebAccountAmortizationRequest)
      .pipe(mergeMap((response) => { return this.afterGetWebAccountAmortizationSuccess(response); })
      ,catchError((error: any) => { return this.afterGetWebAccountAmortizationFailure(error); }));
  }


  private afterGetWebAccountAmortizationSuccess(result: GetWebAccountAmortizationResponse): Observable<AmortizationModel> {
    let amortization: AmortizationDTO = result && result.accountAmortization ? result.accountAmortization.actualAmortization : null;
    let amortizationModel = new AmortizationModel(amortization, false);
    return of(amortizationModel);
  }

  private afterGetWebAccountAmortizationFailure(error: any): Observable<AmortizationModel> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    let amortizationModel = new AmortizationModel(null, true);
    return of(amortizationModel);
  }

  public getAccountAmortizationDocument(accountNumber: string): Observable<AmortizationDocument> {
    let getAccountAmortizationDocumentRequest = new GetAccountAmortizationDocumentRequest();
    getAccountAmortizationDocumentRequest.accountNumber = accountNumber;
    getAccountAmortizationDocumentRequest.system = GetAccountAmortizationDocumentRequestSystem.MyBMW;
    getAccountAmortizationDocumentRequest.amortType = GetAccountAmortizationDocumentRequestAmortType.ACTUAL;
    getAccountAmortizationDocumentRequest.customerNumber = this.userService.getCustomerNumber();
    return this.amortizationServiceClient.getAccountAmortizationDocument(getAccountAmortizationDocumentRequest)
      .pipe(mergeMap((response) => { return this.afterGetAmortizationDocumentSuccess(response); })
      ,catchError((error: any) => { return this.afterGetAmortizationDocumentFailure(error); }));
  }

  private afterGetAmortizationDocumentSuccess(result: any): Observable<AmortizationDocument> {
    let amortDocument = new AmortizationDocument();
    amortDocument.error = false;
    amortDocument.amortizationData = result.amortizationDocument;
    return of(amortDocument);
  }

  private afterGetAmortizationDocumentFailure(error: any): Observable<AmortizationDocument> {
    this.fsTokenErrorHandler.handleFSTokenError(error);
    let amortDocument = new AmortizationDocument();
    amortDocument.error = true;
    amortDocument.faultType = error.faultType;
    return of(amortDocument);
  }

}
