import { Injectable } from '@angular/core';
import { IAuthProviderUrllBuilderService } from '../../_models/interface/iauthprovider-url-builder.service';
import { routes } from './router.service';
import { EnvironmentConfig } from '../../_models/environment-config';
import { ApplicationConfig } from '../../_models/application-config';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.store';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { ObjectService } from './object.service';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class GcdmOneLoginUrlBuilderService implements IAuthProviderUrllBuilderService {

  public currentRoute: routes;
  public envConfig: EnvironmentConfig;
  public appConfig: ApplicationConfig;

  constructor(
    private store: Store<IAppState>,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private objectService: ObjectService) {

  }

  getLoginRedirectUrl(state: string, token: string): string {
    let url, gcdmConfigClient, gcdmConfigBrand, gcdmConfigCountry, gcdmConfigScope, gcdmConfigResType, ssoredirectURL: string;
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    url = this.envConfig.GCDM_ONE_CONFIG.LOGINURL;
    gcdmConfigClient = this.envConfig.GCDM_ONE_CONFIG.CLIENT;
    gcdmConfigBrand = this.envConfig.GCDM_ONE_CONFIG.BRAND;
    gcdmConfigCountry = this.envConfig.GCDM_ONE_CONFIG.COUNTRY;
    gcdmConfigScope = this.envConfig.GCDM_ONE_CONFIG.SCOPE
    gcdmConfigResType = this.envConfig.GCDM_ONE_CONFIG.RESPONSETYPE;
    ssoredirectURL = this.envConfig.SSO_REDIRECT_URI;

    let domainUrl: string = url.concat(Constants.UrlParametersPrefix);
    let client = this.getRouteParameter(Constants.GcdmOneLoginClient, gcdmConfigClient);
    let brand = this.getRouteParameter(Constants.GcdmOneLoginBrand, gcdmConfigBrand);
    let country = this.getRouteParameter(Constants.GcdmOneLoginCountry, gcdmConfigCountry);
    let language = this.getRouteParameter(Constants.GcdmOneLoginLanguage, this.languageService.getCurrentLanguage());
    let scope = this.getRouteParameter(Constants.GcdmOneLoginScope, gcdmConfigScope);
    let responseType = this.getRouteParameter(Constants.GcdmOneLoginResponseType, gcdmConfigResType);
    let redirectUri = this.getRouteParameter(Constants.GcdmOneLoginRedirectUri, ssoredirectURL);
    let statePart = this.getRouteParameter(Constants.GcdmOneLoginState, encodeURIComponent(state), true);
    statePart = (statePart.length > 0) ? statePart.concat(Constants.UrlParametersConcat) : statePart.concat(Constants.UrlParametersPrefix);
    let langParam = this.getRouteParameter(Constants.LanguageParameterName, this.languageService.getCurrentLanguage(), true);
    let idToken = token ? this.getRouteParameter(Constants.GcdmOneLoginIdToken, token, true) : '';
    return domainUrl.concat(client, brand, country, language, scope, responseType, redirectUri, statePart, langParam, idToken);
  }

  getLogoutRedirectUrl(language: string): string {
    let url, gcdmOneConfigClient, myAccountRedirectUrl: string;
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    url = this.envConfig.GCDM_ONE_CONFIG.LOGOUTURL;
    gcdmOneConfigClient = this.envConfig.GCDM_ONE_CONFIG.CLIENT;
    myAccountRedirectUrl = this.envConfig.NGD_BASE_URL;
    let domainUrl: string = url.concat(Constants.UrlParametersPrefix);
    let client = this.getRouteParameter(Constants.GcdmOneLoginClient, gcdmOneConfigClient);
    let langParam = this.getRouteParameter(Constants.LanguageParameterName, language == Constants.UNDEFINED ? this.languageService.getCurrentLanguage() : language, true);
    myAccountRedirectUrl = myAccountRedirectUrl ? myAccountRedirectUrl.concat(Constants.UrlParametersPrefix, langParam) : '';
    let redirectUrl = this.getRouteParameter(Constants.GcdmOneLogoutRedirectUrl, encodeURIComponent(myAccountRedirectUrl), true);
    return domainUrl.concat(client, redirectUrl);
  }

  getProfileUrl(): string {
    let url, gcdmConfigClient, gcdmConfigBrand, gcdmConfigCountry: string | undefined;
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    url = this.envConfig.GCDM_ONE_CONFIG.PROFILEURL
    gcdmConfigClient = this.envConfig.GCDM_ONE_CONFIG.CLIENT;
    gcdmConfigBrand = this.envConfig.GCDM_ONE_CONFIG.BRAND;
    gcdmConfigCountry = this.envConfig.GCDM_ONE_CONFIG.COUNTRY;
    let domainUrl: string = url.concat(Constants.UrlParametersPrefix);
    let client = this.getRouteParameter(Constants.GcdmOneLoginClient, gcdmConfigClient);
    let brand = this.getRouteParameter(Constants.GcdmOneLoginBrand, gcdmConfigBrand);
    let country = this.getRouteParameter(Constants.GcdmOneLoginCountry, gcdmConfigCountry);
    let langParam = this.getRouteParameter(Constants.LanguageParameterName, this.languageService.getCurrentLanguage(), true);
    return domainUrl.concat(client, country, brand, langParam);
  }

  getRedirectUrl(url: string): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_DOMAIN.concat(url);
    return domainUrl;
  }

  getRegistrationUrl(): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_REGISTER_URL.concat(Constants.UrlParametersPrefix);
    return domainUrl;
  }

  getManageSettingsRedirectUrl(): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_MANAGE_SETTINGS_REDIRECT_URI.concat(Constants.UrlParametersPrefix, Constants.LanguageParameterName, Constants.UrlParameterNameAndValueConcat, this.languageService.getCurrentLanguage());
    return domainUrl;
  }

  getTermsUseRedirectUr(): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    let domainUrl = this.envConfig.AUTH_HUB_REDIRECT_URI.concat(Constants.termsURIsuffix + Constants.UrlParametersPrefix + this.languageService.getLanguageParameter());
    return domainUrl;
  }

  getUnsubscribeUrl(): string {
    let unsubscribeUrl: string;

    this.translateService.get('ngw.footer.links.unsubscribe-url')
      .subscribe(value => { unsubscribeUrl = value });

    return unsubscribeUrl;
  }

  private getRouteParameter(parameterName: string, parameterValue: string, isLastParameter?: boolean): string {
    return isLastParameter
      ? parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue)
      : parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue, Constants.UrlParametersConcat);
  }


}
