import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-one-time-payment-authorization-dialog',
  templateUrl: './one-time-payment-authorization-dialog.component.html',
  styleUrls: ['./one-time-payment-authorization-dialog.component.scss']
})
export class OneTimePaymentAuthorizationDialog {
  constructor(
    public dialogRef: MatDialogRef<OneTimePaymentAuthorizationDialog>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
   
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
