<div class="modal-vehicle-service-dialog">
  <h1>{{dialogTitle}}</h1>
  <p>{{dialogMessage}}</p>
  <p>{{dialogDuringLeaseEndMessage}}</p>
  <div class="action-buttons">
    <button (click)="cancel()" class="btn btn-secondary btn-transparent" id="cancelBtn" analytics-event="body.cancel-vehicle-service-dialog.cancel-vehicle-service-dialog.button">
      {{'ngw.dashboard.account-activity.vehicle-service-dialog.btn-cancel' | translate}}
    </button>
    <button (click)="continue()" class="btn btn-primary" id="continueBtn" type="submit" analytics-event="body.continue-vehicle-service-dialog.continue-vehicle-service-dialog.button">
      {{'ngw.dashboard.account-activity.vehicle-service-dialog.btn-submit' | translate}}
    </button>
  </div>
</div>
