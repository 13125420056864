import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { MaturityBillingFaultType, MaturityBillingModel } from "../_models/contract/maturity-billing.model";
import { MaturityBillStatementPdfModel } from "../_models/my-account/maturitybillstatementpdf.model";
import { ContractAccountDetailDTO, GetMaturityBillStatementPDFRequest, GetMaturityBillStatementPDFResponse, GetMaturityBillingRequest, MaturityBillingServiceClient } from '../core/gateway-api';
import { FaultCodes } from '../shared/FaultCodes';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { AccountInfoService } from '../shared/_helper-services/account-info.service';
import { IAppState } from "../shared/store/app.store";
import { MaturityBillingActions } from '../shared/store/reducers/maturity-billing.reducer';

@Injectable()
export class MaturityBillingService {
  constructor(
    private maturityServiceClient: MaturityBillingServiceClient,
    private store: Store<IAppState>,
    private maturityBillingActions: MaturityBillingActions,
    private fsTokenErrorHandler: FSTokenErrorHandler,
    private accountInfoService: AccountInfoService) { }

  private getCachedMaturityBilling(accountNumber: string): MaturityBillingModel {
    let maturityBillings: MaturityBillingModel[];
    this.store.select(state => state.MaturityBillings).subscribe(x => maturityBillings = x);
    let cachedMaturityBillingModel = _.find(maturityBillings, function (maturityBilling) { return maturityBilling.accountNumber === accountNumber; });
    return cachedMaturityBillingModel;
  }

  public getMaturityBilling(accountNumber: string, refresh: boolean, contract: ContractAccountDetailDTO): Observable<MaturityBillingModel> {
    let cachedMaturityBillingModel = this.getCachedMaturityBilling(accountNumber);
    if (contract
      && this.accountInfoService.isTerminatedAccount(contract.statusCategoryCode)
      && (this.accountInfoService.isLeaseAccount(contract.portfolioCategoryCode) || this.accountInfoService.isOwnersChoiceContractType(contract.contractType))
      && (!cachedMaturityBillingModel || refresh)) {
      let maturityBillingRequest = new GetMaturityBillingRequest();
      maturityBillingRequest.accountNumber = accountNumber;

      return this.maturityServiceClient.getMaturityBilling(maturityBillingRequest)
        .pipe(mergeMap((response) => { return this.afterMaturityBillingSuccess(response, accountNumber, refresh); })
          , catchError((error: any) => { return this.afterMaturityBillingFailure(error); }));
    }
    return of(cachedMaturityBillingModel);
  }

  public getMaturityBillStatementPdf(accountNumber: string, vin: string): Observable<MaturityBillStatementPdfModel> {
    let request = new GetMaturityBillStatementPDFRequest();
    request.vIN = vin;
    request.accountNumber = accountNumber;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => request.userId = x);
    return this.maturityServiceClient.getMaturityBillStatementPDF(request)
      .pipe(mergeMap((response) => { return this.afterMaturityBillingStatementPdfSuccess(response); })
        , catchError((error: any) => { return this.afterMaturityBillingStatementPdfFailure(error); }));
  }

  private afterMaturityBillingStatementPdfSuccess(response: GetMaturityBillStatementPDFResponse): Observable<MaturityBillStatementPdfModel> {
    let maturityBillStatementPDF = new MaturityBillStatementPdfModel();
    if (response && response.maturityBillStatementPdf) {
      maturityBillStatementPDF.error = false;
      maturityBillStatementPDF.pdfData = response.maturityBillStatementPdf;
    }
    return of(maturityBillStatementPDF);
  }

  private afterMaturityBillingStatementPdfFailure(response: GetMaturityBillStatementPDFResponse): Observable<MaturityBillStatementPdfModel> {
    let maturityBillStatementPDF = new MaturityBillStatementPdfModel();

    maturityBillStatementPDF.error = true;

    return of(maturityBillStatementPDF);
  }


  private afterMaturityBillingSuccess(response: any, accountNumber: string, refresh: boolean): Observable<MaturityBillingModel> {
    let maturityBilling = new MaturityBillingModel();
    maturityBilling.maturityBilling = response;
    maturityBilling.accountNumber = accountNumber;
    maturityBilling.error = false;
    if (!refresh) {
      this.store.dispatch(this.maturityBillingActions.pushMaturityBilling(maturityBilling));
    }
    return of(maturityBilling);
  }

  private afterMaturityBillingFailure(error: any): Observable<MaturityBillingModel> {
    let maturityBilling = new MaturityBillingModel();
    maturityBilling.error = true;
    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_CustomerCashManagement_MaturityBillNotFound_V201112_MaturityBillNotFoundFault:
          {
            maturityBilling.faultType = MaturityBillingFaultType.MaturityBillNotFoundFault;
            break;
          } case FaultCodes.BMWFSAM_Services_AccountNotFound_V200906_AccountNotFoundFault:
          {
            maturityBilling.faultType = MaturityBillingFaultType.AccountNotFoundFault;
            break;
          }
      }
    }
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(maturityBilling);
  }

}
