<div class="modal-consent">
  <h3>{{'ngw.dashboard.account-activity.prepare-vehicle-return.customer-intent-dialog.title'|translate}}</h3>
  <div class="general error-container" role="alert" *ngIf="updateIntentError === true">
    <em class="error-validation" id="errorMessage" [innerHtml]="errorMsg">
    </em>
  </div>
  <div class="action-buttons">
    <button class="btn btn-primary" *ngFor="let item of intentList;let i=index" (click)="updateCustomerIntent(i)">{{item.btnText}}</button>
  </div>
</div>
