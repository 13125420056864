import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { AccountActivity } from '../../../_models/payment-details/account-activity.model';


export class AccountActivitiesActions {
  static PUSH_ACCOUNT_ACTIVITY: string = 'PUSH_ACCOUNT_ACTIVITY';
  static REMOVE_ACCOUNT_ACTIVITY: string = 'REMOVE_ACCOUNT_ACTIVITY';
  static CLEAR_ACCOUNT_ACTIVITIES: string = 'CLEAR_ACCOUNT_ACTIVITIES';

  pushAccountActivity(accountActivity: AccountActivity): FSA<string, any, null> {
        return {
          type: AccountActivitiesActions.PUSH_ACCOUNT_ACTIVITY,
          payload: accountActivity,
            meta: null
        };
    }

  removeAccountActivity(accountNumber: string): FSA<string, any, null> {
        return {
          type: AccountActivitiesActions.REMOVE_ACCOUNT_ACTIVITY,
          payload: accountNumber,
            meta: null
        };
    }

  clearAccountActivities(): FSA<string, any, null> {
        return {
          type: AccountActivitiesActions.CLEAR_ACCOUNT_ACTIVITIES,
            payload: null,
            meta: null
        };
    }
}

@Injectable()
export class AccountActivitiesReducers {
  static AccountActivitiesReducer: Reducer<AccountActivity[]> = (state: AccountActivity[] = [], action: FSA<string, any, void>) => {
        switch (action.type) {
          case AccountActivitiesActions.PUSH_ACCOUNT_ACTIVITY:
                return state = [...state, action.payload];
          case AccountActivitiesActions.REMOVE_ACCOUNT_ACTIVITY:
            return state = state ? state.filter(accountActivity => accountActivity.accountNumber !== action.payload) : [];
          case AccountActivitiesActions.CLEAR_ACCOUNT_ACTIVITIES:
                return state = [];
            default:
                return state;
        }
    }
}
