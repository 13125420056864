import { BankInfoDTO, FinancialAccountDTO } from '../core/gateway-api';

export class FinancialAccounts {
    error: boolean;
    accounts: FinancialAccountDTO[];
}


export class BankInfo {
    error: boolean;
    bank: BankInfoDTO;
}
