import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { forkJoin } from 'rxjs';
import { LogSiteActivityService } from '../../_web-services/log-site-activity.service';
import {
  ContractAccountDetailDTO,
  ServicingPreferenceDTO,
  ServicingPreferenceDTOPreferenceCode,
  ServicingPreferenceDTOPreferenceStatus
} from '../../core/gateway-api';
import { ActivityTypes } from '../../shared/enums';
import { IAppState } from "../../shared/store/app.store";
import { Constants } from '../constants';
import { UserActionOnAntoenrollActions } from '../store/reducers/can-start-dashboard-tour.reducers';
import { RecurringPaymentEntry } from './../../_models/recurring-payment/recurring-payment-entry.model';
import { CustomerACHService } from './../../_web-services/customer-ach.service';
import { PreferenceCenterService } from './../../_web-services/preference-center.service';
import { AccountInfoService } from './account-info.service';
import { routes } from './router.service';
import { UserService } from './user.service';

@Injectable()
export class AutoEnrollService {
  elligiblePreferencesForUpdate: ServicingPreferenceDTO[];
  recurringPayments: RecurringPaymentEntry[];
  activeContractDetails: ContractAccountDetailDTO[];
  shouldShowAutoEnrollmentModal: boolean;
  isConnectedApp: boolean;

  constructor(
    private store: Store<IAppState>,
    private translateService: TranslateService,
    private userService: UserService,
    private accountInfoService: AccountInfoService,
    private customerACHService: CustomerACHService,
    private preferenceCenterService: PreferenceCenterService,
    private logSiteActivityService: LogSiteActivityService,
    private userActionOnAntoenrollActions: UserActionOnAntoenrollActions) {
  }

  public autoEnrollElligiblePreferences(recurringPayments?: RecurringPaymentEntry[], isConnectedApp?: boolean): any {
    let hasAutoEnrolledAlready = this.userService.getHasBeenAutoEnrolled();
    let isInConnectedAppMode = isConnectedApp || this.userService.isInConnectedAppMode();
    this.isConnectedApp = isConnectedApp;
    if (isInConnectedAppMode && !hasAutoEnrolledAlready) {
      this.store.select(state => state.ActiveContractAccountDetails).subscribe(x => this.activeContractDetails = x);
      this.recurringPayments = recurringPayments;
      if (!this.recurringPayments || this.recurringPayments.length === 0) {
        let self = this;
        let promisesList = new Array();
        _.each(self.activeContractDetails, function (accountDetails) {
          promisesList.push(self.customerACHService.findEasyPaySchedule(accountDetails.accountNumber));
        })
        forkJoin(promisesList).subscribe(responses => {
          // this.recurringPayments = responses;   // Need to re-verify this.
          this.getAndAutoEnrollElligiblePreferences();
        });
      }
      else {
        this.getAndAutoEnrollElligiblePreferences();
      }
    }
  }

  private getAndAutoEnrollElligiblePreferences() {
    let preferencesObj: ServicingPreferenceDTO[];
    this.store.select(state => state.Preferences).subscribe(x => preferencesObj = x);
    let preferences: ServicingPreferenceDTO[] = _.values(preferencesObj);
    if (preferences.length === 0) {
      let customerNumber: number = this.userService.getCustomerNumber();
      let accountNumbers = this.userService.getAccountNumbers();
      this.preferenceCenterService.getServicingPreferences(customerNumber, accountNumbers)
        .subscribe(response => {
          this.store.select(state => state.Preferences).subscribe(x => preferencesObj = x);
          preferences = _.values(preferencesObj);
          this.filterAndUpdateElligiblePreferences(preferences);
        });
    }
    else {
      this.filterAndUpdateElligiblePreferences(preferences);
    }
  }

  private filterAndUpdateElligiblePreferences(preferences: ServicingPreferenceDTO[]) {
    let customerNumber: number = this.userService.getCustomerNumber();

    if (customerNumber && preferences && preferences.length > 0) {
      this.setAutoEnrollmentModalStatus(preferences);
      this.filterElligiblePreferencesForUpdate(preferences);
      if (this.elligiblePreferencesForUpdate && this.elligiblePreferencesForUpdate.length > 0) {
        this.updateElligiblePreferences(customerNumber);
      }
      else if (!this.shouldShowAutoEnrollmentModal && this.userService.isInConnectedAppMode()) {
        this.setAutoEnrollVisitedFlag(true);
      }
    }
  }

  private filterElligiblePreferencesForUpdate(preferences: ServicingPreferenceDTO[]) {
    let self = this;
    this.elligiblePreferencesForUpdate = _.filter(preferences, function (pref: ServicingPreferenceDTO) {
      if (self.isActiveContract(pref.accountNumber) && !pref.isPreferenceChosenByCustomer) {
        let isPaymentConfirmationPushNoti = pref.preferenceCode === ServicingPreferenceDTOPreferenceCode.PaymentConfirmationPushNoti;
        let isPaymentReminderPushNoti = pref.preferenceCode === ServicingPreferenceDTOPreferenceCode.PaymentReminderPushNoti
          && !self.accountIsEnrolledInRecurringPayments(pref.accountNumber);
        return isPaymentConfirmationPushNoti || isPaymentReminderPushNoti;
      }
      return false;
    });
  }
  private updateElligiblePreferences(customerNumber: number) {
    let self = this;
    _.each(self.elligiblePreferencesForUpdate, function (pref: ServicingPreferenceDTO) {
      pref.preferenceStatus = ServicingPreferenceDTOPreferenceStatus.On;
      if (pref.preferenceCode === ServicingPreferenceDTOPreferenceCode.PaymentReminderPushNoti) {
        pref.preferenceValue = Constants.DefaultDaysForConnectedAppPaymentReminder.toString();
      }
    });

    let accountNumbers: string[] = [];

    _.each(self.activeContractDetails, function (accountDetails) {
      accountNumbers.push(accountDetails.accountNumber)
    });

    return this.preferenceCenterService.updateServicingPreferencesStatus(customerNumber, this.elligiblePreferencesForUpdate)
      .subscribe(response => {
        if (response) {
          _.each(self.elligiblePreferencesForUpdate, function (pref: ServicingPreferenceDTO) {
            let activityType = self.getActivityType(pref.preferenceCode)
            self.logSiteActivityService.logUserActivity(customerNumber, pref.accountNumber, activityType);
          });

          this.userService.setHasBeenAutoEnrolled(true);
          this.showAutoEnrollModal();

        }
      });
  }

  private getActivityType(preferenceCode: ServicingPreferenceDTOPreferenceCode) {
    if (preferenceCode === ServicingPreferenceDTOPreferenceCode.PaymentConfirmationPushNoti) {
      return ActivityTypes.PaymentConfirmationNotification;
    }
    else if (preferenceCode === ServicingPreferenceDTOPreferenceCode.PaymentReminderPushNoti) {
      return ActivityTypes.PaymentReminderNotification;
    }
  }

  private accountIsEnrolledInRecurringPayments(accountNumber: string): boolean {
    let self = this;
    let recurringPaymentForCurrentAccount = _.find(self.recurringPayments, function (rec: RecurringPaymentEntry) {
      return rec.accountNumber === accountNumber &&
        rec.findEasyPayScheduleResponse && rec.findEasyPayScheduleResponse.easyPaySchedule !== null
        && rec.findEasyPayScheduleResponse.easyPaySchedule !== undefined;
    });
    return recurringPaymentForCurrentAccount !== undefined && recurringPaymentForCurrentAccount != null;
  }

  private isActiveContract(accountNumber: string): boolean {
    let self = this;
    let contract = _.find(self.activeContractDetails, function (contract: ContractAccountDetailDTO) { return contract.accountNumber === accountNumber });
    return contract !== undefined;
  }

  private setAutoEnrollmentModalStatus(preferences: ServicingPreferenceDTO[]) {
    if (!this.isConnectedApp) {
      let customerEnrolledCAPreference = _.find(preferences, function (pref: ServicingPreferenceDTO) {
        return (pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaymentConfirmationPushNoti ||
          pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaymentReminderPushNoti) &&
          pref.isPreferenceChosenByCustomer;
      });
      this.shouldShowAutoEnrollmentModal = customerEnrolledCAPreference == undefined;
    } else {
      this.shouldShowAutoEnrollmentModal = false;
    }
  }

  private showAutoEnrollModal(): void {
    this.setAutoEnrollVisitedFlag(false);
  }

  public setAutoEnrollVisitedFlag(tourDisplayflag: boolean) {
    this.store.dispatch(this.userActionOnAntoenrollActions.setAutoEnrollAction(tourDisplayflag));
  }
}

