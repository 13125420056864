import { Component, OnInit, Inject, Injector, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from "../../../core/basecomponent/base.component";
import { LogSiteActivityService } from '../../../_web-services/log-site-activity.service';
import { DOCUMENT } from '@angular/common';
import { RouterService } from "../../../shared/_helper-services/router.service";
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { VehicleReturnEstimateDTO, ContractAccountDetailDTO } from '../../../core/gateway-api';
import { AccountInfoService } from '../../../shared/_helper-services/account-info.service';

@Component({
  selector: 'app-estimated-summary',
  templateUrl: './estimated-summary.component.html',
  styleUrls: ['./estimated-summary.component.scss']
})

export class EstimatedSummaryComponent extends BaseApplicationComponent<EstimatedSummaryComponent> implements OnInit {

  outstandingBalance: number;
  mileageSummary: number;
  inspectionCharges: number;
  dispositionFee: number;
  feesAndCredits: number;
  depositsAndRefunds: number;
  estimatedTotal: number;
  disclaimerTotal: string;
  contractDetail: ContractAccountDetailDTO;
  isOwnersChoice: boolean = false;
  estimatedTotalLabelKey: string;

  @Input() vehicleReturnEstimate: VehicleReturnEstimateDTO;

  constructor(private _location: Location, private translateService: TranslateService, private routerService: RouterService,
    injector: Injector,
    private logSiteActivityService: LogSiteActivityService,
    private currencyPipe: CurrencyPipe,
    private accountInfoService: AccountInfoService,
    @Inject(DOCUMENT) document) {
    super(injector, document);
  }

  ngOnInit() {
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    this.isOwnersChoice = this.accountInfoService.isOwnersChoiceContractType(this.contractDetail.contractType);
    this.estimatedTotalLabelKey = this.accountInfoService.isLeaseAccount(this.contractDetail.portfolioCategoryCode) ? "ngw.vehicle-return.estimated-summary.lbl-estimated-return-total" : "ngw.vehicle-return.estimated-summary.lbl-total";
    if (this.vehicleReturnEstimate) {
      this.estimatedTotal = this.vehicleReturnEstimate.estimatedTotal;
      this.outstandingBalance = this.vehicleReturnEstimate.remainingPaymentsDetails ? this.vehicleReturnEstimate.remainingPaymentsDetails.total : 0;
      this.mileageSummary = this.vehicleReturnEstimate.excessMileageDetails ? this.vehicleReturnEstimate.excessMileageDetails.total : 0;
      this.inspectionCharges = this.vehicleReturnEstimate.inspection ? this.vehicleReturnEstimate.inspection.totalDamageAmount : 0;
      if (this.vehicleReturnEstimate.feesAndCreditsDetails) {
        this.dispositionFee = this.vehicleReturnEstimate.feesAndCreditsDetails.dispositionFeeTotal;
        this.feesAndCredits = this.vehicleReturnEstimate.feesAndCreditsDetails.feeAndCreditsTotal;
        this.depositsAndRefunds = -this.vehicleReturnEstimate.feesAndCreditsDetails.depositRefund;
      }
    }
    let disclaimerTotalKey = this.estimatedTotal > 0 ? "ngw.vehicle-return.estimated-summary.disclaimer-total-positive"
      : "ngw.vehicle-return.estimated-summary.disclaimer-total-negative";
    this.translateService.get(disclaimerTotalKey).subscribe(result => this.disclaimerTotal = result);
  }

}
