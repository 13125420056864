import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { SchedulePaymentModel } from '../_models/recurring-payment/schedule-payment.model';
import {
  FindScheduledItemsRequest,
  FindScheduledItemsResponse,
  PaymentScheduleQueryDTO,
  PaymentScheduleQueryDTOItemStatus,
  PaymentScheduleQueryDTOScheduleGroup,
  ScheduledPaymentServiceClient
} from '../core/gateway-api';
import { IAppState } from '../shared/store/app.store';
import { UpcomingEasyPaySchedulesActions } from '../shared/store/reducers/upcoming-easy-pay-schedules.reducer';

@Injectable()
export class SchedulePaymentService {

  constructor(private scheduledPaymentServiceClient: ScheduledPaymentServiceClient,
    private store: Store<IAppState>,
    private upcomingEasyPaySchedulesActions: UpcomingEasyPaySchedulesActions
  ) {

  }

  public findUpComingEasyPaySchedule(accountNumber: string): Observable<SchedulePaymentModel> {

    let schedulePaymentModel = this.getCachedUpcomingEasyPaySchedules(accountNumber);

    if (!schedulePaymentModel) {
      let findScheduledItemsRequest = new FindScheduledItemsRequest();
      findScheduledItemsRequest.query = new PaymentScheduleQueryDTO();
      findScheduledItemsRequest.query.accountNumber = accountNumber;
      findScheduledItemsRequest.query.includeExpired = false;
      findScheduledItemsRequest.query.scheduleGroup = PaymentScheduleQueryDTOScheduleGroup.Recurring;
      findScheduledItemsRequest.query.itemStatus = PaymentScheduleQueryDTOItemStatus.Scheduled;

      return this.scheduledPaymentServiceClient.findScheduledItems(findScheduledItemsRequest)
        .pipe(mergeMap((response) => { return this.afterfindScheduledItemsSucess(response, accountNumber); })
          , catchError((error: any) => { return this.afterfindScheduledItemsFailure(error, accountNumber); }));
    }
    return of(schedulePaymentModel)
  }

  public getCachedUpcomingEasyPaySchedules(accountNumber: string): SchedulePaymentModel {
    let upcomingEasyPaySchedules: SchedulePaymentModel[];
    this.store.select(state => state.UpcomingEasyPaySchedules).subscribe(x => upcomingEasyPaySchedules = x);
    let cachedViewRepairReceiptModel = _.find(upcomingEasyPaySchedules, function (upcomingEasyPaySchedules) { return upcomingEasyPaySchedules.accountNumber === accountNumber; });
    return cachedViewRepairReceiptModel;
  }

  public afterfindScheduledItemsSucess(result: FindScheduledItemsResponse, accountNumber: string): Observable<SchedulePaymentModel> {
    let schedulePaymentModel = new SchedulePaymentModel();

    schedulePaymentModel.response = result;
    schedulePaymentModel.error = false;
    schedulePaymentModel.accountNumber = accountNumber;

    this.store.dispatch(this.upcomingEasyPaySchedulesActions.pushUpcomingEasyPaySchedules(schedulePaymentModel));

    return of(schedulePaymentModel);
  }

  public afterfindScheduledItemsFailure(result: FindScheduledItemsResponse, accountNumber: string): Observable<SchedulePaymentModel> {
    let schedulePaymentModel = new SchedulePaymentModel();
    schedulePaymentModel.error = true;
    schedulePaymentModel.accountNumber = accountNumber;

    return of(schedulePaymentModel);
  }

}
