<div class="terms-accept-wrapper" *ngIf="dataLoaded">
<app-terms headTitle="{{ 'terms.accept.title' | translate}}" 
checkboxLabel="{{ 'terms.accept.lbl-agree' | translate}}" 
primaryBtnText="{{ 'terms.accept.btn-continue' | translate}}" 
secondaryBtnText="{{ 'terms.accept.btn-close' | translate}}" 
primaryBtnAriaLabel="{{ 'terms.accept.btn-continue-AriaLabel' | translate}}" 
secondaryBtnAriaLabel="{{ 'terms.accept.btn-close-AriaLabel' | translate}}"
primaryBtnAnalyticsEvent="{{ 'terms.accept.btn-continue' | translate}}"
secondaryBtnAnalyticsEvent ="{{ 'terms.accept.btn-close' | translate}}"
[termsContentHtml]="termsContentHtml" checkboxLink="{{ 'terms.accept.lnk-online-services-agreement' | translate}}" 
class="terms-accept"(onCancel)="cancelTerms()" (onContinue)="acceptTerms()" [termsLoadFailed]="termsLoadFailed" errorMessage="{{ 'global.technical-error' | translate}}" (onTermsAccepted)="termsView()" checkboxErrorMessage="{{'validation.error.accepted-required'  | translate}}"
brandType ="{{ 'terms.accept.brandType' | translate}}">
</app-terms>
</div>