import { FinancialAccountDTOAccountType } from '../core/gateway-api';

export class FinancialAccountDetailModel {
  financialAccountId: string;
  customerNumber: number;
  customerId: string;
  bankId: number;
  financialAccountNumber: string;
  displayName: string;
  displaySuffix: string;
  accountType?: FinancialAccountDTOAccountType;
  subType: string;
  expirationDate: Date;
  lastUsedDate: Date;
  error: boolean;
  errorType: FinancialAccountDetailErrorType;
}

export enum FinancialAccountDetailErrorType {
  CustomerNotFound,
  CustomerIdentifierRequired,
  FinancialAccountMustHaveAccountNumber,
  InsertACHFailed
}
