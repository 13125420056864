import {Component, Inject, HostListener, EventEmitter, Injector} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DueDateChangeReviewActions } from '../../../../shared/store/reducers/due-date-change-review.reducer';
import { DueDateChangeEntryActions } from '../../../../shared/store/reducers/due-date-change-entry.reducer';
import { BaseApplicationComponent } from '../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'confirm-cancel-due-date-change-dialog',
    templateUrl: './confirm-cancel-due-date-change-dialog.component.html',
    styleUrls: ['./confirm-cancel-due-date-change-dialog.component.scss']
})

export class ConfirmCancelDueDateChangeDialog extends BaseApplicationComponent<ConfirmCancelDueDateChangeDialog>  {
      
  constructor(private translateService: TranslateService,
      private dueDateChangeReviewActions: DueDateChangeReviewActions,
      private dueDateChangeEntryActions: DueDateChangeEntryActions,
    private dialogRef: MatDialogRef<ConfirmCancelDueDateChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) document,
    injector: Injector) {
        super(injector, document);
    }

    ngOnInit() {
    }

    confirmUserAction() {
        this.store.dispatch(this.dueDateChangeReviewActions.clearDueDateChangeReview());
        this.store.dispatch(this.dueDateChangeEntryActions.clearDueDateChangeEntry());
        this.dialogRef.close(true);
    }
    
    cancelUserAction() {
        this.dialogRef.close();
    }

}
