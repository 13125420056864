import { Component, OnInit, Inject, Injector, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { UpdatePhoneNumbers } from '../../../../../../_models/contract/contact-information.model';
import { MaskService } from '../../../../../../shared/_helper-services/mask.service';
import { Constants } from '../../../../../../shared/constants';
import { ContactActions } from '../../../../../../shared/store/reducers/contact.reducers';
import { ContractAccountDetailsActions } from '../../../../../../shared/store/reducers/contract-account-details.reducers';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import {
  PhoneNumberDTO2, PhoneNumberDTO2PhoneNumberType, PhoneNumberDTO, PhoneNumberDTOPhoneNumberType, PhoneNumberDTO2PhoneConsentStatus,
  PhoneNumberDTO2ConsentSource, PhoneNumberDTOPhoneConsentStatus, PhoneNumberDTOConsentSource, ContactDTO, ContractAccountDetailDTO, AccountPhoneNumberDTO, AccountPhoneNumberDTOPhoneNumberType
} from '../../../../../../core/gateway-api';
import { PartnerContactService } from '../../../../../../_web-services/partner-contact.service';
import { AccountProfileManagementService } from '../../../../../../_web-services/account-profile-management.service';
import { ValidatorService } from '../../../../../../_validators/validator.service';
import { AnalyticsEvent } from '../../../../../../shared/enums';

@Component({
  selector: 'app-edit-phone-numbers',
  templateUrl: './edit-phone-numbers-dialog.component.html',
  styleUrls: ['./edit-phone-numbers-dialog.component.scss']
})

export class EditPhoneNumbersDialogComponent extends BaseApplicationComponent<EditPhoneNumbersDialogComponent> implements OnInit {

  onUpdatePhoneNumbers = new EventEmitter();
  noHomePhone: boolean;
  accountNumber: string;
  primaryMobile: string;
  secondaryMobile: string;
  homePhone: string;
  workPhone: string;
  errorMessage: string;
  noPhoneErrorMessage: string;
  noPhoneError: boolean;
  updatePhoneError: boolean;

  primaryMobileUpdatePending: boolean;
  secondaryMobileUpdatePending: boolean;
  homePhoneUpdatePending: boolean;
  workPhoneUpdatePending: boolean;

  primaryMobileUpdated: boolean;
  secondaryMobileUpdated: boolean;
  workPhoneUpdated: boolean;
  homePhoneUpdated: boolean;

  prevPrimaryMobile: string;
  prevSecondaryMobile: string;
  prevHomePhone: string;
  prevWorkPhone: string;

  phoneNumberType = PhoneNumberDTO2PhoneNumberType;

  constructor(private partnerContactService: PartnerContactService,
    private accountProfileManagementService: AccountProfileManagementService,
    private validatorService: ValidatorService,
    private translateService: TranslateService,
    private contractAccountDetailsActions: ContractAccountDetailsActions,
    private contactActions: ContactActions,
    private maskService: MaskService,
    public dialogRef: MatDialogRef<EditPhoneNumbersDialogComponent>,
    @Inject(DOCUMENT) document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector) {
    super(injector, document);
    this.noHomePhone = false;
    this.noPhoneError = false;
    this.updatePhoneError = false;
  }

  ngOnInit() {
    this.dataLayer = {
      myAccount: {
        siteTool: Constants.EditPhoneNumbers,
        siteToolStep: Constants.SiteToolStart
      }
    }
    super.pushDataLayer({ eventName: AnalyticsEvent.SITE_TOOL });
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
        this.setInitialData();
      });
  }

  private setInitialData() {
    let phoneNumbers: PhoneNumberDTO2[] = this.data ? this.data.phoneNumbers : null;
    this.accountNumber = this.data ? this.data.accountNumber : null;
    this.prevHomePhone = this.homePhone = this.data ? this.data.homePhone : null;
    this.noHomePhone = this.data ? this.data.noHomePhone : null;

    for (let phoneNumber of phoneNumbers) {
      if (phoneNumber.phoneNumberType === PhoneNumberDTO2PhoneNumberType.Work) {
        this.prevWorkPhone = this.workPhone = this.maskService.getFormatedPhoneNumber(phoneNumber.phoneNumber.trim());
      }
      if (phoneNumber.phoneNumberType === PhoneNumberDTO2PhoneNumberType.Cell) {
        this.prevPrimaryMobile = this.primaryMobile = this.maskService.getFormatedPhoneNumber(phoneNumber.phoneNumber.trim());
      }
      if (phoneNumber.phoneNumberType === PhoneNumberDTO2PhoneNumberType.Cell2) {
        this.prevSecondaryMobile = this.secondaryMobile = this.maskService.getFormatedPhoneNumber(phoneNumber.phoneNumber.trim());
      }
    }
  }

  public showPhoneNumberError(fieldValue: any): boolean {
    return this.validatorService.showRegexError(fieldValue, 'phoneNumber');
  }

  public formatPhoneNumber(phoneValue, phoneNumberType: PhoneNumberDTO2PhoneNumberType) {
    if (phoneValue.trim().length === 10 && !isNaN(phoneValue.trim()) && phoneValue.trim() !== Constants.NoPhoneNumber) {
      let formatedPhoneValue = this.maskService.getFormatedPhoneNumber(phoneValue.trim());;
      switch (phoneNumberType) {
        case PhoneNumberDTO2PhoneNumberType.Home:
          this.homePhone = formatedPhoneValue;
          break;
        case PhoneNumberDTO2PhoneNumberType.Work:
          this.workPhone = formatedPhoneValue;
          break;
        case PhoneNumberDTO2PhoneNumberType.Cell:
          this.primaryMobile = formatedPhoneValue;
          break;
        case PhoneNumberDTO2PhoneNumberType.Cell2:
          this.secondaryMobile = formatedPhoneValue;
          break;
      }
    }
  }

  public cancel() {
    this.dataLayer = { ...this.dataLayer, myAccount: { siteTool: null, siteToolStep: null } };
    super.pushDataLayer();
    this.dialogRef.close(true);
  }

  public save() {
    this.scrollToTop = false;
    this.validateNoPhoneAtOtherAccounts();
    if (!this.noPhoneError) {
      this.updatePhoneError = false;
      super.setDataLoadedOnRequest(false);

      this.primaryMobileUpdatePending = false;
      this.secondaryMobileUpdatePending = false;
      this.homePhoneUpdatePending = false;
      this.workPhoneUpdatePending = false;

      if (this.homePhone !== this.prevHomePhone) {
        this.homePhoneUpdated = false;
        let homePhoneNumberDetail = new PhoneNumberDTO();
        if (this.homePhone === Constants.EMPTY) {
          homePhoneNumberDetail.phoneNumber = Constants.NoPhoneNumber
        }
        else {
          homePhoneNumberDetail.phoneConsentStatus = PhoneNumberDTOPhoneConsentStatus.Granted;
          homePhoneNumberDetail.consentSource = PhoneNumberDTOConsentSource.CFA;
          let userID: string;
          this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userID = x);
          homePhoneNumberDetail.lastConsentModifiedBy = userID;
          homePhoneNumberDetail.phoneNumber = this.maskService.getPhoneNumber(this.homePhone);
        }
        homePhoneNumberDetail.phoneNumberType = PhoneNumberDTOPhoneNumberType.Home;
        this.homePhoneUpdatePending = true;

        this.accountProfileManagementService.updateAccountPhone(this.accountNumber, homePhoneNumberDetail)
          .subscribe(result => { this.postUpdatePhone(result, PhoneNumberDTO2PhoneNumberType.Home); });
      }
      else {
        this.homePhoneUpdated = true;
      }

      if (this.workPhone !== this.prevWorkPhone) {
        this.workPhoneUpdated = false;
        this.workPhoneUpdatePending = true;
        this.updateContactPhone(PhoneNumberDTO2PhoneNumberType.Work, this.workPhone);
      }
      else {
        this.workPhoneUpdated = true;
      }

      if (this.primaryMobile !== this.prevPrimaryMobile) {
        this.primaryMobileUpdated = false;
        this.primaryMobileUpdatePending = true;
        this.updateContactPhone(PhoneNumberDTO2PhoneNumberType.Cell, this.primaryMobile);
      }
      else {
        this.primaryMobileUpdated = true;
      }

      if (this.secondaryMobile !== this.prevSecondaryMobile) {
        this.secondaryMobileUpdated = false;
        this.secondaryMobileUpdatePending = true;
        this.updateContactPhone(PhoneNumberDTO2PhoneNumberType.Cell2, this.secondaryMobile);
      }
      else {
        this.secondaryMobileUpdated = true;
      }

      this.analytics.pushSiteToolEvent({ siteTool: Constants.EditPhoneNumbers, siteToolStep: Constants.SiteToolComplete });

    }
    else {
      this.scrollToTop = true; //scroll page to top to display error message
    }
  }

  private validateNoPhoneAtOtherAccounts(): boolean {
    this.noPhoneError = false;
    if ((this.workPhone === Constants.EMPTY || this.workPhone === undefined) && (this.primaryMobile === Constants.EMPTY || this.primaryMobile === undefined) && (this.secondaryMobile === Constants.EMPTY || this.secondaryMobile === undefined)) {
      if (this.noHomePhone) {
        this.noPhoneError = true;
        const errorTranslationKey = "ngw.profile.contact-information.edit-phone-numbers.error.no-phone-validation";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          this.noPhoneErrorMessage = res;
        });
        this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
      }
    }
    return this.noPhoneError;
  }

  private updateContactPhone(phoneType: PhoneNumberDTO2PhoneNumberType, phoneNumber: string) {
    let contactPhoneDetail = new PhoneNumberDTO2();
    if (phoneNumber !== Constants.EMPTY) {
      contactPhoneDetail.phoneConsentStatus = PhoneNumberDTO2PhoneConsentStatus.Granted;
      contactPhoneDetail.consentSource = PhoneNumberDTO2ConsentSource.CFA;
      let userID: string;
      this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userID = x);
      contactPhoneDetail.lastConsentModifiedBy = userID;
      contactPhoneDetail.phoneNumber = this.maskService.getPhoneNumber(phoneNumber);
    }
    else {
      contactPhoneDetail.phoneNumber = Constants.NoPhoneNumber;
    }
    contactPhoneDetail.phoneNumberType = phoneType;

    this.partnerContactService.updateContactPhoneByGcidClientId(contactPhoneDetail)
      .subscribe(result => { this.postUpdatePhone(result, phoneType); });
  }

  private postUpdatePhone(result: UpdatePhoneNumbers, phoneType: PhoneNumberDTO2PhoneNumberType) {
    if (!result.error) {
      this.postUpdateSuccess(phoneType);
    }
    else {
      this.postUpdateFailure(result.errorDesc);
    }
  }

  private postUpdateSuccess(phoneType) {

    switch (phoneType) {
      case PhoneNumberDTO2PhoneNumberType.Home:
        this.homePhoneUpdatePending = false;
        break;
      case PhoneNumberDTO2PhoneNumberType.Work:
        this.workPhoneUpdatePending = false;
        break;
      case PhoneNumberDTO2PhoneNumberType.Cell:
        this.primaryMobileUpdatePending = false;
        break;
      case PhoneNumberDTO2PhoneNumberType.Cell2:
        this.secondaryMobileUpdatePending = false;
        break;
    }

    if (!this.homePhoneUpdatePending && !this.workPhoneUpdatePending && !this.primaryMobileUpdatePending && !this.secondaryMobileUpdatePending) {
      this.updateContactCache();
      this.updateContractAccountDetailsCache();
      super.setDataLoadedOnRequest(true);
      this.onUpdatePhoneNumbers.emit(true);
      this.dialogRef.close();
    }
  }

  private updateContactCache() {
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    let updatedPhoneNumber: PhoneNumberDTO2[] = [];
    contact.phoneNumbers = contact.phoneNumbers ? contact.phoneNumbers : [];
    for (let phoneNumber of contact.phoneNumbers) {
      if (phoneNumber.phoneNumberType === PhoneNumberDTO2PhoneNumberType.Work && this.workPhone !== Constants.EMPTY) {
        phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.workPhone);
        updatedPhoneNumber.push(phoneNumber);
        this.workPhoneUpdated = true;
      }
      if (phoneNumber.phoneNumberType === PhoneNumberDTO2PhoneNumberType.Cell && this.primaryMobile !== Constants.EMPTY) {
        phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.primaryMobile);
        updatedPhoneNumber.push(phoneNumber);
        this.primaryMobileUpdated = true;
      }
      if (phoneNumber.phoneNumberType === PhoneNumberDTO2PhoneNumberType.Cell2 && this.secondaryMobile !== Constants.EMPTY) {
        phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.secondaryMobile);
        updatedPhoneNumber.push(phoneNumber);
        this.secondaryMobileUpdated = true;
      }
    }

    if (this.workPhoneUpdated === false && this.workPhone !== Constants.EMPTY) {
      let phoneNumber = this.getPhoneDetail(PhoneNumberDTO2PhoneNumberType.Work);
      phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.workPhone);
      updatedPhoneNumber.push(phoneNumber);
      this.workPhoneUpdated = true;
    }

    if (this.primaryMobileUpdated === false && this.primaryMobile !== Constants.EMPTY) {
      let phoneNumber = this.getPhoneDetail(PhoneNumberDTO2PhoneNumberType.Cell);
      phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.primaryMobile);
      updatedPhoneNumber.push(phoneNumber);
      this.primaryMobileUpdated = true;
    }

    if (this.secondaryMobileUpdated === false && this.secondaryMobile !== Constants.EMPTY) {
      let phoneNumber = this.getPhoneDetail(PhoneNumberDTO2PhoneNumberType.Cell2);
      phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.secondaryMobile);
      updatedPhoneNumber.push(phoneNumber);
      this.secondaryMobileUpdated = true;
    }

    contact.phoneNumbers = updatedPhoneNumber;
    this.store.dispatch(this.contactActions.setContact(contact));
  }

  private updateContractAccountDetailsCache() {
    let contractAccountDetails: ContractAccountDetailDTO[];
    this.store.select(state => state.ContractAccountDetails).subscribe(x => contractAccountDetails = x);
    let currentContract: ContractAccountDetailDTO = this.getCurrentContract(this.accountNumber, contractAccountDetails);
    let updateHomePhone: AccountPhoneNumberDTO[] = [];

    for (let phoneNumber of currentContract.phoneNumbers) {
      if (phoneNumber.phoneNumberType === AccountPhoneNumberDTOPhoneNumberType.Home && this.homePhone !== Constants.EMPTY) {
        phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.homePhone);
        updateHomePhone.push(phoneNumber);
        this.homePhoneUpdated = true;
      }
      else if (phoneNumber.phoneNumberType !== AccountPhoneNumberDTOPhoneNumberType.Home) {
        updateHomePhone.push(phoneNumber);
      }
    }

    if (this.homePhoneUpdated === false && this.homePhone !== Constants.EMPTY) {
      let phoneNumber = new AccountPhoneNumberDTO();
      phoneNumber.phoneNumberType = AccountPhoneNumberDTOPhoneNumberType.Home;
      phoneNumber.phoneNumber = this.maskService.getPhoneNumber(this.homePhone);
      updateHomePhone.push(phoneNumber);
      this.homePhoneUpdated = true;
    }
    currentContract.phoneNumbers = updateHomePhone;

    this.store.dispatch(this.contractAccountDetailsActions.setContracts(contractAccountDetails));
  }

  private getCurrentContract(accountNumber: string, array: any[]) {
    let currentContract = _.find(array, function (contract) {
      return contract.accountNumber === accountNumber;
    });
    return currentContract;
  }

  private getPhoneDetail(numberType: PhoneNumberDTO2PhoneNumberType): PhoneNumberDTO2 {
    let phoneNumber = new PhoneNumberDTO2();
    phoneNumber.phoneConsentStatus = PhoneNumberDTO2PhoneConsentStatus.Granted;
    phoneNumber.consentSource = PhoneNumberDTO2ConsentSource.CFA;
    let userID: string;
    this.store.select(state => state.ApplicationConfig.USER_ID).subscribe(x => userID = x);
    phoneNumber.lastConsentModifiedBy = userID;
    phoneNumber.phoneNumberType = numberType;

    return phoneNumber;
  }

  private postUpdateFailure(errorDesc: string) {
    super.setDataLoadedOnRequest(true);
    this.updatePhoneError = true;
    this.scrollToTop = true; //scroll page to top to display error message
    const errorTranslationKey = "ngw.global.technical-error";
    this.translateService.get(errorTranslationKey).subscribe((res: string) => {
      this.errorMessage = res;
    });
    this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
    this.logger.logcritical(["Error occurred in: " + Constants.EditPhoneNumbersDialogComponent + ", fault: " + errorDesc]);
  }

  public formIsInvalid(form: any): boolean {
    return form.invalid || this.showPhoneNumberError(form.controls.primaryMobile) || this.showPhoneNumberError(form.controls.secondaryMobile) || this.showPhoneNumberError(form.controls.homePhone) || this.showPhoneNumberError(form.controls.workPhone)
      || ((this.homePhone === Constants.EMPTY || this.homePhone === undefined) && (this.workPhone === Constants.EMPTY || this.workPhone === undefined) && (this.primaryMobile === Constants.EMPTY || this.primaryMobile === undefined) && (this.secondaryMobile === Constants.EMPTY || this.secondaryMobile === undefined))
      || (this.primaryMobile === this.prevPrimaryMobile && this.secondaryMobile === this.prevSecondaryMobile && this.homePhone === this.prevHomePhone && this.workPhone === this.prevWorkPhone);
  }
}
