import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState } from "../store/app.store";
import { ContractAccountDetailDTO, ContractAccountDetailDTOContractType, EmailAddressDTO, ServicingPreferenceDTOPreferenceStatus, ServicingPreferenceDTO, ServicingPreferenceDTOPreferenceCode, BookBillAndPayExtensionRequest, BookBillAndPayExtensionRequestSystem, BookBillAndPayExtensionRequestChannel, BookBillAndPayExtensionRequestPaymentFeeType, BookBillAndPayExtensionRequestPaymentSource, GetInformationAndSaveExtensionRequest, GetInformationAndSaveExtensionRequestSystem, GetInformationAndSaveExtensionRequestChannel, BookAndBillExtensionRequest, PaymentAccountDTO, ContactDTO, Financialstatus } from "../../core/gateway-api";
import { AccountInfoService } from './account-info.service';
import { Constants } from '../constants';
import { FeatureIndicatorService } from '../../_web-services/feature-indicator.service';
import { FeatureIndicatorNames } from '../enums';
import { LeaseExtensionEligbility } from '../../_models/lease-extension/lease-extension-eligibility.model';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { LeaseExtensionMonths, LeaseExtReviewDetails } from '../../_models/lease-extension.model';
import { CustomerACHService } from '../../_web-services/customer-ach.service';
import * as _ from 'lodash-es';
import { ObjectService } from './object.service';
import { FinancialAccounts } from '../../_models/payment-source.model';
import { ApplicationConfig } from '../../_models/application-config';

@Injectable()
export class LeaseExtensionHelperService {
  MakeaPaymentTxt: string;
  MakeaPaymentLink: string;
  storeAppConfig: ApplicationConfig;
  constructor(private store: Store<IAppState>,
    private accountInfoService: AccountInfoService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private customerACHService: CustomerACHService,
    private featureIndicatorService: FeatureIndicatorService,
    private objectService: ObjectService,) {

    this.translateService.get("ngw.general.make-a-payment").subscribe((res: string) => {
      this.MakeaPaymentTxt = res;
    });
  }

  public showLeaseExtension(contract: ContractAccountDetailDTO): boolean {
    let self = this;
    return self.showProgressBarLeaseExtensionLink(contract);
  }

  public showProgressBarLeaseExtensionLink(contract: ContractAccountDetailDTO): boolean {
    let extendLeaseStatus: boolean = false;
    extendLeaseStatus = this.accountInfoService.isEligibleForLeaseExtn(contract)
    return extendLeaseStatus;
  }

  public isLeaseExtensionEnable(): Observable<boolean> {
    return this.featureIndicatorService.getFeatureIndicatorByClientId(FeatureIndicatorNames.ExtendLeaseContract);
  }

  public showHelpCenterLeaseExtensionLink(contract: ContractAccountDetailDTO): boolean {
    let extendLeaseStatus: boolean = false;
    if (contract && contract.currentContractEndDate) {
      if (contract.currentContractEndDate <= new Date()) {
        extendLeaseStatus = false;
      } else if (contract.currentContractEndDate > new Date()) {
        extendLeaseStatus = true;
      }
    }
    return extendLeaseStatus;
  }

  public hideHelpCenterLeaseExtensionLink(contract: ContractAccountDetailDTO): boolean {
    if (this.accountInfoService.isTerminatedAccount(contract.statusCategoryCode)) {
      return false;
    }
    else if (contract.originalContractEndDate > new Date()) {
      return false;
    }
    return true;
  }

  public isAnyOutstandingBalance(data: any): LeaseExtensionEligbility {
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    leaseExtensionEligbility.isEligible = (data.contract.totalAmountDue == undefined || data.contract.totalAmountDue <= 0) ? true : false;
    let eligibilityDescription: string;

    if (!leaseExtensionEligbility.isEligible) {
      eligibilityDescription = "ngw.shared.extend-lease-contract-dialog.extend-lease-contract-criteria.outstanding-balance"
      leaseExtensionEligbility.showMakeAPayment = true;
      this.translateService.get(eligibilityDescription).subscribe((res: string) => {
        leaseExtensionEligbility.description = res;
      });
    }

    return leaseExtensionEligbility;
  }

  public isAnyOutstandingBalanceCan(data: any): LeaseExtensionEligbility {
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    leaseExtensionEligbility.isEligible = (data.contract.totalAmountDue == undefined || data.contract.totalAmountDue <= 0) ? true : false;
    let eligibilityDescription: string;

    if (!leaseExtensionEligbility.isEligible) {
      if (this.accountInfoService.isPastDue(data.contract.pastdueAmount) || this.accountInfoService.showOtherPayment(data.contract)) {
        eligibilityDescription = "ngw.shared.extend-lease-contract-dialog.extend-lease-contract-criteria.outstanding-balance"
        leaseExtensionEligbility.showMakeAPayment = true;
        this.translateService.get(eligibilityDescription).subscribe((res: string) => {
          leaseExtensionEligbility.description = res;
        });
      }
      else {
        leaseExtensionEligbility.showMakeAPayment = false;
      }

    }

    return leaseExtensionEligbility;
  }

  public isExtendedForTwoMonths(data: any): LeaseExtensionEligbility {
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    let numberOfMonthsExtended: any = data.contract.currentTerm - data.contract.originalTerm;
    leaseExtensionEligbility.isEligible = numberOfMonthsExtended >= Constants.AllowedMonthsToExtend ? false : true;

    return leaseExtensionEligbility;
  }

  public isExtendedForMaxAllowedMonths(data: any): LeaseExtensionEligbility {
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    let numberOfMonthsExtended = this.monthsDiff(new Date(data.contract.originalContractEndDate), new Date(data.contract.currentContractEndDate))
    leaseExtensionEligbility.isEligible = numberOfMonthsExtended >= Constants.MaxMonthsToExtend ? false : true;

    return leaseExtensionEligbility;
  }
  public isValidMaturityDate(data: any): LeaseExtensionEligbility {
    let daysToExtendLease: number;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    daysToExtendLease = this.storeAppConfig.DAYS_FOR_LEASE_EXTENSION;
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    leaseExtensionEligbility.isEligible = (new Date() <= data.contract.currentContractEndDate && this.noOfDaysBetweenDate(data.contract.currentContractEndDate, new Date())) > daysToExtendLease && this.isExtendedForMaxAllowedMonths(data).isEligible ? false : true;
    this.setMaturityDateMessage(leaseExtensionEligbility, data);
    return leaseExtensionEligbility;
  }
  public isValidFurtureMaturityDate(data: any): LeaseExtensionEligbility {
    let daysToExtendLease: number;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    daysToExtendLease = this.storeAppConfig.DAYS_FOR_LEASE_EXTENSION;
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    leaseExtensionEligbility.isEligible = (new Date() <= data.contract.currentContractEndDate && this.noOfDaysBetweenDate(data.contract.currentContractEndDate, new Date())) > daysToExtendLease ? false : true;
    this.setMaturityDateMessage(leaseExtensionEligbility, data);
    return leaseExtensionEligbility;
  }

  private setMaturityDateMessage(leaseExtensionEligbility: LeaseExtensionEligbility, data: any) {
    let eligibilityDescription;
    let date;
    let longDateFormat;
    if (!leaseExtensionEligbility.isEligible) {
      let daysToExtendLease: number;
      this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
      daysToExtendLease = this.storeAppConfig.DAYS_FOR_LEASE_EXTENSION;
      eligibilityDescription = "ngw.shared.extend-lease-contract-dialog.extend-lease-contract-criteria.within-30-days";
      date = new Date(data.contract.currentContractEndDate);
      date.setDate(date.getDate() - daysToExtendLease);
      this.translateService.get("ngw.general.date-long-format").subscribe((res: string) => {
        longDateFormat = res;
      });
      this.translateService.get(eligibilityDescription, { CurrentMaturityDate: this.datePipe.transform(date, longDateFormat) }).subscribe((res: string) => {
        leaseExtensionEligbility.description = res;
      });
    }
  }

  public isPastMaturityDate(data: any): LeaseExtensionEligbility {
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    leaseExtensionEligbility.isEligible = (new Date() >= data.contract.currentContractEndDate && this.noOfDaysBetweenDate(data.contract.currentContractEndDate, new Date())) > 14 ? false : true;

    return leaseExtensionEligbility;
  }

  public otherInEligibilityConditions(data: any, easypaySchedules) {
    let leaseExtensionEligbility = new LeaseExtensionEligbility();
    leaseExtensionEligbility = this.isPastMaturityDate(data);
    if (leaseExtensionEligbility.isEligible)
      leaseExtensionEligbility = this.isExtendedForMaxAllowedMonths(data);
    if (leaseExtensionEligbility.isEligible)
      leaseExtensionEligbility.isEligible = (data.contract.isElvPricingPackage || data.contract.originatingDealerNumber == Constants.ElvDelear || data.contract.originatingDealerNumber == Constants.ElvDelearPricingPkg) ? false : true;
    if (leaseExtensionEligbility.isEligible)
      leaseExtensionEligbility.isEligible = (data.contract.contractType == ContractAccountDetailDTOContractType.PrePayLease || data.contract.contractType == ContractAccountDetailDTOContractType.FlexLease) ? false : true;
    if (leaseExtensionEligbility.isEligible)
      leaseExtensionEligbility.isEligible = (data.contract.outstandingProvisionalCredit > 0) ? false : true;
    if (leaseExtensionEligbility.isEligible)
      leaseExtensionEligbility.isEligible = this.isEasyPayEnrolled(easypaySchedules) ? true : false;
    if (leaseExtensionEligbility.isEligible)
      leaseExtensionEligbility.isEligible = this.accountInfoService.isConditionCodeEligibleForLeaseExt(data.contract) ? true : false;
    if (leaseExtensionEligbility.isEligible)
      leaseExtensionEligbility.isEligible = !data.isLeaseExtOverDue;

    return leaseExtensionEligbility.isEligible;
  }

  public isEasyPayEnrolled(easyPayScheduled: any) {
    let isRecurringPaymentEnrolled: boolean = false;
    if (easyPayScheduled && easyPayScheduled.findEasyPayScheduleResponse && easyPayScheduled.findEasyPayScheduleResponse.easyPaySchedule) {
      isRecurringPaymentEnrolled = true;
    }
    return isRecurringPaymentEnrolled;
  }

  public isEnrolledInDriveProgram(data: any) {
    return data.customerIntent != undefined && data.customerIntent.isDriveProgram;
  }

  private noOfDaysBetweenDate(pastDate: Date, currentDate: Date): any {
    var timeDifference = Math.abs(currentDate.getTime() - pastDate.getTime());
    return Math.ceil(timeDifference / (Constants.OneThousandDays * Constants.ThirtySixHundredDays * Constants.TwentyFourDays));
  }

  public getTotalMiles(contract: ContractAccountDetailDTO): number {
    let additionalPurchasedMiles = contract.additionalPurchasedMiles
      ? contract.additionalPurchasedMiles
      : 0;

    let totalMiles = contract.milesAtInception + additionalPurchasedMiles + (contract.annualMileage / 12 * (contract.currentTerm + Constants.OneMonth));

    let roundedTotalMiles = totalMiles ? Math.round(totalMiles) : 0;
    return roundedTotalMiles;
  }

  public getTotalKilometers(contract: ContractAccountDetailDTO, extendedMonths: number): number {
    let additionalPurchasedMiles = contract.additionalPurchasedMiles
      ? contract.additionalPurchasedMiles
      : 0;

    let totalMiles = contract.milesAtInception + additionalPurchasedMiles + (contract.annualMileage / 12 * (contract.currentTerm + extendedMonths));

    let roundedTotalMiles = Math.round(totalMiles);
    return roundedTotalMiles;
  }

  public getAdditionalExtensionMiles(contract: ContractAccountDetailDTO): number {
    let additonalExtensionMiles = contract.annualMileage / 12;
    return additonalExtensionMiles ? Math.round(additonalExtensionMiles) : 0;
  }

  public newContractEndDate(contractEndDate, originalEndDate) {

    let originalContractDay = new Date(originalEndDate).getDate();
    let currentContractEndDateMonth = new Date(contractEndDate).getMonth() + Constants.OneMonth;
    let currentContractEndDateYear = new Date(contractEndDate).getFullYear();

    if ((currentContractEndDateMonth + Constants.OneMonth) === Constants.NumberThirteen) {
      currentContractEndDateMonth = Constants.NumberZero;
      currentContractEndDateYear++;
    }
    // Create the date using Original Day, Current Month and Year.
    let newContractEndDate = new Date(currentContractEndDateYear + Constants.ForwardSlash + (currentContractEndDateMonth + Constants.OneMonth) + Constants.ForwardSlash + originalContractDay);

    // Get the Month of possible contract end date
    let possibleContractEndDateMonth = newContractEndDate.getMonth() + Constants.OneMonth;

    if (currentContractEndDateMonth + Constants.OneMonth == possibleContractEndDateMonth) {
      return newContractEndDate;
    }
    else {
      return new Date(newContractEndDate.getFullYear(), newContractEndDate.getMonth(), Constants.NumberZero);
    }
  }
  public getPrimaryEmailAddress(): string {
    let primaryEmailAddress: EmailAddressDTO;
    let contact: ContactDTO;
    this.store.select(state => state.Contact).subscribe(x => contact = x);
    let emailAddresses: EmailAddressDTO[];
    if (contact && contact.emailAddresses) {
      emailAddresses = contact.emailAddresses;
      primaryEmailAddress = _.find(emailAddresses, function (email: EmailAddressDTO) {
        return email.isPrimary;
      });
    }
    return primaryEmailAddress ? primaryEmailAddress.emailAddress : null;
  }

  public setEmailPrefLeaseExtReviewDetails(accountPreferences, leaseExtReviewDetails: LeaseExtReviewDetails) {
    if (accountPreferences != null) {
      let preference = _.find(accountPreferences, function (pref: ServicingPreferenceDTO) {
        return pref.preferenceCode == ServicingPreferenceDTOPreferenceCode.PaymentConfirmation;
      });
      if (preference != null && preference.preferenceStatus == ServicingPreferenceDTOPreferenceStatus.On) {
        leaseExtReviewDetails.paymentConfEmailPreference = true;
        leaseExtReviewDetails.primaryEmailAddress = this.getPrimaryEmailAddress();
      }
    }

  }
  public getBookBillAndPayExtensionRequest(leaseExtReviewDetails: LeaseExtReviewDetails, paymentMode: string): BookBillAndPayExtensionRequest {
    let getBookBillAndPayExtensionRequest = new BookBillAndPayExtensionRequest();

    getBookBillAndPayExtensionRequest.accountNumber = leaseExtReviewDetails.accountno;
    getBookBillAndPayExtensionRequest.extensionQuoteId = leaseExtReviewDetails.extensionQuoteId;
    getBookBillAndPayExtensionRequest.system = BookBillAndPayExtensionRequestSystem.CanadaOwnersCircle;
    getBookBillAndPayExtensionRequest.channel = BookBillAndPayExtensionRequestChannel.Web;
    getBookBillAndPayExtensionRequest.originalPaymentAmount = leaseExtReviewDetails.monthlyAmountDue;
    getBookBillAndPayExtensionRequest.paymentFeeType = BookBillAndPayExtensionRequestPaymentFeeType.NoFeeApplicable;
    getBookBillAndPayExtensionRequest.financialAccountId = leaseExtReviewDetails.financialAccountId;
    getBookBillAndPayExtensionRequest.paymentSource = BookBillAndPayExtensionRequestPaymentSource.UltimatePay;
    getBookBillAndPayExtensionRequest.lumpSumAmount = 0;
    getBookBillAndPayExtensionRequest.paymentNote = Constants.LeaseExtensionPaymentNote;
    getBookBillAndPayExtensionRequest.paymentDate = leaseExtReviewDetails.extendatedDates[0].paymentDate;
    getBookBillAndPayExtensionRequest.shouldSendEmail = leaseExtReviewDetails.paymentConfEmailPreference ? true : false;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    getBookBillAndPayExtensionRequest.siteId = this.storeAppConfig.SITEID.toString();
    getBookBillAndPayExtensionRequest.userId = this.storeAppConfig.USER_ID;
    getBookBillAndPayExtensionRequest.customerNumber = leaseExtReviewDetails.customerno;
    getBookBillAndPayExtensionRequest.emailAddress = leaseExtReviewDetails.primaryEmailAddress;
    getBookBillAndPayExtensionRequest.isThirdPartyMakingPayment = false;
    getBookBillAndPayExtensionRequest.paymentMode = paymentMode;

    return getBookBillAndPayExtensionRequest;
  }

  public getInformationAndSaveExtensionRequest(leaseExtReviewDetails: LeaseExtReviewDetails): GetInformationAndSaveExtensionRequest {
    let getInformationAndSaveExtensionRequest = new GetInformationAndSaveExtensionRequest();
    getInformationAndSaveExtensionRequest.accountNumber = leaseExtReviewDetails.accountno;
    getInformationAndSaveExtensionRequest.extensionMonths = leaseExtReviewDetails.selectedMonthsOption;
    getInformationAndSaveExtensionRequest.system = GetInformationAndSaveExtensionRequestSystem.CanadaOwnersCircle;
    getInformationAndSaveExtensionRequest.channel = GetInformationAndSaveExtensionRequestChannel.Web;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    getInformationAndSaveExtensionRequest.userId = this.storeAppConfig.USER_ID;
    getInformationAndSaveExtensionRequest.currentOdometer = leaseExtReviewDetails.currentOdometer;
    return getInformationAndSaveExtensionRequest;
  }

  public getBookAndBillExtensionRequest(leaseExtReviewDetails: LeaseExtReviewDetails, paymentMode: string) {
    let getBookAndBillExtensionRequest = new BookAndBillExtensionRequest();
    getBookAndBillExtensionRequest.extensionQuoteId = leaseExtReviewDetails.extensionQuoteId;
    getBookAndBillExtensionRequest.accountNumber = leaseExtReviewDetails.accountno;
    getBookAndBillExtensionRequest.paymentMode = paymentMode;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    getBookAndBillExtensionRequest.userId = this.storeAppConfig.USER_ID;
    return getBookAndBillExtensionRequest;
  }
  public getNewContractEndDate(contractEndDate: Date, originalEndDate: Date, newPaymentDate: Date, schedulePaymentDate: Date): any[] {
    let monthDiff = this.monthsDiff(originalEndDate, contractEndDate);
    let leaseExtensionDDL: any[] = [];
    let ddlValues: any;
    let selected: boolean;
    let DDLtext: Date;
    let newPaymentDatesToExtend: Date;
    let allowedMonthstoExtend = Constants.MaxMonthsToExtend - monthDiff;
    let currentContractEndDate = new Date(contractEndDate.toString());
    let scheduleDateToAdd = new Date(schedulePaymentDate.toString());
    let newPaymentDateToCalculate: Date
    if (!newPaymentDate)
      newPaymentDateToCalculate = new Date(scheduleDateToAdd.toString());
    else
      newPaymentDateToCalculate = new Date(newPaymentDate.toString());

    for (var i = 1; i <= allowedMonthstoExtend; i++) {
      DDLtext = this.addMonths(new Date(currentContractEndDate.toString()), i)
      if (i == 1)
        newPaymentDate ? newPaymentDatesToExtend = new Date(newPaymentDateToCalculate.toString()) : newPaymentDatesToExtend = new Date();
      else
        newPaymentDatesToExtend = this.addMonths(new Date(newPaymentDateToCalculate.toString()), i - 1);

      ddlValues = new LeaseExtensionMonths(DDLtext, i, newPaymentDatesToExtend);
      leaseExtensionDDL.push(ddlValues);
    }
    return leaseExtensionDDL;
  }
  private monthsDiff(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    let years = this.yearsDiff(d1, d2);
    let months = (years * Constants.TwelveDays) + (date2.getMonth() - date1.getMonth());
    return months;
  }

  private yearsDiff(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
  }
  private isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0))
      || (year % 400 === 0));
  }

  private getDaysInMonth(year, month) {
    return [31, (this.isLeapYear(year) ? 29 : 28),
      31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  public addMonths(date, value) {
    var calculatedDate = new Date(date),
      dateNo = date.getDate();
    calculatedDate.setDate(1);
    calculatedDate.setMonth(calculatedDate.getMonth() + value);
    var daysInMonth = this.getDaysInMonth(calculatedDate.getFullYear(),
      calculatedDate.getMonth());
    if (dateNo > daysInMonth) {
      calculatedDate.setDate(1);
      calculatedDate.setMonth(calculatedDate.getMonth() + 1);
    }
    else {
      calculatedDate.setDate(dateNo);
    }
    return calculatedDate;
  }

  public getPaymentSourceForLeaseExtension(financialAccounts: FinancialAccounts, easyPaySchedulePaymentAccount: PaymentAccountDTO, nextPaymentDueDate: Date): any {
    let paymentAccount: any;
    if (this.objectService.isEmptyObject(financialAccounts)
      || (financialAccounts && financialAccounts.error)
      || this.objectService.isEmptyObject(financialAccounts.accounts)) {
      return paymentAccount;
    }
    if (nextPaymentDueDate)
      paymentAccount = easyPaySchedulePaymentAccount;
    else {
      let paymentAccounts = _.orderBy(financialAccounts.accounts, ['LastUsedDate'], ['desc']);
      paymentAccount = paymentAccounts[0];
    }
    return paymentAccount;
  }

  handleLeaseExtOverDueDays(value: Financialstatus[], accountNumber: string): boolean {
    let accountData = value.filter(account => account.accountNumber === accountNumber);
    return Object.values(accountData).some(count => count.over30Days || count.over60Days || count.over90Days );
  }
}
