import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';

import { VehicleReturnModel } from '../../../_models/vehicle-return-estimate/vehicle-return.model';

@Injectable()
export class VehicleReturnActions {
  static PUSH_VEHICLE_RETURN: string = 'PUSH_VEHICLE_RETURN';
  static REMOVE_VEHICLE_RETURN: string = 'REMOVE_VEHICLE_RETURN';
  static CLEAR_VEHICLE_RETURN: string = 'CLEAR_VEHICLE_RETURN';

  pushVehicleReturnEstimate(vehicleReturn: VehicleReturnModel): FSA<string, any, null> {
    return {
      type: VehicleReturnActions.PUSH_VEHICLE_RETURN,
      payload: vehicleReturn,
      meta: null
    };
  }

  removeVehicleReturnEstimate(accountNumber: string): FSA<string, any, null> {
    return {
      type: VehicleReturnActions.REMOVE_VEHICLE_RETURN,
      payload: accountNumber,
      meta: null
    };
  }

  clearVehicleReturnEstimate(): FSA<string, any, null> {
    return {
      type: VehicleReturnActions.CLEAR_VEHICLE_RETURN,
      payload: null,
      meta: null
    };
  }
}

@Injectable()
export class VehicleReturnActionsReducers {
  static VehicleReturnActionsReducers: Reducer<VehicleReturnModel[]> = (state: VehicleReturnModel[] = [], action: FSA<string, any, void>) => {
    switch (action.type) {
      case VehicleReturnActions.PUSH_VEHICLE_RETURN:
        return state = [...state, action.payload];
      case VehicleReturnActions.REMOVE_VEHICLE_RETURN:
        return state = state ? state.filter(vehicleReturn => vehicleReturn.accountNumber !== action.payload) : [];
      case VehicleReturnActions.CLEAR_VEHICLE_RETURN:
        return state = [];
      default:
        return state;
    }
  }
}
