export class FeesAndCreditDetailsModel {
    feesAndCreditDetails: CreditFeeAndDetailKeyValue[];
    feesAndCreditsTotal?: number;
}

export class CreditFeeAndDetailKeyValue {
    public Key: string;
    public Value: number;
    constructor(Key: string,
        Value: number) {
        this.Key = Key;
        this.Value = Value;
    };
}
