export class OnBoardngModel {
  esignAccepted: boolean;
  termsAccepted: boolean;
  paperLessEnrolled: boolean;
  accountNumber: string;
  moduleName: string;
  profileTermsAccepted:boolean;

  constructor(esignAccepted?: boolean, termsAccepted?: boolean, paperLessEnrolle?: boolean, accountNumber?: string,moduleName?:string, profileTermsAccepted?:boolean) {
    this.esignAccepted = esignAccepted;
    this.termsAccepted = termsAccepted;
    this.paperLessEnrolled = paperLessEnrolle;
    this.accountNumber = accountNumber;
    this.moduleName = moduleName;
    this.profileTermsAccepted = profileTermsAccepted;
  }
}
