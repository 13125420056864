<div #currentMilesTopDiv id="currentMilesTopDiv"></div>
<h3 class="pre-header">{{'ngw.map.entry.current-miles.pre-header' | translate}}</h3>
<h2>{{'ngw.map.entry.current-miles.title' | translate}}</h2>
<div class="error-container">
  <em *ngIf="globalTechError === true" class="error-validation" id="errorMessage" [innerHtml]="errorMessage"></em>
</div>
<form [formGroup]="currentMileageGroup">
  <mat-form-field floatLabel="never">
    <input aria-label="Estimate your vehicle's current mileage" formControlName="currentMileage" matInput  numeric-value placeholder="0" type="text" min="0" class="input-mileage">
    <span matSuffix>mi</span>
  </mat-form-field>
</form>
<p>
  {{'ngw.map.entry.current-miles.accurate-quote' | translate}}
</p>
<p>
  {{'ngw.map.entry.current-miles.skip-step' | translate}}
</p>
<div class="action-buttons">
  <button class="btn btn-secondary" type="button" [mat-dialog-close] analytics-event="body.cancel-map-current-miles.cancel-map-current-miles.button">
    {{'ngw.map.entry.current-miles.btn-cancel' | translate}}</button>
  <button class="btn btn-primary" type="button" [disabled]="isCurrentMilageValid()" (click)="getMileageCalculation()">{{'ngw.map.entry.current-miles.btn-next' | translate}}</button>
</div>
