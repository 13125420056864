import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class IsInPaymentFlowActions
{
  static SET_IS_IN_PAYMENT_FLOW: string = 'SET_IS_IN_PAYMENT_FLOW';

   

  setIsInPaymentFlow(isInPaymentFlow: boolean): FSA<string, boolean, null>{
        return {
          type: IsInPaymentFlowActions.SET_IS_IN_PAYMENT_FLOW,
          payload: isInPaymentFlow,
            meta: null
        };
    }

}

@Injectable()
export class IsInPaymentFlowReducers
{
  static IsInPaymentFlowReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
          case IsInPaymentFlowActions.SET_IS_IN_PAYMENT_FLOW:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
