<form>
  <div class="dialog modal-delete-smcthreads">
    <h1>{{"ngw.shared.discard-message-dialog.title" | translate}}</h1>
    <p>{{"ngw.shared.discard-message-dialog.warning" | translate}}</p>
    <div class="action-buttons">
      <button class="btn btn-secondary" (click)="cancelDiscardMessage(); $event.preventDefault();" type="submit" setFocus>{{"ngw.shared.discard-message-dialog.btn-no" | translate}}</button>
      <button class="btn btn-primary" tabindex="-1" (click)="discardMessage()">{{"ngw.shared.discard-message-dialog.btn-yes" | translate}}</button>
    </div>
  </div>
</form>
