import { ActionReducerMap } from '@ngrx/store';
import { AccountIncentiveModel } from '../../_models/account-incentive.model';
import { AddAchPaymentSourceCanModel } from '../../_models/add-ach-payment-source-can.model';
import { ApplicationConfig } from '../../_models/application-config';
import { BooleanModel } from '../../_models/boolean.model';
import { CoBrandedCreditCardModel } from '../../_models/cobranded-credit-card.model';
import { MaturityBillingModel } from '../../_models/contract/maturity-billing.model';
import { ScheduledItem } from '../../_models/contract/scheduled-item.model';
import { VehicleImageModel } from '../../_models/contract/vehicle-image.model';
import { CustomerBulletinModel } from '../../_models/customer-bulletin.model';
import { DeepLinkParam } from '../../_models/deeplink-param.model';
import { PaymentSourceDeleteReview } from '../../_models/delete-payment-source.model';
import { DueDateChangeEntryModel } from '../../_models/due-date-change/due-date-change-entry.model';
import { DueDateChangeReviewModel } from '../../_models/due-date-change/due-date-change-review.model';
import { EnvironmentConfig } from '../../_models/environment-config';
import { FAQModel } from '../../_models/faq.model';
import { LeaseExtReviewDetails } from '../../_models/lease-extension.model';
import { MapPaymentReviewDetails } from '../../_models/map/map-payment-review-details.model';
import { MidtermMileageAdjustment } from '../../_models/map/midterm-mileage-adjustment.model';
import { SelectMiles } from '../../_models/map/select-miles.model';
import { Autopay } from '../../_models/my-account/autopay';
import { PaperlessPreferenceStatus } from '../../_models/my-account/paperless-statement.model';
import { Payoff } from '../../_models/my-account/payoff.model';
import { StatementCollection } from '../../_models/my-account/statement.model';
import { Offers } from '../../_models/offerModel';
import { CreatePaymentDeferralModel } from '../../_models/payment-deferral/create-payment-deferral.model';
import { PaymentDeferralEligibilityModel } from '../../_models/payment-deferral/payment-deferral-eligibility.model';
import { AccountActivity } from '../../_models/payment-details/account-activity.model';
import { PaymentEntry, PaymentReviewDetails } from '../../_models/payment.model';
import { RecurringPaymentCancel } from '../../_models/recurring-payment/recurring-payment-cancel-model';
import { RecurringPaymentEntry } from '../../_models/recurring-payment/recurring-payment-entry.model';
import { RecurringPaymentHoldsModel } from '../../_models/recurring-payment/recurring-payment-holds.model';
import { SchedulePaymentModel } from '../../_models/recurring-payment/schedule-payment.model';
import { SMCViewThreadModel } from '../../_models/secure-message-center/view-thread.model';
import { StringModel } from '../../_models/string.model';
import { VehicleReturnModel } from '../../_models/vehicle-return-estimate/vehicle-return.model';
import { ViewRepairReceiptModel } from '../../_models/view-repair-receipt.model';
import { ContactDTO, ContractAccountDetailDTO, Financialstatus, MMAQuoteDTO, MessageSubjectClassificationDTO, ServicingPreferenceDTO, VehicleReturnEstimateInspectionDTO, VehicleShadowDTO } from '../../core/gateway-api';
import { Constants } from '../constants';
import { AccountActivitiesReducers } from './reducers/account-activities.reducers';
import { AccountIncentiveReducers } from './reducers/account-incentive.reducer';
import { ActiveContractAccountDetailsReducers } from './reducers/active-contract-account-details.reducers';
import { AddChequingAccountReducers } from './reducers/add-chequing-account.reducer';
import { AdobeAnalyticsReducers } from './reducers/adobe-analytics.reducer';
import { ApplicationConfigReducers } from './reducers/application-config.reducer';
import { AutopaysReducers } from './reducers/autopays.reducers';
import { CanNavigateBackReducers } from './reducers/can-navigate-back.reducer';
import { CanNavigateToConfirmationReducers } from './reducers/can-navigate-to-confirmation-page.reducer';
import { UserActionOnAntoenrollReducers } from './reducers/can-start-dashboard-tour.reducers';
import { CannotAuthenticateReducers } from './reducers/cannot-authenticate.reducer';
import { CardHolderReducers } from './reducers/card-holder.reducer';
import { ConnectedAppSessionIsExpiredReducers } from './reducers/connected-app-session-is-expired.reducer';
import { ContactReducers } from './reducers/contact.reducers';
import { ContractAccountDetailReducers } from './reducers/contract-account-detail.reducers';
import { ContractAccountDetailsReducers } from './reducers/contract-account-details.reducers';
import { ContractsLoadingErrorReducers } from './reducers/contracts-loading-error.reducer';
import { CustomerBulletinReducers } from './reducers/customer-bulletin.reducer';
import { DataLoadedReducers } from './reducers/dataloaded.reducer';
import { DeepLinkParamReducers } from "./reducers/deeplink-param.reducer";
import { DueDateChangeEntryReducers } from './reducers/due-date-change-entry.reducer';
import { DueDateChangeReviewReducers } from './reducers/due-date-change-review.reducer';
import { EasypaySchedulesReducers } from './reducers/easypay-schedules.reducers';
import { EmailIdReducers } from './reducers/email-id.reducer';
import { EnvironmentConfigReducers } from './reducers/environment-config.reducer';
import { EsignAgreementCheckedReducers } from './reducers/esign-agreement-checked.reducer';
import { FeatureIndicatorReducers } from './reducers/feature-indicator.reducers';
import { GlobalErrorReducers } from './reducers/global-error-message.reducer';
import { HasLoggedInReducers } from './reducers/has-logged-in.reducer';
import { HelpServiceReducers } from './reducers/help.service.reducers';
import { InitiationIdReducers } from "./reducers/initiation-id.reducer";
import { IsDarkThemeReducers } from './reducers/is-dark-theme.reducer';
import { IsEpassInitializedReducers } from './reducers/is-epaas-initialized.reducer';
import { IsInMAPFlowReducers } from './reducers/is-in-map-flow.reducer';
import { IsInPaymentFlowReducers } from './reducers/is-in-payment-flow.reducer';
import { IsPaymentSourceRetainedReducers } from './reducers/is-payment-source-retained.reducer';
import { LanguageReducers } from './reducers/language.reducer';
import { LeaseExtReviewDetailsReducers } from './reducers/leaseext-review-details.reducer';
import { MapPaymentReviewDetailsReducers } from './reducers/map-payment-review-details.reducer';
import { MapQuoteSavedReducers } from './reducers/map-quote-saved.reducer';
import { MapQuoteReducers, MapQuotesReducers } from './reducers/map-quotes.reducers';
import { MaturityBillingReducers } from './reducers/maturity-billing.reducer';
import { MidtermMileageAdjustmentReducers } from './reducers/midterm-mileage-adjustment.reducers';
import { OffersReducers } from './reducers/offers.reducers';
import { PageTitleReducers } from './reducers/page-title.reducer';
import { PaperlessPreferenceStatusReducers } from './reducers/paperless-statement.reducers';
import { PaperlessToggleReducers } from './reducers/paperless_toggled.reducer';
import { ParentActivityIdReducers } from './reducers/parent-activity-id.reducer';
import { PaymentDeferralConfirmationReducers } from './reducers/payment-deferral-confirmation.reducer';
import { PaymentDeferralEntryReducers } from './reducers/payment-deferral-entry.reducer ';
import { PaymentEntryReducers } from './reducers/payment-entry.reducer';
import { PaymentReviewDetailsReducers } from './reducers/payment-review-details.reducer';
import { PaymentSourceDeleteReviewReducers } from './reducers/payment-source.reducers';
import { PayoffsReducers } from './reducers/payoffs.reducers';
import { PopStateChangeReducers } from './reducers/popup-state-change.reducer';
import { PreferencesReducers } from './reducers/preferences.reducers';
import { PreviousUrlReducers } from './reducers/previous-url.reducer';
import { ProfileAgreementCheckedReducers } from './reducers/profile-agreement-checked.reducer';
import { CancelRecurringPaymentReducers } from './reducers/recurring-payment-cancel.reducer';
import { RecurringPaymentHoldsReducers } from './reducers/recurring-payment-holds.reducer';
import { RecurringPaymentEntryReducers } from './reducers/recurring-payment.reducer';
import { RepairReceiptsReducers } from './reducers/repair-receipts.reducer';
import { ScheduledItemsReducers } from './reducers/scheduled-items.reducers';
import { SelectMilesReducers } from './reducers/select-miles.reducers';
import { SelectedContractDashboardReducers } from './reducers/selected-contract-dashboard.reducers';
import { ShowSpinnerReducers } from './reducers/show-spinner.reducers';
import { SMCPostCreateDisplayReducers } from "./reducers/smc-post-create-display.reducer";
import { UserTokenReducers } from './reducers/smc-user-token.reducer';
import { ViewThreadReducers } from './reducers/smc-view-thread.reducers';
import { SortedActiveContractAccountDetailsReducers } from './reducers/sorted-active-contract-account-details.reducers';
import { SortedTerminatedContractAccountDetailsReducers } from './reducers/sorted-terminated-contract-account-details.reducers';
import { StatementCollectionReducers } from './reducers/statement-collection.reducers';
import { StatesReducers } from './reducers/states.reducers';
import { SubjectTopicsReducers } from './reducers/subject-topics.reducers';
import { TermsAgreementCheckedReducers } from './reducers/terms-agreement-checked.reducer';
import { ToastReducers } from './reducers/toast.reducer';
import { UpcomingEasyPaySchedulesReducers } from './reducers/upcoming-easy-pay-schedules.reducer';
import { UserActionReducers } from './reducers/user-action.reducer';
import { UserActivityIdReducers } from './reducers/user-activityId.reducer';
import { UserInactivityDialogCloseReducers } from './reducers/user-inactivity-dialog-close.reducers';
import { UserIsTimedOutReducers } from './reducers/user-is-timed-out.reducer';
import { VehicleImageReducers } from './reducers/vehicle-image.reducers';
import { VehicleReturnInspectionActionsReducers } from './reducers/vehicle-inspection.reducer';
import { VehicleReturnActionsReducers } from './reducers/vehicle-return-estimate.reducer';
import { VehicleShadowDataActionsReducers } from './reducers/vehicle-shadow-data.reducer';
import { V2DueDateChangeReducer } from './reducers/v2-due-date-change.reducer';
import { LeaseExtDaysCountReducers } from './reducers/lease-extension.reducer';
export interface IAppState {
  Faq?: FAQModel;
  StatementCollection?: StatementCollection;
  DataLoaded?: BooleanModel;
  showSpinner?:BooleanModel;
  PaperlessToggle?: BooleanModel;
  UserIsTimedOut?: BooleanModel;
  offers?:Offers;
  ContractAccountDetails?: ContractAccountDetailDTO[];
  ActiveContractAccountDetails?: ContractAccountDetailDTO[];
  SortedActiveContractAccountDetails?: ContractAccountDetailDTO[];
  SortedTerminatedContractAccountDetails?: ContractAccountDetailDTO[];
  ScheduledItems?: ScheduledItem[];
  Payoffs?: Payoff[];
  Autopays?: Autopay[];
  VehicleReturn?: VehicleReturnModel[];
  EasypaySchedules?: RecurringPaymentEntry[];
  IsInPaymentFlow?: BooleanModel;
  IsInMAPFlow?: BooleanModel;
  CanNavigateBack?: BooleanModel;
  EmailId?: StringModel;
  PreviousUrl?: StringModel;
  GlobalError?: StringModel;
  ParentActivityId?: StringModel;
  UserActivityId?: StringModel;
  PaymentReviewDetails?: PaymentReviewDetails;
  LeaseExtReviewDetails?: LeaseExtReviewDetails;
  AddChequingAccount?: AddAchPaymentSourceCanModel;
  PaymentEntry?: PaymentEntry;
  ApplicationConfig?: ApplicationConfig;
  EnvironmentConfig?: EnvironmentConfig;
  CanNavigateToConfirmation?: BooleanModel;
  Contact?: ContactDTO;
  ContractAccountDetail?: ContractAccountDetailDTO;
  SelectMiles?: SelectMiles;
  MidtermMileageAdjustment?: MidtermMileageAdjustment;
  PaymentSourceDeleteReview?: PaymentSourceDeleteReview;
  RecurringPaymentCancel?: RecurringPaymentCancel;
  RecurringPaymentEntry?: RecurringPaymentEntry;
  InitiationId?: StringModel;
  MessageCenterUserToken?: StringModel;
  ViewSMCThread?: SMCViewThreadModel;
  SubjectTopics?: MessageSubjectClassificationDTO[];
  UserAction?: StringModel;
  UserActionOnAntoenroll?: BooleanModel;
  Toast?: StringModel;
  AccountActivities?: AccountActivity[];
  MaturityBillings?: MaturityBillingModel[];
  CardHolder?: CoBrandedCreditCardModel;
  States?: string[];
  SMCPostCreateDisplay?: StringModel;
  DeepLinkParam?: DeepLinkParam;
  VehicleImages?: VehicleImageModel[];
  DueDateChangeReview?: DueDateChangeReviewModel;
  DueDateChangeEntry?: DueDateChangeEntryModel;
  ConnectedAppSessionIsExpired?: BooleanModel;
  Preferences?: ServicingPreferenceDTO[];
  CustomerBulletin?: CustomerBulletinModel;
  AccountIncentive?: AccountIncentiveModel;
  ContractsLoadingError?: BooleanModel;
  CustomerPaperlessPreferenceStatus?: PaperlessPreferenceStatus;
  PopStateChange?: BooleanModel;
  MapQuoteSaved?: BooleanModel;
  MapQuotes?: MMAQuoteDTO[];
  MapQuote?: MMAQuoteDTO;
  MapPaymentReviewDetails?: MapPaymentReviewDetails;
  TermsAgreementChecked?: BooleanModel;
  EsignAgreementChecked?: BooleanModel;
  ProfileAgreementChecked?: BooleanModel;
  SelectedContractDashboard?: ContractAccountDetailDTO;
  PaymentDeferralEntry?: PaymentDeferralEligibilityModel;
  PaymentDeferralConfirmation?: CreatePaymentDeferralModel;
  VehicleInspection?: VehicleReturnEstimateInspectionDTO;
  VehicleShadow?: VehicleShadowDTO[];
  RepairReceipts?: ViewRepairReceiptModel[];
  HasLoggedIn?: BooleanModel
  RecurringPaymentHolds?: RecurringPaymentHoldsModel[];
  UpcomingEasyPaySchedules?: SchedulePaymentModel[];
  MultiFactorAuthentication: BooleanModel;
  Recaptcha: BooleanModel;
  OffersEnabled:BooleanModel;
  UserInactivityDialogClose: BooleanModel;
  PageTitle: StringModel;
  IsPaymentSourceRetained: BooleanModel;
  IsDarkTheme: BooleanModel;
  CoBrandedCreditCardEnabled: BooleanModel;
  EPaasEnabled: BooleanModel;
  AdobeAnalyticsEnabled: BooleanModel;
  IsEpassInitialized:BooleanModel;
  PayOffPaymentIsEligible:BooleanModel;
  CannotAuthenticate: BooleanModel;
  Language: StringModel;
  V2Enabled: BooleanModel;
  V2PayoffEnabled: BooleanModel;
  V2MakePaymentEnabled: BooleanModel;
  V2Statements: BooleanModel;
  V2EasyPay: BooleanModel;
  V2DueDateChange: BooleanModel;
  V2PaymentDetails: BooleanModel;
  V2LeaseExtension: BooleanModel;
  V2PaymentOverview: BooleanModel;
  EndOfTerm: BooleanModel;
  V2MidtermMileage: BooleanModel;
  LeasePastDueDaysCount?: Financialstatus[];
}

export const INITIAL_STATE: IAppState = {
  DataLoaded: new BooleanModel(true),
  showSpinner:new BooleanModel(false),
  offers:new Offers(),
  PaperlessToggle: new BooleanModel(true),
  UserIsTimedOut: new BooleanModel(false),
  ConnectedAppSessionIsExpired: new BooleanModel(false),
  ContractAccountDetails: [],
  ActiveContractAccountDetails: [],
  SortedActiveContractAccountDetails: [],
  SortedTerminatedContractAccountDetails: [],
  ScheduledItems: [],
  Payoffs: [],
  Autopays: [],
  VehicleReturn:[],
  EasypaySchedules: [],
  StatementCollection: new StatementCollection(),
  PaymentEntry: null,
  ApplicationConfig: null,
  EnvironmentConfig: null,
  CanNavigateToConfirmation: new BooleanModel(false),
  ParentActivityId: new StringModel(''),
  UserActivityId: new StringModel(''),
  RecurringPaymentEntry: null,
  MessageCenterUserToken: null,
  ViewSMCThread: null,
  UserAction: new StringModel(undefined),
  UserActionOnAntoenroll: new BooleanModel(false),
  Toast: new StringModel(undefined),
  AccountActivities: [],
  MaturityBillings: [],
  CardHolder: null,
  States: Constants.States,
  DeepLinkParam: null,
  VehicleImages: [],
  DueDateChangeReview: null,
  DueDateChangeEntry: null,
  CustomerBulletin: null,
  AccountIncentive: null,
  Preferences: [],
  ContractsLoadingError: new BooleanModel(false),
  CustomerPaperlessPreferenceStatus: new PaperlessPreferenceStatus(),
  PopStateChange: new BooleanModel(false),
  MapQuoteSaved: new BooleanModel(false),
  SelectMiles: new SelectMiles(),
  MapQuotes: [],
  MapQuote: new MMAQuoteDTO(),
  MapPaymentReviewDetails: new MapPaymentReviewDetails(),
  MidtermMileageAdjustment: new MidtermMileageAdjustment(),
  TermsAgreementChecked: new BooleanModel(false),
  EsignAgreementChecked: new BooleanModel(false),
  ProfileAgreementChecked: new BooleanModel(false),
  SelectedContractDashboard: null,
  PaymentDeferralEntry: null,
  PaymentDeferralConfirmation: null,
  VehicleInspection: new VehicleReturnEstimateInspectionDTO(),
  VehicleShadow: [],
  RepairReceipts: [],
  HasLoggedIn: new BooleanModel(false),
  UpcomingEasyPaySchedules: [],
  MultiFactorAuthentication: new BooleanModel(false),
  Recaptcha: new BooleanModel(false),
  OffersEnabled:new BooleanModel(null),
  UserInactivityDialogClose: new BooleanModel(false),
  PageTitle: new StringModel(''),
  IsPaymentSourceRetained:new BooleanModel(false),
  IsDarkTheme: new BooleanModel(false),
  CoBrandedCreditCardEnabled: new BooleanModel(false),
  IsEpassInitialized:new BooleanModel(false), 
  PayOffPaymentIsEligible:new BooleanModel(false),
  EPaasEnabled: new BooleanModel(false),
  AdobeAnalyticsEnabled: new BooleanModel(false),
  CannotAuthenticate: new BooleanModel(false),
  Language: new StringModel(''),
  V2Enabled: new BooleanModel(true),
  V2PayoffEnabled: new BooleanModel(false),
  V2MakePaymentEnabled: new BooleanModel(false),
  V2Statements: new BooleanModel(true),
  V2EasyPay: new BooleanModel(true),
  V2DueDateChange: new BooleanModel(true),
  V2PaymentDetails: new BooleanModel(true),
  V2LeaseExtension: new BooleanModel(true),
  V2PaymentOverview: undefined,
  EndOfTerm: new BooleanModel(true),
  V2MidtermMileage: new BooleanModel(true),
  LeasePastDueDaysCount: []
};

export const rootReducer : ActionReducerMap<IAppState> ={
  Language: LanguageReducers.LanguageReducer,
  offers:OffersReducers.OffersReducer,
  Faq: HelpServiceReducers.HelpServiceReducer,
  DataLoaded: DataLoadedReducers.DataLoadedReducer,
  showSpinner:ShowSpinnerReducers.ShowSpinnerReducer,
  PaperlessToggle: PaperlessToggleReducers.PaperlessToggleReducers,
  UserIsTimedOut: UserIsTimedOutReducers.UserIsTimedOutReducer,
  ContractAccountDetails: ContractAccountDetailsReducers.ContractAccountDetailsReducer,
  ActiveContractAccountDetails: ActiveContractAccountDetailsReducers.ActiveContractAccountDetailsReducer,
  SortedActiveContractAccountDetails: SortedActiveContractAccountDetailsReducers.SortedActiveContractAccountDetailsReducers,
  SortedTerminatedContractAccountDetails: SortedTerminatedContractAccountDetailsReducers.SortedTerminatedContractAccountDetailsReducers,
  ScheduledItems: ScheduledItemsReducers.ScheduledItemsReducer,
  Payoffs: PayoffsReducers.PayoffsReducer,
  Autopays: AutopaysReducers.AutopaysReducer,
  StatementCollection: StatementCollectionReducers.StatementCollectionReducer,
  IsInPaymentFlow: IsInPaymentFlowReducers.IsInPaymentFlowReducer,
  IsInMAPFlow: IsInMAPFlowReducers.IsInMAPFlowReducer,
  CanNavigateBack: CanNavigateBackReducers.CanNavigateBackReducer,
  EmailId: EmailIdReducers.EmailIdReducer,
  PreviousUrl: PreviousUrlReducers.PreviousUrlReducer,
  GlobalError: GlobalErrorReducers.GlobalErrorReducer,
  ParentActivityId: ParentActivityIdReducers.ParentActivityIdReducer,
  UserActivityId: UserActivityIdReducers.UserActivityIdReducers,
  PaymentReviewDetails: PaymentReviewDetailsReducers.PaymentReviewDetailsReducer,
  LeaseExtReviewDetails: LeaseExtReviewDetailsReducers.LeaseExtReviewDetailsReducer,
  AddChequingAccount: AddChequingAccountReducers.AddChequingAccountReducers,
  PaymentEntry: PaymentEntryReducers.PaymentEntryReducer,
  ApplicationConfig: ApplicationConfigReducers.ApplicationConfigReducer,
  EnvironmentConfig: EnvironmentConfigReducers.EnvironmentConfigReducer,
  Contact: ContactReducers.ContactReducer,
  ContractAccountDetail: ContractAccountDetailReducers.ContractAccountDetailReducer,
  SelectMiles: SelectMilesReducers.SelectMilesReducer,
  MidtermMileageAdjustment: MidtermMileageAdjustmentReducers.MidtermMileageAdjustmentReducer,
  CanNavigateToConfirmation: CanNavigateToConfirmationReducers.CanNavigateToConfirmationReducer,
  PaymentSourceDeleteReview: PaymentSourceDeleteReviewReducers.PaymentSourceDeleteReviewReducers,
  RecurringPaymentEntry: RecurringPaymentEntryReducers.RecurringPaymentEntryReducer,
  RecurringPaymentCancel: CancelRecurringPaymentReducers.CancelRecurringPaymentReducers,
  MessageCenterUserToken: UserTokenReducers.UserTokenReducer,
  SubjectTopics: SubjectTopicsReducers.SubjectTopicsReducer,
  ViewSMCThread: ViewThreadReducers.ViewThreadReducer,
  InitiationId: InitiationIdReducers.InitiationIdReducer,
  UserAction: UserActionReducers.UserActionReducer,
  UserActionOnAntoenroll: UserActionOnAntoenrollReducers.UserActionOnAntoenrollReducers,
  Toast: ToastReducers.ToastReducer,
  AccountActivities: AccountActivitiesReducers.AccountActivitiesReducer,
  MaturityBillings: MaturityBillingReducers.MaturityBillingReducer,
  CardHolder: CardHolderReducers.CardHolderReducer,
  VehicleReturn: VehicleReturnActionsReducers.VehicleReturnActionsReducers,
  States: StatesReducers.StatesReducer,
  SMCPostCreateDisplay: SMCPostCreateDisplayReducers.SMCPostCreateDisplayReducer,
  DeepLinkParam: DeepLinkParamReducers.DeepLinkParamReducer,
  VehicleImages: VehicleImageReducers.VehicleImageReducer,
  DueDateChangeReview: DueDateChangeReviewReducers.DueDateChangeReviewReducer,
  DueDateChangeEntry: DueDateChangeEntryReducers.DueDateChangeEntryReducer,
  PaymentDeferralEntry: PaymentDeferralEntryReducers.PaymentDeferralEntryReducer,
  PaymentDeferralConfirmation: PaymentDeferralConfirmationReducers.PaymentDeferralConfirmationReducer,
  ConnectedAppSessionIsExpired: ConnectedAppSessionIsExpiredReducers.ConnectedAppSessionIsExpiredReducer,
  Preferences: PreferencesReducers.PreferencesReducer,
  CustomerBulletin: CustomerBulletinReducers.CustomerBulletinReducer,
  AccountIncentive: AccountIncentiveReducers.AccountIncentiveReducer,
  EasypaySchedules: EasypaySchedulesReducers.EasypaySchedulesReducer,
  ContractsLoadingError: ContractsLoadingErrorReducers.ContractsLoadingErrorReducer,
  CustomerPaperlessPreferenceStatus: PaperlessPreferenceStatusReducers.PaperlessPreferenceStatusReducer,
  PopStateChange: PopStateChangeReducers.PopStateChangeReducers,
  MapQuoteSaved: MapQuoteSavedReducers.MapQuoteSavedReducers,
  MapQuotes: MapQuotesReducers.MapQuotesReducer,
  MapQuote: MapQuoteReducers.MapQuoteReducer,
  MapPaymentReviewDetails: MapPaymentReviewDetailsReducers.MapPaymentReviewDetailsReducer,
  TermsAgreementChecked: TermsAgreementCheckedReducers.TermsAgreementCheckedReducer,
  EsignAgreementChecked: EsignAgreementCheckedReducers.EsignAgreementCheckedReducer,
  ProfileAgreementChecked:ProfileAgreementCheckedReducers.ProfileAgreementCheckedReducer,
  SelectedContractDashboard: SelectedContractDashboardReducers.SelectedContractDashboardReducer,
  VehicleInspection: VehicleReturnInspectionActionsReducers.VehicleReturnInspectionActionsReducer,
  VehicleShadow: VehicleShadowDataActionsReducers.VehicleShadowDataActionsReducers,
  RepairReceipts: RepairReceiptsReducers.RepairReceiptsReducer,
  RecurringPaymentHolds: RecurringPaymentHoldsReducers.RecurringPaymentHoldsReducer,
  HasLoggedIn: HasLoggedInReducers.HasLoggedInReducer,
  UpcomingEasyPaySchedules: UpcomingEasyPaySchedulesReducers.UpcomingEasyPaySchedulesReducer,
  MultiFactorAuthentication: FeatureIndicatorReducers.MultiFactorAuthenticationReducer,
  Recaptcha: FeatureIndicatorReducers.RecaptchaReducer,
  OffersEnabled:FeatureIndicatorReducers.OffersEnabledReducer,
  UserInactivityDialogClose: UserInactivityDialogCloseReducers.UserInactivityDialogCloseReducer,
  PageTitle: PageTitleReducers.PageTitleReducer,
  IsPaymentSourceRetained: IsPaymentSourceRetainedReducers.IsPaymentSourceRetainedReducer,
  IsDarkTheme: IsDarkThemeReducers.IsDarkThemeReducer,
  CoBrandedCreditCardEnabled : FeatureIndicatorReducers.CoBrandedCreditCardEnabledReducer,  
  IsEpassInitialized:IsEpassInitializedReducers.IsEpassInitializedReducer,
  EPaasEnabled: FeatureIndicatorReducers.EPaasEnabledReducer,
  AdobeAnalyticsEnabled: AdobeAnalyticsReducers.AdobeAnalyticsEnabledReducer,
  PayOffPaymentIsEligible:FeatureIndicatorReducers.PayOffPaymentReducer,
  CannotAuthenticate: CannotAuthenticateReducers.CannotAuthenticateReducer,
  V2Enabled : FeatureIndicatorReducers.V2EnabledReducer,
  V2PayoffEnabled : FeatureIndicatorReducers.V2PayoffEnabledReducer,
  V2MakePaymentEnabled : FeatureIndicatorReducers.V2MakePaymentEnabledReducer,
  V2Statements : FeatureIndicatorReducers.V2StatementsReducer,
  V2EasyPay : FeatureIndicatorReducers.V2EasyPayReducer,
  V2DueDateChange : V2DueDateChangeReducer.V2DueDateChangeReducer,
  V2PaymentDetails : FeatureIndicatorReducers.V2PaymentDetailsReducer,
  V2LeaseExtension : FeatureIndicatorReducers.V2LeaseExtensionReducer,
  V2PaymentOverview : FeatureIndicatorReducers.V2PaymentOverviewReducer,
  EndOfTerm : FeatureIndicatorReducers.EndOfTermReducer,
  V2MidtermMileage: FeatureIndicatorReducers.V2MidtermMileageEnabledReducer,
  LeasePastDueDaysCount: LeaseExtDaysCountReducers.LeaseExtDaysCountReducer
};

