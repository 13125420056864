import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { UserService } from '../../shared/_helper-services/user.service';
import { Observable } from 'rxjs';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

     if (this.userService.fsTokenExists()) {
          request = request.clone({
            setHeaders: {
              'X-BMW-BEARER-TOKEN':  this.userService.getFSToken()
            }
          });
     }
    
    return next.handle(request);
  }
}
