import { User } from './user.model';

export class ChangePasswordStatus {
  error: boolean;
  errortype: ChangePasswordErrorType;
  currentUser: User;
}

export enum ChangePasswordErrorType {
  UserNotFound,
  AuthenticationFailed,
  UserAccountPasswordLocked,
  InvalidClientId,
  InvalidPassword,
  DataValidation,
  GenericServerError
}
