export class Payoff {
    error: boolean;
    stopSaleFlag: boolean
    fSAccountId: number;
    payoffAmount: number;
    goodThroughDate: Date;
    quoteDate: Date;
    securityDeposit: number;
}

