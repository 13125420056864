<div class="modal-change-contract">
  <a class="btn btn-close" (click)="closeDialog()"></a>
  <h3>{{ title | translate}}</h3>
  <h5 *ngIf="subtitle"> {{ subtitle }}</h5>
  <ul>
    <li *ngFor="let contract of sortedContracts" (click)="selectContract(contract)">
      <app-contract-header [shouldDisableCurrentContract]="disableCurrent" [contractDetail]="contract"></app-contract-header>
    </li>
  </ul>
</div>
