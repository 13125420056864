import { Injectable, Injector } from '@angular/core';
import { IAppState } from '../../store/app.store';
import { Store } from '@ngrx/store';
import { UserService } from '../user.service';
import { LoginService } from '../../../_web-services/login.service';
import { RouterService } from '../router.service';
import { IdleService } from '../idle.service';
import { UserIsTimedOutActions } from '../../store/reducers/user-is-timed-out.reducer';
import { ActivityLogging } from '../../../core/basecomponent/features/logging/logging.factory';
import { LoginStatus } from '../../../_models/login-status.model';
import { Constants } from '../../constants';
import { DataLoadedActions } from '../../store/reducers/dataloaded.reducer';

@Injectable()

export class SsoService {
  public store: Store<IAppState>;
  public errorMessage: string;
  public hasError: boolean;
  private dataLoadedActions: DataLoadedActions;

  constructor(
    private injector:Injector,
    private userService: UserService,
    private loginService: LoginService,
    private routerService: RouterService,
    private userIsTimedOutActions: UserIsTimedOutActions,
    private idleService: IdleService) {
    this.dataLoadedActions = injector.get(DataLoadedActions);
    this.store = injector.get(Store);
  }

  public authenticateUser(state:string,code:string,grantType:string,uri:string,logger:ActivityLogging) {
    //reset session timeout
    this.store.dispatch(this.userIsTimedOutActions.setUserIsTimedOut(false));
    //clear any orphan session
    this.userService.clearSession();
    this.userService.clearOnBoardingSession();
    //reset error
    this.errorMessage = "";
    this.hasError = false;
    this.loginService.ssoLogin(code, grantType, uri)
      .subscribe(loginStatus => { this.postAuthentication(loginStatus, state, grantType,logger); });
  }

  postAuthentication(loginStatus: LoginStatus, state: string, grantType: string, logger: ActivityLogging) {
    if (loginStatus.error == true) {
      this.hasError = true;
      logger.logcritical(["Error occurred in: " + Constants.LoginComponentName + ", loginService.ssoLogin()"]);
      this.userService.clearSession();
      this.userService.clearOnBoardingSession();
      this.routerService.navigateToErrorPage();
    } else {
      this.hasError = false;
      this.store.dispatch(this.dataLoadedActions.setDataLoaded(true));
      let decodedTargetUrl = decodeURIComponent(state);
      this.routerService.navigateToTarget(decodedTargetUrl);
    }
  }
}
