import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IAppState } from "../../../../shared/store/app.store";
import { LeaseExtensionEligbility } from '../../../../_models/lease-extension/lease-extension-eligibility.model';
import { TranslateService } from '@ngx-translate/core';
import { RouterService } from '../../../_helper-services/router.service';
import { Constants } from '../../../constants';
import { LeaseExtensionHelperService } from '../../../_helper-services/lease-extension-helper.service';
import { ContractAccountDetailActions } from "../../../../shared/store/reducers/contract-account-detail.reducers";


@Component({
  selector: 'extend-lease-contract-dialog',
  templateUrl: './extend-lease-contract-dialog.component.html',
  styleUrls: ['./extend-lease-contract-dialog.component.scss']
})

export class ExtendLeaseContractDialog implements OnInit, AfterViewInit {
  public leaseExtensionEligibilities: LeaseExtensionEligbility[]
  isLeaseExtensionEligible: boolean;
  elgibilityDescription: string;
  elgibilityDescriptionNote: string = Constants.EMPTY;
  isEnrolledInDriveProgram: boolean;
  showButton: boolean;
  disableButton: boolean;
  eligibleToExtend: boolean = false;
  extendMoreThanTwoMonths: boolean = false;
  pastmaturityDate: boolean = false;
  outStandingBalance: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<ExtendLeaseContractDialog>,
    private translateService: TranslateService,
    private routerService: RouterService,
    private leaseExtensionHelperService: LeaseExtensionHelperService,
    private routes: RouterService,
    private contractAccountDetailActions: ContractAccountDetailActions,
    private route: RouterService,
    private store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      this.leaseExtensionEligibilities = new Array();
      this.leaseExtensionEligibilities.push(this.leaseExtensionHelperService.isAnyOutstandingBalance(this.data));
      this.leaseExtensionEligibilities.push(this.leaseExtensionHelperService.isExtendedForTwoMonths(this.data));
      this.leaseExtensionEligibilities.push(this.leaseExtensionHelperService.isValidFurtureMaturityDate(this.data));
      this.leaseExtensionEligibilities.push(this.leaseExtensionHelperService.isPastMaturityDate(this.data));

      this.isEnrolledInDriveProgram = this.leaseExtensionHelperService.isEnrolledInDriveProgram(this.data);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.isEligibleToExtend();
      this.extendedMoreThenTwoMonths();
      this.pastMaturityDate();
      this.anyOutStandingBalanceOrValidFurtureMaturityDate();
    }, 0);
  }

  public isEligibleToExtend() {
    this.isLeaseExtensionEligible = this.leaseExtensionEligibilities.filter(item => item.isEligible == false).length > 0 || this.isEnrolledInDriveProgram ? false : true;
    if (this.isLeaseExtensionEligible) {
      this.translateService.get("ngw.shared.extend-lease-contract-dialog.eligible-description-1").subscribe((res: string) => {
        this.elgibilityDescription = res;
      });
      this.showButton = true;
      this.disableButton = false;
      this.eligibleToExtend = true;
    } else {
      this.eligibleToExtend = false;
    }
  }

  public anyOutStandingBalanceOrValidFurtureMaturityDate() {
    if ((this.leaseExtensionEligibilities[0] || this.leaseExtensionEligibilities[2]) && (!this.leaseExtensionEligibilities[0].isEligible || !this.leaseExtensionEligibilities[2].isEligible)) {
      this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-description-2").subscribe((res: string) => {
        this.elgibilityDescription = res;
      });

      this.showButton = true;
      this.disableButton = true;
      this.outStandingBalance = true;
    } else {
      this.outStandingBalance = false;
    }
  }

  public extendedMoreThenTwoMonths() {
    if (this.leaseExtensionEligibilities[1] && !this.leaseExtensionEligibilities[1].isEligible) {
      this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-description-1").subscribe((res: string) => {
        this.elgibilityDescription = res;
      });
      this.translateService.get("ngw.shared.extend-lease-contract-dialog.eligible-description-note").subscribe((res: string) => {
        this.elgibilityDescriptionNote = res;
      });

      this.showButton = false;
      this.disableButton = false;
     this.extendMoreThanTwoMonths = true;
    } else {
      this.extendMoreThanTwoMonths = false;
    }
  }

  public pastMaturityDate() {
    if (this.leaseExtensionEligibilities[3] && !this.leaseExtensionEligibilities[3].isEligible) {
      this.translateService.get("ngw.shared.extend-lease-contract-dialog.not-eligible-description-3").subscribe((res: string) => {
        this.elgibilityDescription = res;
      });
      this.showButton = false;
      this.disableButton = false;
      this.pastmaturityDate = true;
    } else {
      this.pastmaturityDate = false;
    }
  }

  public closeDialogOnly() {
    this.dialogRef.close();
  }

  public navigateToLeasePayment() {
    this.store.dispatch(this.contractAccountDetailActions.setContractAccountDetail(this.data.contract));
    this.dialogRef.close(Constants.LeaseExtensionPayment);
  }

  public navigateToContactUs(): void {
    this.dialogRef.close(Constants.ContactUsComponentName);
  }

  public navigateToMakeAPayment(): void {
    this.store.dispatch(this.contractAccountDetailActions.setContractAccountDetail(this.data.contract));
    this.dialogRef.close(Constants.PaymentEntryComponentName);
  }
}
