import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';

import { tassign } from 'tassign';


@Injectable()
export class UserIsTimedOutActions
{
    static SET_USER_IS_TIMED_OUT: string = 'SET_USER_IS_TIMED_OUT';

    setUserIsTimedOut(userIsTimedOut: boolean): FSA<string, boolean, null>{
        return {
            type: UserIsTimedOutActions.SET_USER_IS_TIMED_OUT,
            payload: userIsTimedOut,
            meta: null
        };
    }

}

@Injectable()
export class UserIsTimedOutReducers
{
    static UserIsTimedOutReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
            case UserIsTimedOutActions.SET_USER_IS_TIMED_OUT:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
