import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class UserActionOnAntoenrollActions {
  static SET_USER_ACTION: string = 'SET_USER_ACTION';
  static GET_USER_ACTION: string = 'GET_USER_ACTION';

  setAutoEnrollAction(flag: boolean): FSA<string, boolean, null> {
    return {
      type: UserActionOnAntoenrollActions.SET_USER_ACTION,
      payload: flag,
      meta: null
    };
  }

  getAutoEnrollStatus(flag: boolean): FSA<string, boolean, null> {
    return {
      type: UserActionOnAntoenrollActions.GET_USER_ACTION,
      payload: flag,
      meta: null
    }
  }

}

@Injectable()
export class UserActionOnAntoenrollReducers {
  static UserActionOnAntoenrollReducers: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case UserActionOnAntoenrollActions.SET_USER_ACTION:
        return tassign(state, new BooleanModel(action.payload));
      case UserActionOnAntoenrollActions.GET_USER_ACTION:
        return state;
      default:
        return state;
    }
  }
}


