import { Component } from '@angular/core';
import { IdleService } from './../../../_helper-services/idle.service';
import { UserService } from './../../../_helper-services/user.service';

@Component({
  selector: 'user-inactivity-dialog-buttons',
  templateUrl: './user-inactivity-dialog-buttons.component.html',
  styleUrls: ['./user-inactivity-dialog-buttons.component.scss']
})

export class UserInactivityDialogButtons {
  public warningMsg: string;
  public title: string;

  constructor(private userService: UserService, private idleService: IdleService) {
  }
  
  public staySignedIn() {
    this.idleService.toggleWarningIdleDialog(false);
    this.idleService.reset();
  }

  public signOut() {
    this.idleService.toggleWarningIdleDialog(false);
    this.userService.logout();
  }


}
