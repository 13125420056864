import { AccountOfferDto } from '../core/gateway-api'

export class Offers{

    offerModel:OfferModel[]=[];
    hasOffer:boolean;
}

export class OfferModel {
    error: boolean;
    accountNumber:string;
    faultType: string;
    offerList?: AccountOfferDto[]
}
