export class ErrorCodes
{
    //PaymentDueDate Error codes  
    public static ValidateDueDate_DueDaysAreEqual: string = "1";
    public static ValidateDueDate_InvalidDueDay: string = "2";
    public static ValidateDueDate_InvalidFinalContractDueDate: string = "3";
    public static ValidateDueDate_BusinessRuleValidationFailed: string = "5";
    public static ValidateDueDate_ExtendedLease: string = "6";
    public static ValidateDueDate_TerminatedStatus: string = "7";
    public static ValidateDueDate_ContractType: string = "8";
    public static ValidateDueDate_ActiveDeferral: string = "9";  
    public static GetDueDate_GetDueDateValidationFailed : string = "1700";
    public static GetAndValidateDueDate_DueDateSemiMonthly : string = "1703";
}
