import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ObjectService } from '../shared/_helper-services/object.service';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from "../shared/store/app.store";
import { BotIntegrationServiceClient, GetAvailableRoutesRequest, GetAvailableRoutesResponse, GetJwTokenRequest, GetJwTokenResponse } from './../core/gateway-api';

@Injectable()
export class BotIntegrationService {

  constructor(private botIntegrationServiceClient: BotIntegrationServiceClient,
    private userService: UserService,
    private store: Store<IAppState>,
    private objectService: ObjectService) {
  }

  public getJwToken(): Observable<GetJwTokenResponse> {
    let request = this.getJwTokenRequest();
    return this.botIntegrationServiceClient.getJwToken(request)
      .pipe(mergeMap((response) => { return this.afterGetJwTokenRequestSuccess(response); })
      ,catchError((error: any) => { return this.afterGetJwTokenRequestFailure(error); }));
  }

  public getAvailableRoutes(): Observable<GetAvailableRoutesResponse> {
    let request = new GetAvailableRoutesRequest();
    request.jwToken = this.userService.getJwToken();
    return this.botIntegrationServiceClient.getAvailableRoutes(request)
      .pipe(mergeMap((response) => { return of(response); })
      ,catchError((error: any) => {
        let response = new GetAvailableRoutesResponse();
        return of(response); }));
  }

  private afterGetJwTokenRequestSuccess(result: GetJwTokenResponse): Observable<GetJwTokenResponse> {
    return of(result);
  }

  private afterGetJwTokenRequestFailure(error: any): Observable<GetJwTokenResponse> {
    let response = new GetJwTokenResponse();
    return of(response);
  }

  private getJwTokenRequest(): GetJwTokenRequest {
    let getJwTokenRequest = new GetJwTokenRequest();
    getJwTokenRequest.gcId = this.userService.getGcid();
    this.store.select(state => state.ApplicationConfig.CLIENT_ID).subscribe(x => getJwTokenRequest.clientId = x);
    return getJwTokenRequest ;
  }

}
