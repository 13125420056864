import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { CustomerDealerDataModel } from '../_models/vehicle-return-estimate/customer-dealer-data.model';
import { CustomerIntentModel } from '../_models/vehicle-return-estimate/customer-intent.model';
import { UpdateCustomerIntentFaultType, UpdateCustomerIntentModel } from '../_models/vehicle-return-estimate/update-customer-intent.model';
import { VehicleReturnErrorType, VehicleReturnModel } from '../_models/vehicle-return-estimate/vehicle-return.model';
import { FaultCodes } from '../shared/FaultCodes';
import { UserService } from '../shared/_helper-services/user.service';
import { VehicleReturnInspectionActions } from '../shared/store/reducers/vehicle-inspection.reducer';
import { VehicleReturnActions } from '../shared/store/reducers/vehicle-return-estimate.reducer';
import { ContractAccountDetailDTO, EndOfTermServiceClient, GetCustomerDealerDataRequest, GetCustomerDealerDataResponse, GetCustomerIntentRequest, GetCustomerIntentResponse, GetInspectionDetailsRequest, GetInspectionDetailsResponse, GetVehicleReturnEstimateRequest, GetVehicleReturnEstimateResponse, UpdateCustomerIntentRequest } from './../core/gateway-api';
import { FSTokenErrorHandler } from './../shared/_errorhandler/gobal-error-handler';
import { IAppState } from "./../shared/store/app.store";


@Injectable()
export class EndOfTermService {

  constructor(private endOfTermServiceClient: EndOfTermServiceClient,
    private fsTokenErrorHandler: FSTokenErrorHandler,
    private vehicleReturnActions: VehicleReturnActions,
    private vehicleReturnInspectionActions: VehicleReturnInspectionActions,
    private store: Store<IAppState>,
    private userService: UserService) {
  }

  private getCachedgetInspectionDetails(accountNumber: string): VehicleReturnModel {
    let vehicleReturn: VehicleReturnModel[];
    this.store.select(state => state.VehicleReturn).subscribe(x => vehicleReturn = x);
    let cachedVehicleReturnModel = _.find(vehicleReturn, function (vehicleReturn) { return vehicleReturn.accountNumber === accountNumber; });
    return cachedVehicleReturnModel;
  }

  public getInspectionDetails(contract?: ContractAccountDetailDTO, refresh?: boolean): Observable<VehicleReturnModel> {
    let getInspectionDetailsRequest = new GetInspectionDetailsRequest();
    let contractAccountDetail: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractAccountDetail = x);
    let currentContract = contract ? contract : contractAccountDetail;
    let cachedVehicleReturnModel = this.getCachedVehicleReturnEstimate(currentContract.accountNumber);

    if (currentContract && (!cachedVehicleReturnModel || refresh)) {
      getInspectionDetailsRequest.accountNumber = currentContract.accountNumber;
      getInspectionDetailsRequest.vIN = currentContract.vIN;
      return this.endOfTermServiceClient.getInspectionDetails(getInspectionDetailsRequest)
        .pipe(mergeMap((response) => { return this.afterGetInspectionDetailsSuccess(response, currentContract.accountNumber); })
          , catchError((error: any) => { return this.afterGetInspectionDetailsFailure(error); }));
    }
    return of(cachedVehicleReturnModel);
  }

  private afterGetInspectionDetailsSuccess(response: GetInspectionDetailsResponse, accountNumber: string): Observable<VehicleReturnModel> {
    this.store.dispatch(this.vehicleReturnActions.removeVehicleReturnEstimate(accountNumber));
    let vehicleReturn = new VehicleReturnModel();

    if (response.vehicleReturnEstimateInspection == undefined
      || response.vehicleReturnEstimateInspection == null
      || (response.vehicleReturnEstimateInspection != undefined
        && response.vehicleReturnEstimateInspection.inspectionTypeId == undefined)
    ) {
      vehicleReturn.error = true;
      vehicleReturn.errorType = VehicleReturnErrorType.NoInspection;
    }
    else {
      vehicleReturn.vehicleReturnEstimate.inspection = response.vehicleReturnEstimateInspection;
      vehicleReturn.error = false;
    }
    vehicleReturn.accountNumber = accountNumber;
    this.store.dispatch(this.vehicleReturnActions.pushVehicleReturnEstimate(vehicleReturn));
    return of(vehicleReturn);
  }

  private afterGetInspectionDetailsFailure(result: any): Observable<VehicleReturnModel> {
    let vehicleReturn = new VehicleReturnModel();
    vehicleReturn.error = true;
    vehicleReturn.errorType = VehicleReturnErrorType.NoInspection;
    this.fsTokenErrorHandler.handleFSTokenError(result);
    return of(vehicleReturn);
  }

  private getCachedVehicleReturnEstimate(accountNumber: string): VehicleReturnModel {
    let vehicleReturn: VehicleReturnModel[];
    this.store.select(state => state.VehicleReturn).subscribe(x => vehicleReturn = x);
    let cachedVehicleReturnModel = _.find(vehicleReturn, function (vehicleReturn) { return vehicleReturn.accountNumber === accountNumber; });
    return cachedVehicleReturnModel;
  }

  public getVehicleReturnEstimate(contract?: ContractAccountDetailDTO, auditNote?: boolean, refresh?: boolean): Observable<VehicleReturnModel> {
    let getVehicleReturnEstimateRequest = new GetVehicleReturnEstimateRequest();
    let contractAccountDetail: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractAccountDetail = x);
    let currentContract = contract ? contract : contractAccountDetail;
    let cachedVehicleReturnModel = this.getCachedVehicleReturnEstimate(currentContract.accountNumber);

    if (currentContract && (!cachedVehicleReturnModel || refresh)) {
      getVehicleReturnEstimateRequest.accountNumber = currentContract.accountNumber;
      getVehicleReturnEstimateRequest.vIN = currentContract.vIN;
      getVehicleReturnEstimateRequest.customerNumber = this.userService.getCustomerNumber();
      getVehicleReturnEstimateRequest.auditNote = (auditNote != undefined) ? auditNote : false;
      return this.endOfTermServiceClient.getVehicleReturnEstimate(getVehicleReturnEstimateRequest)
        .pipe(mergeMap((response) => { return this.afterGetVehicleReturnEstimateSuccess(response, currentContract.accountNumber); })
          , catchError((error: any) => { return this.afterGetVehicleReturnEstimateFailure(error); }));
    }
    return of(cachedVehicleReturnModel);
  }

  public getCustomerDealerData(contract?: ContractAccountDetailDTO): Observable<CustomerDealerDataModel> {
    let getCustomerDealerDataRequest = new GetCustomerDealerDataRequest();
    let contractAccountDetail: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractAccountDetail = x);
    let currentContract = contract ? contract : contractAccountDetail;
    if (currentContract) {
      getCustomerDealerDataRequest.accountNumber = currentContract.accountNumber;
      return this.endOfTermServiceClient.getCustomerDealerData(getCustomerDealerDataRequest)
        .pipe(mergeMap((response) => { return this.afterGetCustomerDealerDataSuccess(response); })
          , catchError((error: any) => { return this.afterGetCustomerDealerDataFailure(error); }));
    }
  }

  public getCustomerIntent(contact: ContractAccountDetailDTO): Observable<CustomerIntentModel> {
    let getCustomerIntentRequest = new GetCustomerIntentRequest();
    getCustomerIntentRequest.accountNumber = contact.accountNumber;
    return this.endOfTermServiceClient.getCustomerIntent(getCustomerIntentRequest)
      .pipe(mergeMap((response) => { return this.afterGetCustomerIntentSuccess(response); })
        , catchError((error: any) => { return this.afterGetCustomerIntentFailure(error); }));

  }

  private afterGetVehicleReturnEstimateSuccess(response: GetVehicleReturnEstimateResponse, accountNumber: string): Observable<VehicleReturnModel> {
    this.store.dispatch(this.vehicleReturnActions.removeVehicleReturnEstimate(accountNumber));
    let vehicleReturn = new VehicleReturnModel();

    // This condition is checked for Accout is terminated but maturity billing is not generated
    if ((response.vehicleReturnEstimate.remainingPaymentsDetails === undefined
      && response.vehicleReturnEstimate.excessWearAndTearDetails == undefined
      && response.vehicleReturnEstimate.excessMileageDetails == undefined)
      && response.vehicleReturnEstimate.inspection != undefined
      && response.vehicleReturnEstimate.inspection != null
      && response.vehicleReturnEstimate.inspection.inspectionTypeId != null) {
      vehicleReturn.error = true;
      vehicleReturn.errorType = VehicleReturnErrorType.NoInspection;
      vehicleReturn.vehicleReturnEstimate = response.vehicleReturnEstimate;
    }
    else if (response.vehicleReturnEstimate.inspection == undefined
      || response.vehicleReturnEstimate.inspection == null
      || (response.vehicleReturnEstimate.inspection != undefined
        && response.vehicleReturnEstimate.inspection.inspectionTypeId == undefined)
    ) {
      vehicleReturn.error = true;
      vehicleReturn.errorType = VehicleReturnErrorType.NoInspection;
    }
    else {
      vehicleReturn.vehicleReturnEstimate = response.vehicleReturnEstimate;
      vehicleReturn.error = false;

    }
    vehicleReturn.accountNumber = accountNumber;
    this.store.dispatch(this.vehicleReturnActions.pushVehicleReturnEstimate(vehicleReturn));
    return of(vehicleReturn);
  }

  private afterGetVehicleReturnEstimateFailure(result: any): Observable<VehicleReturnModel> {
    let vehicleReturn = new VehicleReturnModel();
    vehicleReturn.error = true;
    if (result.faultType && result.faultType == FaultCodes.BMWFSAM_Services_EndOfTerm_VehicleReturnEstimate_V200906_AutoVinInspectionNotFoundFault) {
      vehicleReturn.errorType = VehicleReturnErrorType.NoInspection;
    }
    this.fsTokenErrorHandler.handleFSTokenError(result);
    return of(vehicleReturn);
  }

  private afterGetCustomerDealerDataSuccess(response: GetCustomerDealerDataResponse): Observable<CustomerDealerDataModel> {
    let customerDealerData: CustomerDealerDataModel = new CustomerDealerDataModel();
    customerDealerData.isDsiDealer = response.isDsiDealer;
    customerDealerData.error = false;
    return of(customerDealerData);
  }

  private afterGetCustomerDealerDataFailure(result: any): Observable<CustomerDealerDataModel> {
    let customerDealerData = new CustomerDealerDataModel();
    customerDealerData.error = true;
    this.fsTokenErrorHandler.handleFSTokenError(result);
    return of(customerDealerData);
  }

  private afterGetCustomerIntentSuccess(response: GetCustomerIntentResponse): Observable<CustomerIntentModel> {
    let customerIntentModel: CustomerIntentModel = new CustomerIntentModel();
    customerIntentModel.isInLeaseEnd = response.isLeaseEnd;
    customerIntentModel.isDriveProgram = response.isDriveProgram;
    customerIntentModel.customerIntent = response.customerIntent;
    customerIntentModel.error = false;
    return of(customerIntentModel);
  }

  private afterGetCustomerIntentFailure(result: any): Observable<CustomerIntentModel> {
    let customerIntentModel: CustomerIntentModel = new CustomerIntentModel();
    customerIntentModel.error = true;
    this.fsTokenErrorHandler.handleFSTokenError(result);
    return of(customerIntentModel);
  }

  public updateCustomerIntent(request: UpdateCustomerIntentRequest): Observable<UpdateCustomerIntentModel> {
    return this.endOfTermServiceClient.updateCustomerIntent(request).
      pipe(mergeMap((response) => { return this.afterUpdateCustomerIntentSucess(response); }),
        catchError((error: any) => { return this.afterUpdateCustomerIntentFailure(error); }));
  }

  private afterUpdateCustomerIntentSucess(result: any): Observable<UpdateCustomerIntentModel> {
    let updateCustomerIntentModel = new UpdateCustomerIntentModel(false);
    return of(updateCustomerIntentModel);
  }

  private afterUpdateCustomerIntentFailure(error: any): Observable<UpdateCustomerIntentModel> {
    let errorType: UpdateCustomerIntentFaultType = this.getUpdateCustomerIntentErrorType(error);
    let editAddressModel = new UpdateCustomerIntentModel(true, errorType);
    return of(editAddressModel);
  }

  private getUpdateCustomerIntentErrorType(error: any): UpdateCustomerIntentFaultType {
    let errorType: UpdateCustomerIntentFaultType;
    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_EndOfTerm_VehicleReturnEstimate_V200906_AddCustomerIntentFault: {
          errorType = UpdateCustomerIntentFaultType.AddCustomerIntentFault;
          break;
        }
        default: {
          errorType = UpdateCustomerIntentFaultType.DataValidation;
          break;
        }
      }
    }
    else {
      errorType = UpdateCustomerIntentFaultType.AddCustomerIntentFault;
    }
    return errorType;
  }

}
