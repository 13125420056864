import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { AddAchPaymentSourceCanModel } from "../../../_models/add-ach-payment-source-can.model";


@Injectable()
export class AddChequingAccountActions {
  static SET_ADD_CHEQUING_ACCOUNT: string = 'SET_ADD_CHEQUING_ACCOUNT';
  static CLEAR_ADD_CHEQUING_ACCOUNT: string = 'CLEAR_ADD_CHEQUING_ACCOUNT';

  setAddChequingAccount(addAchPaymentSourceCanModel: AddAchPaymentSourceCanModel): FSA<string, AddAchPaymentSourceCanModel, null> {
    return {
      type: AddChequingAccountActions.SET_ADD_CHEQUING_ACCOUNT,
      payload: addAchPaymentSourceCanModel,
      meta: null
    };
  }

  clearAddChequingAccount(): FSA<string, AddAchPaymentSourceCanModel, null> {
      return {
        type: AddChequingAccountActions.CLEAR_ADD_CHEQUING_ACCOUNT,
        payload: null,
        meta: null
      };
    }

}

@Injectable()
export class AddChequingAccountReducers {
  static AddChequingAccountReducers: Reducer<AddAchPaymentSourceCanModel> = (state: AddAchPaymentSourceCanModel = new AddAchPaymentSourceCanModel(null), action: FSA<string, AddAchPaymentSourceCanModel, void>) => {
    switch (action.type) {
      case AddChequingAccountActions.SET_ADD_CHEQUING_ACCOUNT:
        return tassign(state, action.payload);
      case AddChequingAccountActions.CLEAR_ADD_CHEQUING_ACCOUNT:
         return tassign(undefined);
      default:
        return state;
    }
  }
}
