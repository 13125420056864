<app-spinner></app-spinner>
<div class="edit-phone-numbers dialog" [scrollTop]="scrollToTop">
  <div class="edit-phone-numbers-container">
    <h1 id="editPhoneNumberTitle">{{'ngw.profile.contact-information.edit-phone-numbers.title' | translate}}</h1>
    <div class="agreement">
      {{'ngw.profile.contact-information.edit-phone-numbers.note-agreement' | translate}}
    </div>
    <div class="required-fields">
      {{'ngw.profile.contact-information.edit-phone-numbers.note-required' | translate}}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="noPhoneError === true">
      <p class="error-validation" id="errorMessage">
        {{noPhoneErrorMessage}}
      </p>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="updatePhoneError === true">
      <p class="error-validation" id="errorMessage">
        {{errorMessage}}
      </p>
    </div>
    <form #editPhoneNumberForm="ngForm" autocomplete="off" novalidate>
      <div class="form-group" [ngClass]="{'has-error': showPhoneNumberError(editPhoneNumberForm?.controls.primaryMobile)}">
        <label class="control-label" for="primaryMobile">{{'ngw.profile.contact-information.edit-phone-numbers.lbl-primary-mobile' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="primaryMobile" name="primaryMobile" id="primaryMobile" class="form-control" (change)="formatPhoneNumber($event.target.value, phoneNumberType.Cell)" />
        <em class="error-validation" id="primaryMobileValidationError" *ngIf="showPhoneNumberError(editPhoneNumberForm?.controls.primaryMobile)">{{'ngw.profile.contact-information.edit-phone-numbers.error.phone-number-validation' | translate}}</em>
      </div>
      <div class="form-group" [ngClass]="{'has-error': showPhoneNumberError(editPhoneNumberForm?.controls.secondaryMobile)}">
        <label class="control-label" for="secondaryMobile">{{'ngw.profile.contact-information.edit-phone-numbers.lbl-secondary-mobile' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="secondaryMobile" name="secondaryMobile" id="secondaryMobile" class="form-control" (change)="formatPhoneNumber($event.target.value, phoneNumberType.Cell2)" />
        <em class="error-validation" id="secondaryMobileValidationError" *ngIf="showPhoneNumberError(editPhoneNumberForm?.controls.secondaryMobile)">{{'ngw.profile.contact-information.edit-phone-numbers.error.phone-number-validation' | translate}}</em>
      </div>
      <div class="form-group" [ngClass]="{'has-error': showPhoneNumberError(editPhoneNumberForm?.controls.homePhone)}">
        <label class="control-label" for="homePhone">{{'ngw.profile.contact-information.edit-phone-numbers.lbl-home-phone' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="homePhone" name="homePhone" id="homePhone" class="form-control" (change)="formatPhoneNumber($event.target.value, phoneNumberType.Home)" />
        <em class="error-validation" id="homePhoneValidationError" *ngIf="showPhoneNumberError(editPhoneNumberForm?.controls.homePhone)">{{'ngw.profile.contact-information.edit-phone-numbers.error.phone-number-validation' | translate}}</em>
      </div>
      <div class="form-group" [ngClass]="{'has-error': showPhoneNumberError(editPhoneNumberForm?.controls.workPhone)}">
        <label class="control-label" for="workPhone">{{'ngw.profile.contact-information.edit-phone-numbers.lbl-work-phone' | translate}}</label>
        <input tabindex="-1" [(ngModel)]="workPhone" name="workPhone" id="workPhone" class="form-control" (change)="formatPhoneNumber($event.target.value, phoneNumberType.Work)" />
        <em class="error-validation" id="workPhoneValidationError" *ngIf="showPhoneNumberError(editPhoneNumberForm?.controls.workPhone)">{{'ngw.profile.contact-information.edit-phone-numbers.error.phone-number-validation' | translate}}</em>
      </div>
      <div class="action-buttons">
        <button type="button" class="btn btn-secondary" id="CancelBtn" (click)="cancel()" analytics-event="body.cancel-edit-phone-numbers.cancel-edit-phone-numbers.button">
          {{'ngw.profile.contact-information.edit-phone-numbers.btn-cancel' | translate}}</button>
        <button type="submit" class="btn btn-primary" id="SaveBtn" (click)="save()" [disabled]="formIsInvalid(editPhoneNumberForm)">{{'ngw.profile.contact-information.edit-phone-numbers.btn-save' | translate}}</button>
      </div>
    </form>
  </div>
</div>
