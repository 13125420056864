import { AnalyticsPageDetail } from "../shared/analytics-page-details";

export class ApplicationConfig {
  SITEID: number;
  USER_ID: string;
  POLICY_FORMAT: string;
  POLICY_LANGUAGE: string;
  POLICY_ID: string;
  COOKIES_LINK: string;
  TENANT: string;
  BRAND: string;
  DISABLED_FEATURES: string;
  DISABLED_ROUTES: string[];
  CLIENT_APP_ID: string;
  FAQ_PRESENTATION_TYPE: number;
  FS_ACCOUNT_HELP_PHONE_NUMBER: string;
  FS_ACCOUNT_HELP_NUMBER_COLUMBUS: string;
  LEASE_HELP_PHONE_NUMBER: string;
  CLIENT_ID: string;
  ADOBE_URL: string;
  CUSTOMER_CARD_NUMBER: string;
  COUNTRY_CODE: string;
  LANGUAGE: string;
  MOVEPACK_FEE: number;
  SCRA_LEGAL_ASSISTANCE_LINK: string;
  CONN_APP_IOS_URL: string;
  IS_IN_IOS_MODE: string;
  PROFILE_MENU_ITEM: string;
  FOOTER_MENU_ITEM: string;
  ACCOUNT_DETAILS_MENU_ITEM: string;
  SYSTEM: string;
  IMG_BG_MOBILE_SRC: string;
  SMC_VENDOR_ID: number;
  PAYMENT_DEFERRAL_TYPE: number;
  BMW_SYSTEM_CODE: string;
  MARKET: string;
  PRODUCT: string;
  WEB_USER_ROLE: string;
  ANALYTICS_PAGE_DETAIL_OVERWRITES: AnalyticsPageDetail[];
  ROAD_AHEAD_URL: string;
  UTM_SOURCE: string;
  UTM_MEDIUM: string;
  EPAAS_TENANT_ID: string;
  EPAAS_FEATURE_TYPE: string;
  CHATBOT_LOGO_URL: string;
  CHATBOT_BUBBLE_URL: string;
  PAYOFF_FEATURE_TYPE:string;
  BMW_BUILD_YOUR_OWN_URL: string;
  VEHICLE_RETURN_URL: string;
  PROFILE_TC_POLICY_NAME: string;
  CLIENT!: string;
  CC_DIALOG_COOKIE_EXPIRATION_DAYS!:number;
  CHATBOT_PERSISTENT_DATA:boolean;
  DAYS_FOR_LEASE_EXTENSION: number;
}
