import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../_guard/auth.guard';
import { LegitimizedGuard } from '../../_guard/legitimized.guard';
import { TwoFactorAuthenticatedGuard } from '../../_guard/two-factor-authenticated.guard';
import { VehicleReturnEstimateComponent } from './vehicle-return-estimate.component';
import { route } from '../../shared/_helper-services/router.service';
import { Constants } from '../../shared/constants';
import { ContractsResolve } from '../../_resolvers/contracts.resolve';
import { OnBoardingAcceptGuard } from '../../_guard/onboarding-accept.guard';
import { V2FeatureIndicatorResolve } from '../../_resolvers/v2-feature-indicator.resolve';

const VehicleReturnEstimateRoutes: Routes = [
  {
    path: 'estimate', data: { subNavType: Constants.Accounts }, component: VehicleReturnEstimateComponent,
    canActivate: [AuthGuard, LegitimizedGuard, TwoFactorAuthenticatedGuard, OnBoardingAcceptGuard],
    resolve: { void: ContractsResolve, V2FeatureIndicatorResolve }
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(VehicleReturnEstimateRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class VehicleReturnEstimateRoutingModule { }
