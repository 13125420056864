export class FAQModel {
  topics: FAQTopicModel[];
  questions: FAQQuestionModel[];
  error: boolean;

  constructor(topics?: FAQTopicModel[], questions?: FAQQuestionModel[], error?: boolean) {
    this.topics = topics;
    this.questions = questions;
    this.error = error;
  }
}

export class FAQTopicModel {
  topicTypeId: number;
  topicTypeDescription: string;
  topicRankNumber: number;
   

  constructor(topicTypeId: number, topicTypeDescription: string, topicRankNumber: number) {
    this.topicTypeId = topicTypeId;
    this.topicTypeDescription = topicTypeDescription;
    this.topicRankNumber = topicRankNumber;
  }
}

export class FAQQuestionModel {
  questionId: number;
  questionText: string;
  answerText: string;
  rankNumber: number;
  topicTypeId: number;

  constructor(questionId: number, questionText: string, answerText: string, rankNumber: number, topicTypeId: number) {
    this.questionId = questionId;
    this.questionText = questionText;
    this.answerText = answerText;
    this.rankNumber = rankNumber;
    this.topicTypeId = topicTypeId;
  }
}
