import { Injectable } from '@angular/core';
import { BridgeService, AppPath, Theme } from 'bridgeservices-lib';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from "../store/app.store";
import { DeepLinkParam } from '../../_models/deeplink-param.model';

@Injectable()
export class BridgeHelperService {

  constructor(
    private bridgeService: BridgeService,
    private store: Store<IAppState>
  ) { }

  public writeData(storageKey: string, storageValue: string, isPersistent: boolean) {
    this.bridgeService.writeData(storageKey, storageValue, isPersistent).then();
  }

  public readData(storageKey: string): Promise<string> {
    return this.bridgeService.readData(storageKey);
  }

  public clearData(storageKey: string) {
    return this.bridgeService.clearData(storageKey, true);
  }

  public deviceTheme: BehaviorSubject<Theme> = this.bridgeService.deviceTheme;

  public showSpinner() {
    this.bridgeService.showSpinner();
  }

  public hideSpinner() {
    this.bridgeService.hideSpinner();
  }

  public exit() {
    let appPath = this.getAppPath();
    this.bridgeService.navigateInAppAndExit(appPath).then().catch(() => {
      this.bridgeService.exit();
    });
  }

  private getAppPath(): AppPath {
    let deepLink: DeepLinkParam;
    this.store.select(state => state.DeepLinkParam).subscribe(x => deepLink = x);
    if (deepLink && deepLink.pageId) {
      return AppPath.NOTIFICATIONS;
    }
    else {
      return AppPath.PROFILE;
    }
  }

  public setNativeAppTitle(title: string) {
    this.bridgeService.setNativeAppTitle(title).then();
  }

  public getIdToken() {
    return this.bridgeService.getIdToken();
  }

  public getNativeAppTitle(): Promise<string> {
    return this.bridgeService.getNativeAppTitle();
  }

  public isDarkMode(): BehaviorSubject<boolean> {
    const isDarkMode = new BehaviorSubject<boolean>(false);

    this.bridgeService.deviceTheme.subscribe(theme => {
      isDarkMode.next(theme === Theme.DarkMode);
    })

    return isDarkMode;
  }

  public openBrowser(url: string) {
    return this.bridgeService.openBrowser(url);
  }

  public isResumed(): BehaviorSubject<boolean> {
    return this.bridgeService.isResumed;
  }

  public resetIsResumed(flag: boolean) {
    this.bridgeService.isResumed.next(flag);
  }

  public viewPrintPdf(data: string) {
    return this.bridgeService.viewPrintPdf(data);
  }
}

