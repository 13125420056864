import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, Injector, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { ACHErrorType, PaymentEntry, PaymentSource } from '../../../../_models/payment.model';
import { ValidatorService } from '../../../../_validators/validator.service';
import { CustomerACHService } from '../../../../_web-services/customer-ach.service';
import { CustomerFinancialAccountService } from '../../../../_web-services/customer-financial-account.service';
import { BaseApplicationComponent } from "../../../../core/basecomponent/base.component";
import { FinancialAccountDTOAccountType } from '../../../../core/gateway-api';
import { MaskService } from '../../../../shared/_helper-services/mask.service';
import { ObjectService } from '../../../../shared/_helper-services/object.service';
import { PaymentHelperService } from '../../../../shared/_helper-services/payment-helper.service';
import { Constants } from '../../../../shared/constants';
import { PaymentEntryActions } from '../../../../shared/store/reducers/payment-entry.reducer';
import { PaymentSourceActions } from '../../../../shared/store/reducers/payment-source.reducers';
import { RecurringPaymentEntryActions } from '../../../../shared/store/reducers/recurring-payment.reducer';
import { RecurringPaymentEntry } from '../../../../_models/recurring-payment/recurring-payment-entry.model';

@Component({
  selector: 'app-add-ach-payment-source',
  templateUrl: './add-ach-payment-source.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./add-ach-payment-source.component.scss']
})

export class AddACHPaymentSourceComponent extends BaseApplicationComponent<AddACHPaymentSourceComponent> implements OnInit {
  title: string;
  validatorService: ValidatorService;
  showError: boolean;
  addACHError: boolean;
  addACHErrorMsg: string;
  achPaymentSource: PaymentSource;
  component: string;
  mustRetain: boolean;
  public accountType: string;

  // Tooltip variables
  position: string;
  innerHeight: number;
  innerWidth: number;

  public achSources = [
    { value: FinancialAccountDTOAccountType.Checking, display: 'Checking' },
    { value: FinancialAccountDTOAccountType.Savings, display: 'Savings' }
  ];


  constructor(validatorService: ValidatorService,
    private _location: Location,
    private translateService: TranslateService,
    private objectService: ObjectService,
    private customerACHService: CustomerACHService,
    private customerFinancialAccountService: CustomerFinancialAccountService,
    public dialogRef: MatDialogRef<AddACHPaymentSourceComponent>,
    public paymentEntryActions: PaymentEntryActions,
    private recurringPaymentEntryActions: RecurringPaymentEntryActions,
    private paymentSourceActions: PaymentSourceActions,
    private paymentHelperService: PaymentHelperService,
    renderer2: Renderer2,
    private maskService: MaskService,
    @Inject(DOCUMENT) document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    injector: Injector) {
    super(injector, document);
    this.position = "right";
    this.validatorService = validatorService;
    this.showError = false;
    this.addACHError = false;
    this.addACHErrorMsg = '';
    this.achPaymentSource = new PaymentSource();
    this.component = this.data.component;
    this.mustRetain = this.component === Constants.ONE_TIME_FLOW ? false : true;
  }

  ngOnInit() {
    super.pushDataLayer();
    this.analytics.pushSiteToolEvent({ siteTool: `${Constants.Add} ${Constants.PaymentSource}`, siteToolStep: Constants.SiteToolStart });
    this.init();
  }

  public init() {
    this.translateService.get("ngw.payment-source.ach.title").subscribe((res: string) => {
      this.title = res;
    })

    this.innerWidth = window.innerWidth;

    if (this.innerWidth < 769) {
      this.position = "left";
    }
  }

  addAch(addAchFormData: FormData) {
    super.setDataLoadedOnRequest(false);
    let formData: any = addAchFormData;
    this.scrollToTop = false;  //reset everytime for onchanges to trigger on the directive
    this.customerACHService.validateRoutingNumber(formData.routingNumber)
      .subscribe(result => { this.postValidateRoutingNumber(result, formData); });
  }

  public showValidationError(form: any) {
    this.showError = true;
  }

  private postValidateRoutingNumber(response: any, addAchFormData: FormData) {
    if (response.error) {
      this.addACHError = true;
      let errorTranslationKey: string;
      if (response.errorType === ACHErrorType.InvalidRoutingNumber) {
        errorTranslationKey = "ngw.payment-source.ach.error.invalid-routing";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          this.addACHErrorMsg = res;
        });
      } else {
        errorTranslationKey = "ngw.global.technical-error"
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          this.addACHErrorMsg = res;
        });
      }
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
      this.scrollToTop = true; //scroll page to top to display error message
      super.setDataLoadedOnRequest(true);
    } else {
      let formData: any = addAchFormData;
      this.achPaymentSource.bankId = response.bankId;
      this.achPaymentSource.financialAccountNumber = formData.accountNumber;
      this.achPaymentSource.accountNickname = formData.accountName;
      if (this.achPaymentSource.accountNickname) {
        this.achPaymentSource.displayAccountNumber = this.achPaymentSource.accountNickname;
      } else {
        this.achPaymentSource.displayAccountNumber = this.achPaymentSource.accountType + ' - ' + this.maskService.getMaskedAccountNo(this.achPaymentSource.financialAccountNumber);
      }
      this.achPaymentSource.accountType = formData.accountType;
      this.achPaymentSource.retain = this.component !== Constants.ONE_TIME_FLOW ? true : formData.retain;
      this.customerFinancialAccountService.insertFinancialAccount(this.achPaymentSource)
        .subscribe(result => { this.postInsertFinancialAccount(result); });
    }
  }

  private postInsertFinancialAccount(response: any) {
    if (response.error) {
      this.addACHError = true;
      const errorTranslationKey = "ngw.global.technical-error";
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.addACHErrorMsg = res;
      });
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
      super.setDataLoadedOnRequest(true);
    } else {
      this.analytics.pushSiteToolEvent({ siteTool: `${Constants.Add} ${Constants.PaymentSource}`, siteToolStep: Constants.SiteToolComplete });
      this.achPaymentSource.financialAccountId = response.financialAccountId;

      this.achPaymentSource.displayAccountNumber = this.paymentHelperService.getPaymentDisplayName(this.achPaymentSource);

      let lastUsedPaymentEntry;
      let paymententry: PaymentEntry;
      this.store.select(state => state.PaymentEntry).subscribe(x => paymententry = x);
      if (!this.objectService.isEmptyObject(paymententry) && paymententry !== null) {
        lastUsedPaymentEntry = _.maxBy(paymententry.sources, 'lastUsed');
        if (lastUsedPaymentEntry) {
          this.achPaymentSource.lastUsed = lastUsedPaymentEntry.lastUsed + 1;
        }
      }
      let recurringPaymentEntry: RecurringPaymentEntry;
      this.store.select(state => state.RecurringPaymentEntry).subscribe(x => recurringPaymentEntry = x);
      if (!this.objectService.isEmptyObject(recurringPaymentEntry) && recurringPaymentEntry !== null) {
        lastUsedPaymentEntry = _.maxBy(recurringPaymentEntry.sources, 'lastUsed');
        if (lastUsedPaymentEntry) {
          this.achPaymentSource.lastUsed = lastUsedPaymentEntry.lastUsed + 1;
        }
      }

      if (this.objectService.isEmptyObject(paymententry)) {
        //todo: move this logic to onetime payment
        let paymentEntry = new PaymentEntry();
        let sources = [];
        sources.push(this.achPaymentSource);
        paymentEntry.sources = sources;
        paymentEntry.selectedPaymentSource = this.achPaymentSource;
        this.store.dispatch(this.paymentEntryActions.setPaymentEntry(paymentEntry));
      }
      else {
        this.store.dispatch(this.paymentEntryActions.pushPaymentSource(this.achPaymentSource));
      }

      this.dialogRef.close(this.achPaymentSource);
      super.setDataLoadedOnRequest(true);
    }
  }

  private padLeft(value: string, padding: number, paddingChar: string): string {
    return Array(padding + 1).join(paddingChar) + value;
  }

  public cancel() {
    let paymententry: PaymentEntry;
    this.store.select(state => state.PaymentEntry).subscribe(x => paymententry = x);
    if (!this.objectService.isEmptyObject(paymententry) && paymententry !== null) {
      let lastUsedPaymentEntry = _.maxBy(paymententry.sources, 'lastUsed');
      this.store.dispatch(this.paymentEntryActions.setSelectedPaymentSource(lastUsedPaymentEntry));
    }
    let recurringPaymentEntry: RecurringPaymentEntry;
    this.store.select(state => state.RecurringPaymentEntry).subscribe(x => recurringPaymentEntry = x);
    if (!this.objectService.isEmptyObject(recurringPaymentEntry) && recurringPaymentEntry !== null) {
      let lastUsedPaymentEntry = _.maxBy(recurringPaymentEntry.sources, 'lastUsed');
      this.store.dispatch(this.recurringPaymentEntryActions.setRecurringSelectedPaymentSource(lastUsedPaymentEntry));
    }
    this.dialogRef.close(null);
  }
}
