import { FinancialAccountDTOAccountType } from '../core/gateway-api';

export class PaymentSourceDeleteReview {
    bankName: string;
    accountType: FinancialAccountDTOAccountType;
    nickName: string;
    accountNumber: string;
    routingNumber: string;
    financialAccountId: string; 
    expirationdate: Date;
}

export class PaymentSourceDeleteStatus {
    error: boolean;
    faultType: string;
}
