import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class IsInMAPFlowActions
{
  static SET_IS_IN_MAP_FLOW: string = 'SET_IS_IN_MAP_FLOW';

   

  setIsInMAPFlow(isInMAPFlow: boolean): FSA<string, boolean, null>{
        return {
          type: IsInMAPFlowActions.SET_IS_IN_MAP_FLOW,
          payload: isInMAPFlow,
            meta: null
        };
    }

}

@Injectable()
export class IsInMAPFlowReducers
{
  static IsInMAPFlowReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
          case IsInMAPFlowActions.SET_IS_IN_MAP_FLOW:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
