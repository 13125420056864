import { Component, OnInit, Inject, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as _ from 'lodash-es';
import { ContractAccountDetailDTO } from '../../../../../../core/gateway-api';
import { BaseApplicationComponent } from '../../../../../../core/basecomponent/base.component';
import { AccountInfoService } from '../../../../../_helper-services/account-info.service';
import { NotificationsHelperService } from '../../../../../_helper-services/notifications-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { PaperlessToggleActions } from '../../../../../store/reducers/paperless_toggled.reducer';
import { ModuleOnBoardingHelperService } from '../../../../../_helper-services/module-onboarding-helper.service';

@Component({
  selector: 'app-legal-agreement-paperless',
  templateUrl: './legal-agreement-paperless.component.html',
  styleUrls: ['./legal-agreement-paperless.component.scss']
})

export class LegalAgreementPaperless extends BaseApplicationComponent<LegalAgreementPaperless> implements OnInit {

  public currentContract: ContractAccountDetailDTO;
  public vehicleImageData: string;
  public primaryEmailAddress: string
  public primaryEmailTextMsg: string;
  public contractYearAndModel: string;
  public contractTypeAndNumber: string;
  public toggled: boolean;

  constructor(@Inject(DOCUMENT) document,
    injector: Injector,
    private accountInfoService: AccountInfoService,
    private translateService: TranslateService,
    private notificationsHelperService: NotificationsHelperService,
    private paperlessToggleActions: PaperlessToggleActions,
    public moduleOnBoardingHelperService: ModuleOnBoardingHelperService) {
    super(injector, document);
    this.toggled = true;
  }
  
  ngOnInit() {
    this.store.dispatch(this.paperlessToggleActions.setPaperlessToggle(true));
    let contracts: ContractAccountDetailDTO[];
    this.store.select(state => state.ContractAccountDetails).subscribe(x => contracts = x);
    if (this.moduleOnBoardingHelperService.getPaperlessNotEnrolledAccount() || this.moduleOnBoardingHelperService.getPaperlessNotEnrolledAccount() != null) {
        this.currentContract = _.find(contracts, contract => contract.accountNumber == this.moduleOnBoardingHelperService.getPaperlessNotEnrolledAccount())
    }
    else {
        this.currentContract = _.first(_.values(contracts));
    }
    this.vehicleImageData = this.accountInfoService.getVehicleImageData(this.currentContract.accountNumber);
    this.primaryEmailAddress = this.notificationsHelperService.getCustomerPrimaryEmailAddress();
    this.translateService.get('ngw.paperless-enrollment.confirmation-text.switch-on.description', { email: this.primaryEmailAddress }).subscribe((res: string) => {
      this.primaryEmailTextMsg = res;
    });
    this.contractYearAndModel = this.accountInfoService.getContractInfo(this.currentContract);
    this.contractTypeAndNumber = this.accountInfoService.getContractInfoWithAccNo(this.currentContract);
  }

  public onToggle(event: any) {
    this.store.dispatch(this.paperlessToggleActions.setPaperlessToggle(event.checked));
  }
}
