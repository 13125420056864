
export class RecurringPaymentCancel {
    error: boolean;
    errorMessage: string;
    titleSection: string;
    vehicleModelYear: string;
    billingEmail: string;
    accountNumber: string;
    paymentSource: string;
    schedulePaymentAmount: number;
    schedulePaymentDate: string;
    additionalAmount: number;
    shouldDrawFullBalance: boolean;
    maxPaymentAmount: number;

    constructor(
        error: boolean = undefined,
        errorMessage: string = undefined,
        titleSection: string = undefined,
        vehicleModelYear: string = undefined,
        billingEmail: string = undefined,
        accountNumber: string = undefined,
        paymentSource: string = undefined,
        schedulePaymentAmount: number = undefined,
        schedulePaymentDate: string = undefined,
        additionalAmount: number = undefined,
        shouldDrawFullBalance: boolean = false,
        maxPaymentAmount: number = undefined
    )
    {
        this.billingEmail = billingEmail ? billingEmail : this.billingEmail;
        this.error = error ? error : this.error;
        this.errorMessage = errorMessage ? errorMessage : this.errorMessage;
        this.titleSection = titleSection ? titleSection : this.titleSection;
        this.vehicleModelYear = vehicleModelYear ? vehicleModelYear : this.vehicleModelYear;
        this.accountNumber = accountNumber ? accountNumber : this.accountNumber;
        this.paymentSource = paymentSource ? paymentSource : this.paymentSource;
        this.schedulePaymentAmount = schedulePaymentAmount ? schedulePaymentAmount : this.schedulePaymentAmount;
        this.schedulePaymentDate = schedulePaymentDate ? schedulePaymentDate : this.schedulePaymentDate;
        this.additionalAmount = additionalAmount ? additionalAmount : this.additionalAmount;
        this.shouldDrawFullBalance = shouldDrawFullBalance ? shouldDrawFullBalance : this.shouldDrawFullBalance;
        this.maxPaymentAmount = maxPaymentAmount ? maxPaymentAmount : this.maxPaymentAmount;
    }
}
