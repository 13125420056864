import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { INITIAL_STATE } from '../app.store';

export class StatesActions {
 
}

@Injectable()
export class StatesReducers {
    static StatesReducer: Reducer<string[]> = (state: string[] = INITIAL_STATE.States, action: FSA<string, string[], void>) => {
        switch (action.type) {
            default:
                return state;
        }
    }
}
