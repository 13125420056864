import { Injectable } from '@angular/core';
import { Constants } from './../constants';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';

@Injectable()
export class PipeService {

  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) { }

  getAmountAsCurrency(amount: number): string {
    return String(this.currencyPipe.transform(amount, "USD", "symbol", "1.2-2"));
  }

  getDateAsFormattedDate(date: Date | string, isMedium: boolean, format?: string): string {
    let type = (format !== undefined) ? format : (isMedium ? 'mediumDate' : 'longDate');
    return date
      ? String(this.datePipe.transform(date, type))
      : Constants.EMPTY;
  }

  public getTodaysDate(): Date {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  }
  
  public getNumberAsFormattedNumber(number: any, format?: string, reverse?: boolean): string {
    //reverse formatting before trying to formate again
    if (reverse) {
      number = this.getNumberFromFormattedNumber(number);
    }
    let formatValue = format ? format : '1.0-2';
    return this.decimalPipe.transform(number, formatValue);
  }
  public getNumberFromFormattedNumber(number: any):number
  {
    if (number && number.includes(',')) {
      number = number.replace(/\,/g, '');
      number = Number(number);
    }
    return number;
  }

}

