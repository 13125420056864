import { AuthhubUrlBuilderService } from "../../shared/_helper-services/authhub-url-builder.service";
import { GcdmOneLoginUrlBuilderService } from "../../shared/_helper-services/gcdm-one-login-url-builder-service";

export interface IAuthProviderUrllBuilderService{
  getLoginRedirectUrl(state: string, token?: string): string;
  getLogoutRedirectUrl(language: string): string;
  getRedirectUrl(url: string): string;
  getRegistrationUrl(): string;
  getManageSettingsRedirectUrl(): string;
  getTermsUseRedirectUr(): string;
  getUnsubscribeUrl(): string;
  getProfileUrl(): string;
}

// AbstractFactoryProvider as a HashMap 0: For AuthHub And 1: For GCDMOneLogin
export const authProviderMap = new Map<number, any>([
  [0, AuthhubUrlBuilderService],
  [1, GcdmOneLoginUrlBuilderService]
]);
