import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { ServicingPreferenceDTO } from '../../../core/gateway-api';

@Injectable()
export class PreferencesActions
{
    static SET_PREFERENCES: string = 'SET_PREFERENCES';
    static CLEAR_PREFERENCES: string = 'CLEAR_PREFERENCES';

  setPreferences(preferences: ServicingPreferenceDTO[]): FSA<string, ServicingPreferenceDTO[], null>{
    return {
      type: PreferencesActions.SET_PREFERENCES,
      payload: preferences,
      meta: null
    };
  }

  clearPreferences(): FSA<string, ServicingPreferenceDTO[], null> {
      return {
          type: PreferencesActions.CLEAR_PREFERENCES,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class PreferencesReducers
{
  static PreferencesReducer: Reducer<ServicingPreferenceDTO[]> = (state: ServicingPreferenceDTO[] = [], action: FSA<string, ServicingPreferenceDTO[], void>) => {
    switch(action.type){
        case PreferencesActions.SET_PREFERENCES:
            return tassign(state, action.payload);
        case PreferencesActions.CLEAR_PREFERENCES:
            return state = [];
        default:
          return state;
    }
  }
}
