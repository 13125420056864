import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../../../../shared/constants';
import { BaseApplicationComponent } from '../../../../../../core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';
import { CustomerFinancialAccountService } from "../../../../../../_web-services/customer-financial-account.service";
import { PaymentSourceDeleteReview, PaymentSourceDeleteStatus } from '../../../../../../_models/delete-payment-source.model';
import { PaymentSourceActions } from '../../../../../../shared/store/reducers/payment-source.reducers';
import { PaymentEntryActions } from '../../../../../../shared/store/reducers/payment-entry.reducer';
import { PaymentEntry } from "../../../../../../_models/payment.model";
import { ObjectService } from "../../../../../../shared/_helper-services/object.service";

@Component({
  selector: 'delete-payment-source',
  templateUrl: './delete-payment-source.component.html',
  styleUrls: ['./delete-payment-source.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeletePaymentSource extends BaseApplicationComponent<DeletePaymentSource> {
  paymentSourceDeleteReview: PaymentSourceDeleteReview;
  errorMessage: string;
  deletePaymentSourceError: boolean;
  constructor(private translateService: TranslateService,
    private customerFinancialAccountService: CustomerFinancialAccountService,
    private objectService: ObjectService,
    private paymentSourceActions: PaymentSourceActions,
    public paymentEntryActions: PaymentEntryActions,
    public dialogRef: MatDialogRef<DeletePaymentSource>, @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
    this.deletePaymentSourceError = false;
    this.errorMessage = "";
  }

  ngOnInit() {
    this.store.select(state => state.PaymentSourceDeleteReview).subscribe(x => this.paymentSourceDeleteReview = x);
    this.analytics.pushSiteToolEvent({ siteTool: `${Constants.DeletePaymentSourceComponentName}`, siteToolStep: Constants.SiteToolStart });
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }

  public deletePaymentSource() {
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.setDataLoadedOnRequest(false);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
        this.customerFinancialAccountService.expireFinancialAccount(this.paymentSourceDeleteReview.financialAccountId)
          .subscribe(response => { this.postDeletePaymentSource(response); });
      });
  }

  private postDeletePaymentSource(response: PaymentSourceDeleteStatus) {
    if (response.error) {
      this.deletePaymentSourceError = true;
      const errorTranslationKey = "ngw.global.technical-error";
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.errorMessage = res;
      });
      this.analytics.pushSiteToolError({ siteToolError: errorTranslationKey });
      super.setDataLoadedOnRequest(true);
    }
    else {
      this.analytics.pushSiteToolEvent({ siteTool: `${Constants.DeletePaymentSourceComponentName}`, siteToolStep: Constants.SiteToolComplete });
      super.setDataLoadedOnRequest(true);
      this.closeDialog();
      let cachedPaymentEntry: PaymentEntry;
      this.store.select(state => state.PaymentEntry).subscribe(x => cachedPaymentEntry = x);
      if (cachedPaymentEntry && cachedPaymentEntry.sources) {
        this.store.dispatch(this.paymentEntryActions.deletePaymentSource(this.paymentSourceDeleteReview.financialAccountId));
      }
      else if (this.objectService.isEmptyObject(cachedPaymentEntry)) {
        let paymentEntry = new PaymentEntry();
        let sources = [];
        paymentEntry.sources = sources;
        paymentEntry.selectedPaymentSource = null;
        this.store.dispatch(this.paymentEntryActions.setPaymentEntry(paymentEntry));
      }
    }
  }

  public isSavingOrChecking(): boolean {
    return (this.paymentSourceDeleteReview.accountType.toString() === 'Checking' || this.paymentSourceDeleteReview.accountType.toString() === 'Savings');
  }

  public isCreditOrDebit(): boolean {
    return (this.paymentSourceDeleteReview.accountType.toString() === 'Debit' || this.paymentSourceDeleteReview.accountType.toString() === 'Credit');
  }

  public cancelDeletePaymentSource() {
    this.store.dispatch(this.paymentSourceActions.clearPaymentSourceDeleteReview());
    this.closeDialog();
  }

}
