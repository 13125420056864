<form>
  <div class="modal-consent">
    <h3>{{"ngw.shared.user-consent-dialog.title" | translate}}</h3>
    <div class="error-container" *ngIf="globalTechError">
      <em class="error-validation" id="errorMessage" [innerHtml]="errorMessage"></em>
    </div>
    <p>{{"ngw.map.payment.cancel-dialog.cancel-dialog-text1" | translate}}</p>
    <p>
      {{"ngw.map.payment.cancel-dialog.cancel-dialog-text2" | translate}}
      <a (click)="saveAsQuoteClick();">{{"ngw.map.payment.cancel-dialog.save-quote-link" | translate}}</a>.
    </p>
    <div class="action-buttons">
      <button id="btnCancel" class="btn btn-secondary" (click)="cancelPayment(); $event.preventDefault();">{{"ngw.map.payment.cancel-dialog.cancel-purchase" | translate}}</button>
      <button class="btn btn-primary" (click)="returnToPurchase(); $event.preventDefault();" type="submit" setFocus>{{"ngw.map.payment.cancel-dialog.return-to-purchase" | translate}}</button>
    </div>
  </div>
</form>



