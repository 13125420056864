import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { ObjectService } from './object.service';
import { IAppState } from "../store/app.store";
import { routes } from "./router.service";
import { Constants } from '../constants';
import { EnvironmentConfig } from "../../_models/environment-config";
import { ApplicationConfig } from '../../_models/application-config';
import { LanguageService } from '../../shared/_helper-services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { authProviderMap } from '../../_models/interface/iauthprovider-url-builder.service';
import { IdTokenProvider, IdTokenProviderService } from '../../../_shared/_models/interface/id-token-provider.service';

@Injectable({
  providedIn: 'root',
})
export class UrlBuilderService {
  public currentRoute: routes;
  public envConfig: EnvironmentConfig;
  public appConfig: ApplicationConfig;
  public service: any;
  private tokenProvider: IdTokenProvider;

  constructor(
    private store: Store<IAppState>,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private objectService: ObjectService, private injector: Injector,
    private idTokenProviderService: IdTokenProviderService) {
  }

  public getIdToken = () => {
    let envConfig: EnvironmentConfig;
    this.store.select(state => state.EnvironmentConfig).subscribe(x => envConfig = x);
    this.tokenProvider = this.idTokenProviderService.getIdTokenProvider(envConfig);
    return this.tokenProvider.idToken();
  }

  public getAuthProviderLoginRedirectUrl(state: string, token?: string): string {
    this.getAuthProvider();
    return this.service.getLoginRedirectUrl(state, token);
  }

  public getAuthProviderLogoutRedirectUrl(language: string): string {
    this.getAuthProvider();
    return this.service.getLogoutRedirectUrl(language);
  }

  public getAuthProviderProfileRedirectUrl(): string {
    this.getAuthProvider();
    return this.service.getProfileUrl();
  }

  public getAuthProviderRedirectUrl(url: string): string {
    this.getAuthProvider();
    return this.service.getRedirectUrl(url);
  }
  public getAuthProviderRegistrationUrl(): string {
    this.getAuthProvider();
    return this.service.getRegistrationUrl();
  }

  public getAuthProviderManageSettingsRedirectUrl(): string {
    this.getAuthProvider();
    return this.service.getManageSettingsRedirectUrl();
  }
  public getAuthProviderTermsUseRedirectUrl(): string {
    this.getAuthProvider();
    return this.service.getTermsUseRedirectUr();
  }

  public getAuthProviderUnsubscribeUrl(): string {
    this.getAuthProvider();
    return this.service.getUnsubscribeUrl();
  }

  public buildUrl(baseUrl: string, route: string, isRoute?: boolean) {
    return !isRoute ? `${baseUrl}${route}` : `${baseUrl}/${route}`;
  }

  private getRouteParameter(parameterName: string, parameterValue: string, isLastParameter?: boolean): string {
    return isLastParameter
      ? parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue)
      : parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue, Constants.UrlParametersConcat);
  }

  private getAuthProvider() {
    if (!this.service) {
      let envConfig_Auth_Provider: number;
      this.store.select(state => state.EnvironmentConfig.AUTH_PROVIDER).subscribe(x => envConfig_Auth_Provider = x);
      let injectableVal = authProviderMap.get(envConfig_Auth_Provider);
      this.service = this.injector.get(injectableVal);
    }
  }

}
