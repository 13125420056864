import { Store } from '@ngrx/store';
import { Injectable } from "@angular/core";
import { IAppState } from "../store/app.store";
import { LanguageService } from "./language.service";
import * as _ from 'lodash';
import { ActivityTypes, LeaselinkType } from "../enums";
import { LogSiteActivityService } from "../../../_shared/_web-services/log-site-activity.service"
import { RouterService } from "./router.service";


@Injectable()
export class LeaseEndNavigationService {
  private environmentConfig;
  constructor(private store: Store<IAppState>,
    private languageService: LanguageService,
    private logSiteActivityService: LogSiteActivityService,
    private routerService: RouterService) {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.environmentConfig = x);
  }
  public logActivity(activityTypes: ActivityTypes, accountNumbers?: string | string[]) {
    this.logSiteActivityService.logActivity(activityTypes, accountNumbers);
  }

  public navigateTo(linkName: string, provinceCode?: string, retailerId?: string) {
    let result = _.find(this.environmentConfig.LEASE_LINK.LINK_LIST, function (x) {
      return x.LINK === linkName;
    });
    const mapObj = {
      provinceCode: provinceCode,
      retailerId: retailerId
    };
    const regex = new RegExp(Object.keys(mapObj).join("|"), "gi");
    if (result) {
      let url = result[LeaselinkType[this.languageService.getCurrentLanguage()]].toString();
      url = url.replace(regex, matched => mapObj[matched]);
      this.routerService.navigateToExternalUrlInNewTab(url);
    }
  }
}