import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { FAQModel } from '../../../_models/faq.model';

@Injectable()
export class HelpServiceActions
{
  static ADD_FAQ: string = 'ADD_FAQ';
  static CLEAR_FAQ: string = 'CLEAR_FAQ';

  addFAQ(faq: FAQModel): FSA<string, FAQModel, null>{
    return {
      type: HelpServiceActions.ADD_FAQ,
      payload: faq,
      meta: null
    };
  }
  
  clearFAQ(): FSA<string, FAQModel, null> {
      return {
          type: HelpServiceActions.CLEAR_FAQ,
          payload: null,
          meta: null
      };
  }
}

@Injectable()
export class HelpServiceReducers
{
  static HelpServiceReducer: Reducer<FAQModel> = (state: FAQModel=new FAQModel([], [], false), action: FSA<string, FAQModel, void>) => {
    switch(action.type){
    case HelpServiceActions.ADD_FAQ:
      return tassign(state,action.payload);
    case HelpServiceActions.CLEAR_FAQ:
      return tassign(undefined);
    default:
      return state;
    }
  }
}
