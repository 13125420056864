import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { FeatureIndicatorService } from '../_web-services/feature-indicator.service';
import { FeatureIndicatorNames } from '../shared/enums';
import { IAppState } from "../shared/store/app.store";
import { FeatureIndicatorActions } from '../shared/store/reducers/feature-indicator.reducers';

export const V2FeatureIndicatorResolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {

  const store = inject(Store<IAppState>);
  const featureIndicatorService = inject(FeatureIndicatorService);
  const featureIndicatorActions = inject(FeatureIndicatorActions);
 
  return featureIndicatorService.getFeatureIndicatorByClientId(FeatureIndicatorNames.EnableV2).pipe(map(v2Enabled => {
    store.dispatch(featureIndicatorActions.setV2Enabled(v2Enabled));
  }));

};