import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { ContractAccountDetailDTO } from '../../../core/gateway-api';

@Injectable()
export class SortedTerminatedContractAccountDetailsActions
{
  static SET_SORTED_TERMINATED_CONTRACTS: string = 'SET_SORTED_TERMINATED_CONTRACTS';
  static CLEAR_SORTED_TERMINATED_CONTRACTS: string = 'CLEAR_SORTED_TERMINATED_CONTRACTS';

  setSortedTerminatedContracts(contracts: ContractAccountDetailDTO[]): FSA<string, ContractAccountDetailDTO[], null>{
    return {
      type: SortedTerminatedContractAccountDetailsActions.SET_SORTED_TERMINATED_CONTRACTS,
      payload: contracts,
      meta: null
    };
  }

  clearSortedTerminatedContracts(): FSA<string, ContractAccountDetailDTO[], null> {
      return {
        type: SortedTerminatedContractAccountDetailsActions.CLEAR_SORTED_TERMINATED_CONTRACTS,
          payload: null,
          meta: null
      };
  }

}

@Injectable()
export class SortedTerminatedContractAccountDetailsReducers
{
  static SortedTerminatedContractAccountDetailsReducers: Reducer<ContractAccountDetailDTO[]> = (state: ContractAccountDetailDTO[] = [], action: FSA<string, ContractAccountDetailDTO[], void>) => {
    switch(action.type){
      case SortedTerminatedContractAccountDetailsActions.SET_SORTED_TERMINATED_CONTRACTS:
        return tassign(state, action.payload);
      case SortedTerminatedContractAccountDetailsActions.CLEAR_SORTED_TERMINATED_CONTRACTS:
         return state = [];
        default:
          return state;
    }
  }
   
}
