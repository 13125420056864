import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { CoBrandedCreditCardModel } from '../_models/cobranded-credit-card.model';
import { BmwCardInfoDTO, BmwCardServiceClient, CardMemberInquiryRequest, CardMemberInquiryResponse, FindCreditCardAccountsRequest, FindCreditCardAccountsRequestBmwCreditCardBrand, FindCreditCardAccountsResponse } from '../core/gateway-api';
import { FSTokenErrorHandler } from '../shared/_errorhandler/gobal-error-handler';
import { UserService } from '../shared/_helper-services/user.service';
import { Constants } from '../shared/constants';
import { IAppState } from "../shared/store/app.store";
import { CardHolderActions } from '../shared/store/reducers/card-holder.reducer';

@Injectable()
export class BmwCardService {
  constructor(
    private bmwCardServiceClient: BmwCardServiceClient,
    private store: Store<IAppState>,
    private cardHolderActions: CardHolderActions,
    private fsTokenErrorHandler: FSTokenErrorHandler,
    private userService: UserService
  ) { }

  private getCachedgetCardHolderData(): CoBrandedCreditCardModel {
    let cachedCardHolderModel: CoBrandedCreditCardModel;
    this.store.select(state => state.CardHolder).subscribe(x => cachedCardHolderModel = x);
    return cachedCardHolderModel;
  }

  public getBMWCreditcardDetails(brand: FindCreditCardAccountsRequestBmwCreditCardBrand, customerNumber: number, refresh: boolean): Observable<CoBrandedCreditCardModel> {

    let cachedCardHolderData = this.getCachedgetCardHolderData();
    if (!cachedCardHolderData|| cachedCardHolderData.isCardHolder==undefined || refresh) {
      var request = new FindCreditCardAccountsRequest();
      request.bmwCreditCardBrand = brand;
      request.customerNumber = customerNumber;
      request.includeExpired = false;
      request.gcid = this.userService.getGcid();
      return this.bmwCardServiceClient.findCreditCardAccounts(request).pipe(mergeMap((response) => { return this.afterFindCreditCardAccountsSuccess(response, refresh); })
        , catchError((error: any) => { return this.afterFindCreditCardAccountsFailure(error); }));
    }
    else {
      let cardHolder = new CoBrandedCreditCardModel();
      if (!cachedCardHolderData.isCardHolder)
        return of(Object.assign(new CoBrandedCreditCardModel(), cachedCardHolderData));

      cardHolder.accountEAC = cachedCardHolderData.accountEAC;
      cardHolder.isCardHolder = true;
      var BmwMemberInquiryRequest = new CardMemberInquiryRequest();
      BmwMemberInquiryRequest.accountEac = cardHolder.accountEAC;
      BmwMemberInquiryRequest.userId = Constants.OcWebUser;
      cardHolder.error = false;
      return this.bmwCardServiceClient.cardMemberInquiry(BmwMemberInquiryRequest).pipe(mergeMap((response) => { return this.afterCardMemberInquirySuccess(response, refresh, cardHolder); })
        , catchError((error: any) => { return this.afterCardMemberInquiryFailure(error, cardHolder); }));
    }

  }

  private afterFindCreditCardAccountsSuccess(response: FindCreditCardAccountsResponse, refresh: boolean): Observable<CoBrandedCreditCardModel> {
    let cardHolder = new CoBrandedCreditCardModel();
    if (response) {
      if (response.creditCardsKeyInfo != undefined && response.creditCardsKeyInfo.length > 0) {
        cardHolder.isCardHolder = true;
        cardHolder.accountEAC = response.creditCardsKeyInfo[0].eac;
        var BmwMemberInquiryRequest = new CardMemberInquiryRequest();
        BmwMemberInquiryRequest.accountEac = cardHolder.accountEAC;
        BmwMemberInquiryRequest.userId = Constants.OcWebUser;
        cardHolder.error = false;
        return this.bmwCardServiceClient.cardMemberInquiry(BmwMemberInquiryRequest).pipe(mergeMap((response) => { return this.afterCardMemberInquirySuccess(response, refresh, cardHolder); })
          , catchError((error: any) => { return this.afterCardMemberInquiryFailure(error, cardHolder); }));
      }
      else {
        cardHolder.accountEAC = Constants.EMPTY;
        cardHolder.isCardHolder = false;
      }
    }

    if (!refresh) {
      this.store.dispatch(this.cardHolderActions.pushCardHolder(cardHolder));
    }
    return of(cardHolder);
  }

  private afterFindCreditCardAccountsFailure(error: any): Observable<CoBrandedCreditCardModel> {
    let cardHolder = new CoBrandedCreditCardModel();
    cardHolder.error = true;
    cardHolder.isCardHolder = false;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(cardHolder);
  }

  private afterCardMemberInquirySuccess(response: CardMemberInquiryResponse, refresh: boolean, cardHolder: CoBrandedCreditCardModel): Observable<CoBrandedCreditCardModel> {

    if (response) {
      let cardInfo: BmwCardInfoDTO;
      cardInfo = response.bmwCardInformation;
      if (cardInfo != null && cardInfo != undefined) {
        let rewardPoints: number = cardInfo.rewardsPointsAvailable;
        let minRequiredPoints: number = cardInfo.minimumRedeemablePoints;
        let dollarvalue: number = rewardPoints * cardInfo.pointsToDollarsMultiplier;
        cardHolder.isSelfServiceRestricted = cardInfo.selfServiceRestricted;
        cardHolder.dollarValue = dollarvalue;
        cardHolder.rewardPoints = rewardPoints;
        cardHolder.minimumRequiredPoints = minRequiredPoints;
      }
      else {
        cardHolder.isSelfServiceRestricted = true;
      }
    }
    cardHolder.error = false;
    if (!refresh) {
      this.store.dispatch(this.cardHolderActions.pushCardHolder(cardHolder));
    }
    return of(cardHolder);
  }

  private afterCardMemberInquiryFailure(error: any, cardHolder: CoBrandedCreditCardModel): Observable<CoBrandedCreditCardModel> {
    cardHolder.error = true;
    cardHolder.isCardHolder = false;
    this.fsTokenErrorHandler.handleFSTokenError(error);
    return of(cardHolder);
  }

  public findCreditCardAccount(): Observable<boolean> {
    let cachedCardHolderData = this.getCachedgetCardHolderData();
    if (!cachedCardHolderData|| cachedCardHolderData.isCardHolder==undefined ) {
      var request = new FindCreditCardAccountsRequest();
      request.bmwCreditCardBrand = FindCreditCardAccountsRequestBmwCreditCardBrand.BMW;
      request.customerNumber = this.userService.getCustomerNumber();;
      request.includeExpired = false;
      request.gcid = this.userService.getGcid();
      return this.bmwCardServiceClient.findCreditCardAccounts(request).pipe(mergeMap((response) => { return this.postFindCreditCardAccountsSuccess(response); })
        , catchError((error: any) => { return this.postFindCreditCardAccountsFailure(error); }));
    }
    else {
      return of(true);
    }
  }

  private postFindCreditCardAccountsSuccess(response: FindCreditCardAccountsResponse): Observable<boolean> {
    let isCardHolder: boolean = response && response.creditCardsKeyInfo && response.creditCardsKeyInfo.length > 0;
    return of(isCardHolder);
  }

  private postFindCreditCardAccountsFailure(error: any): Observable<boolean> {
    return of(false);
  }

}
