import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../_guard/auth.guard';
import { LegitimizedGuard } from '../../_guard/legitimized.guard';
import { TwoFactorAuthenticatedGuard } from '../../_guard/two-factor-authenticated.guard';
import { PaymentConfirmationDeactivateGuard } from '../../_guard/payment-confirmation.guard';
import { CancelUserActionConfirmationGuard } from '../../_guard/cancel-useraction-confirmation.guard';
import { OnBoardingAcceptGuard } from '../../_guard/onboarding-accept.guard';

import { PaymentEntryComponent } from './_components/payment-entry/payment-entry.component';
import { PaymentConfirmationComponent } from './_components/payment-confirmation/payment-confirmation.component';

import { PaymentConfirmationResolve } from './_components/payment-confirmation/payment-confirmation.resolve';
import { route } from '../../shared/_helper-services/router.service';
import { Constants } from '../../shared/constants';
import { ContractsResolve } from "../../_resolvers/contracts.resolve";
import { ContactResolve } from '../../_resolvers/contact.resolve';
import { V2FeatureIndicatorResolve } from '../../_resolvers/v2-feature-indicator.resolve';
import { MakeAPaymentResolver } from '../../_resolvers/make-payment.resolve';

const paymentEntryRoutes: Routes = [
    {
        path: 'entry', data: { subNavType: Constants.Accounts }, component: PaymentEntryComponent, canActivate: [LegitimizedGuard, TwoFactorAuthenticatedGuard, OnBoardingAcceptGuard], resolve: {
            void: ContractsResolve, ContactResolve, V2FeatureIndicatorResolve, MakeAPaymentResolver
        }
    },
    {
        path: 'confirmation', data: { subNavType: Constants.Accounts }, component: PaymentConfirmationComponent, canDeactivate: [PaymentConfirmationDeactivateGuard], canActivate: [LegitimizedGuard, TwoFactorAuthenticatedGuard, OnBoardingAcceptGuard], resolve: {
            void: PaymentConfirmationResolve
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(paymentEntryRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PaymentRoutingModule { }
