import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';
import { FSA } from 'flux-standard-action';
import { tassign } from 'tassign';


@Injectable()
export class FeatureIndicatorActions {
  static SET_OFFERS: string = "SET_OFFERS";
  static SET_MULTIFACTOR_AUTHENTICATION: string = 'SET_MULTIFACTOR_AUTHENTICATION';
  static SET_RECAPTCHA: string = 'SET_RECAPTCHA';
  static SET_COBRANDED_CREDITCARD: string = 'SET_COBRANDED_CREDITCARD';
  static SET_PAYOFF_PAYMENT: string = 'SET_PAYOFF_PAYMENT';
  static SET_EPAAS: string = 'SET_EPAAS';
  static SET_V2: string = 'SET_V2';
  static SET_V2_PAYOFF: string = 'SET_V2_PAYOFF';
  static SET_V2_MAKE_PAYMENT: string = 'SET_V2_MAKE_PAYMENT';
  static SET_V2_STATEMENTS_ENABLED: string = 'SET_V2_STATEMENTS_ENABLED';
  static SET_V2_EASY_PAY_ENABLED: string = 'SET_V2_EASY_PAY_ENABLED';
  static SET_V2_PAYMENT_DETAILS_ENABLED: string = 'SET_V2_PAYMENT_DETAILS_ENABLED';
  static SET_V2_LEASE_EXTENSION_ENABLED: string = 'SET_V2_LEASE_EXTENSION_ENABLED';
  static SET_V2_PAYMENT_OVERVIEW_ENABLED: string = 'SET_V2_PAYMENT_OVERVIEW_ENABLED';
  static SET_END_OF_TERM: string = 'SET_END_OF_TERM';
  static SET_V2_MIDTERM_MILEAGE_ENABLED: string = 'SET_V2_MIDTERM_MILEAGE_ENABLED';

  setMultiFactorAuthentication(multiFactorAuthentication: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_MULTIFACTOR_AUTHENTICATION,
      payload: multiFactorAuthentication,
      meta: null
    };
  }
  setRecaptcha(recaptcha: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_RECAPTCHA,
      payload: recaptcha,
      meta: null
    }
  }
  setOffers(hasOffers: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_OFFERS,
      payload: hasOffers,
      meta: null
    }
  }
  setCoBrandedCreditCard(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_COBRANDED_CREDITCARD,
      payload: featureState,
      meta: null
    }
  }
  setEPaas(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_EPAAS,
      payload: featureState,
      meta: null
    }
  }

  setPayOffPayment(payOffPayment: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_PAYOFF_PAYMENT,
      payload: payOffPayment,
      meta: null
    }
  }

  setV2Enabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2,
      payload: featureState,
      meta: null
    }
  }

  setV2PaymentDetailEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_PAYMENT_DETAILS_ENABLED,
      payload: featureState,
      meta: null
    }
  }

  setV2LeaseExtensionEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_LEASE_EXTENSION_ENABLED,
      payload: featureState,
      meta: null
    }
  }

  setV2StatementsEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_STATEMENTS_ENABLED,
      payload: featureState,
      meta: null
    }
  }

  setV2EasyPayEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_EASY_PAY_ENABLED,
      payload: featureState,
      meta: null
    }
  }

  setV2PaymentDetailsEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_PAYMENT_DETAILS_ENABLED,
      payload: featureState,
      meta: null
    }
  }

  setV2PayoffEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_PAYOFF,
      payload: featureState,
      meta: null
    }
  }

  setV2MakePaymentEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_MAKE_PAYMENT,
      payload: featureState,
      meta: null
    }
  }

  setEndOfTermEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_END_OF_TERM,
      payload: featureState,
      meta: null
    }
  }

  setV2PaymentOverviewEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_PAYMENT_OVERVIEW_ENABLED,
      payload: featureState,
      meta: null
    }
  }
  
  setV2MidtermMileageEnabled(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_V2_MIDTERM_MILEAGE_ENABLED,
      payload: featureState,
      meta: null
    }
  }

}


@Injectable()
export class FeatureIndicatorReducers {
  static MultiFactorAuthenticationReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_MULTIFACTOR_AUTHENTICATION:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
  static RecaptchaReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_RECAPTCHA:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
  static OffersEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(null), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_OFFERS:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
  static CoBrandedCreditCardEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_COBRANDED_CREDITCARD:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
  static EPaasEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_EPAAS:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static PayOffPaymentReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_PAYOFF_PAYMENT:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2EnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(true), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2StatementsReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_STATEMENTS_ENABLED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2EasyPayReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_EASY_PAY_ENABLED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2PaymentDetailsReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_PAYMENT_DETAILS_ENABLED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2LeaseExtensionReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_LEASE_EXTENSION_ENABLED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2PayoffEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_PAYOFF:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2MakePaymentEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_MAKE_PAYMENT:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2PaymentOverviewReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_PAYMENT_OVERVIEW_ENABLED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }
  static EndOfTermReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_END_OF_TERM:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

  static V2MidtermMileageEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(undefined), action: FSA<string, boolean, void>) => {
    switch (action.type) {
      case FeatureIndicatorActions.SET_V2_MIDTERM_MILEAGE_ENABLED:
        return tassign(state, new BooleanModel(action.payload));
      default:
        return state;
    }
  }

}
