import { Injectable } from '@angular/core';
import { Base64BtnModel } from '../../_models/base-64-btn.model';
import { Constants } from '../constants';

@Injectable()
export class FileDownloadService {


  public openFile(base64BtnInfo: Base64BtnModel) {
    if (base64BtnInfo.base64 != undefined) {
      let byteArray = this.base64ToArrayBuffer(base64BtnInfo.base64);
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        var blob2 = new Blob([byteArray], { type: "application" });
        nav.msSaveOrOpenBlob(blob2, base64BtnInfo.docName + base64BtnInfo.fileType);
      }
      this.saveByteArray(base64BtnInfo.docName, byteArray, base64BtnInfo.fileType)
    }
  }

  private base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  private saveByteArray(reportName, byte, fileType) {
    let blob: any;
    switch (fileType.toUpperCase()) {
      case Constants.FileDownloadPng:
        blob = new Blob([byte], { type: Constants.FileDownloadTypePng });
        break;
      case Constants.FileDownloadPdf:
        blob = new Blob([byte], { type: Constants.FileDownloadTypePdf });
        break;
      case Constants.FileDownloadGif:
        blob = new Blob([byte], { type: Constants.FileDownloadTypeGif });
        break;
      case Constants.FileDownloadJpeg:
        blob = new Blob([byte], { type: Constants.FileDownloadTypeJpeg });
        break;
      case Constants.FileDownloadJpg:
        blob = new Blob([byte], { type: Constants.FileDownloadTypeJpg });
        break;
      case Constants.FileDownloadTif:
        blob = new Blob([byte], { type: Constants.FileDownloadTypeTif });
        break;
      case Constants.FileDownloadTiff:
        blob = new Blob([byte], { type: Constants.FileDownloadTypeTiff });
        break;
      default:
        blob = new Blob([byte], { type: Constants.FileDownloadTypePdf });
        break;
    }
    var link = document.createElement('a');
    link.href = (window.URL || (window as any).webkitURL).createObjectURL(blob);
    link.setAttribute("download", reportName + fileType);
    document.body.appendChild(link);
    setTimeout(function () {
      link.click();
      document.body.removeChild(link);
    }, 66);
  }
}
