import { Component, Inject, Injector, HostListener, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../../../../shared/_helper-services/user.service';
import { NotificationModel } from '../../../../../../_models/notification.model';
import { PreferenceCenterService } from '../../../../../../_web-services/preference-center.service';
import { BaseApplicationComponent } from '../../../../../../core/basecomponent/base.component';
import { ServicingPreferenceDTOPreferenceCode } from '../../../../../../core/gateway-api';
import { Constants } from '../../../../../../shared/constants';
import { PreferencesActions } from '../../../../../../shared/store/reducers/preferences.reducers';

@Component({
  selector: 'notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class NotificationDialog extends BaseApplicationComponent<NotificationDialog> {
  public cancelBtnText: string;
  public submitBtnText: string;
  public error: boolean;
  public errorMessage: string;
  public notification: NotificationModel;
  public message: string;
  private isInEditMode: boolean;
  private numberOfDays: number[];

  constructor(
    private translateService: TranslateService,
    private preferenceCenterService: PreferenceCenterService,
    private preferencesActions: PreferencesActions,
    private userService: UserService,
    private dialogRef: MatDialogRef<NotificationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
  }

  ngOnInit() {
    this.notification = this.data ? this.data.notification : null;
    this.isInEditMode = this.data ? this.data.isInEditMode : null;
    this.numberOfDays = Constants.NumberOfDays;
    this.setMessage();
    if(this.notification !== null){
      this.analytics.pushSiteToolEvent({siteTool:this.data.notification.label, siteToolStep:Constants.SiteToolStart});
    }
    
  }

  public confirmUserAction(): void {
    let customerNumber = this.userService.getCustomerNumber();
    let siteToolPreferance = (this.notification && this.notification.isSelected) ? Constants.notificationRemainderOn : Constants.notificationRemainderOff;
    if (customerNumber &&
      this.notification &&
      this.notification.preference &&
      //don't save if there's no secondary email & no dropdown value(days for payment reminder) and we are in edit mode
      !(this.isInEditMode && !this.notification.dialogOptionSecondaryText && !this.notification.dialogValue)) {
      super.setDataLoadedOnRequest(false);
      this.notification.preference.alternateEmailIndicator = this.notification.dialogOptionSecondaryIsChecked;
      this.notification.preference.preferenceValue = this.notification.dialogValue ? this.notification.dialogValue.toString() : null;
      this.analytics.pushSiteToolEvent({siteTool:this.data.notification.label, siteToolStep:Constants.SiteToolComplete, notificationReminderPreference: siteToolPreferance});
      this.preferenceCenterService.updateServicingPreferencesStatus(customerNumber, [this.notification.preference])
        .subscribe(response => { this.postUpdatePreference(response); });
    }
    else {
      this.dialogRef.close(null);
    }
  }

  public cancelUserAction(): void {
    let response = this.isInEditMode;
    this.dialogRef.close(response);
  }

  private setMessage() {
    if (this.isInEditMode || (!this.isInEditMode && this.notification.isSelected)) {
      this.message = this.notification.dialogMessageActivate;
      this.submitBtnText = this.notification.submitBtnActivateText;
      this.cancelBtnText = this.notification.cancelBtnActivateText;
    }
    else {
      this.message = this.notification.dialogMessageDeactivate;
      this.submitBtnText = this.notification.submitBtnDeactivateText;
      this.cancelBtnText = this.notification.cancelBtnDeactivateText;
    }
  }

  private postUpdatePreference(response: boolean) {
    if (response) {
      this.error = false;
      this.store.dispatch(this.preferencesActions.clearPreferences());
      this.dialogRef.close(true);
    }
    else {
      this.error = true;
      const errorTranslationKey = "ngw.global.technical-error";
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.errorMessage = res;
      });
      this.analytics.pushSiteToolError({siteToolError: errorTranslationKey});
    }
    super.setDataLoadedOnRequest(true);
  }

  private secondaryEmailCheckboxTooltipClick() {
    this.notification.dialogOptionSecondaryIsChecked = !this.notification.dialogOptionSecondaryIsChecked;
  }

}
