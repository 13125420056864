import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { CustomerInvoiceServiceClient, GetInvoiceHistoryRequest, InvoiceHistoryDTO, GetInvoicePDFWithValidationRequest } from '../core/gateway-api';
import { StatementInfo, StatementCollection } from "../_models/my-account/statement.model";
import { StatementPdf } from "../_models/my-account/statementpdf.model";
import { FSTokenErrorHandler } from "../shared/_errorhandler/gobal-error-handler";
import { StatementCollectionActions } from './../shared/store/reducers/statement-collection.reducers';
import { Store } from '@ngrx/store';
import { ObjectService } from '../shared/_helper-services/object.service';
import { IAppState } from "../shared/store/app.store";

@Injectable()
export class CustomerInvoiceService {
    constructor(
        private customerInvoiceServiceClient: CustomerInvoiceServiceClient,
        private fsTokenErrorHandler: FSTokenErrorHandler,
        private store: Store<IAppState>,
        private objectService: ObjectService,
        private statementCollectionActions: StatementCollectionActions) {
    }

    public getCachedStatementCollection(): StatementCollection {
        let statementCollection: StatementCollection;
        this.store.select(state => state.StatementCollection).subscribe(x => statementCollection = x);
        return statementCollection;
    }

    public getInvoiceHistory(accountNumber: string): Observable<StatementCollection> {
        let cachedStatementCollection = this.getCachedStatementCollection();
        if (this.objectService.isEmptyObject(cachedStatementCollection) || cachedStatementCollection == null
            || (cachedStatementCollection && cachedStatementCollection.accountNumber !== accountNumber)) {
            let getInvoiceHistoryRequest = new GetInvoiceHistoryRequest();
            getInvoiceHistoryRequest.accountNumber = accountNumber;
            return this.customerInvoiceServiceClient.getInvoiceHistory(getInvoiceHistoryRequest)
                .pipe(mergeMap((response) => { return this.afterGetInvoiceHistorySuccess(response, accountNumber); })
                    , catchError((error: any) => { return this.afterGetInvoiceHistoryFailure(error); }));
        }
        return of(this.getCachedStatementCollection());
    }

    private getStatementInfo(invoiceHistory: InvoiceHistoryDTO[], accountNumber: string): StatementCollection {
        let statementCollection = new StatementCollection();
        let statementInfo = new Array<StatementInfo>();
        invoiceHistory.forEach(e => {
            let statementInfoObj = new StatementInfo();
            statementInfoObj.invoiceNumber = e.invoiceNumber;
            statementInfoObj.invoiceDate = e.invoiceDate
            statementInfoObj.isAccountInBankruptcyStatus = e.isAccountInBankruptcyStatus;
            statementInfo.push(statementInfoObj);
        });
        statementCollection.statementInfo = statementInfo;
        statementCollection.accountNumber = accountNumber;
        return statementCollection;
    }

    private afterGetInvoiceHistorySuccess(result: any, accountNumber: string): Observable<StatementCollection> {
        let statementCollection = this.getStatementInfo(result.invoiceHistory, accountNumber);
        this.store.dispatch(this.statementCollectionActions.setStatementCollection(statementCollection));
        return of(statementCollection);
    }

    private afterGetInvoiceHistoryFailure(result: any): Observable<StatementCollection> {
        let statementCollection = new StatementCollection();
        statementCollection.error = true;
        this.fsTokenErrorHandler.handleFSTokenError(result);
        return of(statementCollection);
    }

    public getStatementPdf(invoiceNumber: number, accountNumber: string): Observable<StatementPdf> {
        let getInvoicePDFWithValidationRequest = new GetInvoicePDFWithValidationRequest();
        getInvoicePDFWithValidationRequest.accountNumber = accountNumber;
        getInvoicePDFWithValidationRequest.invoiceNumber = invoiceNumber;
        return this.customerInvoiceServiceClient.getInvoicePDFWithValidation(getInvoicePDFWithValidationRequest)
            .pipe(mergeMap((response) => { return this.afterGetStatementPdfSuccess(response); })
                , catchError((error: any) => { return this.afterGetStatementPdfFailure(error); }));
    }

    private afterGetStatementPdfSuccess(result: any): Observable<StatementPdf> {
        let statementPdf = new StatementPdf();
        statementPdf.pdfData = result.invoicePdf;
        return of(statementPdf);
    }

    private afterGetStatementPdfFailure(error: any): Observable<StatementPdf> {
        this.fsTokenErrorHandler.handleFSTokenError(error);
        let statementPdf = new StatementPdf();
        statementPdf.error = true;
        statementPdf.faultType = error.faultType;
        return of(statementPdf);
    }
}
