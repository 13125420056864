<nav class="breadcrumbs">
  <div class="desktop-container">
    <a class="back" (click)="navigateToBack()"></a>
    <div class="current-page">
      <app-title [title]="title"></app-title>
    </div>
  </div>
</nav>
<div class="vehicle-return-estimate" [ngClass]="{'vehcle-return-estimate-error' : error}">
  <div class="vehicle-return-estimate-container" [scrollTop]="scrollToTop">
    <div *ngIf="error" class="app-vehicle-return-estimate-statement-container">
       <em class="error-validation error-vre">{{errorMessage}}</em>
    </div>
    <div *ngIf="!error && vehicleReturnEstimate" class="app-vehicle-return-estimate-statement-container">
      <div class="vehicle-return-estimate-statement">
        <div class="vehicle-return-estimate-statement-header">
          <app-vehicle-return-estimate-header [vehicleReturnEstimate]="vehicleReturnEstimate"></app-vehicle-return-estimate-header>
        </div>
        <div class="vehicle-return-estimate-statement-charges">
          <app-estimated-charges [vehicleReturnEstimate]="vehicleReturnEstimate"></app-estimated-charges>
        </div>
      </div>
      <div class="vehicle-return-estimate-summary" id="vre-summary">
        <app-estimated-summary [vehicleReturnEstimate]="vehicleReturnEstimate"></app-estimated-summary>
      </div>
    </div>
  </div>
</div>
