import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Constants } from '../constants';


@Injectable()

export class DeviceDetectorHelperService{

  constructor(private deviceDetectorService: DeviceDetectorService) {  }

  public isDeviceDesktop(){
    return this.deviceDetectorService.isDesktop()
  }

  public isiOS(): boolean { 
    let deviceOS: string;
    deviceOS = this.deviceDetectorService.os;
    if(deviceOS == Constants.iOS){
      return true;
    }
    else{
      return false;
    }
  }

}