import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { ActivityLogging } from "../../../_shared/core/basecomponent/features/logging/logging.factory";

@Injectable()
export class LogErrorService {
  constructor(private userService: UserService) { }
  protected logger: ActivityLogging;

  public logCriticalError(
    logger: ActivityLogging,
    componentName: string,
    logMsg: string
  ) {
    logger.logcritical([
      this.getStringFromArray([
        "Error occurred in: ",
        componentName,
        ", customer number:",
        this.userService.getCustomerNumber().toString(),
        ", Msg: ",
        logMsg,
      ]),
    ]);
  }

  private getStringFromArray(arrVals: any) {
    return arrVals.join(" ");
  }

  private getConcatenatedString(
    value1: string,
    value2: string,
    value3: string,
    value4: string,
    value5: string
  ): string {
    let commaSeparator = ",";
    let string: string = value1;
    if (value2) {
      string = string.concat(commaSeparator, value2);
    }
    if (value3) {
      string = string.concat(commaSeparator, value3);
    }
    if (value4) {
      string = string.concat(commaSeparator, value4);
    }
    if (value5) {
      string = string.concat(commaSeparator, value5);
    }
    return string;
  }
}
