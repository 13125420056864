import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalculateMiles } from '../../../../../../_models/map/calculate-miles.model';
import { MidtermMileageAdjustment } from '../../../../../../_models/map/midterm-mileage-adjustment.model';
import { SelectMiles } from '../../../../../../_models/map/select-miles.model';
import { ValidatorService } from '../../../../../../_validators/validator.service';
import { MidtermMileageAdjustmentService } from '../../../../../../_web-services/midterm-mileage-adjustment.service';
import { BaseApplicationComponent } from "../../../../../../core/basecomponent/base.component";
import { ContractAccountDetailDTO } from '../../../../../../core/gateway-api';
import { FaultCodes } from '../../../../../../shared/FaultCodes';
import { ObjectService } from "../../../../../../shared/_helper-services/object.service";
import { WindowService } from '../../../../../../shared/_helper-services/window.service';
import { IsInMAPFlowActions } from '../../../../../../shared/store/reducers/is-in-map-flow.reducer';
import { MidtermMileageAdjustmentActions } from '../../../../../../shared/store/reducers/midterm-mileage-adjustment.reducers';

@Component({
  selector: 'app-select-miles',
  templateUrl: './select-miles.component.html',
  styleUrls: ['./select-miles.component.scss']
})


export class SelectMilesComponent extends BaseApplicationComponent<SelectMilesComponent> implements OnInit, OnDestroy {

  @Input() stepper: any;
  @ViewChild('selectMilesTopDiv', { read: ElementRef }) selectMilesTopDiv: any;

  selectMilesData: SelectMiles;
  selectMilesSubscription: any;
  selectMiles: string;
  optionRecommended: string = 'Recommended';
  optionChooseYourOwn: string;
  recommendedOpened: boolean = false;
  chooseYourOwnOpened: boolean = false;
  recommendedSelected: boolean = false;
  chooseYourOwnSelected: boolean = false;
  chooseYourOwnAmt = 0;
  milesToPurchaseInput: any;
  milesEntered: boolean = false;
  mileageError: boolean = false;
  mileageWarning: boolean = false;
  errorMessage: string;
  currentMilesAllowed: number;
  expandState = true;
  minimumMileageFaultExists: boolean = false;
  calculateMilesData: CalculateMiles;
  midtermMileageAdjustmentData: MidtermMileageAdjustment;
  nextActive: boolean = false;
  currentAccountNumber: string;
  newTotalMilesAllowed: number;
  midtermMileageAdjustmentSubscription: any;
  constructor(private translateService: TranslateService,
    injector: Injector, @Inject(DOCUMENT) document,
    private midtermMileageAdjustmentService: MidtermMileageAdjustmentService,
    private validatorService: ValidatorService,
    private midtermMileageAdjustmentActions: MidtermMileageAdjustmentActions,
    private isInMAPFlowActions: IsInMAPFlowActions,
    private objectService: ObjectService,
    private windowService: WindowService, 
    private cdRef: ChangeDetectorRef) {
    super(injector, document);
  }

  ngOnInit() {
    this.translateService.get("ngw.map.entry.select-miles.lbl-choose-your-own-option").subscribe((res: string) => {
      this.optionChooseYourOwn = res;
    });
    this.store.select(state => state.ContractAccountDetail.accountNumber).subscribe(x => this.currentAccountNumber = x);
    this.midtermMileageAdjustmentSubscription = this.store.select(state => state.MidtermMileageAdjustment)
      .subscribe(midtermMileageAdjustment => this.setMidtermMileageAdjustmentData(midtermMileageAdjustment));
    this.selectMilesSubscription = this.store.select(state => state.SelectMiles)
      .subscribe(selectMiles => {
          this.setSelectMilesData(selectMiles)
      });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.selectMilesSubscription) {
      this.selectMilesSubscription.unsubscribe();
    }
    if (this.midtermMileageAdjustmentSubscription) {
      this.midtermMileageAdjustmentSubscription.unsubscribe();
    }
  }
  
  setSelectMilesData(selectMiles: SelectMiles): void {
    this.mileageError = false;
    this.mileageWarning = false;
    this.selectMiles = '';
    this.milesToPurchaseInput = '';
    this.chooseYourOwnAmt = 0;
    this.milesEntered = false;
    this.chooseYourOwnOpened = false;
    this.recommendedOpened = false;
    this.recommendedSelected = false;
    this.chooseYourOwnSelected = false;
    this.selectMilesData = selectMiles;
    this.minimumMileageFaultExists = this.selectMilesData.faultType == FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageMinimumFault;
    this.windowService.scrollDialogToTop(this.selectMilesTopDiv);
  }

  setMidtermMileageAdjustmentData(midtermMileageAdjustment: MidtermMileageAdjustment): void {
    this.midtermMileageAdjustmentData = midtermMileageAdjustment;
    let contractDetail: ContractAccountDetailDTO;
    this.store.select(state => state.ContractAccountDetail).subscribe(x => contractDetail = x);
    if (!this.objectService.isEmptyObject(this.midtermMileageAdjustmentData) &&
      this.midtermMileageAdjustmentData.midtermMileageAdjustmentData &&
      this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.allowedMiles &&
      contractDetail) {
      let originalContractedMiles = parseInt(this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.allowedMiles.toString());
      let additionalMiles = contractDetail.additionalPurchasedMiles;
      this.currentMilesAllowed = originalContractedMiles + additionalMiles;
    }

  }

  public setSelected(option): void {
    if (option === this.optionRecommended) {
      this.chooseYourOwnSelected = false;
      this.recommendedSelected = true;
    } else {
      this.chooseYourOwnSelected = true;
      this.recommendedSelected = false;
    }
  }

  public chooseYourOwnOptionIsExpanded(): boolean {
    return !this.selectMilesData.recommendedMilesSectionEnabled || this.chooseYourOwnOpened;
  }

  public onOpenChooseYourOwn() {
      this.chooseYourOwnSelected = true;
      this.chooseYourOwnOpened = true;
  }

  public onOpenRecommended() {
    this.recommendedOpened = true;
    this.recommendedSelected = true;
  }
  public updateMiles(): void {
    this.selectMiles = this.optionChooseYourOwn;
    this.setSelected(this.optionChooseYourOwn);
    this.chooseYourOwnAmt = this.milesToPurchaseInput;
    this.mileageError = false;
    this.mileageWarning = false;
    this.getMileageCalculation();
    this.milesEntered = true;
    this.nextActive = true;
  }

  getMileageCalculation(): void {
    if (this.midtermMileageAdjustmentData && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData) {
      let mMAStartDate: Date = new Date();
      if (this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.monthlyMmaWithNoQuote == 0 && this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.mMAStartDateList.length > 0) {
        mMAStartDate = this.midtermMileageAdjustmentData.midtermMileageAdjustmentData.mMAStartDateList[0];
      }
      super.setDataLoadedOnRequest(false);
      this.midtermMileageAdjustmentService.calculateMidTermMileageAdjustment(this.currentAccountNumber, mMAStartDate, this.milesToPurchaseInput).subscribe(response => {
        this.postGetMileageCalculation(response);
      });
    }
  }

  private postGetMileageCalculation(response: any) {
    this.navigateToNextStep(response);
    super.setDataLoadedOnRequest(true);
    this.store.dispatch(this.isInMAPFlowActions.setIsInMAPFlow(true));
  }

  private navigateToNextStep(response: any): void {
    this.calculateMilesData = new CalculateMiles();
    if (response && response.error) {
      this.mileageError = true;
      let errorTranslationKey: string;
      if (!response.faultType || (response.faultType != FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageMinimumFault &&
        response.faultType != FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageThresholdFault)) {
        errorTranslationKey = "ngw.global.technical-error";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          this.errorMessage = res;
        });
      }
      else if (response.faultType == FaultCodes.BMWFSAM_Services_AccountManagement_LeaseMileageThresholdFault) {
        errorTranslationKey = "ngw.map.entry.select-miles.error-exceed";
        this.translateService.get(errorTranslationKey).subscribe((res: string) => {
          this.errorMessage = res;
        });
      }
      this.nextActive = false;
      this.pushErrorEvent(errorTranslationKey);
    }
    else if (response && response.midtermMileageCalculation) {
      let contractAccountDetail: ContractAccountDetailDTO;
      this.store.select(state => state.ContractAccountDetail).subscribe(x => contractAccountDetail = x);
      this.calculateMilesData.midtermMileageCalculation = response.midtermMileageCalculation;
      this.newTotalMilesAllowed = (contractAccountDetail.additionalPurchasedMiles == null ? 0 : contractAccountDetail.additionalPurchasedMiles)
        + (contractAccountDetail.totalContractMileage == null ? 0 : contractAccountDetail.totalContractMileage)
        + this.milesToPurchaseInput;
      this.handleMinimumMileage();
      this.nextActive = true;
    }
  }

  public confirmCancellation(): void {
    this.stepper.selectedIndex = 5;
  }

  public nextClick(): void {
    this.stepper.selectedIndex = 4;
    this.midtermMileageAdjustmentData = new MidtermMileageAdjustment();
    if (this.recommendedSelected)
      this.midtermMileageAdjustmentData.milesToPurchase = this.selectMilesData.mileageCalculation.projectedMilesDeficit;
    else
      this.midtermMileageAdjustmentData.milesToPurchase = this.chooseYourOwnAmt;
    this.store.dispatch(this.midtermMileageAdjustmentActions.setMidtermMileageAdjustment(this.midtermMileageAdjustmentData));
  }

  public isMilesToPurchaseInvalid(field: any): boolean {
    if ((this.milesToPurchaseInput === undefined || this.milesToPurchaseInput == null || this.milesToPurchaseInput == '' || this.milesToPurchaseInput <= 0) ||
      this.milesToPurchaseIsInvalid(field, true)) {
      this.nextActive = false;
      return true;
    }
    return false;
  }

  public isSelectMilageValid(): boolean {
    return (this.recommendedSelected || (this.chooseYourOwnSelected && this.nextActive));
  }

  public onMilesToPurchaseChange() {
    this.calculateMilesData = new CalculateMiles();
    this.newTotalMilesAllowed = 0;
    this.milesEntered = false;
    this.nextActive = false;
  }

  public milesToPurchaseIsInvalid(field: any, showAtStart: boolean) {
    return this.validatorService.showRegexError(field, 'miles', showAtStart);
  }

  private handleMinimumMileage(): void {
    if (this.selectMilesData.recommendedMilesSectionEnabled && this.milesAreBelowRecommended(this.milesToPurchaseInput)) {
      this.mileageWarning = true;
      const errorTranslationKey = "ngw.map.entry.select-miles.note-minimum";
      this.translateService.get(errorTranslationKey).subscribe((res: string) => {
        this.errorMessage = res;
      });
      this.pushErrorEvent(errorTranslationKey);
    }
  }

  private milesAreBelowRecommended(milesInput: number) {
    return this.selectMilesData && this.selectMilesData.mileageCalculation && (milesInput < this.selectMilesData.mileageCalculation.projectedMilesDeficit);
  }

}
