// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  ENVIRONMENT_CODE: "UAT",
  GATEWAY_API_BASE_URL: "https://uat-myaccount.mini.ca",
  IDLE_SESSION: 4,
  TIME_OUT_SESSION: 1,
  TWO_FACTOR_AUTH_COOKIE_EXPIRATION_DAYS: 90,
  FS_EMAIL_ADDRESS: "minica.uat@bmwfs.com",
  SSO_REDIRECT_URI: "https://uat-myaccount.mini.ca/sso",
  AUTH_HUB_SSO_URL: "https://uat-securelogin.mini.ca/sso",
  SSO_LOGOUT_REDIRECT_URI: "https://uat-myaccount.mini.ca/sso/logout",
  AUTH_HUB_MANAGE_SETTINGS_REDIRECT_URI: "https://uat-securelogin.mini.ca/managesettings",
  AUTH_HUB_REDIRECT_URI: "https://uat-securelogin.mini.ca",
  MY_GARAGE_URL: "https://uatrod-mygarage.bmwusa.com",
  NGD_URL: "https://uat-myaccount.mini.ca",
  COSY_URL: "https://int.cosy.bmw.cloud/GRPCIRCLE/cosySec",
  MY_FS_URL: "https://myfs.bmwusa.com/bmw/",
  MY_BMW_URL: "https://www.bmwusa.com",
  MY_BMW_WEB_URL: "https://www.bmwusa.com/mybmw?mobileoverride=true",
  SAFETY_RECALL_LINK: "https://www.bmwusa.com/safety-recalls.html",
  PRIVACY_POLICY_URL: "https://www.bmwusa.com/privacy-policy/index.html",
  REFRESH_BEARER_TOKEN_INTERVAL_IN_SEC: 300,
  LOGOUT_FOR_BEARER_TOKEN_EXPIRY_INTERVAL_IN_MS: 3600000,
  SMC_ATTACHMENT_SIZE_LIMIT: 3,
  DEEPLINK_ENABLED: true,
  CRITICAL_MASS_HEADER_ENABLED: true,
  CONSOLE_LOGGING_ENABLED: true,
  APP_LOGGING_ENABLED: true,
  CA_DISABLED_FEATURES: [],
  ENABLED_FEATURES: ["MAP"],
  ANALYTICS_CONFIG: {
    BASE_DOMAIN: "bmwfs.com",
    CUSTOM_LINK_TRACKING: false,
    DATALAYER_NAME: "BMWdataLayer",
    DEBUG: false,
    EMBED_SCRIPT_URL: "https://assets.adobedtm.com/204e23461fef/634d11f0046a/launch-c43257db4875-development.min.js",
    SUB_DOMAIN: "mini-ca-uat"
  },
  LEASE_LINK: {
    LINK_LIST: [
      {
        TYPE: "Lease",
        LINK: "ACCOUNT_ACTIVITY_OFFERS",
        ENGLSIH_DESTINATION_URL: "https://mini.ca/en/special-offers?campaign=myaccount&province=provinceCode",
        FRENCH_DESTINATION_URL: "https://mini.ca/fr/special-offers?campaign=myaccount&province=provinceCode",
      },
      {
        TYPE: "Lease",
        LINK: "LEASE_END_GET_NEW_VEHICLE",
        ENGLSIH_DESTINATION_URL: "https://mini.ca/en/special-offers?campaign=myaccount&province=provinceCode",
        FRENCH_DESTINATION_URL: "https://mini.ca/fr/special-offers?campaign=myaccount&province=provinceCode",
      },
      {
        TYPE: "Lease",
        LINK: "LEASE_END_PRE_OWNED",
        ENGLSIH_DESTINATION_URL: "https://mini.ca/en/shopping/mini-next/list?campaign=myaccount&province=provinceCode&retailer=retailerId",
        FRENCH_DESTINATION_URL: "https://mini.ca/fr/shopping/mini-next/list?campaign=moncompte&province=provinceCode&retailer=retailerId",
      },
      {
        TYPE: "Lease",
        LINK: "LEASE_END_BUYOUT_VEHICLE",
        ENGLSIH_DESTINATION_URL: "https://mini.ca/en/about/contact?campaign=myaccount&select=vehiclebuyout",
        FRENCH_DESTINATION_URL: "https://mini.ca/fr/about/contact?campaign=moncompte&select=rachatduvehicle",
      },
    ]
  },
  MAP_RETRY_COUNT: 3,
  CONNECTED_DRIVE_URL: "https://www.bmwusa.com/explore/connecteddrive.html",
  DEALER_LOCATOR_URL: "https://www.bmwusa.com/dealer-locator.html",
  BMW_CONTACTUS_URL: "https://www.bmwusa.com/contact-us.html",
  CONNECTED_DRIVE_WEB_URL: "https://ConnectedDrive.bmwusa.com",
  MY_BMW_CREDIT_CARD_URL: "https://www.mybmwcreditcard.com/offer13",
  CREDIT_CARD_SSO_LOGIN_URL: "https://uat-federation.usbank.com/sp/ACS.saml2",
  CAPTCHA_SITE_KEY: "6LeHBcQZAAAAABuNTsjlvIJ_stgw5a8aIZl0bAGo",
  SSO: true,
  AUTH_PROVIDER: 1,
  EPAAS_SCRIPT_URL: "https://www.bmw.com/etc/clientlibs/wcmp/consentcontroller.fallback/epaas.js",
  GCDM_ONE_CONFIG:
  {
    CLIENT: "canhub",
    BRAND: "mini",
    COUNTRY: "CA",
    LANGUAGE: "en",
    SCOPE: "authenticate_user",
    RESPONSETYPE: "code",
    LOGINURL: "https://login-i.bmwusa.com/oneid/login",
    GRANTTYPE: "authorization_code",
    TOKENPROVIDER: '',
    LOGOUTURL: "https://login-i.bmwusa.com/oneid/#/logout"
  },
  NGD_BASE_URL: "https://uat-myaccount.mini.ca/",
  PROFILE_URL: "https://login-i.bmwusa.com/oneid/#/profile",
  STRIPE_PK: "pk_test_51MpFQfFUDg5SnVfK2ct9Fh9lpFF1r9Y0HuOmlzyofgNrvlPWQmikQ8ncyJrjpEOPyH54RwiY6OPRaskDbCKGa5SS00oxVXK8tg"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
