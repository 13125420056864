import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IAppState } from "../../../store/app.store";

@Component({
  selector: 'app-communication-preferences-dialog',
  templateUrl: './communication-preferences-dialog.component.html',
  styleUrls: ['./communication-preferences-dialog.component.scss']
})

export class CommunicationPreferencesDialog implements OnInit {

  constructor(public dialogRef: MatDialogRef<CommunicationPreferencesDialog>, private store: Store<IAppState>) {

  }

  ngOnInit() {

  }

  confirmUserAction() {
    this.dialogRef.close(true);
  }

  cancelUserAction() {
    this.dialogRef.close();
  }

}
